import { PromiseFunctionStorage } from '@/spa/helpers/app-utils/promise-function-storage';
import {OFFLOAD} from "@/spa/constants";

export const action = {
  IMPORT: 'IMPORT',
  GET_ALL: 'GET_ALL',
  GET_GROUPS: 'GET_GROUPS',
  GET_MODIFIERS: 'GET_MODIFIERS',
  GET_OPEN_ITEM: 'GET_OPEN_ITEM',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PROMOS: 'GET_PROMOS',
  GET_PRICES: 'GET_PRICES'
};

export function generateRequestId() {
  // crypto.randomUUID is standard on modern browsers, but is only available on
  // secure contexts (localhost or https-served pages)
  // Fall back to literally anything that works when it is not present, so
  // development setups work as expected.

  if ('crypto' in window && !!crypto.randomUUID) {
    return crypto.randomUUID();
  }

  return `fallback${Math.random() * 100}-${new Date().getTime()}`;
}

export function getUniqueObject(rows, key) {
  const uniqueCategories = new Map();

  rows.forEach(obj => {
    if (obj.id !== 0 && obj?.product_tag !== 'Open') {
      uniqueCategories.set(obj[key], obj);
    }
  });

  return Array.from(uniqueCategories.values());
}

export class DataOffloadBridge {
  static promiseStorage = new PromiseFunctionStorage();
  static listening = false;

  static listenForMessages() {
    if (!DataOffloadBridge.listening) {
      window.addEventListener('message', (event) => {
        let msgData = event.data;
        if (!msgData.mosaicBridgeMessage || msgData.type != 'DataOffloadMessage') {
          // Not for us. Ignore.
          return;
        }

        DataOffloadBridge.promiseStorage.resolve(msgData.requestId, msgData.body);
      });

      DataOffloadBridge.listening = true;
    }
  }

  constructor(type) {
    this.type = type;
  }

  async sendMessage(action, body) {
    if (!DataOffloadBridge.listening) {
      DataOffloadBridge.listenForMessages();
    }

    if (!window.locationId) {
      throw new Error('Cannot open SQLite offload database: locationId is not available');
    }

    if (window.mosaicDataOffload) {
      const terminalJson = sessionStorage.getItem('terminal');
      let terminalId = null;

      // Include the terminal ID, so multiterminal setups can filter out their
      // own events and not refresh UI excessively.
      // 
      // Note!
      // We only get a terminal when logged in. However, note that we also use
      // the offload bridge system for non-SQLite functionality, so we need to
      // still work when this code runs to facilitate some web<->native
      // interaction while in the login screen (hypothetically).
      if (terminalJson) {
        const terminal = JSON.parse(terminalJson);
        
        terminalId = terminal.id;
      }

      return new Promise((resolve, reject) => {
        const type = this.type;
        const requestId = generateRequestId();
        const locationId = window.locationId;
        const terminal = JSON.parse(sessionStorage.getItem('terminal') || '{}');
        const terminalId = terminal.id;

        // Only use in development
        // console.debug('DataOffloadBridge: sending message', {
        //   type, action, body, requestId, locationId
        // });

        window.mosaicDataOffload.postMessage(JSON.stringify({
          type, action, body, requestId, locationId, terminalId
        }));

        DataOffloadBridge.promiseStorage.put(requestId, resolve, reject);
      });
    }
  }

  async bulkImport(rows) {
    await this.sendMessage(action.IMPORT, { rows });
  }

  async getAll() {
    console.log(`${this.type} loaded from SQLite!`);
    return await this.sendMessage(action.GET_ALL);
  }

  async deleteAll(pass = null) {
    if (pass !== "1nc0rr3cT") {
      return "Access denied!";
    }

    return await this.sendMessage('DELETE_ALL');
  }
}

export class BrandBridge extends DataOffloadBridge {
  constructor() {
    super('Brand');
  }

  async getAll() {
    const { rows } = await this.sendMessage(action.GET_ALL);
    console.log(`${this.type} loaded from SQLite!`);
    return rows.map(row => ({ ...row, service_types: JSON.parse(row.service_types) }));
  }
}

export class ServiceChannelBridge extends DataOffloadBridge {
  type = 'ServiceChannel';
}

export class PriceBridge extends DataOffloadBridge {
  constructor() {
    super('Price');
  }

  async getPrices(params={}) {
    console.log(`${this.type} loaded from SQLite!`);
    const { rows } = await this.sendMessage(action.GET_PRICES, { params });
    rows.map(row => {
      row.service_channel_id = row.service_channel_id || null;
      return row;
    })
    return rows;
  }
}

export class ProductCategoryBridge extends DataOffloadBridge {
  constructor() {
    super('ProductCategory');
  }

  async getProductCategories(params) {
    console.log(`${this.type} loaded from SQLite!`);
    const {rows} = await this.sendMessage('GET_PRODUCT_CATEGORIES', { params });
    return rows;
  }
}

export class ProductGroupBridge extends DataOffloadBridge {
  constructor() {
    super('ProductGroup');
  }

  async getGroups(categoryId=null, brandId=null) {
    let body = { categoryId };

    if (OFFLOAD.sqliteOffloadMA58) {
      body = {
        ...body,
        brandId
      }
    }

    const { rows } = await this.sendMessage(action.GET_GROUPS, { ...body });
    return rows;
  }
}

export class ProductBridge extends DataOffloadBridge {
  constructor() {
    super('Product');
  }

  async getOpenItem() {
    const openItem = await this.sendMessage(action.GET_OPEN_ITEM);

    if (!openItem?.product_group) {
      return null
    }

    openItem.product_group = JSON.parse(openItem?.product_group);
    return openItem;
  }

  async getProducts(params) {
    console.log(`${this.type} loaded from SQLite!`);
    const results = await this.sendMessage(action.GET_PRODUCTS, { params });
    results.data.map(datum => {
      datum.product_group = JSON.parse(datum.product_group);
    });
    return results;
  }
}

export class ReceiptDetailBridge extends DataOffloadBridge {
  constructor() {
    super('ReceiptDetail');
  }

  async getAll() {
    console.log(`${this.type} loaded from SQLite!`);
    const { rows: data } = await this.sendMessage(action.GET_ALL);

    return { data };
  }

  async updateData(payload) {
    await this.sendMessage('UPDATE_DATA', { payload });
  }

  async getRowByLocationId(locationId) {
    return await this.sendMessage('GET_ROW_BY_LOCATION_ID', { locationId });
  }
}

export class TaxBridge extends DataOffloadBridge {
  constructor() {
    super('Tax');
  }

  async getRow(payload) {
    return await this.sendMessage('GET_ROW', { payload });
  }
}

export class UserBridge extends DataOffloadBridge {
  constructor() {
    super('User');
  }

  async getRows(params = {}) {
    const { rows } = await this.sendMessage('GET_ROWS', { params });
    return rows;
  }

  async getRowById(id) {
    return await this.sendMessage('GET_ROW_BY_ID', { id });
  }
}

export class LocationBridge extends DataOffloadBridge {
  constructor() {
    super('Location');
  }

  async getAll() {
    const { rows } = await this.sendMessage(action.GET_ALL);

    return rows.map(row => {
      row.permission_ids = JSON.parse(row.permission_ids);
      return row;
    });
  }

  async getColumnValue(col) {
    if (!OFFLOAD.sqliteOffloadPermission) {
      return false;
    }

    const [firstRow] = await this.getAll();
    return firstRow ? firstRow[col] : false;
  }
}
