<template>
  <div class="m-icon-message d-flex flex-column align-items-center">
    <div class="icon d-flex justify-content-center align-items-center"><i class="fa mb-5" :class="icon" v-if="icon" /></div>
    <div class="message" v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "MIconMessage",

  props: {
    icon: {
      type: String,
    },

    message: {
      type: String,
    },
  },
};
</script>

<style scoped>
.icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #e5e5e5;
  margin-bottom: 10px;
}

.icon i {
  color: #898989;
  font-size: 60px;
  margin: 0 !important;
}

.message {
  color: #656565;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
</style>
