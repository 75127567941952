import { DataOffloadBridge } from '../offload/offload';

const actions = {
  GET_SERIES_NUMBER: 'GET_SERIES_NUMBER',
  SET_SERIES_NUMBER: 'SET_SERIES_NUMBER',
  GET_AND_INCREMENT_SERIES_NUMBER: 'GET_AND_INCREMENT_SERIES_NUMBER',
  GET_STATUS: 'GET_STATUS',
  FORCE_SATELLITE_LOGOUT: 'FORCE_SATELLITE_LOGOUT',
}

export class SeriesUtilityBridge extends DataOffloadBridge {
  constructor() {
    super('SeriesUtility');
  }

  async getSeriesNumber(key) {
    return await this.sendMessage(actions.GET_SERIES_NUMBER, { key });
  }

  async setSeriesNumber(key, value) {
    value = parseInt(value);

    return await this.sendMessage(actions.SET_SERIES_NUMBER, { key, value });
  }

  async getAndIncrement(key, value) {
    return await this.sendMessage(actions.GET_AND_INCREMENT_SERIES_NUMBER, { key });
  }
}

export class MultiterminalUtilityBridge extends DataOffloadBridge {
  constructor() {
    super('MultiterminalUtility');
  }

  async getStatus() {
    return await this.sendMessage(actions.GET_STATUS, {});
  }
}

export class PrimaryBroadcastUtilityBridge extends DataOffloadBridge {
  constructor() {
    super('PrimaryBroadcastUtility');
  }

  async forceSatelliteLogout() {
    const response = await this.sendMessage(actions.FORCE_SATELLITE_LOGOUT, {});

    await new Promise(resolve => setTimeout(resolve, 2500));
    return response;
  }
}
