<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label class="ml-0">Settings Page</label>

      <div class="tab-panel mt-3" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between mb-1">
          <div class="d-flex flex-column align-items-start">
            <label class="mb-3 ml-0">Discounts</label>
            <label class="mb-1 ml-0" for="discountsFilter"><i class="fas fa-filter mr-1"></i>Filter discounts:</label>
            <select v-model="selectedFilterOption" name="discounts" id="discountsFilter" class="mr-0 form-control minimal">
              <option
                v-for="filterOption in filterOptions"
                :value="filterOption.value"
                :key="`discounts-filter-${filterOption}`"
                >
                {{ filterOption.label }}
              </option>
            </select>
          </div>
        
          <div class="d-flex align-self-end" id="searchAndCheckboxContainer">
            <input
              id="discountSearchQuery"
              v-model="discountSearchQuery"
              class="form-control"
              type="text"
              placeholder="Search discounts"
            />
          </div>
        </div>
        
        <div class="discounts-settings-section mb-4">
          <v-data-table
            :headers="discountsHeaders"
            :items="filteredDiscounts"
            :rows-per-page="10"
            sort-by="discount_name"
            sort-type="desc"
            >
            <template #item-discount_name="item">
              <strong class="ml-1">
                {{ item.discount_name }}
              </strong>
            </template>

            <template #item-discount_rate="item">
              {{ `${item.discount_rate * 100}%`  ?? '-' }}
            </template>

            <template #item-discount_amount="item">
              {{ item.discount_amount ?? '-' }}
            </template>

            <template #item-is_service_bsd="item">
              {{ item.is_service_bsd ? 'Yes' : 'No' }}
            </template>

            <template #item-has_customer_details="item">
              {{ item.has_customer_details ? 'Yes' : 'No' }}
            </template>

            <template #item-is_vat_based="item">
              {{ item.is_vat_based ? 'Yes' : 'No' }}
            </template>

            <template #item-start_date="item">
              {{ item.start_date ?? '-' }}
            </template>

            <template #item-end_date="item">
              {{ item.end_date ?? '-' }}
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import VDataTable from 'vue3-easy-data-table';
import { getAllDiscountItems } from '@/spa/services/discount-service';
import { checkCloudConnection } from "@/spa/plugins/axios";

export default {

  components: {
    SettingsSidebar,
    VDataTable,
  },

  data() {
    return {
      filterOptions: [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
      ],
      discountsHeaders: [
        {
            value: 'id',
            sortable: true,
            text: 'ID'
        },
        {
            value: 'discount_name',
            sortable: true,
            text: 'Name'
        },
        {
            value: 'discount_rate',
            sortable: true,
            text: 'Rate'
        },
        {
            value: 'discount_amount',
            sortable: true,
            text: 'Amount'
        },
        {
            value: 'is_service_bsd',
            sortable: true,
            text: 'Service Based?'
        },
        {
            value: 'has_customer_details',
            sortable: true,
            text: 'Customer Details?'
        },
        {
            value: 'is_vat_based',
            sortable: true,
            text: 'From Price without VAT?'
        },
        {
            value: 'start_date',
            sortable: true,
            text: 'Start Date',
        },
        {
            value: 'end_date',
            sortable: true,
            text: 'End Date',
        }
      ],
      originalDiscounts: [],
      discounts: [],
      selectedFilterOption: 'all',
      discountSearchQuery: '',
    };
  },
  computed: {
    filteredDiscounts() {
        return this.discounts.filter(discount => {
          if (this.selectedFilterOption === 'all') {
            return true;
          } 
          
          if (this.selectedFilterOption === 'active') {
            return !discount.end_date || new Date() < new Date(discount.end_date);
          }  
          
          if (this.selectedFilterOption === 'expired') {
            return discount.end_date && new Date() > new Date(discount.end_date);
          }
        });
    },
  },

  async mounted() {
    await this.getDiscounts();
  },

  methods: {
    async getDiscounts() {
      const includeExpiredDiscounts = true;
      const forceRefresh = await checkCloudConnection();
      const response = await getAllDiscountItems(forceRefresh, includeExpiredDiscounts);
      this.discounts = response.data.discounts;
      // Store the original discounts data (for search functionality)
      this.originalDiscounts = [...this.discounts];
    },
  },

  watch: {
    discountSearchQuery(value) {
      if (!value) {
        // If search query is empty, restore the original discounts data
        this.discounts = [...this.originalDiscounts];
      } else {
        this.discounts = this.originalDiscounts.filter(discount => {
          return discount.discount_name.toLowerCase().includes(value.toLowerCase());
        });
      }
    },
  }
}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

:deep(#searchAndCheckboxContainer) {
  width: 100%;
  max-width: 500px;
}

:deep(#discountSearchQuery) {
  width: 100%;
}

.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>