export const VAT_EXCL = ['Senior Citizen Discount', 'Senior Citizen Ceiling', 'Diplomat Discount', 'PWD', 'PWD Ceiling', 'Complimentary'];
export const ZERO_RATED = ['Diplomat Discount'];
export const HAS_VAT_DISCOUNT = ['Medal of Valor', 'Medal Of Valor', 'MOV', 'MOV Discount', 'Valor', 'Valor Discount'];
export const VAT_BEFORE_DISCOUNT = HAS_VAT_DISCOUNT.concat([
  'Athlete',
  'Athlete Discount',
  'Athletes Discount',
  "Athlete's Discount",
  "Athlete's Discount",
  "National Athlete Discount",
  "National Athlete's Discount",
  "Athlete Discount",
  "Athlete's Discount",
  "Athlete",
  "20% Athletes",
  "20 DISC Athlete And Coach",
  "20% Athlete's Discount",
  "National Athlete",
  "Athlete disc"
]);
export const TAX_INCL = 'Tax Inclusive';
// Define the patterns for "PWD" and "Senior Citizen"
const PWD_REGEX = /\bPWD\b/i;
const SENIOR_CITIZEN_REGEX = /\bSenior\sCitizen\b/i;

export const getPriceTax = (vat, serviceCharge, isComplimentary = false) => {
  if (isComplimentary) return 1;
  return Number(vat.tax_rate) + (serviceCharge?.tax_type.type_name == "Tax Inclusive" ? Number(serviceCharge?.tax_rate ?? 0) : 0);
}

export const processVatBeforeDiscount = (netSales, vatValue, discountValue, vat, discount) => {
  // Compute discount from original net sales if Athlete or MoV
  if (VAT_BEFORE_DISCOUNT.includes(discount.discount_name)) {
    vatValue = netSales * (Number(vat.tax_rate) - 1);
  }

  // Add vat from discount if MoV
  if (HAS_VAT_DISCOUNT.includes(discount.discount_name)) {
    vatValue += discountValue * (Number(vat.tax_rate) - 1);
  }

  return vatValue;
}

export const processVatExclDiscount = (discount, order, vat, net_of_vat) => {
  const discountValue = net_of_vat * Number(discount.discount_rate);
  const netSales = net_of_vat - discountValue;
  const allVat = {};
  let vatExclSales, zeroRatedSales;

  if (ZERO_RATED.includes(discount.discount_name)) {
    zeroRatedSales = allVat['Zero Rated Sales'] = netSales;
    order.total_zero_rtd_sales = (order.total_zero_rtd_sales?? 0) + netSales;
  } else if (VAT_EXCL.includes(discount.discount_name)) {
    vatExclSales = allVat['Vat Exempt Sales'] = netSales;
    order.total_vat_ex_sales = (order.total_zero_rtd_sales?? 0) + netSales;
  }

  let vatValue = VAT_EXCL.includes(discount.discount_name) ? 0 : netSales * (Number(vat.tax_rate) - 1);
  
  vatValue = processVatBeforeDiscount(net_of_vat, vatValue, discountValue, vat, discount);

  return {
    order,
    netSales,
    discountValue,
    allVat,
    vatValue,
    zeroRatedSales,
    vatExclSales,
  };
};

export const processRegularDiscount = (discount, vat, total, price_tax) => {
  const discountValue = total * Number(discount.discount_rate);
  const netSales = (total - discountValue) / price_tax;
  const netBeforeDisc = netSales;

  let vatValue = netSales * (Number(vat.tax_rate) - 1);

  vatValue = processVatBeforeDiscount(netBeforeDisc, vatValue, discountValue, vat, discount);

  return {
    discountValue,
    netSales,
    vatValue,
  };
};

export const applyDynamicTaxes = (taxes, net_of_vat, total, isLineItem = false) =>
  taxes.map((tax) => {
    const { tax_name, is_net_based, tax_rate } = tax;
    const taxValue = (is_net_based ? net_of_vat : total) * Number(tax_rate);

    if (!isLineItem) {
      return { [tax_name]: taxValue };
    }

    return {
      tax_name,
      is_net_based,
      tax_type: tax.tax_type.type_name,
      fix_amount: "0",
      tax_amount: taxValue,
    };
  });

export const processOrderDiscount = (order) => {
  let allDiscount = {},
    totalDiscount = 0;
  let sc = 0,
    vat = 0,
    vatableSales = 0;
  let vatExSales = {
    "Vat Exempt Sales": 0,
    "Zero Rated Sales": 0,
  };
  const discPercentages = [];

  order.order.forEach((item) => {
    if (item.discount_type) {
      allDiscount[item.discount_type] = (allDiscount[item.discount_type] ?? 0) + item.discount_value;
      totalDiscount += item.discount_value;
      if(item.discount_percent > 0) {
        discPercentages.push(item.discount_percent);
      }
    }
    vatableSales += item.total_vatable_price;
    sc += (isNaN(item.svc_chrge_amt) ? 0 : item.svc_chrge_amt) * (isNaN(item.qty) ? 0 : item.qty);
    vat += item.total_tax_amount;
    vatExSales["Vat Exempt Sales"] += item.unit_vat_ex_sales ?? 0;
    vatExSales["Zero Rated Sales"] += item.unit_zero_rtd_sales ?? 0;
  });

  const billPrice = vatableSales + vat + sc;
  return {
    ...order,
    service_charge: sc,
    vat_amt: vat,
    all_discounts: allDiscount,
    total_discount: totalDiscount,
    dynamic_tax_total: order.dynamic_tax_total ?? 0,
    all_vat: vatExSales,
    total_vat_ex_sales: vatExSales["Vat Exempt Sales"],
    total_zero_rtd_sales: vatExSales["Zero Rated Sales"],
    sub_total: vatableSales,
    bill_price: billPrice,
    disc_percentages: [...new Set(discPercentages)]
  };
};

export const computeServiceCharge = (discount, discountValue, netSales, vat, serviceCharge) => {
  if(!discount.has_service_charge || !serviceCharge) {
    return 0;
  }

  const vatRate = Number(vat?.tax_rate ?? 0);
  const scRate = Number(serviceCharge?.tax_rate ?? 0);
  const isNetBsd = Boolean(serviceCharge?.is_net_based);
  
  let grossBeforeDiscount = discount.is_vat_based && VAT_EXCL.includes(discount.discount_name) ? (netSales + discountValue) * vatRate : netSales * vatRate + discountValue;
  let totalBeforeDisc = grossBeforeDiscount;
  let afterDiscTotal = netSales;

  if (isNetBsd || serviceCharge?.tax_type?.type_name == TAX_INCL) {
    totalBeforeDisc /= vatRate;
  } else if (!VAT_EXCL.includes(discount.discount_name) && !discount.is_vat_based) {
    afterDiscTotal = netSales * vatRate;
  }
  if (serviceCharge?.is_after_discount) {
    return afterDiscTotal * scRate;
  }

  return totalBeforeDisc * scRate;
}

export const hasDiscountEligibilityKeywords = (text) => {
  return PWD_REGEX.test(text) || SENIOR_CITIZEN_REGEX.test(text);
}

window.computeServiceCharge = computeServiceCharge;
