import { camelCase } from 'lodash';
import {BILLS_DENOMINATION} from "@/spa/constants";


function defaultStateFactory() {
    return {
        cashFloat: [],
        terminalsCashFund: [],
        billsDenomination: BILLS_DENOMINATION,
        shiftTable: {},
        posDate: '',
        beginningBalance: 0,
        beginningVoid: {},
        beginningVoidAmount: 0,
        shiftChangeRecord: [],
        dayEndRecord: [],
        previousVoidNum: 0,
        previousReceiptNum: 0,
        currentShift: {},
        serviceTypes: [],
        cashFloatActivities: [],
        hasCashFloat: true
    };
}

export default {
    namespaced: true,
    state: defaultStateFactory(),

    mutations: {
        resetState(state) {
            Object.assign(state, defaultStateFactory());
        },

        setCashFloatActivities(state, data) {
            state.cashFloatActivities = data;
        },

        setHasCashFloat(state, value) {
            state.hasCashFloat = value;
        },

        pushNewCashFloat(state, data) {
            state.cashFloatActivities.push(data);
        },

        setServiceTypes(state, data) {
            state.serviceTypes = data;
        },

        setBeginningBalance(state, data) {
            state.beginningBalance = data;
        },

        setBeginningVoid(state, data) {
            state.beginningVoid = data;
        },

        setBeginningVoidAmount(state, data){
            state.beginningVoidAmount = data;
        },

        setPreviousVoidNum(state, data) {
            state.previousVoidNum = data;
        },

        setPreviousReceiptNum(state, data) {
            state.previousReceiptNum = data;
        },

        setShiftChangeRecord(state, data) {
            state.shiftChangeRecord.push(data);
        },

        setDayendRecord(state, data) {
            state.dayEndRecord = data;
        },

        addDayendRecord(state, data) {
            state.dayEndRecord.push(data);
        },

        setCashFloat(state, data) {
            state.cashFloat = data;
        },

        setBillsDenomination(state, data) {
            state.billsDenomination = data;
        },

        setShiftTable(state, data) {
            state.shiftTable = data;
        },

        setCurrentShift(state, data) {
            state.currentShift = data;
        },

        incrementCurrentShiftNum(state, date) {
            state.currentShift.shift++;
            state.currentShift.shift_start = date;
        },

        setPosDate(state, data) {
            state.posDate = data;
        },

        setState(state, value) {
            Object.keys(value).forEach(k => {
                state[camelCase(k)] = value[k];
            });
        },

        setTerminalsCashFund(state, data) {
            state.terminalsCashFund = data;
        },
    },
}