import axios from "@/spa/plugins/axios";
import {OFFLOAD} from "@/spa/constants";

export const getDiscountItem = (data, productId = "") => {
  return axios.post(route("show.discount.item", [productId]), data);
};

export const getBillLevelDiscounts = async (forceRefresh = false) => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    const db = new DiscountBridge();
    const data = await db.getBillDiscounts();
    return {
      data
    }
  }

  return axios.get(route("all.discounts"), {
    forceRefresh
  });
};

export const getAllDiscountItems = async (forceRefresh = false, includeExpiredDiscounts = false) => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    const db = new DiscountBridge();
    const data = await db.getDiscountItems();
    return {
      data
    }
  }

  return axios.get(route("all.discount.items"), {
    forceRefresh,
    params: {
      includeExpiredDiscounts
    }
  });
};

export const checkPasscode = (userId, passcode) => {
  return axios.post(route("validate.passcode"), {
    approver: userId,
    passcode,
  });
};

export default {
  getDiscountItem,
  getBillLevelDiscounts,
  checkPasscode,
  getAllDiscountItems,
};
