<template>
    <m-card
        clickable
        class="m-pending-order"
        v-bind="$attrs"
        >
        <div class="d-flex flex-nowrap align-items-center">
            <div
                class="service-type-label"
                v-text="serviceTypeLabel"
                />

            <div class="order-info p-2">
                <div class="order-number">
                    ORDER #: {{ orderNum }}
                </div>

                <div
                    v-if="order.orderDetail"
                    class="order-detail"
                    :title="order.orderDetail"
                    v-text="truncatedDetails"
                    />

                <div v-if="order.totals" class="order-totals">
                    <span class="order-total-label mr-2">TOTAL PRICE:</span>
                    <span class="order-total-price" v-text="`₱${getTotalValue}`" />
                </div>
            </div>

            <div v-if="!isOwnOrder" class="foreign-order-lock-indicator">
                <i class="fa fa-lock" />
                {{ order.originTerminalId }}
            </div>
        </div>
    </m-card>
</template>

<script>
import { isString } from 'lodash';
import { mapState } from 'vuex';
import MCard from '@/spa/components/common/MCard';
import { mapActions } from 'vuex';
import ReceiptMixin from "@/spa/components/mixins/ReceiptMixin";

export default {
    name: 'MPendingOrder',

    mixins: [ReceiptMixin],

    components: {
        MCard,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },

        maxOrderDetailsLength: {
            type: Number,
            default: 20,
        },
    },

    async mounted() {
        if (!this.order.totals) {
            this.regenerateOrderTotals({ orderId: this.order._id });
        }

        await this.getGrossSales(this.order._id);
    },

    computed: {
        ...mapState('user', ['terminalId']),

        lockForeignTransactions() {
            // make dynamic when requirements are defined
            return false;
        },

        isOwnOrder() {
            return this.order.originTerminalId === this.terminalId
                || !this.lockForeignTransactions;
        },

        serviceTypeLabel() {
            if (this.order.serviceType === 'Delivery') return this.order.channelName;
            return this.order.serviceType;
        },

        orderNum() {
            return this.order.order_num
                || `${this.order.brandId}-${this.order._id}`;
        },

        truncatedDetails() {
            if (!isString(this.order.orderDetail)) return '';

            if (this.maxOrderDetailsLength >= this.order.orderDetail.length) {
                return this.order.orderDetail;
            }

            const ellipsis = this.order.orderDetail.length > this.maxOrderDetailsLength ? '…' : '';
            return `${this.order.orderDetail.substring(0, this.maxOrderDetailsLength)}${ellipsis}`;
        },

        getTotalValue() {
          return this.$filters.formatPrice(this.grossSales || this.order.totals.total);
        }
    },

    methods: {
        ...mapActions(['regenerateOrderTotals']),
    },
};
</script>

<style scoped>
.service-type-label {
    display: grid;
    place-content: center;
    background-color: #e0f2ff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.6;
    text-transform: uppercase;
    color: #2b7fc0;
    height: 72px;
    width: 96px;
}

@media (min-width: 992px) {
    .service-type-label {
        font-size: 14px;
        width: 112px;
    }
}

.order-number {
    font-weight: bold;
}

.order-total-label {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
}

.order-total-price {
    font-weight: bolder;
    font-size: 1.15rem;
}

.m-pending-order {
    max-width: 100%;
    padding: 16px;
}

.m-pending-order:not(.active) .order-total-price {
    color: #2b7fc0;
}

.order-detail {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
}

.m-pending-order:not(.active) .order-detail {
    color: #4c5b67;
}

.foreign-order-lock-indicator {
    position: absolute;
    right: 8px;
    bottom: 8px;
    background-color: var(--danger);
    padding: 2px 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15),
        0 4px 8px 0 rgba(0, 0, 0, 0.15)
    ;
    color: white;
}
</style>
