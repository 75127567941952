<script>
import {
  getPaymentInvoices,
  createPaymentInvoice,
  cancelPaymentInvoice,
  createPaymongoInvoiceApi,
} from "@/spa/services/cashier-service";
import NetworkMixin from "@/spa/components/mixins/NetworkMixin";
import {mapActions, mapMutations, mapState} from "vuex";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {OFFLOAD} from "@/spa/constants";
import {isEmpty} from "lodash";
import bus from '@/spa/utils/bus';
import swal from "sweetalert";
import { PAYMENT_INVOICE_STATUSES } from "@/spa/constants";

export default {
  mixins: [NetworkMixin],

  data() {
    return {
      fetchPaymentInvoicesInterval: null,
      billQrCodeDetails: null,
      revalidateTimer: 5000,
      paymentInvoicesResponse: null,
    };
  },

  computed: {
    ...mapState("global", ["showPaymentQRModal", "paymentQRDetails"]),

    ePaymentInvoices() {
      return this.paymentInvoicesResponse?.map((payment) =>
        this.mapPaymentInvoice(payment)
      );
    },
  },

  methods: {
    ...mapMutations("global", ["setShowPaymentQRModal", "setPaymentQRDetails"]),
    ...mapMutations(["updateOrder"]),
    ...mapActions(["sqliteUpsertReceipt"]),

    generatePaymentQR(params) {
      if (!this.isOnline) {
        this.$customSwal({
          iconHtml: '<img src="/images/icon_cloud-offline.svg" alt="Offline Icon"/>',
          title: "You are offline",
          text: "Please check your network and try again.",
          customClass: {
            icon: 'no-border-icon',
            title: 'custom-title-class',
            content: 'custom-content-class',
          },
          buttonText: "OK",
          className: 'center-btn'
        });

        return;
      }

      this.setPaymentQRDetails(params);
      this.setShowPaymentQRModal(true);
    },

    startAutoRevalidation({ status }) {
      if (!isNetworkStable() || !window.hasEPayment) {
        return;
      }

      console.log(`PaymentQRMixin - startAutoRevalidation is called..`);
      console.log(`window.hasEPayment: ${window.hasEPayment}`);

      this.getPaymentInvoices({ status });

      clearInterval(this.fetchPaymentInvoicesInterval);

      this.fetchPaymentInvoicesInterval = setInterval(() => {
        if (this.isOnline) {
          this.getPaymentInvoices({ status }).then(() => {
            // only apply to paymongo for now
            if (this.paymentQRDetails?.type == "paymongo") {
              this.$store.commit("global/setPaymentQRDetails", {
                ...this.$store.state.global.paymentQRDetails,
                status: this.paymentQRDetails?.status,
              });
            }
          });
        }
      }, this.revalidateTimer);
    },

    async getPaymentInvoices({ status }) {
      try {
        const orderId = this.$route.params.orderId;
        const params = { orderId, status };
        const response = await getPaymentInvoices(params);

        if (OFFLOAD.sqliteOffloadReceipt) {
          const storage = new ScopedNativeStorage(window.locationId);
          const invoices = response.data.filter(item => item.orderId == orderId && item.status === 'PAID' && item?.isFullyPaid);

          if (invoices.length > 0) {
            storage.put('appLatestId', orderId);
          }
        }

        this.paymentInvoicesResponse = response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async createPaymongoInvoice(params) {
      console.log(`PaymentQRMixin - createPaymongoInvoice API is called!`);

      try {
        if (OFFLOAD.sqliteOffloadReceipt) {
          const ob = new OrderBridge();
          const order = await ob.getOrderById(parseInt(params.orderId));

          if (isEmpty(order)) {
            throw new Error('Failed to retrieve order data.');
          }

          params.order = order.order_data;
        }

        const response = await createPaymongoInvoiceApi(params);
        const payment = response.data.data;

        console.log(`paymentResponse: `, payment);

        this.setPaymentQRDetails({
          ...this.paymentQRDetails,
          paymentIntentId: payment?.paymentIntentId,
          description: payment?.description,
          qrCodeImageUrl: payment?.qrCodeImageUrl,
          qrGeneratedAt: payment?.qrGeneratedAt,
          externalId: payment?.externalId
        });

      } catch (e) {
        this.setShowPaymentQRModal(false);

        const errorResponse = e.response?.data || {};

        if (errorResponse?.message?.includes("PayMongo")) {
          this.$customSwal({
            stackedIcons: [
              { src: '/images/icon_temp-unavailable.svg', alt: 'Temporarily Unavailable Icon' },
              { src: '/images/mosaicpay-qrph.svg', alt: 'Mosaic Logo' },
            ],
            title: "Temporarily unavailable",
            text: "Please choose another payment method to complete the transaction or try again later. Error code: SYSTEM_ERROR",
            customClass: {
              icon: 'no-border-icon',
              title: 'custom-title-class',
              content: 'custom-content-class',
            },
            buttonText: "OK",
            className: 'center-btn'
          });
        } else {
          this.$customSwal({
            iconHtml: '<img src="/images/icon_close-circle.svg" alt="Something Went Wrong Icon"/>',
            title: "Something went wrong",
            text: "An unexpected error has occurred. Please try again. Error code: POS_INTERNAL_ERROR",
            customClass: {
              icon: 'no-border-icon',
              title: 'custom-title-class',
              content: 'custom-content-class',
            },
            buttonText: "OK",
            className: 'center-btn'
          });
        }

        throw e;
      }
    },

    async createPaymentInvoice(params) {
      try {
        if (OFFLOAD.sqliteOffloadReceipt) {
          const ob = new OrderBridge();
          const order = await ob.getOrderById(parseInt(params.orderId));

          if (isEmpty(order)) {
            throw new Error('Failed to retrieve order data.');
          }

          params.order = order.order_data;
        }

        const response = await createPaymentInvoice(params);

        const payment = response.data.data;

        this.setPaymentQRDetails({
          ...this.paymentQRDetails,
          invoiceUrl: payment.invoiceUrl,
          externalId: payment.externalId,
        });

        this.$emit("qrGenerated", this.mapPaymentInvoice(payment));
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        throw e;
      }
    },

    async cancelPaymentInvoice(externalId) {
      try {
        await cancelPaymentInvoice(externalId);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        throw e;
      }
    },

    // frequently called after a couple of seconds
    mapPaymentInvoice(payment) {
      return {
        id: payment?.paymentTypeId,
        type: payment?.type,
        payment_invoice_id: payment?.id,
        externalId: payment?.externalId,
        paymentIntentId: payment?.paymentIntentId,
        description: payment?.description,
        amount: payment?.amount,
        exact_amount: payment?.amount,
        change: 0,
        
        method: payment?.type == "xendit"
          ? `E-PAYMENT (${payment?.paymentChannel})` 
          : payment?.paymentType?.payment_name,

        type: payment?.type == "xendit" 
          ? "epayment" 
          : payment?.paymentType?.field_type,

        invoiceUrl: payment?.invoiceUrl,
        qrCodeImageUrl: payment?.qrCodeImageUrl,
        qrGeneratedAt: payment?.qrGeneratedAt,
        status: payment?.status,
        paymentChannel: payment?.paymentChannel,
        paymentType: payment?.paymentType,
        billNum: payment?.billNum,
      };
    },

    updateActivePaymentQR(payment) {
      if (!this.showPaymentQRModal) {
        return;
      }

      if (this.paymentQRDetails.externalId === payment.externalId) {
        this.setPaymentQRDetails({
          ...this.paymentQRDetails,
          status: payment.status,
        });
      }
    },
  },

  unmounted() {
    clearInterval(this.fetchPaymentInvoicesInterval);
  },
};
</script>

<style scoped>
/* SweetAlert custom styles */
.custom-title-class {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.custom-content-class {
  font-size: 14px;
  color: #555;
  text-align: center;
}
</style>