<template>
    <m-card
        class="m-product"
        :class="{
            unavailable: !isProductAvailable,
            'fallback-image': isFallbackImage,
        }"
        :label="product.isPromo ? product.promo_name : product.product_name"
        ratio="1:1"
        clickable
        @click="selectIfAvailable"
    >
        <template #default>
            <div class="m-product-image">
                <img
                    :src="productImage"
                    :style="{ opacity: isFallbackImage ? fallbackImageOpacity : 1 }"
                    @error="hasImageError = true"
                    />
            </div>
            <div
                v-if="product.hasModifier == 1 || (product.is_customize == 1 && enableParentChildClassification)"
                class="m-product-modifier-indicator"
                >&nbsp;
            </div>
        </template>
    </m-card>
</template>

<script>
import MCard from '@/spa/components/common/MCard';
import { ENABLE_PARENT_CHILD_CLASSIFICATION } from "@/spa/constants";

export default {
    name: 'MProduct',

    components: { MCard },

    props: {
        product: {
            type: Object,
            required: true,
        },
        isProductAvailable: {
            type: Boolean,
            required: true
        },
        fallbackImage: {
            type: String,
            default: '/images/item-placeholder.jpg',
        },
        fallbackImageOpacity: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            hasImageError: false,
        };
    },

    computed: {
        productImage() {
            if (this.hasImageError) return this.fallbackImage;

            const hostRoot = window.location.origin;
            const [https, domain, com] = hostRoot.split('.');

            let baseUrl = window.APP_ENV === 'production'
                ? 'https://admin.mosaic-pos.com/'
                : `${https}-admin.${domain}.${com}/`;

            let fileName = this.product.image;

            if (this.product.s3_file_key && window.awsCloudfrontUrl) {
                baseUrl = window.awsCloudfrontUrl;
                fileName = this.product.s3_file_key;
            }

            return this.product.image
                ? `${baseUrl}${fileName}`
                : this.fallbackImage;
        },

        isFallbackImage() {
            return this.productImage === this.fallbackImage;
        },

        enableParentChildClassification() {
            return ENABLE_PARENT_CHILD_CLASSIFICATION;
        }
    },

    methods: {
        selectIfAvailable() {
            if (this.isProductAvailable) {
                this.$emit('productSelected', this.product);
            }
        },
    },
};
</script>

<style scoped>
div.m-product {
    width: 160px;
    position: relative;
    overflow: hidden;
}

div.m-product .m-product-image > img {
    width: 100%;
    max-height: 96px;
    object-fit: cover;
    height: auto;
    object-fit: cover;
    object-position: center center;
}

div.m-product.unavailable {
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: grid;
    place-content: center;
    font-weight: bold;
    color: white;
}

div.m-product.unavailable::after{
    content: "Unavailable";
    position: absolute;
}

.m-product-modifier-indicator {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  color: var(--orange);
  font-style: italic;
  min-width: 37px;
  min-height: 43px;
}
</style>

<style>
div.m-product .m-card__body {
    align-self: stretch;
}
</style>