<template>
    <base-modal
        v-bind="$attrs"
        min-width="50"
        :hasConfirm=false
        :hasCancel=true
        cancelLabel="Close"
        >

        <template v-slot:title>Print Bill Void</template>
        <div class="void-slip-content" v-html="voidSlipHtml">

        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';

export default {
    name: 'VoidSlipModal',

    props: {
        voidSlipHtml: {
            type: String,
            default: '',
        },
    },

    components: {
        BaseModal,
    },

    data() {
        return {

        };
    },
}
</script>

<style scoped>
.void-slip-content {
    max-height: 70vh;
}
</style>