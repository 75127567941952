<template>
    <div
        v-if="tableStyles.length"
        class="m-arranged-tables"
        :style="{
            backgroundColor,
            width: containerWidth,
        }"
        >
        <div
            v-for="(table, index) in tables"
            class="positioned-table"
            :style="tableStyles[index]"
            >

            <m-table
                :table="table"
                :occupied="occupiedTables[table.table_id]"
                :key="`table-${table.table_id}`"
                :isTarget="targetTables.includes(table.table_id)"
                :selected="isSelected(table.table_id)"
                :x-params="xParams"
                :y-params="yParams"
                :class="{
                    disabled: (table.table_id in occupiedTables && disableOccupiedTables)
                        || disabledTableIds.includes(table.table_id)
                        || disableUnoccupiedTables && !occupiedTables[table.table_id],
                    [`m-table-${table.table_id}`]: true,
                }"
                :isMoveItem="isMoveItem"
                :item-data="itemData"
                v-bind="$attrs"
                :moveItemQty="moveItemTableQty[table.table_id]"
            />
        </div>
    </div>
</template>

<script>
import { map, compact } from 'lodash';

import MTable from '@/spa/components/common/MTable';

export default {
    name: 'MArrangedTables',

    components: { MTable },

    props: {
        tables: {
            type: Array,
            required: true,
        },

        occupiedTables: {
            type: Object,
            required: true,
        },

        activeTableId: {
            type: [Number, String],
            default: null,
        },

        activeTableIds: {
            type: Array,
            default: () => [],
        },

        disableOccupiedTables: {
            type: Boolean,
            default: false,
        },

        isMoveItem: {
            type: Boolean,
            default: false,
        },

        boxSizeX: {
            type: Number,
            default: 180,
        },

        boxSizeY: {
            type: Number,
            default: 120,
        },

        padding: {
            type: Number,
            default: 16,
        },

        backgroundColor: {
            type: String,
            default: '#fff',
        },

        disabledTableIds: {
            type: Array,
            default: () => [],
        },

        disableUnoccupiedTables: {
            type: Boolean,
            default: false,
        },

        targetTables: {
            type: Array,
            default: [],
        },

        itemData: {
            type:Object,
            default: {},
        },

        moveItemTableQty: {
            type: Object,
            default: {}
        },

        multiSelect: {
            type: Boolean,
            default: false,
        },

        containerWidth: {
            type: String,
            default: '60vw',
        },
    },

    computed: {
        tablePositions() {
            return map(this.tables, 'table_position');
        },

        xParams() {
            const xArr = compact(map(this.tablePositions, 'attrs.x').sort((a, b) => a - b));
            const min = xArr[0] - this.padding;
            const max = xArr[xArr.length - 1] + this.boxSizeX + this.padding;
            return { min, max, range: max - min };
        },

        yParams() {
            const yArr = compact(map(this.tablePositions, 'attrs.y').sort((a, b) => a - b));
            const min = yArr[0] - this.padding;
            const max = yArr[yArr.length - 1] + this.boxSizeY + this.padding;
            return { min, max, range: max - min };
        },

        tableStyles() {
            return map(this.tables, (table, index) => this.getStyle(index));
        },
    },

    methods: {
        isSelected(tableId) {
            if (this.multiSelect) {
                return this.activeTableIds.includes(tableId);
            } else {
                return this.activeTableId === tableId;
            }
        },

        getStyle(index) {
            const table = this.tables[index];

            let rotate, scaleX, scaleY;
            if (table.table_position) {
                rotate = table.table_position.attrs.rotation ? `rotate(${table.table_position.attrs.rotation}deg)` : '';
                scaleX = table.table_position.attrs.scaleX ? `scaleX(${table.table_position.attrs.scaleX})` : '';
                scaleY = table.table_position.attrs.scaleY ? `scaleY(${table.table_position.attrs.scaleY})` : '';
            }

            const { x = 0, y = 0 } = table.table_position.attrs;

            return {
                left: (x - this.xParams.min) / this.xParams.range * 100 + '%',
                top: (y - this.yParams.min) / this.yParams.range * 100 + '%',
                transform: [rotate, scaleX, scaleY].join(' ').trim() || 'none',
            };
        },
    },
};
</script>

<style scoped>
.m-arranged-tables {
    position: relative;
    min-height: 600px;
    height: calc(100vh - 300px);
    background-color: white;
    border-radius: 16px;
}

.positioned-table {
    position: absolute;
}

.m-table.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.8;
}
</style>