<template>
    <base-modal
        v-bind="$attrs"
        min-width="600px"
        min-height="400px"
        :has-confirm="true"
        cancel-color="primary"
        cancel-label="Close"
        confirm-color="danger"
        confirm-label="Update"
        @confirm="saveProductPrices"
        @before-open="parseProduct"
    >
        <template v-slot:title>Product Name: {{ productName }}</template>

        <hr />
        <v-data-table
            :headers="headers"
            :items="prices"
            :hide-default-footer="true"
            disable-pagination
            >
            <template #item-service_type_name="item">
                <strong class="ml-1">
                    {{ item.service_type_name }}
                </strong>
            </template>

            <template #item-channel_name="item">
                {{ item.channel_name }}
            </template>

            <template #item-prod_price="item">
                <select v-model="item.is_available" @change="updateItem(item)" class="form-control">
                    <option :value="1" v-text="'Available'" />
                    <option :value="0" v-text="'Unavailable'" />
                </select>
            </template>
        </v-data-table>
    </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import { get } from 'lodash';
import cloneDeep from 'rfdc/default';
import VDataTable from 'vue3-easy-data-table';

export default {
    components: {
        BaseModal,
        VDataTable,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            headers: [
                { text: 'Service Types', value: 'service_type_name', sortable: false },
                { text: 'Service Channel', value: 'channel_name', sortable: false },
                { text: 'Availability', value: 'prod_price', sortable: false },
            ],
            prices: [],
        };
    },

    computed: {
        productName() {
            return get(
                this.product,
                'product_name',
                this.product.id
            );
        }
    },

    methods: {
        parseProduct() {
            this.prices = cloneDeep(this.product.pricings);
        },

        updateItem(item){
            const matchingIndex = this.prices.findIndex(product => {
                return (
                    product.product_detail_id == item.product_detail_id &&
                    product.service_channel_id == item.service_channel_id &&
                    product.service_type_id == item.service_type_id
                );
            });

            if (matchingIndex == -1) return;

            this.prices[matchingIndex].is_available = item.is_available;
        },

        async saveProductPrices() {
            const result = await this.$openApproverModal();
            if (!result.success) return;

            this.$emit("saveProductPrices", this.prices);
        }
    }
};
</script>