<template>
  <base-modal
    v-bind="$attrs"
    min-width="600px"
    min-height="100px"
    :has-cancel="true"
    @confirm="$emit('save', this.form)"
    >
    <template v-slot:title>Add Cash Float</template>

    <hr />

    <div class="form-group mt-4">
      <div class="form-label">
        Terminal
        <span class="text-danger">
          * <i v-text="errors.terminal"></i>
        </span>
      </div>
      <select v-model="form.terminal" disabled class="form-control">
        <option :value="terminalId">Terminal {{ terminalId }}</option>
      </select>

      <div class="form-label">
        Cashier
        <span class="text-danger">
          * <i v-text="errors.cashier_id"></i>
        </span>
      </div>
      <select v-model="form.cashier_id" disabled class="form-control">
        <option :value="id" v-text="username" />
      </select>

      <div class="form-label">Type
        <span class="text-danger">
          * <i v-text="errors.type"></i>
        </span>
      </div>
      <select v-model="form.type" class="form-control" :disabled="floatActivity">
        <option value="Additional Fund">Additional Fund</option>
        <option value="Withdraw">Withdraw</option>
      </select>

      <div class="form-label">
        Reason
        <span class="text-danger">
          * <i v-text="errors.float_reason_id"></i>
        </span>
      </div>
      <select v-model="form.float_reason_id" class="form-control">
        <option
          v-for="reason in reasonOptions"
          :key="reason.id"
          :value="reason.id" 
          v-text="reason.reason_name"
          />
      </select>

      <div class="form-label">
        Amount
        <span class="text-danger">
          * <i v-text="errors.amount"></i>
        </span>
      </div>
      <input
        type="number"
        v-model="form.amount"
        class="form-control"
        />
    </div>
  </base-modal>
</template>

<script>
import { getFloatReasons } from '@/spa/services/cashier-service';
import BaseModal from './BaseModal.vue'
import { mapState } from 'vuex';
export default {
  props: {
    floatActivity: Object,
    errors: Object,
  },
  components: { BaseModal },
  computed: {
    ...mapState('user', ['id','username','terminalId']),
    reasonOptions() {
      const { form: { type } } = this;

      return this.floatReasons.filter(f => f.type == type);
    }
  },
  watch: {
    floatActivity() {
      if (this.floatActivity) {
        const data = {
          id: this.floatActivity.id,
          terminal: this.floatActivity.terminal,
          cashier_id: this.floatActivity.cashier_id,
          type: this.floatActivity.type,
          float_reason_id: this.floatActivity.float_reason_id,
          amount: this.floatActivity.amount,
        };

        this.form = data;
      } else {
        this.form = {
          terminal: this.terminalId ?? 1,
          cashier_id: this.id,
          type: 'Additional Fund',
          float_reason_id: this.floatReasons?.[0]?.id,
          amount: 0,
        };
      }
    },
    reasonOptions(val) {
      if (val.length && !val.find(i => i.id == this.form.float_reason_id)) {
        this.form.float_reason_id = val[0].id;
      }
    },
  },
  mounted() {
    this.fetchFloatReasons();
    this.form.cashier_id = this.id;
    this.form.terminal = this.terminalId;
  },
  data() {
    return {
      form: {
        terminal: 1,
        cashier_id: null,
        type: 'Additional Fund',
        float_reason_id: null,
        amount: 0,
      },
      'floatReasons': [],
    };
  },
  methods: {
    async fetchFloatReasons() {
      try {
        const { data: { floatReasons } } = await getFloatReasons();
        this.floatReasons = floatReasons;
        if (floatReasons.length) {
          this.form.float_reason_id = floatReasons[0].id;
        }
      } catch (e) {
        this.floatReasons = [];
        console.log(e);
      }
    },
  },
}
</script>