<template>
  <div class="d-flex">
    <div class="col-8 p-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <MPageTitle icon="fa-store" :title="accountName" />

        <div
          class="d-flex align-items-center"
          :class="{ 'opacity-0': !isOnline }"
        >
          <MSearchBar
            placeholder="Type to search"
            @changed="searchOrder"
            :initialValue="searchInitialvalue"
          />
          <MNotificationIcon
            class="ml-2"
            :count="pendingCount"
            @click="setFilterToPending"
          />
        </div>
      </div>

      <div class="row" :class="{ 'opacity-0': !isOnline }">
        <div class="col-4">
          <div class="label">Status</div>

          <VueMultiselect
            v-model="selectedStatusFilter"
            :options="statusFilters"
            :showLabels="false"
            label="name"
            @select="filterOrders"
          />
        </div>
      </div>

      <div class="items-container" :class="classes">
        <template v-if="!isOnline">
          <MIconMessage icon="fa-wifi" message="No Internet Connection" />
        </template>

        <template v-else>
          <MIconMessage
            v-if="isEmpty && !loading"
            icon="fa-box-open"
            :message="emptyMessage"
          />

          <div v-if="loading" class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>

          <MDeliveryItem
            v-else
            v-for="(order, key) in orders"
            :key="key"
            :item="order"
            :selected="isSelected(order.orderId)"
            :delivery="channelName"
            @click="toggleOrder(order.orderId)"
            @enableOrder="enableOrder(order.orderId)"
            @orderAccepted="orderAccepted(order)"
            @orderRejected="showRejectOrderModal = true"
            @orderCancelled="showCancelOrderModal = true"
            @orderReadyForDelivery="orderReadyForDelivery(order)"
            @orderCollected="orderCollected(order)"
            @orderDelivered="orderDelivered(order)"
            @orderExtendPreparationTime="showTimeExtensionModal = true"
            @orderRejectedWithoutReason="orderRejected"
          />
        </template>
      </div>

      <MPagination
        v-if="hasPagination"
        :pageMeta="pageMeta"
        @onPageChange="changePage($event)"
        ref="pagination"
      />
    </div>
    <div class="col-4 bg-white py-2 pl-2 pr-4">
      <MDeliverySalesTotal
        :amount="totalDeliverySales"
        :delivery="channelName"
      />

      <OrdersPanel
        v-if="selectedOrderDetails"
        :order-id="selectedOrderDetails._id"
        :orders="selectedOrderDetails.orders"
        :kots="selectedOrderDetails.kots"
        :order-detail="selectedOrderDetails.orderDetail"
        :billNumber="selectedOrderDetails.bill_num"
        :channelName="selectedOrderDetails.channelName"
        serviceType="Delivery"
        preview
        delivery
        :deliveryStatus="selectedOrder?.status"
        :lockEditOrder="lockEditOrder"
        consolidate-orders
        @printKOT="handlePrintKOT"
        @printOR="handlePrintOR"
        @printVoidSlip="handlePrintVoidSlip"
        :ableToPrint="ableToPrint"
        @updateOrders="updateOrders"
        @refreshSelectedOrder="refreshSelectedOrder"
      />
    </div>
  </div>

  <OrderRejectReasonsModal
    v-if="withRejectReasons"
    v-model="showRejectOrderModal"
    :order="selectedOrder"
    :delivery="channelName"
    @orderRejected="orderRejected"
  />

  <OrderCancelReasonsModal
    v-model="showCancelOrderModal"
    :order="selectedOrder"
    :delivery="channelName"
    @orderCancelled="orderCancelled"
  />

  <DynamicOptionsModal
    v-model="showTimeExtensionModal"
    :title="timeExtensionHeader"
    label="How much more time do you need?"
    :options="extendOptions"
    @confirmed="orderPreparationTimeExtended"
  />
</template>

<script>
import {isEmpty} from "lodash";
import MDeliveryItem from "@/spa/components/common/MDeliveryItem";
import MPagination from "@/spa/components/common/MPagination";
import MSearchBar from "@/spa/components/common/MSearchBar";
import MNotificationIcon from "@/spa/components/common/MNotificationIcon";
import MPageTitle from "@/spa/components/common/MPageTitle";
import MDeliverySalesTotal from "@/spa/components/common/MDeliverySalesTotal";
import MIconMessage from "@/spa/components/common/MIconMessage";
import OrdersPanel from "@/spa/components/panels/OrdersPanel";
import VueMultiselect from "vue-multiselect";
import {
  getOrders,
  getOrder,
  orderAccepted,
  orderReadyForDelivery,
  orderCollected,
  orderDelivered,
  orderRejected,
  orderCancelled,
  orderExtendReadyTime,
  orderItemVoided,
} from "@/spa/services/delivery-service";
import NetworkMixin from "@/spa/components/mixins/NetworkMixin";
import { mapMutations, mapGetters, mapState } from "vuex";
import PrintMixin from "@/spa/components/mixins/PrintMixin";
import { startCase } from "lodash";
import OrderRejectReasonsModal from "@/spa/components/modals/OrderRejectReasonsModal";
import OrderCancelReasonsModal from "@/spa/components/modals/OrderCancelReasonsModal";
import DynamicOptionsModal from "@/spa/components/modals/DynamicOptionsModal";
import {OFFLOAD} from "@/spa/constants";

export default {
  name: "DeliveryPage",

  mixins: [NetworkMixin, PrintMixin],

  components: {
    MDeliveryItem,
    MPagination,
    MSearchBar,
    MNotificationIcon,
    MPageTitle,
    MDeliverySalesTotal,
    MIconMessage,
    OrdersPanel,
    VueMultiselect,
    OrderRejectReasonsModal,
    OrderCancelReasonsModal,
    DynamicOptionsModal,
  },

  props: {
    lastDeliveryOrderId: {
      type: Number,
    },

    shortOrderId: {
      type: Number,
    },

    channelName: {
      type: String,
    },

    updatedOrderId: {
      type: Number,
    },
  },

  data() {
    return {
      selectedStatusFilter: { name: "All", value: null },

      printDisabledStatuses: ["PLACED", "FAILED", "REJECTED"],

      itemPerPage: 10,

      selectedOrderDetails: null,

      orderResponse: null,

      currentPage: 1,

      pageInterval: null,

      revalidateTimer: 5000,

      accountName: tempAccountName,

      search: "",

      searchInitialvalue: "",

      updatingOrders: [],

      showRejectOrderModal: false,

      showCancelOrderModal: false,

      showTimeExtensionModal: false,

      extendOptions: [
        { id: 1, value: 5, display: "+ 5 Mins" },
        { id: 2, value: 10, display: "+ 10 Mins" },
        { id: 3, value: 15, display: "+ 15 Mins" },
        { id: 4, value: 20, display: "+ 20 Mins" },
        { id: 5, value: 25, display: "+ 25 Mins" },
        { id: 6, value: 30, display: "+ 30 Mins" },
      ],

      overrideActiveOrders: false,

      loading: true,
    };
  },

  computed: {
    ...mapGetters(["activeOrder"]),

    ...mapState("global", ["deliveryResumePages"]),

    orders() {
      // if fetch order haven't completed yet
      if (!this.orderResponse?.data) {
        return [];
      }

      // if there is no local updating of orders
      // e.g cashier manual accept or reject order
      if (!this.updatingOrders.length) {
        return this.orderResponse.data;
      }

      // if there are local updating of order let's show that request is completed
      return this.orderWithLocalUpdates;
    },

    orderWithLocalUpdates() {
      return this.orderResponse.data.map((order) => {
        const currentOrderUpdating = this.updatingOrders.find(
          (updatingOrder) => updatingOrder.id === order.id
        );

        if (currentOrderUpdating) {
          // since we are polling the new orders, we need to check if the status, delivery time, etc has changed or not

          // check delivery time changed
          if (
            currentOrderUpdating.readyTimeUpdated &&
            currentOrderUpdating.readyTime > order.readyTime
          ) {
            return {
              ...order,
              readyTime: currentOrderUpdating.readyTime,
            };
          }

          // check status changed
          if (
            currentOrderUpdating.newStatus &&
            order.status === currentOrderUpdating.status
          ) {
            return {
              ...order,
              status: currentOrderUpdating.newStatus,
            };
          }

          this.removeUpdatingOrder(currentOrderUpdating.id);
        }

        return order;
      });
    },

    isEmpty() {
      return this.orders.length ? false : true;
    },

    hasPagination() {
      return (
        this.orderResponse?.meta?.total > this.itemPerPage && this.isOnline
      );
    },

    totalDeliverySales() {
      if (!this.isOnline) {
        return "--";
      }

      return this.orderResponse?.meta?.totalSales ?? "--";
    },

    classes() {
      let classes = "";

      if (this.isEmpty || !this.isOnline) {
        classes += "d-flex d-flex justify-content-center align-items-center ";
      }

      if (this.hasPagination) {
        classes += "has-pagination";
      }

      return classes;
    },

    pendingCount() {
      return this.orderResponse?.meta?.pending;
    },

    params() {
      let params = { page: this.currentPage };

      if (this.selectedStatusFilter?.value) {
        params = { ...params, status: this.selectedStatusFilter.value };
      }

      if (this.search) {
        params = { ...params, search: this.search };
      }

      return params;
    },

    pageMeta() {
      return {
        from: this.orderResponse?.meta?.from,
        to: this.orderResponse?.meta?.to,
        total: this.orderResponse?.meta?.total,
        pageCount: Math.ceil(
          this.orderResponse?.meta?.total / this.itemPerPage
        ),
      };
    },

    emptyMessage() {
      return `No ${this.channelName} Order`;
    },

    ableToPrint() {
      const updatingOrder = this.updatingOrders.find(
        (updatingOrder) => updatingOrder.id === this.selectedOrder?.id
      );

      if (updatingOrder) {
        return !this.printDisabledStatuses.includes(updatingOrder.newStatus);
      }

      if (this.selectedOrder?.status === 'CANCELLED' && !this.selectedOrder?.isAccepted) {
        return false;
      }

      return !this.printDisabledStatuses.includes(this.selectedOrder?.status);
    },

    selectedOrder() {
      if (!this.selectedOrderDetails) {
        return null;
      }

      return this.orders.find(
        (order) => order.orderId === this.selectedOrderDetails._id
      );
    },

    pendingStatuses() {
      if (this.channelName === "Grab") {
        return "ACCEPTED,DRIVER_ALLOCATED,DRIVER_ARRIVED,COLLECTED,PLACED,ORDER_PREPARED";
      }

      return "ACCEPTED,PLACED,ORDER_PREPARED";
    },

    statusFilters() {
      if (this.channelName === "Grab") {
        return [
          { name: "All", value: null },
          {
            name: "Pending",
            value: this.pendingStatuses,
          },
          { name: "Placed", value: "PLACED" },
          { name: "Accepted", value: "ACCEPTED" },
          { name: "Driver Allocated", value: "DRIVER_ALLOCATED" },
          { name: "Driver Arrived", value: "DRIVER_ARRIVED" },
          { name: "Collected", value: "COLLECTED" },
          { name: "Delivered", value: "DELIVERED" },
          { name: "Failed", value: "FAILED" },
          { name: "Cancelled", value: "CANCELLED" },
        ];
      } else if (this.channelName === 'AlipayDStore') {
        return [
          { name: "All", value: null },
          { name: "Accepted", value: "Accepted" },
          { name: "Cancelled", value: "Cancelled" },
        ];
      }

      return [
        { name: "All", value: null },
        {
          name: "Pending",
          value: this.pendingStatuses,
        },
        { name: "Placed", value: "PLACED" },
        { name: "Accepted", value: "ACCEPTED" },
        { name: "Order Prepared", value: "ORDER_PREPARED" },
        { name: "Collected", value: "COLLECTED" },
        { name: "Rejected", value: "REJECTED" },
        { name: "Cancelled", value: "CANCELLED" },
      ];
    },

    timeExtensionHeader() {
      if (Number(this.selectedOrder?.readyTime) < 0) {
        return `${Math.abs(this.selectedOrder?.readyTime)} mins elapsed ${
          this.selectedOrder?.id
        }`;
      }

      return `${this.selectedOrder?.readyTime} mins to prepare ${this.selectedOrder?.id}`;
    },

    // Temporarily disabled until we create a proper handling for grab edit order
    lockEditOrder() {
      // const forcedLockedStatuses = [
      //   "PLACED",
      //   "COMPLETED",
      //   "COLLECTED",
      //   "CANCELLED",
      //   "REJECTED",
      // ];

      // if (forcedLockedStatuses.includes(this.selectedOrder?.status)) {
      //   return true;
      // }

      // if (this.channelName === "Grab") {
      //   return false;
      // }

      return true;
    },

    withRejectReasons() {
      return this.channelName === "FoodPanda";
    },
  },

  methods: {
    ...mapMutations([
      "setActiveOrder",
      "setActiveOrderId",
      "setActiveServiceTypeId",
    ]),

    ...mapMutations("global", ["setDeliveryResumePages"]),

    toggleOrder(id) {
      if (this.isSelected(id)) {
        this.selectedOrderDetails = null;
        return;
      }

      this.getOrder(id);
    },

    enableOrder(id) {
      if (this.isSelected(id)) {
        return;
      }

      this.getOrder(id);
    },

    async getOrder(id) {
      this.selectedOrderDetails = null;
      const ob = new OrderBridge();

      try {
        if (OFFLOAD.sqliteOffloadReceipt) {
          const order = await ob.getOrderById(parseInt(id));

          if (isEmpty(order)) {
            return
          }

          this.selectedOrderDetails = JSON.parse(order.order_data);

        } else {
          const response = await getOrder(id);
          this.selectedOrderDetails = response.data;
        }

        if (this.overrideActiveOrders) {
          this.overrideActiveOrders = false;
          this.selectedOrderDetails.orders = this.activeOrder.orders;
        }

        this.setActiveOrderId(this.selectedOrderDetails._id);
        this.setActiveOrder(this.selectedOrderDetails);
        this.setActiveServiceTypeId(this.selectedOrderDetails.serviceTypeId);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getOrders() {
      try {
        const response = await getOrders(this.params, this.channelName);
        this.orderResponse = response.data;
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.loading = false;
      }
    },

    isSelected(orderId) {
      return orderId === this.selectedOrderDetails?._id;
    },

    changePage(page) {
      this.currentPage = page;

      this.startAutoRevalidation();
    },

    startAutoRevalidation() {
      this.getOrders();

      clearInterval(this.pageInterval);

      this.pageInterval = setInterval(() => {
        if (this.isOnline) {
          this.getOrders();
        }
      }, this.revalidateTimer);
    },

    searchOrder(search) {
      this.resetPagination();
      this.selectedOrderDetails = null;
      this.search = search;

      this.startAutoRevalidation();
    },

    setFilterToPending() {
      this.resetPagination();
      this.selectedOrderDetails = null;

      this.selectedStatusFilter = {
        name: "Pending",
        value: this.pendingStatuses,
      };

      this.startAutoRevalidation();
    },

    filterOrders() {
      this.resetPagination();
      this.selectedOrderDetails = null;

      this.startAutoRevalidation();
    },

    resetPagination() {
      if (this.hasPagination) {
        this.$refs.pagination.page = 1;
      }
    },

    handlePrintKOT() {
      const isVoided = this.selectedOrder.status === 'CANCELLED' ? true : false;

      this.printAllKOT(
        this.selectedOrderDetails._id,
        this.selectedOrderDetails.kots,
        true,
        isVoided
      );
    },

    handlePrintOR() {
      this.printReceipt("settlement", this.selectedOrderDetails);
    },

    handlePrintVoidSlip() {
      this.printDeliveryVoidBilled(this.selectedOrderDetails)
    },

    async orderAccepted(order) {
      this.updatingOrders.push({ ...order, newStatus: "ACCEPTED" });

      this.printAllKOT(
          this.selectedOrderDetails._id,
          this.selectedOrderDetails.kots
      );


      this.selectedOrderDetails.isBilled = true;

      try {
        await orderAccepted(order.id, this.channelName);
        this.selectedOrderDetails.isBilled = true;
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(order.id);
        this.selectedOrderDetails.isBilled = false;
        throw e;
      }
    },

    async orderRejected(reason = null) {
      const result = await this.$openApproverModal();
      if (result.cancelled) return;

      this.updatingOrders.push({
        ...this.selectedOrder,
        newStatus: "REJECTED",
      });

      this.showRejectOrderModal = false;

      try {
        await orderRejected(this.selectedOrder.id, reason, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(this.selectedOrder.id);
        throw e;
      }
    },

    async orderCancelled(reason) {
      const result = await this.$openApproverModal();
      if (result.cancelled) return;

      this.updatingOrders.push({
        ...this.selectedOrder,
        newStatus: "CANCELLED",
      });

      this.showCancelOrderModal = false;

      try {
        await orderCancelled(this.selectedOrder.id, reason, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(this.selectedOrder.id);
        throw e;
      }
    },

    async orderReadyForDelivery(order) {
      this.updatingOrders.push({ ...order, newStatus: "ORDER_PREPARED" });

      try {
        await orderReadyForDelivery(order.id, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(order.id);
        throw e;
      }
    },

    async orderCollected(order) {
      this.updatingOrders.push({ ...order, newStatus: "COLLECTED" });

      try {
        await orderCollected(order.id, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(order.id);
        throw e;
      }
    },

    async orderDelivered(order) {
      this.updatingOrders.push({ ...order, newStatus: "DELIVERED" });

      try {
        await orderDelivered(order.id, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(order.id);
        throw e;
      }
    },

    async orderPreparationTimeExtended(adjustment) {
      const tempReadyTime = this.selectedOrder.readyTime;
      const extension = adjustment.value;
      const readyTime = this.selectedOrder.readyTime + extension;

      this.showTimeExtensionModal = false;

      this.updatingOrders.push({
        ...this.selectedOrder,
        readyTime,
        readyTimeUpdated: true,
      });

      try {
        await orderExtendReadyTime({
          shortOrderId: this.selectedOrder.id,
          extension: extension,
        });
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.removeUpdatingOrder(this.selectedOrder.id);
        this.selectedOrder.readyTime = tempReadyTime;
        throw e;
      }
    },

    removeUpdatingOrder(id) {
      this.updatingOrders = this.updatingOrders.filter(
        (updatingOrder) => updatingOrder.id !== id
      );
    },

    updateOrders() {
      this.setDeliveryResumePages({
        ...this.deliveryResumePages,
        [this.channelName.toLowerCase()]: this.currentPage,
      });

      this.$router.push({
        name: "update-orders",
        params: { orderId: this.selectedOrderDetails._id },
      });
    },

    async refreshSelectedOrder(product) {
      this.selectedOrderDetails.orders = this.activeOrder.orders;

      try {
        await orderItemVoided(this.selectedOrder.id, product, this.channelName);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        throw e;
      }
    },
  },

  watch: {
    lastDeliveryOrderId(newValue) {
      if (newValue) {
        this.toggleOrder(newValue);
      }
    },

    channelName() {
      this.orderResponse = [];
      this.selectedOrderDetails = [];
    },
  },

  created() {
    if (this.shortOrderId) {
      this.searchInitialvalue = this.shortOrderId;
      this.search = this.shortOrderId;
    }

    if (this.updatedOrderId) {
      this.currentPage =
        this.deliveryResumePages[this.channelName.toLowerCase()];

      this.setDeliveryResumePages({
        ...this.deliveryResumePages,
        [this.channelName.toLowerCase()]: 1,
      });
    }

    this.startAutoRevalidation();
  },

  mounted() {
    if (this.lastDeliveryOrderId) {
      this.toggleOrder(this.lastDeliveryOrderId);
    }

    if (this.updatedOrderId) {
      this.toggleOrder(this.updatedOrderId);
      this.overrideActiveOrders = true;
    }
  },

  unmounted() {
    clearInterval(this.pageInterval);
  },
};
</script>

<style scoped>
.label {
  font-size: 11px;
  font-weight: 600;
  color: #797979;
}
.items-container {
  height: calc(100vh - 225px);
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 10px;
}

.items-container.has-pagination {
  height: calc(100vh - 295px);
}
</style>
