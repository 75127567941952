// Import dependencies
import axios from "axios";
import axiosRetry from 'axios-retry';
import localForage from "localforage";
import { setupCache } from "axios-cache-adapter";
import { toNumbersDeep } from '../store/orders';
import vuexStore, { clearStorage } from '@/spa/plugins/vuex';
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {OFFLOAD} from "@/spa/constants";

export const store = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: "mosaic-pos-spa-axios",
});

export const clearAxiosCache = () => store.clear();

const TIMEOUT = 30000;
const HEALTH_CHECK_TIMEOUT = 10000;
const HEALTH_CHECK_SLEEP = 5000;
const MAX_RETRIES = 5;
const MAX_SQLITE_REQUEST = 5;
let requestCounter  = 0;
let sqliteRequestCounter  = 0;
let healthCheckFailed = false;

const incrementRequestCounter = (config) => {
    if (config.url == route("orders.broadcast.offline")) {
        requestCounter++;
    }
    if (config.url.startsWith('/sync-offload-orders')) {
        sqliteRequestCounter++;
    }
};

const decrementRequestCounter = (config) => {
    if (config.url == route("orders.broadcast.offline")) {
        requestCounter--;
    }
    if (config.url.startsWith('/sync-offload-orders')) {
        sqliteRequestCounter--;
    }
};

export const hasPendingRequests = () => requestCounter > 0;
export const isSqliteRequestLimitNotReached = () => sqliteRequestCounter <= MAX_SQLITE_REQUEST;

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 60 * 60 * 1000, // 1 hr
  exclude: {
    query: false,
  },
  store,
  key: ({ url }) => `${window.userId}-${url}`,
  invalidate: async (config, request) => {
    if (request.forceRefresh) {
      await config.store.removeItem(config.uuid)
    }
  },
});

const api = axios.create({
  adapter: cache.adapter,
  timeout: TIMEOUT,
});

axiosRetry(api, {
  retries: MAX_RETRIES,
  retryDelay: axiosRetry.exponentialDelay
});

const responseHandler = response => {
    if (response.status === 401) {
      window.location.href = `/spa/${window.locationId}/${window.userId}`;
    }

    return {
      ...response,
      data: toNumbersDeep(response.data),
    };
};

const errorHandler = async error => {
    if (error.response?.status === 401) {
      await window.Swal.fire({
        title: 'Session Expired',
        text: 'Your session has expired. Please login again.',
      });

      vuexStore.commit('user/resetState');
      vuexStore.commit('global/resetState');
      vuexStore.commit('modals/resetState');
      vuexStore.commit('settings/resetState');
      vuexStore.commit('resetState');
      sessionStorage.clear();
      localStorage.clear();
      await clearStorage();
      await clearAxiosCache();
      window.location.href = '/';
    }

    return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => {
    decrementRequestCounter(response.config);
    return responseHandler(response)
  },
  (error) => {
    decrementRequestCounter(error.config);
    return errorHandler(error)
  },
);

export async function checkCloudConnection(skip = true) {
  if (healthCheckFailed || window.enableOfflineMode
      || (skip && OFFLOAD.sqliteOffloadReceipt && !isNetworkStable())) {
    return false;
  }

  try {
    await axios.head(route('health.check'), {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      timeout: HEALTH_CHECK_TIMEOUT,
    });
    return true;
  } catch (e) {
    healthCheckFailed = true;
    setTimeout(() => healthCheckFailed = false, HEALTH_CHECK_SLEEP);

    return false;
  }
}

async function createAuthSession(username, password) {
  return axios.post(route('initial.auth.login'), { username, password });
}

api.interceptors.request.use(async config => {
  incrementRequestCounter(config);

  const hasNoAuth = await localForage.getItem('hasNoAuth');
  if (!hasNoAuth) return config;

  const isConnected = await checkCloudConnection();
  if (!isConnected) return config;

  if (await localForage.getItem('isCreatingOnlineSession')) return config;

  console.info('Attempting to create online auth session');
  await localForage.setItem('isCreatingOnlineSession', true);
  const currentUsername = await localForage.getItem('offlineUsername');
  const password = await localForage.getItem(`${currentUsername}-pw`);
  try {
    await createAuthSession(currentUsername, password);
    await localForage.setItem('hasNoAuth', false);
    console.info('Online auth session established');
  } catch (error) {
    console.error('Failed to create online auth session, error:');
    console.error({ error });
  }

  return config;
});

export default api;
