<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label>Settings Page</label>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">App Info</label>
        </div>

        <ul class="list-group mt-2">
          <li class="list-group-item">
            <h5 class="mb-1">App version</h5>
            <small>{{ versionNumbers?.versionName || 'Unavailable' }}</small>
          </li>
          <li class="list-group-item">
            <h5 class="mb-1">Build number</h5>
            <small>{{ versionNumbers?.versionCode || 'Unavailable' }}</small>
          </li>
          <li class="list-group-item">
            <h5 class="mb-1">SQLite database file version</h5>
            <small>{{ versionNumbers?.databaseVersion || 'Unavailable' }}</small>
          </li>
          <li class="list-group-item">
            <h5 class="mb-1">WebView user agent string</h5>
            <small>{{ uaString }}</small>
          </li>
          <li class="list-group-item">
            <h5 class="mb-1">Webapp build timestamp</h5>
            <small>{{ webappBuildTimestamp || 'Unavailable' }}</small>
          </li>
        </ul>

        <div class="mt-3">
          <button class="btn btn-primary btn-lg" @click="handleS3Upload" :disabled="loading">
            <span v-if="!loading">Validate Data</span>
            <span v-else>
              <i class="fas fa-spinner fa-spin"></i> Sending data, please do not close this app...
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import {ACTIVE_POS_DATE, OFFLOAD} from "@/spa/constants";
import {sqliteOffloadGetStorageValue, TRANSACTION_STATUS_ID} from "@/mobile_bridge/offload/receipt-model";
import {storeToS3} from "@/spa/services/logger-service";
import {getSyncedOrders} from "@/spa/services/sync-service";
import { BackupUtilityBridge } from '../../../mobile_bridge/offload/backup';

export default {
  components: {
    SettingsSidebar
  },
  data() {
    return {
      uaString: window.navigator.userAgent,
      versionNumbers: window.MosaicPosAppVersionNumbers,
      webappBuildTimestamp: window.WEBAPP_BUILD_TIMESTAMP,
      loading: false,
    }
  },
  methods: {
    async handleS3Upload() {
      this.loading = true;
      const backupBridge = new BackupUtilityBridge();

      const s3Upload = this.uploadToS3()
      const sqliteUpload = backupBridge.uploadDatabase()

      try {
        await Promise.all([s3Upload, sqliteUpload]);

        Swal.fire({
          title: "Good job!",
          text: "Your transactions has been transmitted to the cloud for validation!",
          icon: "success"
        });
      } catch (e) {
        console.error(e);
        Swal.fire({
          title: "A problem occurred",
          text: "We were unable to send your transactions to the cloud. Please try uploading again.",
          icon: "success"
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadToS3() {
      if (OFFLOAD.sqliteOffloadReceipt && OFFLOAD.sqliteOffloadPOSFE1310) {
        const transactionsStatusIds = Object.values(TRANSACTION_STATUS_ID);
        const posDateBridge = new PosDateBridge();
        const business_date = await posDateBridge.getPosDate(window.locationId);
        const rb = new ReceiptBridge();
        const ob = new OrderBridge();

        const params = {
          where: { business_date }
        }

        const receipts = await rb.getReceipts(params);
        await storeToS3(receipts, "transactions", window.locationId);

        const orders = await ob.getPendingOrders(transactionsStatusIds.join(","));
        await storeToS3(orders, "SQLite-orders", window.locationId);
        
        return;
      }

      const syncedOrders = await getSyncedOrders();
      await storeToS3(syncedOrders, "transactions", window.locationId);
    },
  }
}
</script>
