<template>
  <!--cart-->
  <Transition name="cart">
    <cart-panel @show-summary="handleCartToggle" :items="consolidatedOrdersWithSeparators"
      :price="this.formattedTotals.total"></cart-panel>
  </Transition>
  <!--end cart-->

  <div class="bg-white orders-summary flex-fill" :class="cartDisplay">
    <div class="orders-panel py-4 d-flex flex-column " :class="{
      edit,
      preview,
      delivery,
      payment,
      locked: orderId && !isOwnOrder,
    }">
      <!--order summary-->
      <div class="orders-header d-md-block d-lg-none text-center">
        <div class="d-block w-100 pl-2 pr-2 pb-3">
          <span class="ml-3 fa-stack fa-1x mr-1 cursor-pointer cart-button float-left" @click="showCart">
            <i class="far fa-circle fa-stack-2x"></i>
            <i class="fas fa-angle-down fa-stack-1x"></i>
          </span>
          <h3>Order Summary</h3>
        </div>
      </div>
      <!--end order summary-->
      <div class="panel-header d-flex align-items-center">
        <div class="panel-box col-lg-3" :title="kotString ? `KOT#: ${fullKotString}` : 'Add details'"
          @click="isOrderDetailsModalOpen = true">
          <i class="fa fa-receipt" />
          <div class="header-label" :class="{ 'single-kot': kots.length === 1 }"
            v-html="kotString ? `KOT#:<br>${kotString}` : 'Add details'">
          </div>
        </div>

        <div class="panel-box col-lg-3" @click="openTableActionsModal">
          <i class="fa fa-chair" />
          <div v-if="hasTable" class="header-label">
            {{ activeTable.table_name || `Table ID: ${tableId}` }}
          </div>
          <div v-else class="header-label" :class="actualServiceType">
            {{ actualServiceType }}
          </div>
        </div>

        <div class="panel-box col-lg-3" @click="openPaxModal" v-if="hasTable">
          <i class="fa fa-user-alt" />
          <div class="header-label">{{ totalPax }}</div>
        </div>
        <div class="panel-box col-lg-3" v-else>
          <i class="fa fa-file-invoice-dollar" />
          <div class="header-label">
            Bill#<br>{{ billNumber || activeOrder.bill_num }}
          </div>
        </div>

        <div class="panel-box col-lg-3" @click="openChangeServiceTypeModal()">
          <i class="fa fa-concierge-bell" />
          <div class="header-label">
            {{ activeOrder.serviceType || serviceType }}
          </div>
        </div>
      </div>

      <div class="mb-2 d-flex flex-nowrap justify-content-between orders-table-header">
        <div v-for="header in headers" :key="header.label" :class="header.class" v-text="header.label"
          @click="isAdvancedTotals = !isAdvancedTotals" />
      </div>

      <div class="orders-container px-4">
        <div v-if="hasNoOrders" class="no-orders-placeholder">
          <i class="fa fa-box-open"></i>
          <h2>NO ITEMS</h2>
        </div>

        <template v-if="groupByKot || isAdvancedTotals">
          <template v-for="(kotOrders, kot) in kotGroupedOrders">
            <b class="kot-label mt-2 ml-3" v-text="`KOT #: ${kot == 'undefined' ? 'Pending' : kot}`" />

            <template v-for="(serviceTypes, serviceType) in kotOrders">
              <div class="panel-header bg-light">
                <div class="text-center text-primary py-1 font-weight-bold">
                  {{ serviceType }}
                </div>
              </div>
              <m-line-item v-for="(item, index) in serviceTypes" :key="`${item.product.id}-${index}`"
                :service-type="serviceType" :item="item" :readonly="preview || payment || (edit && kots.includes(item.kot))
                  " :locked="payment || lockEditOrder" :edit="edit"
                :is-advanced-totals="isAdvancedTotals" :merged-to-table-name="activeOrder.tableMergedToName"
                @addQuantity="addQuantity(item)" @subtractQuantity="subtractQuantity(item)"
                @removeLineItem="removeLineItem(item)" @addRequest="addRequest(item, $event)"
                @showItemAction="showItemAction(item, $event)" @changeService="changeService($event, item)"
                @voidItem="openLineItemVoidModal(item)" />

            </template>

            <hr />
          </template>
        </template>

        <template v-else>
          <template v-for="(serviceTypeItem, index) in serviceTypes">

            <template v-if="consolidatedOrders.some(
              (o) => o && o.serviceTypeId == serviceTypeItem.id
            )
              ">
              <div :key="`service-type-${serviceTypeItem.id}-${index}`" class="panel-header bg-light">
                <div class="text-center text-primary py-1 font-weight-bold">
                  {{ serviceTypeItem.service_name }}
                </div>
              </div>

              <vue-draggable v-model="consolidatedOrdersWithSeparators"
                :key="`draggable-${serviceTypeItem.id}`" :group="serviceTypeItem.id"
                :disabled="preview || payment || delivery || !isSeparatorsEnabled" handle=".drag-handle"
                item-key="_id">
                <template #item="{ element: item }">
                  <m-line-item-separator v-if="item.isSeparator" :removable="shouldShowAddSeparator &&
                    (item.ordinal > lastExistingLineItemIndex ||
                      isSeparatorsEditable)
                    " :movable="shouldShowAddSeparator &&
    (item.ordinal > lastExistingLineItemIndex ||
      isSeparatorsEditable)
    " @click="removeLineItemSeparator(item)">
                    Separator
                  </m-line-item-separator>
                  <m-line-item v-else-if="serviceTypeItem.id == item.serviceTypeId"
                    :service-type="serviceTypeItem.service_name" :item="item" :readonly="preview || payment || (edit && kots.includes(item.kot))
                      " :movable="shouldShowAddSeparator &&
    item.ordinal > lastExistingLineItemIndex &&
    !kots.includes(item.kot)
    " :locked="payment || lockEditOrder || (toBeRedirect && !delivery)
    " :edit="edit" :is-advanced-totals="isAdvancedTotals" :merged-to-table-name="activeOrder.tableMergedToName"
                    @addQuantity="addQuantity(item)" @subtractQuantity="subtractQuantity(item)"
                    @removeLineItem="removeLineItem(item)" @addRequest="addRequest(item, $event)"
                    @showItemAction="showItemAction(item, $event)"
                    @changeService="changeService($event, item)" />
                </template>
              </vue-draggable>

              <m-add-line-item-separator-button v-if="shouldShowAddSeparator &&
                serviceTypeItem.id == dineInServiceTypeId
                " :key="`separator-${serviceTypeItem.id}-${index}`" :disabled="isLastItemASeparator"
                class="mt-2" @click="addLineItemSeparator" />
            </template>
          </template>
        </template>
      </div>

      <div class="d-flex mx-4 flex-wrap totals-container bg-white mt-auto">
        <h3 class="d-lg-none">Breakdown</h3>
        <div class="col-lg-6">
          <div class="px-2 py-1 d-flex justify-content-between service-charge">
            <strong>Service Charge</strong>
            <span>{{ formattedTotals.serviceCharge }}</span>
          </div>

          <div class="px-2 py-1 d-flex justify-content-between vat">
            <strong>VAT</strong>
            <span>{{ formattedTotals.vat }}</span>
          </div>

          <template v-if="displayDiscount.length || orderDiscounts.length">
            <div class="px-2 py-1 d-flex justify-content-between payment-cell">
              <strong>Total Discount</strong>
              <span>{{ $filters.formatPrice(discountsTotal) }}</span>
            </div>

            <div v-for="(discount, index) in displayDiscount"
              class="pl-4 px-2 py-1 d-flex justify-content-between payment-cell">
              <div class="d-flex">
                <i v-if="(!canSplit || activeOrder.billDiscount) &&
                  !activeOrder.isOnlineDelivery
                  " class="fa fa-times remove-button mr-2" @click="removeDiscount(index)"></i>
                <strong>{{ discount.discount.discount_name }}</strong>
              </div>
              <span>{{ $filters.formatPrice(discount.amount) }}</span>
            </div>

            <div v-for="(discount, index) in orderDiscounts"
              class="pl-4 px-2 py-1 d-flex justify-content-between payment-cell">
              <div class="d-flex">
                <strong>{{ discount.discount.discount_name }}</strong>
              </div>
              <span>{{ $filters.formatPrice(discount.amount) }}</span>
            </div>
          </template>
        </div>

        <div class="col-lg-6">
          <div class="px-2 py-1 d-flex justify-content-between net">
            <strong>Sub Total</strong>
            <span>{{ formattedTotals.rawPrice }}</span>
          </div>

          <div v-if="formattedTotals.vatableSales" class="pl-4 px-2 py-1 d-flex justify-content-between net">
            <strong>VATABLE SALES</strong>
            <span>{{ formattedTotals.vatableSales }}</span>
          </div>

          <div v-if="formattedTotals.vatExemptSales" class="pl-4 px-2 py-1 d-flex justify-content-between net">
            <strong>VAT EXEMPT SALES</strong>
            <span>{{ $filters.formatPrice(computedVatExemptSales) }}</span>
          </div>

          <div v-if="formattedTotals.zeroRatedSales" class="pl-4 px-2 py-1 d-flex justify-content-between net">
            <strong>ZERO RATED SALES</strong>
            <span>{{ formattedTotals.zeroRatedSales }}</span>
          </div>

          <div v-for="chargeName in Object.keys(actualTotals.otherCharges ?? {})" :key="chargeName"
            class="pl-4 px-2 py-1 d-flex justify-content-between net">
            <strong>{{ chargeName }}</strong>
            <span>{{ actualTotals.otherCharges[chargeName] }}</span>
          </div>

          <div class="px-2 py-1 d-flex justify-content-between total">
            <strong>Total</strong>
            <span>{{ getTotalValue }}</span>
          </div>

          <div v-if="payment" class="px-2 py-1 d-flex justify-content-between payment-cell">
            <strong>Tendered Amount</strong>
            <span>{{ $filters.formatPrice(paymentsTotal) }}</span>
          </div>

          <div v-if="payment" v-for="(pay, index) in payments"
            class="px-2 py-1 d-flex justify-content-between payment-cell">
            <div class="d-flex align-items-center" :class="{ paid: pay.status === 'PAID' }">
              <i v-if="!canSplit || forceSingleBill" class="fa fa-times remove-button mr-2"
                @click="removePayment(index, pay)"></i>
              <strong>{{ pay.method }}</strong>

              <i v-if="['epayment', 'mosaicpay_qrph'].includes(pay.type)"
                class="fas fa-qrcode ml-1 bill-qr"
                @click="showPaymentQR(pay)">
              </i>

            </div>
            <span>{{ $filters.formatPrice(pay.amount) }}</span>
          </div>

          <div v-if="payment" class="px-2 py-1 d-flex justify-content-between payment-cell">
            <strong>Return</strong>
            <span>{{ $filters.formatPrice(change) }}</span>
          </div>

          <div v-if="payment" class="px-2 py-1 d-flex justify-content-between payment-cell">
            <strong>Balance</strong>
            <span>{{ $filters.formatPrice(remainingBalance) }}</span>
          </div>
        </div>
      </div>

      <div v-if="!activeOrder.tableMergedTo &&
        (isOwnOrder || !orderId) &&
        !delivery &&
        !toBeRedirect
        " class="d-flex flex-nowrap px-2 summary-buttons">
        <div v-if="!preview && !payment" class="col-6">
          <m-icon-button :post-click-delay="0" label="Clear" color="danger" @click="clearOrders"
            class="no-icon-button" />
        </div>
        <div v-if="$can(PERMISSIONS.PLACE_ORDER) && !preview && !payment" class="col-6">
          <m-icon-button :post-click-delay="0" label="Place Order" color="primary" @click="saveOrders"
            :disabled="ordersArr.length < 1 || disabledPlaceOrder" class="no-icon-button" />
        </div>

        <div v-if="preview && !activeOrder.isBilled && !ordersArr.length" class="col-3 px-1">
          <m-icon-button label="Void" icon="times" color="danger" @click="voidOrders" />
        </div>

        <div v-if="preview" class="col-3 px-1">
          <m-icon-button label="Add Order" icon="concierge-bell" color="#0052CC" @click="updateOrders"
            :disabled="activeOrder?.isOnlineDelivery" />
        </div>

        <div v-if="preview" class="col-3 px-1">
          <m-icon-button label="Discounts" icon="percentage" color="#0052CC"
            @click="openBillLevelDiscountModal" />
        </div>

        <div v-if="preview && activeOrder.isBilled" class="col-3 px-1">
          <m-icon-button label="Print" icon="print" color="#0052CC" @click="showReceipt(null, null)" />
        </div>

        <div v-if="$can(PERMISSIONS.SETTLE_ORDER) && preview && activeOrder.isBilled" class="col-3 px-1">
          <m-icon-button id="btn-payment" label="Payment" icon="money-bill-wave" color="#0052CC"
            @click="billOrders" />
        </div>

        <div v-if="preview && !activeOrder.isBilled" class="col-3 px-1">
          <m-icon-button id="btn-bill" label="Bill" icon="receipt" color="#0052CC" @click="setBilledState"
            :disabled="isBilling" />
        </div>

        <div v-if="preview && !activeOrder.isBilled && moreActions.length" class="col-3 px-1">
          <m-icon-button label="More" icon="ellipsis-h" color="#0052CC" @click="isMoreActionModalOpen = true" />
        </div>

        <div v-if="payment && enablePrintAll && !forceSingleBill" class="col-6">
          <m-icon-button label="Print All" icon="print" color="#0052CC" @click="$emit('printAll')" />
        </div>

        <div v-if="payment" :class="{
          [enablePrintAll && !forceSingleBill ? 'col-6' : 'col-12']: true,
        }">
          <m-icon-button :post-click-delay="1" :disabled="!ableToPrint || hasEPaymentMethod" :label="forceSingleBill ? 'Print' : 'Finish'" icon="print"
            color="#0052CC" @click="finishPayment" />
        </div>
      </div>

      <div v-if="delivery && ableToPrint" class="d-flex flex-nowrap px-3">
        <div class="px-2 col-6">
          <m-icon-button label="Print KOT" icon="print" color="#0052CC" @click="$emit('printKOT')" />
        </div>

        <div class="px-2 col-6" v-if="deliveryStatus !== 'CANCELLED'">
          <m-icon-button label="Print OR" icon="receipt" color="#0052CC" :disabled="isPrintingDisabled" @click="$emit('printOR')" />
        </div>

        <div class="px-2 col-6" v-else>
          <m-icon-button label="Print Void Slip" icon="receipt" color="#0052CC" @click="$emit('printVoidSlip')" />
        </div>
      </div>

      <div v-if="toBeRedirect && !delivery" class="d-flex flex-nowrap px-3">
        <div class="px-2 col-12">
          <m-icon-button :label="`Go to ${activeOrder.channelName} page`" color="#0052CC"
            @click="gotoDeliveryPage" />
        </div>
      </div>

      <line-item-request-modal v-model="isItemRequestModalOpen" :item="itemRequestTarget"
        @saveItemRequest="saveItemRequest" />

      <line-item-action-modal v-model="isItemActionModalOpen" :item="itemRequestTarget"
        :isOrderPlaced="preview || payment" :service-type="activeOrder.serviceType || serviceType"
        :delivery="delivery" @showItemDiscounts="showItemDiscounts" @showMoveItemTables="showMoveItemTables"
        @voidItem="openLineItemVoidModal" @toggleLineItemAction="changeService" />

      <line-item-discount-modal v-model="isItemDiscountModalOpen" :item="itemRequestTarget" :discounts="itemDiscounts.filter(disc => !(/\bMEMC\b/i).test(disc.discount_name))" :currentpax="pax" :approvers="discountApprovers" @discountSelected="applyDiscount" />

      <line-item-move-table-modal v-model="isItemMoveTableModalOpen" :item="itemRequestTarget"
        :activeTableId="tableId" :service-type="actualServiceType" @itemMoveTable="itemMoveTable"
        :getKOTPrintObj="getKOTPrintObj" :locationId="receiptDetails?.location_id" />

      <line-item-void-modal v-model="isItemVoidModalOpen" :item="itemRequestTarget"
        :orderBilled="activeOrder.isBilled" @itemVoidUpdated="applyVoid" />

      <order-details-modal v-if="!delivery" v-model="isOrderDetailsModalOpen"
        :kot-string="kotExceedsTruncateThreshold ? fullKotString : ''" :current-details="orderDetail"
        :current-order-customer-name="activeOrder.orderCustomerName" :delivery="isDeliveryService"
        @saveOrderDetail="saveOrderDetail" />

      <pax-modal v-model="isPaxModalOpen" :current-pax="pax" @paxSelected="updatePax" />

      <change-service-type-modal v-model="isChangeServiceTypeModalOpen"
        @serviceTypeUpdated="isChangeServiceTypeModalOpen = false" />

      <bill-discount-modal v-model="isBillDiscountModalOpen" :pax="activeOrderPax" :order="activeOrder"
        :isOpen="isBillDiscountModalOpen" @applyDiscount="applyBillDiscount" @removeDiscount="removeBillDiscount" />

      <table-actions-modal v-if="activeOrder.tableId" v-model="isTableActionsModalOpen"
        :source-table-id="activeOrder.tableId" @tableSelected="changeActiveOrderTable" />

      <!-- FOR COMPUTATION -->
      <m-line-item v-for="(item, index) in ordersArr" :key="`${item.product.id}-${index}-hidden`" ref="lineItems"
        :service-type="serviceType" :item="item" hidden class="hidden" />

      <OrderMoreActionModal v-model="isMoreActionModalOpen" :actions="moreActions"
        @setBilledAndSplit="setBilledAndSplit" @checkIfActiveOrderHasSingleKOT="checkIfActiveOrderHasSingleKOT"
        @getReservations="getReservations" />

      <OrderPrintStickerModal v-model="isPrintStickerModalOpen" :actions="moreActions"
        :activeOrderKOTs="this.activeOrder.kots" @printLabelStickers="printLabelStickers" />

      <MewsGetReservationsModal v-model="isMewsGetReservationsModalOpen" @chargeToRoom="chargeToRoom" />
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";
import {
  isEmpty,
  compact,
  debounce,
  mapValues,
  isEqual,
  defaultTo,
  uniq,
  has,
  map,
  get,
  pick,
  isNil,
  partition,
  intersection,
} from "lodash";
import cloneDeep from "rfdc/default";
import { checkCloudConnection } from "@/spa/plugins/axios";
import VueDraggable from "vuedraggable";
import {
  ENABLE_LINE_ITEM_SEPARATORS,
  MAX_KOT_BEFORE_TRUNCATE_DISPLAY,
  ENABLE_OTHER_CHARGES_OFFLINE,
  ENABLE_LINE_ITEM_SEPARATOR_EDITING,
  PERMISSIONS,
  ORDER_PANEL_ACTION_BUTTONS,
  ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE,
  ENABLE_DISCOUNT_ON_PROMO_ITEMS,
  ENABLE_NEW_VAT_EXEMPT_SALES,
  OFFLOAD,
  ACTIVE_ACCOUNT_TYPE,
  ACCOUNT_TYPES,
  USE_NEW_AMOUNT_DUE_FORMULA,
} from "@/spa/constants";

import { getTableLayout } from "@/spa/services/table-service";
import { getAllDiscountItems } from "@/spa/services/discount-service";
import { print } from "@/spa/services/printer-service";
import { mapState } from "vuex";
import {
  getReceiptDetails,
  getCancellationReasonsByComp,
  getCancellationRatesByComp,
  getApprovers,
} from "@/spa/services/cashier-service";
import { addReservationProduct } from "@/spa/services/mews-service";
import MLineItem from "@/spa/components/common/MLineItem";
import MIconButton from "@/spa/components/common/MIconButton";
import MAddLineItemSeparatorButton from "@/spa/components/common/buttons/MAddLineItemSeparatorButton";
import MLineItemSeparator from "@/spa/components/common/MLineItemSeparator";
import PaxModal from "@/spa/components/modals/PaxModal";
import OrderDetailsModal from "@/spa/components/modals/OrderDetailsModal";
import LineItemRequestModal from "@/spa/components/modals/LineItemRequestModal";
import LineItemActionModal from "@/spa/components/modals/LineItemActionModal";
import LineItemDiscountModal from "@/spa/components/modals/LineItemDiscountModal";
import LineItemMoveTableModal from "@/spa/components/modals/LineItemMoveTableModal";
import LineItemVoidModal from "@/spa/components/modals/LineItemVoidModal";
import CompItemModal from "@/spa/components/modals/CompItemModal";
import ChangeServiceTypeModal from "@/spa/components/modals/ChangeServiceTypeModal";
import BillDiscountModal from "@/spa/components/modals/BillDiscountModal";
import TableActionsModal from "@/spa/components/modals/TableActionsModal";
import OrderMoreActionModal from "@/spa/components/modals/OrderMoreActionModal";
import OrderPrintStickerModal from "@/spa/components/modals/OrderPrintStickerModal";
import MewsGetReservationsModal from "@/spa/components/modals/MewsGetReservationsModal.vue";
import kotHtml from "@/spa/components/templates/print/kot.html";
import { convert } from "html-to-text";
import {
  calculateDiscountAmount,
  applyDiscount,
  discountAmountToPercentage,
} from "@/spa/utils/computations";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { toIsoString } from "@/spa/utils/date-format";
import {
  getReceiptPrintString,
  generateReceiptItemString,
  kotGroupOrdersByServiceType,
} from "@/spa/utils/print";

import { getLatestSeries } from "@/spa/services/sync-service";
import { PAYMENT_INVOICE_STATUSES } from "@/spa/constants";
import OrderMixin from "@/spa/components/mixins/OrderMixin";
import LabelPrinterMixin from "@/spa/components/mixins/LabelPrinterMixin";
import ReceiptMixin from "@/spa/components/mixins/ReceiptMixin";
import seriesService from "@/spa/services/series-service";

import moment from "moment";
import { VAT_EXCL, hasDiscountEligibilityKeywords } from "@/vue/helper/discount";
import CartPanel from "@/spa/components/panels/CartPanel";
import {
  getAmountDue,
  mergeAndUpdatePax,
  OFFLOAD_RECEIPT_ACTION,
  OrderBridge
} from "@/mobile_bridge/offload/offload-receipt";
import {formatAmount, posDateWithCurrentTime, sumArray} from "@/mobile_bridge/offload/receipt-model";
import bus from '@/spa/utils/bus';

export default {
  name: "OrdersPanel",

  mixins: [OrderMixin, LabelPrinterMixin, ReceiptMixin],

  components: {
    MLineItem,
    MIconButton,
    PaxModal,
    OrderDetailsModal,
    LineItemRequestModal,
    LineItemActionModal,
    LineItemDiscountModal,
    LineItemMoveTableModal,
    LineItemVoidModal,
    ChangeServiceTypeModal,
    BillDiscountModal,
    TableActionsModal,
    CompItemModal,
    OrderMoreActionModal,
    OrderPrintStickerModal,
    MAddLineItemSeparatorButton,
    MLineItemSeparator,
    VueDraggable,
    MewsGetReservationsModal,
    CartPanel
  },

  props: {
    orderId: {
      type: [Number, String],
      default: "",
    },

    orders: {
      type: Array,
      required: true,
    },

    preview: {
      type: Boolean,
      default: false,
    },

    payment: {
      type: Boolean,
      default: false,
    },

    payments: {
      type: Array,
      default: () => [],
    },

    discounts: {
      type: Array,
      default: () => [],
    },

    serviceType: {
      type: String,
      default: "Dine-in",
    },

    channelName: {
      type: String,
      default: "",
    },

    pax: {
      type: Number,
      default: 0,
    },

    tableId: {
      type: [String, Number],
      default: "",
    },

    orderDetail: {
      type: String,
      default: "",
    },

    orderCustomerName: {
      type: String,
      default: "",
    },

    kots: {
      type: Array,
      default: () => [],
    },

    billNumber: {
      type: [Number, String],
      default: "",
    },

    edit: {
      type: Boolean,
      default: false,
    },

    groupByKot: {
      type: Boolean,
      default: false,
    },

    consolidateOrders: {
      type: Boolean,
      default: false,
    },

    forceSingleBill: {
      type: Boolean,
      default: false,
    },

    splitTotals: {
      type: Object,
      default: () => ({}),
    },

    splitDiscounts: {
      type: Object,
      default: () => ({}),
    },

    enablePrintAll: {
      type: Boolean,
      default: false,
    },

    delivery: {
      type: Boolean,
      default: false,
    },

    deliveryStatus: {
      type: String,
      default: "",
    },

    balanceOverride: {
      type: [Number, null],
      default: null,
    },

    changeOverride: {
      type: [Number, null],
      default: null,
    },

    ableToPrint: {
      type: Boolean,
      default: true,
    },

    lockEditOrder: {
      type: Boolean,
      default: false,
    },

    isFinishPayment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCartVisible: true,
      cartDisplay: "d-none d-lg-block",
      totals: {
        vat: 0,
        serviceCharge: 0,
        net: 0,
        total: 0,
        beforeSc: 0,
        rawPrice: 0,
        vatExemptSales: null,
        zeroRatedSales: null,
        isScInclusive: 0,
        scIsAfterDiscount: 0,
        vatableSales: null,
      },
      isItemRequestModalOpen: false,
      isItemActionModalOpen: false,
      isItemDiscountModalOpen: false,
      isItemMoveTableModalOpen: false,
      isCompItemModalOpen: false,
      isItemVoidModalOpen: false,
      itemRequestTarget: null,
      isPaxModalOpen: false,
      isOrderDetailsModalOpen: false,
      isChangeServiceTypeModalOpen: false,
      isBillDiscountModalOpen: false,
      isTableActionsModalOpen: false,
      isMoreActionModalOpen: false,
      isPrintStickerModalOpen: false,
      isMewsGetReservationsModalOpen: false,
      isAdvancedTotals: false,
      itemDiscounts: [],
      discountApprovers: [],
      cancellationReasons: [],
      cancellationRates: [],
      compApprovers: [],
      tables: [],
      receiptDetails: null,
      ordersArr: this.orders || [],
      debouncedRecompute: () => null,
      disabledPlaceOrder: false,
      separatorPositions: [],
      currentButtonSet: 1,
      deliveryChannels: ["Grab", "FoodPanda"],
      isBilling: false,
    };
  },


  beforeDestroy() {
    window.eventBus.off("show-cart", this.handleCartToggle);
  },

  computed: {
    cartDisplay() {
      return this.isCartVisible ? "d-none d-lg-block" : "d-block";
    },

    ...mapState([
      "activeBrandId",
      "activeOrderId",
      "lastBillNum",
      "lastReceiptNum",
      "lastVoidBillNum",
      "lastVoidReceiptNum",
      "dataContainer",
      "isLocalSeriesValidated",
    ]),

    ...mapState("user", ["terminalId"]),

    ...mapState("settings", ["shiftTable", "currentShift", "serviceTypes"]),

    ...mapGetters(["activeOrder", "pendingOrders"]),

    ...mapGetters("user", ["multiTerminalType"]),

    lockForeignTransactions() {
      // make dynamic when requirements are defined
      return false;
    },

    isOwnOrder() {
      return (
        this.activeOrder.originTerminalId === this.terminalId ||
        !this.lockForeignTransactions
      );
    },

    hasNoOrders() {
      return isEmpty(this.ordersArr);
    },

    hasSplitTotals() {
      return Object.values(this.splitTotals).length > 0;
    },

    splitBillTotals() {
      const totals = Object.values(this.splitTotals).reduce(
        (acc, cur) => {
          acc.vat += cur.vat;
          acc.serviceCharge += cur.serviceCharge;
          acc.net += cur.net;
          acc.total += cur.total;
          acc.beforeSc += cur.beforeSc;
          acc.rawPrice += cur.rawPrice;
          acc.vatExemptSales += cur.vatExemptSales ?? 0;
          acc.zeroRatedSales += cur.zeroRatedSales ?? 0;
          acc.vatableSales += cur.vatableSales ?? 0;

          return acc;
        },
        {
          vat: 0,
          serviceCharge: 0,
          net: 0,
          total: 0,
          beforeSc: 0,
          rawPrice: 0,
          vatExemptSales: 0,
          zeroRatedSales: 0,
          vatableSales: 0,
        }
      );

      if (totals.vatExemptSales === 0) {
        totals.vatExemptSales = null;
      }

      if (totals.zeroRatedSales === 0) {
        totals.zeroRatedSales = null;
      }

      if (totals.vatableSales === 0) {
        totals.vatableSales = null;
      }

      return totals;
    },

    actualTotals() {
      return this.hasSplitTotals ? this.splitBillTotals : this.totals;
    },

    formattedTotals() {
      return mapValues(this.actualTotals, (v) =>
        v !== null ? this.$filters.formatPrice(v) : v
      );
    },

    computedVatExemptSales() {
      const discounts = [
        ...this.displayDiscount,
        ...this.orderDiscounts,
      ].filter(
        (d) =>
          ENABLE_NEW_VAT_EXEMPT_SALES &&
          VAT_EXCL.includes(d.discount?.discount_name)
      );

      let vatExemptSales = parseFloat(this.formattedTotals.vatExemptSales.replace(/,/g, ''));

      discounts.forEach((d) => {
        vatExemptSales += parseFloat(d.amount);
      });

      return vatExemptSales;
    },

    orderDiscounts() {
      return this.ordersArr
        .filter((o) => o.discount && !o.isVoided)
        .reduce((acc, o) => {
          const existing = acc.find(
            (d) => d.discount.discount_name === o.discount.discount_name
          );
          if (existing) {
            existing.amount += o.discount.discountAmount;
          } else {
            acc.push({
              discount: o.discount,
              amount: o.discount.discountAmount,
            });
          }

          return acc;
        }, []);
    },

    splitDiscountsValues() {
      return Object.values(this.splitDiscounts).flatMap((v) => v);
    },

    hasSplitDiscounts() {
      return Object.values(this.splitDiscountsValues).length > 0;
    },

    displayDiscount() {
      if (this.hasSplitDiscounts) {
        return this.splitDiscountsValues;
      }

      const compDiscount = [];

      this.ordersArr.forEach((order) => {
        if (!order.comp) return;

        compDiscount.push({
          discount: {
            discount_name: "COMP",
            discount_amount:
              order.activePrice * order.quantity * order.comp.compRate,
            has_service_charge: order.comp.has_service_charge,
          },
          amount: order.activePrice * order.quantity * order.comp.compRate,
        });
      });

      const actualDiscounts = isEmpty(this.discounts)
        ? compact([this.activeOrder.billDiscount]).map((d) => ({
          amount: 0,
          ...d,
        }))
        : this.discounts;

      return [...compDiscount, ...actualDiscounts];
    },

    hasLineItemDiscount() {
      return this.ordersArr.some((o) => o.discount && !o.isVoided);
    },

    hasPromoItem() {
      return this.ordersArr.some(
        (order) => order.product.isPromo || order.product.isSecondItem
      );
    },

    hasBillLevelDiscount() {
      return this.activeOrder.billDiscount;
    },

    hasDiscount() {
      return !isEmpty(this.displayDiscount) || this.hasLineItemDiscount;
    },

    paymentsTotal() {
      if (!this.payments) return 0;

      return this.payments.reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);
    },

    discountsTotal() {
      return [...this.displayDiscount, ...this.orderDiscounts].reduce(
        (total, discount) => {
          return total + discount.amount;
        },
        0
      );
    },

    paymentAndDiscountsTotal() {
      return this.paymentsTotal + this.discountsTotal;
    },

    remainingBalance() {
      return (
        this.balanceOverride ??
        Math.max(this.actualTotals.total - this.paymentsTotal, 0).toFixed(2)
      );
    },

    change() {
      return (
        this.changeOverride ??
        Math.max(this.paymentsTotal - this.actualTotals.total, 0).toFixed(2)
      );
    },

    totalLabel() {
      return this.isAdvancedTotals ? "Total +" : "Total";
    },

    headers() {
      return [
        {
          label: "QTY",
          class: ["quantity", this.isAdvancedTotals ? "col-2" : "col-3"],
        },
        {
          label: "Name",
          class: ["name", this.isAdvancedTotals ? "col-5" : "col-6"],
        },
        {
          label: "VAT",
          class: ["vat", "col-1"],
          hidden: !this.isAdvancedTotals,
        },
        {
          label: "Net",
          class: ["net", "col-1"],
          hidden: !this.isAdvancedTotals,
        },
        {
          label: "SC",
          class: ["service-charge", "col-1"],
          hidden: !this.isAdvancedTotals,
        },
        {
          label: this.totalLabel,
          class: ["price", this.isAdvancedTotals ? "col-2" : "col-3"],
        },
      ].filter((header) => !header.hidden);
    },

    fullKotString() {
      return uniq(this.kots).join(", ");
    },

    kotExceedsTruncateThreshold() {
      return this.kots?.length >= MAX_KOT_BEFORE_TRUNCATE_DISPLAY;
    },

    kotString() {
      if (!this.kotExceedsTruncateThreshold) {
        return this.fullKotString;
      }

      const kots = cloneDeep(uniq(this.kots));
      const first = kots.shift();
      const last = kots.pop();
      const secondLast = kots.pop();

      return `${first}, ... ${secondLast}, ${last}`;
    },

    kotGroupedOrders() {
      return this.orders.reduce((acc, order) => {
        if (!acc[order.kot]) {
          acc[order.kot] = {};
        }

        const serviceType = this.serviceTypes.find(
          (i) => i.id === order.serviceTypeId
        ).service_name;
        if (!acc[order.kot][serviceType]) {
          acc[order.kot][serviceType] = [];
        }

        acc[order.kot][serviceType].push(order);

        return acc;
      }, {});
    },

    consolidatedOrders() {
      if (!this.consolidateOrders) return this.orders;

      const orders = {};
      this.orders.forEach((o) => {
        const prodString = JSON.stringify({
          ...pick(o.product, [
            "id",
            "forcedMods",
            "unforcedMods",
            "product_name",
            "price",
            "bundled_products",
            "customized_products",
            "customizedProductSummary",
          ]),
          kot:
            this.isSeparatorsEnabled && !isEmpty(this.separatorPositions)
              ? o.kot
              : Boolean(o.kot),
          isVoided: Boolean(o.isVoided),
          serviceTypeId: o.serviceTypeId,
        });

        if (orders[prodString]) {
          orders[prodString].quantity += o.quantity;
          orders[prodString]._ids.push(o._id);
          if (o.discount && orders[prodString].discount) {
            orders[prodString].discount.discountPax += o.discount.discountPax;
            orders[prodString].discount.discountAmount +=
              o.discount.discountAmount;
          }
        } else {
          orders[prodString] = cloneDeep(o);
          orders[prodString]._ids = [o._id];
        }
      });

      return Object.values(orders);
    },

    consolidatedOrdersWithSeparators: {
      get() {
        if (!this.isSeparatorsEnabled || isEmpty(this.separatorPositions))
          return this.consolidatedOrders;

        const orders = [];
        const separatorPositions = [...this.separatorPositions];

        let offset = 0;
        this.consolidatedOrders.forEach((o, index) => {
          let i = index + offset;
          while (separatorPositions.includes(i)) {
            orders.push({
              isSeparator: true,
              ordinal: i,
            });
            separatorPositions.splice(separatorPositions.indexOf(i), 1);
            offset++;
            i++;
          }
          const indexWithNewOffset = index + offset;
          orders.push({ ...o, ordinal: indexWithNewOffset });
        });

        if (!isEmpty(separatorPositions)) {
          orders.push(
            ...separatorPositions.map((i) => ({
              isSeparator: true,
              ordinal: i,
            }))
          );
        }

        return orders;
      },
      set(items) {
        const updatedSeparatorPositions = [];
        items.forEach((o, i) => {
          if (o.isSeparator) {
            updatedSeparatorPositions.push(i);
          }
        });

        let lastExistingLineItemIndex =
          this.consolidatedOrdersWithSeparators.findLastIndex(
            (o) => !o.isSeparator && o._id
          );

        const lockedSeparators = this.isSeparatorsEditable
          ? []
          : (this.activeOrder.separatorPositions || []).filter(
            (p) => p < lastExistingLineItemIndex
          );

        const [originalPositions, addOrderPositions] = partition(
          updatedSeparatorPositions,
          (p) =>
            p < lastExistingLineItemIndex &&
            this.activeOrder.separatorPositions?.includes(p)
        );

        if (
          !this.isSeparatorsEditable &&
          intersection(originalPositions, lockedSeparators).length !==
          lockedSeparators.length
        ) {
          return;
        }

        if (
          !this.isSeparatorsEditable &&
          Math.max(...originalPositions) > Math.min(...addOrderPositions)
        ) {
          return;
        }

        if (
          !this.isSeparatorsEditable &&
          lastExistingLineItemIndex >= Math.min(...addOrderPositions)
        ) {
          return;
        }

        this.separatorPositions = updatedSeparatorPositions;
        this.$emit(
          "unsavedOrdersRearranged",
          items.filter((o) => !o.isSeparator && !o._id)
        );
      },
    },

    lastExistingLineItemIndex() {
      let lastExistingLineItemIndex =
        this.consolidatedOrdersWithSeparators.findLastIndex(
          (o) => !o.isSeparator && o._id
        );

      return lastExistingLineItemIndex;
    },

    isLastItemASeparator() {
      return this.consolidatedOrdersWithSeparators[
        this.consolidatedOrdersWithSeparators.length - 1
      ]?.isSeparator;
    },

    activeTable() {
      if (!this.tableId) return {};

      const table = this.tables.find((table) => table.table_id == this.tableId);
      return table || {};
    },

    canSplit() {
      return this.actualServiceType === "Dine-in";
    },

    actualChannelName() {
      return `${this.activeOrder.channelName || this.channelName}`;
    },

    actualServiceType() {
      const sType = this.activeOrder.serviceType || this.serviceType;
      return sType === "Delivery" ? this.actualChannelName : sType;
    },

    isSeparatorsEnabled() {
      return ENABLE_LINE_ITEM_SEPARATORS;
    },

    isSeparatorsEditable() {
      return ENABLE_LINE_ITEM_SEPARATOR_EDITING;
    },

    shouldShowAddSeparator() {
      return this.isSeparatorsEnabled && (this.edit || !this.activeOrderId);
    },

    dineInServiceTypeId() {
      return (
        this.serviceTypes.find((i) => i.service_name === "Dine-in")?.id ?? 1
      );
    },

    hasTable() {
      const serviceTypesWithTable = ["Dine-in", "Take-out"];
      return serviceTypesWithTable.includes(this.actualServiceType);
    },

    ableToPrint() {
      if (this.delivery) return true;

      const onlinePaymentTypes = ["epayment", "mosaicpay_qrph"];
      const unpaidEPayments = this.payments.filter(
        (payment) => 
          onlinePaymentTypes.includes(payment.type) &&
            (payment.status === PAYMENT_INVOICE_STATUSES.PENDING ||
              (payment.type == "mosaicpay_qrph" && [
                PAYMENT_INVOICE_STATUSES.PENDING, 
                PAYMENT_INVOICE_STATUSES.FAILED, 
                PAYMENT_INVOICE_STATUSES.EXPIRED].includes(payment.status)
              )
            )
      );

      console.log(`unpaidEPayments: `, unpaidEPayments);

      if (unpaidEPayments.length > 0) {
        return false;
      }

      return (this.payments.length > 0 && this.$filters.formatPrice(this.remainingBalance) == 0)
        || this.isFinishPayment;
    },

    mergeOrderPax() {
      if (isEmpty(this.activeOrder?.tableMergedWith)) return 0;

      const mergedOrders = this.pendingOrders.filter((o) =>
        this.activeOrder.tableMergedWith.includes(o.tableId)
      );
      return mergedOrders.reduce((acc, o) => acc + o.pax, 0);
    },

    totalPax() {
      const mergeText =
        this.mergeOrderPax > 0 ? ` (+${this.mergeOrderPax})` : "";
      return `${this.pax}${mergeText} pax`;
    },

    moreActions() {
      let actions = [];

      if (
        this.$can(PERMISSIONS.SETTLE_ORDER) &&
        this.preview &&
        this.hasTable &&
        !this.activeOrder.isBilled
      ) {
        actions.push(ORDER_PANEL_ACTION_BUTTONS.SPLIT);
      }

      if (
        this.$can(PERMISSIONS.LABEL_PRINTING) &&
        this.productsHasLabelPrint()
      ) {
        actions.push(ORDER_PANEL_ACTION_BUTTONS.LABEL_PRINT);
      }

      if (this.$can(PERMISSIONS.ROOM_PAYMENT)) {
        actions.push(ORDER_PANEL_ACTION_BUTTONS.ROOM_PAYMENT);
      }

      return actions;
    },

    toBeRedirect() {
      return (
        this.deliveryChannels.includes(this.activeOrder.channelName) &&
        this.activeOrder?.isOnlineDelivery
      );
    },

    isDeliveryService() {
      return (
        this.activeOrder.serviceType == "Delivery" ||
        this.$route.params.serviceType == "Delivery"
      );
    },
    isPrintingDisabled() {
      const channelName = this.activeOrder.channelName || this.channelName;

      return (channelName === "Grab" && !['DELIVERED', 'COLLECTED'].includes(this.deliveryStatus))
        || (channelName === "FoodPanda" && this.deliveryStatus !== "COLLECTED");
    },
    hasEPaymentMethod() {
      if (OFFLOAD.sqliteOffloadReceipt && window.hasEPayment) {
        const ePayments = this.payments.filter(payment => payment.method === 'E-PAYMENT' && payment.hasOwnProperty('invoiceUrl'));

        if (ePayments.length !== this.payments.length) {
          return ePayments.length > 0 && ePayments.every(invoice => invoice.status !== "PAID")
        }

        return true;
      }

      return false;
    },
    getTotalValue() {
      return this.grossSales || this.formattedTotals.total;
    }
  },

  watch: {
    orders: {
      handler(newVal) {
        this.ordersArr = newVal;
        this.debouncedRecompute();
      },
      deep: true,
    },

    remainingBalance() {
      this.$emit("balanceUpdated", this.remainingBalance);
    },

    activeOrder: {
      handler(activeOrder, oldActiveOrder) {
        if (activeOrder._id != oldActiveOrder._id) {
          this.separatorPositions = cloneDeep(
            activeOrder?.separatorPositions || []
          );
        }
        this.debouncedRecompute();
      },
      deep: true,
    },

    displayDiscount: {
      handler() {
        this.debouncedRecompute();
      },
      deep: true,
    },

    actualTotals: {
      handler(newValue, oldValue) {
        if (OFFLOAD.sqliteOffloadReceipt && USE_NEW_AMOUNT_DUE_FORMULA && !isEmpty(newValue)) {
          this.actualTotals.total = getAmountDue(newValue);
        }
      },
      deep: true
    }
  },

  async mounted() {
    window.eventBus.on("show-cart", this.handleCartToggle);

    this.debouncedRecompute = debounce(() => this.recomputeTotals(), 100);
    this.fetchTables();
    this.parseReceiptDetails();
    this.debouncedRecompute();

    this.separatorPositions = cloneDeep(
      this.activeOrder.separatorPositions || []
    );

    await this.getGrossSales(this.activeOrderId);
  },

  methods: {
    handleCartToggle() {
      this.isCartVisible = !this.isCartVisible;
      this.cartDisplay =
        this.cartDisplay === "d-none" ? "d-block" : "";
    },

    showCart() {
      window.eventBus.emit("show-cart", true);
    },

    ...mapMutations([
      "updateOrder",
      "updateOrderLineItem",
      "clearActiveOrderId",
      "deleteOrder",
      "incrementLastBillNum",
      "incrementLastReceiptNum",
      "incrementLastVoidBillNum",
      "setLastEndingVoidOrder",
      "refreshActiveOrder",
      "setIsLocalSeriesValidated",
    ]),

    ...mapMutations("global", ["setShowPaymentQRModal", "setPaymentQRDetails"]),

    ...mapActions(["sqliteUpsertReceipt"]),

    async fetchTables() {
      try {
        const layoutResponse = await getTableLayout();
        this.tables = layoutResponse.data.table_details.service_areas
          .map((a) => a.tables)
          .flat();
      } catch (error) {
        console.error(error);
      }
    },

    async parseReceiptDetails() {
      try {
        let receiptResponse = await getReceiptDetails();
        let details = Object.values(receiptResponse.data).find(
          (d) => d.brand_id == this.activeBrandId
        );
        this.receiptDetails = details;
      } catch (error) {
        console.log(error);
      }
    },

    addLineItemSeparator() {
      this.separatorPositions.push(
        this.consolidatedOrdersWithSeparators.length
      );
    },

    removeLineItemSeparator(item) {
      const index = this.separatorPositions.indexOf(item.ordinal);
      if (index > -1) {
        this.separatorPositions.splice(index, 1);
      }
    },

    resetTotals() {
      this.totals = {
        vat: 0,
        serviceCharge: 0,
        net: 0,
        total: 0,
        beforeSc: 0,
        rawPrice: 0,
        vatExemptSales: null,
        zeroRatedSales: null,
        isScInclusive: 0,
        scIsAfterDiscount: 0,
        otherCharges: {},
        vatableSales: null,
      };
    },

    openChangeServiceTypeModal() {
      if (!this.activeOrderId) return;
      const serviceType = this.activeOrder.serviceType || this.serviceType;
      this.isChangeServiceTypeModalOpen = serviceType != "Delivery";
    },

    getAggregateTotals() {
      let totals = {
        vat: 0,
        serviceCharge: 0,
        net: 0,
        total: 0,
        beforeSc: 0,
        rawPrice: 0,
        vatExemptSales: null,
        zeroRatedSales: null,
        isScInclusive: 0,
        scIsAfterDiscount: 0,
        otherCharges: {},
        vatableSales: null,
      };

      if (!this.$refs.lineItems) return totals;

      const appliedFixedOtherCharges = {};
      const parsedAmounts = this.$refs.lineItems.map(
        (item) => item.parsedAmounts
      );
      parsedAmounts.forEach((parsedAmount) => {
        totals.vat += parsedAmount.vat;
        totals.serviceCharge += parsedAmount.serviceCharge;
        totals.net += parsedAmount.net;
        totals.total += parsedAmount.total;
        totals.beforeSc += parsedAmount.beforeSc;
        totals.rawPrice += parsedAmount.rawPrice;
        totals.isScInclusive = parsedAmount.isScInclusive;
        totals.scIsAfterDiscount = parsedAmount.scIsAfterDiscount;

        if (parsedAmount.vatableSales > 0) {
          totals.vatableSales =
            (totals.vatableSales ?? 0) + parsedAmount.vatableSales;
        }

        if (parsedAmount.vatExemptSales > 0) {
          totals.vatExemptSales =
            (totals.vatExemptSales ?? 0) + parsedAmount.vatExemptSales;
        }

        if (parsedAmount.zeroRatedSales > 0) {
          totals.zeroRatedSales =
            (totals.zeroRatedSales ?? 0) + parsedAmount.zeroRatedSales;
        }

        if (parsedAmount.otherCharges && ENABLE_OTHER_CHARGES_OFFLINE) {
          Object.keys(parsedAmount.otherCharges).forEach((key) => {
            totals.otherCharges[key] =
              parseFloat(totals.otherCharges[key] ?? 0) +
              parseFloat(parsedAmount.otherCharges[key]);
          });
        }

        if (parsedAmount.otherChargesFixed && ENABLE_OTHER_CHARGES_OFFLINE) {
          Object.keys(parsedAmount.otherChargesFixed).forEach((key) => {
            if (appliedFixedOtherCharges[key]) return;

            appliedFixedOtherCharges[key] = true;
            totals.otherCharges[key] =
              parseFloat(totals.otherCharges[key] ?? 0) +
              parseFloat(parsedAmount.otherChargesFixed[key]);
          });
        }
      });

      totals.otherCharges = mapValues(totals.otherCharges, (charge) =>
        this.$filters.formatPrice(parseFloat(charge))
      );

      return totals;
    },

    async recomputeTotals(
      percentOverride = null,
      recomputeCeilingDiscount = false
    ) {
      this.resetTotals();

      if (!this.$refs.lineItems) return;

      const oldTotals = cloneDeep(this.totals);

      this.totals = {
        vat: 0,
        serviceCharge: 0,
        net: 0,
        total: 0,
        beforeSc: 0,
        rawPrice: 0,
        vatExemptSales: 0,
        zeroRatedSales: 0,
        isScInclusive: 0,
        scIsAfterDiscount: 0,
        otherCharges: {},
      };

      const undiscountedTotals = this.getAggregateTotals();

      if (!this.displayDiscount.length) {
        this.totals = undiscountedTotals;
      } else {
        let totalDiscountAmount = 0;
        const discountAmounts = [];
        this.displayDiscount.forEach((discountData, index) => {
          if (
            !isNil(percentOverride) &&
            !isNaN(percentOverride) &&
            percentOverride >= 0
          ) {
            discountData.discount.original_discount_rate =
              discountData.discount.discount_rate;
            discountData.discount.discount_rate = percentOverride;
            discountData.discount.original_discount_amount =
              discountData.discount.discount_amount;
            discountData.discount.discount_amount = null;
          }

          discountAmounts.push(0);
          const totalRawPrice = this.$refs.lineItems.reduce(
            (a, b) => a + b.parsedAmounts.rawPrice,
            0
          );

          this.$refs.lineItems.forEach((item) => {
            const amount = calculateDiscountAmount(
              discountData,
              item.parsedAmounts,
              this.activeOrderPax
            );
            totalDiscountAmount += amount;
            discountAmounts[index] += amount;
            const vatConfig = Object.values(item.item.product.tax_data).find(
              (td) => td && td.tax_name === "VAT"
            );

            let lineTotals = applyDiscount(
              discountData,
              item.parsedAmounts,
              amount,
              this.activeOrderPax,
              vatConfig
            );
            if (
              ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE &&
              discountData.discount.max_discount_amount > 0
            ) {
              const maxDiscountableRatio =
                (discountData.discount.max_discount_amount *
                  item.parsedAmounts.rawPrice) /
                totalRawPrice;
              lineTotals = applyDiscount(
                {
                  ...discountData,
                  discount: {
                    ...discountData.discount,
                    max_discount_amount: maxDiscountableRatio,
                  },
                },
                item.parsedAmounts,
                amount,
                this.activeOrderPax,
                vatConfig
              );
            }

            this.totals.vat += lineTotals.vat;
            this.totals.serviceCharge += lineTotals.serviceCharge;
            this.totals.net += lineTotals.net;
            this.totals.total += lineTotals.total;
            this.totals.beforeSc += lineTotals.beforeSc;
            this.totals.rawPrice += lineTotals.rawPrice;
            this.totals.vatExemptSales += lineTotals.vatExemptSales ?? 0;
            this.totals.zeroRatedSales += lineTotals.zeroRatedSales ?? 0;
            this.totals.isScInclusive = lineTotals.isScInclusive;
            this.totals.scIsAfterDiscount = lineTotals.scIsAfterDiscount;
            this.totals.scIsNetBased = lineTotals.scIsNetBased;

            if (ENABLE_OTHER_CHARGES_OFFLINE) {
              Object.keys(lineTotals.otherCharges).forEach((key) => {
                this.totals.otherCharges[key] =
                  (this.totals.otherCharges[key] ?? 0) +
                  lineTotals.otherCharges[key];
              });
            }
          });
        });

        if (
          this.hasBillLevelDiscount &&
          this.activeOrder.billDiscount.amount != totalDiscountAmount
        ) {

          if (has(this.activeOrder, 'tableMergedWith') && !isEmpty(this.activeOrder.tableMergedWith)) {
              totalDiscountAmount = this.activeOrder.billDiscount.amount;
          }

          this.updateOrder({
            orderId: this.activeOrderId,
            order: {
              billDiscount: {
                ...this.activeOrder.billDiscount,
                amount: totalDiscountAmount,
              },
            },
          });
        }

        const discounts = (this.activeOrder.discounts || []).map(
          (discount, index) => ({
            ...discount,
            amount: discountAmounts[index],
          })
        );

        if (!isEqual(discounts, this.activeOrder.discounts)) {
          this.updateOrder({
            orderId: this.activeOrderId,
            order: { discounts },
          });
        }

        const discount = this.activeOrder.billDiscount?.discount;
        const discountIsExceedOrFixed =
          (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE
            ? undiscountedTotals.total
            : totalDiscountAmount) >
          (discount?.max_discount_amount || Infinity) ||
          !isNil(discount?.discount_amount);

        if (
          !ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE &&
          this.hasBillLevelDiscount &&
          discountIsExceedOrFixed &&
          percentOverride === null &&
          !discount.meal_combination_amount
        ) {
          const flatAmount =
            discount.discount_amount ?? discount.max_discount_amount;
          const desiredPercentage = discountAmountToPercentage(
            this.activeOrder.billDiscount,
            undiscountedTotals,
            this.activeOrder.pax,
            flatAmount
          );
          return this.recomputeTotals(desiredPercentage);
        }

        if (
          this.hasBillLevelDiscount &&
          discountIsExceedOrFixed &&
          percentOverride === null &&
          recomputeCeilingDiscount === true &&
          !discount.meal_combination_amount
        ) {
          let flatAmount =
            discount.discount_amount ?? discount.max_discount_amount;
          if (
            ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE &&
            discount.max_discount_amount > 0
          ) {
            flatAmount = discount.discount_amount;
            flatAmount ??= calculateDiscountAmount(
              this.activeOrder.billDiscount,
              {
                net: discount.max_discount_amount / 1.12,
                rawPrice: discount.max_discount_amount,
              },
              this.activeOrderPax
            );
          }

          const desiredPercentage = discountAmountToPercentage(
            this.activeOrder.billDiscount,
            undiscountedTotals,
            this.activeOrder.pax,
            flatAmount
          );
          return this.recomputeTotals(desiredPercentage);
        }

        if (
          this.hasBillLevelDiscount &&
          this.activeOrder.billDiscount.discount.meal_combination_amount &&
          hasDiscountEligibilityKeywords(this.activeOrder.billDiscount.discount.discount_name)
        ) {
            const billDiscountData = this.activeOrder.billDiscount;
            const totalMEMCAmount = billDiscountData.discount.meal_combination_amount * billDiscountData.discountQuantity;

            this.totals.vatExemptSales = (billDiscountData.discount.meal_combination_amount * billDiscountData.discountQuantity) / 1.12;
            this.totals.vatableSales = (this.activeOrder.totals.rawPrice - totalMEMCAmount) / 1.12;
            this.totals.vat = (this.totals.vatableSales * 0.12);

            totalDiscountAmount = this.totals.vatExemptSales * parseFloat(billDiscountData.discount.discount_rate);
            this.updateOrder({
              orderId: this.activeOrderId,
              order: {
                billDiscount: {
                  ...this.activeOrder.billDiscount,
                  amount: totalDiscountAmount,
                },
              },
            });

            // Update the field and copy the rest of the fields
            this.displayDiscount.forEach((disc) => {
              disc.amount = totalDiscountAmount
            });
        }
      }

      if (!this.totals.vatExemptSales) this.totals.vatExemptSales = null;
      if (!this.totals.zeroRatedSales) this.totals.zeroRatedSales = null;
      if (!this.totals.vatableSales) this.totals.vatableSales = null;

      this.deferredUpdateOrder(!isEqual(oldTotals, this.totals));
    },

    deferredUpdateOrder(broadcastUpdate = true) {
      this.$nextTick(() => {
        if (isEqual(this.totals, this.activeOrder.totals)) return;
        this.updateOrder({
          orderId: this.activeOrderId,
          order: {
            totals: cloneDeep(this.totals),
          },
          isFromBroadcast: !broadcastUpdate,
        });
      });
    },

    removePayment(paymentIndex, payment) {
      this.$emit("removePayment", paymentIndex);

      if (["epayment", "mosaicpay_qrph"].includes(payment?.type)) {
        this.$emit("removePaymentInvoice", payment.externalId);
      }

      this.debouncedRecompute();
    },

    removeBillDiscount() {
      this.removeDiscount(0);
      this.isBillDiscountModalOpen = false;
    },

    removeDiscount(discountIndex) {
      if (this.activeOrder.billDiscount) {
        this.updateOrder({
          orderId: this.activeOrderId,
          order: { billDiscount: null },
        });

        if (OFFLOAD.sqliteOffloadReceipt) {
          this.sqliteUpsertReceipt({
            orderId: this.activeOrderId,
            action: OFFLOAD_RECEIPT_ACTION.DISCOUNT_REMOVE
          })
        }

        return;
      }

      this.$emit("removeDiscount", discountIndex);
      this.debouncedRecompute();
    },

    clearOrders() {
      this.separatorPositions = [];
      this.$emit("clearOrders");
    },

    async saveOrders() {
      if (this.disabledPlaceOrder) return;
      if (!this.$refs.lineItems) return;
      if (isEmpty(this.ordersArr)) return;

      const parsedAmounts = this.$refs.lineItems.map(
        (item) => item.parsedAmounts
      );
      this.disabledPlaceOrder = true;
      this.$emit("saveOrders", {
        parsedAmounts,
        grandTotals: this.totals,
        tableName: this.activeTable.table_name,
        separatorPositions: this.separatorPositions,
        adjustedSeparatorPositions: this.separatorPositions.map(
          (p) => p - (this.lastExistingLineItemIndex + 1)
        ),
        lineItems: this.ordersArr,
      });
    },

    updateOrders() {
      if (this.activeOrder.isSplitRequested) {
        this.$swal.warning(
          "Cannot add order",
          "Adding orders to split bill is not allowed"
        );
        return;
      }
      this.$emit("updateOrders");
    },

    billOrders(query = {}) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.UPDATE_STATE
        })
      }

      this.$emit("billOrders", {
        tableName: this.activeTable.table_name,
        query,
      });
    },

    async getLatestBillNum() {
      if (!(await checkCloudConnection())) return null;
      try {
        const response = await getLatestSeries();
        const ret = response.data.bill_num ? response.data.bill_num + 1 : null;
        return ret && ret >= this.lastBillNum ? ret : this.lastBillNum;
      } catch (e) {
        console.log(e);
      }
    },

    async setBilledState(isSplit = false) {
      if (this.isBilling) return;
      this.isBilling = true;
      let date = toIsoString(new Date());

      let bill_num = await seriesService.getAndIncrementBillNum() + 1;

      const updatedOrder = {
        orderId: this.activeOrderId,

        order: {
          isBilled: true,
          bill_num,
          billCreatedAt: date,
          billedShiftTable: cloneDeep(this.currentShift),
          // need explicit check here for true in case isSplit is an event
          isSplitRequested: isSplit === true,
          payments: [],
        },
      };

      if (OFFLOAD.sqliteOffloadReceipt) {
        updatedOrder.order.billedAt = await posDateWithCurrentTime();
      }

      this.updateOrder(updatedOrder);

      if (OFFLOAD.sqliteOffloadReceipt) {
        this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.BILL_ORDER
        })
      }

      this.isBilling = false;
    },

    async getReservations() {
      //open MEWS search customer modal
      this.isMoreActionModalOpen = false;
      this.isMewsGetReservationsModalOpen = true;
    },

    async setBilledAndSplit() {
      if (this.hasDiscount) {
        this.$swal.error(
          "Cannot split bill",
          "Please remove all discounts before splitting bill"
        );
        return;
      }

      if (OFFLOAD.sqliteOffloadReceipt && !OFFLOAD.sqliteOffloadSplitBill) {
        this.$swal.error("","This feature is being developed");
        return;
      }

      await this.setBilledState(true);
      this.billOrders();
    },

    openLineItemVoidModal(item) {
      if (this.hasBillLevelDiscount || item.discount) {
        this.$swal.error(
          "Cannot Void Item with discount. Remove Discount First."
        );
        return;
      }

      this.itemRequestTarget = cloneDeep(item);
      this.isItemActionModalOpen = false;
      this.isItemVoidModalOpen = true;
    },

    ratioizeTotals(totals, ratio) {
      const ratioizedTotals = cloneDeep(totals);

      ratioizedTotals.net *= ratio;
      ratioizedTotals.originalVat *= ratio;
      ratioizedTotals.rawPrice *= ratio;
      ratioizedTotals.serviceCharge *= ratio;
      ratioizedTotals.total *= ratio;
      ratioizedTotals.vat *= ratio;
      ratioizedTotals.vatExemptSales *= ratio;
      ratioizedTotals.zeroRatedSales *= ratio;

      return ratioizedTotals;
    },

    reabsorbVoidedQty(originLineItemId, voidedQty) {
      const originLineItem = this.ordersArr.find(
        (l) => l._id == originLineItemId
      );
      if (!originLineItem) return;

      const originLineItemClone = cloneDeep(originLineItem);

      const oldOriginQty = originLineItemClone.quantity;
      originLineItemClone.quantity += voidedQty;
      let qtyRatio = originLineItemClone.quantity / oldOriginQty;

      originLineItemClone.totals = this.ratioizeTotals(
        originLineItemClone.totals,
        qtyRatio
      );

      return originLineItemClone;
    },

    detachVoidedQty(lineItemId, voidedQty) {
      const lineItem = this.ordersArr.find((l) => l._id == lineItemId);
      const voidedClone = {
        ...cloneDeep(lineItem),
        _id: new Date().getTime(),
        isVoided: true,
        isCancelled: !this.activeOrder.isBilled,
        quantity: voidedQty,
        originLineItemId: lineItem._id,
      };

      const voidQtyRatio = voidedQty / lineItem.quantity;
      voidedClone.preVoidTotals = this.ratioizeTotals(
        lineItem.totals,
        voidQtyRatio
      );
      voidedClone.totals = cloneDeep(voidedClone.preVoidTotals);

      return voidedClone;
    },

    async applyVoid(item) {
      const targetLineItems =
        this.itemRequestTarget._ids?.flatMap((id) => {
          return this.ordersArr.filter((l) => l._id == id);
        }) ?? this.ordersArr.filter((l) => l._id == this.itemRequestTarget._id);

      let { voidedQty } = item;
      let voidedClone = null;

      const updates = [];

      for (let lineItem of targetLineItems) {
        let itemClone = cloneDeep(lineItem);
        if (voidedQty > 0) {
          itemClone.isVoided = itemClone.quantity <= voidedQty;
          itemClone.isCancelled =
            !this.activeOrder.isBilled && itemClone.isVoided;

          if (itemClone.isVoided) {
            itemClone.voidedQty = itemClone.quantity;
            itemClone.preVoidTotals = cloneDeep(itemClone.totals);
            voidedQty -= itemClone.quantity;
          } else {
            itemClone.quantity -= voidedQty;

            const qtyRatio =
              itemClone.quantity / (itemClone.quantity + voidedQty);
            itemClone.totals = this.ratioizeTotals(itemClone.totals, qtyRatio);

            voidedClone = this.detachVoidedQty(itemClone._id, voidedQty);

            updates.push({
              orderId: lineItem.isMerged
                ? lineItem.parentOrderId
                : this.orderId,
              lineItemId: voidedClone._id,
              lineItem: voidedClone,
            });

            voidedQty = 0;
          }
        } else {
          itemClone.isVoided = false;
          itemClone.isCancelled = false;

          if (itemClone.originLineItemId) {
            const originLineItemClone = this.reabsorbVoidedQty(
              itemClone.originLineItemId,
              itemClone.quantity
            );
            if (originLineItemClone) {
              updates.push({
                orderId: originLineItemClone.isMerged
                  ? originLineItemClone.parentOrderId
                  : this.orderId,
                lineItemId: originLineItemClone._id,
                lineItem: originLineItemClone,
              });

              itemClone.isDeleted = true;
            }
          }
        }

        updates.push({
          orderId: lineItem.isMerged ? lineItem.parentOrderId : this.orderId,
          lineItemId: lineItem._id,
          lineItem: itemClone,
        });
      }

      if (voidedQty > 0) {
        this.$swal.error(
          "Cannot void",
          "Voided quantity exceeds the quantity of the line item"
        );
        return;
      }

      updates.forEach((update) => this.updateOrderLineItem(update));
      this.refreshActiveOrder();

      this.isItemVoidModalOpen = false;
      this.debouncedRecompute();

      this.$nextTick(async() => {
        // check if there are no remaining orders
        if (this.ordersArr.filter((o) => !o?.isVoided).length === 0) {
          let nextVoidBillNum = undefined;
          if (this.activeOrder.isBilled) {
            nextVoidBillNum = await seriesService.getLastVoidBillNum() + 1;
            seriesService.setLastVoidBillNum(nextVoidBillNum);
          }

          this.updateOrder({
            orderId: this.activeOrderId,
            order: {
              isVoided: true,
              voidDateTime: moment().format("YYYY-MM-DD H:mm:ss"),
              void_bill_num: nextVoidBillNum,
              updateType: "void",
            },
          });

          if (OFFLOAD.sqliteOffloadReceipt) {
            const action = this.activeOrder.isBilled
              ? OFFLOAD_RECEIPT_ACTION.BILL_VOID
              : OFFLOAD_RECEIPT_ACTION.PENDING_VOID;

            this.sqliteUpsertReceipt({
              orderId: this.activeOrderId,
              action
            })
          }

          this.clearActiveOrderId();
          this.$swal.success("Order voided successfully!");
          this.$emit("voidSuccess");
        }
      });

      if (this.activeOrder.billDiscount) {
        this.$nextTick(() =>
          this.applyBillDiscount(this.activeOrder.billDiscount)
        );
      }

      const updatesHasVoided = updates.some((u) => u.lineItem.isVoided);
      if (item.isVoided || updatesHasVoided) {
        await this.printItemVoid(this.activeOrder, item.kotNum);
      }

      if (this.activeOrder.isOnlineDelivery) {
        this.$emit("refreshSelectedOrder", {
          productId: item.product.id,
          qty: item.voidedQty,
        });
      }
    },

    getLineItemDiscounts() {
      return this.activeOrder.orders.reduce((ret, o) => {
        if (o.discount) {
          ret.push(o.discount);
        }

        return ret;
      }, []);
    },

    generateReceiptArgs(customTotals = null, customOrder = null) {
      let discountContainer = [];

      if (customOrder) {
        customOrder.billDiscounts?.forEach((d) => {
          discountContainer.push({
            discountAmount: d.amount,
            discountPax: d.discountQuantity,
            ...d.customerDetails[0],
            ...d.discount,
          });
        });
      } else if (this.activeOrder.billDiscount) {
        this.activeOrder.billDiscount.customerDetails.forEach((detail) => {
          discountContainer.push({
            discountAmount:
              this.activeOrder.billDiscount.amount /
              this.activeOrder.billDiscount.discountQuantity,
            discountPax: this.activeOrder.billDiscount.discountQuantity,
            ...detail,
            ...this.activeOrder.billDiscount.discount,
          });
        });
      } else {
        //get line item discount
        discountContainer.push(...this.getLineItemDiscounts());
      }

      let receiptArgs = {
        formattedTotals: defaultTo(customTotals, this.formattedTotals),
        tableName: this.activeTable.table_name,
        receiptDetails: this.receiptDetails,
        customerDetails: {},
        discountContainer: discountContainer,
        payments: this.payments,
        dataContainer: this.dataContainer,
        activeBrandId: this.activeBrandId,
        serviceType: this.serviceType,
        bill_num: this.activeOrder.bill_num,
        receipt_num: "",
        mode: "bill",
      };

      console.log("generateReceiptArgs", {
        receiptDetails: this.receiptDetails,
      });

      return receiptArgs;
    },

    async showReceipt(customOrder = null, customTotals = null) {
      if (OFFLOAD.sqliteOffloadReceipt) {
        await this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.UPDATE_STATE
        })
      }

      let receiptArgs = this.generateReceiptArgs(customTotals, customOrder);
      let activeOrder = cloneDeep(defaultTo(customOrder, this.activeOrder));

      if (OFFLOAD.sqliteOffloadPOSFE1300
        && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
        && !isEmpty(activeOrder.receiptMergedWith)
      ) {
        activeOrder = await mergeAndUpdatePax(activeOrder);
      }

      let receiptString = await getReceiptPrintString(activeOrder, receiptArgs);
      let receiptItemString = await generateReceiptItemString(activeOrder);
      let printData = {
        print_type: "Receipt",
        html: receiptString,
        mode: receiptArgs.mode,
      };
      let printConfig = {
        location_id: this.receiptDetails.location_id,
      };

      print(printData, printConfig, receiptItemString, false);
    },

    async printItemVoid(order, kot) {
      //get product categories and put them in an array
      let orderProductCategories = uniq(
        map(order.orders, (o) =>
          get(o, "product.product_group.product_category_id")
        )
      );

      //generate kot printing html
      let kotPrintObj = await this.getKOTPrintObj(
        order,
        kot,
        orderProductCategories,
        true
      );
      this.kotHTMLContent = kotPrintObj.printTemplate;

      let printData = {
        print_type: "KOT",
        html: this.kotHTMLContent,
        orders: order.orders.map((o) => o.product),
      };

      let printConfig = {
        location_id: this.receiptDetails.location_id,
        kotItemObject: kotPrintObj.kotItemObject,
      };
      await print(printData, printConfig);
    },

    async getKOTPrintObj(
      orderDetail,
      lastKOT,
      orderProductCategories = [],
      isVoidItem = false,
      orderNumOverride = null
    ) {
      let receiptDetails = this.receiptDetails;
      let printTemplate = kotHtml;

      //initialize kotItemObject. this is for category based printing
      let kotItemObject = {};
      orderProductCategories.forEach(function (value) {
        kotItemObject[value] = "";
      });

      const getMovedKotNum = (order) => {
        if (order.prevKots?.[order.prevKots?.length - 2]) {
          return order.prevKots?.[order.prevKots?.length - 2];
        }

        return orderDetail.originTableDetails?.kot;
      };

      //generate kot items
      const orders = await kotGroupOrdersByServiceType(orderDetail);
      let kotItemsStr = '';
      for (let serviceType in orders) {
        const serviceHeaderString = `<span class="fs-17 kot-service-header">***${serviceType.toUpperCase()}***</span><br/><br/>`;

        kotItemsStr += serviceHeaderString;
        orders[serviceType].map(function (order, index) {
          // order && order.combinedKots?.includes(orderNumOverride ?? lastKOT) - check for move line item scenario where item being moved already exists in the table
          if (order && (order.prevKots?.[order.prevKots?.length - 1] == lastKOT || order.kot == lastKOT)) {
            let returnString = '';
            // added filter to return voided orders only, return all orders if not voided
            if ((isVoidItem && order.isVoided) || !isVoidItem) {
              returnString = `<span class="fs-17 ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">
                ${order.quantity} ${order.product.product_name} ${orderDetail?.originTableDetails ? `- item moved from ${orderDetail.originTableDetails?.tableName} (KOT# ${getMovedKotNum(order)})` : ''}
                </span><br>
                ${order.product.hasModifier == 1 ?
                  `${order.product.forcedMods.map(function (fmod) {
                    return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${fmod.quantity} ${fmod.modifier_name}</span><br>`
                  }).join(' ')}${order.product.unforcedMods.map(function (ufmod) {
                    return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${ufmod.quantity} ${ufmod.modifier_name}</span><br>`
                  }).join(' ')}` : ''}
                ${order.request != undefined && order.request != null && order.request.length > 0 ?
                  `<span class="reqs">&nbsp;&nbsp;${order.request}</span><br>` : ''}
                ${order.isVoided == true ? `<span class="kot-voided-item">- voided</span><br>` : ''}
                `;

              let categoryId = order.product.product_group?.product_category_id;
              if (!kotItemObject[categoryId]) {
                if (orderDetail?.originTableDetails) {
                  kotItemObject[categoryId] = serviceHeaderString;
                } else {
                  if (index == 0) kotItemObject[categoryId] += serviceHeaderString;
                }
              }

              kotItemObject[categoryId] = kotItemObject[categoryId].concat(returnString);
              kotItemsStr += returnString;
            }
          }
        });
      }

      //assign the kotItemStr to default key in kotItemObject
      kotItemObject["default"] = kotItemsStr;

      let service_type = this.serviceType == "Delivery" ? "Delivery" : "Table";
      let identifier =
        orderDetail.tableName == undefined
          ? orderDetail.tableId
          : orderDetail.tableName;

      let identifierString = "";
      switch (receiptDetails.account_type_id) {
        case 4:
          identifierString =
            orderDetail.brandId +
            "-" +
            this.dataContainer["qsr_" + orderDetail._id];
          break;
        case 2:
          if (this.serviceType == "Delivery") {
            identifierString =
              orderDetail.brandId +
              "-" +
              this.dataContainer["bm_del_" + orderDetail._id];
          } else {
            identifierString =
              this.serviceType.replace("-", " ") + "-" + identifier;
          }
          break;
        default:
          identifierString =
            this.serviceType.replace("-", " ") + "-" + identifier;
      }

      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let time =
        ("0" + today.getHours()).substr(-2) +
        ":" +
        ("0" + today.getMinutes()).substr(-2) +
        ":" +
        ("0" + today.getSeconds()).substr(-2);
      let dateTime = date + " " + time;

      let toReplace = {
        __REPRINT__: "",
        __ORDERIDENTIFIER__: service_type + " : " + identifierString,
        __DATETIME__: dateTime,
        __ORDERNUM__: lastKOT,
        __CASHIERNAME__: receiptDetails.cashier_name,
        __PAXCOUNT__: orderDetail.pax,
        __BRANDNAME__: receiptDetails.brand_name,
        __LOCATIONNAME__: receiptDetails.location,
        __MOREDETAILS__: orderDetail.orderDetail
          ? "Reference: " + orderDetail.orderDetail
          : "",
        __SERVICETYPE__:
          orderDetail?.originTableDetails || isVoidItem
            ? ""
            : orderDetail.serviceType.toUpperCase(),
        __KOTVOID__: isVoidItem
          ? '<span style="font-weight: bold;">KOT - VOID</span><br>'
          : "",
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
      printTemplate = printTemplate.replace(RE, function (matched) {
        return toReplace[matched];
      });

      console.log(
        convert(printTemplate.replace("__KOTITEMS__", kotItemsStr), {
          wordwrap: 130,
          preserveNewlines: true,
        })
      );

      return { printTemplate, kotItemObject };
    },

    finishPayment() {
      let receiptArgs = this.generateReceiptArgs();
      this.$emit("finishPayment", receiptArgs);
    },

    async voidOrders() {
      const result = await this.$openApproverModal();

      if (result.success) {
        this.updateOrder({
          orderId: this.activeOrderId,
          order: {
            isVoided: true,
            voidApprover: result.approver,
            voidDateTime: moment().format("YYYY-MM-DD H:mm:ss"),
            isUpdateSyncing: false,
            isUpdateSynced: false,
            updateType: "void",
          },
        });

        this.setLastEndingVoidOrder(this.activeOrderId);

        this.clearActiveOrderId();
        this.$swal.success("Order voided successfully!");
        this.$emit("voidSuccess");
      }
    },

    findItemIndex(item, isPreview = false) {
      return !isPreview
        ? this.orders.findIndex(
          (order) =>
            !order.kot &&
            this.areProductSkusEqual(order.product, item.product)
        )
        : this.orders.findIndex((order) =>
          this.areProductSkusEqual(order.product, item.product)
        );
    },

    addQuantity(item) {
      const index = this.orders.findIndex((o) => {
        return !o.kot && this.areProductSkusEqual(o.product, item.product);
      });

      if (index == -1) return;

      if (item.product.isPromo) {
        //for promo items
        const secondItemIndex = this.findIndexOfSecondItem(index);
        //add also the second items qty.
        this.orders[secondItemIndex].quantity++;
      }

      this.orders[index].quantity++;
      this.reapplyBillDiscount();
    },

    subtractQuantity(item) {
      const index = this.orders.findIndex((o) => {
        return !o.kot && this.areProductSkusEqual(o.product, item.product);
      });

      if (index == -1) return;

      if (item.product.isPromo) {
        //for promo items
        const secondItemIndex = this.findIndexOfSecondItem(index);
        //reduce also the second items qty.
        this.orders[secondItemIndex].quantity--;
        if (this.orders[secondItemIndex].quantity <= 0) {
          this.orders.splice(secondItemIndex, 1);
        }
      }

      this.orders[index].quantity--;
      if (this.orders[index].quantity <= 0) {
        this.orders.splice(index, 1);
      }

      this.reapplyBillDiscount();
    },

    removeLineItem(item) {
      const index = this.findItemIndex(item);

      if (item.product.isPromo) {
        //for promo items
        const secondItemIndex = this.findIndexOfSecondItem(index);
        //remove also the second items
        this.orders.splice(secondItemIndex, 1);
      }

      this.orders.splice(index, 1);
      this.reapplyBillDiscount();
    },

    findIndexOfSecondItem(primaryItemIndex) {
      //find index of the second items of promo
      return this.orders.findIndex(
        (o) =>
          o.product.isSecondItem == true &&
          o.product.promo_id ==
          this.orders[primaryItemIndex].product.promo_id &&
          o.product.id == this.orders[primaryItemIndex].product.second_item_id
      );
    },

    addRequest(item, parsedTotals) {
      this.itemRequestTarget = { ...item, ...parsedTotals };
      this.isItemRequestModalOpen = true;
    },

    showItemAction(item, parsedTotals) {
      this.itemRequestTarget = { ...item, ...parsedTotals };
      this.isItemActionModalOpen = true;
    },

    areProductSkusEqual(product1, product2) {
      const relevantFields = [
        "id",
        "forcedMods",
        "unforcedMods",
        "product_name",
        "price",
      ];
      return isEqual(
        pick(product1, relevantFields),
        pick(product2, relevantFields)
      );
    },

    getProductString(item, params = []) {
      const productProps = [
        "id",
        "forcedMods",
        "unforcedMods",
        "product_name",
        "price",
      ];
      const product = pick(item.product, productProps);
      const obj = params.reduce(
        (obj, value) => ({ ...obj, [value]: item[value] }),
        {}
      );
      return JSON.stringify({
        ...product,
        isVoided: Boolean(item.isVoided),
        ...obj,
      });
    },

    async changeService(serviceName, item, quantity = null) {
      if (item.isVoided) {
        this.$swal.error(
          "Cannot Apply change service type in voided items. Remove Void First."
        );
        return;
      }
      const serviceTypeId = this.serviceTypes.find((i) =>
        i.service_name.includes(serviceName)
      )?.id;
      if (!quantity) {
        const existingIndex = this.orders.findIndex(
          (o) => this.areProductSkusEqual(o.product, item.product) && !o._id
        );
        if (existingIndex >= 0) {
          this.orders[existingIndex].serviceTypeId = serviceTypeId;
          this.orders[existingIndex].serviceName = serviceName;
          this.debouncedRecompute();
          return;
        }
      }
      const targetLineItems =
        this.itemRequestTarget._ids?.flatMap((id) => {
          return this.ordersArr.filter((l) => l._id == id);
        }) ?? this.ordersArr.filter((l) => l._id == this.itemRequestTarget._id);

      let remainingDeductAmount = quantity;
      const promises = [];
      for (const lineItem of targetLineItems) {
        const order = cloneDeep(lineItem);
        if (order.serviceTypeId !== serviceTypeId) {
          const existingIndex = this.orders.findIndex((i) => {
            return (
              this.getProductString(i, ["kot", "serviceTypeId"]) ===
              this.getProductString({ ...order, serviceTypeId }, [
                "kot",
                "serviceTypeId",
              ])
            );
          });
          const originalIndex = this.orders.findIndex((i) => {
            return (
              this.getProductString(i, ["kot", "serviceTypeId"]) ===
              this.getProductString(order, ["kot", "serviceTypeId"])
            );
          });

          const availableQuantity = order.quantity - remainingDeductAmount;
          let deductedQuantity = 0;
          if (availableQuantity >= 0) {
            deductedQuantity = remainingDeductAmount;
            remainingDeductAmount = 0;
          } else {
            deductedQuantity = order.quantity;
            remainingDeductAmount = Math.abs(availableQuantity);
          }

          if (deductedQuantity > 0) {
            if (existingIndex >= 0) {
              this.orders[existingIndex].quantity += deductedQuantity;
              this.orders[existingIndex].isServiceChanged = true;
              promises.push(this.$nextTick());
            } else {
              this.orders.push({
                ...cloneDeep(order),
                _id: new Date().getTime(),
                serviceTypeId,
                serviceName,
                quantity: deductedQuantity,
                isServiceChanged: true,
              });
              promises.push(this.$nextTick());
            }

            const originalOrder = this.orders[originalIndex];
            originalOrder.quantity -= deductedQuantity;
            originalOrder.isServiceChanged = true;
            if (originalOrder.quantity <= 0) {
              this.orders.splice(originalIndex, 1);
              promises.push(this.$nextTick());
            }
          }
        }
      }

      try {
        await Promise.all(promises);

        this.orders
          .filter((i) => i.isServiceChanged)
          .map((order) => {
            const lineItemIndex = this.$refs.lineItems.findIndex((l) => {
              return (
                this.getProductString(l.item, ["kot", "serviceTypeId"]) ===
                this.getProductString(order, ["kot", "serviceTypeId"])
              );
            });
            order.totals = this.$refs.lineItems[lineItemIndex].parsedAmounts;
            delete order.isServiceChanged;
          });

        const kotBreakdowns = this.orders.reduce((acc, curr) => {
          const { kotNum, net, serviceCharge, total, vat } = curr.totals;
          const existingOrder = acc.find((order) => order.kotNum === kotNum);
          if (existingOrder) {
            existingOrder.net += net;
            existingOrder.serviceCharge += serviceCharge;
            existingOrder.total += total;
            existingOrder.vat += vat;
          } else {
            acc.push({ kotNum, vat, serviceCharge, net, total });
          }
          return acc;
        }, []);

        this.updateOrder({
          orderId: this.orderId,
          order: { kotBreakdowns, orders: toRaw(this.orders) },
        });

        this.isItemActionModalOpen = false;
        this.debouncedRecompute();
      } catch (e) {
        console.log("changeService error: ", e);
      }
    },

    async showItemDiscounts(item) {
      if (
        !ENABLE_DISCOUNT_ON_PROMO_ITEMS &&
        (item.product.isPromo || item.product.isSecondItem)
      ) {
        this.$swal.error("Cannot Apply item discount in promo items.");
        return;
      }

      if (item.isVoided) {
        this.$swal.error(
          "Cannot Apply item discount in voided items. Remove Void First."
        );
        return;
      }
      if (this.hasBillLevelDiscount) {
        this.$swal.error(
          "Cannot apply item level discount when bill level discount is applied"
        );
        return;
      }

      try {
        // Only fetch new line discounts if there's connection and when this
        // function is called (meaning modal is opened)
        const forceRefresh = await checkCloudConnection();
        const discString = await getAllDiscountItems(forceRefresh);
        this.itemRequestTarget = item;
        this.itemDiscounts = discString.data.discounts;
        this.discountApprovers = discString.data.approvers;
        this.isItemActionModalOpen = false;
        this.isItemDiscountModalOpen = true;
      } catch (error) {
        console.error(error);
      }
    },

    showMoveItemTables(item) {
      if (item.isVoided) {
        this.$swal.error(
          "Cannot Apply Move tables in voided items. Remove Void First."
        );
        return;
      }
      this.itemRequestTarget = item;
      this.isItemMoveTableModalOpen = true;
    },

    itemMoveTable(tableObject) {
      this.isItemMoveTableModalOpen = false;
      this.isItemActionModalOpen = false;
      this.$emit("itemMoved", tableObject);
    },

    saveItemRequest(request) {
      const itemIndex = this.findItemIndex(this.itemRequestTarget);
      this.$emit("saveItemRequest", { targetItemIndex: itemIndex, request });
      this.isItemRequestModalOpen = false;
    },

    saveOrderDetail(orderDetail) {
      if (this.orderId) {
        this.updateOrder({ orderId: this.orderId, order: { ...orderDetail } });
      } else {
        this.$emit("saveOrderDetail", orderDetail);
      }

      this.isOrderDetailsModalOpen = false;
    },

    openPaxModal() {
      if (this.activeOrder.billDiscount) {
        this.$swal.error("Please remove bill discount before changing pax");
        return;
      }

      this.isPaxModalOpen = true;
    },

    updatePax(pax) {
      if (this.orderId) {
        this.updateOrder({ orderId: this.orderId, order: { pax } });
      }

      this.$emit("updatePax", pax);
      this.isPaxModalOpen = false;

      if (this.activeOrder.billDiscount) {
        this.applyBillDiscount(this.activeOrder.billDiscount);
      } else {
        this.debouncedRecompute();
      }
    },

    async applyDiscount(discount) {
      const targetLineItems =
        this.itemRequestTarget._ids?.flatMap((id) => {
          return this.ordersArr.filter((l) => l._id == id);
        }) ?? this.ordersArr.filter((l) => l._id == this.itemRequestTarget._id);

      const originalDiscountAmt = discount.discountPax;
      let discountAmt = discount.id ? discount.discountPax : 0;

      const updates = [];

      for (let lineItem of targetLineItems) {
        let lineItemDiscount = undefined;
        if (discountAmt > 0) {
          lineItemDiscount = cloneDeep(discount);
          lineItemDiscount.discountPax = Math.min(
            discountAmt,
            lineItem.quantity
          );
          lineItemDiscount.discountAmount *=
            lineItemDiscount.discountPax / originalDiscountAmt;
          lineItemDiscount.netSales *=
            lineItemDiscount.discountPax / originalDiscountAmt;
          discountAmt -= lineItemDiscount.discountPax;
        }

        // check if discount after placed order
        if (has(lineItem, "kot")) {
          const lineItemIndex = this.ordersArr.findIndex(
            (l) => l._id == lineItem._id
          );
          this.ordersArr.splice(lineItemIndex, 1, {
            ...this.ordersArr[lineItemIndex],
            ...lineItem,
            discount: lineItemDiscount,
          });

          await this.$nextTick();
          const parsedAmounts = this.$refs.lineItems.map(
            (item) => item.parsedAmounts
          );
          lineItem.preDiscountTotals = cloneDeep(lineItem.totals);
          lineItem.totals = parsedAmounts[lineItemIndex];
        }

        updates.push({
          orderId: lineItem.isMerged ? lineItem.parentOrderId : this.orderId,
          lineItemId: lineItem._id,
          lineItem: {
            discount: lineItemDiscount,
            totals: lineItem.totals,
            preDiscountTotals: lineItem.preDiscountTotals,
          },
        });
      }

      updates.map((update) => this.updateOrderLineItem(update));
      this.refreshActiveOrder();

      // recompute kot breakdowns
      const kotBreakdowns = this.orders.reduce((acc, curr) => {
        const { kotNum, net, serviceCharge, total, vat } = curr.totals;
        const existingOrder = acc.find((order) => order.kotNum === kotNum);
        if (existingOrder) {
          existingOrder.net += net;
          existingOrder.serviceCharge += serviceCharge;
          existingOrder.total += total;
          existingOrder.vat += vat;
        } else {
          acc.push({ kotNum, vat, serviceCharge, net, total });
        }
        return acc;
      }, []);

      this.updateOrder({
        orderId: this.activeOrderId,
        order: { kotBreakdowns, orders: toRaw(this.orders) },
      });

      if (OFFLOAD.sqliteOffloadReceipt) {
        this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.DISCOUNT_ORDER
        })
      }

      this.isItemDiscountModalOpen = false;
      this.debouncedRecompute();
    },

    applyComp(comp) {
      const itemIndex = this.findItemIndex(this.itemRequestTarget, true);

      if (this.ordersArr[itemIndex].comp && !comp.compReasonId) {
        delete this.ordersArr[itemIndex].comp;
      } else if (comp.compReasonId) {
        this.ordersArr[itemIndex].comp = comp;
      }

      this.updateOrder({
        orderId: this.orderId,
        order: {
          orders: cloneDeep(this.ordersArr),
        },
      });

      this.isCompItemModalOpen = false;
      this.debouncedRecompute();
    },

    removeComp() {
      const itemIndex = this.findItemIndex(this.itemRequestTarget, true);

      delete this.ordersArr[itemIndex].comp;

      this.updateOrder({
        orderId: this.orderId,
        order: {
          orders: cloneDeep(this.ordersArr),
        },
      });

      this.isCompItemModalOpen = false;
      this.debouncedRecompute();
    },

    openBillLevelDiscountModal() {
      if (!ENABLE_DISCOUNT_ON_PROMO_ITEMS && this.hasPromoItem) {
        this.$swal.error(
          "Can't apply bill level discount in order with Promo items."
        );
        return;
      }

      if (this.hasLineItemDiscount) {
        this.$swal.error(
          "Please remove line item discount before applying bill level discount"
        );
        return;
      }

      this.isBillDiscountModalOpen = true;
    },

    applyBillDiscount(discount) {
      const amount = calculateDiscountAmount(
        discount,
        this.getAggregateTotals(),
        this.activeOrderPax
      );
      this.updateOrder({
        orderId: this.activeOrderId,
        order: { billDiscount: { ...discount, amount } },
      });

      this.isBillDiscountModalOpen = false;

      // recompute kot breakdowns
      const kotBreakdowns = this.orders.reduce((acc, curr) => {
        const { kotNum, net, serviceCharge, total, vat } = curr.totals;
        const existingOrder = acc.find((order) => order.kotNum === kotNum);
        if (existingOrder) {
          existingOrder.net += net;
          existingOrder.serviceCharge += serviceCharge;
          existingOrder.total += total;
          existingOrder.vat += vat;
        } else {
          acc.push({ kotNum, vat, serviceCharge, net, total });
        }
        return acc;
      }, []);

      // this.updateOrder({ orderId: this.activeOrderId, order: { kotBreakdowns, orders: toRaw(this.orders) } });

      this.$nextTick(() => this.recomputeTotals(null, true));

      if (OFFLOAD.sqliteOffloadReceipt) {
        this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.DISCOUNT_ORDER
        })
      }
    },

    async reapplyBillDiscount() {
      if (this.activeOrder.billDiscount) {
        await this.$nextTick();
        this.applyBillDiscount(this.activeOrder.billDiscount);
      } else {
        this.debouncedRecompute();
      }
    },

    async mergeTablesToActiveTable(tableIds) {
      const receiptMergedWith = [];

      for (const po of this.pendingOrders) {
        if (!po.tableId) continue;
        if (tableIds.includes(po.tableId)) {
          this.updateOrder({
            orderId: po._id,
            order: {
              tableMergedTo: this.activeOrder.tableId,
              receiptMergedTo: this.activeOrderId,
              tableMergedToName: this.activeOrder.tableName,
            },
          });
          receiptMergedWith.push(po._id);
        } else if (po.tableMergedTo == this.activeOrder.tableId) {
          this.updateOrder({
            orderId: po._id,
            order: {
              tableMergedTo: null,
              receiptMergedTo: null,
              tableMergedToName: "",
            },
          });
        }

        if (OFFLOAD.sqliteOffloadPOSFE1300) {
          await this.$nextTick();

          await this.sqliteUpsertReceipt({
            orderId: po._id,
            action: OFFLOAD_RECEIPT_ACTION.MERGE_TABLE
          });
        }
      }

      this.updateOrder({
        orderId: this.activeOrderId,
        order: { tableMergedWith: cloneDeep(tableIds), receiptMergedWith },
      });

      if (OFFLOAD.sqliteOffloadPOSFE1300) {
        await this.$nextTick();

        await this.sqliteUpsertReceipt({
          orderId: this.activeOrderId,
          action: OFFLOAD_RECEIPT_ACTION.MERGE_TABLE
        });

        bus.emit("triggerUpdateTableStates", this.activeOrder.tableId);
      } else {
        this.$emit("tableUpdated");
      }

      this.isTableActionsModalOpen = false;
    },

    async changeActiveOrderTable(data) {
      const { tableId, mode } = data;
      const activeOrder = cloneDeep(this.activeOrder);
      const tdb = new ServiceTableDetailBridge();
      const ob = new OrderBridge();

      if (mode === "merge") {
        return this.mergeTablesToActiveTable(data.tableIds);
      }

      const existingOrder = cloneDeep(
        this.pendingOrders.find((order) => order.tableId == tableId)
      );

      if (existingOrder) {
        existingOrder.pax += this.activeOrderPax;
        existingOrder.kots.push(...this.activeOrder.kots);
        existingOrder.kotBreakdowns.push(...this.activeOrder.kotBreakdowns);
        existingOrder.orderDetails = `${existingOrder.orderDetails}\n${this.activeOrder.orderDetails}`;
        existingOrder.orders.push(...this.activeOrder.orders);
        existingOrder.totals = mapValues(
          existingOrder.totals,
          (total, key) => total + this.activeOrder.totals[key]
        );
        existingOrder.transactionCreatedAtKot = {
          ...existingOrder.transactionCreatedAtKot,
          ...this.activeOrder.transactionCreatedAtKot,
        };

        this.updateOrder({ orderId: existingOrder._id, order: existingOrder });
        this.deleteOrder(this.activeOrderId);
        this.clearActiveOrderId();

        if (OFFLOAD.sqliteOffloadPOSFE1300) {
          // Delete order json in the origin table
          await ob.deleteOrderById(activeOrder._id);
        }
      } else {
        this.updateOrder({
          orderId: this.activeOrderId,
          order: {
            tableId,
            tableName: data.table.table_name,
          },
        });
      }

      if (OFFLOAD.sqliteOffloadPOSFE1300) {
        // Ensure the Vue component is updated before proceeding
        await this.$nextTick();

        const orderId = existingOrder ? existingOrder._id : activeOrder._id;

        await this.sqliteUpsertReceipt({
          orderId,
          action: OFFLOAD_RECEIPT_ACTION.MOVE_TABLE
        });

        // Update the 'receipt_local_id' in the destination table
        await tdb.update({
          table_id: tableId,
          receipt_local_id: orderId
        });

        // Reset the 'receipt_local_id' in the origin table
        await tdb.update({
          table_id: activeOrder?.tableId ?? null,
          receipt_local_id: null
        });

        await this.$nextTick();

        // Emit an event to trigger the update of table states
        bus.emit("triggerUpdateTableStates", tableId);
      }

      this.isTableActionsModalOpen = false;
      this.$emit("tableUpdated");
      if (existingOrder) {
        this.$router.push({
          name: "tables",
          params: {
            serviceType: existingOrder.serviceType,
            serviceAreaId:
              existingOrder.originalServiceTypeId ??
              existingOrder.serviceTypeId,
          },
        });
      }
    },

    openTableActionsModal() {
      if (this.hasDiscount) {
        this.$swal.error(
          "Cannot move/merge",
          "Please remove all discounts first"
        );
        return;
      }

      if (this.activeOrder.tableMergedTo) {
        this.$swal.warning(
          `This table is merged to ${this.activeOrder.tableMergedToName}. Please manage that table instead.`
        );
        return;
      }

      this.isTableActionsModalOpen =
        this.hasTable && !this.activeOrder.isBilled;
    },

    showPaymentQR(payment) {
      console.log(`OrdersPanel - showPaymentQR:`);
      console.log(`payment: `, payment);

      const params = {
        paymentProvider: payment?.type == "mosaicpay_qrph" ? "paymongo" : "xendit",
        orderId: this.orderId,
        bill: this.activeOrder.bill_num,
        table: this.activeOrder.tableId ?? null,
        payment: payment.paymentType,
        amount: payment.amount,
        status: payment.status,
        invoiceUrl: payment.invoiceUrl,
        externalId: payment.externalId,
      };

      if (payment.type === "mosaicpay_qrph") {

        if (payment.status == PAYMENT_INVOICE_STATUSES.PAID) {
          const formattedPaymentAmount = parseFloat(payment.amount).toFixed(2);

          this.$customSwal({
            stackedIcons: [
              { src: '/images/mosaicpay-qrph.svg', alt: 'Mosaic Logo' },
              { src: '/images/icon_verified-check.svg', alt: 'Payment Received Icon' },
            ],
            title: "Payment Received",
            text: `₱ ${formattedPaymentAmount}`,
            customClass: {
              icon: 'no-border-icon',
              title: 'custom-title-class',
              content: 'custom-content-class',
            },
            buttonText: "OK",
            className: 'center-btn'
          });

          return;
        }

        // if payment has failed, show failed pop-up dialog
        if (payment.status == PAYMENT_INVOICE_STATUSES.FAILED) {
          this.$customSwal({
            iconHtml: '<img src="/images/icon_close-circle.svg" alt="QR Code Icon"/>',
            title: "Payment Failed",
            text: "Transaction has been cancelled. We are unable to process the payment. Please choose another payment method or try again later.",
            customClass: {
              icon: 'no-border-icon',
              title: 'custom-title-class',
              content: 'custom-content-class',
            },
            buttonText: "OK",
            className: 'center-btn'
          });

          return;
        }

        // if payment has expired, show expired pop-up dialog
        if (payment.status == PAYMENT_INVOICE_STATUSES.EXPIRED) {
          this.$customSwal({
            iconHtml: '<img src="/images/icon_qr-code.svg" alt="QR Code Icon"/>',
            title: "QR Code Expired",
            text: "This QR code has expired. Please generate a new code to proceed with your transaction.",
            customClass: {
              icon: 'no-border-icon',
              title: 'custom-title-class',
              content: 'custom-content-class',
            },
            buttonText: "OK",
            className: 'center-btn'
          });

          return;
        }

        // qrCodeImageUrl must not be empty.. if not set, it will trigger a new API call to generate QRCode..
        // behavior is observed in PaymentQrCodeModal - checkQR() method
        params.paymentIntentId = payment?.paymentIntentId;
        params.description = payment?.description;
        params.qrCodeImageUrl = payment?.qrCodeImageUrl;
        params.qrGeneratedAt = payment?.qrGeneratedAt;
      }

      this.setPaymentQRDetails(params);
      this.setShowPaymentQRModal(true);
    },

    productsHasLabelPrint() {
      const allProducts = this.activeOrder.orders.reduce(
        (products, order) => (products = [...products, order.product]),
        []
      );
      return Boolean(allProducts.find((product) => product.label_printing));
    },

    checkIfActiveOrderHasSingleKOT() {
      if (this.activeOrder.kots.length === 1) {
        return this.printLabelStickers(this.activeOrder.kots[0]);
      }

      return this.setPrintStickerModalOpen();
    },

    async printLabelStickers(selectedKOT) {
      if (selectedKOT) {
        return await this.processPrintLabel(this.activeOrder, selectedKOT);
      } else {
        console.log("No KOT is selected");
      }
    },

    setPrintStickerModalOpen() {
      this.isMoreActionModalOpen = false;
      this.isPrintStickerModalOpen = true;
    },

    gotoDeliveryPage() {
      this.$router.push({
        name: "delivery",
        params: {
          channelName: this.activeOrder.channelName,
          lastDeliveryOrderId: this.activeOrder._id,
          shortOrderId: this.activeOrder.shortOrderId,
        },
      });
    },

    async chargeToRoom(data) {
      this.isMewsGetReservationsModalOpen = false;
      //Call MEWS' Add Reservation Product API
      try {
        await addReservationProduct({
          reservation_id: data.reservationId,
          orders: this.activeOrder.orders,
        });

        this.setBilledState();
        localStorage.setItem(
          "activeMewsOrder",
          JSON.stringify(this.activeOrder)
        );
        this.billOrders({ isChargeToRoom: true });
        this.$swal.success(
          "Success",
          "Transaction will be charged to room. Kindly settle."
        );
      } catch (error) {
        console.log(error);
        this.$swal.error(
          "Posting data to MEWS failed. Please check your internet connection and try again."
        );
      }
    },
  },
};
</script>

<style scoped>
div.totals-container {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.075),
    0px 8px 16px rgba(0, 0, 0, 0.05);
}

.remove-button {
  color: var(--danger);
  font-size: 11px;
  cursor: pointer;
}

.remove-button:active {
  transform: scale(0.98);
}

.orders-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -8px;
  margin-bottom: 12px;
  margin-top: 12px;
}

div.orders-panel {
  height: calc(100vh - 84px);
}

.forced-offline-mode div.orders-panel {
    height: calc(100vh);
}

@media (min-width: 992px) {
    .forced-offline-mode div.orders-panel {
        height: calc(100vh - 48px);
    }
}

.forced-offline-mode div.orders-panel.delivery {
  height: calc(100vh - 230px);
  border: 1px solid #dbdbdb;
  padding: 10px 0 !important;
  margin-top: 15px;
  border-radius: 10px;
}

.panel-header i.fa {
  color: #4e4e4e;
  background-color: #e2e2e2;
  padding: 7px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.panel-header>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.panel-header>div+div {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-header .header-label {
  color: #4c5b67;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
}

.orders-table-header {
  background-color: #f7f5f5;
  color: #4c5b67;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.7rem;
  margin-top: 8px;
  cursor: pointer;
}

.hidden {
  height: 0 !important;
  overflow: hidden !important;
  display: none !important;
}

.no-orders-placeholder {
  position: relative;
  height: 50vh;
  width: 100%;
}

.no-orders-placeholder>* {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #656565;
}

.no-orders-placeholder>.fa.fa-box-open {
  padding-bottom: 32px;
  font-size: 3rem;
}

.no-orders-placeholder>h2 {
  padding-top: 40px;
  font-size: 1rem;
  font-weight: bold;
}

.orders-panel.payment .totals-container .total {
  background-color: #feffd9;
}

.totals-container span,
.totals-container strong {
  font-size: 11px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  line-height: 0.9rem;
}

.totals-container span {
  padding-right: 5px;
  text-align: right;
  font-size: 14px;
}

.totals-container .payment-cell span,
.totals-container .payment-cell strong {
  font-size: 0.7rem;
  line-height: 0.7rem;
}

.locked,
.locked * {
  pointer-events: none;
  cursor: unset;
}

::v-deep(.no-icon-button) .label {
  font-size: 16px;
}

.single-kot {
  white-space: nowrap;
}

.delivery div.totals-container {
  margin: 0 8px 10px !important;
}

.delivery .panel-header {
  display: none;
}

.delivery .orders-table-header {
  margin-bottom: 0 !important;
}

.delivery .orders-container {
  margin-top: 0;
}

#mosaic-pos-app .just-code-basic .orders-panel.delivery .totals-container {
  margin: 0 8px 10px !important;
}

.payment-cell .bill-qr {
  cursor: pointer;
}

.payment-cell .paid .bill-qr {
  color: green;
}

.payment-cell .paid .remove-button {
  color: gray;
  pointer-events: none;
  opacity: 0.3;
}

.foodpanda {
  text-transform: lowercase;
}
</style>
