import axios from "@/spa/plugins/axios";

export const generateAndSendFile = async (location, businessDate) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    return await axios.post(route('generate.and.send.file'), {
        location_id: location,
        business_date: businessDate
    }, { headers });
}

export const checkIfPreviousTxtFileIsSent = async () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    
    return await axios.post(route('check.previous.text.file'), {
        location_id: window.locationId
    }, { headers });
}

export const resendTextFile = async(accreditationId) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    return await axios.post(route('resend.text.file', [accreditationId]), { headers });
};

export default {
    generateAndSendFile,
    checkIfPreviousTxtFileIsSent,
    resendTextFile
}