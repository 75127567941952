<template>
    <base-modal
        v-bind="$attrs"
        min-width="0"
        max-width="800"
        min-height="100px"
        width="100%"
        :confirm-label="item && !item.isVoided ? 'Void' : 'Remove Void'"
        @confirm="toggleItemVoid"
        @before-open="init"
        >
        <template #title>Void Item</template>

        <hr />

        <!--
            The v-ifs here are critical, otherwise the POS becomes unresponsive
            upon removing an item with a special request from the cart
         -->
        <div v-if="item" class="d-flex d-lg-none align-items-center justify-content-between mb-3">
            <div class="item-details" v-if="item.product">
                <h2 class="item-name">{{ item.product.product_name }}</h2>
            </div>
            <div class="item-price" v-if="item.totals">
                <p class="mx-0 my-0">₱ {{ item.totals.rawPrice }}</p>
            </div>
        </div>
        
        <div class="row" v-if="item && !item.isVoided && item.quantity > 0">
            <div class="col-md-12 d-lg-block d-none">
                <label class="ml-4">
                    <h5> Quantity</h5>
                </label>
            </div>
            <div class="col-lg-10 col-12 d-flex order-qty align-items-center justify-content-center">
                <div class="min-qty change-pax-qty" @click="reduceVoidQty">-</div>
                <h4 class="cust-disc-pax-qty">{{ voidedQty }}</h4>
                <div class="plus-qty change-pax-qty" @click="addVoidQty">+</div>
            </div>
        </div>
        <div class="row d-flex mt-2">
            <div class="col-lg-12">
                <label class="mx-0 my-0">
                    Select void reason
                </label>
                <div class="reasons-row d-lg-flex flex-wrap d-none d-lg-block">
                    <m-card
                        v-for="reason in voidReasons"
                        :key="reason.id"
                        :label="reason.cancellation_reason_name"
                        :active="reason.id == selectedVoidReason?.id"
                        clickable
                        ratio="1:1"
                        class="col-4 mx-0"
                        @click="selectReason(reason)"
                    />
                </div>
                <div class="reasons-mobile d-block d-lg-none">
                    <div v-for="reason in voidReasons" :key="reason.id">
                        <input type="radio" 
                        :id="reason.id" 
                        name="voidReason" 
                        class="mr-1"
                        v-model="selectedVoidReasonId" 
                        :value="reason.id"
                        @change="selectReason(reason)"
                    />
                    <label>
                        {{ reason.cancellation_reason_name }}
                    </label>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import MCard from '@/spa/components/common/MCard';
import BaseModal from '@/spa/components/modals/BaseModal';
import { getVoidReasons } from '@/spa/services/cashier-service';

import cloneDeep from 'rfdc/default';

export default {
    name: 'LineItemVoidModal',

    components: {
        MCard,
        BaseModal,
    },

    props: {
        item: {
            type: Object,
            default: () =>({}),
        },
        orderBilled: {
            type: Boolean,
            default: false,
        },
        triggeredFrom: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            selectedVoidReason: null,
            voidReasons: [],
            isVoided: false,
            voidedQty: 0
        };
    },

    methods: {
        async init() {
            this.selectedVoidReason = null;
            await this.fetchVoidReasons();
            const item = cloneDeep(this.item);
            if (this.item.isVoided) {
                this.selectedVoidReason = item.voidReason;
            }

            this.isVoided = item?.isVoided || false
            this.voidedQty = !item.isVoided ? item.quantity : (item.voidedQty || 1)
        },

        async fetchVoidReasons() {
            try {
                const response = await getVoidReasons();
                this.voidReasons = response.data;
            } catch (e) {
                console.error(e);
                this.voidReasons = [];
            }
        },

        selectReason(reason) {
            this.selectedVoidReason = reason;
        },

        async toggleItemVoid() {
            if (!this.selectedVoidReason && !this.item.isVoided) {
                this.$swal.warning('Please select a reason');
                return;
            }

            const approverResponse = await this.$openApproverModal();
            if (!approverResponse.success) {
                if (approverResponse.cancelled) {
                    this.$swal.warning('Void authorization cancelled');
                }

                return;
            }
            let {item, voidedQty} = this;
            let isVoided = !item.isVoided;
            const isCancelled = !this.orderBilled && isVoided;
            const voidReason = isVoided ? cloneDeep(this.selectedVoidReason) : null;
            const isVoidAfterBill = isVoided && this.orderBilled ? true : false; //set to true if applied after bill

            // BILL SETTINGS PAGE GAP TEMP LOGIC
            // check if triggered from bill settings page, if yes
            // set voidquantity to zero
            if(this.triggeredFrom === 'bill_settings') {
                voidedQty = 0;
            } else {
                item.quantity = (isVoided ? (item.quantity - voidedQty) : (item.quantity + voidedQty))
                if (!isVoided) {
                    item.quantity = voidedQty
                    voidedQty = 0
                }
                isVoided &&= item.quantity === 0;
            }

            this.$emit('itemVoidUpdated', { ...item, isVoided, voidReason, isCancelled, isVoidAfterBill, voidedQty });
        },

        addVoidQty() {
            this.voidedQty += this.voidedQty !== this.item.quantity ? 1 : 0;
        },

        reduceVoidQty() {
            this.voidedQty -= this.voidedQty > 1 ? 1 : 0;
        }
    },
};
</script>

<style scoped>
.reasons-row {
    gap: 16px;
    margin: 24px;
}
</style>
