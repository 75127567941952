<template>
<div class="payment-method-selector">
            <m-card
                v-for="method in paymentMethods"
                class="payment-method-card"
                :class="{ active: method.name === selectedPaymentMethod }"
                clickable
                ratio="1:1"
                :label="method.type === 'mosaicpay_qrph' ? '' : method.name"
                :active="method.name === selectedPaymentMethod"
                @click="$emit('selectPaymentMethod', method)"
            >
                <template #default>
                    <!-- Show specific logo for mosaicpay_qrph -->
                    <div v-if="method.type === 'mosaicpay_qrph'" class="svg-container">
                        <img
                            src="/images/mosaicpay-qrph.svg"
                            alt="MosaicPay Logo"
                            class="responsive-logo"
                        />
                    </div>

                    <!-- Default Icons for other types -->
                    <i
                        v-else
                        class="fas"
                        :class="{
                            'fa-credit-card': method.type === 'card',
                            'fa-money-bill-wave': method.type === 'cash',
                            'fa-money-check-alt': method.type === 'voucher' || method.type === 'check',
                            'fa-qrcode': method.type === 'epayment',
                        }"
                    />
                </template>
            </m-card>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MCard from '@/spa/components/common/MCard';
import { getPaymentMethods } from '@/spa/services/cashier-service';
import { OrderBridge } from '../../../mobile_bridge/offload/offload-receipt';
import { OFFLOAD } from '../../constants';

export default {
    name: 'PaymentMethodSelector',

    components: { MCard },

    props: {
        selectedPaymentMethod: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            paymentMethods: [],
        };
    },

    computed: {
        ...mapGetters(['activeOrder']),
    },

    mounted() {
        this.fetchPaymentMethods();
    },

    methods: {
        async fetchPaymentMethods() {
            this.paymentMethods = [];
            let brandId = null;

            // On SQLite-enabled clients, get the brand ID from SQLite
            // Multiterminal requires this approach
            if (OFFLOAD.sqliteOffloadReceipt) {
                const orderId = this.$route.params.orderId;
                const ob = new OrderBridge();
                const order = await ob.getOrderById(parseInt(orderId));
                const orderData = JSON.parse(order.order_data);

                brandId = orderData.brandId;
            } else {
                // For non-SQLite-enabled clients, get it from the activeOrder
                // in the store
                brandId = this.activeOrder.brandId;
            }

            try {
                const response = await getPaymentMethods();
                this.paymentMethods = response.data
                    .filter(p => p.brand_id == brandId)
                    .map(p => ({...p, name: p.payment_name, type: p.field_type }));
                console.log('Filtered payment methods by brand ID', brandId);
                console.log('Matching payment methods:', this.paymentMethods);
            } catch (e) {
                console.error(e);
            }
        },
    },
}

</script>

<style scoped>
div.payment-method-selector {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 768px) {
    div.payment-method-selector {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 992px) {
    div.payment-method-selector {
        grid-template-columns: repeat(4, 1fr);
    }
}

div.payment-method-selector > * {
    width: 100%;
}

div.payment-method-card i {
    font-size: 2rem;
    color: var(--secondary);
}

div.payment-method-card.active i {
    font-size: 2rem;
    color: var(--light);
}

/* Center and make the SVG responsive */
.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.responsive-logo {
    max-width: 95%; /* Increase the max size */
    max-height: 95%; /* Increase the max size */
    object-fit: contain;
}
</style>