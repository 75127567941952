<template>
    <div class="d-flex flex-nowrap">
      <div class="settings-page px-lg-4 py-4">
        <label>Settings Page</label>

        <button
            class="btn btn-primary btn-lg mr-2"
            :disabled="isFetching"
            @click="triggerManualFetchingOfOrders()"
        >
          <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
        </button>
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-xl-4 order-lg-12">
        <SettingsSidebar />
      </div>
      <div class="col">

        <div class="row">
          <div class="col">
            <div class="tab-panel" role="tabpanel">

              <!-- Products -->
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">Products</label>

                <input
                  v-model="productQuery"
                  class="form-control col-4"
                  type="text"
                  placeholder="Search Product"
                />
              </div>

              <div class="product-setting-section mb-4">
                <v-data-table
                  :headers="productHeaders"
                  :items="productItems"
                  :rows-per-page="10"
                  :loading="isFetching"
                  sort-by=""
                  sort-type=""
                >
                  <template #item-product_name="item">
                    <strong class="ml-1">
                      {{ item.product_name }}
                    </strong>
                  </template>

                  <template #item-category="item">
                    {{ item.category }}
                  </template>

                  <template #item-product_group.group_name="item">
                    {{ item.product_group.group_name }}
                  </template>

                  <template #item-product_sub_group_name="item">
                    {{ item.product_sub_group_name }}
                  </template>

                  <!-- might be used in the future
                  <template #item-available="item">
                    <div class="custom-control custom-switch "
                        @click="toggleStatus(item)">
                      <input type="checkbox"
                        class="custom-control-input"
                        v-model="item.available"
                        :id="`product-status-${item.id}`"
                        :disabled="true"
                        >
                      <label class="custom-control-label"
                        :for="`product-status-${item.id}`">
                        {{ item.available ? 'available' : 'unavailable' }}
                      </label>
                    </div>
                  </template> -->

                  <template #item-actions="item">
                    <div class="d-flex">
                      <div class="col">
                        <button
                            class="btn btn-sm btn-block btn-primary"
                            @click="openProductModal(item)"
                        >
                          Update Availability
                        </button>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </div>
              <!-- / -->
            </div>
          </div>
        </div>        
      </div>

      <product-price-modal
        v-if="selectedProduct"
        v-model="isProductPriceModalOpen"
        :product="selectedProduct"
        @saveProductPrices="saveProductPrices"
      />

    </div>
  </template>

  <script>
  import { mapState } from 'vuex';
  import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
  import {
    getAllProducts,
    getProductPricings,
    updateAvailability,
    updateProductPricings,
  } from '@/spa/services/product-service';
  import VDataTable from 'vue3-easy-data-table';
  import { checkCloudConnection } from '@/spa/plugins/axios';

  import ProductPriceModal from '@/spa/components/modals/ProductPriceModal';

  import { getChannels, getOwnBrands } from '@/spa/services/cashier-service';
  import { cloneDeep, debounce } from "lodash";
  import { ENABLE_PRODUCTS_SETTINGS, OFFLOAD } from '@/spa/constants';

  export default {
    name: 'ProductSettings',
    components: {
      SettingsSidebar,
      VDataTable,
      ProductPriceModal,
    },

    data() {
      return {
        productHeaders: [
            { text: 'Item Name', value: 'product_name', sortable: true },
            { text: 'Product Category', value: 'category', sortable: true },
            { text: 'Product Group', value: 'product_group.group_name', sortable: true },
            { text: 'Product Sub Group', value: 'product_sub_group_name', sortable: true },
            { text: 'Actions', value: 'actions' },
        ],

        productItems: [],
        products: [],
        productQuery: '',

        isFetching: true,

        isProductPriceModalOpen: false,

        selectedProduct: null,
        pricings: [],

        channels: [],
        brands: [],
        serviceTypes: [],

        debouncedFilteredProducts: () => null,
      };
    },

    computed: {
      ...mapState(['activeBrandId']),
      ...mapState('user', ['locationId']),
      ...mapState('sqlite', ['initialData']),
    },

    async mounted() {
      if(!ENABLE_PRODUCTS_SETTINGS) this.$router.push({ name: 'settings' });

      this.debouncedFilteredProducts = debounce(this.fetchAllProducts.bind(this), 500);

      await this.fetchPricings();
      
      await this.fetchOwnBrands();
      await this.fetchChannels();
      this.fetchServiceTypes();
      
      this.productItems = await this.fetchAllProducts();
    },

    watch: {
        async productQuery(value) {
          if (!value) {
            await this.fetchAllProducts();
            return;
          }

          this.debouncedFilteredProducts();
        },
    },

    methods: {
      async fetchAllProducts(refresh = false) {
        const oldProducts = this.products;
        try {
          this.products = [];
          let response = await getAllProducts(refresh, false);

          // TODO this mapping should be in BE not in FE
          response = response.data.values.map(item => {
            const { id, available } = item;
            const pricingMatch = this.pricings.find(p => p.product_detail_id === id);

            if (!pricingMatch) {
              return {
                ...item,
                available: available === 1,
                pricings: [],
              };
            }

            const pricings = this.pricings
              .filter(p => p.product_detail_id === id)
              .map(pricing => {
                const channel_name = this.channels.find(c => c.id === pricing.service_channel_id)?.channel_name;
                const service_type = this.serviceTypes.find(s => s.id === pricing.service_type_id);

                // Skip items with mismatched service_type_id
                if (!service_type) return;

                const service_type_name = service_type.service_name;

                return {
                  ...pricing,
                  channel_name,
                  service_type_name,
                };
              })
              .filter(Boolean); // Filter out null values from the map

            return {
              ...item,
              available: available === 1,
              pricings,
            };
          });

          if(this.productQuery) {
            const lowerQuery = this.productQuery.toLowerCase();
            response = response.filter(r =>
                        `${r.product_name}`.toString().toLowerCase().includes(lowerQuery)
                      );
          }

          this.products = this.productItems = response;
          this.isFetching = false;
          return response;
        } catch (e) {
          this.products = oldProducts;
          return oldProducts;
        }
      },

      async fetchPricings(refresh = false) {
          try {
            const response = await getProductPricings(refresh, false);
            this.pricings = cloneDeep(response.data);
          } catch (e) {
            console.error(e);
          }
      },

      async fetchChannels() {
          if (OFFLOAD.sqliteOffloadProduct) {
            this.channels = this.initialData?.channels || [];

            return;
          }

          try {
            const response = await getChannels();
            this.channels = response.data;
          } catch (e) {
            console.error(e);
          }
      },

      fetchServiceTypes() {
        if (!this.activeBrandId) return [];

        const activeBrand = this.brands.find(
          brand => brand.id === this.activeBrandId
        );

        this.serviceTypes = activeBrand ? activeBrand.service_types : [];
      },

      async fetchOwnBrands() {
        if (OFFLOAD.sqliteOffloadProduct) {
            this.brands = this.initialData?.brands || [];
            return;
        }

        try {
          const response = await getOwnBrands();
          this.brands = response?.data || [];
        } catch (e) {
          console.error(e);
          this.brands = [];
        }
      },

      async toggleStatus(item) {
        if (!(await checkCloudConnection())) {
            this.$swal.warning('No internet Connection, Kindly connect to the internet to update the status of item.');
            return;
        }

        const key = this.productItems.findIndex(i => i.id == item.id);

        const isConfirmed = await this.$swal.confirm('Are you sure?', 'Do you want to change the status of ' + item.product_name + ' to ' + (item.available ? 'unavailable?' : 'available?'));
        if (!isConfirmed) return;

        try {
          const result = await this.$openApproverModal();
          if (!result.success) return;

          //update to database
          await updateAvailability(item.id, !item.available, this.locationId);

          this.productItems[key].available = !item.available;
          await this.fetchAllProducts(true);

        } catch (e) {
            console.error(e);
        }
      },

      async openProductModal(item) {
        if (!(await checkCloudConnection())) {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to update the status of item.');
          return;
        }

        this.selectedProduct = item;
        this.isProductPriceModalOpen = true;
      },

      async saveProductPrices(updatedPrice) {
        try {
          this.isProductPriceModalOpen = false;

          if (!(await checkCloudConnection())) {
              this.$swal.warning('No internet Connection, Kindly connect to the internet to update the status of item.');
              return;
          }

          const key = this.productItems.findIndex(i => i.id == updatedPrice[0].product_detail_id);
          this.productItems[key] = {
            ...this.productItems[key],
            pricings: updatedPrice
          };

          await updateProductPricings(this.productItems[key], this.locationId);
          await this.fetchPricings(true);

        } catch (e) {
          this.$swal.error(e.message);
          console.error(e);
        }
      },

      async triggerManualFetchingOfOrders(refresh=true) {
        if (!(await checkCloudConnection())) {
          this.$swal.warning('No internet Connection, Kindly connect to the internet to fetch the products.');
          return;
        }

        this.isFetching = true;
        try {
          await this.fetchAllProducts(refresh);
          await this.fetchPricings(refresh);

          this.isFetching = false;

          this.$swal.success('Success', 'Products fetched successfully');
        } catch (e) {
          this.isFetching = false;
          this.$swal.error(e.message);
        }
      }
    }
  }
  </script>

  <style scoped>
    :deep(.items-breakdown) {
      height: 200px;
      overflow-y: auto;
      width: 100%;
      padding-right: 16px;
      margin-right: -16px;
    }

    :deep(.items-breakdown)>div:not(:last-of-type) {
      border-bottom: 1px dotted #aaa;
    }

    .settings-page {
      overflow-y: auto;
      max-height: calc(100vh - 86px);
    }

    .custom-control-input[checked] ~ .custom-control-label::before,
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color:#3490dc !important;
    }
  </style>
