<template>
  <vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="base-modal-container"
    content-class="base-modal-content" :style="{
      '--content-min-width': minWidth,
      '--content-max-width': maxWidth,
      '--content-min-height': minHeight,
      '--content-width': width,
    }" :click-to-close="enableOutsideClick" :esc-to-close="enableOutsideClick">
    <span class="modal__title">
      <slot name="title"></slot>
    </span>
    <div class="modal__content" ref="modalContent">
      <slot :params="params"></slot>
    </div>
    <div v-if="!hideModalAction" class="modal__action d-flex justify-content-end align-items-center">
      <div v-if="hasCancel" :class="{ [fullWidthActions ? 'col' : 'col col-lg-4']: true }">
        <button class="btn btn-large btn-block" :class="{ [`btn-${cancelColor}`]: true }" :disabled="cancelDisabled"
          @click="emitCancel(close)">
          {{ cancelLabel }}
        </button>
      </div>

      <div v-if="hasSecondaryConfirm" :class="{ [fullWidthActions ? 'col' : 'col col-lg-4']: true }">
        <button class="btn btn-large btn-block" :class="{ [`btn-${confirmColor}`]: true }"
          :disabled="secondaryConfirmDisabled" @click="$emit('secondaryConfirm')">
          {{ secondaryConfirmLabel }}
        </button>
      </div>

      <div v-if="hasConfirm" :class="{ [fullWidthActions ? 'col' : 'col col-lg-4']: true }">
        <button class="btn btn-large btn-block" :class="{ [`btn-${confirmColor}`]: true }" :disabled="confirmDisabled"
          @click="$emit('confirm')">
          {{ confirmLabel }}
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { throttle } from "lodash";

export default {
  name: "BaseModal",

  props: {
    hideModalAction: {
      type: Boolean,
      default: false,
    },

    confirmLabel: {
      type: String,
      default: "Confirm",
    },

    secondaryConfirmLabel: {
      type: String,
      default: "Re-Confirm",
    },

    cancelLabel: {
      type: String,
      default: "Cancel",
    },

    width: {
      type: String,
      default: "",
    },

    minWidth: {
      type: String,
      default: "1000px",
    },

    maxWidth: {
      type: String,
      default: "70vw",
    },

    minHeight: {
      type: String,
      default: "600px",
    },

    confirmDisabled: {
      type: Boolean,
      default: false,
    },

    secondaryConfirmDisabled: {
      type: Boolean,
      default: false,
    },

    cancelDisabled: {
      type: Boolean,
      default: false,
    },

    hasConfirm: {
      type: Boolean,
      default: true,
    },

    hasSecondaryConfirm: {
      type: Boolean,
      default: false,
    },

    hasCancel: {
      type: Boolean,
      default: true,
    },

    confirmColor: {
      type: String,
      default: "primary",
    },

    cancelColor: {
      type: String,
      default: "danger",
    },

    fullWidthActions: {
      type: Boolean,
      default: false,
    },

    enableOutsideClick: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.bindModalContentScrollend();
  },

  beforeUnmount() {
    this.unbindModalContentScrollend();
  },

  methods: {
    emitCancel(callback) {
      this.$emit("cancel");
      callback();
    },

    bindModalContentScrollend() {
      this.$refs.modalContent.addEventListener(
        "scroll",
        throttle(this.emitScrollEvent.bind(this), 16)
      );
    },

    unbindModalContentScrollend() {
      this.$refs.modalContent.removeEventListener(
        "scroll",
        throttle(this.emitScrollEvent.bind(this), 16)
      );
    },

    emitScrollEvent() {
      this.$emit("contentScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.base-modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.base-modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  min-height: var(--content-min-height);
  margin: 0 1rem;
  padding: 1rem;
  background: white;
  min-width: var(--content-min-width);
  max-width: var(--content-max-width);
  width: var(--content-width);
  padding: 16px;
  border-radius: 10px;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.2),
    4px 8px 16px 0 rgba(0, 0, 0, 0.15), 8px 16px 64px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  ::v-deep(.base-modal-content) {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0 0 !important;
  }

  .modal__action {
    button {
      border: 0 !important;
      box-shadow: initial !important;
      font-weight: 500;
      text-transform: uppercase;
    }

    button.btn-danger,
    button.danger:focus,
    button.danger:active {
      background-color: transparent !important;
      color: #4a5059;
    }

    button.btn-primary {
      background-color: #0052cc;
    }
  }
}

.modal__title {
  margin: 0;
  margin-bottom: 16px;
  font-size: 1.5rem;
  font-weight: 700;
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal__action {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
  border-top: 1px solid #c9dfff;
  margin: 16px -16px 0 -16px;
}

@media (min-width: 992px) {
  .modal__action {
    flex-wrap: nowrap;
  }
}
</style>
