<template>
	<base-modal
		v-bind="$attrs"
		min-width="600px"
        min-height="100px"
		@confirm="transferData"
		>
		<template v-slot:title>Transfer Data</template>

		<hr />

		<div class="d-flex flex-wrap justify-content-between align-items-center">
			<div class="form-group mt-4">
                <label for="product-name">Terminal ID</label>
                <input
                    type="text"
                    class="form-control"
                    id="product-name"
                    v-model="terminalId"
                    />
            </div>
		</div>
	</base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import { isEmpty } from 'lodash';
import cloneDeep from 'rfdc/default';
import { mapGetters } from 'vuex';

export default {
  	components: { BaseModal },
	data() {
		return {
			terminalId: null,
		};
	},
	computed: {
		...mapGetters('user', ['multiTerminalType']),
	},
	methods: {
		transferData() {
			if (isEmpty(this.terminalId)) {
				this.$swal.warning('Destination terminal is required!');
				return;
			}
			let states = cloneDeep(this.$store.state);
			delete states.user;
		}
	}
}
</script>