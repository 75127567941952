<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label>Settings Page</label>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">Print Table QR</label>
        </div>
        <div class="printer-settings-section mb-4">
          <v-data-table
              :headers="tableQrHeaders"
              :items="tableQrItems"
              :rows-per-page="10"
              sort-by="ip_address"
              sort-type="desc"
          >

            <template #item-table_name="item">
              <strong>
                {{ item.table_name }}
              </strong>
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                      class="btn btn-sm btn-block btn-primary"
                      style="width: 4rem"
                      @click="printTable(item)"
                  >
                    Print
                  </button>
                </div>
              </div>
            </template>

          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import VDataTable from 'vue3-easy-data-table';
import {getTableLayout} from "@/spa/services/table-service";
import {map, flatMap} from "lodash";
import {print} from "@/spa/services/printer-service";

export default {
  components: {
    SettingsSidebar,
    VDataTable
  },
  data() {
    return {
      tableQrHeaders: [
        {text: 'Tables', value: 'table_name', sortable: true},
        {text: 'Actions', value: 'actions'},
      ],
      tableQrItems: []
    }
  },
  async mounted() {
    await this.fetchTables();
  },
  methods: {
    async fetchTables() {
      try {
        const response = await getTableLayout(this.serviceAreaId);
        this.tableQrItems = response.data.table_details.service_areas.find(area => area.area_name == 'Dine In')?.tables || [];
      } catch (e) {
        console.error(e);
      }
    },

    printTable(item) {
      const printData = {
        print_type: 'QRCode',
        html: ""
      };
      const qrUrl = 'https://smartweb-ecms.tabsquare.com/scan/39f2f8d9-3ac3-4c3b-a893-51ffd416bc5a';
      const qrNote = 'Scan using your phone camera';

      print(printData, {}, qrNote, qrUrl);
    }
  }
}
</script>
