import { SYNC_STATUSES } from "@/spa/constants";
import { unionBy } from "lodash";
import bus from "@/spa/utils/bus";

function defaultStateFactory() {
  return {
    posStatus: {
      show: false,
      status: "",
    },
    showBlocker: false,
    syncStatus: SYNC_STATUSES.SYNCED,
    syncProgressPercent: 0,
    latestDeliveryNotifications: [],
    latestCancelledDeliveryNotifications: [],
    deliveryResumePages: {
      grab: 1,
      foodpanda: 1,
    },
    showPaymentQRModal: false,
    paymentQRDetails: null,
    latestOnlinePaymentNotifications: [],
  };
}

export default {
  namespaced: true,

  state: defaultStateFactory(),

  mutations: {
    resetState(state) {
      Object.assign(state, defaultStateFactory());
    },

    setPosStatus(state, value) {
      state.posStatus = value;
    },

    setShowBlocker(state, value) {
      state.showBlocker = value;
    },

    setSyncStatus(state, value) {
      state.syncStatus = value;
    },

    setSyncProgressPercent(state, value) {
      state.syncProgressPercent = value;
    },

    setLatestDeliveryNotifications(state, values) {
      const newValues = unionBy(
        values,
        state.latestDeliveryNotifications,
        "type"
      );
      state.latestDeliveryNotifications = newValues;
    },

    setLatestCancelledDeliveryNotifications(state, values) {
      const newValues = unionBy(
        values,
        state.latestCancelledDeliveryNotifications,
        "type"
      );
      state.latestCancelledDeliveryNotifications = newValues;
    },

    removeLatestDeliveryNotifications(state, id) {
      const newValues = state.latestDeliveryNotifications.filter(
        (order) => order.id !== id
      );

      state.latestDeliveryNotifications = newValues;
    },

    removeLatestCancelledDeliveryNotifications(state, id) {
      const newValues = state.latestCancelledDeliveryNotifications.filter(
        (order) => order.id !== id
      );

      state.latestCancelledDeliveryNotifications = newValues;
    },

    setDeliveryResumePages(state, value) {
      state.deliveryResumePages = value;
    },

    setShowPaymentQRModal(state, value) {
      state.showPaymentQRModal = value;
    },

    setPaymentQRDetails(state, value) {
      state.paymentQRDetails = value;

      // Check if the status is "PAID", "EXPIRED" or "FAILED" and emit an event
      if (state?.paymentQRDetails?.paymentProvider == "paymongo") {
        if (value?.status == "PAID") {
          bus.emit("paymentPaid");
        }
  
        if (value?.status == "EXPIRED") {
          bus.emit("paymentExpired");
        }
  
        if (value?.status == "FAILED") {
          bus.emit("paymentFailed");
        }
      }
    },

    setLatestOnlinePaymentNotifications(state, value) {
      state.latestOnlinePaymentNotifications = [
        ...state.latestOnlinePaymentNotifications,
        value,
      ];
    },

    removeLatestOnlinePaymentNotifications(state, id) {
      const newValues = state.latestOnlinePaymentNotifications.filter(
        (notification) => notification.id !== id
      );

      state.latestOnlinePaymentNotifications = newValues;
    },
  },
};
