export function setIdleHandler(onIdle, idleDurationMs = 5000, onActive = () => null) {
    let t;

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.ontouchmove = resetTimer;  // required by some devices
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function resetTimer() {
        clearTimeout(t);
        onActive();
        t = setTimeout(onIdle, idleDurationMs);  // time is in milliseconds
    }
}
