<template>
  <span class="status" :class="classes">{{ upperCasedStatus }}</span>
</template>

<script>
import { kebabCase, upperCase } from "lodash";

export default {
  name: "MDeliveryStatus",

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    classes() {
      return kebabCase(this.status);
    },

    upperCasedStatus() {
      return upperCase(this.status);
    },
  },
};
</script>

<style scoped>
.status {
  font-size: 10px;
  font-weight: 600;
  background-color: #ddd;
  color: gray;
  padding: 2px 12px;
  border-radius: 3px;
  white-space: nowrap;
}

.accepted {
  color: white;
  background-color: #8d2ec8;
}

.driver-arrived {
  color: white;
  background-color: #37c6f4;
}

.completed,
.collected {
  color: #2ec86b;
  background-color: #c4fad9;
}

.delivered,
.order-picked-up {
  color: white;
  background-color: #2ec86b;
}

.failed,
.rejected,
.cancelled {
  color: white;
  background-color: #f47070;
}

.ready {
  color: white;
  background-color: #2e87c8;
}

.placed {
  color: #8d2ec8;
  background-color: rgba(141, 46, 200, 0.14);
}

.preparing,
.driver-assigned,
.driver-allocated,
.order-prepared {
  color: #ed9d26;
  background-color: rgba(237, 157, 38, 0.14);
}

.order-collected {
  color: white;
  background-color: #ed9d26;
}
</style>
