<template>
  <div
    class="m-delivery-item d-flex justify-content-between align-items-center"
    :class="{ selected }"
  >
    <div
      class="for-pickup d-flex justify-content-center align-items-center"
      :class="{ expended: showForPickUp }"
      v-if="item.orderType === 'TakeAway'"
      @click.stop="showForPickUp = !showForPickUp"
    >
      <Pickup /> <span>For Pick-up</span>
    </div>

    <div class="m-delivery-item-container">
      <div
        class="m-delivery-item-content d-flex align-items-center flex-nowrap"
        :class="[{ open: showActions }, widthClass, delivery.toLowerCase()]"
        v-touch:swipe.left="handleDrag"
        v-touch:swipe.right="handleDrag"
      >
        <div class="info d-flex justify-content-between align-items-center">
          <div class="logo">
            <component :is="delivery" />
          </div>

          <div class="id">
            <div class="label">ID</div>
            <div class="value">{{ item.id }}</div>
          </div>

          <div class="started-at">
            <div class="label">Started</div>
            <div class="value">{{ item.startedAt }}</div>
          </div>

          <div
            v-if="hasReadyTime"
            class="delivery-time"
            :class="{ passed: Number(item.readyTime) < 0 }"
          >
            <div class="label">Ready Time</div>
            <div class="value">{{ readyTime }}</div>
          </div>

          <div class="delivery-status">
            <div class="label">Status</div>
            <div class="status">
              <MDeliveryStatus :status="item.status" />
            </div>
          </div>

          <div class="total">
            <div class="label darker">Total</div>
            <div class="value">PHP {{ item.total }}</div>
          </div>
        </div>

        <!-- actions based on status -->
        <div class="actions d-flex">
          <button
            v-if="availableActions.includes('reject')"
            class="btn btn-danger"
            @click.stop="orderRejected"
          >
            Reject
          </button>

          <button
            v-if="availableActions.includes('accept')"
            class="btn btn-primary"
            @click.stop="orderAccepted"
          >
            Accept
          </button>

          <button
            v-if="availableActions.includes('extend')"
            class="btn btn-outline-primary"
            @click.stop="orderExtendPreparationTime"
          >
            <i class="fas fa-plus mr-1"></i> Extend
          </button>

          <button
            v-if="availableActions.includes('cancel')"
            class="btn btn-danger"
            @click.stop="orderCancelled"
          >
            Cancel
          </button>

          <button
            v-if="availableActions.includes('ready')"
            class="btn btn-primary"
            @click.stop="orderReadyForDelivery"
          >
            Ready
          </button>

          <button
            v-if="availableActions.includes('collected')"
            class="btn btn-primary"
            @click.stop="orderCollected"
          >
            Collected
          </button>

          <button
            v-if="availableActions.includes('delivered')"
            class="btn btn-primary"
            @click.stop="orderDelivered"
          >
            Delivered
          </button>
        </div>
      </div>
    </div>

    <div
      class="show-actions"
      @click.stop="showActions = !showActions"
      :class="{ 'opacity-0 pointer-events-none': !availableActions.length }"
    >
      <i class="fas fa-ellipsis-h" />
    </div>
  </div>
</template>

<script>
import Grab from "@/spa/components/icons/deliveries/grab";
import FoodPanda from "@/spa/components/icons/deliveries/foodpanda-text";
import AlipayDStore from "@/spa/components/icons/deliveries/AlipayDstore";
import Pickup from "@/spa/components/icons/deliveries/pickup";
import MDeliveryStatus from "@/spa/components/common/MDeliveryStatus";

export default {
  name: "MDeliveryItem",

  components: { Grab, FoodPanda, Pickup, MDeliveryStatus, AlipayDStore },

  props: {
    item: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    delivery: {
      type: String,
      required: true,
      validator(value) {
        return ["Grab", "FoodPanda", "AlipayDStore"].includes(value);
      },
    },
  },

  data() {
    return {
      showActions: false,
      showForPickUp: false,
    };
  },

  computed: {
    readyTime() {
      if (typeof this.item.readyTime === 'string') {
        return this.item.readyTime;
      }

      if (this.item.readyTime === null) {
        return "--";
      }

      if (Number(this.item.readyTime) < 0) {
        return `${Math.abs(this.item.readyTime)} mins elapsed`;
      }

      return `${this.item.readyTime} mins left`;
    },

    rejectWithoutReason() {
      if (this.delivery === "Grab") {
        return true;
      }

      return false;
    },

    hasReadyTime() {
      if (this.delivery === "Grab" || this.delivery === 'AlipayDStore') {
        return true;
      }

      return false;
    },

    availableActions() {
      if (this.delivery === "Grab") {
        return this.availableGrabActions;
      } else if (this.delivery === "AlipayDStore") {
        return this.availableDStoreActions;
      }

      return this.availableFoodPandaActions;
    },

    availableDStoreActions() {
      switch (this.item.status) {
        case "Placed":
          return ["accept", "cancel"];
        case "Accepted":
          return ["cancel", "ready"];
        case "Preparing":
          return ["cancel", "ready", "collected"];
        case "Ready":
          return ["collected", "cancel"];
        default:
          return [];
      }
    },

    availableGrabActions() {
      if (this.item.orderType === "TakeAway") {
        switch (this.item.status) {
          case "PLACED":
            return ["accept", "reject"];

          case "ACCEPTED":
            return ["extend", "ready"];

          default:
            return [];
        }
      }

      if (this.item.orderType === "Delivered") {
        switch (this.item.status) {
          case "PLACED":
            return ["accept", "reject"];

          case "ACCEPTED":
            return ["extend", "cancel", "ready"];

          default:
            return [];
        }
      }

      if (this.item.orderType === "DeliveredByRestaurant") {
        switch (this.item.status) {
          case "PLACED":
            return ["accept", "reject"];

          case "ACCEPTED":
            return ["extend", "cancel", "collected"];

          case "COLLECTED":
            return ["cancel", "delivered"];

          default:
            return [];
        }
      }

      if (this.item.orderType === "DineIn") {
        switch (this.item.status) {
          case "PLACED":
            return ["accept", "reject"];

          case "ACCEPTED":
            return ["extend", "cancel", "ready"];

          default:
            return [];
        }
      }

      return [];
    },

    availableFoodPandaActions() {
      switch (this.item.status) {
        case "PLACED":
          return ["accept", "reject"];

        case "ACCEPTED":
          return ["cancel", "ready"];

        case "ORDER_PREPARED":
          return ["cancel", "collected"];

        default:
          return [];
      }
    },

    widthClass() {
      if (this.availableActions.length) {
        return `width-${this.availableActions.length}`;
      }

      return "";
    },
  },

  methods: {
    handleDrag(e) {
      if (!this.availableActions.length) {
        return;
      }

      if (e === "left") {
        this.showActions = true;
      }

      if (e === "right") {
        this.showActions = false;
      }
    },

    orderAccepted() {
      this.$emit("orderAccepted");
      this.showActions = false;
    },

    orderRejected() {
      if (this.rejectWithoutReason) {
        this.$emit("orderRejectedWithoutReason");
      } else {
        this.$emit("orderRejected");
      }

      this.showActions = false;
    },

    orderCancelled() {
      this.$emit("orderCancelled");
      this.showActions = false;
    },

    orderReadyForDelivery() {
      this.$emit("orderReadyForDelivery");
      this.showActions = false;
    },

    orderCollected() {
      this.$emit("orderCollected");
      this.showActions = false;
    },

    orderDelivered() {
      this.$emit("orderDelivered");
      this.showActions = false;
    },

    orderExtendPreparationTime() {
      this.$emit("orderExtendPreparationTime");
      this.showActions = false;
    },
  },

  watch: {
    showActions(newValue) {
      if (newValue) {
        this.$emit("enableOrder");
      }
    },

    selected(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.showActions = false;
      }
    },
  },
};
</script>

<style scoped>
.m-delivery-item {
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 0 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.m-delivery-item.selected {
  background-color: #e1f3ff !important;
}

.m-delivery-item-container {
  flex: 1 1 auto;
  overflow: hidden;
}

.m-delivery-item-content {
  transform: translateX(0);
  transition: transform 350ms;
}

.m-delivery-item-content.open {
  transform: translateX(-300px);
}

.m-delivery-item-content.open.width-1 {
  transform: translateX(-180px);
}

.m-delivery-item-content.open.width-2 {
  transform: translateX(-340px);
}

.m-delivery-item-content.open.width-3 {
  transform: translateX(-490px);
}

.label {
  font-size: 12px;
  color: #797979;
}

.label.darker {
  color: #4c5b67;
}

.value {
  font-size: 14px;
  color: #4c5b67;
  font-weight: 600;
}

.delivery-status {
  margin-bottom: 3px;
}

.status {
  margin-top: 4px;
}

.info {
  width: 100%;
  flex-shrink: 0;
}

.info > div {
  width: 20%;
  padding: 0 5px;
}

.info > div.id {
  width: 13%;
}

.info > div.logo {
  width: 18%;
}

.grab .info > div.logo {
  width: 12%;
}

.info > div.started-at {
  width: 27%;
}

.actions {
  margin-left: 25px;
}

.actions button {
  text-transform: uppercase;
  padding: 12px 30px !important;
  width: 150px;
  margin-left: 5px;
}

.actions button:first-child {
  margin-left: 0;
}

.show-actions {
  padding: 15px;
}

.show-actions i {
  color: #797979;
  font-size: 20px;
}

.btn-outline-primary {
  border-width: 2px;
  font-weight: bold;
}

.delivery-time.passed .value {
  color: red;
}

.for-pickup {
  background-color: #1b75bb;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 10px 0 10px 0;
  cursor: pointer;
  transition: width 350ms;
  white-space: nowrap;
}

.for-pickup svg {
  width: 14px !important;
  height: 16px !important;
}

.for-pickup span {
  text-transform: uppercase;
  margin-left: 10px;
  color: white;
  display: none;
}

.for-pickup.expended {
  width: 130px;
}

.for-pickup.expended span {
  display: block;
}
</style>
