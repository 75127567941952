import axios from "@/spa/plugins/axios";
import { OFFLOAD } from "@/spa/constants";

export const getAllProducts = (forceRefresh = false, skip = true, params = {}) => {
  if (skip && OFFLOAD.sqliteOffloadProduct) return;

  return axios.get(route("get.products.v2"), {
    forceRefresh,
    params
  });
};

export const getPopularProducts = (interval) => {
  return axios.get(route("get.products.v2.popular") + "/" + interval);
};

export const getAllModifiers = (forceRefresh = false, skip = true, params = {}) => {
  if (skip && OFFLOAD.sqliteOffloadModifier) return;

  if (window.MosaicPosAppVersionNumbers?.versionCode >= 58) {
    return axios.get(route("get.modifiers.v2"), {
      forceRefresh,
      params
    });
  }

  return axios.get(route("get.modifiers"), {
    forceRefresh,
    params
  });

};

export const getTaxes = (skip = true) => {
  if (skip && OFFLOAD.sqliteOffloadProduct) return;
  return axios.get(route("get.products.taxes"));
};

export const getProductPricings = (forceRefresh = false, skip = true, params = {}) => {
  if (skip && OFFLOAD.sqliteOffloadProduct) return;

  return axios.get(route("get.products.pricings"), {
    forceRefresh,
    params
  });
};

export const getOpenFoodItem = (brandId) => {
  const params = {
    brand_id: brandId,
  };
  return axios.get(route("get.product.open.food"), { params });
};

export const getPromos = () => {
  return axios.get(route("get.promos"));
};

export const addOpenFoodItem = (brandId, name, price) => {
  return axios.post(route("add.product.open.food"), {
    brand_id: brandId,
    product_name: name,
    product_price: price,
  });
};

export const updateCustomerInfo = (receiptDetails, locationId) => {
  return axios.put(route("orders.update.customer_info"), {
    receiptDetails: receiptDetails,
    location_id: locationId,
  });
};

export const updateAvailability = (itemId, itemStatus, locationId) => {
  return axios.post(route("update.product.availability"), {
    item_id: itemId,
    availability: itemStatus,
    location_id: locationId,
  });
};

export const updateProductPricings = (productPricings, locationId) => {
  return axios.post(route("update.product.pricings"), {
    productPricings: productPricings,
    location_id: locationId,
  });
};

export const updateProductPrices = (prices) => {
  return axios.post(route("update.product.prices"), {
    prices,
  });
};

export const updateModifierPrices = (prices) => {
  return axios.post(route("update.modifier.prices"), {
    prices,
  });
};

export default {
  getAllProducts,
  getAllModifiers,
  getTaxes,
  getProductPricings,
  getOpenFoodItem,
  addOpenFoodItem,
  updateCustomerInfo,
  updateAvailability,
  updateProductPricings,
};
