import {action, DataOffloadBridge} from "@/mobile_bridge/offload/offload";
import {OFFLOAD} from "@/spa/constants";

export class ProductModifierBridge extends DataOffloadBridge {
    constructor() {
        super('ProductModifier');
    }

    async getProductModifiers(params = {}) {
        console.log(`${this.type} loaded from SQLite!`);
        const { rows } = await this.sendMessage(action.GET_MODIFIERS, { params });
        return rows;
    }

    async getRowsPaged(params = {}) {
        if (!OFFLOAD.sqliteOffloadPOSFE1222) {
            return null;
        }

        return await this.sendMessage('GET_ROWS_PAGED', { params });
    }

    async update(payload) {
        if (!OFFLOAD.sqliteOffloadPOSFE1222) {
            return;
        }

        return await this.sendMessage('UPDATE_DATA', { payload });
    }
}

export class ProductModifierPriceBridge extends DataOffloadBridge {
    constructor() {
        super('ProductModifierPrice');
    }

    async getRows(params = {}) {
        if (!OFFLOAD.sqliteOffloadPOSFE1222) {
            return null;
        }

        const { data } = await this.sendMessage('GET_ROWS', { params });

        return data;
    }

    async update(payload) {
        if (!OFFLOAD.sqliteOffloadPOSFE1222) {
            return;
        }

        return await this.sendMessage('UPDATE_DATA', { payload });
    }
}
