<template>
  <BaseModal
    v-model="isOpen"
    max-width="440"
    min-width="440"
    min-height="600"
    full-width-actions
    :click-to-close="false"
    @confirm="confirm"
    confirm-label="Continue"
    @before-open="reset"
  >
    <template v-slot:title>Select Reason</template>

    <MCard
      v-for="reason in reasons"
      v-if="canCancel"
      :key="reason.reason"
      :active="reason.reason == selectedReason?.reason"
      clickable
      class="mb-2 mr-2"
      :header="reason.title"
      @click="selectedReason = reason"
      alignItems="start"
    >
      <div class="px-4 py-2" v-if="reason.display">{{ reason.display }}</div>
    </MCard>

    <div v-else class="non-cancel-reason">
      {{ nonCancellationReason }}
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import { getRejectReasons } from "@/spa/services/delivery-service";
import MCard from "@/spa/components/common/MCard";
import { upperCase } from "lodash";

export default {
  name: "OrderRejectReasonsModel",

  components: { BaseModal, MCard },

  props: {
    status: {
      type: String,
    },

    order: {
      type: [Object, null],
    },

    delivery: {
      type: String,
      required: true,
      validator(value) {
        return ["grab", "foodpanda", "AlipayDStore"].includes(value);
      },
    },
  },

  data() {
    return {
      selectedReason: null,
      reasonsResponse: [],

      approvers: [],
      selectedApprover: "",

      canCancel: true,
      nonCancellationReason: "",
    };
  },

  computed: {
    reasons() {
      switch (this.delivery) {
        case "Grab":
          return this.handleGrabReasons;

        case "FoodPanda":
          return this.handleFoodPandaReasons;

        case "AlipayDStore":
          return this.handleAlipayReasons;

        default:
          return [];
      }
    },

    handleAlipayReasons() {
      return this.reasonsResponse?.map(reason => ({
        title: reason.cancellation_reason_name,
        reason: reason.id,
        // display: reason.cancellation_reason_name,
      }));
    },

    handleGrabReasons() {
      if (this.reasonsResponse?.cancelable === false) {
        this.canCancel = false;
        this.nonCancellationReason =
          this.reasonsResponse?.nonCancellationReason;
      }

      return this.reasonsResponse?.cancelReasons?.map((reason) => ({
        title: upperCase(reason.reason),
        reason: reason.reason,
        code: reason.code,
      }));
    },

    handleFoodPandaReasons() {
      let reasons = this.reasonsResponse;

      reasons = reasons.filter(
        (reason) =>
          reason.applicable === "after acceptance" ||
          reason.applicable === "before acceptance, after acceptance"
      );

      reasons = reasons.map((reason) => ({
        title: upperCase(reason.value),
        reason: reason.value,
        message: reason.description,
        display: reason.short_description ?? reason.description,
      }));

      return reasons;
    },

    ableToFetchReasons() {
      if (this.delivery === "FoodPanda" || this.delivery === "AlipayDStore") {
        return true;
      }

      if (this.delivery === "Grab" && this.order?.id) {
        return true;
      }

      return false;
    },

    params() {
      if (!this.selectedReason) {
        return null;
      }

      if (this.delivery === "FoodPanda" || this.delivery === "AlipayDStore") {
        return {
          message: this.selectedReason.message,
          reason: this.selectedReason.reason,
        };
      }

      if (this.delivery === "Grab" && this.order?.id) {
        return {
          cancelCode: this.selectedReason.code,
        };
      }

      return null;
    },
  },

  methods: {
    reset() {
      this.selectedReason = null;
      this.canCancel = true;
      this.nonCancellationReason = "";
      this.getRejectReasons();
    },

    async getRejectReasons() {
      if (!this.ableToFetchReasons) return;

      try {
        const response = await getRejectReasons(this.order?.id, this.delivery);
        this.reasonsResponse = response.data.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    confirm() {
      if (!this.selectedReason) {
        this.$swal.warning("Please select a reason");
        return;
      }

      this.$emit("orderCancelled", this.params);
    },
  },

  mounted() {
    this.getRejectReasons();
  },
};
</script>

<style scoped>
:deep(.modal__title) {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 15px 0;
}

:deep(.m-card__header) {
  margin-left: 16px;
  padding-bottom: 0 !important;
  color: #555;
}

.non-cancel-reason {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
}
</style>
