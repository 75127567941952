<template>
	<div class="row mx-2 settings-page px-lg-4 py-4">
		<div class="col-xl-4 order-lg-12">
			<SettingsSidebar />
		</div>
		<div class="col">
			<label>Settings Page</label>

			<button
				class="btn btn-prim my-1"
				id="add-cashfloat-ttl-btn"
				v-text="'ADD CASH FLOAT'"
				@click="openModal(null)"
				/>

			<div class="tab-panel" role="tabpanel">
				<div class="float-section mb-4">
					<v-data-table
						:headers="floatHeaders"
						:items="activityItems"
						:rows-per-page="10"
						sort-by="created_at"
						sort-type="desc"
						>
						<template v-if="!hasCashFundPermission" #item-actions="item">
							<div class="d-flex">
								<div class="col">
									<button
										class="btn btn-sm btn-block btn-primary"
										:disabled="item.type == 'Beginning Fund'"
										@click="openModal(item)"
										>
										Modify
									</button>
								</div>
								<div class="col">
									<button
										class="btn btn-sm btn-block btn-danger"
										:disabled="item.type == 'Beginning Fund'"
										@click="deleteCashFloat(item.id)"
										>
										Remove
									</button>
								</div>
							</div>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>

		<float-setting-modal 
			v-model="isFloatModalOpen"
			:floatActivity="selected"
			:errors="errors"
			@save="submitCashFloat"
			/>
			
	</div>
</template>

<script>
import cloneDeep from 'rfdc/default';
import { addFloatTransaction, addFloatTransactionOld, getFloatActivities, updateCashFloat, deleteCashFloat } from '@/spa/services/cashier-service';
import {isEmpty} from "lodash";
import moment from 'moment';
import VDataTable from 'vue3-easy-data-table';
import { mapMutations, mapState } from 'vuex';
import SettingsSidebar from '../common/SettingsSidebar.vue';
import FloatSettingModal from '../modals/FloatSettingModal.vue';
import {
  ENABLE_CASH_FUND_IMPROVEMENT,
} from '@/spa/constants';
import PrintMixin from "@/spa/components/mixins/PrintMixin";
import {sqliteOffloadGetStorageValue} from "@/mobile_bridge/offload/receipt-model";

export default {
  	components: {
		SettingsSidebar,
		VDataTable,
    	FloatSettingModal,
	},
	mixins: [PrintMixin],
	computed: {
		...mapState('settings', ['cashFloat','cashFloatActivities','terminalsCashFund']),
		...mapState('user', ['terminalId']),
		activityItems() {
			return this.cashFloatActivities.map(item => {
				return {
					...item,
					time: moment(item.created_at).format('YYYY-MM-DD h:mm:ss a'),
					reason: item.reason?.reason_name?? '---',
					formattedAmount: item.type == 'Withdraw' ? `(${item.amount})` : item.amount,
				}
			});
		},
		hasCashFundPermission() {
			return ENABLE_CASH_FUND_IMPROVEMENT || (this.$can(this.PERMISSIONS.CASH_FUND_MANAGEMENT) || false);
		},
	},
	mounted() {
		this.fetchActivities();
	},
	data() {
		return {
			selected: null,
			isFloatModalOpen: false,
			isApproverOpen: false,
			floatHeaders: [
				{ text: 'Time', value: 'time', sortable: true },
				{ text: 'Type', value: 'type', sortable: true },
				{ text: 'Amount', value: 'formattedAmount', sortable: true },
				{ text: 'Reason', value: 'reason', sortable: true },
			],
			errors: {
				location_pos_id: '',
				cashier_id: '',
				type: '',
				float_reason_id: '',
				amount: '',
			},
		}
	},
	methods: {
		...mapMutations('settings', ['setCashFloat', 'setCashFloatActivities', 'pushNewCashFloat', 'setTerminalsCashFund']),
		openModal(item = null) {
			this.resetErrors();
			this.selected = item;
			this.isFloatModalOpen = true;
		},
		async submitCashFloat(data) {
			const result = await this.$openApproverModal();
			if (!result.success) return;

			let cashFloat = parseFloat(this.cashFloat[this.terminalId]);
			let cashFloatObj = this.cashFloat;
			const key = Object.keys(cashFloatObj)[0];

			let cashFunds = this.terminalsCashFund;
			let beginningFund = cashFunds["Terminal " + this.terminalId]['Beginning Fund'] ?? 0;
			let cashWithdraw = cashFunds["Terminal " + this.terminalId]['Withdraw'] ?? 0;
			let additionalFund = cashFunds["Terminal " + this.terminalId]['Additional Fund'] ?? 0;

      		data.approved_by = result?.approver?.id;
			if (!this.selected) {
				if (this.hasCashFundPermission) {
					if (data.type == 'Additional Fund') {
						// beginning fund + additional fund
						cashFloat += data.amount;
						// additional fund
						additionalFund += data.amount
					} else if (data.type == 'Withdraw') {
						// withdraw fund
						cashWithdraw += data.amount
					}

					let cashFunds = {
						'Cash Float': cashFloat, 
						'Beginning Fund': beginningFund,
						'Withdraw': cashWithdraw, 
						'Additional Fund': additionalFund,
					};

					await this.createCashFloat(data, cashFunds);
				} else {
					if (data.type == 'Withdraw') {
						cashFloat -= data.amount;
					} else {
						cashFloat += data.amount;
					}
					await this.createCashFloatOld(cashFloat, key, data);
					this.isFloatModalOpen = false;
				}

			} else {
				const oldAmt = this.selected.amount;
				const newAmt = data.amount - oldAmt;

				if (data.type == 'Withdraw') {
					cashFloat -= newAmt;
				} else {
					cashFloat += newAmt;
				}

				await this.updateCashFloat(cashFloat, key, data);
				this.isFloatModalOpen = false;
			}
		},
		async createCashFloat(data, cashFunds) {
			try {
				let res = await addFloatTransaction({
					float: data,
					cashFunds
				});
				res = res.data;
				
				this.pushNewCashFloat(res);
				this.setCashFloat({[this.terminalId]: cashFunds['Cash Float']});
				this.setTerminalsCashFund({
					["Terminal " + this.terminalId]: {
						'Beginning Fund': cashFunds['Beginning Fund'],
						'Additional Fund': cashFunds['Additional Fund'],
						'Withdraw': cashFunds['Withdraw'],
				}})

				this.printFloatReceipt(res);
				this.isFloatModalOpen = false;
			} catch (e) {
				this.handleErrors(e);
			}
		},
		async createCashFloatOld(cashFloat, key, data) {
			try {
				let res = await addFloatTransactionOld({
					float: data,
					cashFloat,
				});
				res = res.data;
	
				let newObj = {};
				newObj[key] = cashFloat;
				this.setCashFloat(newObj);
				this.pushNewCashFloat(res);

				this.printFloatReceipt(res);

				this.isFloatModalOpen = false;
			} catch (e) {
				this.handleErrors(e);
			}
		},
		async updateCashFloat(cashFloat, key, data) {
			try {
				let res = await updateCashFloat(data.id, {
					float: data,
					cashFloat,
				});
				res = res.data;

				let newObj = {};
				newObj[key] = cashFloat;
				this.setCashFloat(newObj);

				const floatActivities = cloneDeep(this.cashFloatActivities);
				const index = floatActivities.findIndex(item => item.id == data.id);
				floatActivities[index] = res;
				this.setCashFloatActivities(floatActivities);

			} catch (e) {
				this.handleErrors(e);
			}
		},
		async fetchActivities() {
		  const activeUser = await sqliteOffloadGetStorageValue();

		  let terminalId = this.terminalId;

		  if (!isEmpty(activeUser?.currentShift)) {
		    terminalId = activeUser.currentShift?.terminal_id;
      }

		  terminalId = terminalId ?? JSON.parse(sessionStorage.getItem('terminal')).id;

			const { data } = await getFloatActivities(terminalId);

			this.setCashFloatActivities(data);
		},
		deleteCashFloat(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#1b75bb',
				cancelButtonColor: '#f47070',
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					actions: 'void-swal-action',
				}
			}).then(async result => {
				if (!result.isConfirmed) return;
				await deleteCashFloat(id);

				const floatActivities = cloneDeep(this.cashFloatActivities);
				const index = floatActivities.findIndex(item => item.id == id);

				let cashFloatObj = this.cashFloat;
				let cashFloat = Object.values(cashFloatObj)[0];
				const key = Object.keys(cashFloatObj)[0];

				if (floatActivities[index].type == 'Withdraw') {
					cashFloat += floatActivities[index].amount;
				} else {
					cashFloat -= floatActivities[index].amount;
				}

				let newObj = {};
				newObj[key] = cashFloat;
				this.setCashFloat(newObj);

				floatActivities.splice(index, 1);
				this.setCashFloatActivities(floatActivities);
			});

		},
		resetErrors() {
			this.errors = {
				location_pos_id: '',
				cashier_id: '',
				type: '',
				float_reason_id: '',
				amount: '',
			}
		},
		saveErrors(data) {
			Object.keys(data).forEach(key => {
				this.errors[key.substring(key.indexOf(".") + 1)] = data[key][0];
			});
		},
		handleErrors(e) {
			const { errors, msg } = e.response?.data;

			if (errors) {
				this.saveErrors(errors);
				return;
			} else if (msg) {
				this.$swal.error(msg);
			} else if (e?.response?.status == 400) {
				this.$swal.error(e.response.data);
			} else {
				this.$swal.error('Need to be online to update cash float..');
			}
			this.isFloatModalOpen = false;
		}
	},
}
</script>
<style scoped>
.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>