<template>
    <div class="sync-container">Saving offline settlements to database, please wait...</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { syncSettlements } from '@/spa/services/sync-service';
import { get, isEmpty, uniq } from 'lodash';
import { checkCloudConnection } from '@/spa/plugins/axios';

export default {
    name: 'SyncPage',

    computed: {
        ...mapState([
            'settlements',
            'orders',
            'pendingSettlements',
            'syncInterval',
        ]),

        ...mapState('user', ['terminalId']),

        ...mapGetters([
            'pendingOrders',
        ]),
    },

    async mounted() {
        window.__showLoader();

        if (await checkCloudConnection()) {
            await this.fetchMe();
            this.saveSettlements();
        } else {
            window.__hideLoader();
            await Swal.fire('Unable to reach server. Please try again.');
            this.$router.go(-1);
            return;
        }
    },

    methods: {
        ...mapMutations([
            'clearOrders',
            'clearSettlements',
            'setOfflineTransactionCountToZero',
            'clearPendingSettlements',
        ]),

        ...mapActions('user', [
            'fetchMe',
        ]),

        async saveSettlements() {
            const ENABLE_BACKGROUND_SYNC = true;
            const data = {
                settlements: this.settlements.filter(s => s.originTerminalId == this.terminalId),
                orders: this.pendingOrders.filter(o => o.originTerminalId == this.terminalId),
                voided_orders: this.orders.filter(v => v.isVoided == true && v.originTerminalId == this.terminalId)
            };
            const pendingSettlements = this.pendingSettlements;

            for(const orderId in pendingSettlements) {
                if(pendingSettlements[orderId]['splitType'] != 'none' && pendingSettlements[orderId]['splitCount'] > 1 && isEmpty(pendingSettlements[orderId]['payments'])) {
                    window.__hideLoader();
                    this.$swal.warning('Split billed must be settled first before switching back to online.')
                    .then(function() {
                        this.$router.push({ name: 'payments', params: { orderId }});
                    });
                    return;
                }
            }

            //check for unsettled transactions
            if(!isEmpty(this.pendingOrders)) {
                window.__hideLoader();
                this.$swal.warning('Please settled first all Pending and billed transactions before switching back to online.')
                .then(function() {
                    this.$router.push({ name: 'pending' });
                });
                return;
            }

            if(ENABLE_BACKGROUND_SYNC) { //check if background sync is enable
               clearInterval(this.syncInterval);
            } else { //for turning off the synching when toggle to online. will return it once process in online is done.
                const result = await syncSettlements(data);

                //transform data from offline to localstorage
                this.saveToLocalStorage(this.pendingOrders, result.data.saved_orders);

                //clearing the store after syncing
                this.clearOrders();
                this.clearSettlements();
                this.clearPendingSettlements();

                //update session storage
                this.updateSessionStorage(result.data);
            }


            //set offline transaction count to zero
            this.setOfflineTransactionCountToZero();

            const targetUrl = window.isStandalone
                ? `/settings/cashier/${this.terminalId}`
                : '/cashier/cashier_home';

            window.location.href = targetUrl;
        },

        saveToLocalStorage(orders, saved_orders) {
            localStorage.clear(); //clear all saved in localstorage such as activeBrandId, activeServiceTypeId, & activeChannelId. it will affect the online ordering if retain
            init_local_printer_config(); // Reinit local printer config, which relies on local storage

            let vatExempt = ['Senior Citizen Discount', 'PWD'];
            orders.forEach(order => {
                let itemKey = '';
                let itemValues = {};
                order.order_num = order.order_num || `${order.brandId}-${order._id}`;

                if(order.serviceType.includes('QSR')) { //for QSR
                    itemKey = 'QSR-' + order.order_num;
                } else if(order.serviceType.includes('Delivery')) { //for delivery
                    itemKey = 'Delivery-' + order.order_num;
                } else { //for non delivery
                    itemKey = 'Table' + order.tableId;
                }

                let res = saved_orders.find(i => i._id == order._id) || {};

                //rearrange order value
                let new_order = [];
                let discount_customer_details = [];
                let all_discounts = {};
                let all_vat = {};
                let total_discount_amount = 0;
                order.orders.forEach(x => {
                    let productValues = {
                        available: x.product.available,
                        category: x.product.category,
                        hasModifier: x.product.hasModifier,
                        id: x.product.id ?? res.open_items.find(o =>
                                    o.product_name == x.product.product_name &&
                                    o.price == x.product.price
                                ).product_detail_id,
                        image: x.product.image,
                        item_net_sales: x.totals.net,
                        menu_price: x.activePrice,
                        price: x.activePrice,
                        product_desc: x.product.product_desc,
                        product_group: x.product.product_group,
                        product_group_id: x.product.product_group_id,
                        product_name: x.product.product_name,
                        product_sub_group_id: x.product.product_sub_group_id,
                        product_tag: x.product.product_tag,
                        qty: x.quantity,
                        kot_num: x.kot,
                        service: order.serviceType,
                        svc_chrge_amt: x.totals.serviceCharge,
                        table_number: order.tableId,
                        vatable_price: x.totals.net/x.quantity, //vatable price per item
                        tax_amount: x.totals.vat/x.quantity, //tax amount ng lahat ng quantity
                        total_price: x.totals.beforeSc,
                        total_tax_amount: x.totals.vat,
                        total_vatable_price: get(x, 'discount.netSales', x.totals.net),
                        dynamic_tax_details: [], //no data yet
                        svc_charge_is_after_discount: x.activeVat.is_after_discount,
                        svc_charge_is_net_based: x.activeVat.is_net_based,
                        svc_charge_name: get(x, 'activeSc.tax_name', ''),
                        svc_charge_type: get(x, 'activeSc.tax_type', ''),
                        svc_chrge_rate: get(x, 'activeSc.tax_rate', 0.00),
                        vat_rate: x.activeVat.tax_rate,
                        updated_at: '', //no data yet
                        created_at: get(x, 'productCreatedAt', ''),
                        deleted_at: '', //no data yet
                    }

                    if(x.discount) { //item level discounts
                        let obj = {};
                        obj[x.discount.discount_name] = x.discount.discountAmount;
                        Object.assign(all_discounts, obj);

                        total_discount_amount += x.discount.discountAmount;

                        const custDetailId = get(res.customerDetails.find(c =>
                                    c.reference_number == x.discount.idReferenceNumber &&
                                    c.discount_name == x.discount.discount_name &&
                                    c.first_name == x.discount.firstName
                                ), 'customer_detail_id', null);

                        discount_customer_details.push({
                            discount_name: x.discount.discount_name,
                            discount_type: x.discount.discount_name,
                            discount_value: x.discount.discountAmount,
                            first_name: x.discount.firstName,
                            last_name: x.discount.lastName,
                            reference_number: x.discount.idReferenceNumber,
                            id: get(x, 'discount.customer_detail_id', custDetailId),
                        });

                        //for savinb all vat exempt
                        if(vatExempt.includes(x.discount.discount_name)) {
                            all_vat['Vat Exempt Sales'] = get(all_vat, 'Vat Exempt Sales', 0) + x.discount.netSales;
                            all_vat['Zero Rated Sales'] = get(all_vat, 'Zero Rated Sales', 0);
                        }

                        //for saving all zero rated
                        if(x.discount.discount_name == 'Diplomat Discount') {
                            all_vat['Vat Exempt Sales'] = get(all_vat, 'Vat Exempt Sales', 0);
                            all_vat['Zero Rated Sales'] = get(all_vat, 'Zero Rated Sales', 0) + x.discount.netSales;
                        }

                        //add discount property to order
                        Object.assign(productValues, {
                            discount_inline_qty: get(x, 'discount.discountPax', 0),
                            discount_percent: get(x, 'discount.discountValue', 0),
                            discount_type: get(x, 'discount.discount_name', ''),
                            discount_value: get(x, 'discount.discountAmount', 0.00),
                            unit_vat_ex_sales: get(x, 'discount.netSales', 0.00),
                        })
                    }

                    if(x.product.hasModifier == '1') { //for the item that has modifiers
                        let modifierIds = [];
                        let modifierPricingIds = [];
                        let modifierQtys = [];
                        let mods = [];
                        let totalUnforcedMods = 0;
                        let totalForcedMods = 0;
                        if(!isEmpty(x.product.forcedMods)) {
                            x.product.forcedMods.forEach(m => {
                                modifierIds.push({
                                    mod_id: m.modifier_detail_id,
                                    modifier_name: m.modifier_name,
                                });

                                modifierPricingIds.push(m.id);

                                modifierQtys.push({
                                    mod_id: m.modifier_detail_id,
                                    modifier_name: m.modifier_name,
                                    price: m.mod_price,
                                    qty: m.quantity,
                                });

                                mods.push(m.quantity + ' ' + m.modifier_name);
                                totalForcedMods += m.mod_price;
                            });
                        }

                        if(!isEmpty(x.product.unforcedMods)) {
                            x.product.unforcedMods.forEach(m => {
                                modifierIds.push({
                                    mod_id: m.modifier_detail_id,
                                    modifier_name: m.modifier_name,
                                });

                                modifierPricingIds.push(m.id);

                                modifierQtys.push({
                                    mod_id: m.modifier_detail_id,
                                    modifier_name: m.modifier_name,
                                    price: m.mod_price,
                                    qty: m.quantity,
                                });

                                mods.push(m.quantity + ' ' + m.modifier_name);
                                totalUnforcedMods += m.mod_price;
                            });
                        }

                        //add modifiers property to order
                        Object.assign(productValues, {
                            modifier_ids: modifierIds,
                            modifier_pricing_ids: modifierPricingIds,
                            modifier_qty: modifierQtys,
                            modifiers: mods,
                            total_forced_mod_prices: totalForcedMods,
                            total_unforced_mod_prices: totalUnforcedMods,
                            total_mod_price: totalForcedMods + totalUnforcedMods,
                            total_price_with_mods: x.totals.beforeSc + totalForcedMods + totalUnforcedMods,
                        })
                    }

                    //for line item void
                    if(x.isVoided == true) { //set the voided values in localstorage
                        Object.assign(productValues, {
                            discount_type: 'Void',
                            discount_value: 0,
                            is_voided: true,
                            item_net_sales: 0,
                            menu_price: 0,
                            price: 0,
                            qty: 0,
                            reason: '',
                            service_charge_rate: 0,
                            svc_charge_is_after_discount: 0,
                            svc_charge_is_net_based: 0,
                            svc_charge_amt: 0,
                            svc_charge_rate: 0,
                            tax_amount: 0,
                            total_forced_mod_prices: 0,
                            total_mod_price: 0,
                            total_price_with_mods: 0,
                            total_tax_amount: 0,
                            total_unforced_mod_prices: 0,
                            total_vatable_price: 0,
                            vatable_price: 0,
                        })
                    }

                    new_order.push(productValues);
                });

                itemValues.table = order.tableId;
                itemValues.pax = order.pax;
                itemValues.channel = order.serviceType.includes('QSR') ? order.serviceType : order.channelName;
                itemValues.service_type_id = order.serviceTypeId;
                itemValues.service_charge_name = '';
                itemValues.created_at = '';
                itemValues.dynamic_tax_total = 0;
                itemValues.dynamic_taxes = [];
                itemValues.more_details = order.orderDetail ?? '';
                itemValues.kot = order.kots;
                itemValues.order = new_order;
                itemValues.order_id = res.order_id;
                itemValues.order_num = order.order_num;
                itemValues.order_taker = order.order_taker ?? '';
                itemValues.sub_total = order.totals.net;
                itemValues.service_charge = order.totals.serviceCharge;
                itemValues.vat_amt = order.totals.vat;
                itemValues.bill_price = order.totals.total;
                itemValues.tracking = { terminal_origin: order.originTerminalId || '1' },
                itemValues.transaction_id = uniq(res.transaction_ids);
                itemValues.url = order.pax;
                itemValues.serviceType = order.serviceType;

                if(!isEmpty(order.bills)) { //billed transaction from online
                    itemValues.bills = order.bills;
                    itemValues.tag = "billed-sum";
                }

                if(order.isBilled && isEmpty(order.bills)) { //Billed transaction from offline
                    itemValues.bills = {
                        bill_id: [order.bill_num],
                        kot_ids: order.kots,
                        idb_billing_data: {
                            bill_id: res.billing_id
                        }
                    };
                    itemValues.tag = "billed-sum";
                }

                if(!isEmpty(all_discounts)) { //item level discount
                    itemValues.all_discounts = all_discounts;
                    itemValues.discount_customer_details = discount_customer_details;
                    itemValues.total_discount = total_discount_amount;

                    if(!isEmpty(all_vat)) {
                        itemValues.all_vat = all_vat;
                        itemValues.total_vat_ex_sales = get(all_vat, 'Vat Exempt Sales', 0);
                        itemValues.total_zero_rtd_sales = get(all_vat, 'Zero Rated Sales', 0);
                    }
                }


                //for Bill level discount
                if(!isEmpty(order.billDiscount)) {
                    const billDisc = order.billDiscount;
                    let allDisc = {};
                    let allVat = {};

                    allDisc[billDisc.discount.discount_name] = order.billDiscount.amount;
                    itemValues.all_discounts = allDisc;
                    itemValues.discount_customer_details = order.billDiscount.customerDetails.map((detail) => {
                                const { firstName, lastName, idReferenceNumber, id } = detail;
                                const custId = get(res.customerDetails.find(c =>
                                    c.reference_number == idReferenceNumber &&
                                    c.discount_name == billDisc.discount.discount_name &&
                                    c.first_name == firstName
                                ), 'customer_detail_id', null);

                                return {
                                    discount_name: billDisc.discount.discount_name,
                                    discount_type: billDisc.discount.discount_name,
                                    discount_value: billDisc.amount,
                                    first_name: firstName,
                                    last_name: lastName,
                                    reference_number: idReferenceNumber,
                                    id: id ?? custId,
                                };
                            });
                    itemValues.total_discount = billDisc.amount;

                    //for saving vat exempt
                    if(vatExempt.includes(billDisc.discount.discount_name)) {
                        allVat['Vat Exempt Sales'] = order.totals.net;
                    }

                    //for saving zero rated
                    if(billDisc.discount.discount_name == 'Diplomat Discount') {
                        allVat['Zero Rated Sales'] = order.totals.net;
                    }
                    itemValues.all_vat = allVat;
                    itemValues.bill_disc_id = billDisc.discount.discount_detail_id;
                    itemValues.bill_disc_method = billDisc.discount.discount_rate != null ? 'percentage' : 'amount';
                    itemValues.bill_disc_type = billDisc.discount.discount_name;
                    itemValues.bill_disc_value = billDisc.amount;
                    itemValues.total_discount = billDisc.amount;
                    itemValues.total_vat_ex_sales = order.totals.net;

                }

                localStorage.setItem(itemKey, JSON.stringify(itemValues));
            });
        },

        updateSessionStorage(data) {
            sessionStorage.setItem('last_kot', data.last_kot);
            sessionStorage.setItem('last_bill', data.last_bill);
        },
    },
};
</script>

<style>
.sync-container {
    display: grid;
    place-content: center;
    height: 90%;
    width: 100%;
    padding-right: 3%;
}
</style>