<template>
  <Switch
    v-model="enabled"
    :class="{ enabled }"
    class="m-switch"
    @click.stop="handleChange"
  >
    <span aria-hidden="true" />
  </Switch>
</template>

<script>
import { Switch } from "@headlessui/vue";

export default {
  name: "MSwitch",

  props: {
    defaultValue: {
      type: Boolean,
      required: true,
    },
  },

  components: { Switch },

  data() {
    return {
      enabled: true,
    };
  },

  watch: {
    defaultValue() {
      this.enabled = this.defaultValue;
    },
  },

  methods: {
    handleChange() {
      this.$emit("changed", this.enabled);
    },
  },

  mounted() {
    this.enabled = this.defaultValue;
  },
};
</script>

<style>
.m-switch {
  position: relative;
  display: inline-flex;
  height: 24px;
  width: 48px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  background: gray;
  padding: 0;
}

.m-switch.enabled {
  background-color: #2fa360;
}

.m-switch span {
  pointer-events: none;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  transform: translateX(0px);
  transition: transform 200ms;
}

.m-switch.enabled span {
  transform: translateX(24px);
}
</style>
