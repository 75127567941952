<template>
  <base-modal
    v-bind="$attrs"
    :click-to-close="false"
    confirm-label="Save"
    min-width="80vw"
    min-height="80vh"
    @confirm="save"
    >
    <template #title>Bluetooth Printer</template>

    <div class="card my-2">
      <div class="card-body d-flex justify-content-between align-items-center">
        <!-- <div class="d-flex flex-column" v-if="!nativeConfigReceived">
          <strong>No Bluetooth printer selected.</strong>
        </div> -->
        <div class="d-flex flex-column" v-if="!deviceName">
          <strong>No Bluetooth printer selected.</strong>
          <span>Please select a printer by tapping Select Printer...</span>
        </div>
        <div class="d-flex flex-column" v-else>
          <strong>{{ deviceName }}</strong>
          <label class="m-0 font-weight-normal" v-if="settings">
            <input type="checkbox" v-model="settings.enabled" /> Enabled
          </label>
        </div>
        
        <button class="btn btn-secondary ml-2" @click="onSelectPairedPrinter">
          Select Printer...
        </button>
      </div>
    </div>

    <!-- <pre>{{ JSON.stringify(settings, null, 2) }}</pre> -->
    <DiscoveredPrinterListItem v-if="deviceName && settings"
      :printer="settings?.printerConfig"
      :productCategories="productCategories"
      :hideRemove="true"
      @testPrint="testPrint"
      @remove="noop" />
    <div v-else>
      Select a printer to configure it.
    </div>
  </base-modal>
</template>
<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import {test_print} from '@/spa/services/printer-service';
import {PRINTER_MODELS} from '@/local_printer_config_constants';
import DiscoveredPrinterListItem from '@/spa/components/common/DiscoveredPrinterListItem';

export default {
  components: {
    BaseModal,
    DiscoveredPrinterListItem
  },
  data() {
    return {
      PRINTER_MODELS,
      ipAddress: '',
      nativeConfigReceived: false,
      deviceName: null,
      deviceAddress: null
    };
  },
  async mounted() {
    this.deviceName = await app_storage_get('BLUETOOTH_PRINTER_NAME');
    this.devicecAddress = await app_storage_get('BLUETOOTH_PRINTER_ADDRESS');

    const listener = this.messageListener = async (event) => {
      if (!event.data.mosaicBridgeMessage) return;

      switch (event.data.type) {
        case 'BluetoothConfiguration': {
          const { name, address } = event.data.body.printer;

          this.deviceName = name;
          this.deviceAddress = address;
          this.settings.enabled = true;
          this.nativeConfigReceived = true;
          break;
        }
      }
    };
    window.addEventListener('message', listener);
  },
  async beforeUnmount() {
    window.removeEventListener('message', this.messageListener);
  },


  emits: ['save', 'selectPaired', 'testPrint'],
  methods: {
    testPrint(printer) {
      test_print(printer);
    },
    save() {
      this.$emit('save', this.settings);
    },
    onSelectPairedPrinter() {
      this.$emit('selectPaired');
    },
    noop() {},
  },
  props: {
    settings: Object,
    productCategories: Array, 
  }
}
</script>
