<template>
  <base-modal v-if="editMode" v-bind="$attrs" cancel-label="Skip" min-width="320px" min-height="100px"
    :enable-outside-click="false" @confirm="saveCustomerDetails" @cancel="skipAndReset">
    <template v-slot:title>More details</template>

    <hr />

    <div class="form-group mt-4">
      <label>Customer Details</label>

      <div class="form-label">Customer name</div>
      <input v-model="customerDetails.name" class="form-control" />

      <div class="form-label">Business Style</div>
      <input v-model="customerDetails.businessStyle" class="form-control" />

      <div class="form-label">Customer Address</div>
      <input v-model="customerDetails.address" class="form-control" />

      <div class="form-label">TIN #</div>
      <input v-model="customerDetails.tin" class="form-control" />

      <div class="form-label">Manual Sales Invoice #</div>
      <input v-model="customerDetails.officialReceipt" class="form-control" maxlength="20" />
    </div>
  </base-modal>

  <base-modal v-else v-bind="$attrs" cancel-label="Skip" min-width="0" max-width="400px" width="100%" min-height="100px"
    :enable-outside-click="false" @confirm="saveCustomerDetails" @cancel="skipAndReset">
    <template v-slot:title>More details</template>

    <hr />

    <div class="form-group mt-4">
      <label>Customer Details</label>

      <div class="form-label">Customer name</div>
      <input v-model="name" class="form-control" />

      <div class="form-label">Business Style</div>
      <input v-model="businessStyle" class="form-control" />

      <div class="form-label">Customer Address</div>
      <input v-model="address" class="form-control" />

      <div class="form-label">TIN #</div>
      <input v-model="tin" class="form-control" />

      <div class="form-label">Manual Sales Invoice #</div>
      <input v-model="officialReceipt" class="form-control" maxlength="20" />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";

export default {
  name: "CustomerDetails",

  components: {
    BaseModal,
  },

  props: {
    customerDetails: {
      type: [Object, null],
    },
    selectedOrder: {
      type: [Object, null],
    },
    editMode: {
      type: Boolean,
    },
  },

  data() {
    return {
      name: "",
      businessStyle: "",
      address: "",
      tin: "",
      officialReceipt: "",
    };
  },

  methods: {
    resetForm() {
      this.name = "";
      this.businessStyle = "";
      this.address = "";
      this.tin = "";
      this.officialReceipt = "";
    },

    async saveCustomerDetails() {
      let customerInfo = {
        name: "",
        businessStyle: "",
        address: "",
        tin: "",
        officialReceipt: "",
      };

      if (this.editMode) {
        customerInfo = {
          name: this.customerDetails.name,
          businessStyle: this.customerDetails.businessStyle,
          address: this.customerDetails.address,
          tin: this.customerDetails.tin,
          officialReceipt: this.customerDetails.officialReceipt,
        };
      } else {
        customerInfo = {
          name: this.name,
          businessStyle: this.businessStyle,
          address: this.address,
          tin: this.tin,
          officialReceipt: this.officialReceipt,
        };
      }

      this.$emit("saveCustomerDetails", this.selectedOrder, customerInfo);

      if (!this.editMode) this.resetForm();
    },

    skipAndReset() {
      this.$emit("skipCustomerDetails");
      this.resetForm();
    },
  },
};
</script>

<style scoped>
.error-label {
  font-size: 12px;
  color: red;
}
</style>
