import {
    ProductBridge,
    ProductCategoryBridge,
    ProductGroupBridge,
    PriceBridge,
    ReceiptDetailBridge,
    ServiceChannelBridge,
    TaxBridge,
    LocationBridge
} from './offload';

import {
    ProductModifierBridge,
    ProductModifierPriceBridge
} from './product-modifier';

import {
    PrinterDetailBridge
} from './offload-printers';

import {
    UserPermissionBridge
} from './offload-permission';

import {
    PaymentMethodBridge
} from './payment-method';

function createLazyInstance(constructor) {
    let instance;

    return function () {
        if (!instance) {
            instance = new constructor();
        }
        return instance;
    };
}

export const sqliteOffloadBridges = {
    product_pricing: {
        lastFetchKey: 'productPricingLastFetchAt',
        bridge: createLazyInstance(PriceBridge)()
    },
    product_detail: {
        lastFetchKey: 'productDetailLastFetchAt',
        bridge: createLazyInstance(ProductBridge)()
    },
    product_categories: {
        lastFetchKey: 'productCategoryLastFetchAt',
        bridge: createLazyInstance(ProductCategoryBridge)()
    },
    product_groups: {
        lastFetchKey: 'productGroupLastFetchAt',
        bridge: createLazyInstance(ProductGroupBridge)()
    },
    modifier_detail: {
        lastFetchKey: 'modifierDetailLastFetchAt',
        bridge: createLazyInstance(ProductModifierBridge)()
    },
    modifier_pricing: {
        lastFetchKey: 'modifierPriceLastFetchAt',
        bridge: createLazyInstance(ProductModifierPriceBridge)()
    },
    own_printer_ips: {
        lastFetchKey: 'ownPrinterIpsLastFetchAt',
        bridge: createLazyInstance(PrinterDetailBridge)(),
    },
    receipt_detail: {
        lastFetchKey: 'receiptDetailLastFetchAt',
        bridge: createLazyInstance(ReceiptDetailBridge)()
    },
    service_channel: {
        lastFetchKey: 'serviceChannelLastFetchAt',
        bridge: createLazyInstance(ServiceChannelBridge)()
    },
    tax_detail: {
        lastFetchKey: 'taxLastFetchAt',
        bridge: createLazyInstance(TaxBridge)()
    },
    user_type_location_permissions: {
        lastFetchKey: 'userPermissionLastFetchAt',
        bridge: createLazyInstance(UserPermissionBridge)(),
    },
    locations: {
        lastFetchKey: 'locationLastFetchAt',
        bridge: createLazyInstance(LocationBridge)(),
    },
    payment_methods: {
        lastFetchKey: 'paymentMethodLastFetchAt',
        bridge: createLazyInstance(PaymentMethodBridge)(),
    },
};
