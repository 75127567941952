import axios from "@/spa/plugins/axios";

export const submitTransactionsToIkonomikal = (locationId, posDate, isPreview = false) => {
  const headers = { "Content-Type": "application/json" };

  return axios.post(
    route('ikonomikal.submit'),
    { location_id: locationId, pos_date: posDate, preview: isPreview },
    { headers },
  );
};

export default {
  submitTransactionsToIkonomikal,
}