<template>
  <div id="product-settings-offload">
    <div class="d-flex flex-nowrap">
      <div class="settings-page p-4">
        <label>Settings Page</label>
        <button
          class="btn btn-primary btn-lg mr-5"
          @click="fetchProducts()"
        >
          <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
        </button>
      </div>
      <input
        class="form-control col-5 mt-4 ml-5"
        type="text"
        placeholder="Search Product"
        v-model="searchQuery"
        @input="handleSearchMenu"
      />
    </div>
    <div class="row mx-2">
      <div class="col-xl-4 order-lg-12">
        <SettingsSidebar />
      </div>
      <div class="col">
        <div class="category-section mt-2">
          <ul>
            <li id="category-products">
              <a href="#" class="cat-btn" :class="{ 'active': isActive('category-products') }" @click="toggleActive('category-products'); fetchProducts();">
                <div class="cat-wrap item">
                  <h4>Products</h4>
                </div>
              </a>
            </li>
            <li id="category-modifiers" v-if="isModifierPricingMigrated">
              <a href="#" class="cat-btn" :class="{ 'active': isActive('category-modifiers') }" @click="toggleActive('category-modifiers'); fetchModifiers();">
                <div class="cat-wrap item">
                  <h4>Modifiers</h4>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <div class="tab-panel" v-if="activeTab === 'category-products'">
          <vue3-easy-data-table
            v-model:server-options="products.serverOptions"
            :server-items-length="products.totalItems"
            :loading="isFetching"
            :headers="products.headers"
            :items="products.data"
            @update-sort="
              products.serverOptions.sortBy = $event.sortBy;
              products.serverOptions.sortType = $event.sortType;
              fetchProducts();
            "
          >
            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                    class="btn btn-sm btn-block btn-primary"
                    @click="openModal(item, 'category-products')"
                  >
                    Update Availability
                  </button>
                </div>
              </div>
            </template>

            <template #pagination>
              <button
                class="btn btn-primary mr-2"
                :disabled="products.serverOptions.page === 1"
                  @click="fetchProducts(products.serverOptions.page, 'prev')">
                <i class="fa fa-chevron-left"></i>
              </button>
              <button
                class="btn btn-primary ml-2"
                :disabled="products.totalItems === 0 || products.serverOptions.page === Math.ceil(products.totalItems / products.serverOptions.rowsPerPage)"
                  @click="fetchProducts(products.serverOptions.page, 'next')">
                <i class="fa fa-chevron-right"></i>
              </button>
            </template>
          </vue3-easy-data-table>
        </div>

        <div class="tab-panel" v-else-if="activeTab === 'category-modifiers'">
          <vue3-easy-data-table
              v-model:server-options="modifiers.serverOptions"
              :server-items-length="modifiers.totalItems"
              :loading="isFetching"
              :headers="modifiers.headers"
              :items="modifiers.data"
              @update-sort="
              modifiers.serverOptions.sortBy = $event.sortBy;
              modifiers.serverOptions.sortType = $event.sortType;
              fetchModifiers();
            "
          >
            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                      class="btn btn-sm btn-block btn-primary"
                      @click="openModal(item, 'category-modifiers')"
                  >
                    Update Availability
                  </button>
                </div>
              </div>
            </template>

            <template #pagination>
              <button
                  class="btn btn-primary mr-2"
                  :disabled="modifiers.serverOptions.page === 1"
                  @click="fetchModifiers(modifiers.serverOptions.page, 'prev')">
                <i class="fa fa-chevron-left"></i>
              </button>
              <button
                  class="btn btn-primary ml-2"
                  :disabled="modifiers.totalItems === 0 || modifiers.serverOptions.page === Math.ceil(modifiers.totalItems / modifiers.serverOptions.rowsPerPage)"
                  @click="fetchModifiers(modifiers.serverOptions.page, 'next')">
                <i class="fa fa-chevron-right"></i>
              </button>
            </template>
          </vue3-easy-data-table>
        </div>
      </div>
    </div>

    <product-availability-modal
      v-model="products.isModalOpen"
      :product="selectedItem"
      @saveAvailability="saveAvailability"
    />

    <modifier-price-modal
      v-model="modifiers.isModalOpen"
      :modifier="selectedItem"
      @saveAvailability="saveModifierAvailability"
    />
  </div>
</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import SettingsSidebar from "@/spa/components/common/SettingsSidebar";
import ProductAvailabilityModal from "@/spa/components/modals/offload/ProductAvailabilityModal";
import ModifierPriceModal from "@/spa/components/modals/offload/ModifierPriceModal";
import {checkCloudConnection} from "@/spa/plugins/axios";
import {updateProductPrices, updateModifierPrices} from "@/spa/services/product-service";

export default {
  components: {
    SettingsSidebar,
    Vue3EasyDataTable,
    ProductAvailabilityModal,
    ModifierPriceModal,
  },
  data() {
    return {
      productBridge: new ProductBridge(),
      modifierBridge: new ProductModifierBridge(),
      searchQuery: "",
      products: {
        headers: [
          { text: 'Item Name', value: 'product_name', sortable: true },
          { text: 'Product Category', value: 'category', sortable: true },
          { text: 'Product Group', value: 'product_group_name', sortable: true },
          { text: 'Product Sub Group', value: 'product_sub_group_name', sortable: true },
          { text: 'Actions', value: 'actions' },
        ],
        data: [],
        serverOptions: {
          page: 1,
          rowsPerPage: 15,
          sortBy: 'product_name',
          sortType: 'asc',
        },
        totalItems: 0,
        isModalOpen: false,
      },
      modifiers: {
        headers: [
          { text: 'Item Name', value: 'product_name', sortable: true },
          { text: 'Modifier Group', value: 'mod_group_name', sortable: true },
          { text: 'Modifier Name', value: 'modifier_name', sortable: true },
          { text: 'Type', value: 'modifier_type', sortable: true },
          { text: 'Actions', value: 'actions' },
        ],
        data: [],
        serverOptions: {
          page: 1,
          rowsPerPage: 15,
          sortBy: 'modifier_name',
          sortType: 'asc',
        },
        totalItems: 0,
        isModalOpen: false,
      },
      isFetching: false,
      activeTab: 'category-products',
      selectedItem: null,
      isModifierPricingMigrated: false,
    }
  },
  async mounted() {
    await this.fetchProducts();

    const lb = new LocationBridge();
    this.isModifierPricingMigrated = await lb.getColumnValue('is_modifier_pricing_migrated') == 1;
  },
  methods: {
    async fetchProducts(page = 1, nav = null) {
      try {
        this.isFetching = true;
        const { serverOptions } = this.products;
        serverOptions.productName = this.searchQuery;
        serverOptions.page = page;
        serverOptions.itemsPerPage = serverOptions.rowsPerPage;

        const lastPage = Math.ceil(this.products.totalItems / serverOptions.rowsPerPage);
        if (nav == "next" && serverOptions.page >= 1) {
          serverOptions.page++;
        } else if (nav == "prev" && serverOptions.page <= lastPage) {
          serverOptions.page--;
        }

        const response = await this.productBridge.getProducts(serverOptions);
        this.products.data = response.data;
        this.products.totalItems = response.totalItems;
      } catch (e) {
        this.$swal.error(e.message);
      } finally {
        this.isFetching = false;
      }
    },

    async fetchModifiers(page = 1, nav = null) {
      try {
        this.isFetching = true;
        const { serverOptions } = this.modifiers;
        serverOptions.modifierName = this.searchQuery;
        serverOptions.page = page;
        serverOptions.itemsPerPage = serverOptions.rowsPerPage;

        const lastPage = Math.ceil(this.modifiers.totalItems / serverOptions.rowsPerPage);
        if (nav == "next" && serverOptions.page >= 1) {
          serverOptions.page++;
        } else if (nav == "prev" && serverOptions.page <= lastPage) {
          serverOptions.page--;
        }

        const response = await this.modifierBridge.getRowsPaged(serverOptions);
        this.modifiers.data = response.data;
        this.modifiers.totalItems = response.totalItems;
      } catch (e) {
        this.$swal.error(e.message);
      } finally {
        this.isFetching = false;
      }
    },

    handleSearchMenu() {
      clearTimeout(this.debouncedSearch);
      this.debouncedSearch = setTimeout(() => {
        this.fetchProducts();
      }, 500);
    },

    openModal(item, category = 'category-products') {
      this.selectedItem = item;
      if (category === 'category-products') {
        this.products.isModalOpen = true;
      } else {
        this.modifiers.isModalOpen = true;
      }
    },

    async saveAvailability(prices) {
      if (!(await checkCloudConnection())) {
        this.$swal.warning('No internet connection. Please connect to the internet to update the availability of this item.');
        return;
      }

      try {
        // local|SQLite
        const priceBridge = new PriceBridge();
        await priceBridge.bulkImport(prices);

        // Cloud
        updateProductPrices(prices);

        this.selectedItem = null;
        this.products.isModalOpen = false;
      } catch (e) {
        this.$swal.error(e?.message ?? e?.response?.data?.message ?? 'Something went wrong');
      }
    },
    async saveModifierAvailability(prices) {
      if (!(await checkCloudConnection())) {
        this.$swal.warning('No internet connection. Please connect to the internet to update the availability of this item.');
        return;
      }

      try {
        // local|SQLite
        const mpb = new ProductModifierPriceBridge();
        await mpb.bulkImport(prices);

        const pmb = new ProductModifierBridge();
        for (const price of prices ) {
          await pmb.update({
            id: price.id,
            is_available: price.is_available
          });
        }

        // Cloud
        updateModifierPrices(prices);

        this.selectedItem = null;
        this.modifiers.isModalOpen = false;
      } catch (e) {
        this.$swal.error(e?.message ?? e?.response?.data?.message ?? 'Something went wrong');
      }
    },
    toggleActive(tab) {
      this.activeTab = tab;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
  }
}
</script>
