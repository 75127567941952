import { pick, omit } from 'lodash';
import cloneDeep from 'rfdc/default';

export const trimProduct = product => {
    const unusedFields = [
        'sku',
        'updated_at',
        'created_at',
        'deleted_at',
        'product_tag',
        'product_desc',
        'image',
        'available',
        'tax_data',
    ];

    return cloneDeep(omit(product, unusedFields));
}

export const trimSyncedOrder = order => {
    const essentialFields = [
        '_id',
        'billDiscount',
        'brandId',
        'isVoided',
        'isBilled',
        'isSettled',
        'isSync',
        'isSyncToReceipts',
        'isNonVat',
        'kotBreakdowns',
        'originTerminalId',
        'serviceType',
        'serviceTypeId',
        'void_receipt_num',
        'void_bill_num',
        'originShiftTable',
        'billedShiftTable',
        'totals',
        'kots',
        'pax',
        'bill_num',
        'receipt_num',
        'reprint_count',
        'separatorPositions',
        'kot_num',
        'status',
        'splits',
        'payments',
        'tableName',
        'isUpdateSyncing',
        'isUpdateSynced',
        'locationId',
        'customerDetails',
        'orderIdentifier'
    ];

    const trimmed = cloneDeep(pick(order, essentialFields));
    trimmed.orders = order.orders.map(o => ({
        ...o,
        product: trimProduct(o.product),
    }));

    return trimmed;
}

export default {
    trimProduct,
    trimSyncedOrder,
}