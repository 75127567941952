import { get, forEach, set, isFunction, has, isString, pick } from 'lodash';
import localForage from 'localforage';
import cloneDeep from 'rfdc/default';

import {getSyncedOrders} from "@/spa/services/sync-service";

export const objectMapper = (data, mapping) => {
    const result = {};
    forEach(mapping, (source, dest) => {
        let sourceKey = isString(source) ? source : source.source;
        let payload = sourceKey ? get(data, sourceKey) : data;

        if (isFunction(source.transform)) {
            payload = source.transform(payload);
        }

        if (has(source, 'fallback')) {
            payload = Boolean(payload)
                ? payload
                : runFallbackIfFunction(source.fallback, data);
        }

        set(result, dest, payload);
    });

    return result;
}

export const runFallbackIfFunction = (fallback, data) => isFunction(fallback) ? fallback(data) : fallback;

export const safeJsonParse = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        console.error(e);
        return {};
    }
}

export const transformFromLocalStorage = (key, mapping) => {
    return objectMapper(
        safeJsonParse(localStorage.getItem(key)),
        mapping,
    );
}

export const safeLocalForageSet = (key, value) => {
    const payload = isCloneable(value) ? value : cloneDeep(value);
    localForage.setItem(key, payload);
}

export const createSafeLocalForageSet = localForageInstance => (key, value) => {
    const payload = isCloneable(value) ? value : cloneDeep(value);
    localForageInstance.setItem(key, payload);
}

export const isCloneable = obj => {
    try {
        postMessage(obj, "*");
    } catch (error) {
        if (error?.code === 25) return false; // DATA_CLONE_ERR
    }

    return true;
};

const getStorageId = (orderId = '') => `${window.locationId}-order-${orderId}`;

// => deprecated <=
export const getLastSeries = async (series) => {
    const keyPrefix = getStorageId();
    const keys = await localForage.keys();

    const pending = await Promise.all(keys.map(async key => {
        if (key.startsWith(keyPrefix)) {
            return await localForage.getItem(key);
        }
    }));

    const synced = await getSyncedOrders();

    const getSeries = (orders) => {
        return orders.filter(item => typeof item === 'object')
            .map(obj => {
                obj.receipt_num = [obj.receipt_num || null];
                obj.bill_num = [obj.bill_num || null];
                if (obj.splits?.length > 0) {
                    obj =  {
                        ...obj,
                        bill_num: obj.splits.map(split => split.bill_num || null),
                        receipt_num: obj.splits.map(split => split.receipt_num || null)
                    };
                }
                return pick(obj, ['kots', 'bill_num', 'receipt_num', 'void_bill_num', 'void_receipt_num'])
            });
    }

    const orders = [...getSeries(pending), ...getSeries(synced)];

    if (
        series === "kots" ||
        series === "bill_num" ||
        series === "receipt_num"
    ) {
        return orders.reduce((max, item) => {
            if (item[series] && item[series].length > 0) {
                const maxSeriesInItem = Math.max(...item[series]);
                if (maxSeriesInItem > max) {
                    return maxSeriesInItem;
                }
            }
            return max;
        }, 0) + 1;
    } else if (
        series === "void_bill_num" ||
        series === "void_receipt_num"
    ) {
        return orders.reduce((max, item) => {
            if (item[series] && item[series] > max) {
                return item[series];
            }
            return max;
        }, 0) + 1;
    }
}
