import axios from "@/spa/plugins/axios";
import {isNetworkStable} from "@/spa/utils/networkCheck";

export default async function(router) {
    let pendingAjaxCall = 0;
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        pendingAjaxCall++;
        // show loader
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
        pendingAjaxCall--;
        return response;
    }, function (error) {
        pendingAjaxCall--;
        return Promise.reject(error);
    });

    router.beforeEach(async (to) => {
        if (window.enableOfflineMode || !isNetworkStable()) return true;
        const hasUpdate = window.SERVICE_WORKER_UPDATE_FOUND;

        if (hasUpdate) {
            console.log('Reloading for webapp update...');
            window.SERVICE_WORKER_UPDATE_FOUND = false;
            location.href = to.href;
            return false;
        }
        return true;
    });
}
