<template>
  <button
    class="btn m-icon-button d-flex flex-column"
    :class="{ disabled }"
    :disabled="disabled || isLoading"
    v-bind="{
      ...$attrs,
      ...colorAttribute,
    }"
    @click.stop="clickHandler"
  >
    <template v-if="icon">
      <div v-if="!isLoading" class="icon-container mb-2">
        <i v-if="icon" :class="iconClass" />
        <slot v-else name="icon" />
      </div>

      <div v-else class="icon-container mb-2">
        <i :class="`fa fa-${loadingIcon} fa-spin`" />
      </div>
    </template>

    <div class="label">
      {{ label }}
    </div>
  </button>
</template>

<script>
export default {
  name: "MIconButton",

  emits: ["click"],

  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loadingIcon: {
      type: String,
      default: "circle-notch",
    },
    postClickDelay: {
      // in seconds
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    iconClass() {
      return `fa fa-${this.icon}`;
    },

    colorAttribute() {
      if (this.color.startsWith("#")) {
        return { style: { backgroundColor: this.color } };
      } else {
        return { class: `btn-${this.color}` };
      }
    },
  },

  methods: {
    clickHandler() {
      if (this.disabled || this.isLoading) return;

      if (this.postClickDelay > 0) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, this.postClickDelay * 1000);
      }

      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.m-icon-button {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  color: white;
  box-shadow: -1px 2px 2px #117ac5;
}

.m-icon-button.btn-danger {
  box-shadow: -1px 2px 2px rgba(167, 44, 44, 0.89);
}

.m-icon-button:active {
  box-shadow: none !important;
  transform: scale(0.97);
}

.m-icon-button .icon-container i {
  font-size: 1.2rem;
}

.m-icon-button .label {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
}

.m-icon-button.disabled {
  pointer-events: none;
}
</style>
