<template>
  <svg width="80" height="80" viewBox="0 0 49 60" fill="none">
    <path
      d="M3.43735 0.653011C2.3294 0.9991 1.32754 1.92201 0.962159 2.94875C0.761787 3.52557 0.75 4.8061 0.75 30.0246C0.75 55.3354 0.761787 56.5236 0.962159 57.1004C1.24504 57.908 2.05831 58.7732 2.90695 59.177L3.57878 59.5H24.5H45.4212L46.0931 59.177C46.9417 58.7732 47.755 57.908 48.0378 57.1004C48.2264 56.5467 48.25 56.016 48.25 50.6401V44.7681L47.9671 44.5489C47.6135 44.2605 47.2364 44.272 46.8474 44.5719L46.5409 44.8027V50.4209C46.5292 56.3275 46.5056 56.5928 45.987 57.1581C45.8691 57.285 45.5744 57.4927 45.3387 57.608C44.9144 57.8157 44.2897 57.8272 24.5 57.8272C4.7103 57.8272 4.08561 57.8157 3.66129 57.608C3.10732 57.3312 2.83623 57.0312 2.62407 56.4775C2.38834 55.8199 2.38834 4.22928 2.62407 3.57171C2.83623 3.01796 3.10732 2.71802 3.66129 2.44115C4.06203 2.24503 4.39206 2.22195 7.50372 2.22195C10.7804 2.22195 10.9218 2.21042 11.2283 1.97969C11.6526 1.65667 11.6526 1.05678 11.2283 0.733765C10.9218 0.503036 10.7804 0.491501 7.42122 0.503036C4.89888 0.503036 3.79094 0.549183 3.43735 0.653011Z"
      fill="#1B75BB" />
    <path
      d="M13.7978 0.572163C13.7153 0.595238 13.5738 0.779819 13.4677 0.975937C13.3145 1.26435 13.3145 1.37971 13.4324 1.65658C13.5031 1.8527 13.6681 2.04882 13.786 2.1065C13.9156 2.17572 19.7854 2.22186 29.4504 2.22186C44.384 2.22186 44.9144 2.2334 45.3387 2.44106C45.5744 2.55642 45.8691 2.76407 45.987 2.89097C46.5527 3.49086 46.5291 2.82175 46.5409 23.0681V42.1261L46.8474 42.3569C47.2363 42.6568 47.6135 42.6684 47.9671 42.3799L48.25 42.1608V22.8489C48.25 4.48299 48.2382 3.51394 48.0378 2.94865C47.755 2.14111 46.9417 1.27588 46.093 0.872108L45.4212 0.549091L29.6861 0.52602C21.0347 0.514481 13.8803 0.537556 13.7978 0.572163Z"
      fill="#1B75BB" />
    <path
      d="M36.9349 19.4688C36.6049 19.7803 36.5813 19.9534 36.7699 20.3687C36.9938 20.8532 37.3239 20.9109 39.8226 20.9109C42.3214 20.9109 42.6514 20.8532 42.8754 20.3687C43.0639 19.9534 43.0404 19.7803 42.7103 19.4688L42.4275 19.1804H39.8226H37.2178L36.9349 19.4688Z"
      fill="#1B75BB" />
    <path
      d="M8.41129 24.2449C7.93983 24.5218 7.57444 24.9371 7.42122 25.3293C7.32692 25.5485 7.29156 28.6749 7.29156 35.0545C7.29156 44.0529 7.30335 44.4682 7.51551 44.8835C7.79839 45.4257 8.10484 45.691 8.6706 45.8987C9.25993 46.0948 17.9584 46.1294 18.4063 45.9333C18.6892 45.8064 18.9603 45.3911 18.9603 45.0911C18.9603 45.0104 18.8542 44.8027 18.7128 44.6297L18.477 44.3297H13.951C11.464 44.3297 9.35422 44.2951 9.24814 44.2605C9.08313 44.2028 9.05956 43.8798 9.05956 41.8379V39.4845H20.1979H31.3362V41.9071V44.3297H26.2916H21.2351L20.9876 44.5951C20.6694 44.9296 20.6576 45.368 20.9522 45.7256L21.1762 46.0025L30.4522 46.0371C36.487 46.0486 39.8815 46.0256 40.1644 45.9448C40.7655 45.7948 41.1898 45.4488 41.4727 44.895C41.6967 44.4682 41.7084 44.1798 41.7084 35.0545C41.7084 28.698 41.6731 25.5485 41.5788 25.3293C41.4256 24.9371 40.9305 24.4064 40.5062 24.1872C40.2351 24.0488 37.7717 24.0257 24.4764 24.0257C9.35422 24.0257 8.7531 24.0373 8.41129 24.2449ZM31.3362 28.1211V30.4861H20.1979H9.05956V28.2019C9.05956 26.9444 9.09491 25.8715 9.14206 25.8369C9.17742 25.7908 14.1985 25.7562 20.2804 25.7562H31.3362V28.1211ZM39.9169 28.1442L39.9404 30.4861H36.5223H33.1042V28.1211V25.7562L36.4988 25.7793L39.8815 25.8139L39.9169 28.1442ZM31.3362 34.9853V37.754H20.1979H9.05956V34.9853V32.2165H20.1979H31.3362V34.9853ZM39.9404 34.9853V37.754H36.5223H33.1042V35.066C33.1042 33.5778 33.1396 32.3319 33.1867 32.2973C33.2221 32.2512 34.7661 32.2165 36.6048 32.2165H39.9404V34.9853ZM39.9404 41.8494C39.9404 44.2028 39.9404 44.2144 39.6811 44.272C39.5279 44.3067 38.0074 44.3182 36.2866 44.3067L33.1632 44.272L33.1278 42.0225C33.116 40.7881 33.1278 39.7152 33.1514 39.6344C33.1985 39.5191 33.9529 39.4845 36.5813 39.4845H39.9404V41.8494Z"
      fill="#1B75BB" />
    <path
      d="M30.5229 50.8131C30.1693 50.9976 29.7804 51.3783 29.3325 51.9436C28.8846 52.5435 28.5899 52.8204 28.3896 52.8435C27.6234 52.9473 27.317 53.7894 27.8592 54.3086C28.1774 54.6316 28.3542 54.6547 29.0025 54.4816C29.5682 54.3201 30.0515 53.9163 30.6644 53.1088C31.4777 52.0244 31.6073 52.0244 32.4559 53.1434C33.3871 54.3778 34.165 54.7585 35.2022 54.4932C35.8033 54.3317 36.2866 53.9394 36.9113 53.1088C37.7246 52.0244 37.8542 52.0244 38.7028 53.1434C39.0564 53.6164 39.5161 54.1009 39.7283 54.2278C40.6005 54.747 41.4491 54.6431 41.7202 53.9971C41.9206 53.501 41.6848 53.0973 41.0366 52.8665C40.6712 52.7396 40.4119 52.5204 40.0229 52.0013C39.1507 50.8361 38.7146 50.5593 37.7953 50.5593C36.9702 50.5593 36.3573 50.94 35.603 51.9321C35.2258 52.4166 34.8368 52.8204 34.7308 52.8435C34.5304 52.8781 34.4597 52.8204 33.5639 51.7244C32.8213 50.8246 32.5149 50.64 31.7252 50.5823C31.1712 50.5477 30.9355 50.5939 30.5229 50.8131Z"
      fill="#1B75BB" />
  </svg>

</template>