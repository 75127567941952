<template>
  <div class="m-pagination d-flex justify-content-between align-items-center">
    <div class="page-count">
      Showing <strong>{{ pageMeta.from }}-{{ pageMeta.to }}</strong> of
      <strong>{{ pageMeta.total }}</strong>
    </div>

    <paginate
      v-model="page"
      :page-count="pageMeta.pageCount"
      :click-handler="changePage"
      :prev-text="`<i class='fas fa-chevron-left' />`"
      :next-text="`<i class='fas fa-chevron-right' />`"
      :container-class="'pagination'"
      prev-class="page-nav page-item"
      next-class="page-nav page-item"
    />

    <div class="goto-page d-flex justify-content-between align-items-center">
      <span class="mr-2">Go to: </span>

      <input
        type="number"
        v-model="gotoPageNumber"
        :max="pageMeta.pageCount"
        :min="1"
      />

      <div
        class="goto-button d-flex justify-content-center align-items-center"
        :class="{ disabled: gotoPageDisabled }"
        @click="handleGotoPage"
      >
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
  name: "MPaginate",

  components: { Paginate },

  data() {
    return {
      page: 1,
      gotoPageNumber: null,
    };
  },

  props: {
    pageMeta: {
      type: Object,
    },
  },

  computed: {
    gotoPageDisabled() {
      return (
        this.gotoPageNumber > this.pageMeta.pageCount || !this.gotoPageNumber
      );
    },
  },

  methods: {
    changePage(page) {
      this.gotoPageNumber = null;
      this.$emit("onPageChange", page);
    },

    handleGotoPage() {
      this.page = this.gotoPageNumber;
      this.gotoPageNumber = null;
    },
  },

  watch: {
    page(newValue) {
      this.$emit("onPageChange", newValue);
    },
  },
};
</script>

<style scoped>
.m-pagination {
  margin-top: 15px;
}

.pagination {
  margin: 0;
}

.page-count,
.goto-page {
  font-size: 15px;
}

.goto-page input {
  width: 50px;
  height: 50px;
  background: #fdfdfd;
  border: 1px solid #dbdbdb;
  border-radius: 3px 0px 0px 3px;
  padding: 5px;
  text-align: center;
  outline: none;
}

.goto-page input::-webkit-outer-spin-button,
.goto-page input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.goto-page input[type="number"] {
  -moz-appearance: textfield;
}

.goto-page .goto-button {
  display: block;
  background-color: #89ccf7;
  cursor: pointer;
  color: white;
  border-radius: 0px 3px 3px 0px;
  height: 50px;
  width: 50px;
}

.goto-page .goto-button.disabled {
  pointer-events: none;
  background-color: #ddd;
}

.goto-page .goto-button:hover {
  background-color: #1b75bb;
}

.goto-page i {
  font-size: 24px;
}

:deep(.pagination) li {
  margin: 0 5px;
}

:deep(.pagination) .page-item.active .page-link {
  background-color: #1b75bb;
  border-color: #1b75bb;
}

:deep(.pagination) .page-item .page-link {
  padding: 15px 20px;
  border-radius: 3px;
}

:deep(.pagination) .page-item.page-nav .page-link {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.pagination) .page-item.page-nav.disabled .page-link {
  background-color: #d9d9d9;
}
</style>
