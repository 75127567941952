<template>
  <div class="m-blocker" :class="{ 'd-none': !showBlocker }" />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MBlocker",

  computed: {
    ...mapState("global", ["showBlocker"]),
  },
};
</script>

<style scoped>
.m-blocker {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 90;
}
</style>