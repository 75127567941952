<template>
  <div class="cart d-lg-none cursor-pointer" v-show="isToggleCart" @click="showSummary">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between">
        <div class="left">
          <div class="d-flex align-items-center justify-content-start">
            <span class="fa-stack fa-1x mr-1 cursor-pointer cart-button">
              <i class="far fa-circle fa-stack-2x"></i>
              <i class="fas fa-angle-up fa-stack-1x"></i>
            </span>
            <p>Cart &bull;</p>

            <p class="ml-1">
              {{ totalItems }}

              Items
            </p>
          </div>
        </div>
        <div class="right">
          <div class="cart-value text-right">
            <p> ₱ {{ price }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    price: {
      type: String,
      default: '0.00',
    }
  },

  data() {
    return {
      isToggleCart: true,
    };
  },

  created() {
    window.eventBus.on("show-cart", this.showCartPanel);
  },
  beforeDestroy() {
    window.eventBus.off("show-cart", this.showCartPanel);
  },

  methods: {
    showSummary() {
      this.isToggleCart = !this.isToggleCart;
      this.$emit("show-summary", this.isToggleCart);
    },

    showCartPanel(isToggleCartValue) {
      this.isToggleCart = isToggleCartValue;
    },

  },

  computed: {
    totalPrice() {
      return this.items.reduce((total, item) => {
        let itemTotal = parseFloat(item.activePrice) * item.quantity;
        if (item.product.hasModifier === 1 && item.product.unforcedMods && item.product.unforcedMods.length) {
          const modsTotal = item.product.unforcedMods.reduce((modTotal, mod) => {
            return modTotal + (parseFloat(mod.mod_price) * mod.quantity);
          }, 0);
          itemTotal += modsTotal;
        }
        return total + itemTotal;
      }, 0).toFixed(2);
    },

    itemCount() {
      return this.items.reduce((total, item) => total + item.quantity, 0);
    },
  },
};
</script>
