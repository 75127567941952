<template>
    <button
        class="btn btn-block m-add-line-item-separator-button d-flex align-items-center justify-content-center"
        :class="{ disabled }"
        :disabled="disabled || isLoading"
        v-bind="{
            ...$attrs,
            ...colorAttribute,
        }"
        @click.stop="clickHandler"
        >
        <i v-if="isLoading" :class="`fa fa-${loadingIcon} fa-spin`" />
        <i v-else class="fa fa-plus" />
        <span class="ml-2">Add separator</span>
    </button>
</template>

<script>
    export default {
        name: 'MAddLineItemSeparatorButton',

        emits: ['click'],

        props: {
            label: {
                type: String,
                default: '',
            },
            icon: {
                type: String,
                default: '',
            },
            color: {
                type: String,
                default: 'white',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loadingIcon: {
                type: String,
                default: 'circle-notch',
            },
            postClickDelay: { // in seconds
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                isLoading: false,
            };
        },

        computed: {
            iconClass() {
                return `fa fa-${this.icon}`;
            },

            colorAttribute() {
                if (this.color.startsWith('#')) {
                    return { style: { backgroundColor: this.color } };
                } else {
                    return { class: `btn-${this.color}` };
                }
            },
        },

        methods: {
            clickHandler() {
                if (this.disabled || this.isLoading) return;

                if (this.postClickDelay > 0) {
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, this.postClickDelay * 1000);
                }

                this.$emit('click');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

<style scoped>
.m-add-line-item-separator-button {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 16px;
    color: var(--secondary);
    border: 3px dashed rgba(0, 0, 0, 0.1);
}

.m-add-line-item-separator-button.btn-danger {
    box-shadow: -1px 2px 2px rgba(167, 44, 44, 0.89) !important;
}

.m-add-line-item-separator-button:active {
    box-shadow: none !important;
    transform: scale(0.97);
}

.m-add-line-item-separator-button .icon-container i {
    font-size: 1.2rem;
}

.m-add-line-item-separator-button .label {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: bold;
}

.m-add-line-item-separator-button.disabled {
    pointer-events: none;
}
</style>