const SPA_PREFIX = '/spa/:locationId/:userId';

export default [{
    path: '/',
    component: require('@/spa/components/pages/BasePage.vue').default,
    children: [
        {
            name: 'tables',
            path: `${SPA_PREFIX}/tables/:serviceType?/:serviceAreaId?`,
            props: true,
            component: require('@/spa/components/pages/TablesPage.vue').default,
        },
        {
            name: 'table-details',
            path: `${SPA_PREFIX}/tables/:tableId(\\d+)/order/:serviceType?`,
            props: true,
            component: require('@/spa/components/pages/ProductsPage.vue').default,
        },
        {
            name: 'deliveries',
            path: `${SPA_PREFIX}/deliveries`,
            component: require('@/spa/components/pages/DeliveriesPage.vue').default,
        },
        {
            name: 'delivery',
            path: `${SPA_PREFIX}/delivery/:channelName`,
            props: true,
            component: require('@/spa/components/pages/DeliveryPage.vue').default,
        },
        {
            name: 'show-products',
            path: `${SPA_PREFIX}/products/:serviceType/:channelName?`,
            props: true,
            component: require('@/spa/components/pages/ProductsPage.vue').default,
        },
        {
            name: 'update-orders',
            path: `${SPA_PREFIX}/orders/:orderId?`,
            props: true,
            component: require('@/spa/components/pages/ProductsPage.vue').default,
        },
        {
            name: 'payments',
            path: `${SPA_PREFIX}/orders/:orderId(\\d+)/payments`,
            props: true,
            component: require('@/spa/components/pages/PaymentPage.vue').default,
        },
        {
            name: 'home',
            path: `${SPA_PREFIX}/`,
            component: require('@/spa/components/pages/Landing.vue').default,
        },
        {
            name: 'sync',
            path: `${SPA_PREFIX}/sync`,
            component: require('@/spa/components/pages/SyncPage.vue').default,
        },
        {
            name: 'pending',
            path: `${SPA_PREFIX}/pending`,
            component: require('@/spa/components/pages/PendingPage.vue').default,
        },
        {
            name: 'settings',
            path: `${SPA_PREFIX}/settings`,
            component: require('@/spa/components/pages/Settings.vue').default,
        },
        {
            name: 'day-end-settings',
            path: `${SPA_PREFIX}/day-end-settings`,
            component: require('@/spa/components/pages/DayEndSettings.vue').default,
        },
        {
            name: 'shift-change-settings',
            path: `${SPA_PREFIX}/shift-change-settings`,
            component: require('@/spa/components/pages/ShiftChangeSettings.vue').default,
        },
        {
            name: 'printer-settings',
            path: `${SPA_PREFIX}/printer-settings`,
            component: require('@/spa/components/pages/PrinterSettings.vue').default,
        },
        {
            name: 'bill-settings',
            path: `${SPA_PREFIX}/bill-settings`,
            component: require('@/spa/components/pages/BillSettings.vue').default,
        },
        {
            name: 'past-transactions',
            path: `${SPA_PREFIX}/past-transactions`,
            component: require('@/spa/components/pages/PastTransactions.vue').default,
        },
        {
            name: 'discounts-settings',
            path: `${SPA_PREFIX}/discounts-settings`,
            component: require('@/spa/components/pages/DiscountSettings.vue').default,
        },
        {
            name: 'imin-settings',
            path: `${SPA_PREFIX}/imin-settings`,
            component: require('@/spa/components/pages/IminSettings.vue').default,
        },
        {
            name: 'cashfloat-settings',
            path: `${SPA_PREFIX}/cashfloat-settings`,
            component: require('@/spa/components/pages/CashFloatSettings.vue').default,
        },
        {
            name: 'product-settings',
            path: `${SPA_PREFIX}/product-settings`,
            component: require('@/spa/components/pages/ProductSettings.vue').default,
        },
        {
            name: 'product-settings-offload',
            path: `${SPA_PREFIX}/product-settings-offload`,
            component: require('@/spa/components/pages/ProductSettingsOffload.vue').default,
        },
        {
            name: 'table-qr-settings',
            path: `${SPA_PREFIX}/table-qr-settings`,
            component: require('@/spa/components/pages/TableQrSettings.vue').default,
        },
        {
            name: 'ayala-sales-file',
            path: `${SPA_PREFIX}/ayala-sales-file`,
            component: require('@/spa/components/pages/AyalaSalesFileSettings.vue').default,
        },
        {
            name: 'app-info',
            path: `${SPA_PREFIX}/app-info`,
            component: require('@/spa/components/pages/AppInfo.vue').default,
        },
    ],
}];
