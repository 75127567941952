<template>
  <Popper :class="details.classes" arrow placement="top" v-if="syncStatus">
    <div class="d-flex align-items-center trigger cursor-pointer">
      <component
        :is="details.triggerIcon"
        :class="{ spin: syncStatus === syncStatuses.SYNCING && hasNetwork }"
      />

      <div class="text-uppercase ml-2">{{ details.triggerText }}</div>

      <font-awesome-icon
        v-if="syncStatus === syncStatuses.FULL && hasNetwork"
        icon="exclamation-triangle"
        class="icon-full"
      />
    </div>

    <template #content>
      <h3 class="pr-3">{{ details.title }}</h3>

      <div class="popper-content d-flex align-items-center">
        <template v-if="hasNetwork">
          <template v-if="syncStatus === syncStatuses.SYNCED">
            <CloudCheckIcon class="cloud-check-icon mr-2" />

            <div>Your data is <strong>synced</strong> to the cloud</div>
          </template>

          <template v-if="syncStatus === syncStatuses.UNSYNC">
            <UnsyncIcon class="unsync-icon mr-2" />

            <div>Your data is <strong>not sync</strong> to the cloud</div>
          </template>

          <template v-if="syncStatus === syncStatuses.FULL">
            <font-awesome-icon
              v-if="syncStatus === syncStatuses.FULL && hasNetwork"
              icon="exclamation-triangle"
              size="2xl"
              class="mr-3 warning"
            />

            <div>
              Your device can only store up to
              {{ maxUnsyncTransactions }} transactions. Please connect online to
              sync your data to the Back office.
            </div>
          </template>

          <template v-if="syncStatus === syncStatuses.SYNCING">
            <m-progress-bar :percent="syncProgressPercent" />
          </template>
        </template>

        <template v-else>
          <UnsyncIcon class="unsync-icon mr-2" />

          <div>You need to be connected online to sync your data</div>
        </template>
      </div>
    </template>
  </Popper>
</template>

<script>
/* globals tempMaxUnsyncTransactions */
import Popper from "vue3-popper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { mapState } from "vuex";
import {
  faSyncAlt,
  faCloud,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { SYNC_STATUSES } from "@/spa/constants";
import MProgressBar from "@/spa/components/common/MProgressBar";
import UnsyncIcon from "@/spa/components/icons/unsync";
import SyncIcon from "@/spa/components/icons/sync";
import CloudCheckIcon from "@/spa/components/icons/cloudCheck";

import SyncStatus from "@/spa/global-mixins/sync-status";

library.add(faSyncAlt, faCloud, faCheck, faExclamationTriangle);

export default {
  name: "MSyncStatus",

  mixins: [SyncStatus],

  components: { Popper, MProgressBar, UnsyncIcon, CloudCheckIcon, SyncIcon },

  props: {
    hasNetwork: {
      type: Boolean,
    },
  },

  data() {
    return {
      syncStatuses: SYNC_STATUSES,
      maxUnsyncTransactions: tempMaxUnsyncTransactions,
    };
  },

  computed: {
    ...mapState(["settlements"]),

    ...mapState("global", ["syncStatus", "syncProgressPercent"]),

    details() {
      if (!this.hasNetwork) {
        return {
          triggerText: "Sync",
          triggerIcon: "UnsyncIcon",
          title: "Unable to sync",
          classes: "unable",
        };
      }

      switch (this.syncStatus) {
        case SYNC_STATUSES.SYNCED:
          return {
            triggerText: "Sync",
            triggerIcon: "SyncIcon",
            title: "Sync to Cloud",
            classes: "synced",
          };

        case SYNC_STATUSES.UNSYNC:
          return {
            triggerText: "Sync",
            triggerIcon: "UnsyncIcon",
            title: "Sync to Cloud",
            classes: "unsync",
          };

        case SYNC_STATUSES.FULL:
          return {
            triggerText: "Sync",
            triggerIcon: "UnsyncIcon",
            title: "Maximum unsynced transactions reached",
            classes: "full",
          };

        case SYNC_STATUSES.SYNCING:
          return {
            triggerText: "SYNCING...",
            triggerIcon: "SyncIcon",
            title: "Sync to Cloud",
            classes: "syncing",
          };

        default:
          return {
            triggerText: "",
            triggerIcon: "",
            title: "",
            classes: "",
          };
      }
    },
  },
};
</script>

<style scoped>
.trigger {
  position: relative;
}

.synced .trigger {
  color: #51dc6b;
}

.synced .trigger svg {
  fill: #51dc6b;
}

.unsync .trigger {
  color: #fff;
}

.unsync .trigger svg {
  fill: #fff;
}

.full .trigger,
.unable .trigger {
  color: #667085;
}

.full .trigger svg,
.unable .trigger svg {
  fill: #667085;
}

.syncing .trigger {
  color: #1b75bb;
}

.syncing .trigger svg {
  fill: #1b75bb;
}

.icon-full {
  color: #febf21;
  position: absolute;
  top: -5px;
  right: -10px;
}

.warning {
  color: #febf21;
}

.progress {
  width: 280px;
}

.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

:deep(.popper-content) .unsync-icon {
  fill: #667085;
}
</style>
