import { VAT_EXCL, ZERO_RATED } from '@/vue/helper/discount';
import { mapValues, omit, isNil, orderBy, partition } from 'lodash';
import cloneDeep from 'rfdc/default';
import { HAS_VAT_DISCOUNT, VAT_BEFORE_DISCOUNT } from '@/vue/helper/discount';
import { OFFLINE_FORCE_EMPLOYEE_NO_SC, ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE } from '@/spa/constants';

const EMPLOYEE_DISCOUNT_REGEX = /.*employee.*/i;

export const calculateDiscountAmount = (discountData, totals, pax, override = true) => {
    let amount = 0;
    const { discount, discountQuantity } = discountData;

    if (!isNil(discount.discount_amount)) {
        amount = parseFloat(discount.discount_amount);
    } else {
        if (discount.is_vat_based != 1) {
            amount = totals.rawPrice * parseFloat(discount.discount_rate);
        } else {
            amount = totals.net * parseFloat(discount.discount_rate);
        }

        amount *= discountQuantity / pax;
    }

    if (discount.meal_combination_amount && amount > discount.meal_combination_amount) {
        amount = discount.meal_combination_amount;
    } else if ((!ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE) && discount.max_discount_amount && amount > discount.max_discount_amount && override) {
        amount = discount.max_discount_amount;
    }

    return amount;
};

export const discountAmountToPercentage = (discountData, totals, pax, discountAmount) => {
    const { discount, discountQuantity } = discountData;

    if (discount.is_vat_based != 1) {
        return (discountAmount / (totals.rawPrice * discountQuantity / pax));
    }

    return (discountAmount / (totals.net * discountQuantity / pax));
};

export const applyDiscount = (discountData, oldTotals, amount, pax, vatConfig=null) => {

    const totals = cloneDeep(oldTotals);
    const oldNet = totals.net;
    const paxDiscountRatio = (pax - discountData.discountQuantity) / pax;

    if (discountData.discount.is_vat_based != 1) {
        totals.net = (totals.net / (totals.beforeSc || 1)) * (totals.beforeSc - amount);
    } else {
        totals.net -= amount;
    }
    const adjustmentRatio = totals.net / (oldNet || 1);

    const hasEmployeeDiscountWithNoSc = EMPLOYEE_DISCOUNT_REGEX.test(discountData.discount.discount_name)
        && OFFLINE_FORCE_EMPLOYEE_NO_SC;

    const hasNoScDiscount = hasEmployeeDiscountWithNoSc
        || discountData.discount?.has_service_charge == 0;

    

    const oldVat = totals.vat;
    totals.originalVat = oldVat;

    if (!VAT_BEFORE_DISCOUNT.includes(discountData.discount.discount_name)) {
        totals.vat *= VAT_EXCL.includes(discountData.discount.discount_name)
            ? paxDiscountRatio
            : adjustmentRatio;
    } else {
        totals.vat = oldVat;
    }

    // if medal of valor and regular vat, add discount vat
    if (HAS_VAT_DISCOUNT.includes(discountData.discount.discount_name) && oldVat > 0) {
        totals.vat += discountData.amount * 0.12;
    }

    if (hasNoScDiscount && discountData.discountQuantity == pax) {
        totals.serviceCharge = 0;
    } else if (discountData.discount.discount_name !== "COMP") {
        if (hasNoScDiscount) {
            totals.serviceCharge *= paxDiscountRatio;
        } else if (oldTotals.scIsAfterDiscount == 1) {
            const newVat = oldTotals.scIsNetBased ? 0 : totals.vat;
            const oldVat = oldTotals.scIsNetBased ? 0 : oldTotals.vat;
            totals.serviceCharge = (totals.net + newVat) * (oldTotals.serviceCharge / ((oldTotals.net + oldVat) || 1));
        }
    }

    const discountPaxRatio = discountData.discountQuantity / pax;

    if (ZERO_RATED.includes(discountData.discount.discount_name)) {
        totals.zeroRatedSales = (oldNet * discountPaxRatio) - amount;
    } else if (!oldVat) {
        totals.vatExemptSales = totals.net;
    } else if (VAT_EXCL.includes(discountData.discount.discount_name)) {
        if (discountData.discount.is_vat_based) {
            totals.vatExemptSales = (oldNet * discountPaxRatio) - amount;
        } else {
            totals.vatExemptSales = vatConfig
                ? (totals.beforeSc / pax - amount) / vatConfig.tax_rate
                : totals.net * discountPaxRatio;
        }

        // override for caramia requirement
        if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE) {
            if (discountData.discount?.max_discount_amount > 0 && (totals.rawPrice) >= discountData.discount?.max_discount_amount) {
                // TODO: need to support SC inclusive locations
                const discountNameIsVatExclusive = VAT_EXCL.includes(discountData.discount.discount_name);
                const discountRate = parseFloat(discountData.discount.original_discount_rate?? discountData.discount.discount_rate);
                const discountedNet = (discountData.discount.max_discount_amount / (vatConfig?.tax_rate?? 1.12)) * discountPaxRatio;
                const undiscountedNet = (totals.rawPrice / (vatConfig?.tax_rate?? 1.12)) - discountedNet;
                const discountAmount = (discountedNet * discountRate);
                const vatExempt = discountNameIsVatExclusive ? (discountedNet - discountAmount) : 0;
                let vatableSales = !discountNameIsVatExclusive ? (discountedNet - discountAmount) : 0;

                vatableSales += undiscountedNet;
                totals.net = undiscountedNet + vatExempt;
                totals.vat = undiscountedNet * ((vatConfig?.tax_rate?? 1.12) - 1);
                totals.vatExemptSales = discountNameIsVatExclusive ? discountedNet : 0;
            }
        }
    }

    totals.total = totals.net + totals.vat + totals.serviceCharge;

    return totals;
}


export class TotalsComputation {
    constructor(item=null, order=null) {
        this.item = item;
        this.order = order;
        this.serviceType = order != null ? order.serviceType : null;
    }

    setOrder(order) {
        this.order = order;
        this.serviceType = order.serviceType;
    }

    setItem(item) {
        this.item = item;
    }

    net() {
        let net = this.itemRawPrice() / (this.totalPercentage() || 1);

        if (this.item.discount) {
            net -= Number(this.item.discount.discountRate) * net;
        }

        return net;
    }

    noDiscountNet() {
        return this.itemRawPrice() / (this.totalPercentage() || 1);
    }

    actualDiscountAmount() {
        if (!this.item.discount) return 0;

        const discountIsVatBased = this.item.discount.is_vat_based;
        const basePrice = discountIsVatBased ? this.noDiscountNet() : this.itemRawPrice();

        return Number(this.item.discount.discountRate) * basePrice * this.item.discount.discountPax;
    }

    vatExemptSales() {
        if (ZERO_RATED.includes(this.item.discount?.discount_name)) {
            return 0;
        } else if (VAT_EXCL.includes(this.item.discount?.discount_name)) {
            return this.net() * this.discountQuantityRatio();
        } else if(Number(this.vatConfig().tax_rate) == 1) {
            return this.net();
        } else {
            return 0;
        }
    }

    zeroRatedSales() {
        if (!this.item.discount) return 0;

        if (ZERO_RATED.includes(this.item.discount?.discount_name)) {
            return this.net() * this.discountQuantityRatio();
        } else {
            return 0;
        }
    }

    discountQuantityRatio() {
        if (!this.item.discount) return 0;
        return this.item.discount.discountPax / this.item.quantity;
    }

    scIsAfterDiscount() {
        return this.scConfig().is_after_discount ?? 0;
    }

    scIsNetBased() {
        return this.scConfig().is_net_based ?? 0;
    }

    isScInclusive() {
        return this.scConfig().tax_type === "Tax Inclusive" ? 1 : 0;
    }

    scConfig() {
        return Object.values(this.item.product.tax_data).find(
            td => td.tax_name === 'Service Charge'
                && td.service_type === this.serviceType
        ) || {
            tax_rate: '0.00',
        };
    }

    discountQty() {
        return this.item.discount?.discountPax || 0;
    }

    hasEmployeeDiscount() {
        if (!this.item.discount) return false;
        return EMPLOYEE_DISCOUNT_REGEX.test(this.item.discount.discount_name);
    }

    hasNoScDiscount() {
        return this.item.discount?.has_service_charge == 0
            || (this.hasEmployeeDiscount() && OFFLINE_FORCE_EMPLOYEE_NO_SC);
    }

    noScDiscountQty() {
        return this.hasNoScDiscount() ? this.discountQty() : 0;
    }

    serviceCharge() {
        if (this.noScDiscountQty() == this.item.quantity) return 0;

        let total;
        if (this.scIsNetBased() || this.isScInclusive()) {
            total = this.scIsAfterDiscount() ? this.net() : this.noDiscountNet();
        } else {
            const discountPerPax = this.discountQty() ? this.actualDiscountAmount() / this.discountQty() : 0;
            total = this.scIsAfterDiscount() ? this.itemRawPrice() - discountPerPax : this.itemRawPrice();
        }

        let scRate = this.scConfig()?.tax_rate || 0;
        if (this.hasNoScDiscount()) {
            scRate *= (this.item.quantity - this.noScDiscountQty()) / this.item.quantity;
        }

        return total * scRate;
    }

    vatConfig() {
        return Object.values(this.item.product.tax_data).find(
            td => td && td.tax_name === 'VAT'
        );
    }

    totalPercentage() {
        let percentage = 0;
        if (this.vatConfig()) {
            percentage += Number(this.vatConfig().tax_rate);
        }

        if (this.scConfig() && this.scConfig().tax_type === 'Tax Inclusive') {
            percentage += Number(this.scConfig().tax_rate);
        }

        return percentage;
    }

    vat() {
        if (!this.vatConfig()) {
            return 0;
        }

        let net = this.itemRawPrice() / (this.totalPercentage() || 1);

        return net * (Number(this.vatConfig().tax_rate) - 1);
    }

    itemRawPrice() {
        if (!this.item) return 0;
        if (this.item.isVoided) return 0;

        const activeServiceTypeId = this.order.serviceTypeId;
        const activeChannelId = this.order.channelTypeId;

        let basePrice = 0;

        if(this.item.product.product_tag == "Open") {

            basePrice = this.item.activePrice;

        } else {

            let pricings = this.item.product.pricings.filter(p => p.service_type_id == activeServiceTypeId);
            if (activeChannelId) {
                pricings = pricings.filter(p => p.service_channel_id == activeChannelId);
            }

            basePrice = pricings.length ? Number(pricings[0].prod_price) : 0;
            if (this.item.product.forcedMods) {
                this.item.product.forcedMods.forEach(mod => {
                    basePrice += Number(mod.mod_price) * Number(mod.quantity);
                });
            }

            if (this.item.product.unforcedMods) {
                this.item.product.unforcedMods.forEach(mod => {
                    basePrice += (Number(mod.mod_price) * Number(mod.quantity));
                });
            }

        }


        return basePrice;
    }

    formattedAmounts() {
        return mapValues(omit(this.parsedAmounts(), ['kotNum']), p => p.toFixed(2));
    }

    parsedAmounts() {
        const beforeSc = this.item.discount
            ? this.net() * this.item.quantity
            : this.itemRawPrice() * this.item.quantity;

        let vatExc = ['Senior Citizen Discount', 'Diplomat Discount', 'PWD'];

        let vat = this.vat() * this.item.quantity;
        let net = this.net() * this.item.quantity;
        let serviceCharge = this.serviceCharge() * this.item.quantity;

        let oldVat = vat;

        //check if item has discount. do recomputations
        if(this.item.discount) {
            //net and sc computation
            if(this.item.quantity != this.item.discount.discountPax) {
                //create net for the not discounted qty
                let undiscountedNet = (this.itemRawPrice() / (this.totalPercentage() || 1)) * Math.abs(this.item.quantity - this.item.discount.discountPax);

                //check open discount by amount
                if(this.item.discount.isDiscountByAmount) {
                    net = ((this.itemRawPrice() * this.item.discount.discountPax) - this.item.discount.discountAmount) / (this.totalPercentage() || 1) + undiscountedNet;
                } else {
                    net = (this.net * this.item.discount.discountPax) + undiscountedNet;
                }

                serviceCharge = (this.serviceCharge() * this.item.discount.discountPax) + (undiscountedNet * Number(this.scConfig().tax_rate));
            }

            //vat computation
            vat = vatExc.includes(this.item.discount.discount_name) ? this.vat() * (this.item.quantity - this.item.discount.discountPax) : net * (Number(this.vatConfig().tax_rate) - 1);
        }

        return {
            kotNum: this.item.kot || '',
            vat: vat,
            serviceCharge: serviceCharge,
            net: net,
            total: serviceCharge + vat + net,
            beforeSc,
            rawPrice: this.itemRawPrice() * this.item.quantity,
            vatExemptSales: this.vatExemptSales(),
            zeroRatedSales: this.zeroRatedSales(),
            originalVat: oldVat,
            scIsAfterDiscount: this.scIsAfterDiscount(),
            isScInclusive: this.isScInclusive(),
        };
    }

    recomputeTotals(order=null) {
        let ret = {};
        ret["item_totals"] = [];

        if(order != null) {
            //set order
            this.setOrder(order);
        }

        this.order.orders.forEach((item, index) => {
            //set the item for each iteration
            this.setItem(item);
            ret["item_totals"][index] = this.parsedAmounts();
        });

        ret.grandTotals = {
            vat: 0,
            serviceCharge: 0,
            net: 0,
            total: 0,
            beforeSc: 0,
            rawPrice: 0,
            vatExemptSales: 0,
            zeroRatedSales: 0,
            isScInclusive: this.isScInclusive(),
            scIsAfterDiscount: this.scIsAfterDiscount(),
        };

        ret.item_totals.forEach(parsedAmount => {
            ret.grandTotals.vat += parsedAmount.vat;
            ret.grandTotals.serviceCharge += parsedAmount.serviceCharge;
            ret.grandTotals.net += parsedAmount.net;
            ret.grandTotals.total += parsedAmount.total;
            ret.grandTotals.beforeSc += parsedAmount.beforeSc;
            ret.grandTotals.rawPrice += parsedAmount.rawPrice;

            if (parsedAmount.vatExemptSales > 0) {
                ret.grandTotals.vatExemptSales = (ret.grandTotals.vatExemptSales ?? 0) + parsedAmount.vatExemptSales;
            }

            if (parsedAmount.zeroRatedSales > 0) {
                ret.grandTotals.zeroRatedSales = (ret.grandTotals.zeroRatedSales ?? 0) + parsedAmount.zeroRatedSales;
            }
        });

        return ret;
    }
}

export const totalsComputationInstance = new TotalsComputation();

export function redistributeTenders(payments, totalPayable) {
    const [cashPayments, nonCashPayments] = partition(payments, (p) => p.method.toLowerCase() == 'cash');
    const sortedPayments = [
        ...orderBy(nonCashPayments, 'amount'),
        ...orderBy(cashPayments, 'amount'),
    ];

    for (let i = 0; i < sortedPayments.length; i++) {
        sortedPayments[i].exact_amount = Math.min(sortedPayments[i].amount, totalPayable);
        sortedPayments[i].change = Math.max(0, sortedPayments[i].amount - sortedPayments[i].exact_amount);
        sortedPayments[i].exact_amount = sortedPayments[i].exact_amount.toFixed(2);
        totalPayable = Math.max(0, totalPayable - sortedPayments[i].amount);
    }

    return sortedPayments;
}

export default {
    calculateDiscountAmount,
    applyDiscount,
    TotalsComputation,
    totalsComputationInstance,
    redistributeTenders,
}
