
require('./bootstrap');

require('alpinejs');

const Cookies = require('js-cookie');

window.Cookies = Cookies;

const { EventEmitter } = require('events');
window.eventBus = new EventEmitter();

window.serializeJs = require('serialize-javascript');
const { v4: uuidv4 } = require('uuid');
window.uuidv4 = uuidv4;

if (window.location.href.includes('/spa')) {
    require('./spa.js');
}

// This method will clear apps cached
window.clearAppsCached = () => {
    sessionStorage.clear()
    localStorage.clear()
    indexedDB.databases().then(dbs => {
        dbs.forEach(db => indexedDB.deleteDatabase(db.name))
    });
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        Cookies.remove(name);
    }
}
import { createApp } from 'vue';

window.createApp = createApp

// BUILD_TIMESTAMP is defined by our Webpack config in webpack.mix.js
window.WEBAPP_BUILD_TIMESTAMP = BUILD_TIMESTAMP;

