<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div>
          <label>Past Transactions</label>

          <button class="btn btn-primary btn-lg mr-2" :disabled="isFetching"
            @click="triggerManualFetchingOfOrders(false)">
            <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
          </button>
        </div>
      </div>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">Bills</label>

          <input
            v-model="fromDate"
            type="date"
            class="form-control"
            name="from_date"
            id="from_date"
            :min="posDateMinusTen"
            :max="toDate"
            onkeydown="return false"
            :disabled="isFetching"
            >

          <input
            v-model="toDate"
            type="date"
            class="form-control"
            name="from_date"
            id="from_date"
            :min="fromDate"
            :max="posDate"
            onkeydown="return false"
            :disabled="isFetching"
            >

          <input v-model="billNumQuery" class="form-control col-4" type="text" placeholder="Search bills" />
        </div>

        <div class="bills-section mb-4">
          <v-data-table :headers="billHeaders" :items="billItems" :rows-per-page="10" :loading="isFetching"
            sort-by="bill_num" sort-type="desc">
            <template #item-bill_num="item">
              <i v-if="queueStatus[item.bill_num] == 1 || item.isOnlineDelivery" class="fas fa-cloud text-success"></i>
              <strong class="ml-1">
                {{ item.bill_num ? `BN-${item.bill_num}` : '-' }}
              </strong>
            </template>

            <template #item-receipt_num="item">
              {{ item.receipt_num }}
            </template>

            <template #item-reprint_number="item">
              {{ item.reprint_count }}
            </template>

            <template #item-totals.vat="item">
              {{ $filters.formatPrice(item.totals.vat) }}
            </template>

            <template #item-totals.net="item">
              {{ $filters.formatPrice(item.totals.net) }}
            </template>

            <template #item-totals.total="item">
              {{ $filters.formatPrice(item.totals.total) }}
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button class="btn btn-sm btn-block btn-primary" @click="openBillDetailsModal(item)">
                    VIEW
                  </button>
                </div>

                <div class="col">
                  <button @click="openFullVoidModal(item, 'BILL')"
                    :disabled="item.isVoided || item.isOnlineDelivery || voidDisabled(item)"
                    class="btn btn-sm btn-block btn-danger" :class="{ disabled: voidDisabled(item) }">
                    VOID
                  </button>
                </div>

                <div class="col">
                  <button
                  class="btn btn-sm btn-block btn-primary"
                  :disabled="item.isVoided"
                  :class="{ disabled: item.isVoided }"
                  @click="showPrintOptions(item)"
                  >
                    PRINT
                  </button>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <bill-details-modal v-if="selectedOrder" v-model="isBillDetailsModalOpen" :order="selectedOrder"
      @reprintKOT="printKOT(null, $event, false)" @printKOTVoid="printKOT(null, $event, true)" />

    <void-slip-modal v-model="isVoidSlipModalOpen" :void-slip-html="voidSlipString" />

    <full-void-modal v-model="isFullVoidModalOpen" :item="selectedItem" :voidTrigger="fullVoidTrigger"
      @applyFullVoid="applyFullVoid" @openVoidConfirmModal="openVoidConfirmModal" />
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import VDataTable from 'vue3-easy-data-table';

import BillDetailsModal from '@/spa/components/modals/BillDetailsModal';
import VoidSlipModal from '@/spa/components/modals/VoidSlipModal';
import FullVoidModal from '@/spa/components/modals/FullVoidModal';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { print, getPrinterData } from "@/spa/services/printer-service";
import { getTaxes } from '@/spa/services/product-service';
import { getReceiptDetails } from "@/spa/services/cashier-service";
import { getReceiptPrintString, getVoidPrintString, generateReceiptItemString } from "@/spa/utils/print";
import kotHtml from '@/spa/components/templates/print/kot.html';
import { mapValues, cloneDeep, isEmpty, flatten, map, uniq, get, debounce, uniqBy } from "lodash";
import { convert } from 'html-to-text';

import { getSyncedOrders, getSyncedSettlements, updateSyncedOrder, getDateRangeOrders } from '@/spa/services/sync-service';

import moment from 'moment';

import {
  STATUSES,
  LINE_ITEM_SEPARATOR_CHAR_COUNT,
  CAN_SEE_PAST_TRANSACTIONS,
  OFFLOAD
} from '@/spa/constants';

import SyncStatus from "@/spa/global-mixins/sync-status";
import { getLastSeries } from "@/spa/utils/local-storage";



export default {
  name: 'PastTransactions',

  components: {
    SettingsSidebar,
    VDataTable,
    BillDetailsModal,
    VoidSlipModal,
    FullVoidModal,
  },

  mixins: [SyncStatus],

  data() {
    return {
      billHeaders: [
        { text: 'Date', value: 'businessDate', sortable: true },
        { text: 'Bill #', value: 'bill_num', sortable: true },
        { text: 'SI #', value: 'receipt_num', sortable: true },
        { text: 'SI Reprint #', value: 'reprint_count', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'VAT', value: 'totals.vat', sortable: true },
        { text: 'Net Sales', value: 'totals.net', sortable: true },
        { text: 'Total Amount', value: 'totals.total', sortable: true },
        { text: 'Actions', value: 'actions' },
      ],

      billNumQuery: '',

      isBillDetailsModalOpen: false,
      isVoidSlipModalOpen: false,
      isFullVoidModalOpen: false,
      isVoidConfirmModalOpen: false,
      selectedVoidItem: null,
      selectedOrder: null,
      selectedItem: null,
      fullVoidTrigger: null,

      orders: [],
      settlements: [],
      tax_data: [],
      voidSlipString: '',

      isFetching: false,

      fromDate: '',
      toDate: '',

      dateRangeOrders: [],
      filteredBillOrders: [],
      debouncedGenerateFilteredBillOrders: () => null,
    };
  },

  computed: {
    ...mapState(['dataContainer', 'lastVoidBillNum', 'lastVoidReceiptNum', 'queueStatus']),
    ...mapState({ originalOrders: state => state.orders }),
    ...mapState('settings', ['posDate', 'serviceTypes']),
    ...mapGetters(['pendingOrders', 'activePendingSettlements']),
    ...mapState('sqlite', ['initialData']),

    billItems() {
      return flatten(this.filteredBillOrders.map(ord => {
        const ords = isEmpty(ord.splits) ? [ord] : ord.splits;
        return map(ords, o => {
          const ret = {
            ...ord,
            ...o,
            reprint_count: o.reprint_count ?? 0,
            orders: o.orders,
            businessDate: moment(o.originShiftTable?.pos_date).format('YYYY-MM-DD'),
            status: this.getStatusString(o),
            isSync: ord.isSync
          }

          //check if order has splits. this is to avoid splits being undefined when voiding splits
          if (!isEmpty(ord.splits)) {
            ret['splits'] = ord.splits;
          }

          return ret;
        });
      }));
    },

    printerData() {
      return this.getPrinterInfo();
    },

    posDateMinusTen() {
      return moment(this.posDate).subtract(10, 'days').format('YYYY-MM-DD');
    },
  },

  async mounted() {
    if (!CAN_SEE_PAST_TRANSACTIONS) {
      this.$router.push({ name: 'home' });
      return;
    }

    this.debouncedGenerateFilteredBillOrders = debounce(this.generateFilteredBillOrders.bind(this), 500);
    this.refreshSyncedData();
    await this.fetchTaxes();

    this.fromDate = this.posDateMinusTen;
    this.toDate = moment(this.posDate).format('YYYY-MM-DD');

    this.triggerManualFetchingOfOrders(true);
    this.generateFilteredBillOrders();
  },

  watch: {
    originalOrders: {
      handler() {
        this.refreshSyncedData();
      },
      deep: true,
    },

    billNumQuery(value) {
      if (!value) {
        this.generateFilteredBillOrders();
        return;
      }

      this.debouncedGenerateFilteredBillOrders();
    },

    fromDate() {
      this.triggerManualFetchingOfOrders(true);
    },

    toDate() {
      this.triggerManualFetchingOfOrders(true);
    },
  },

  methods: {
    ...mapMutations(['addOrder', 'updateOrder', 'incrementLastVoidBillNum', 'incrementLastVoidReceiptNum', 'setPendingSettlements']),
    ...mapActions(['regenerateOrderTotals', 'recomputeTotals', 'getLatestServerOrders', 'forceSyncOrders']),
    ...mapMutations('global', ['setIsForceSyncing']),

    kotGroupOrdersByServiceType(orderDetail, transactionItem, ignoreKot = false) {
      return orderDetail.orders.reduce((acc, order) => {
        const serviceType = this.serviceTypes.find(i => i.id === order.serviceTypeId).service_name || 'Unknown Service Type'

        if (order && (order.kot == transactionItem.kot || ignoreKot)) {
          if (!acc[serviceType]) {
            acc[serviceType] = [];
          }

          acc[serviceType].push(order);
        }

        return acc;

      }, {});
    },

    async refreshSyncedData() {
      this.orders = await getSyncedOrders();
      this.settlements = await getSyncedSettlements();
      this.debouncedGenerateFilteredBillOrders();
    },

    generateFilteredBillOrders() {
      let billedOrders = this.dateRangeOrders;

      billedOrders = billedOrders.map(o => {
        const key = this.originalOrders.findIndex(i => i._id === o._id);
        if (key >= 0) {
          o = { ...o, ...this.originalOrders[key] };
        }
        return o;
      });

      if (!this.billNumQuery) {
        this.filteredBillOrders = billedOrders;
      }

      const lowerQuery = this.billNumQuery.toLowerCase();
      this.filteredBillOrders = billedOrders.filter(o =>
        `BN-${o.bill_num}`.toLowerCase().includes(lowerQuery)
        || o.splits?.some(s => s.bill_num.toString().toLowerCase().includes(lowerQuery))
        || o.receipt_num?.toString().toLowerCase().includes(lowerQuery)
        || o.splits?.some(s => s.receipt_num.toString().toLowerCase().includes(lowerQuery))
        || this.getStatusString(o).toLowerCase().includes(lowerQuery)
      );
    },

    async triggerManualFetchingOfOrders(silent = false) {
      if (this.isFetching) return;
        this.isFetching = true;
      try {
        const onlineOrders = await getDateRangeOrders(this.fromDate, this.toDate);
        this.dateRangeOrders = onlineOrders.data.orders;

        this.generateFilteredBillOrders();
        this.refreshSyncedData();
        if (!silent) this.$swal.success('Success', 'Multiterminal orders fetched successfully');
      } catch (e) {
        if (!silent) this.$swal.error('Failed to fetch orders', 'Please check your connection and try again');
      }

      setTimeout(() => this.isFetching = false, 1000);
    },

    formatTotals(totals) {
      return mapValues(totals, v => v !== null ? this.$filters.formatPrice(v) : v);
    },

    async getPrinterInfo() {
      return await getPrinterData();
    },

    async parseReceiptDetails(order) {
      try {
        let receiptResponse = await getReceiptDetails();
        let details = Object.values(receiptResponse.data).find(d => d.brand_id == order.brandId);
        return details;
      } catch (error) {
        console.log(error);
      }

      return {};
    },

    getStatusString(order) {
      if (order.isVoided) return STATUSES.VOID;
      if (order.isSettled) return STATUSES.PAID;
      if (order.isBilled) return STATUSES.BILLED;
      return STATUSES.PENDING;
    },

    openBillDetailsModal(order) {
      this.selectedOrder = order;
      this.isBillDetailsModalOpen = true;
    },

    async generateReceiptArgs(order) {
      let discountContainer = [];

      let paymentsArr = order.payments ?? [];

      if (order.splits && order.splits.length) {
        paymentsArr = [];
        order.splits.filter(s => s.bill_num == order.bill_num).forEach(s => {
          paymentsArr.push(...s.payments);
          if (s.billDiscount) {
            s.billDiscount.customerDetails.forEach(detail => {
              discountContainer.push({
                discountAmount: s.billDiscount.amount / s.billDiscount.discountQuantity,
                discountPax: s.billDiscount.discountQuantity,
                ...detail,
                ...s.billDiscount.discount,
              });
            });
          } else {
            s.discounts?.forEach(d => {
              discountContainer.push({ discountAmount: d.amount, discountPax: d.discountQuantity, ...d.customerDetails[0], ...d.discount });
            });
            //check if discount array is empty
            if (s.discounts.length == 0) {
              //get line item discount
              discountContainer.push(...this.getLineItemDiscounts(s));
            }
          }
        });
      } else if (order.billDiscount) {
        order.billDiscount.customerDetails.forEach(detail => {
          discountContainer.push({
            discountAmount: order.billDiscount.amount / order.billDiscount.discountQuantity,
            discountPax: order.billDiscount.discountQuantity,
            ...detail,
            ...order.billDiscount.discount,
          });
        });
      } else {
        //get line item discount
        discountContainer.push(...this.getLineItemDiscounts(order));
      }

      let receiptArgs = {
        formattedTotals: this.formatTotals(order.totals),
        tableName: order.tableName,
        receiptDetails: await this.parseReceiptDetails(order),
        customerDetails: order.customerDetails,
        discountContainer: discountContainer,
        payments: paymentsArr,
        dataContainer: this.dataContainer,
        activeBrandId: order.brandId,
        serviceType: order.serviceType,
        bill_num: order.bill_num,
        receipt_num: order.receipt_num,
        mode: 'bill',
        reprint_string: 'REPRINT <br>',
      };

      return receiptArgs;
    },

    getLineItemDiscounts(order) {
      return order.orders.reduce((ret, o) => {
        if (o.discount) {
          ret.push(o.discount);
        }

        return ret;
      }, []);
    },

    showPrintOptions(order) {
      const swalTitle = order.isVoided ?
        `Reprint Void ${order.isSettled ? 'Slip' : 'Bill'}`
        : 'Choose the type of receipt';

      Swal.fire({
        title: swalTitle,
        showDenyButton: order.isSettled,
        showCancelButton: true,
        showConfirmButton: !order.isVoided ? true : !order.isSettled,
        confirmButtonText: order.isVoided ? 'Print' : 'Bill',
        denyButtonText: order.isVoided ? 'Print' : 'SI',
        confirmButtonColor: '#1b75bb',
        denyButtonColor: '#1b75bb',
        cancelButtonColor: '#f47070',
        customClass: {
          actions: 'print-swal-action',
        }
      }).then(async (result) => {
        if (result.isDismissed) {
          Swal.close();
          return;
        }
        let receiptArgs = await this.generateReceiptArgs(order);
        if (order.isVoided) {
          return this.reprintVoid(order, receiptArgs);
        }
        receiptArgs.mode = (result.isConfirmed) ? "bill" : "settlement";
        if (receiptArgs.mode === "settlement") {
          const orderSplits = order.splits ?? [];
          const isUpdateReprintCount = Boolean(orderSplits.length == 0);
          const isUpdateSplitOrders = Boolean(orderSplits.length > 0);

          const newSplits = orderSplits.filter(s => s._id == order._id).map(o => {
            let ret = {
              ...o,
            };
            if (isUpdateSplitOrders && o.bill_num == order.bill_num) {
              ret['reprint_count'] = o.reprint_count ? o.reprint_count + 1 : 1;
            }
            return ret;
          });

          this.updateOrder({
            orderId: order._id,
            order: cloneDeep({
              reprint_count: isUpdateReprintCount && order.reprint_count ? order.reprint_count + 1 : 1,
              splits: newSplits,
            }),
          })
          await updateSyncedOrder(
            order._id,
            cloneDeep({
              orderId: order._id,
              orders: order.orders,
              reprint_count: isUpdateReprintCount && order.reprint_count ? order.reprint_count + 1 : 1,
              splits: newSplits,
            }),
            false,
            order.isSync
          );
          if (order.isSync) {
            this.refreshSyncedData();
          }
        }
        let receiptString = await getReceiptPrintString(order, receiptArgs);
        let receiptItemString = await generateReceiptItemString(order);
        let receiptDetails = await this.parseReceiptDetails(order);
        let printData = {
          print_type: 'Receipt',
          html: receiptString,
          mode: receiptArgs.mode,
        };
        let printConfig = {
          location_id: receiptDetails.location_id,
        };

        print(printData, printConfig, receiptItemString);
      });
    },

    async fetchTaxes() {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.tax_data = this.initialData.taxes;

        return;
      }

      try {
        const response = await getTaxes();
        this.tax_data = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async reprintVoid(order, receiptArgs) {
      receiptArgs.isReprint = true;
      if (order.isSettled) {
        receiptArgs.mode = "void_paid";
        receiptArgs.void_refnum = order.void_receipt_num;
      } else {
        receiptArgs.mode = "void_bill";
        receiptArgs.void_refnum = order.void_bill_num;
      }
      //print
      let voidSlipHTML = await getVoidPrintString(order, receiptArgs);

      //replace text for modal display
      let html = voidSlipHTML;
      html = html.replace(/__BLANK__/ig, ' ');
      html = html.replace(/__MOD__/ig, ' ');
      this.voidSlipString = html;

      let receiptDetails = await this.parseReceiptDetails(order);
      let printData = {
        print_type: 'Void',
        html: voidSlipHTML,
        mode: receiptArgs.mode,
      };
      let printConfig = {
        location_id: receiptDetails.location_id,
      };

      print(printData, printConfig);

      this.isVoidSlipModalOpen = true;

      setTimeout(() => this.triggerManualFetchingOfOrders(true), 3000);
    },

    async voidBill(order, voidReason) {
      const result = await this.$openApproverModal();
      if (!result.success) return;

      //generate void num
      let void_bill_num = null;
      let void_receipt_num = null;

      let receiptArgs = await this.generateReceiptArgs(order);

      switch (order.status) {
        case "Billed":
          void_bill_num = Math.max(await getLastSeries("void_bill_num"), this.lastVoidBillNum + 1);
          receiptArgs.mode = "void_bill";
          receiptArgs.void_refnum = void_bill_num;
          break;
        case "Paid":
          void_receipt_num = Math.max(await getLastSeries("void_receipt_num"), this.lastVoidReceiptNum + 1);
          receiptArgs.mode = "void_paid";
          receiptArgs.void_refnum = void_receipt_num;
          break;
        default:
          //do nothing
          break;
      }

      const orderSplits = order.splits ?? [];
      const isVoidWholeOrder = Boolean(orderSplits.length == 0 || orderSplits.filter(s => s.isVoided == true).length == orderSplits.length - 1);
      const isUpdateSplitOrders = Boolean(orderSplits.length > 0);

      const ordersClone = order.orders.map(o => ({
        ...o,
        isVoidedBeforeBill: o.isVoided && o.isCancelled,
        isLineItemVoided: o.isVoided && !o.isCancelled,
        isCancelled: false,
        isVoided: true,
      }));

      const newSplits = orderSplits.filter(s => s._id == order._id)
        .map(o => {
          let ret = {
            ...o,
            isVoided: Boolean(o.isVoided || o.bill_num == order.bill_num),
          };

          if (isUpdateSplitOrders && o.bill_num == order.bill_num) {
            ret['orders'] = ordersClone;
            ret['voidApprover'] = cloneDeep(result.approver);
            ret['isUpdateSyncing'] = false;
            ret['isUpdateSynced'] = false;
            ret['updateType'] = 'void';
            ret['void_bill_num'] = void_bill_num;
            ret['void_receipt_num'] = void_receipt_num;
            ret['voidReason'] = voidReason;
          }

          return ret;
        });

      if (order.isSettled) {
        await updateSyncedOrder(
          order._id,
          cloneDeep({
            orderId: order._id,
            orders: ordersClone,
            isVoided: true,
            voidApprover: cloneDeep(result.approver),
            updateType: 'void',
            void_bill_num,
            void_receipt_num,
            voidReason,
            splits: newSplits,
          }),
          false,
          order.isSync
        );

        // DO NOT DELETE MIGHT BE USED IN THE FUTURE
        // POS2-1630 - REOPEN VOIDED PAID
        // -----------LOGIC START------------------
        // FOR THE MEAN TIME add tax data
        // ordersClone = order.orders.map(o => ({
        //   ...o,
        //   product: {
        //     ...o.product,
        //     tax_data: this.tax_data,
        //   },
        // }));

        // const orderClone = cloneDeep({
        //     ...order,
        //     orders: ordersClone,
        //     isSettled: false,
        //     status: 'Billed',
        //     isReopened: true,
        //   });

        // delete orderClone['payments'];

        // // set is delete to true
        // // remove from synced orders
        // await updateSyncedOrder(order._id, orderClone, true);

        // //re-add to orders state
        // this.addOrder(orderClone);
        // -----------LOGIC END------------------

        this.orders = await getSyncedOrders();
      }

      //update settlement state if there are voided splits
      const voidedSplits = newSplits.filter(s => s.isVoided === true);
      if (voidedSplits.length > 0) {
        const settledSplitIndices = cloneDeep(this.activePendingSettlements?.settledSplitIndices);
        if (settledSplitIndices) {
          newSplits.forEach((s, i) => {
            if (s.isVoided) {
              settledSplitIndices[i] = '__DELETE__';
            }
          });
        }
        this.setPendingSettlements({
          orderId: order._id,
          settlement: {
            settledSplitIndices: settledSplitIndices,
          },
        });
      }

      let updateOrderObj = {
        isVoided: isVoidWholeOrder,
        voidApprover: cloneDeep(result.approver),
        isUpdateSyncing: false,
        isUpdateSynced: false,
        updateType: 'void',
        void_bill_num,
        void_receipt_num,
        voidReason,
        splits: newSplits,
      };

      if (isVoidWholeOrder) {
        updateOrderObj['orders'] = ordersClone;
      }

      this.updateOrder({
        orderId: order._id,
        order: updateOrderObj,
      });

      this.updateUnsyncVoidedBillCount();

      //print
      let voidSlipHTML = await getVoidPrintString(order, receiptArgs);

      //replace text for modal display
      let html = voidSlipHTML;
      html = html.replace(/__BLANK__/ig, ' ');
      html = html.replace(/__MOD__/ig, ' ');
      this.voidSlipString = html;

      let receiptDetails = await this.parseReceiptDetails(order);
      let printData = {
        print_type: 'Void',
        html: voidSlipHTML,
        mode: receiptArgs.mode,
      };
      let printConfig = {
        location_id: receiptDetails.location_id,
      };

      print(printData, printConfig);

      this.isVoidSlipModalOpen = true;

      this.$swal.success('Bill voided successfully!');

      setTimeout(() => this.triggerManualFetchingOfOrders(true), 3000);
    },

    async voidTransaction(transaction, voidReason) {
      const result = await this.$openApproverModal();
      if (!result.success) {
        if (result.cancelled) {
          this.$swal.error('Voiding cancelled!');
        }
        return;
      }

      const parentOrder = cloneDeep(this.pendingOrders.find(o => o._id === transaction.parentOrderId));
      parentOrder.orders = parentOrder.orders.map(o => ({
        ...o,
        isVoided: o.kot === transaction.kot ? true : o.isVoided,
      }));

      this.regenerateOrderTotals({ orderId: transaction.parentOrderId, key: 'preVoidTotals' });

      //check if all kot are voided
      let isOrderVoided = parentOrder.orders.length === parentOrder.orders.filter(o => o.isVoided == true).length;

      this.updateOrder({
        orderId: transaction.parentOrderId,
        order: {
          ...parentOrder,
          isVoided: isOrderVoided || parentOrder.isVoided,
          isUpdateSyncing: false,
          isUpdateSynced: false,
          updateType: 'void',
          voidReason,
        },
      });
      this.regenerateOrderTotals({ orderId: transaction.parentOrderId });

      //print kot void
      this.printKOT(transaction, null, true);
    },

    openFullVoidModal(item, triggeredFrom = null) {
      this.selectedItem = item;
      this.fullVoidTrigger = triggeredFrom;
      this.isFullVoidModalOpen = true;
    },

    applyFullVoid(voidParams) {
      this.isFullVoidModalOpen = false;
      if (voidParams.triggeredFrom == 'BILL') {
        this.voidBill(voidParams.item, voidParams.reason);
      } else if (voidParams.triggeredFrom == 'TRANSACTION') {
        this.voidTransaction(voidParams.item, voidParams.reason);
      }
    },

    applyFullVoidWithConfirmationModal() {
      this.isFullVoidModalOpen = false;
      this.isVoidConfirmModalOpen = false;
      if (this.selectedVoidItem.triggeredFrom == 'BILL') {
        this.voidBill(this.selectedVoidItem.item, this.selectedVoidItem.reason);
      } else if (this.selectedVoidItem.triggeredFrom == 'TRANSACTION') {
        this.voidTransaction(this.selectedVoidItem.item, this.selectedVoidItem.reason);
      }
    },

    openVoidConfirmModal(voidParams) {
      this.isVoidConfirmModalOpen = true;
      this.selectedVoidItem = voidParams;
    },

    cancelVoid() {
      this.isVoidConfirmModalOpen = false;
    },

    async printKOT(transactionItem, transItemObj = null, isVoided = false) {
      if (transactionItem == null) {
        transactionItem = transItemObj;
      }

      let orderDetail = uniqBy([...this.orders, ...this.originalOrders.filter(o => o && (!o.isSync || o.isOnlineDelivery))], '_id')
        .find(o => o
          && o._id == transactionItem.parentOrderId
          && (o.originShiftTable?.pos_date == this.posDate || o.isOnlineDelivery)
        );

      let receiptDetails = await this.parseReceiptDetails(orderDetail);
      let kotPrintObj = this.getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoided);
      let kotHTMLContent = kotPrintObj.printTemplate;

      let printData = {
        print_type: 'KOT',
        html: kotHTMLContent,
        kotStrArr: kotPrintObj.kotStrArr,
      };

      let printConfig = {
        location_id: receiptDetails.location_id,
        kotItemObject: kotPrintObj.kotItemObject,
      };
      print(printData, printConfig);
    },

    getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoidItem = false) {

      let printTemplate = kotHtml;

      //initialize kotItemObject. this is for category based printing
      let kotItemObject = {};
      //get product categories and put them in an array
      let orderProductCategories = uniq(map(orderDetail.orders, o => get(o, 'product.product_group.product_category_id')));

      orderProductCategories.forEach(function (value) {
        kotItemObject[value] = '';
      });

      //generate kot items
      let orders = this.kotGroupOrdersByServiceType(orderDetail, transactionItem, true);
      let kotItemsStr = '';
      let kotStrArr = [];
      let separatorPositions = [...orderDetail.separatorPositions ?? []];
      for (let serviceType in orders) {
        const serviceHeaderString = `<span class="fs-17">***${serviceType.toUpperCase()}***</span><br/><br/>`;
        const lineItemSeparatorString = `<span class="fs-17">${'-'.repeat(LINE_ITEM_SEPARATOR_CHAR_COUNT)}</span><br/><br/>`;
        kotItemsStr += serviceHeaderString;
        kotStrArr.push({ type: 'service-type-header', string: serviceHeaderString });
        let offset = 0;
        let index = 0;

        orders[serviceType].map(function (order) {
          if (order && order.kot == transactionItem.kot) {
            let offsetIndex = index + offset;
            let separatorString = '';
            while (serviceType == 'Dine-in' && separatorPositions.includes(offsetIndex)) {
              separatorString += lineItemSeparatorString;
              separatorPositions.splice(separatorPositions.indexOf(index + offset), 1);
              offset++;
              offsetIndex++;
            }

            const returnString = `<span class="fs-17">${order.quantity}  ${order.product.product_name}</span><br>
                ${order.product.hasModifier == 1 ?
                `${order.product.forcedMods.map(function (fmod) {
                  return `<span class="mods">&nbsp;&nbsp;${fmod.quantity} ${fmod.modifier_name}</span><br>`
                }).join(' ')}${order.product.unforcedMods.map(function (ufmod) {
                  return `<span class="mods">&nbsp;&nbsp;${ufmod.quantity} ${ufmod.modifier_name}</span><br>`
                }).join(' ')}` : ''}
                ${order.request != undefined && order.request != null && order.request.length > 0 ?
                `<span class="reqs">&nbsp;&nbsp;${order.request}</span><br>` : ''}
                ${order.isVoided == true ? `<span>- voided</span><br>` : ''}`;

            let categoryId = order.product.product_group?.product_category_id;
            if (!kotItemObject[categoryId]) {
              kotItemObject[categoryId] += serviceHeaderString;
              const previousSeparators = orderDetail.separatorPositions?.filter(pos => pos < index) ?? [];
              if (previousSeparators.length) {
                kotItemObject[categoryId] += lineItemSeparatorString.repeat(previousSeparators.length);
              }
            }

            kotItemObject[categoryId] += separatorString + returnString;
            kotItemsStr += separatorString + returnString;
            if (separatorString) kotStrArr.push({ type: 'separator', string: lineItemSeparatorString });
            kotStrArr.push({ type: 'line-item', string: returnString, categoryId });
            index++;
          } else {
            let offsetIndex = index + offset;
            while (separatorPositions.includes(offsetIndex)) {
              separatorPositions.splice(separatorPositions.indexOf(index + offset), 1);
              offset++;
              offsetIndex++;
            }
            index++;
          }
        });

        const remainingSeparators = separatorPositions.filter(pos => pos >= index);
        if (serviceType == 'Dine-in' && remainingSeparators.length) {
          kotItemsStr += lineItemSeparatorString;
          kotStrArr.push({ type: 'separator', string: lineItemSeparatorString });
        }
      }

      //assign the kotItemStr to default key in kotItemObject
      kotItemObject['default'] = kotItemsStr;

      let service_type = orderDetail.serviceType == "Delivery" ? "Delivery" : "Table";
      let identifier = orderDetail.tableName == undefined ? orderDetail.tableId : orderDetail.tableName;

      let identifierString = "";
      switch (receiptDetails.account_type_id) {
        case 4:
          identifierString = orderDetail.brandId + "-" + this.dataContainer['qsr_' + orderDetail._id];
          break;
        case 2:
          if (orderDetail.serviceType == "Delivery") {
            identifierString = orderDetail.brandId + "-" + this.dataContainer['bm_del_' + orderDetail._id];
          } else {
            identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
          }
          break;
        default:
          identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
      }

      if (orderDetail.isOnlineDelivery) {
        identifierString = orderDetail.shortOrderId ?? orderDetail.channelName;
      }

      let today = new Date();
      let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      let dateTime = date + " " + time;

      let toReplace = {
        __REPRINT__: isVoidItem ? '' : '<span style="font-weight: bold;">REPRINT</span><br>',
        __ORDERIDENTIFIER__: service_type + " : " + identifierString,
        __DATETIME__: dateTime,
        __ORDERNUM__: transactionItem.kot,
        __CASHIERNAME__: receiptDetails.cashier_name,
        __PAXCOUNT__: orderDetail.pax,
        __BRANDNAME__: receiptDetails.brand_name,
        __LOCATIONNAME__: receiptDetails.location,
        __MOREDETAILS__: orderDetail.orderDetail ? 'Reference: ' + orderDetail.orderDetail : '',
        __SERVICETYPE__: '',
        __KOTVOID__: isVoidItem ? '<span style="font-weight: bold;">KOT - VOID</span><br>' : '',
      };

      var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
      printTemplate = printTemplate.replace(RE, function (matched) {
        return toReplace[matched];
      });

      console.log(
        convert(printTemplate.replace('__KOTITEMS__', kotItemsStr),
          {
            wordwrap: 130,
            preserveNewlines: true,
          }
        ));

      return { printTemplate, kotItemObject, kotStrArr };
    },

    voidDisabled(item) {
      return (item?.isSettled && !item?.isSync && !item.hasOwnProperty('forManualSync')) ||
        (item?.isSettled && this.queueStatus[item.bill_num] == 0 && item.hasOwnProperty('forManualSync')) ||
        this.checkSplitBillAreAllSettled(item);
    },

    checkSplitBillAreAllSettled(item) {
      return item?.splits?.length > 1
        && !item?.isSync
        && !item.hasOwnProperty('forManualSync');
    }
  }
}
</script>

<style scoped>
.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}

button.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  ;
}
</style>