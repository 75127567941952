<template>
  <base-modal v-bind="$attrs" max-width="600px" min-width="0" min-height="100px" width="100%" @confirm="saveOrderDetail">
    <template v-slot:title>More details</template>

    <hr />

    <div v-if="$can(PERMISSIONS.LABEL_PRINTING)" class="form-group mt-4">
      <label for="order-details">Customer Name</label>
      <input class="form-control" :maxlength="customerNameMaxLen" v-model="orderCustomerName" />
    </div>

    <div class="form-group mt-4" :class="{ 'mb-0': kotString }">
      <label for="order-details">Add details</label>
      <textarea v-model="details" class="form-control" id="order-details" rows="3" :max-length="maxLength"
        @keyup="checkIfMaxLengthReached" />

      <div class="d-flex justify-content-end pt-2">
        <span class="counter" v-text="`${details.length}/${maxLength}`" />
      </div>
    </div>

    <div v-if="kotString" class="kots-container mb-2">
      <label>Order KOTs:</label>
      <div class="kots p-3" v-text="kotString" />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import { PERMISSIONS } from "@/spa/constants";

export default {
  name: "OrderDetailsModal",

  components: {
    BaseModal,
  },

  props: {
    currentDetails: {
      type: String,
      default: "",
    },

    currentOrderCustomerName: {
      type: String,
      default: "",
    },

    maxLength: {
      type: Number,
      default: 500,
    },

    kotString: {
      type: String,
      default: "",
    },

    delivery: {
      type: Boolean,
      default: false,
    },

    customerNameMaxLen: {
      type: Number,
      default: 25,
    },
  },

  data() {
    return {
      orderCustomerName: null,
      details: "",
    };
  },

  watch: {
    currentDetails: {
      handler(value) {
        this.details = value;
      },
      immediate: true,
    },

    currentOrderCustomerName: {
      handler(value) {
        this.orderCustomerName = value;
      },
      immediate: true,
    },

    details(newValue) {
      if (newValue.length > this.maxLength) {
        this.details = this.details.slice(0, this.maxLength);
      }
    },
  },

  methods: {
    saveOrderDetail() {
      this.$emit("saveOrderDetail", {
        orderCustomerName: this.orderCustomerName,
        orderDetail: this.details,
      });
    },
  },
};
</script>

<style scoped></style>
