const LogLevel = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
};

class Logger {
  static currentLogLevel = LogLevel.DEBUG;
  static isLocalDevelopment = process.env.NODE_ENV === "development";

  static setLogLevel(level = LogLevel.INFO) {
    this.currentLogLevel = level;
  }

  static setLocalDevelopment(isLocal = true) {
    this.isLocalDevelopment = isLocal;
  }

  static shouldLog(level, defaultLevel = LogLevel.INFO) {
    if (window.FORCE_DEBUG_MODE) return true;

    // Convert the provided level to uppercase for case insensitivity
    level = level.toUpperCase();

    // Check if the level is a valid log level
    if (LogLevel[level] !== undefined) {
      return this.isLocalDevelopment && this.currentLogLevel >= LogLevel[level];
    } else {
      console.error(
        `Invalid log level: ${level}, falling back to default level: ${defaultLevel}`
      );
      return this.isLocalDevelopment && this.currentLogLevel >= defaultLevel;
    }
  }

  static getTimestamp() {
    return new Date().toISOString();
  }

  static log(level, message, ...optionalParams) {
    if (this.shouldLog(level)) {
      const timestamp = this.getTimestamp();
      const logMessage = `[${level}] [${timestamp}] ${message}`;
      // console.log(logMessage, ...optionalParams);
    }
  }

  static info(message, ...optionalParams) {
    this.log("INFO", message, ...optionalParams);
  }

  static warn(message, ...optionalParams) {
    this.log("WARN", message, ...optionalParams);
  }

  static error(message, ...optionalParams) {
    this.log("ERROR", message, ...optionalParams);
  }

  static debug(message, ...optionalParams) {
    this.log("DEBUG", message, ...optionalParams);
  }
}

module.exports = { Logger, LogLevel };
