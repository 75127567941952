<template>
    <m-card
        class="m-table"
        :class="{ selected }"
        :header="tableHeader"
        :active="occupied"
        :ratio="aspectRatio"
        justify-content="between"
        align-items="stretch"
        header-color="#1B75BB"
        clickable
        @click="$emit('tableSelected', table.table_id)"
    >
        <template #default>
            <pre
                v-if="truncatedDetails"
                v-text="truncatedDetails"
                :title="orderDetails"
                class="table-more-details m-0 px-3"
            />

            <h5 class="font-weight-bold" v-if="isMoveItem && selected && itemData && Object.keys(itemData).length > 0">
                QTY: {{ itemData.quantity }}
            </h5>

            <div
                class="table-label d-flex justify-content-between align-items-center py-2 px-3"
                :class="{ 'occupied-label': occupied }"
                v-if="isMoveItem && isTarget"
                >
                <button
                    class="min-qty"
                    @click.stop="$emit('subtractQuantity', table.table_id)"
                    v-text="'-'"
                    />

                <span>{{ moveItemQty }}</span>

                <button
                    class="plus-qty"
                    @click.stop="$emit('addQuantity', table.table_id)"
                    v-text="'+'"
                    />
            </div>

            <div
                class="table-label d-flex justify-content-between align-items-center py-2 px-3"
                :class="{ 'occupied-label': occupied }"
                >
                <span v-text="label" />
                <i class="fa fa-arrow-right" />
            </div>

            <div v-if="occupied" class="occupancy-details">
                <div class="d-flex align-items-center">
                    <i class="mr-1 fa fa-clock" />
                    <span v-text="stayTime" />
                </div>

                <div class="d-flex align-items-center">
                    <i class="mr-1 fa fa-user" />
                    <span v-text="`${tablePax} Guest(s)`" />
                </div>
            </div>

            <div v-if="occupied && !isOwnOrder" class="foreign-order-lock-indicator">
                <i class="fa fa-lock" />
                {{ tableOrderOriginTerminalId }}
            </div>
        </template>
    </m-card>
</template>

<script>
import MCard from '@/spa/components/common/MCard';

import { mapGetters, mapState } from 'vuex';

import { get } from 'lodash';

export default {
    name: 'MTable',

    components: { MCard },

    props: {
        table: {
            type: Object,
            required: true,
        },
        occupied: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        maxOrderDetailsLength: {
            type: Number,
            default: 20,
        },
        isMoveItem: {
            type: Boolean,
            default: false,
        },
        isTarget: {
            type: Boolean,
            default: false,
        },
        itemData: {
            type: Object,
            default: () => ({}),
        },
        moveItemQty: {
            type: [Number, String],
            default: 0,
        },
        maxNameChars: {
            type: Number,
            default: 0,
        },
        truncateTableInName: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters(['pendingOrders']),
        ...mapState('user', ['terminalId']),

        label() {
            return this.occupied
                ? `Occupied${this.stayDuration ? ' (' + this.stayDuration + ')' : '' }`
                : 'Available';
        },

        tableHeader() {
            const originalName = (this.table.table_name?.toString() || '').replace(/[ -._]/g, '');
            const name = this.truncateTableInName
                ? originalName.replace(/table/gi, 'T')
                : originalName;

            if (this.maxNameChars > 0 && name.length > this.maxNameChars + 1) {
                return name.substring(0, this.maxNameChars) + '…';
            }

            return name;
        },

        tableOrder() {
            const order = this.pendingOrders.find(order => order.tableId == this.table.table_id);
            return order || {};
        },

        orderDetails() {
            return get(this.tableOrder, 'orderDetail', '') ?? '';
        },

        tableOrderOriginTerminalId() {
            return get(this.tableOrder, 'originTerminalId', '') ?? '';
        },

        lockForeignTransactions() {
            // make dynamic when requirements are defined
            return false;
        },

        isOwnOrder() {
            return this.terminalId === this.tableOrderOriginTerminalId
                || !this.lockForeignTransactions;
        },

        tablePax() {
            return get(this.tableOrder, 'pax', 1);
        },

        aspectRatio() {
            return `${this.table.table_position.attrs.scaleX || 3}:${this.table.table_position.attrs.scaleY || 2}`;
        },

        truncatedDetails() {
            if (this.maxOrderDetailsLength > this.orderDetails.length) {
                return this.orderDetails;
            }

            const ellipsis = this.orderDetails.length > this.maxOrderDetailsLength ? '…' : '';
            return `${this.orderDetails.substring(0, this.maxOrderDetailsLength)}${ellipsis}`;
        },

        stayTime() {
            if (!this.tableOrder.occupiedAt) return '';

            return new Date(this.tableOrder.occupiedAt).toLocaleTimeString();
        },

        stayDuration() {
            if (!this.tableOrder.occupiedAt) return '';

            const durationSeconds = (new Date().getTime() - this.tableOrder.occupiedAt) / 1000;

            let unit = '';
            let displayDuration = 0;
            if (durationSeconds < 60) {
                unit = 'sec';
                displayDuration = Math.floor(durationSeconds);
            } else if (durationSeconds < 3600) {
                unit = 'min';
                displayDuration = Math.floor(durationSeconds / 60);
            } else if (durationSeconds < 86400) {
                unit = 'hr';
                displayDuration = Math.floor(durationSeconds / 3600);
            } else {
                unit = 'day';
                displayDuration = Math.floor(durationSeconds / 86400);
            }

            return `${displayDuration}${unit}`;
        },
    },
};
</script>

<style scoped>
.m-table.selected {
    border-bottom: 3px solid var(--primary);
    box-shadow: inset 0 0 0 4px #A2E9FF;
}

.m-table.selected:hover {
    background: #9df;
}

.table-more-details {
    white-space: pre-wrap;
    max-width: 128px;
    word-wrap: break-word;
    color: white;
}

.table-label {
    color: #1B75BB;
}

.table-label.occupied-label {
    color: white;
}

.table-label > span {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
}

.occupancy-details {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 0.7rem;
}

.m-table :deep(.m-card__header) {
    font-size: 2.2rem;
    padding-left: 16px !important;
}

.min-qty, .plus-qty {
  background-color: #36c6f4;
  font-weight: bold;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #fff;
  margin: 0px 5px;
  text-align: center;
  cursor: pointer;
  font-size: 19px;
}

.foreign-order-lock-indicator {
    position: absolute;
    right: 8px;
    bottom: 8px;
    background-color: var(--danger);
    padding: 2px 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15),
        0 4px 8px 0 rgba(0, 0, 0, 0.15)
    ;
}
</style>
