function defaultStateFactory() {
    return {
        approvers: false,
        approversCallback: null,
        permissionType: null,
        isCashFloatModalOpen: false,
    };
}

export default {
    namespaced: true,

    state: defaultStateFactory(),

    mutations: {
        resetState(state) {
            Object.assign(state, defaultStateFactory());
        },

        setApprovers(state, isOpen) {
            state.approvers = isOpen;
        },

        openApproversModal(state, value) {
            state.approvers = true;
            state.permissionType = value
        },

        closeApproversModal(state) {
            state.approvers = false;
        },

        setApproversCallback(state, callback) {
            state.approversCallback = callback;
        },

        setIsCashFloatModalOpen(state, value) {
            state.isCashFloatModalOpen = value;
        }
    },
}