import {DataOffloadBridge} from "@/mobile_bridge/offload/offload";

export async function getSqliteApprovers() {
    const ub = new UserBridge();
    const managerId = 5;

    return await ub.getRows({
        where: {
            user_type_id: managerId
        }
    });
}

export class DiscountBridge extends DataOffloadBridge {
    constructor() {
        super('Discount');
    }

    async getRows(params = {}) {
        const { rows } = await this.sendMessage('GET_ROWS', { params });
        return rows;
    }

    async getBillDiscounts() {
        const approvers = await getSqliteApprovers();
        const discounts = await this.getRows({
            select: [
                'id AS discount_detail_id',
                'has_customer_details',
                'has_service_charge',
                'is_vat_based',
                'passcode_required',
                'max_discount_amount',
                'meal_combination_amount',
                'discount_amount',
                'discount_rate',
                'discount_name',
                'start_date',
                'end_date',
            ],
            where: {
                is_open_discount: 0
            }
        });

        return {
            approvers,
            discounts,
        }
    }

    async getDiscountItems() {
        const approvers = await getSqliteApprovers();
        const discountRows = await this.getRows({
            where: {
                is_open_discount: 0
            }
        });

        const discounts = discountRows.map(discount => {
            discount.service_type = JSON.parse(discount.service_type);
            return discount;
        });

        return {
            approvers,
            discounts,
            location_id: window.locationId,
        }
    }
}
