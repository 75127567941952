import swal from 'sweetalert';

const swalWrapper = (title, text, icon) => swal({ title, text, icon });

const customSwalWrapper = ({
    iconHtml = null,
    stackedIcons = [], // New array for stacked icons
    title = '',
    text = '',
    icon = null, // Default SweetAlert icon ('info', 'warning', etc.)
    confirmButtonText = 'OK',
    buttonColor = '#3085d6',
    className = '', // Custom class for styling
    customClass = {}, // Additional custom classes for styling
} = {}) => {
    // Check if neither `iconHtml` nor `stackedIcons` is provided; fallback to SweetAlert with `icon`
    if (!iconHtml && stackedIcons.length === 0) {
        return swal({
            title,
            text,
            icon, // Fallback to default SweetAlert icon
            button: {
                text: confirmButtonText,
                className: className, // Apply custom button class
            },
        });
    }

    // Build custom content
    const customContent = document.createElement('div');
    customContent.style.textAlign = 'center'; // Center everything in the custom content

    // Handle stacked icons
    if (stackedIcons.length > 0) {
        const stackedIconsContainer = document.createElement('div');
        stackedIconsContainer.style.display = 'flex';
        stackedIconsContainer.style.flexDirection = 'column';
        stackedIconsContainer.style.alignItems = 'center';
        stackedIconsContainer.style.marginBottom = '15px'; // Spacing between icons and title

        stackedIcons.forEach((icon) => {
            const imgElement = document.createElement('img');
            imgElement.src = icon.src;
            imgElement.alt = icon.alt || 'Icon';
            imgElement.style.maxWidth = '200px'; 

            // Spacing between icons
            imgElement.style.marginTop = '15px';
            imgElement.style.marginBottom = '5px'; 
            
            stackedIconsContainer.appendChild(imgElement);
        });

        customContent.appendChild(stackedIconsContainer);
    }

    // Handle single `iconHtml`
    if (iconHtml) {
        const iconContainer = document.createElement('div');
        iconContainer.innerHTML = iconHtml; // Add the custom icon HTML
        iconContainer.style.marginBottom = '15px'; // Add spacing between icon and title
        iconContainer.className = customClass.icon || ''; // Apply custom class for the icon
        customContent.appendChild(iconContainer);
    }

    if (title) {
        const titleContainer = document.createElement('h2');
        titleContainer.innerText = title;
        titleContainer.className = customClass.title || 'custom-title-class'; // Apply custom styling
        titleContainer.style.marginBottom = '10px'; // Add spacing between title and text
        customContent.appendChild(titleContainer);
    }

    if (text) {
        const textContainer = document.createElement('p');
        textContainer.innerText = text;
        textContainer.className = customClass.content || 'custom-content-class'; // Apply custom styling
        textContainer.style.textAlign = 'center';
        textContainer.style.marginBottom = '20px'; // Add spacing between text and button
        customContent.appendChild(textContainer);
    }

    // Call SweetAlert with the custom content
    return swal({
        content: customContent,
        button: {
            text: confirmButtonText,
            className: `${className}`,
        },
    });
};  

export default {
    computed: {
        $swal() {
            return {
                success: (title, text = '') => swalWrapper(title, text, 'success'),
                error: (title, text = '') => swalWrapper(title, text, 'error'),
                warning: (title, text = '') => swalWrapper(title, text, 'warning'),
                info: (title, text = '') => swalWrapper(title, text, 'info'),
                confirm: (title, text = '', icon = 'info') => swal({ title, text, icon, buttons: true }),
            }
        },
        $customSwal() {
            return (options) => customSwalWrapper(options);
        },
    },
};
