<template>
  <base-modal
    v-bind="$attrs"
    max-width="300px"
    min-width="300px"
    min-height="100px"
    width="100%"
    @before-open="init"
    @confirm="updateServiceType"
  >
    <template v-slot:title>Available Service Types</template>

    <hr />

    <div class="d-flex flex-wrap mb-4">
      <div
        v-for="service in serviceTypes"
        :key="`service-${service.id}`"
        class="p-2 col-3"
      >
        <m-card
          :label="service.service_name"
          :active="service.id == selectedServiceType.id"
          ratio="1:1"
          clickable
          @click="selectServiceType(service)"
        >
          <template #default>
            <div class="home-nav-icon" v-html="service.icon" />
          </template>
        </m-card>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import MCard from "@/spa/components/common/MCard";

import { mapGetters, mapState, mapMutations } from "vuex";
import { getOwnBrands } from "@/spa/services/cashier-service";
import { pick } from "lodash";
import { OFFLOAD } from "@/spa/constants";

export default {
  name: "ChangeServiceTypeModal",

  components: {
    BaseModal,
    MCard,
  },

  data() {
    return {
      brands: [],
      selectedServiceType: {},
      originalServiceType: {},
    };
  },

  computed: {
    ...mapState(["activeOrderId", "activeBrandId"]),
    ...mapGetters(["activeOrder"]),
    ...mapState("sqlite", ["initialData"]),

    serviceTypes() {
      const activeBrandId = this.activeOrder.brandId || this.activeBrandId;
      const activeBrand = this.brands.find(
        (brand) => brand.id == activeBrandId
      );

      const serviceTypes = activeBrand ? activeBrand.service_types : [];
      const filteredServiceTypes = serviceTypes.filter(
        (st) =>
          st.service_name != "Delivery" &&
          st.service_name != this.activeOrder.serviceType
      );
      return filteredServiceTypes;
    },
  },

  mounted() {
    this.fetchOwnBrands();

    this.selectedServiceType = {
      id: this.activeOrder.serviceTypeId,
      service_name: this.activeOrder.serviceType,
    };
    this.originalServiceType = this.selectedServiceType;
  },

  methods: {
    ...mapMutations(["updateOrder"]),

    async init() {
      await this.fetchOwnBrands();

      this.selectedServiceType = {
        id: this.activeOrder.serviceTypeId,
        service_name: this.activeOrder.serviceType,
      };
    },

    async fetchOwnBrands() {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.brands = this.initialData?.brands || [];

        return;
      }

      try {
        const response = await getOwnBrands();
        this.brands = response?.data || [];
      } catch (e) {
        console.error(e);
      }
    },

    selectServiceType(service) {
      this.selectedServiceType = service;
    },

    updateServiceType() {
      const serviceTypeId = this.selectedServiceType.id;
      const serviceName = this.selectedServiceType.service_name;
      const orders = Object.values(
        this.activeOrder.orders.reduce((acc, curr) => {
          const prodString = JSON.stringify({
            ...pick(curr.product, [
              "id",
              "forcedMods",
              "unforcedMods",
              "product_name",
              "price",
            ]),
            kot: curr.kot,
            isVoided: Boolean(curr.isVoided),
          });

          if (!acc[prodString]) {
            acc[prodString] = {
              ...curr,
              serviceName,
              serviceTypeId,
              quantity: 0,
            };
          }
          acc[prodString].quantity += curr.quantity;
          return acc;
        }, {})
      );

      this.updateOrder({
        orderId: this.activeOrderId,
        order: {
          serviceTypeId,
          serviceType: serviceName,
          orders,
          originalServiceTypeId: this.originalServiceType.id,
        },
      });

      this.$emit("serviceTypeUpdated");
    },
  },
};
</script>

<style scoped>
:deep(.home-nav-icon) svg {
  height: 48px;
  width: auto;
}
</style>
