<template>
  <div class="m-search-bar">
    <i class="fas fa-search"></i>

    <input
      :placeholder="placeholder"
      type="search"
      v-model="search"
      @input="handleChanged"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "MSearchBar",

  data() {
    return {
      search: null,
    };
  },

  props: {
    placeholder: {
      type: String,
      default: "Search",
    },

    debounce: {
      type: Boolean,
      default: true,
    },

    initialValue: {
      type: String,
    },
  },

  methods: {
    handleChanged() {
      if (this.debounce) {
        this.debounceChanged();
      } else {
        this.$emit("changed", this.search);
      }
    },

    debounceChanged: debounce(function () {
      this.$emit("changed", this.search);
    }, 1000),
  },

  mounted() {
    if (this.initialValue) {
      this.search = this.initialValue;
    }
  },
};
</script>

<style scoped>
.m-search-bar {
  position: relative;
}

.m-search-bar input {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 10px 10px 10px 40px;
  outline: none;
}

.m-search-bar i {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #767676;
}

input {
  width: 350px;
}
</style>
