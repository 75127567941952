<template>
  <svg width="80" height="80" viewBox="0 0 51 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3928 0.775791C11.0776 1.01223 10.9988 1.34717 10.9594 2.82487L10.8806 4.59812L7.80695 4.67693C4.81214 4.73604 4.67422 4.75574 3.66938 5.32712C2.4084 6.01672 1.77792 6.72602 1.08832 8.18402L0.576049 9.26767L0.516941 25.818C0.477536 41.8363 0.477536 42.4077 0.851888 43.6489C1.36416 45.304 2.29019 46.4073 3.86641 47.1757L5.06827 47.7865H25.5H45.9317L47.1336 47.1757C48.7098 46.4073 49.6358 45.304 50.1481 43.6489C50.5225 42.4077 50.5225 41.8363 50.4831 25.818L50.424 9.26767L49.7738 7.94759C49.0251 6.43047 48.1187 5.58326 46.6213 5.03158C45.7741 4.71634 45.0845 4.63753 43.0354 4.63753H40.4741V2.90369C40.4741 1.03193 40.2179 0.499954 39.3707 0.499954C38.4447 0.499954 38.2083 0.953117 38.1492 2.84458L38.0703 4.63753H25.6773H13.2843V2.90369C13.2843 1.46539 13.2252 1.09104 12.9297 0.8349C12.4765 0.421143 11.9051 0.40144 11.3928 0.775791ZM10.92 8.55837C10.92 9.81934 10.9988 10.1937 11.3337 10.6272C11.7869 11.2182 12.2204 11.2773 12.8508 10.8242C13.2449 10.5483 13.2843 10.371 13.2843 8.7554V7.00185H25.6773H38.0703L38.1294 8.65688C38.228 10.903 38.8782 11.6517 40.0406 10.8242C40.4347 10.5483 40.4741 10.371 40.4741 8.7554V6.96245L43.193 7.04126C45.8332 7.10037 45.9514 7.12007 46.641 7.65204C47.9611 8.65688 48.0399 8.97213 48.1187 13.5432L48.1975 17.6413H25.5197H2.84186V14.0357C2.86157 9.14945 3.0783 8.40075 4.73333 7.45502C5.42292 7.06096 5.77757 7.00185 8.22071 7.00185H10.92V8.55837ZM48.1187 31.4726C48.0596 41.7181 48.0202 43.0184 47.7247 43.5504C47.3109 44.3188 46.6213 44.9493 45.9317 45.2054C45.1436 45.501 5.85638 45.501 5.06827 45.2054C4.37868 44.9493 3.68908 44.3188 3.27532 43.5504C2.97978 43.0184 2.94038 41.7181 2.88127 31.4726L2.82216 20.0057H25.5H48.1778L48.1187 31.4726Z"
      fill="#1B75BB" />
    <path
      d="M19.2345 22.8429L18.5055 23.5128L18.4464 32.0638C18.407 40.3389 18.407 40.6147 18.8011 41.2452C19.4907 42.3486 20.062 42.4668 24.8104 42.4668C29.5587 42.4668 30.1301 42.3486 30.8197 41.2452C31.1547 40.6936 31.2138 40.3192 31.1744 38.8218L31.1153 37.0485H30.4257H29.7361L29.6376 38.7824C29.5587 40.0237 29.4602 40.5556 29.2435 40.6936C29.0859 40.7921 27.0762 40.8906 24.771 40.8906C21.2442 40.8906 20.5546 40.8512 20.2985 40.5753C20.0226 40.3192 19.9832 39.275 19.9832 32.2411C19.9832 26.2712 20.0423 24.163 20.2197 23.9856C20.5546 23.6507 29.0662 23.6507 29.4011 23.9856C29.539 24.1236 29.6376 24.8526 29.6376 25.8574V27.4927H30.4257H31.2138V25.6998C31.2138 23.7098 30.9576 23.0596 30.0316 22.4882C29.5587 22.2124 28.7706 22.173 24.7316 22.173H19.9832L19.2345 22.8429Z"
      fill="#1B75BB" />
    <path
      d="M32.79 28.9309V31.4332H28.5539H24.3178V32.2213V33.0094H28.5539H32.79V35.4722C32.79 36.8317 32.8491 37.9351 32.9476 37.9351C33.0264 37.9351 34.3465 36.6741 35.903 35.1176L38.7008 32.3198L35.7454 29.3644L32.79 26.409V28.9309ZM35.5484 31.3346L36.4153 32.2213L35.3908 33.2655L34.3662 34.2901V32.3592C34.3662 31.3149 34.445 30.448 34.5239 30.448C34.6027 30.448 35.0755 30.8421 35.5484 31.3346Z"
      fill="#1B75BB" />
  </svg>
</template>