<template>
  <base-modal
    v-bind="$attrs"
    min-width="600px"
    min-height="400px"
    :has-confirm="true"
    cancel-color="primary"
    cancel-label="Close"
    confirm-color="danger"
    confirm-label="Update"
    @confirm="save()"
    @before-open="fetchPrices"
  >
    <template v-slot:title>Product Name: {{ product?.product_name || '' }}</template>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-footer
      show-index
    >
      <template #item-service_type_name="item">
        <strong class="ml-1">
          {{ item.service_type_name }}
        </strong>
      </template>

      <template #item-channel_name="item">
        {{ item.channel_name }}
      </template>

      <template #item-availability="item">
        <select v-model="item.is_available" @change="updateAvailability(item)" class="form-control">
          <option :value="1" v-text="'Available'" />
          <option :value="0" v-text="'Unavailable'" />
        </select>
      </template>
    </v-data-table>
  </base-modal>
</template>

<script>
import { cloneDeep } from "lodash";
import BaseModal from '../BaseModal.vue';
import VDataTable from 'vue3-easy-data-table';

export default {
  components: {
    BaseModal,
    VDataTable,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Service Types', value: 'service_type_name' },
        { text: 'Service Channel', value: 'channel_name' },
        { text: 'Availability', value: 'availability' },
      ],
      items: [],
    };
  },

  methods: {
    async fetchPrices() {
      const priceBridge = new PriceBridge();
      this.items = await priceBridge.getPrices({
        product_detail_id: this.product.id
      });
    },

    updateAvailability(item) {
      const index = item.index - 1;
      this.items[index].is_available = item.is_available;
      this.items[index].is_updated = true;
    },

    async save() {
      const result = await this.$openApproverModal();
      if (!result.success) return;

      const updatedItems = cloneDeep(this.items)
        .filter(item => item?.is_updated);

      this.$emit("saveAvailability", updatedItems);
    }
  }
};
</script>
