<template>
    <m-card
        :header="`Split Bill #: ${splitNumber}`"
        :class="{ settled }"
        >
        <div class="line-items-container">
            <template
                v-for="(serviceName, key) in uniqueServiceTypes"
                :key="key"
            >
                <div class="bg-light-gray text-center text-primary py-1 font-weight-bold">
                    {{ serviceName }}
                </div>
                <template v-for="(item, index) in consolidatedOrders" :key="index">
                    <m-line-item
                        :key="`${item.product.id}-${index}`"
                        ref="lineItems"
                        :service-type="item.serviceName"
                        :item="item"
                        v-if="item.serviceName === serviceName"
                        readonly
                        locked
                        :class="{
                            selected: selection.includes(index),
                            clickable: showMove && !isPaymentStep,
                        }"
                        @showItemAction="$emit('showItemAction', item)"
                        @click.prevent="selectItem(index)"
                    />
                </template>
            </template>

            <div v-if="!items.length" class="no-item-placeholder text-muted">
                <i class="fas fa-exclamation-circle" />
                No items
            </div>
        </div>

        <div class="d-flex mx-4 flex-wrap totals-container bg-white mt-auto">
            <div class="col-6">
                <div class="px-2 py-1 d-flex justify-content-between service-charge">
                    <strong>Service Charge</strong>
                    <span>{{ formattedTotals.serviceCharge }}</span>
                </div>

                <div class="px-2 py-1 d-flex justify-content-between vat">
                    <strong>VAT</strong>
                    <span>{{ formattedTotals.vat }}</span>
                </div>

                <template v-if="discounts.length">
                    <div class="px-2 py-1 d-flex justify-content-between payment-cell">
                        <strong>Total Discount</strong>
                        <span>{{ $filters.formatPrice(discountsTotal) }}</span>
                    </div>

                    <div v-for="(discount, index) in discounts" :key="`${discount.discount.discount_name}-${index}`" class="pl-4 px-2 py-1 d-flex justify-content-between payment-cell">
                        <div class="d-flex align-items-center">
                            <i class="fa fa-times remove-button mr-2" :disabled="settled" @click="removeDiscount(index)"></i>
                            <strong>{{ discount.discount.discount_name }}</strong>
                        </div>
                        <span>{{ $filters.formatPrice(discount.amount) }}</span>
                    </div>
                </template>
            </div>

            <div class="col-6">
                <div class="px-2 py-1 d-flex justify-content-between net">
                    <strong>Sub Total</strong>
                    <span>{{ formattedTotals.rawPrice }}</span>
                </div>

                <div
                    v-if="formattedTotals.vatExemptSales != null"
                    class="pl-4 px-2 py-1 d-flex justify-content-between net"
                    >
                    <strong>VAT EXEMPT SALES</strong>
                    <span>{{ formattedTotals.vatExemptSales }}</span>
                </div>

                <div
                    v-if="formattedTotals.zeroRatedSales != null"
                    class="pl-4 px-2 py-1 d-flex justify-content-between net"
                    >
                    <strong>ZERO RATED SALES</strong>
                    <span>{{ formattedTotals.zeroRatedSales }}</span>
                </div>

                <div class="px-2 py-1 d-flex justify-content-between total">
                    <strong>Total</strong>
                    <span>{{ formattedTotals.total }}</span>
                </div>

                <div v-if="payments.length" class="px-2 py-1 d-flex justify-content-between payment-cell">
                    <strong>Tendered Amount</strong>
                    <span>{{ $filters.formatPrice(paymentsTotal) }}</span>
                </div>

                <div v-for="(pay, index) in payments" :key="`${pay.method}-${index}`" class="px-2 py-1 d-flex justify-content-between payment-cell">
                    <div class="d-flex align-items-center" :class="{'paid' : pay.status === 'PAID'}">
                        <i class="fa fa-times remove-button mr-2" :disabled="settled" @click="removePayment(index)"></i>
                        <strong>{{ pay.method }}</strong>
                        <i v-if="pay.type === 'epayment'" class="fas fa-qrcode ml-1 bill-qr" @click="showPaymentQR(pay)"></i>
                    </div>
                    <span>{{ $filters.formatPrice(pay.amount) }}</span>
                </div>

                <div v-if="payments.length" class="px-2 py-1 d-flex justify-content-between payment-cell">
                    <strong>Return</strong>
                    <span>{{ $filters.formatPrice(change) }}</span>
                </div>

                <div v-if="payments.length" class="px-2 py-1 d-flex justify-content-between payment-cell">
                    <strong>Balance</strong>
                    <span>{{ $filters.formatPrice(remainingBalance) }}</span>
                </div>
            </div>
        </div>

        <div class="d-flex flex-nowrap actions-container py-2">
            <div
                v-if="showMove"
                class="px-1 col-3"
                >
                <m-icon-button
                    :post-click-delay="0"
                    label="Move Here"
                    icon="level-down-alt"
                    color="primary"
                    @click="moveItems"
                    />
            </div>

            <div
                v-if="$can(PERMISSIONS.APPLY_DISCOUNT_TO_SPLIT)"
                class="px-1"
                :class="[showMove || isPaymentStep ? 'col-3' : 'col-4']"
                >
                <m-icon-button
                    label="Discount"
                    icon="percentage"
                    color="#1261A0"
                    :disabled="!isPaymentStep || settled || isVoided"
                    @click="$emit('addDiscount')"
                    />
            </div>

            <div
                class="px-1"
                :class="[showMove || isPaymentStep ? 'col-3' : 'col-4']"
                >
                <m-icon-button
                    label="Payment"
                    icon="money-bill-wave"
                    color="#48a7c2"
                    :disabled="!isPaymentStep || settled || isVoided"
                    @click="$emit('addPayment')"
                    />
            </div>

            <div
                class="px-1"
                :class="[showMove || isPaymentStep ? 'col-3' : 'col-4']"
                >
                <m-icon-button
                    label="Print"
                    icon="print"
                    color="primary"
                    :disabled="!isPaymentStep || settled || isVoided"
                    @click="emitPrintBill"
                    />
            </div>

            <div
                v-if="!showMove && isPaymentStep"
                class="px-1 col-3"
                >
                <m-icon-button
                    :label="`${pax} pax`"
                    icon="user-alt"
                    color="#99b"
                    :disabled="settled"
                    @click="openPaxModal"
                    />
            </div>
        </div>

        <div class="d-flex flex-nowrap actions-container py-2">
            <div class="px-1 col-12">
                <m-icon-button
                    label="Settle and Print"
                    icon="print"
                    color="#135a96"
                    :disabled="!isPaymentStep || !canSettle || settled || isVoided || hasPendingEpayments || isFinishPayment"
                    @click="$emit('settleBill')"
                    />
            </div>
        </div>

        <pax-modal
            v-model="isPaxModalOpen"
            :current-pax="pax"
            :max-pax="maxPax"
            @paxSelected="updatePax"
            />
    </m-card>
</template>

<script>
import MCard from '@/spa/components/common/MCard';
import MLineItem from '@/spa/components/common/MLineItem';
import MIconButton from '@/spa/components/common/MIconButton';
import PaxModal from '@/spa/components/modals/PaxModal'

import { calculateDiscountAmount, applyDiscount } from '@/spa/utils/computations';
import { mapGetters, mapMutations } from 'vuex';
import {ENABLE_LINE_ITEM_SEPARATORS, PAYMENT_INVOICE_STATUSES} from '@/spa/constants';
import OrderMixin from '@/spa/components/mixins/OrderMixin';
import {isEmpty, pick} from "lodash";
import cloneDeep from 'rfdc/default';
import {dbService} from "@/spa/services/db-service";

export default {
    name: 'MSplitBill',

    emits: [
        'update:pax',
        'printBill',
        'moveItems',
        'addDiscount',
        'addPayment',
        'settleBill',
        'totalsUpdated',
    ],

    mixins: [
        OrderMixin
    ],

    components: {
        MCard,
        MLineItem,
        MIconButton,
        PaxModal,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },

        serviceType: {
            type: String,
            required: true,
        },

        splitNumber: {
            type: Number,
            default: 1,
        },

        settled: {
            type: Boolean,
            default: false,
        },

        isVoided: {
            type: Boolean,
            default: false,
        },

        showMove: {
            type: Boolean,
            default: false,
        },

        moveDisabled: {
            type: Boolean,
            default: false,
        },

        selection: {
            type: Array,
            default: () => [],
        },

        isPaymentStep: {
            type: Boolean,
            default: false,
        },

        canSettle: {
            type: Boolean,
            default: false,
        },

        discounts: {
            type: Array,
            default: () => [],
        },

        payments: {
            type: Array,
            default: () => [],
        },

        pax: {
            type: Number,
            default: 1,
        },

        maxPax: {
            type: Number,
            default: 10000,
        },

        consolidateOrders: {
            type: Boolean,
            default: false,
        },

        isFinishPayment: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            totals: {
                vat: 0,
                serviceCharge: 0,
                net: 0,
                total: 0,
                beforeSc: 0,
                rawPrice: 0,
                vatExemptSales: 0,
                zeroRatedSales: 0,
            },
            isPaxModalOpen: false,
            separatorPositions: [],
        };
    },

    computed: {
        ...mapGetters(['activeOrder', 'pendingOrders']),

        formattedTotals() {
            return {
                vat: this.$filters.formatPrice(this.totals.vat),
                serviceCharge: this.$filters.formatPrice(this.totals.serviceCharge),
                net: this.$filters.formatPrice(this.totals.net),
                total: this.$filters.formatPrice(this.totals.total),
                vatExemptSales: this.totals.vatExemptSales ? this.$filters.formatPrice(this.totals.vatExemptSales) : null,
                zeroRatedSales: this.totals.zeroRatedSales ? this.$filters.formatPrice(this.totals.zeroRatedSales) : null,
            };
        },

        discountsTotal() {
            return this.discounts.reduce((acc, discount) => acc + discount.amount, 0);
        },

        paymentsTotal() {
            return this.payments.reduce((acc, payment) => acc + payment.amount, 0);
        },

        remainingBalance() {
            return Math.max(this.totals.total - this.paymentsTotal, 0);
        },

        change() {
            return Math.max(this.paymentsTotal - this.totals.total, 0);
        },

        uniqueServiceTypes() {
          const serviceNames = this.consolidatedOrders.map(item => item.serviceName);
          return [...new Set(serviceNames)];
        },

        hasPendingEpayments() {
            const unpaidEPayments = this.payments.filter(payment => payment.type === 'epayment' && payment.status === PAYMENT_INVOICE_STATUSES.PENDING );


            if(unpaidEPayments.length) {
                return true;
            }

            return false;
        },

        isSeparatorsEnabled() {
            return ENABLE_LINE_ITEM_SEPARATORS;
        },

        consolidatedOrders() {
            if (!this.consolidateOrders) return this.items;

            const orders = {};
            this.items.forEach(o => {
                const prodString = JSON.stringify({
                    ...pick(o.product, ['id', 'forcedMods', 'unforcedMods', 'product_name', 'price']),
                    kot: this.isSeparatorsEnabled && !isEmpty(this.separatorPositions) ? o.kot : Boolean(o.kot),
                    isVoided: Boolean(o.isVoided),
                    serviceTypeId: o.serviceTypeId
                });

                if (orders[prodString]) {
                    orders[prodString].quantity += o.quantity;
                    orders[prodString]._ids.push(o._id);
                    if (o.discount && orders[prodString].discount) {
                        orders[prodString].discount.discountPax += o.discount.discountPax;
                        orders[prodString].discount.discountAmount += o.discount.discountAmount;
                    }
                } else {
                    orders[prodString] = cloneDeep(o);
                    orders[prodString]._ids = [o._id];
                }
            });

            return Object.values(orders);
        }
    },

    mounted() {
        this.$nextTick(() => this.recomputeTotals());

        this.separatorPositions = cloneDeep(this.activeOrder.separatorPositions || []);
    },

    watch: {
        items: {
            handler() {
                this.$nextTick(() => this.recomputeTotals());
            },
            deep: true,
        },

        discounts: {
            handler() {
                this.$nextTick(() => this.recomputeTotals());
            },
            deep: true,
        },

        pax: {
            handler() {
                this.$nextTick(() => this.recomputeTotals());
            },
            init: true,
        },
    },

    methods: {
        ...mapMutations("global", ["setShowPaymentQRModal", "setPaymentQRDetails"]),     
        
        resetTotals() {
            this.totals = {
                vat: 0,
                serviceCharge: 0,
                net: 0,
                total: 0,
                beforeSc: 0,
                rawPrice: 0,
                vatExemptSales: 0,
                zeroRatedSales: 0,
            };
        },

        emitPrintBill() {
            this.$emit('printBill', this.formattedTotals);
        },

        openPaxModal() {
            this.isPaxModalOpen = true;
        },

        updatePax(pax) {
            this.$emit('update:pax', pax);
            this.isPaxModalOpen = false;
        },

        recomputeTotals() {
            this.resetTotals();

            if (!this.$refs.lineItems) return;

            const parsedAmounts = this.$refs.lineItems.map(item => item.parsedAmounts);
            parsedAmounts.forEach(parsedAmount => {
                this.totals.rawPrice += parsedAmount.rawPrice;
                this.totals.vat += parsedAmount.vat;
                this.totals.serviceCharge += parsedAmount.serviceCharge;
                this.totals.net += parsedAmount.net;
                this.totals.total += parsedAmount.total;
                this.totals.beforeSc += parsedAmount.beforeSc;
                this.totals.isScInclusive = parsedAmount.isScInclusive;
                this.totals.scIsAfterDiscount = parsedAmount.scIsAfterDiscount;

                if (parsedAmount.vatExemptSales > 0) {
                    this.totals.vatExemptSales = (this.totals.vatExemptSales ?? 0) + parsedAmount.vatExemptSales;
                }

                if (parsedAmount.zeroRatedSales > 0) {
                    this.totals.zeroRatedSales = (this.totals.zeroRatedSales ?? 0) + parsedAmount.zeroRatedSales;
                }
            });

            this.discounts.forEach(async discountData => {
                const activeVat = await dbService.fetchItem('tax_detail', (item) => item && item.tax_name == 'VAT')
                const amount = calculateDiscountAmount(discountData, this.totals, this.activeOrderPax);
                this.totals = applyDiscount(discountData, this.totals, amount, this.activeOrderPax, activeVat);
            });

            this.$emit('totalsUpdated', this.totals);
        },

        selectItem(index) {
            if (this.isPaymentStep) return;

            const newSelection = this.selection.includes(index)
                ? this.selection.filter(item => item !== index)
                : [...this.selection, index];

            this.$emit('itemsSelected', newSelection);
        },

        moveItems() {
            this.$emit('moveClicked');
        },

        removeDiscount(index) {
            if (this.settled) {
                this.$swal.warning('Cannot remove discount', 'This bill has been settled');
                return;
            }

            this.$emit('removeDiscount', index);
        },

        removePayment(index) {
            if (this.settled) {
                this.$swal.warning('Cannot remove payment', 'This bill has been settled');
                return;
            }

            this.$emit('removePayment', index);
        },

        showPaymentQR(payment) {
            const params  = {
                orderId: this.orderId,
                bill: this.splitNumber,
                table: this.activeOrder.tableId ?? null,
                payment: payment.paymentChannel,
                amount: payment.amount,
                status: payment.status,
                invoiceUrl: payment.invoiceUrl,
                externalId: payment.externalId,
            }

            this.setPaymentQRDetails(params);

            this.setShowPaymentQRModal(true);
        }
    },
}
</script>

<style>
.settled {
    border: 1px solid var(--green);
}

.selected {
    background-color: var(--blue) !important;
}

.clickable {
    cursor: pointer !important;
}

.clickable:hover {
    background-color: var(--cyan) !important;
}

.line-items-container {
    background: rgba(128, 128, 128, 0.1);
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
}

.no-item-placeholder {
    height: 200px;
    display: grid;
    place-content: center;
    font-size: 1.2rem;
}

.no-item-placeholder i {
    font-size: 2rem;
    text-align: center;
}

.remove-button {
    color: var(--danger);
    font-size: 11px;
    cursor: pointer;
}

.bg-light-gray {
  background-color: #CED8E1;
}

.payment-cell .bill-qr {
    cursor: pointer;
}
.payment-cell .paid .bill-qr {
    color: green;
}

.payment-cell .paid .remove-button {
    color: gray;
    pointer-events: none;
    opacity: .3;
}
</style>
