import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { prefetchAll } from "@/spa/plugins/prefetch";
import { vfmPlugin } from "vue-final-modal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3TouchEvents from "vue3-touch-events";
import VueQRCodeComponent from 'vue-qrcode-component'

import SwalMixin from "@/spa/global-mixins/sweetalert";
import ApprovalMixin from "@/spa/global-mixins/approvers-dialog";
import SessionMeta from "@/spa/global-mixins/session-meta";
import PermissionsMixin from "@/spa/global-mixins/permissions";
import filters from "@/spa/helpers/filters";

import routes from "@/spa/routes";
import store from "@/spa/plugins/vuex";

import 'vue-multiselect/dist/vue-multiselect.css';
import "@/spa/main.css";
import 'vue3-easy-data-table/dist/style.css';
import localForage from 'localforage';
import versionCheck from "@/spa/plugins/version-check";

import '@/spa/css-overrides/just-code-basic.css';

import { setupErrorHandler } from './spa/utils/errorHandler';

import { MAX_TRANSACTIONS, USE_EXPERIMENTAL_STORAGE_STRATEGY } from '@/spa/constants';

function spaSetup() {
  const app = createApp();
  const router = new createRouter({
    routes,
    history: createWebHistory(),
  });

  versionCheck(router);

  router.beforeEach(async (to, from, next) => {
    await store.restored;
    if (USE_EXPERIMENTAL_STORAGE_STRATEGY) {
      await store.dispatch('restoreOrdersFromLocalForage');
      await store.dispatch('restoreSettlementsFromLocalForage');
    }

    if(store.state.orders.length >= MAX_TRANSACTIONS && to.name != 'home') {
      location.href = route('spa', { locationId: window.locationId, userId: window.userId });
      return false;
    }

    if ($.cookie('passcode-in')) return next();

    const isLoggedInOffline = await localForage.getItem('offlineUsername');
    if (isLoggedInOffline) return next();



    location.href = route('index');
  });

  setupErrorHandler(app);

  app.use(router);
  app.use(vfmPlugin);
  app.use(store);
  app.use(Vue3TouchEvents);

  app.mixin(SwalMixin);
  app.mixin(ApprovalMixin);
  app.mixin(SessionMeta);
  app.mixin(PermissionsMixin);

  app.config.globalProperties.$filters = filters;
  app.config.globalProperties.$tempTerminalId = tempTerminalId;
  app.config.globalProperties.$tempActiveDeliveryIntegrations = tempActiveDeliveryIntegrations;

  app.component("font-awesome-icon", FontAwesomeIcon);
  app.component('qr-code', VueQRCodeComponent);

  app.mount("#mosaic-pos-app");

  prefetchAll();

  const overrides = [
    { id: "sidebar-home", target: { name: "home" } },
    {
      id: "sidebar-tables",
      target: {
        name: "tables",
        params: { serviceType: "Dine-in", serviceAreaId: "1" },
      },
    },
    {
      id: "sidebar-takeout",
      target: {
        name: "tables",
        params: { serviceType: "Take-out", serviceAreaId: "2" },
      },
    },
    { id: "sidebar-pending", target: { name: "pending" } },
    {
      id: "sidebar-settings",
      target: {
        name: 'settings',
      }
    },
    {
      id: "sidebar-delivery",
      target: {
        name: "deliveries"
      },
    },
  ];

  overrides.forEach(({ id, target }) => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener("click", e => {
        e.preventDefault();
        router.push(target);
      });
    }
  });
}

window.onload = () => spaSetup();
