import moment from 'moment';
import { PromiseFunctionStorage } from '../helpers/app-utils/promise-function-storage';
import { OFFLOAD } from '@/spa/constants';

var downloadPromises = new PromiseFunctionStorage();
var requestPromises = new PromiseFunctionStorage();

export const shouldRunDbfDownloads = async () => {
  const supportsNativeDownload = !!window.MosaicPosAppSupportedFeatures?.nativeFileDownload;
  let brands = await db.brands.toArray();

  if (OFFLOAD.sqliteOffloadProduct) {
    const brand = new BrandBridge();
    brands = await brand.getAll();
  }

  const isPDM = brands.filter(b => {
    const bn = b.brand_name.toUpperCase();

    return (
      bn.includes('PDM') || bn === 'PAN DE MANILA'
    );
  }).length > 0;

  return supportsNativeDownload && isPDM;
}

export const getDbfApiHost = (feDomain) => {
  const subdomain = feDomain.split('.')[0]
  
  // If the 'www' were to ever be removed from the prod URL, we want to
  // still return the correct backoffice host
  if (subdomain === 'www' || subdomain === 'mosaic-pos') {
    return 'admin.mosaic-pos.com';
  } else {
    // Covers the host convention for preprod and QA
    return `${subdomain}-admin.mosaic-pos.com`
  }
}

export const getDbfDownloadLink = async (date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const locationId = window.locationId;
  const host = getDbfApiHost(location.host);
  const url = `https://${host}/api/${locationId}/${formattedDate}/dbf/get-link`

  const response = await sendNativeRequest(url);
  console.log('getDbfDownloadLink: Got response', response);

  if (response.statusCode != 200) {
    throw response;
  }

  return JSON.parse(response.data);
}

/**
 * Runs the full flow for downloading DBF files. Intended to run immediately
 * after the day-end process.
 * 
 * @param {*} date 
 * @returns 
 */
export const runDbfDownloadFlow = async (date) => {
  const shouldRun = await shouldRunDbfDownloads();
  let dlLink;

  if (!shouldRun) {
    return;
  }

  try {
    dlLink = await getDbfDownloadLink(date);
    console.log('DBF download link response:', dlLink);
  } catch (err) {
    console.error('Failed to get DBF download link!', err)
    return;
  }

  const url = dlLink.url;
  const msg = JSON.stringify({ url });
  const hasWindowsBridge = !!window.chrome?.webview?.postMessage;

  if (hasWindowsBridge) {
    return new Promise((resolve, reject) => {
      window.chrome.webview.postMessage({ msg, channel: 'download' });
      downloadPromises.put(url, resolve, reject);

      window.__hideLoader();
      Swal.fire({
        icon: 'info',
        title: 'Day-End DBF Download',
        text: 'Prompting for download location...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      });
    });
  }
}

const handleDbfMessages = (message) => {
  if (message.type === 'NativeDownloadStatus') {
    const { status, url } = message.body;

    if (status === 'STARTING') {
      Swal.fire({
        icon: 'info',
        title: 'Day-End DBF Download',
        text: 'Download in progress...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      Swal.showLoading();
    } else if (status === 'SUCCESS') {
      Swal.fire({
        icon: 'success',
        title: 'Day-End DBF Download',
        text: 'Download complete!',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      Swal.hideLoading();
      window.__showLoader();
      downloadPromises.resolve(url);
    } else if (status === 'FAILED') {
      Swal.fire({
        icon: 'error',
        title: 'Day-End DBF Download',
        text: 'Download failed.',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      Swal.hideLoading();
      downloadPromises.reject(url);
    }
  }
}

/**
 * A way to have the native app do a request for us, beyond any browser-based
 * request restrictions. This can only do GET requests.
 * 
 * @param {*} url 
 * @returns 
 */
export const sendNativeRequest = (url) => {
  const supportsNativeRequest = !!window.MosaicPosAppSupportedFeatures?.nativeRequest;
  const hasWindowsBridge = !!window.chrome?.webview?.postMessage;
  const msg = JSON.stringify({ url });
  
  if (supportsNativeRequest && hasWindowsBridge) {
    return new Promise((resolve, reject) => {
      // Send a request only if there is no request in progress for this URL
      const fns = requestPromises.getPromiseFunctionsForTag(url);

      if (fns.length === 0) {
        window.chrome.webview.postMessage({ msg, channel: 'request' });
      }

      requestPromises.put(url, resolve, reject);
    });
  }
}

const handleRequestMessages = (message) => {
  console.log(message.type);
  switch (message.type) {
    case 'NativeRequestSuccess': {
      const { url, data, statusCode } = message.body;

      requestPromises.resolve(url, { data, statusCode, url });
      break;
    }
    case 'NativeRequestFailed': {
      const { url, exception } = message.body;

      requestPromises.reject(url, { url, exception });
      break;
    }
  }
}

const handleMessages = (message) => {
  if (!message.mosaicBridgeMessage) {
    return;
  }

  switch (message.type) {
    case 'NativeDownloadStatus':
      handleDbfMessages(message);
      break;
    case 'NativeRequestSuccess':
    case 'NativeRequestFailed':
      handleRequestMessages(message);
      break;
  }
}
window.addEventListener('message', event => handleMessages(event.data));
