<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label>Settings Page</label>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">Printer Settings</label>
        </div>
        <div class="printer-settings-section mb-4">
          <v-data-table
            :headers="printerHeaders"
            :items="printerItems"
            :rows-per-page="10"
            sort-by="ip_address"
            sort-type="desc"
            >
            <template #item-ip_address="item">
              <strong class="ml-1">
                {{ item.ip_address ? item.ip_address : '-' }}
              </strong>
            </template>

            <template #item-printer_location="item">
              {{ item.printer_location }}
            </template>

            <template #item-paper_width="item">
              {{ item.paper_width }}
            </template>

            <template #item-can_print_kot="item">
              {{ item.can_print_kot ? 'Yes' : 'No' }}
            </template>

            <template #item-can_print_bill="item">
              {{ item.can_print_bill ? 'Yes' : 'No' }}
            </template>

            <template #item-can_print_receipt="item">
              {{ item.can_print_receipt ? 'Yes' : 'No' }}
            </template>

            <template #item-can_print_z_report="item">
              {{ item.can_print_z_report ? 'Yes' : 'No' }}
            </template>

            <template #item-actions="item">
              <div class="d-flex">
                <div class="col">
                  <button
                        class="btn btn-sm btn-block btn-primary"
                        @click="testPrint(item)"
                    >
                      Test Print
                  </button>
                </div>
              </div>
            </template>
          </v-data-table>

          <div class="d-flex flex-column flex-lg-row mt-2" v-if="localConfigAvailable">
            <button class="btn btn-primary mb-2 mb-lg-0" @click="scanForPrinters()" :disabled="scanningForPrinters">
              {{ scanningForPrinters ? 'Finding Printers...' : 'Find Printers on the Network' }}
            </button>
            <button class="btn btn-secondary mb-2 mb-lg-0 mx-lg-2" @click="editPrinters()" v-if="usingLocalConfig" :disabled="scanningForPrinters">
              Edit Found Printers
            </button>
            <div class="flex-lg-fill"></div>
            <button class="btn btn-secondary ml-lg-2" @click="confirmLoadFromBackoffice()" :disabled="scanningForPrinters">
              Load Settings from Backoffice
            </button>
          </div>
        </div>


        <div class="d-flex justify-content-between align-items-center" v-if="bluetoothPrintingAvailable">
          <label class="mb-0">Bluetooth Printer</label>
        </div>

        <div class="d-flex justify-content-between align-items-center ml-3" v-if="bluetoothPrintingAvailable">
          <span v-if="bluetoothSettings?.enabled">Bluetooth printing is enabled</span>
          <span v-else>Bluetooth printing is disabled</span>
          <button class="btn btn-primary" @click="openBluetoothModal()">
            Configure
          </button>
        </div>
      </div>
    </div>

    <DiscoveredPrintersModal
      v-model="discoveredPrintersModalVisible"
      :printers="printersForEditing"
      @save="confirmDiscoveredPrinters"
      @manualAdd="onManualAddForEdit"
      @remove="onPrinterRemove" />
    
    <DiscoveredPrintersModal
      v-model="editingPrintersModalVisible"
      :printers="printersForEditing"
      @save="confirmDiscoveredPrinters"
      @manualAdd="onManualAddForEdit"
      @remove="onPrinterRemove" />

    <BluetoothPrinterModal
      v-model="bluetoothModalVisible"
      :settings="bluetoothSettingsForEditing"
      :productCategories="productCategories"
      @save="saveBluetoothSettings"
      @selectPaired="openBluetoothDeviceSelector"
      @testPrint="testPrint" />

    <ConfirmMessageModal
      v-model="confirmingLoadFromBackoffice"
      message="Are you sure you want to replace your current printer settings with settings from the backoffice?"
      @confirmed="loadPrintersFromBackoffice"
      />
  </div>
</template>

<script>
import {uniqBy, map, orderBy} from 'lodash';
import {mapState} from 'vuex';
import {OFFLOAD} from '@/spa/constants';
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
import DiscoveredPrintersModal from '@/spa/components/modals/DiscoveredPrintersModal';
import DiscoveredPrinterListItem from '@/spa/components/common/DiscoveredPrinterListItem';
import BluetoothPrinterModal from '@/spa/components/modals/BluetoothPrinterModal.vue';
import ConfirmMessageModal from '@/spa/components/modals/ConfirmMessageModal';
import VDataTable from 'vue3-easy-data-table';
import {test_print, getPrinterData} from "@/spa/services/printer-service";
import {PrinterConfig, LocalPrinterConfigService} from "@/spa/services/local-printer-config-service";

const BLUETOOTH_SETTINGS_KEY = 'BLUETOOTH_SETTINGS';

export default {

  components: {
    SettingsSidebar,
    VDataTable,
    DiscoveredPrintersModal,
    DiscoveredPrinterListItem,
    BluetoothPrinterModal,
    ConfirmMessageModal
  },

  data() {
    return {
      printerHeaders: [
        { text: 'IP Address', value: 'ip_address', sortable: true },
        { text: 'Printer Location', value: 'printer_location', sortable: true },
        { text: 'Paper Width', value: 'paper_width', sortable: true },
        { text: 'Can Print KOT?', value: 'can_print_kot', sortable: true },
        { text: 'Can Print Bill?', value: 'can_print_bill', sortable: true },
        { text: 'Can Print Receipts?', value: 'can_print_receipt', sortable: true },
        { text: 'Can Print Reports?', value: 'can_print_z_report', sortable: true },
        { text: 'Actions', value: 'actions' },
      ],

      printerItems: [],
      localConfigAvailable: is_local_printer_config_available(),
      bluetoothPrintingAvailable: !!window.MosaicPosAppSupportedFeatures?.bluetoothPrinting,
      scanningForPrinters: false,
      editingPrintersModalVisible: false,
      printersForEditing: [],
      discoveredPrintersModalVisible: false,
      bluetoothModalVisible: false,
      bluetoothPrinter: null,
      bluetoothSettings: null,
      bluetoothSettingsForEditing: null,
      usingLocalConfig: is_using_local_printer_config(),
      confirmingLoadFromBackoffice: false,
      lastCreatedPrinterID: 0,
    };
  },

  computed: {
    ...mapState('sqlite', ['initialData']),
  },

  async mounted() {
    this.printerItems = await this.getPrinterItems();
    this.productCategories = await this.getProductCategories();
    this.bluetoothSettings = await this.getBluetoothSettings(); 
  },

  methods: {
    async getBackofficePrinterItems() {
      let printerData = await getPrinterData();
      return printerData.printer_details.map(pd => {
        pd.printer_model = printerData.printer_models.find(pm => pm.id == pd.printer_model_id);
        return pd;
      });
    },

    async getPrinterItems() {
      if (is_print_via_app_available() && is_using_local_printer_config()) {
        const printers = await db.printer_details.toArray();

        return printers.map(p => Object.assign(new PrinterConfig(), p));
      } else {
        return this.getBackofficePrinterItems();
      }
    },

    async getProductCategories() {
      if (OFFLOAD.sqliteOffloadProduct) {
        return [ ...this.initialData.groups ];
      } else {
        const products = await db.product_detail.toArray();
        const categories = [
          ...orderBy(
            uniqBy(
              map(products, p => ({
                id: p.product_group.product_category_id,
                name: p.category,
              })),
              'id'
            ),
            'name'
          ),
        ];

        return categories;
      }
    },

    async scanForPrinters() {
      this.scanningForPrinters = true;
      const printerIPs = await scan_network_for_printers();
      this.scanningForPrinters = false;
      const categories = await this.getProductCategories();

      this.printersForEditing = printerIPs.map(ip => new PrinterConfig(ip, categories));
      this.discoveredPrintersModalVisible = true;
    },

    async onManualAddForEdit(ip) {
      const categories = await this.getProductCategories();
      const newPrinter = new PrinterConfig(ip, categories);
    
      this.printersForEditing = [...this.printersForEditing, newPrinter];
    },

    onPrinterRemove(printer) {
      this.printersForEditing = this.printersForEditing.filter(
        p => p.id != printer.id
      );
    },

    showFoundPrintersModal() {
      this.discoveredPrintersModalVisible = true;
    },

    async editPrinters() {
      const printers = Dexie.deepClone(this.printerItems).map(p => Object.assign(new PrinterConfig(), p));
      this.printersForEditing = printers;
      this.editingPrintersModalVisible = true;
    },

    async confirmDiscoveredPrinters(printers) {
      const copy = Dexie.deepClone(printers).map(p => Object.assign(new PrinterConfig(), p));
      const storage = new ScopedNativeStorage(`${window.locationId}`);
      const allCategories = await this.getProductCategories();
      const configSvc = new LocalPrinterConfigService(allCategories);

      copy.forEach(printer => configSvc.syncUpPrinterModelData(printer));
      this.printerItems = copy
      this.discoveredPrintersModalVisible = false;
      this.editingPrintersModalVisible = false;
      await db.location_printers.clear();

      const locationPrinters = configSvc.bulkCreateCategoryEntries(copy);

      await db.location_printers.bulkAdd(locationPrinters);
      await db.printer_details.clear();
      await db.printer_details.bulkAdd(copy);

      this.usingLocalConfig = true;
      set_using_local_printer_config(true);
      storage.put('printer_details', JSON.stringify(copy));
      storage.put('location_printers', JSON.stringify(locationPrinters));
    },
    
    onDiscoveredPrintersCancel() {
      this.discoveredPrintersModalVisible = false;
    },

    confirmLoadFromBackoffice() {
      if (is_using_local_printer_config()) {
        this.confirmingLoadFromBackoffice = true;
      } else {
        this.loadPrintersFromBackoffice();
      }
    },

    openBluetoothDeviceSelector() {
      open_bluetooth_config();
    },

    openBluetoothModal() {
      this.bluetoothSettingsForEditing = Dexie.deepClone(this.bluetoothSettings);
      this.bluetoothModalVisible = true;
    },

    async getBluetoothSettings() {
      const storage = new ScopedNativeStorage(`${window.locationId}`);
      const btJson = await storage.get(BLUETOOTH_SETTINGS_KEY)

      if (btJson) {
        const settings = JSON.parse(await storage.get(BLUETOOTH_SETTINGS_KEY));

        settings.printerConfig = Object.assign(new PrinterConfig(), settings.printerConfig);
        return settings
      } else {
        return {
          enabled: false,
          device: null,
          printerConfig: new PrinterConfig('LOCAL-BLUETOOTH', this.productCategories),
        }
      }
    },

    saveBluetoothSettings(settings) {
      const storage = new ScopedNativeStorage(`${window.locationId}`);
      const configSvc = new LocalPrinterConfigService(this.productCategories);

      configSvc.syncUpPrinterModelData(settings.printerConfig);
      settings.printingSystemIntegration = {
        location_printers: configSvc.createCategoryEntries(settings.printerConfig),
        printer_details: [ settings.printerConfig ],
      }

      this.bluetoothSettings = settings;
      storage.put(BLUETOOTH_SETTINGS_KEY, JSON.stringify(settings));
      this.bluetoothModalVisible = false;
    },

    async loadPrintersFromBackoffice() {
      this.confirmingLoadFromBackoffice = false;
      __showLoader();
      this.printerItems = await this.getBackofficePrinterItems();
      __hideLoader();
      set_using_local_printer_config(false);
      this.usingLocalConfig = false;
    },

    testPrint(printerData) {
      test_print(printerData);
    },

    noop() {},
  }
}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>