export default {
    props: {
        userId: {
            type: [String, Number],
            default: window.userId,
        },

        locationId: {
            type: [String, Number],
            default: window.locationId,
        },
    }
}
