<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.61868e-09 11.5948V11.5624C1.61868e-09 7.21024 2.77452 3.50247 6.64608 2.12024C7.04614 1.97691 7.48602 2.18665 7.62839 2.58737C7.77142 2.98828 7.56229 3.42926 7.16225 3.5726C3.89925 4.73816 1.55704 7.85542 1.53858 11.5194V11.5625C1.53858 15.7189 4.52927 19.1816 8.4708 19.9031L7.82158 18.9278C7.58617 18.5734 7.68147 18.0947 8.03466 17.8588C8.38837 17.6229 8.86604 17.7184 9.10146 18.0723L10.6399 20.3848C10.7392 20.5344 10.78 20.7054 10.767 20.8711V20.8735C10.7599 20.9645 10.7369 21.0507 10.7007 21.1302C10.6439 21.2574 10.5515 21.3708 10.4268 21.4539L8.11916 22.9956C7.76545 23.2315 7.28778 23.136 7.05236 22.782C6.81695 22.4276 6.91225 21.9489 7.26544 21.713L7.81849 21.3437C3.35788 20.3478 0.0155025 16.361 0.000118046 11.5949L1.61868e-09 11.5948ZM19.9998 11.5301V11.5624C19.9998 15.9316 17.2036 19.6515 13.3075 21.0214C12.9068 21.1616 12.4676 20.9505 12.3268 20.5489C12.1869 20.1473 12.3975 19.7071 12.7983 19.5661C16.0821 18.412 18.4426 15.2839 18.4615 11.6056V11.5625C18.4615 7.4061 15.4709 3.94342 11.5293 3.22191L12.1785 4.19718C12.4139 4.55163 12.3186 5.03031 11.9655 5.26623C11.6118 5.50213 11.1341 5.40664 10.8987 5.0527L9.36021 2.74016C9.26097 2.59064 9.2201 2.41961 9.23315 2.25391V2.2515C9.24019 2.16048 9.2632 2.07428 9.29943 1.99479C9.35627 1.86763 9.44864 1.75424 9.5733 1.67113L11.881 0.129441C12.2347 -0.10646 12.7123 -0.0109648 12.9478 0.342972C13.1832 0.697425 13.0879 1.1761 12.7347 1.41203L12.1816 1.78128C16.6422 2.77716 19.9846 6.76402 20 11.5301L19.9998 11.5301Z"
    />
  </svg>
</template>