<template>
  <svg width="80" height="80" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.0652 0.854597C43.0791 1.3882 41.7549 3.42377 42.1008 5.40993C42.1601 5.7459 42.2293 6.09175 42.2589 6.1708C42.2885 6.25974 40.9644 7.45539 38.3557 9.67871L34.413 13.0483L33.9289 12.821C33.5435 12.6431 33.2767 12.6036 32.4664 12.6036C31.5672 12.6036 31.419 12.6333 30.836 12.9099C30.4802 13.0779 30.0257 13.3546 29.8379 13.5226L29.4822 13.8289L26.0731 12.4356L22.6542 11.0325L22.5949 10.4396C22.5158 9.57989 21.9822 8.54234 21.3202 7.94946C19.8182 6.58582 17.6739 6.48701 16.0534 7.71231C15.085 8.44353 14.5613 9.4712 14.4526 10.8645L14.3834 11.6846L10.8953 13.7499L7.40712 15.825L7.13044 15.6273C6.70554 15.321 5.91503 15.0443 5.27273 14.9752C3.62254 14.7874 1.84388 15.8052 1.11265 17.3665C0.8755 17.8803 0.845856 18.0483 0.845856 19.0265C0.845856 19.9554 0.8755 20.1827 1.07313 20.6076C1.43874 21.3981 1.79447 21.8427 2.417 22.3072C4.41305 23.7795 7.16997 23.1965 8.44467 21.0325C8.74111 20.5186 9.01779 19.3329 8.97827 18.7499L8.94862 18.3546L12.4466 16.2795L15.9447 14.2143L16.5474 14.5107C17.3775 14.9356 17.9605 15.0542 18.8597 15.0048C19.7095 14.9455 20.4506 14.6688 21.0929 14.1451C21.3004 13.9771 21.5277 13.8388 21.587 13.8388C21.6463 13.8388 23.1878 14.4514 25.0059 15.2024L28.3162 16.5562L28.3854 17.1392C28.5435 18.3842 29.413 19.6589 30.5296 20.2419C33.504 21.8329 37.0514 19.3032 36.4881 15.9831C36.3893 15.4198 36.3893 15.2617 36.4881 15.1629C36.5573 15.0937 38.3459 13.572 40.4704 11.7736C43.0494 9.57989 44.3735 8.52258 44.4723 8.56211C45.3814 8.90796 46.3103 8.97713 47.1897 8.73997C48.336 8.44353 49.2846 7.65302 49.838 6.53642C50.1344 5.92377 50.1542 5.82495 50.1542 4.79729C50.1542 3.76962 50.1344 3.6708 49.838 3.05816C48.9387 1.23009 46.9921 0.340763 45.0652 0.854597Z"
      fill="#1B75BB" />
    <path d="M42.3972 33.5029V51.2894H46.4486H50.5V33.5029V15.7163H46.4486H42.3972V33.5029Z" fill="#1B75BB" />
    <path
      d="M14.502 20.7262C14.4624 20.7558 14.4328 27.6531 14.4328 36.0424V51.2894H18.4842H22.5356V35.9732V20.657H18.5534C16.3597 20.657 14.5316 20.6867 14.502 20.7262Z"
      fill="#1B75BB" />
    <path d="M28.4644 39.2341V51.2894H32.5158H36.5672V39.2341V27.1788H32.5158H28.4644V39.2341Z" fill="#1B75BB" />
    <path
      d="M0.56917 32.2875C0.529644 32.3171 0.5 36.6155 0.5 41.823V51.2894H4.55138H8.60277V41.7539V32.2183H4.62055C2.42688 32.2183 0.598814 32.2479 0.56917 32.2875Z"
      fill="#1B75BB" />
  </svg>

</template>