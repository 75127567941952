<template>
    <base-modal
        v-bind="$attrs"
        min-width="100px"
        min-height="100px"
        confirmLabel="Add"
        @confirm="addOpenItemProduct"
        >
        <template v-slot:title>Open Food</template>

        <hr />
        <div class="open-item">
            <div class="form-group mt-4">
                <label for="product-name">Product Name</label>
                <input 
                    type="text" 
                    class="form-control"
                    id="product-name"
                    v-model="productName"
                    />

                

            </div>

            <div class="form-group mt-4">
                <label for="product-price">Product Price</label>
                <input 
                    type="number" 
                    v-model="productPrice"
                    placeholder="0.00"
                    class="form-control"
                    id="product-price"
                    />

            </div>

        </div>
        

    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import { mapState } from 'vuex';
import { some, isEmpty } from 'lodash';

export default {
    name: 'OpenItemModal',

    components: {
        BaseModal,
    },

    data() {
        return {
            productName: '',
            productPrice: ''
        };
    },

    computed: {
        ...mapState([
            'activeBrandId'
        ])
    },

    methods: {
        addOpenItemProduct() {

            if (some([this.productName, this.productPrice], item => isEmpty(item.toString()))) {
                this.$swal.warning('Please fill in all open food details');
                return;
            }
            this.$emit('saveOpenItemProduct', { 
                                                'id': null,
                                                'product_group_id': null,
                                                'product_sub_group_id': null,
                                                'product_name': this.productName, 
                                                'product_price': this.productPrice,
                                                'brand_id': this.activeBrandId,
                                                'is_open_item': true
                                                });

            this.productName = "";
            this.productPrice = "";
        },
    },
}
</script>

<style scoped>

.open-item {
    width: 500px;
}

</style>