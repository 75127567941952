<template>
  <svg
    width="20"
    height="26"
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.06732 1.27966L3.06732 1.27966C2.89671 0.982314 2.99892 0.603309 3.29605 0.432488L3.29625 0.432376L3.29625 0.432376C3.59351 0.262073 3.97241 0.364188 4.14327 0.661038C4.14328 0.661062 4.1433 0.661087 4.14331 0.661111L18.0337 24.7205L18.0338 24.7207C18.2044 25.0176 18.1024 25.3971 17.8049 25.5674C17.5082 25.738 17.1288 25.6361 16.9584 25.339L3.06732 1.27966ZM3.06732 1.27966L3.06752 1.28001L16.9581 25.3385L3.06732 1.27966Z"
      stroke="white"
      stroke-width="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 13.0948V13.0624C0 8.71024 2.77452 5.00247 6.64608 3.62024C7.04614 3.47691 7.48602 3.68665 7.6284 4.08737C7.77142 4.48828 7.56229 4.92926 7.16225 5.0726C3.89925 6.23816 1.55704 9.35542 1.53857 13.0194V13.0625C1.53857 17.2189 4.52927 20.6816 8.4708 21.4031L7.82158 20.4278C7.58617 20.0734 7.68147 19.5947 8.03466 19.3588C8.38837 19.1229 8.86604 19.2184 9.10146 19.5723L10.6399 21.8848C10.7392 22.0344 10.78 22.2054 10.767 22.3711V22.3735C10.7599 22.4645 10.7369 22.5507 10.7007 22.6302C10.6439 22.7574 10.5515 22.8708 10.4268 22.9539L8.11916 24.4956C7.76545 24.7315 7.28778 24.636 7.05236 24.282C6.81695 23.9276 6.91225 23.4489 7.26544 23.213L7.81849 22.8437C3.35788 21.8478 0.0153847 17.8608 0 13.0948ZM19.9998 13.0301V13.0624C19.9998 17.4316 17.2036 21.1515 13.3075 22.5214C12.9068 22.6616 12.4676 22.4505 12.3268 22.0489C12.1869 21.6473 12.3975 21.2071 12.7983 21.0661C16.0821 19.912 18.4426 16.7839 18.4615 13.1056V13.0625C18.4615 8.9061 15.4709 5.44342 11.5293 4.72191L12.1785 5.69718C12.4139 6.05163 12.3186 6.53031 11.9655 6.76623C11.6118 7.00213 11.1341 6.90664 10.8987 6.5527L9.36021 4.24016C9.26097 4.09064 9.2201 3.91961 9.23315 3.75391V3.7515C9.24019 3.66048 9.2632 3.57428 9.29943 3.49479C9.35627 3.36763 9.44864 3.25424 9.5733 3.17113L11.881 1.62944C12.2347 1.39354 12.7123 1.48903 12.9478 1.84297C13.1832 2.19742 13.0879 2.6761 12.7347 2.91203L12.1816 3.28128C16.6422 4.27716 19.9844 8.26406 19.9998 13.0301Z"
    />
  </svg>
</template>