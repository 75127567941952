<script>
export default {
  data() {
    return {
      isOnline: true,
    };
  },

  mounted() {
    window.addEventListener("offline", () => (this.isOnline = false));
    window.addEventListener("online", () => (this.isOnline = true));
  },

  beforeDestroy() {
    window.removeEventListener("offline", () => (this.isOnline = false));
    window.removeEventListener("online", () => (this.isOnline = true));
  },
};
</script>