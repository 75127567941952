<template>
  <BaseModal
    v-model="isOpen"
    min-width="500"
    min-height="210"
    full-width-actions
    :click-to-close="false"
    :hasConfirm="false"
    cancelLabel="Close"
  >
    <template v-slot:title>Brands</template>

    <div class="label mb-1">
      Pause or Resume {{ channel?.channel_name }} operation
    </div>

    <div class="brands">
      <div
        v-for="detail in details"
        class="brand d-flex align-items-center justify-content-between"
      >
        <div class="brand-title">{{ detail.brand.brand_name }}</div>

        <div>
          <span
            v-if="isGrab ? detail.grab_resume_at : detail.foodpanda_resume_at"
            class="resume-in mr-2"
            >resume in {{ detail.resumeAtFormatted }}</span
          >

          <MSwitch
            :defaultValue="
              !Boolean(
                isGrab ? detail.grab_resume_at : detail.foodpanda_resume_at
              )
            "
            @changed="handleChangeBrandAvailability(detail, $event)"
            :ref="`detail-${detail.id}`"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import MSwitch from "@/spa/components/common/MSwitch";

export default {
  name: "MultiplePauseResumeModal",

  components: { BaseModal, MSwitch },

  props: {
    channel: {
      type: String,
      required: true,
    },

    details: {
      type: Array[Object],
      required: true,
    },
  },

  data() {
    return {
      selected: null,
    };
  },

  computed: {
    isGrab() {
      return this.channel?.channel_name === "Grab";
    },
  },

  methods: {
    reset() {
      this.selected = null;
    },

    confirm() {
      this.$emit("confirmed", this.selected);
    },

    handleChangeBrandAvailability(detail, enable) {
      this.$emit("handleChangeBrandAvailability", {
        enable,
        detail,
      });
    },
  },
};
</script>

<style scoped>
.brands {
  padding-top: 20px;
}

.brand {
  margin-bottom: 5px;
  padding: 10px;
  background-color: #ddd;
  border-radius: 5px;

  font-weight: bold;
}

.brand .brand-title {
  font-size: 16px;
}

.brand .resume-in {
  color: #767676;
}
</style>
