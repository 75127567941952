<template>
  <base-modal
    v-bind="$attrs"
    :confirmLabel="confirmLabel"
    :secondaryConfirmLabel="secondaryConfirmLabel"
    max-width="600px"
    min-width="0"
    min-height="100px"
    width="100%"
    :hasSecondaryConfirm="hasSecondaryConfirm"
    @confirm="confirm"
    @secondaryConfirm="secondaryConfirm"
    @cancel="resetValues"
    :confirmDisabled="confirmDisabled"
    :secondaryConfirmDisabled="confirmDisabled"
    @before-open="reset"
    :hasConfirm="hasConfirm"
  >
    <template v-slot:title>Add Payment</template>

    <hr />

    <MEPaymentSelector
      v-if="selectedPaymentMethodType === 'epayment'"
      class="px-2"
      @ePaymentSelected="selectedEPayment = $event"
      @showPaymentSelection="selectedPaymentMethod = null"
      :showBackIcon="!preselectedPaymentMethod"
      ref="epaymentSelector"
    />

    <m-payment-method-selector
      v-if="
        !preselectedPaymentMethod && selectedPaymentMethodType !== 'epayment'
      "
      :selected-payment-method="selectedPaymentMethod"
      @selectPaymentMethod="setSelectedPaymentMethod($event.name)"
    />

    <template
      class="payment-details-form d-block"
      :class="{ 'py-2': !hasPaymentSelection }"
    >
      <div class="mb-4 text-center total-value">
        <h6 for="total-payable">Total</h6>
        <h3>{{ $filters.formatPrice(displayedPayable) }}</h3>
      </div>

      <nav>
        <div class="nav nav-tabs payment-tabs" id="nav-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            data-toggle="tab"
            href="#nav-quick"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            >Quick Payment</a
          >
          <a
            class="nav-item nav-link"
            data-toggle="tab"
            href="#nav-custom"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
            >Custom Payment</a
          >
        </div>
      </nav>

      <div class="mt-3 px-1">
        <h6 for="total-payable">Payment Amount</h6>
        <input
          :value="$filters.formatPrice(paymentAmount)"
          id="payment-amount"
          class="form-control"
          readonly
        />
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-quick" role="tabpanel">
          <div class="d-flex flex-column justify-content-between">
            <div class="mb-2 mt-4 px-0 py-0 quick-cash">
              <h6>Quick Payment</h6>
              <div class="d-flex flex-wrap">
                <div class="col-6 px-1 pt-1">
                  <button
                    class="btn btn-block btn-primary quick-cash-amount-button"
                    @click="addQuickAmount('exact')"
                  >
                    Exact
                  </button>
                </div>

                <div
                  v-for="(amount, index) in quickCashAmounts"
                  class="col-6 px-1 pt-1"
                  :key="`quick-cash-amount-${index}`"
                >
                  <button
                    class="btn btn-block btn-primary quick-cash-amount-button"
                    :disabled="isMosaicPayQRPH && amount > remainingPayable"
                    @click="addQuickAmount(amount)"
                  >
                    {{ amount }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-custom" role="tabpanel">
          <div
            class="d-flex flex-column justify-content-between mt-4 custom-payment"
          >
            <div
              v-for="y in [0, 1, 2]"
              class="d-flex py-2"
              :key="`keypad-row-${y}`"
            >
              <div
                v-for="x in [1, 2, 3]"
                class="col-4"
                :key="`keypad-${y}-${x}`"
              >
                <button
                  class="btn btn-block btn-light p-4"
                  @click="appendAmount(x + y * 3)"
                >
                  {{ x + y * 3 }}
                </button>
              </div>
            </div>

            <div class="d-flex py-2">
              <div class="col-4">
                <button
                  class="btn btn-block btn-danger p-4"
                  @click="clearAmount"
                >
                  CLEAR
                </button>
              </div>

              <div class="col-4">
                <button
                  class="btn btn-block btn-light p-4"
                  @click="appendAmount(0)"
                >
                  0
                </button>
              </div>

              <div class="col-4">
                <button
                  class="btn btn-block btn-light p-4"
                  @click="addDecimalPoint"
                >
                  .
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedPaymentMethodType === 'card'" class="mb-2 mt-3">
        <h6 for="customer-name">Customer Name</h6>
        <input
          v-model="customerName"
          id="customer-name"
          class="form-control"
        />
      </div>

      <div v-if="selectedPaymentMethodType === 'card'" class="mt-2 mb-2">
        <h6 for="card-number">Card Number</h6>
        <input
          v-model="cardNumber"
          @keyup="inputNumbersOnly"
          id="card-number"
          class="form-control"
          maxlength="4"
        />
        <span class="sm-descriptive-text"
          >Card number must contain the last 4 digits of the card.</span
        >
      </div>

      <div v-if="selectedPaymentMethodType === 'card'" class="mb-2">
        <h6 for="approval-code">Approval Code</h6>
        <input
          v-model="approvalCode"
          @keyup="inputNonSpecialCharacters"
          id="approval-code"
          class="form-control"
          maxlength="6"
        />
        <span class="sm-descriptive-text"
          >Approval code must contain 5-6 numeric/alpha-numeric
          characters.</span
        >
      </div>

      <div v-if="selectedPaymentMethodType === 'check'" class="mb-2">
        <h6 for="approval-code">Reference number</h6>
        <input
          v-model="approvalCode"
          @keyup="inputNonSpecialCharacters"
          id="approval-code"
          class="form-control"
          maxlength="100"
        />
      </div>

    </template>
  </base-modal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import MPaymentMethodSelector from "@/spa/components/common/MPaymentMethodSelector";
import MEPaymentSelector from "@/spa/components/common/MEPaymentSelector";

import { mapGetters } from "vuex";
import { getPaymentMethods } from "@/spa/services/cashier-service";
import {OFFLOAD} from "@/spa/constants";

export default {
  name: "AddPaymentModal",

  components: {
    BaseModal,
    MPaymentMethodSelector,
    MEPaymentSelector,
  },

  props: {
    totalPayable: {
      type: [Number, String],
      default: 0,
    },

    preselectedPaymentMethod: {
      type: String,
      default: "",
    },

    canPrintQrCode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      paymentAmount: 0,
      selectedPaymentMethod: "CASH",
      selectedEPayment: null,
      customerName: "",
      cardNumber: "",
      approvalCode: "",
      paymentMethods: [],
      quickCashAmounts: [1000, 500, 200, 100, 50],
    };
  },

  computed: {
    ...mapGetters(["activeOrder"]),

    selectedPayment() {
      return this.paymentMethods.find(
        (m) => m.name === this.selectedPaymentMethod
      );
    },

    selectedPaymentMethodType() {
      const method = this.paymentMethods.find(
        (m) => m.name === this.selectedPaymentMethod
      );

      return method && method.type;
    },

    displayedPayable() {
      return this.totalPayable > 0 ? this.totalPayable : 0;
    },

    remainingPayable() {
      return Math.max(this.displayedPayable - this.paymentAmount, 0);
    },

    isMosaicPayQRPH() {
      return this.selectedPaymentMethodType === "mosaicpay_qrph";
    },

    hasPaymentSelection() {
      return this.selectedPaymentMethodType === "epayment" || this.isMosaicPayQRPH;
    },

    hasConfirm() {

      // Skip the "Can Print QR Code?" printer toggle check for MosaicPay QRPH,
      // allowing confirmation as long as a payment selection has been made.
      if (this.hasPaymentSelection && this.isMosaicPayQRPH) {
        return true;
      }

      if (this.hasPaymentSelection && !this.canPrintQrCode) {
        return false;
      }

      return true;
    },

    hasSecondaryConfirm() {
      return this.hasPaymentSelection && !this.isMosaicPayQRPH;
    },

    confirmLabel() {
      if (this.hasPaymentSelection && this.isMosaicPayQRPH) {
        return "Generate QR";
      }

      if (this.hasPaymentSelection) {
        return "Generate and Print QR";
      }

      return "Add Payment";
    },

    secondaryConfirmLabel() {
      return "Generate QR";
    },

    confirmDisabled() {
      if (this.isMosaicPayQRPH) {
        return false;
      }

      if (!this.hasPaymentSelection) {
        return false;
      }

      if (!this.selectedEPayment) {
        return true;
      }

      if (!this.paymentAmount) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.fetchPaymentMethods();
  },

  watch: {
    preselectedPaymentMethod: {
      handler() {
        if (this.preselectedPaymentMethod) {
          this.usePreselectedMethod();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchPaymentMethods() {
      this.paymentMethods = [];
      try {
        const response = await getPaymentMethods();
        this.paymentMethods = response.data
          .filter((p) => p.brand_id == this.activeOrder.brandId)
          .map((p) => ({ ...p, name: p.payment_name, type: p.field_type }));
      } catch (e) {
        console.error(e);
      }
    },

    usePreselectedMethod() {
      this.selectedPaymentMethod = this.preselectedPaymentMethod;
    },

    setSelectedPaymentMethod(method) {
      this.selectedPaymentMethod = method;
    },

    truncateNumber(num, places = 2) {
      return parseFloat(parseFloat(num).toFixed(places));
    },

    addQuickAmount(amount) {
      if (amount === "exact") {
        this.paymentAmount = this.truncateNumber(this.displayedPayable);
      } else {
        this.paymentAmount = Number(this.paymentAmount) + Number(amount);
      }
    },

    clearAmount() {
      this.paymentAmount = 0;
    },

    appendAmount(digit) {
      if (this.paymentAmount == 0) {
        this.paymentAmount = digit;
      } else {
        this.paymentAmount = `${this.paymentAmount}${digit}`;
      }
    },

    unappendAmount() {
      const chars = Array.from(String(this.paymentAmount));
      chars.pop();
      this.paymentAmount = chars.join("");
    },

    addDecimalPoint() {
      if (this.paymentAmount.indexOf(".") === -1) {
        this.paymentAmount = `${this.paymentAmount}.`;
      }
    },

    validateCustomPayment() {
      console.log(`paymentAmount: ${this.paymentAmount}`);
      console.log(`displayedPayable: ${this.displayedPayable}`);
      console.log(`remainingPayable: ${this.remainingPayable}`);

      const roundedDisplayedPayable = Math.round(this.displayedPayable * 100) / 100;

      if (roundedDisplayedPayable == 0) {
        this.$customSwal({
          icon: 'warning',
          title: "No Remaining Balance",
          text: "There is no balance to pay for this transaction.",
          confirmButtonText: "OK",
          className: 'center-btn',
        });

        return false;
      }

      if (this.paymentAmount > roundedDisplayedPayable) {
        this.$customSwal({
          icon: 'warning',
          title: "Invalid tendered amount",
          text: "Tender must not exceed bill amount for non-cash payments.",
          confirmButtonText: "OK",
          className: 'center-btn',
        });

        return false;
      }

      if (this.paymentAmount < 20) {
        this.$customSwal({
          icon: 'warning',
          title: "Minimum Transaction Required",
          text: "To proceed with your transaction, the minimum amount must be at least 20.00 PHP.",
          confirmButtonText: "OK",
          className: 'center-btn',
        });

        return false;
      }

      return true;
    },

    savePayment() {
      if (!this.selectedPaymentMethodType) {
        this.$swal.warning("Please select a payment method.");
        return;
      }

      if (
        this.paymentAmount <= 0 &&
        this.selectedPaymentMethod != "Complimentary"
      ) {
        this.$swal.warning("Please enter a valid payment amount.");
        return;
      }

      const paymentAmount = Number(this.paymentAmount);
      const exactAmount = Number(this.displayedPayable);
      const normalizedExactAmount = Math.min(paymentAmount, exactAmount);
      const change = paymentAmount - normalizedExactAmount;

      const sqlitePayload = OFFLOAD.sqliteOffloadReceipt
        ? { id: this.selectedPayment.id }
        : {};

      const payload = {
        ...sqlitePayload,
        amount: paymentAmount, //tendered amount
        method: this.selectedPaymentMethod,
        type: this.selectedPaymentMethodType,
        exact_amount: normalizedExactAmount.toFixed(2), //exct amount to pay
        change: Math.max(0, change.toFixed(2)), // if change is negative value will set it to 0, scenarios for multiple payments
      };

      if (
        this.selectedPaymentMethod.toLowerCase() != "cash" &&
        this.selectedPaymentMethodType !== "voucher" &&
        Number(this.paymentAmount) > Number(this.displayedPayable).toFixed(2)
      ) {
        this.$swal.warning(
          "Invalid tendered amount",
          "Tender must not exceed bill amount for noncash payments!"
        );
        return;
      }

      if (this.selectedPaymentMethodType === "card") {
        if (!this.customerName) {
          this.$swal.warning("Please enter customer name.");
          return;
        }

        if (!this.cardNumber) {
          this.$swal.warning("Please enter card number.");
          return;
        }

        if (!this.approvalCode) {
          this.approvalCode = '00000';
        }

        if (this.cardNumber.length < 4) {
          this.$swal.warning(
            "Please enter the last 4 digits of the card number."
          );
          return;
        }

        if (this.approvalCode.length < 5) {
          this.$swal.warning("Please enter a valid approval code.");
          return;
        }

        payload.customerName = this.customerName;
        payload.cardNumber = this.cardNumber;
        payload.approvalCode = this.approvalCode;
      }

      if (this.selectedPaymentMethodType === "check") {
        if (!this.approvalCode) {
          this.approvalCode = '00000';
        }
        payload.approvalCode = this.approvalCode;
      }

      this.$emit("savePayment", payload);
      this.$nextTick(() => this.resetValues());
    },

    confirm() {
      if (!this.hasPaymentSelection) {
        this.savePayment();
        return;
      }

      if (this.isMosaicPayQRPH) {
        this.generateMosaicPayQRPhCode(false);
        return;
      }

      this.generatePaymentQRInfo(true);
    },

    secondaryConfirm() {
      if (this.hasPaymentSelection) {
        this.generatePaymentQRInfo();
      }
    },

    generateMosaicPayQRPhCode(print = false) {
      const isValid = this.validateCustomPayment();
      if (!isValid) {
        return;
      }

      console.log('generating MosaicPay QRPH Code..');
      console.log(`selectedPaymentMethod: ${this.selectedPaymentMethod}`);
      console.log(`selectedPaymentMethodType: ${this.selectedPaymentMethodType}`);

      this.$emit("generateMosaicPayQRPhCode", {
        amount: this.paymentAmount,
        print
      });

      this.$nextTick(() => this.resetValues());
    },

    generatePaymentQRInfo(print = false) {
      if (
        Number(this.paymentAmount) > Number(this.displayedPayable).toFixed(2)
      ) {
        this.$swal.warning(
          "Invalid tendered amount",
          "Tender must not exceed bill amount for non-cash payments!"
        );
        return;
      }

      this.$emit("generatePaymentQRInfo", {
        payment: this.selectedEPayment,
        amount: this.paymentAmount,
        print,
      });

      this.$nextTick(() => this.resetValues());
    },

    resetValues() {
      this.paymentAmount = 0;
      this.cardNumber = "";
      this.customerName = "";
      this.approvalCode = "";

      if (this.$refs?.epaymentSelector?.selelectedPayment) {
        this.$refs.epaymentSelector.selelectedPayment = null;
      }
    },

    reset() {
      if (this.$refs?.epaymentSelector?.selelectedPayment) {
        this.$refs.epaymentSelector.selelectedPayment = null;
      }
    },

    inputNumbersOnly(evt) {
      this.$filters.inputNumbersOnly(evt);
    },

    inputNonSpecialCharacters(evt) {
      this.$filters.inputNonSpecialCharacters(evt);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-details-form {
  .total-value {
    h3 {
      color: #0052cc;
    }

    h6 {
      font-size: 20px;
      color: #9da6af;
    }
  }
}

.payment-tabs {
  border: 0 !important;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  a {
    border-radius: 8px;
    background-color: #fff;
    color: #4a5059;
    font-weight: 400;
    border: 0 !important;
    box-shadow: 0px 8.8100004196167px 21.149999618530273px 0px #0052cc1a;
    width: 48%;
    text-align: center;
    font-size: 15px;
  }

  a.active {
    background-color: #0052cc !important;
    color: #fff !important;
  }
}

button.quick-cash-amount-button,
.custom-payment button {
  background-color: #fff !important;
  border: solid 1px #dfecff !important;
  color: #0052cc !important;
  font-weight: 400 !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 515px) {
  .custom-payment button {
    font-size: 14px;
  }
}

div.payment-methods-container {
  gap: 16px;
}

div.payment-method-card i {
  font-size: 2rem;
  color: var(--secondary);
}
h6 {
  font-weight: bold;
}
span.sm-descriptive-text {
  color: #686868;
  font-size: 12px;
  font-style: italic;
  line-height: 15px;
  padding-left: 13px;
  padding-top: 3px;
  display: inline-block;
}

button.quick-cash-amount-button:disabled {
  background-color: #e9ecef !important;
  color: #adb5bd !important;
  border: none !important; /* Remove border */
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 0.7; /* Optional: to make it visually distinct */
}

</style>
