<template>
  <div class="m-page-title">
    <i class="fa mr-2" :class="icon" v-if="icon" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "MPageTitle",

  props: {
    title: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
    },
  },
};
</script>

<style scoped>
.m-page-title {
  color: #454545;
}

.m-page-title i {
  font-size: 14px;
}

.m-page-title span {
  font-size: 16px;
  font-weight: 600;
}
</style>
