<template>
  <Popper
    :show="posStatus.show"
    class="pos-status"
    :class="classes + ' pr-1'"
    arrow
    placement="top"
    @open:popper="setShowBlocker(true)"
    @close:popper="setShowBlocker(false)"
  >
    <font-awesome-icon :icon="icon" @click="showStatus" />

    <template #content>
      <h3>Details</h3>

      <div class="description mb-2">
        <template v-if="posStatus.status === posDateStatus.MATCHED">
          The POS date & Calendar date is matched
        </template>

        <template v-if="posStatus.status === posDateStatus.UNMATCH">
          The POS date & Calendar date is not matched
        </template>

        <template v-if="posStatus.status === posDateStatus.OVER">
          The <strong>POS DATE</strong> & <strong>CALENDAR DATE</strong> does
          not match OR you're trying to
          <strong>operate outside the OPERATING HOURS</strong>. Please check
          Mandatory Day End settings.
        </template>
      </div>

      <font-awesome-icon @click="hideStatus" icon="times" class="close" />

      <div class="popper-content">
        <div><strong>POS DATE:</strong> {{ posDate }}</div>
        <div><strong>CALENDAR DATE:</strong> {{ currentDate }}</div>
        <div>
          <template v-if="operatingHours">
            <strong>OPERATING HOURS:</strong>
            {{ operatingHours.startTime }} -
            {{ operatingHours.endTime }}
          </template>

          <template v-else>
            <div class="mt-2">
              <strong> Warning: No operating hours set!</strong>
            </div>
          </template>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Popper from "vue3-popper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { POS_DATE_STATUSES } from "@/spa/constants";

import {
  faExclamationCircle,
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

library.add(faExclamationCircle, faCheckCircle, faTimes);

export default {
  name: "MPosStatus",

  data() {
    return {
      show: false,
      posDate: moment(sessionStorage.getItem('posDate') ?? tempPosDate).format("MMM DD, YYYY"),
      currentDate: moment().format("MMM DD, YYYY"),
      operatingHours: tempOperatingHours,
      icon: tempPosAndCalendarDateMatches
        ? "check-circle"
        : "exclamation-circle",
      classes: tempPosAndCalendarDateMatches ? "icon-green" : "icon-red" + 'pr-1',
      posDateStatus: POS_DATE_STATUSES,
    };
  },

  components: { Popper },

  computed: {
    ...mapState("global", ["posStatus"]),
  },

  methods: {
    ...mapMutations("global", ["setShowBlocker", "setPosStatus"]),

    showStatus() {
      if (tempPosAndCalendarDateMatches) {
        this.setPosStatus({ show: true, status: POS_DATE_STATUSES.MATCHED });
        return;
      }

      this.setPosStatus({ show: true, status: POS_DATE_STATUSES.UNMATCH });
    },

    hideStatus() {
      this.setPosStatus({ ...this.posStatus, show: false });
    },
  },

  mounted() {
    this.setShowBlocker(false);

    if (!tempPosAndCalendarDateMatches) {
      this.setPosStatus({ show: true, status: POS_DATE_STATUSES.UNMATCH });
    }
  },
};
</script>

<style scoped>
.pos-status {
  margin: 0;
  cursor: pointer;
}

.icon-green {
  color: #51dc6b;
}

.icon-red {
  color: red;
}

.description {
  font-size: 18px;
}
</style>
