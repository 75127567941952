<template>
  <li class="list-group-item py-4">
    <div class="d-flex align-items-start justify-content-between">
      <h4>{{ heading }}</h4>

      <div class="d-flex">
        <button class="btn btn-danger mr-4" @click="$emit('remove', printer)" v-if="!hideRemove">
          Remove
        </button>
        <button class="btn btn-primary" @click="$emit('testPrint', printer)">
          Test Print
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Printer Location</label>
          <input type="text" class="form-control" v-model="printer.printer_location"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Printer Model</label>
          <select class="custom-select" v-model="printer.printer_model_id">
            <option v-for="model in PRINTER_MODELS" :value="model.id">{{ model.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Paper Width</label>
          <select class="custom-select" v-model="printer.paper_width">
            <option v-for="pwidth in PAPER_WIDTHS" :value="pwidth">{{ pwidth }}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="!!printer.can_print_kot"
              @input="printer.can_print_kot = $event.target.checked ? 1 : 0">
            Can Print KOT?
          </label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="!!printer.can_print_bill"
              @input="printer.can_print_bill = $event.target.checked ? 1 : 0">
            Can Print Bill?
          </label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="!!printer.can_print_receipt"
              @input="printer.can_print_receipt = $event.target.checked ? 1 : 0">
            Can Print Receipts?
          </label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="!!printer.can_print_z_report"
              @input="printer.can_print_z_report = $event.target.checked ? 1 : 0">
            Can Print Reports?
          </label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="!!printer.can_print_qr_code"
              @input="printer.can_print_qr_code = $event.target.checked ? 1 : 0">
            Can Print QR Codes?
          </label>
        </div>
      </div>

      <div class="col-12 mt-3">
        <label>Categories</label>
      </div>
      <div class="col-4" v-for="category in productCategories">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox"
              :checked="printer.hasCategory(category)"
              @input="printer.setCategoryActive(category, $event.target.checked)" />
            {{ category.name }}
          </label>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import {PRINTER_MODELS} from '@/local_printer_config_constants';

export default {
  data() {
    return {
      PRINTER_MODELS,
      PAPER_WIDTHS: ['76mm', '80mm'],
    };
  },
  emits: ['remove', 'testPrint'],
  methods: {
  },
  computed: {
    heading() {
      let label = this.printer.ip_address;

      if (label === 'LOCAL-BLUETOOTH') {
        label = 'Bluetooth Printer';
      } else if (label === 'LOCAL-USB') {
        label = 'USB Printer';
      }

      return label;
    }
  },
  props: {
    printer: Object,
    productCategories: Array,
    hideRemove: Boolean,
  }
}
</script>
<style scoped>
.list-group-item + .list-group-item {
  border-top-width: 1px;
}
</style>
