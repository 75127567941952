<script>
import {OFFLOAD, USE_NEW_AMOUNT_DUE_FORMULA} from "@/spa/constants";

export default {
  data() {
    return {
      grossSales: 0,
    }
  },
  methods: {
    async getGrossSales(orderId) {
      if (!OFFLOAD.sqliteOffloadReceipt || !USE_NEW_AMOUNT_DUE_FORMULA) {
        return;
      }

      const rb = new ReceiptBridge();
      const receipt = await rb.getReceiptByLocalId(orderId);
      this.grossSales = receipt?.gross_sales || 0;
    },
  }
}
</script>
