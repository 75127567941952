<template>
    <base-modal
        v-bind="$attrs"
        @confirm="toggleConfirm"
        min-width="0"
        max-width="800"
        min-height="100px"
        width="100%"
        >
        <template v-slot:title>Item Action</template>

        <hr />

        <div v-if="item" class="d-flex justify-content-between align-items-center">
            <div class="item-details col-8">
                <h2 class="item-name" v-text="item.product.product_name" />
                <h4 class="item-modifiers" v-text="modifiersString" />
            </div>

            <div class="item-price col-4">
                ₱{{ this.$filters.formatPrice(getTotalItemRawPrice(item.quantity)) }}
            </div>
        </div>

        <div class="row tab-modifier tab-billopt m-2 item-orientation">
            <div class="col-lg-4 col-12 transact-box-container">
                <div class="transact-box billmod" id="void-btn" @click="$emit('voidItem', item)">
                    <i class="transact-box-top fas fa-trash"></i>
                    <svg version="1.0" width="60.000000pt" height="60.000000pt" viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                        <path d="M1473 5105 c-75 -21 -142 -57 -196 -107 -26 -24 -204 -221 -397 -438`
                        -362 -407 -394 -450 -419 -560 -7 -30 -11 -584 -11 -1650 0 -1761 -3 -1665 60
                        -1790 39 -77 133 -171 210 -210 124 -63 69 -60 1265 -60 l1091 0 35 -36 c87
                        -92 255 -184 409 -226 122 -32 328 -32 450 0 96 26 225 84 297 133 150 102
                        284 272 347 440 57 151 74 364 42 516 -69 321 -295 581 -598 688 l-78 28 0
                        1428 0 1428 -23 74 c-55 172 -187 296 -364 342 -83 22 -2040 21 -2120 0z
                        m2129 -212 c68 -31 131 -99 157 -167 l21 -57 0 -1403 0 -1403 -97 -6 c-223
                        -14 -430 -108 -591 -268 -158 -157 -249 -348 -271 -572 -18 -176 9 -317 96
                        -515 4 -10 -194 -12 -1018 -10 l-1024 3 -47 23 c-60 30 -120 90 -150 150 l-23
                        47 -3 1613 -2 1612 338 0 338 0 52 26 c57 28 96 69 123 129 17 36 19 74 19
                        427 0 299 3 387 13 391 6 3 464 5 1017 4 l1005 -2 47 -22z m-2287 -720 c-3
                        -13 -15 -25 -28 -28 -12 -3 -131 -4 -264 -3 l-243 3 268 301 267 301 3 -276
                        c1 -152 0 -286 -3 -298z m2640 -2543 c343 -109 549 -421 513 -778 -42 -421
                        -451 -722 -865 -636 -153 31 -264 92 -378 208 -140 141 -206 303 -206 506 0
                        205 66 365 211 511 112 113 210 168 365 205 88 20 270 12 360 -16z"/>
                        <path d="M1424 3580 c-30 -12 -64 -58 -64 -87 0 -31 17 -63 44 -85 19 -16 80
                        -18 849 -18 l829 0 29 29 c51 51 33 137 -33 160 -38 13 -1622 14 -1654 1z"/>
                        <path d="M1391 2819 c-41 -41 -42 -86 -2 -131 l29 -33 804 -3 c442 -2 817 0
                        834 3 81 14 112 108 55 166 l-29 29 -830 0 -830 0 -31 -31z"/>
                        <path d="M1433 2113 c-34 -7 -73 -59 -73 -98 0 -48 46 -95 94 -95 48 0 82 23
                        96 65 16 48 -8 101 -54 120 -20 8 -37 14 -38 14 -2 -1 -13 -4 -25 -6z"/>
                        <path d="M1833 2113 c-30 -6 -73 -59 -73 -89 0 -33 25 -81 49 -94 13 -6 236
                        -10 646 -10 l627 0 29 29 c57 57 28 149 -52 165 -35 7 -1191 6 -1226 -1z"/>
                        <path d="M1411 1366 c-68 -38 -66 -137 4 -171 29 -13 110 -15 615 -15 634 0
                        633 0 659 56 23 50 3 111 -44 132 -40 18 -1201 16 -1234 -2z"/>
                        <path d="M3434 1296 c-56 -25 -78 -79 -53 -129 6 -12 59 -70 117 -130 l107
                        -107 -115 -115 c-113 -113 -115 -116 -115 -161 0 -60 36 -94 97 -94 39 0 47 6
                        157 115 l116 114 116 -114 c110 -109 118 -115 157 -115 61 0 97 34 97 94 0 45
                        -2 48 -115 161 l-115 115 107 107 c58 60 111 118 117 130 26 51 3 105 -55 129
                        -51 21 -83 2 -204 -120 l-104 -106 -117 115 c-64 63 -121 115 -127 115 -6 0
                        -16 2 -24 5 -7 2 -27 -2 -44 -9z"/>
                        </g>
                    </svg>
                    <div class="transact-box-bottom">
                        <h4>VOID ITEM</h4>
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 transact-box-container" v-if="showItemDiscountButton">
                <div class="transact-box billmod my-2" id="discount-btn" @click="$emit('showItemDiscounts', item)">
                    <i class="transact-box-top fas fa-percentage"></i>
                    <svg version="1.0" width="70.000000pt" height="70.000000pt" viewBox="0 0 512.000000 512.000000"
                     preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                        <path d="M1994 4831 c-71 -32 -119 -94 -204 -261 -43 -85 -88 -164 -99 -174
                        -24 -22 -61 -19 -234 20 -196 45 -298 34 -376 -39 -66 -63 -78 -115 -85 -362
                        -2 -82 -9 -162 -15 -176 -11 -31 -46 -45 -221 -85 -207 -48 -295 -105 -326
                        -211 -25 -87 -12 -142 82 -345 41 -86 64 -149 64 -172 0 -37 -7 -45 -208 -209
                        -124 -102 -177 -207 -153 -304 22 -88 80 -156 236 -277 97 -75 125 -107 125
                        -144 0 -20 -25 -87 -64 -170 -94 -203 -107 -258 -82 -345 31 -106 119 -163
                        326 -211 174 -40 210 -54 221 -84 6 -15 12 -99 15 -187 2 -88 7 -178 10 -200
                        15 -93 70 -167 149 -201 61 -26 167 -21 326 15 164 37 189 38 216 8 11 -12 55
                        -89 96 -172 108 -214 173 -274 298 -275 75 0 162 44 294 151 180 144 168 145
                        365 -11 131 -103 205 -140 280 -140 124 1 189 61 297 275 41 83 85 160 96 172
                        27 30 50 29 215 -8 233 -53 333 -41 411 47 54 62 69 129 75 334 2 91 9 177 14
                        191 12 31 47 45 222 85 205 47 295 106 326 211 25 87 12 142 -82 345 -39 83
                        -64 150 -64 170 0 37 28 69 125 144 156 121 214 189 236 276 24 98 -29 203
                        -153 305 -201 164 -208 172 -208 209 0 23 23 86 64 172 94 203 107 258 82 345
                        -31 106 -112 160 -314 208 -190 45 -222 57 -233 88 -6 14 -12 94 -15 176 -6
                        245 -19 299 -85 362 -78 73 -180 84 -376 39 -173 -39 -210 -42 -234 -20 -11
                        10 -56 89 -99 174 -111 219 -176 279 -300 280 -75 0 -149 -37 -280 -140 -197
                        -156 -185 -155 -365 -11 -132 107 -219 151 -294 151 -33 0 -73 -8 -97 -19z
                        m177 -237 c34 -26 95 -72 135 -104 104 -83 173 -114 254 -114 88 0 146 29 302
                        151 151 117 161 122 189 97 11 -10 54 -85 95 -166 122 -241 190 -296 354 -285
                        47 3 135 17 197 31 122 28 187 33 200 14 4 -7 11 -98 14 -203 11 -338 48 -383
                        377 -464 90 -22 170 -45 178 -52 25 -21 15 -58 -51 -199 -100 -212 -114 -295
                        -65 -390 30 -58 72 -103 185 -194 114 -92 155 -133 155 -156 0 -22 -46 -67
                        -160 -160 -244 -197 -259 -275 -115 -580 66 -141 76 -178 51 -199 -8 -7 -88
                        -30 -178 -52 -179 -44 -262 -81 -309 -138 -49 -59 -61 -116 -68 -326 -3 -104
                        -10 -196 -14 -202 -13 -20 -77 -15 -222 17 -307 68 -382 32 -527 -255 -42 -83
                        -86 -159 -97 -169 -28 -25 -38 -20 -189 97 -156 122 -214 151 -302 151 -88 0
                        -146 -29 -302 -151 -151 -117 -161 -122 -189 -97 -11 10 -54 85 -95 166 -122
                        241 -190 296 -354 285 -47 -3 -135 -17 -197 -31 -122 -28 -187 -33 -200 -13
                        -4 6 -11 98 -14 202 -11 338 -48 383 -377 464 -90 22 -170 45 -178 52 -25 21
                        -15 58 51 199 144 306 127 387 -120 585 -110 87 -155 133 -155 156 0 22 39 61
                        159 158 245 198 260 276 116 581 -67 141 -76 178 -50 200 8 6 87 30 176 52
                        222 54 289 90 333 177 34 67 46 145 46 307 0 177 5 194 60 194 23 0 92 -12
                        153 -26 323 -74 403 -38 548 249 71 141 102 187 125 187 6 0 40 -21 75 -46z"/>
                        <path d="M1945 3457 c-150 -38 -253 -147 -284 -299 -33 -158 54 -325 207 -400
                        60 -30 75 -33 157 -33 80 0 97 4 155 31 83 39 145 101 184 184 27 58 31 75 31
                        155 0 82 -3 97 -33 157 -77 157 -257 245 -417 205z m155 -222 c63 -33 99 -115
                        80 -184 -14 -52 -75 -107 -129 -116 -54 -9 -126 25 -159 75 -30 45 -28 130 6
                        177 41 58 138 81 202 48z"/>
                        <path d="M3255 3399 c-31 -17 -1514 -1501 -1531 -1531 -38 -72 6 -158 81 -158
                        20 0 46 5 58 11 31 16 1515 1498 1533 1531 39 73 -6 158 -83 158 -21 0 -47 -5
                        -58 -11z"/>
                        <path d="M2995 2387 c-113 -38 -191 -105 -239 -207 -28 -58 -31 -75 -31 -155
                        0 -82 3 -97 33 -157 75 -153 242 -240 400 -207 158 32 270 144 302 301 31 156
                        -55 325 -205 399 -75 37 -192 48 -260 26z m165 -219 c66 -34 93 -79 88 -149
                        -7 -94 -66 -149 -161 -149 -58 0 -105 32 -135 92 -32 63 -24 115 25 169 55 60
                        113 72 183 37z"/>
                        </g>
                    </svg>
                    <div class="transact-box-bottom">
                        <h4>DISCOUNT ITEM</h4>
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 transact-box-container" v-if="showMoveItemTable">
                <div class="transact-box billmod my-2" id="move-table-btn" @click="$emit('showMoveItemTables',item)">
                    <i class="transact-box-top fas fa-exchange-alt"></i>         
                    <svg version="1.0" width="70.000000pt" height="70.000000pt" viewBox="0 0 100.000000 100.000000"
                    preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                        fill="#000000" stroke="none">
                        <path d="M22 728 c-8 -10 -5 -48 10 -142 l20 -127 -21 -92 c-16 -69 -18 -92
                        -9 -98 18 -11 26 -2 38 41 l11 40 93 0 93 0 7 -39 c7 -43 15 -54 34 -42 16 10
                        -4 192 -22 203 -6 4 -50 8 -97 8 l-85 0 -22 122 c-22 126 -31 148 -50 126z"/>
                        <path d="M955 730 c-5 -8 -45 -222 -45 -242 0 -5 -40 -8 -89 -8 -59 0 -92 -4
                        -99 -12 -5 -7 -15 -53 -21 -102 -8 -70 -8 -92 2 -98 16 -10 37 19 37 54 l0 28
                        95 0 94 0 11 -40 c12 -44 20 -52 39 -41 9 6 7 27 -10 99 l-21 91 21 124 c20
                        118 20 157 1 157 -5 0 -12 -4 -15 -10z"/>
                        <path d="M232 638 c-8 -8 -9 -15 -1 -25 8 -9 44 -13 130 -13 l119 0 0 -130 0
                        -129 -32 -17 c-52 -26 -60 -35 -47 -50 9 -11 19 -9 55 9 l44 21 44 -21 c36
                        -18 46 -20 55 -9 13 15 5 24 -46 50 l-33 17 0 129 0 130 118 0 c122 0 154 9
                        131 37 -15 18 -519 19 -537 1z"/>
                        </g>
                    </svg>
                    <div class="transact-box-bottom">
                        <h4>MOVE TABLE</h4>
                        <i class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 transact-box-container" v-if="isServiceBtnVisible && isOrderPlaced && item && item.serviceTypeId == activeServiceTypeId && !delivery">
                <div class="transact-box billmod my-2" id="service-type-btn" v-bind:class="{'service-type-btn': serviceTypeBtnActive}">
                    <Service />
                    <i class="transact-box-top fas fa-receipt"></i>    
                    <div class="transact-box-bottom">
                        <div class="d-flex flex-column justify-content-between">
                            <h4>{{ inverseServiceType.toUpperCase() }}</h4>
                            <div class="d-flex order-qty align-items-center justify-content-center">
                                <div class="min-qty change-pax-qty p-0" @click="reduceSelectedQty">-</div>
                                <h3 class="cust-disc-pax-qty px-2 pt-1">{{ selectedQty }}</h3>
                                <div class="plus-qty change-pax-qty p-0" @click="addSelectedQty">+</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import Service from '@/spa/components/icons/service';

import { LineItemMixin } from '@/spa/components/mixins/ComputationMixin';
import { getAllDiscountItems } from '@/spa/services/discount-service';
import { mapState } from 'vuex';
import {ENABLE_DINE_IN_TAKE_OUT_BUTTON, OFFLOAD} from "@/spa/constants";

export default {
    components: {
        BaseModal,
        Service
    },

    mixins: [
        LineItemMixin,
    ],

    props: {
        item: {
            type: [Object, null],
            required: true,
        },
        serviceType: {
            type: String,
            default: 'Dine-in',
        },
        isOrderPlaced: {
            type: Boolean,
            default: false,
        },
        delivery: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedQty: 0,
            serviceTypeBtnActive: false
        }
    },

    computed: {
        ...mapState([
          'activeServiceTypeId'
        ]),

        ...mapState('settings', ['serviceTypes']),

        itemTotalPrice() {
            return this.$filters.formatPrice(this.item.total);
        },

        modifiersString() {
            if (!this.item) return '';

            return [
                ...(this.item.product.forcedMods || []),
                ...(this.item.product.unforcedMods || []),
            ].map(mod => `${mod.quantity || 1} ${mod.modifier_name}`).join(', ');
        },

        inverseServiceType() {
            const serviceType = this.serviceTypes.find(i => i.id == this.item?.serviceTypeId)?.service_name || ''
            return serviceType.includes('Dine-in') ? 'Take-out' : 'Dine-in'
        },

        isServiceBtnVisible() {
            const hasTakeOutAndDineIn = ['Dine-in', 'Take-out'].every(service => this.serviceTypes.map(item => item.service_name).includes(service));
            return ENABLE_DINE_IN_TAKE_OUT_BUTTON && hasTakeOutAndDineIn && this.serviceType.match(/Dine-in|Take-out/)
        },

        showItemDiscountButton() {
          if (this.delivery) {
            return false;
          }

          return !HIDE_ITEM_DISCOUNT_BUTTON;
        },

        showMoveItemTable() {
          if (OFFLOAD.sqliteOffloadPOSFE1300) {
            return false;
          }

          return this.serviceType !== 'Delivery' && !this.serviceType.includes('QSR') && this.isOrderPlaced && !this.delivery;
        },
    },

    methods: {
        addSelectedQty() {
            this.selectedQty += this.selectedQty !== this.item.quantity ? 1 : 0;
        },

        reduceSelectedQty() {
            this.selectedQty -= this.selectedQty > 1 ? 1 : 0;
        },

        toggleConfirm() {
            this.$emit('toggleLineItemAction', this.inverseServiceType, this.item, this.selectedQty);
            this.selectedQty = 0
        }
    },

    watch: {
        selectedQty(value) {
            this.serviceTypeBtnActive = value > 0
        }
    }
}
</script>

<style scoped>
.item-name {
    font-size: 2rem;
    color: #1b75bb;
    font-weight: bold;
}

.item-price {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Exo', sans-serif;
    text-align: right;
}

.transact-box-bottom {
    bottom: 10px !important;
}

.transact-box:hover h3 {
    color: white;
}

.service-type-btn {
    background-color: #1b75bb !important;
}

.service-type-btn .cust-disc-pax-qty {
    color: white !important;
}

.service-type-btn svg path {
    fill: #fff;
}

.service-type-btn .transact-box-bottom h4 {
    color: #fff !important;
}

.transact-box-top {
    display: none;
}

@media (max-width: 992px) {

    .transact-box {
        flex-direction: column;
        justify-content: center;
        padding: 15px;
    }

    .transact-box-container {
       /* display: grid;  */
        justify-content: center;
        margin: 0 0 10px 0;
    }

    .transact-box.billmod {
        width: 100%;
        display: block;
        clear: both;
        height: 100%;
    }

    .transact-box.billmod div i, .transact-box.billmod svg {
        display: none;
    }

    .item-orientation {
        /* display:grid; */
        grid-template-columns: repeat(2, 1fr);
    }
    
    .item-name, .item-modifiers, .item-price {
        font-size: 1rem;
    }

    .transact-box-top {
        padding-top: 10px;
        display: grid;
        font-size: x-large !important;      
    }

    .transact-box-bottom {
        text-align: center;
        padding: 10px 0 0 0;
        margin: 0;
        width: auto;
        position: initial;
    }

    .transact-box-bottom h4, .cust-disc-pax-qty {
        font-size: small !important;
    }

    .min-qty, .plus-qty {
        transform: scale(1);
    }

    
}

</style>
<style>
    /* We need this unscoped */
    .service-type-btn svg path {
        fill: white;
    }
</style>
