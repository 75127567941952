<template>
  <div
    class="m-alert-bar alert d-flex align-items-center"
    :class="[
      dismissible ? 'justify-content-between' : 'justify-content-center',
      `alert-${variant}`,
    ]"
  >
    <div v-if="dismissible" />

    <div>
      <i v-if="icon" class="icon" :class="icon" size="lg"></i>
      <slot />
    </div>

    <div @click.stop="$emit('close')" class="p-1">
      <font-awesome-icon v-if="dismissible" icon="times" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MAlertBar",

  props: {
    message: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      default: null,
    },

    variant: {
      type: String,
      default: "warning",
      validator(value) {
        return ["success", "warning", "grab", "foodpanda", "tabsquare"].includes(value);
      },
    },

    dismissible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.m-alert-bar .icon {
  margin-right: 5px;
}

.alert {
  font-weight: 600;
  border-radius: 0;
  margin-bottom: 0;
  padding: 5px;
  font-size: 0.85rem;
}

.alert-success {
  color: white;
  background-color: #2ec86b;
  border-color: #2ec86b;
}

.alert-success svg {
  color: white;
}

.alert-warning {
  color: black;
  background-color: #fbbf22;
  border-color: #fbbf22;
}

.alert-warning svg {
  color: black;
}

.alert-grab {
  color: white;
  background-color: #00b45e;
  border-color: #00b45e;
}

.alert-foodpanda {
  color: white;
  background-color: #d70f64;
  border-color: #d70f64;
}

.alert-tabsquare {
  color: white;
  background-color: #ff6b00;
  border-color: #ff6b00;
}
</style>
