import { mapState, mapGetters } from 'vuex';
import { omit, mapValues, pick } from 'lodash';

import { VAT_EXCL, ZERO_RATED } from '@/vue/helper/discount';
import { HAS_VAT_DISCOUNT, VAT_BEFORE_DISCOUNT } from '@/vue/helper/discount';
import { OFFLINE_FORCE_EMPLOYEE_NO_SC, ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE } from '@/spa/constants';

const EMPLOYEE_DISCOUNT_REGEX = /.*employee.*/i;

export function getNet(rawPrice, taxScCoefficient, discount = null) {
    if(discount && discount.is_vat_based) {
        return LineItemMixin.methods.computeNetBased(rawPrice, taxScCoefficient, discount);
    }

    if(discount && !discount.is_vat_based) {
        return LineItemMixin.methods.computeGrossBased(rawPrice, taxScCoefficient, discount);
        }

    return rawPrice / (taxScCoefficient || 1);
}

const LineItemMixin = {
    props: {
        item: {
            type: [Object, null],
            required: true,
        },

        serviceType: {
            type: String,
            default: 'Dine-in',
        },
    },
    computed: {
        ...mapState([
            'activeServiceTypeId',
            'activeChannelId',
        ]),

        ...mapState('settings', ['serviceTypes']),

        ...mapGetters([
            'activeOrder',
        ]),

        net() {
            return getNet(this.itemRawPrice, this.totalPercentage, this.item.discount);
        },

        noDiscountNet() {
            return this.itemRawPrice / (this.totalPercentage || 1);
        },

        actualDiscountAmount() {
            if (!this.item.discount) return 0;

            const discountIsVatBased = this.item.discount.is_vat_based;
            const basePrice = discountIsVatBased ? this.noDiscountNet : this.itemRawPrice;

            return Number(this.item.discount.discountRate) * basePrice * this.item.discount.discountPax;
        },

        vatExemptSales() {
            if (this.item.isVoided) return 0;

            if (ZERO_RATED.includes(this.item.discount?.discount_name)) {
                return 0;
            } else if(Number(this.vatConfig?.tax_rate) == 1) {
                const undiscountedQty = this.item.quantity - this.discountQty;
                return (this.net * this.discountQty) + (this.noDiscountNet * undiscountedQty);
            } else if (VAT_EXCL.includes(this.item.discount?.discount_name)) {
                return this.item.discount.netSales;
            } else {
                return 0;
            }
        },

        zeroRatedSales() {
            if (this.item.isVoided) return 0;
            if (!this.item.discount) return 0;

            const paxDiscountAdjustedTotal = (this.itemRawPrice * this.item.quantity) * (this.item.discount.discountPax / this.item.quantity);
            const paxDiscountAdjustedVat = (this.vat * this.item.quantity) * (this.item.discount.discountPax / this.item.quantity);

            if (ZERO_RATED.includes(this.item.discount.discount_name)) {
                return paxDiscountAdjustedTotal - paxDiscountAdjustedVat - this.actualDiscountAmount;
            } else {
                return 0;
            }
        },

        scIsAfterDiscount() {
            return this.scConfig.is_after_discount ?? 0;
        },

        scIsNetBased() {
            return this.scConfig.is_net_based;
        },

        isScInclusive() {
            return this.scConfig.tax_type === "Tax Inclusive" ? 1 : 0;
        },

        scConfig() {
            const itemServiceType = this.serviceTypes.find(i => i.id == this.item.serviceTypeId)?.service_name || ''

            const serviceType = itemServiceType || this.activeOrder.serviceType || this.serviceType;
            return Object.values(this.item.product.tax_data).find(
                td => td.tax_name === 'Service Charge'
                    && td.service_type === serviceType
            ) || {
                tax_rate: '0.00',
            };
        },

        otherChargesConfig() {
            const itemServiceType = this.serviceTypes.find(i => i.id == this.item.serviceTypeId)?.service_name || '';

            const serviceType = itemServiceType || this.activeOrder.serviceType || this.serviceType;
            return Object.values(this.item.product.tax_data).filter(
                td => td.tax_name !== 'Service Charge'
                    && td.tax_name !== 'VAT'
                    && td.service_type === serviceType
            );
        },

        otherCharges() {
            return this.otherChargesConfig.reduce((acc, config) => {
                const chargeAmount = config.is_net_based == 1
                    ? this.net * config.tax_rate
                    : (this.itemRawPrice * this.item.quantity) * config.tax_rate;

                acc[config.tax_name] ??= 0;
                acc[config.tax_name] += chargeAmount;

                return acc;
            }, {}) ?? {};
        },

        otherChargesFixed() {
            return this.otherChargesConfig.reduce((acc, config) => {
                if (!config.fix_amount) return acc;
                acc[config.tax_name] ??= 0;
                acc[config.tax_name] += config.fix_amount;

                return acc;
            }, {}) ?? {};
        },

        discountQty() {
            return this.item.discount?.discountPax ?? 0;
        },

        hasEmployeeDiscount() {
            if (!this.item.discount) return false;
            return EMPLOYEE_DISCOUNT_REGEX.test(this.item.discount.discount_name);
        },

        hasNoScDiscount() {
            return this.item.discount?.has_service_charge == 0
                || (this.hasEmployeeDiscount && OFFLINE_FORCE_EMPLOYEE_NO_SC);
        },

        noScDiscountQty() {
            return this.hasNoScDiscount ? this.discountQty : 0;
        },

        preScTotal() {
            let total;
            if (this.scIsNetBased || this.isScInclusive) {
                total = this.scIsAfterDiscount ? this.net : this.noDiscountNet;
            } else {
                const discountPerPax = this.discountQty ? this.actualDiscountAmount / this.discountQty : 0;
                const discIsVatBased = this.item.discount?.is_vat_based;
                const vatRate = discIsVatBased ? Number(this.vatConfig.tax_rate) : 1;
                total = this.scIsAfterDiscount ? (this.itemRawPrice / vatRate) - discountPerPax : this.itemRawPrice;
            }

            return total;
        },

        serviceCharge() {
            if (this.noScDiscountQty == this.item.quantity) return 0;

            let scRate = this.scConfig.tax_rate;
            if (this.noScDiscountQty) {
                scRate *= (this.item.quantity - this.noScDiscountQty) / this.item.quantity;
            }

            return this.preScTotal * scRate;
        },

        vatConfig() {
            return Object.values(this.item.product.tax_data).find(
                td => td && td.tax_name === 'VAT'
            );
        },

        totalPercentage() {
            let percentage = 0;
            if (this.vatConfig) {
                percentage += Number(this.vatConfig.tax_rate);
            }

            if (this.scConfig && this.scConfig.tax_type === 'Tax Inclusive') {
                percentage += Number(this.scConfig.tax_rate);
            }

            return percentage;
        },

        vat() {
            if (!this.vatConfig || (Number(this.vatConfig.tax_rate)) == 0) {
                return 0;
            }

            return this.noDiscountNet * (Number(this.vatConfig.tax_rate) - 1);
        },

        itemRawPrice() {
            let product = this.item.product;
            if(product.isSecondItem) {
                if(product.hasModifier) {
                    let newPrice = 0;
                    if(product.promo_type === 'BOGO') {
                        if (product.unforcedMods) {
                            product.unforcedMods.forEach(mod => {
                                newPrice += (Number(mod.mod_price) * Number(mod.quantity));
                            });
                        }
                    } else {
                        newPrice += Number(this.item.activePrice);
                        if (product.forcedMods) {
                            product.forcedMods.forEach(mod => {
                                newPrice += Number(mod.mod_price) * Number(mod.quantity);
                            });
                        }
                        
                        newPrice -= product.promo_type === 'By Percentage' ? (newPrice * (product.promo_percentage/100)) : 0;
                        
                        if (product.unforcedMods) {
                            product.unforcedMods.forEach(mod => {
                                newPrice += (Number(mod.mod_price) * Number(mod.quantity));
                            });
                        }

                        if(product.promo_type === 'By Amount') {
                            newPrice -= product.promo_amount;
                            newPrice = newPrice < 0 ? 0: newPrice;
                        }
                    }
                    return newPrice;
                } else {
                    if(product.promo_type === 'BOGO') {
                        return 0;
                    } else {
                        return this.item.activePrice - (product.promo_type === 'By Amount' ? product.promo_amount : (this.item.activePrice * (product.promo_percentage/100)));
                    }
                }
            }

            if (!this.item) return 0;
            if (this.item.isVoided) return 0;

            const activeServiceTypeId = this.item.serviceTypeId || this.activeOrder.serviceTypeId || this.activeServiceTypeId;
            const activeChannelId = this.item.channelTypeId || this.activeOrder.channelTypeId || this.activeChannelId;

            let basePrice = 0;

            if(this.item.product.product_tag == "Open") {

                basePrice = this.item.activePrice;

            } else {

                if(!this.item.product?.pricings) return 0;

                let pricings = this.item.product.pricings.filter(p => p.service_type_id == activeServiceTypeId);
                if (activeChannelId) {
                    pricings = pricings.filter(p => p.service_channel_id == activeChannelId);
                }

                basePrice = pricings.length ? Number(pricings[0].prod_price) : 0;
                if (this.item.product.forcedMods) {
                    this.item.product.forcedMods.forEach(mod => {
                        basePrice += Number(mod.mod_price) * Number(mod.quantity);
                    });
                }

                if (this.item.product.unforcedMods) {
                    this.item.product.unforcedMods.forEach(mod => {
                        basePrice += (Number(mod.mod_price) * Number(mod.quantity));
                    });
                }

            }


            return basePrice;
        },

        formattedAmounts() {

            const nonNumeric = ['kotNum', 'isScInclusive', 'scIsAfterDiscount', 'otherCharges', 'otherChargesFixed'];

            return {

                ...mapValues(omit(this.parsedAmounts, nonNumeric), p => p.toFixed(2)),

                ...pick(this.parsedAmounts, nonNumeric),

            };

        },

        parsedAmounts() {
            const beforeSc = this.item.discount
                ? this.net * this.item.quantity
                : this.itemRawPrice * this.item.quantity;

            let vatExc = ['Senior Citizen Discount', 'Diplomat Discount', 'PWD'];

            let vat = this.vat * this.item.quantity;
            let net = this.net * this.item.quantity;
            let serviceCharge = this.serviceCharge * this.item.quantity;

            let oldVat = vat;
            let vatExemptSales = this.vatExemptSales;

            //check if item has discount. do recomputations
            if(this.item.discount) {

                //net and sc computation
                if(this.item.quantity != this.item.discount.discountPax) {
                    //create net for the not discounted qty
                    let undiscountedNet = (this.itemRawPrice / (this.totalPercentage || 1)) * Math.abs(this.item.quantity - this.item.discount.discountPax);

                    //check open discount by amount
                    if(this.item.discount.isDiscountByAmount) {
                        net = ((this.itemRawPrice * this.item.discount.discountPax) - this.item.discount.discountAmount) / (this.totalPercentage || 1) + undiscountedNet;
                    } else {
                        net = (this.net * this.item.discount.discountPax) + undiscountedNet;
                    }

                    if (this.scIsAfterDiscount) {
                        let discountedSc = this.hasNoScDiscount ? 0 : this.serviceCharge * this.item.discount.discountPax;
                        serviceCharge = discountedSc + (undiscountedNet * Number(this.scConfig.tax_rate));
                    }
                }

                //vat computation
                if (!VAT_BEFORE_DISCOUNT.includes(this.item.discount.discount_name)) {
                    vat = vatExc.includes(this.item.discount.discount_name)
                        ? this.vat * (this.item.quantity - this.item.discount.discountPax)
                        : net * (Number(this.vatConfig.tax_rate) - 1);
                } else {
                    vat = oldVat;
                }

                // if medal of valor and regular vat, add discount vat
                if (HAS_VAT_DISCOUNT.includes(this.item.discount.discount_name) && oldVat > 0) {
                    vat += this.item.discount.discountAmount * (Number(this.vatConfig.tax_rate) - 1);
                }

                // override for caramia requirement
                if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE) {
                    const discountPaxRatio = this.item.discount.discountPax / this.item.quantity;
                    if (this.item.discount?.max_discount_amount > 0) {
                        // TODO: need to support SC inclusive locations
                        const discountNameIsVatExclusive = VAT_EXCL.includes(this.item.discount?.discount_name);
                        const discountRate = parseFloat(this.item.discount?.discount_rate);
                        const discountedNet = (this.item.discount?.max_discount_amount / (Number(this.vatConfig.tax_rate?? 1.12))) * discountPaxRatio;
                        let undiscountedNet = ((this.itemRawPrice * this.item.quantity) / (Number(this.vatConfig.tax_rate?? 1.12))) - discountedNet;

                        const discountAmount = discountedNet * discountRate;
                        const vatExempt = discountNameIsVatExclusive ? (discountedNet - discountAmount) : 0;

                        net = undiscountedNet + vatExempt;
                        vat = undiscountedNet * (Number(this.vatConfig.tax_rate?? 1.12) - 1);
                        vatExemptSales = discountNameIsVatExclusive ? discountedNet : 0;

                        this.item.discount.discountAmount = discountAmount;
                    }
                }

            } else if(this.item.comp && !this.item.comp.has_service_charge){ //else check if item has comp and not has service charge
                serviceCharge = 0;
            }

            const otherChargesTotal = Object.values(this.otherCharges).reduce((acc, oc) => acc + oc, 0) ?? 0;

            return {
                kotNum: this.item.kot || '',
                vat,
                serviceCharge,
                net,
                total: serviceCharge + vat + net + otherChargesTotal,
                beforeSc,
                rawPrice: this.itemRawPrice * this.item.quantity,
                vatExemptSales,
                zeroRatedSales: this.zeroRatedSales,
                originalVat: oldVat,
                scIsAfterDiscount: this.scIsAfterDiscount,
                isScInclusive: this.isScInclusive,
                otherCharges: this.otherCharges,
                otherChargesFixed: this.otherChargesFixed,
                otherChargesTotal,
                scIsNetBased: this.scConfig?.is_net_based ?? 0,
            };
        },
    },
    methods: {
        getTotalItemRawPrice(qty) {
            return qty * this.itemRawPrice;
        },

        computeNetBased(rawPrice, taxScCoefficient, discount) {
            const net = rawPrice / (taxScCoefficient || 1);
            const discountAmount = discount.max_discount_amount ? (Number(discount.discountAmount) / discount.discountPax) : Number(discount.discountRate) * net;

            return net - discountAmount;
        },

        computeGrossBased(rawPrice, taxScCoefficient, discount) {
            let net = rawPrice;

            const discountAmount = this.shouldDiscountByAmount(discount) ? (Number(discount.discountAmount) / discount.discountPax) : Number(discount.discountRate) * net;

            net = net - discountAmount;
            net = net / (taxScCoefficient || 1);

            return net;
        },

        shouldDiscountByAmount(discount) {
            // if discount has discount ceiling
            if(discount.max_discount_amount) {
                return true;
            }

            // if discount is by rate
            if(discount.discountRate) {
                return false;
            }

            // if discount is open discount it will be computed by rate no matter if it is by amount or rate
            if(discount.discount_name === 'Open Discount') {
                return false;
            }

            // if discount is by amount
            return true;
        }
    },
}

export {
    LineItemMixin,
}