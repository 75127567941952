<template>
  <svg
    width="69"
    height="70"
    viewBox="0 0 69 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.9152 51.2528C39.4776 51.2528 43.2748 47.9595 43.2748 43.9099H26.5652C26.5652 47.9595 30.1042 51.2528 34.9248 51.2528H34.9152Z"
      fill="#D70F64"
    />
    <path
      d="M63.0061 16.3C65.0339 14.7778 66.0477 12.2408 66.0477 9.45495C66.0477 4.39056 62.0018 0.589879 56.9326 0.589879C53.6519 0.589879 50.859 2.11207 49.3477 4.64905C44.7753 2.42601 39.7488 1.29851 34.6659 1.35576C29.5935 1.36759 24.5853 2.49171 19.9936 4.64905C18.4633 2.10249 15.6895 0.589879 12.3993 0.589879C10.0185 0.564224 7.72423 1.48179 6.01668 3.14248C4.30914 4.80318 3.32687 7.07227 3.28409 9.45495C3.30528 10.742 3.58556 12.0115 4.10817 13.1876C4.63077 14.3638 5.38497 15.4223 6.32567 16.3C2.4824 21.8828 0.448727 28.5142 0.50076 35.2938C0.503294 44.3634 4.10333 53.061 10.5097 59.4751C16.9161 65.8891 25.6046 69.4949 34.6659 69.5C43.7255 69.4949 52.4126 65.8905 58.8187 59.4785C65.2248 53.0665 68.826 44.3714 68.831 35.3034C68.831 28.219 66.8033 21.6229 63.0061 16.3096V16.3ZM34.9241 66.7045C17.6981 66.7045 3.54234 52.5166 3.54234 35.3034C3.54234 28.7073 5.56049 22.6281 8.85075 17.5637C11.9133 12.9291 16.2105 9.2448 21.2562 6.92754C25.3021 4.89796 30.1131 3.88317 34.9146 3.88317C39.7161 3.88317 44.5271 4.89796 48.573 6.92754C53.6423 9.45495 57.6882 13.0067 60.9784 17.5637C64.2687 22.6377 66.2868 28.7073 66.2868 35.2938C66.2868 52.5262 52.1215 66.695 34.9146 66.695L34.9241 66.7045Z"
      fill="#D70F64"
    />
    <path
      d="M34.9148 41.8804C35.4313 42.1294 40.7493 40.1093 40.7493 37.5724C40.7493 36.0502 36.4452 35.8013 34.9148 35.8013C33.3845 35.8013 29.0995 36.0502 29.0995 37.5724C28.8508 40.1093 34.4174 42.1294 34.9244 41.8804H34.9148ZM54.6564 20.5985C51.6244 18.5785 45.0438 17.5637 42.5092 20.857C42.5092 20.857 39.2189 24.3992 42.5092 27.9414C45.7994 31.4836 48.0758 35.045 48.841 37.8308C49.6062 40.8657 50.8592 41.8804 53.1451 41.8804C55.4311 41.8804 59.9743 38.3382 60.73 32.508C60.9215 30.1543 60.4549 27.7936 59.3822 25.6903C58.3096 23.5869 56.6732 21.8239 54.6564 20.5985V20.5985ZM47.8272 27.951C46.8133 27.951 46.0577 27.1851 46.0577 26.1799C46.0577 25.1747 46.8229 24.3992 47.8272 24.3992C48.8315 24.3992 49.5966 25.1651 49.5966 26.1799C49.5966 27.1947 48.8315 27.951 47.8272 27.951ZM27.0717 20.857C24.5467 17.5637 17.9661 18.5785 14.9246 20.5985C11.883 22.6185 8.34404 26.6777 8.85097 32.508C9.3579 38.3382 13.9203 41.8804 16.4454 41.8804C18.7218 41.8804 19.9843 40.6167 20.7495 37.8308C21.5146 34.7865 24.0397 31.4932 27.0717 27.951C30.362 24.1503 27.0717 20.857 27.0717 20.857ZM22.2703 27.9414C21.2564 27.9414 20.4912 27.1851 20.4912 26.1799C20.4912 25.1747 21.2564 24.3992 22.2703 24.3992C23.2841 24.3992 24.0397 25.1651 24.0397 26.1799C24.0397 27.1947 23.2746 27.951 22.2703 27.951V27.9414Z"
      fill="#D70F64"
    />
  </svg>
</template>
