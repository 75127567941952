export class PromiseFunctionStorage {
  static instance = null

  constructor() {
    this._fns = {};
  }
  
  put(id, resolve, reject) {
    const fns = this.getPromiseFunctionsForTag(id);

    this._fns[id] = [ ...fns, { resolve, reject } ];
  }

  resolve(id, data) {
    const fns = this.getPromiseFunctionsForTag(id);

    if (fns) {
      fns.forEach(({ resolve }) => {
        resolve(data)
      });
    } else {
      console.error(`No such promise with id ${id}! Currently have:`, this._fns);
    }

    delete this._fns[id];
  }

  reject(id, data) {
    const fns = this.getPromiseFunctionsForTag(id);

    if (fns) {
      fns.forEach(({ reject }) => {
        reject(data)
      });
    }

    delete this._fns[id]
  }

  getPromiseFunctionsForTag(tag) {
    return this._fns[tag] || [];
  }
}
