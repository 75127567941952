<template>
  <svg width="80" height="80" viewBox="0 0 51 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3928 0.775791C11.0776 1.01223 10.9988 1.34717 10.9594 2.82487L10.8806 4.59812L7.80695 4.67693C4.81214 4.73604 4.67422 4.75574 3.66938 5.32712C2.4084 6.01672 1.77792 6.72602 1.08832 8.18402L0.576049 9.26767L0.516941 25.818C0.477536 41.8363 0.477536 42.4077 0.851888 43.6489C1.36416 45.304 2.29019 46.4073 3.86641 47.1757L5.06827 47.7865H25.5H45.9317L47.1336 47.1757C48.7098 46.4073 49.6358 45.304 50.1481 43.6489C50.5225 42.4077 50.5225 41.8363 50.4831 25.818L50.424 9.26767L49.7738 7.94759C49.0251 6.43047 48.1187 5.58326 46.6213 5.03158C45.7741 4.71634 45.0845 4.63753 43.0354 4.63753H40.4741V2.90369C40.4741 1.03193 40.2179 0.499954 39.3707 0.499954C38.4447 0.499954 38.2083 0.953117 38.1492 2.84458L38.0703 4.63753H25.6773H13.2843V2.90369C13.2843 1.46539 13.2252 1.09104 12.9297 0.8349C12.4765 0.421143 11.9051 0.40144 11.3928 0.775791ZM10.92 8.55837C10.92 9.81934 10.9988 10.1937 11.3337 10.6272C11.7869 11.2182 12.2204 11.2773 12.8508 10.8242C13.2449 10.5483 13.2843 10.371 13.2843 8.7554V7.00185H25.6773H38.0703L38.1294 8.65688C38.228 10.903 38.8782 11.6517 40.0406 10.8242C40.4347 10.5483 40.4741 10.371 40.4741 8.7554V6.96245L43.193 7.04126C45.8332 7.10037 45.9514 7.12007 46.641 7.65204C47.9611 8.65688 48.0399 8.97213 48.1187 13.5432L48.1975 17.6413H25.5197H2.84186V14.0357C2.86157 9.14945 3.0783 8.40075 4.73333 7.45502C5.42292 7.06096 5.77757 7.00185 8.22071 7.00185H10.92V8.55837ZM48.1187 31.4726C48.0596 41.7181 48.0202 43.0184 47.7247 43.5504C47.3109 44.3188 46.6213 44.9493 45.9317 45.2054C45.1436 45.501 5.85638 45.501 5.06827 45.2054C4.37868 44.9493 3.68908 44.3188 3.27532 43.5504C2.97978 43.0184 2.94038 41.7181 2.88127 31.4726L2.82216 20.0057H25.5H48.1778L48.1187 31.4726Z"
      fill="#1B75BB" />
    <path
      d="M22.7613 22.1729C19.5892 23.0202 17.0869 25.0298 15.6683 27.9261C14.0921 31.0983 14.0921 34.3098 15.6683 37.5214C17.1263 40.4571 19.5695 42.4076 22.8598 43.2549C29.2435 44.8902 35.6469 40.6738 36.5729 34.2507C37.6763 26.5272 30.406 20.183 22.7613 22.1729ZM27.9628 23.4536C32.258 24.5767 35.3514 28.4581 35.3514 32.7139C35.3514 38.8217 29.2041 43.511 23.2342 41.9742C18.1706 40.6541 15.0379 35.7678 16.0624 30.8027C17.146 25.5618 22.7613 22.1138 27.9628 23.4536Z"
      fill="#1B75BB" />
    <path
      d="M24.9089 30.8422V34.9797H28.2584H31.6078V34.1916V33.4035H29.0465H26.4851V30.0541V26.7046H25.697H24.9089V30.8422Z"
      fill="#1B75BB" />
  </svg>

</template>