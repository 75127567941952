import axios from "@/spa/plugins/axios";

export const storeAuditLog = (params) => {
  return axios.post(route("audit-log.store"), {
    ...params,
  });
};

export const storeToS3 = (params, folderName, locationId = null) => {
  return axios.post(`/api/log/${folderName}/${locationId}`, {
    ...params,
  });
};
