const LOCATION_ID = window.locationId ?? false;
const STATUS_UNSYNCED = 'STATUS_UNSYNCED';
const NAMESPACE = 'UNSYNCED';

function getKey(key) {
    return `${LOCATION_ID}-${NAMESPACE}-${key}`;
}

export function localStoragetSet(key, value) {
    if (!LOCATION_ID) return false;
    localStorage.setItem(getKey(key), JSON.stringify(value));
    return true;
}

export function localStorageGet(key) {
    if (!LOCATION_ID) return false;
    const value = localStorage.getItem(getKey(key));
    return value ? JSON.parse(value) : null;
}

export function localStorageRemove(key) {
    if (!LOCATION_ID) return false;
    localStorage.removeItem(getKey(key));
    return true;
}

export function trackOrder(orderId) {
  return localStoragetSet(orderId, STATUS_UNSYNCED);
}

export function untrackOrder(orderId) {
    return localStorageRemove(orderId);
}

export function checkIfOrderUnsynced(orderId) {
    return localStorageGet(orderId) === STATUS_UNSYNCED;
}

export default {
    trackOrder,
    untrackOrder,
    checkIfOrderUnsynced,
}