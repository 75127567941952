<template>
    <div class="pagination col-md-12">
        <div class="col-md-2">
            Showing<br /><strong>{{ maxPage - (itemsPerPage - 1) }} - {{ totalItems < maxPage ? totalItems : maxPage }} of {{ totalItems }}</strong>
        </div>
        <div class="col-md-10 text-center d-inline-block">
            <button class="custom-paginate paginate-btn paginate-prev btn btn-link d-inline-block mr-2"
                :disabled="currentPage == 1"
                @click="handleClick(currentPage - 1)"
            >
                <i class="fa fa-chevron-left"></i>
            </button>
            <button class="custom-paginate paginate-number btn btn-link d-inline-block"
                :class="{ 'paginate-number-active': currentPage === 1 }"
                @click="handleClick(1)"
            >
                1
            </button>
            <button
                v-for="i in pages"
                :key="i"
                class="custom-paginate paginate-number btn btn-link d-inline-block"
                :class="{ 'paginate-number-active': currentPage === i }"
                @click="handleClick(i)"
            >
              {{ i }}
            </button>
            <button
                v-if="lastPage > 1"
                class="custom-paginate paginate-number btn btn-link d-inline-block"
                :class="{ 'paginate-number-active': currentPage === lastPage }"
                @click="handleClick(lastPage)"
            >
                {{ lastPage }}
            </button>
            <button class="custom-paginate paginate-btn paginate-prev btn btn-link d-inline-block ml-2"
                :disabled="currentPage === lastPage"
                @click="handleClick(currentPage + 1)"
            >
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        itemsPerPage: {
            type: Number,
            required: true
        },
        totalItems: {
            type: Number,
            required: true
        }
    },
    computed: {
        maxPage() {
            return this.currentPage * this.itemsPerPage;
        },

        lastPage() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },

        pages() {
            const start = this.currentPage - 4 > 1 ? this.currentPage - 4 : 2;
            let end = this.lastPage;
            if (end > 10) {
                end = this.currentPage < 6 ? 10 : this.currentPage + 4;
            }
            if (end > this.lastPage) {
                end = this.lastPage;
            }

            const pages = [];
            for (let i = start; i < end; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    methods: {
        handleClick(page) {
            this.$emit("onPageChange", page);
        }
    }
}
</script>
