<template>
    <base-modal
        v-bind="$attrs"
        min-width="50"
        :hasConfirm=false
        :hasCancel=true
        cancelLabel="Close"
        >
        <template v-slot:title>{{ reportTitle }}</template>

        <hr />
        <div class="x-report">
            <div class="row x-read-title">
                <div class="col-md-12 ">
                    <h5>{{recieptDetails.account_name}}</h5>
                    <h5>{{recieptDetails.location}}</h5>
                    <h5>{{recieptDetails.location_address}}</h5>
                </div>
            </div>

            <div class="row x-read-printer-details">
                <div class="col-md-12 ">
                    <code>{{prefix}}VAT REG TIN: {{recieptDetails.location_vat_reg}}</code> <br />
                    <code>MIN: {{recieptDetails.lp_min}}</code> <br />
                    <code>SERIAL NO.:  {{recieptDetails.lp_serial}}</code> <br />
                </div>
            </div>

            <hr />

            <table class="z-read-tables mt-2" id="z-balance-tables">
                <tbody>
                    <tr>
                        <td valign="top">Beg. Bal.</td>
                        <td valign="top">{{ this.$filters.formatPrice(parseFloat(beginningBalance)) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Ending Bal.</td>
                        <td valign="top">{{ this.$filters.formatPrice(parseFloat(endingBalance)) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Beg. SI#</td>
                        <td valign="top">{{ this.padWithZero(beginningSettlement.receipt_num || endingOR) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">End. SI#</td>
                        <td valign="top">{{ this.padWithZero(endingSettlement.receipt_num || endingOR) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Beg. Void#</td>
                        <td valign="top">{{ this.padWithZero(beginningVoidNum || currentVoidReceiptNum) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">End. Void#</td>
                        <td valign="top">{{ this.padWithZero(endingVoidNum || currentVoidReceiptNum) }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="z-read-tables mt-2" id="z-numbers-tables">
                <tbody>
                    <tr>
                        <td valign="top">Beg. Void Amts.</td>
                        <td valign="top">{{ this.$filters.formatPrice(beginningVoidAmount) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">End. Void Amt.</td>
                        <td valign="top">{{ this.$filters.formatPrice(endingVoidAmount) }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="z-read-tables mt-2" id="z-main-tables">
                <tbody>
                    <tr>
                        <td colspan="2"> ------------------------------- </td>
                    </tr>
                    <tr v-if="enableHideSc">
                        <td valign="top">Gross</td>
                        <td valign="top">{{ this.$filters.formatPrice(totalGrossWithServiceCharge) }}</td>
                    </tr>
                    <template v-if="!enableHideSc">
                        <tr>
                            <td valign="top">Gross Sales</td>
                            <td valign="top">{{ this.$filters.formatPrice(totalGrossWithServiceChargeWoVoid) }}</td>
                        </tr>
                        <tr>
                            <td valign="top">Gross w/ Void</td>
                            <td valign="top">{{ this.$filters.formatPrice(totalGrossWithServiceCharge) }}</td>
                        </tr>
                        <template v-if="totalServiceCharge != 0">
                            <tr>
                                <td valign="top">Gross w/o Service Charge</td>
                                <td valign="top">{{ this.$filters.formatPrice(totalWOVoidAndServiceCharge) }}</td>
                            </tr>
                        </template>
                    </template>
                    <tr v-if="!enableHideSc && totalServiceCharge != 0">
                        <td valign="top">Service Charge</td>
                        <td valign="top">{{ this.$filters.formatPrice(totalServiceCharge) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Other Charges</td>
                        <td valign="top">{{ this.$filters.formatPrice(totalOtherCharges) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Net Sales w/ VAT {{ totalServiceCharge != 0 ? '& SC' : '' }}</td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalNetSalesWCharges) }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" height="20"></td>
                    </tr>
                    <tr>
                        <td valign="top" colspan="2" class="text-left">Discount</td>
                    </tr>
                    <tr v-for="(discount, index) in discountItems" :key="index">
                        <td class="word-wrap" valign="top">{{ discount.name }}</td>
                        <td valign="top">{{ this.$filters.formatPrice(discount.amount) }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td valign="top">Total Discounts</td>
                        <td valign="top">{{ this.$filters.formatPrice(totalDiscounts) }}</td>
                    </tr>
                    <tr v-if="accreditationTypeId != 1">
                        <td valign="top">VAT ADJUSTMENT {{ accreditationTypeId }}</td>
                        <td valign="top">{{ this.$filters.formatPrice(totalVatAdjustment) }}</td>
                    </tr>
                    <tr v-if="accreditationTypeId == 1">
                        <td valign="top">PWD & SC VAT ADJUSTMENT </td>
                        <td valign="top">{{ this.$filters.formatPrice(totalPwdScVatAdjustment) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Breakdown of Sales</td>
                        <td valign="top"></td>
                    </tr>
                    <tr>
                        <td valign="top">VATABLE SALES</td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalVatableSales) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">VAT AMOUNT</td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalVatAmount) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Vat Exempt Sales</td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalVatExemptSales) }}</td>
                    </tr>
                    <tr>
                        <td valign="top">Zero Rated Sales</td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalZeroRatedSales) }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td valign="top">Net Sales </td>
                        <td valign="top">{{ this.$filters.formatPrice(this.totalNetSales) }}</td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td valign="top">Voided Sales</td>
                        <td valign="top"> {{ this.$filters.formatPrice(voidedSalesAmount) }} </td>
                    </tr>
                    <tr>
                        <td valign="top">Voided Billed</td>
                        <td valign="top"> {{ this.$filters.formatPrice(voidedBilledAmount) }} </td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td colspan="2"> ------------------------------- </td>
                    </tr>
                    <template v-if="hasCashFundPermission">
                      <tr>
                        <td valign="top" colspan="2" class="text-left">Cash Funds</td>
                      </tr>
                    </template>
                    <template v-if="hasCashFundPermission" v-for="(terminal, terminalIndex) in floats" :key="terminalIndex">
                        <tr><td valign="top">{{ terminalIndex }}</td></tr>
                        <tr v-for="(float, floatIndex) in terminal" :key="floatIndex">
                            <td valign="top">{{ floatIndex }}</td>
                            <td valign="top">{{this.$filters.formatPrice(float)}}</td>
                        </tr>
                    </template>
                    <tr v-if="hasCashFundPermission">
                        <td colspan="2"> ------------------------------- </td>
                    </tr>

                    <template v-if="hasHourlySales && !isEmpty(hourlySales) && Object.keys(hourlySales)">
                      <tr>
                        <td valign="top" colspan="2" class="text-left">Hourly Sales</td>
                      </tr>
                      <template v-for="key in Object.keys(hourlySales)" :key="key">
                        <tr>
                          <td valign="top" col="2">{{ key }}</td>
                          <td valign="top" col="1" class="text-left"><pre>{{hourlySales[key].count.toFixed(0).padStart(5,' ')}}{{this.$filters.formatPrice(hourlySales[key].value).padStart(12,' ')}}</pre></td>
                        </tr>
                      </template>
                      <tr>
                        <td valign="top" col="2">TOTAL:</td>
                        <td valign="top" col="1" class="text-left"><pre>{{ String(totalHourlySalesQty).padStart(5, ' ') }} {{ this.$filters.formatPrice(totalHourlySales).padStart(12, ' ') }}</pre></td>
                      </tr>
                      <tr>
                        <td colspan="2"> ------------------------------- </td>
                      </tr>
                    </template>

                    <tr>
                        <td valign="top" colspan="2" class="text-left">Breakdown of Tender</td>
                    </tr>
                    <tr v-for="(breakdown, index) in paymentMethods" :key="index">
                        <td valign="top">{{ breakdown.name }}</td>
                        <td valign="top">{{this.$filters.formatPrice(breakdown.amount)}}</td>
                    </tr>
                    
                    <tr>
                        <td colspan="2"> ------------------------------- </td>
                    </tr>
                </tbody>
            </table>

            <table class="z-read-tables mt-2" id="z-main-tables">
                <tbody>
                    <tr>
                        <td valign="top" colspan="3" class="text-left">Income Head Sales</td>
                    </tr>
                    <tr v-for="(incomeHead, index) in incomeHeadSales" :key="index">
                        <td valign="top">{{incomeHead.name}}</td>
                        <td valign="top">{{incomeHead.qty}}</td>
                        <td valign="top">{{this.$filters.formatPrice(reportType == "xread" ? incomeHead.shiftwise : incomeHead.daywise)}}</td>
                    </tr>
                    <tr>
                        <td colspan="3"> ------------------------------- </td>
                    </tr>
                </tbody>
            </table>

            <table class="z-read-tables mt-2" id="z-main-tables">
                <tbody>
                    <tr>
                        <td valign="top">LAST KOT NO.</td>
                        <td valign="top">{{ lastKot || currentKotNum }}</td>
                    </tr>
                    <tr>
                        <td valign="top">LAST BILL NO.</td>
                        <td valign="top"> {{ lastBillNum || currentBillNum }} </td>
                    </tr>
                    <tr>
                        <td valign="top">TRANSACTION COUNT</td>
                        <td valign="top">{{ totalTransaction }}</td>
                    </tr>
                    <tr>
                        <td valign="top">GUEST COUNT</td>
                        <td valign="top">{{ totalPax }}</td>
                    </tr>
                    <tr>
                        <td valign="top">AVERAGE PER TRANSACTION</td>
                        <td valign="top">{{ this.$filters.formatPrice(avgPerTransaction) }}</td>
                    </tr>
                    <tr v-if="zCounter">
                        <td valign="top">Z COUNTER</td>
                        <td valign="top">{{ zCounter }}</td>
                    </tr>
                    <tr>
                        <td valign="top">GRAND TOTAL </td>
                        <td valign="top">{{ this.$filters.formatPrice(endingBalance) }}</td>
                    </tr>
                    <tr>
                        <td colspan="2"> ------------------------------- </td>
                    </tr>
                </tbody>
            </table>
            <table id="z-footer">
                <tbody>
                    <template v-if="enableUpdatedReportTimestamp">
                        <tr>
                            <td valign="top" style="font-size: 10px;">Printing Date</td>
                            <td valign="top" style="font-size: 10px;">{{ receiptDateOnly }}</td>
                        </tr>
                        <tr>
                            <td valign="top" style="font-size: 10px;">Printing Time</td>
                            <td valign="top" style="font-size: 10px;">{{ receiptTimeOnly }}</td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td valign="top" style="font-size: 10px;">Receipt printed on</td>
                        <td valign="top" style="font-size: 10px;">{{ receiptDate }}</td>
                    </tr>
                    <tr>
                        <td valign="top" style="font-size: 10px;">POS Date:</td>
                        <td valign="top" style="font-size: 10px;">{{ formattedPosDate() }}</td>
                    </tr>
                    <tr>
                    <td colspan="2">
                        <br>
                    </td>
                    </tr>
                    <tr>
                        <td valign="top" style="font-size: 10px;">Shift #:</td>
                        <td valign="top" style="font-size: 10px;">{{ currentShift.shift }}</td>
                    </tr>
                    <tr>
                        <td valign="top" style="font-size: 10px;">Terminal #:</td>
                        <td valign="top" style="font-size: 10px;">{{ shiftTable.terminal_id }}</td>
                    </tr>
                    <tr>
                        <td valign="top" style="font-size: 10px;">Generated by:</td>
                        <td valign="top" style="font-size: 10px;">{{ generatedByDisplay }}</td>
                    </tr>
                </tbody>
            </table>
        </div>


    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import { mapState } from 'vuex';
import moment from "moment";
import {
    IS_NON_VAT,
    ENABLE_HIDE_NO_SERVICE_CHARGE,
    ENABLE_CASH_SALES_BREAKDOWN,
    ENABLE_UPDATED_REPORT_TIMESTAMP,
    ENABLE_UPDATED_GENERATED_BY,
    SERIES_TYPES,
} from "@/spa/constants";
import {isEmpty} from "lodash";

export default {
    name: 'NewXZReadPreviewModal',


    props: {
        recieptDetails: {
            type: Object,
            required: true,
        },

        paymentMethods: {
            type: Array,
            required: true,
        },

        incomeHeadSales: {
            type: Array,
            required: true,
        },

        lastKot: {
            type: Number,
            required: true,
        },

        lastBillNum: {
            type: Number,
            required: true,
        },

        totalPax: {
            type: Number,
            required: true,
        },

        avgPerTransaction: {
            type: Number,
            required: true,
        },

        totalVatableSales: {
            type: Number,
            required: true,
        },

        totalSales: {
            type: Number,
            required: true,
        },

        zCounter: {
            type: [Number, null],
            default: null,
        },

        totalVatAmount: {
            type: Number,
            required: true,
        },

        totalVatAdjustment: {
            type: Number,
            required: true,
        },

        totalNetSales: {
            type: Number,
            required: true,
        },

        totalNetSalesWCharges: {
            type: Number,
            required: true,
        },

        totalVatExemptSales: {
            type: Number,
            required: true,
        },

        totalZeroRatedSales: {
            type: Number,
            required: true,
        },

        discountItems: {
            type: Array,
            required: true,
        },

        totalServiceCharge: {
            type: Number,
            required: true,
        },

        totalOtherCharges: {
            type: Number,
            required: true,
        },

        totalBeforeServiceCharge: {
            type: Number,
            required: true,
        },
        
        totalWoVoidBeforeServiceCharge: {
            type: Number,
            required: true,
        },
        totalWOVoidAndServiceCharge: {
            type: Number,
            required: true,
        },

        beginningSettlement: {
            type: Object,
            required: true,
        },

        endingSettlement: {
            type: Object,
            required: true,
        },

        beginningBalance: {
            type: Number,
            required: true,
        },

        endingBalance: {
            type: Number,
            required: true,
        },

        voidedSalesAmount: {
            type: Number,
            required: true
        },

        voidedBilledAmount: {
            type: Number,
            required: false
        },

        beginningVoidAmount: {
            type: Number,
            required: true
        },

        endingVoidAmount: {
            type: Number,
            required: true
        },

        endingVoidOrder: {
            type: Object,
            required: true,
        },

        totalTransaction: {
            type: Number,
            required: true
        },

        beginningVoidNum: {
            type: Number,
            required: true
        },

        endingVoidNum: {
            type: Number,
            required: true
        },

        reportType: {
            type: String,
            required: true,
        },

        receiptDate: {
            type: String,
            required: true,
        },

        endingOR: {
            type: Number,
            required: true
        },
        totalGrossWithServiceCharge: {
            type: Number,
            required: true
        },
        totalGrossWithServiceChargeWoVoid: {
            type: Number,
            required: true
        },
        locationStatus: {
          type: String,
        },

        shiftNumber: {
            type: Number,
        },

        floats: {
            type: Object,
        },

        hasCashFundPermission: {
            type: Boolean,
            default: false
        },

        cashFloat: {
            type: Number,
            default: 0,
        },

        cashAdditional: {
            type: Number,
            default: 0,
        },

        cashWithdraw: {
            type: Number,
            default: 0,
        },

        hourlySales: {
            type: Object,
        },

        accreditationTypeId: {
            type: Number,
            required: true,
        },

        totalRegularVatAdjustment: {
            type: Number,
            required: true,
        },
        
        totalPwdScVatAdjustment: {
            type: Number,
            required: true,
        },
    },

    components: {
        BaseModal,
    },

    data() {
        return {
            xToggleStates: {
                'income-head-sales': false,
            },
            zToggleStates: {
                'income-head-sales': false,
            },

            currentKotNum: null,
            currentBillNum: null,
            currentVoidReceiptNum: null,
        };
    },

    async created() {
        const storage = new ScopedNativeStorage(window.locationId);
        this.currentKotNum = await storage.get(SERIES_TYPES.KOT_NUM);
        this.currentBillNum = await storage.get(SERIES_TYPES.BILL_NUM);
        this.currentVoidReceiptNum = await storage.get(SERIES_TYPES.VOID_RECEIPT_NUM);
    },

    computed: {

        ...mapState([
            'activeBrandId', 'user/locationId'
        ]),

        ...mapState('user', ['terminalId', 'username',  'firstName', 'userTypeName']),

        ...mapState('settings', ['posDate','shiftTable', 'currentShift']),

        totalHourlySales() {
          return Object.values(this.hourlySales).reduce((accumulator, object) => {
            return accumulator + object.value;
          }, 0);
        },

        totalHourlySalesQty() {
            return Object.values(this.hourlySales).reduce((accumulator, object) => {
                return accumulator + object.count;
            }, 0);
        },
        
        hasHourlySales() {
          return window.ENABLE_HOURLY_SALES_XZREAD || window.HOURLY_SALES_XZREAD_LOCATIONS.includes(this.locationId);
        },

        totalDiscounts() {

            const totalDiscounts = this.discountItems.reduce((accumulator, object) => {
            return accumulator + object.amount;
            }, 0);

            return totalDiscounts;
        },

        reportTitle() {
            return this.reportType == "xread" ? "X-Read Report" : "Z-Read Report";
        },

        prefix() {
            return IS_NON_VAT ? 'Non-' : '';
        },

        enableHideSc() {
            return ENABLE_HIDE_NO_SERVICE_CHARGE;
        },

        enableCashSalesBreakdown() {
            return ENABLE_CASH_SALES_BREAKDOWN;
        },

        enableUpdatedReportTimestamp() {
            return ENABLE_UPDATED_REPORT_TIMESTAMP;
        },

        receiptDateOnly() {
            return moment(this.receiptDate).format("YYYY-MM-DD");
        },

        receiptTimeOnly() {
            return moment(this.receiptDate).format("HH:mm:ss");
        },

        generatedByDisplay() {
            return ENABLE_UPDATED_GENERATED_BY
            ? `${this.firstName}-${this.userTypeName}`
            : this.username;
        },

        toggleState() {
            return this.reportType == "xread" ? this.xToggleStates : this.zToggleStates;
        },

    },

    methods: {
      isEmpty,
        padWithZero(num) {

            return String(num || 0).padStart(20, '0');

        },

        formattedPosDate() {
            return moment(this.posDate).format("YYYY-MM-DD")
        },

    },
}
</script>

<style scoped>

.x-report {
    width: 300;
}

.x-read-title > div > h5 {
    text-align: center;
    text-transform: uppercase;
}

.x-read-title {
    margin-bottom: 17px;
    font-weight: bold;
}

.x-read-printer-details {
    font-weight: bold;
    text-align: center;
}

.x-read-printer-details > div > code {
    color: #000;
}

.word-wrap {
    word-break: break-word;
}

</style>