import { pick, without } from 'lodash';
import cloneDeep from 'rfdc/default';
import { createStore } from 'vuex';
import orders from '@/spa/store/orders';
import user from '@/spa/store/user';
import settings from '@/spa/store/settings';
import global from '@/spa/store/global';
import sqlite from '@/spa/store/sqlite';

import modalToggles from '@/spa/store/modal-toggles';

import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';

import { USE_EXPERIMENTAL_STORAGE_STRATEGY } from '@/spa/constants';

import { isCloneable } from '@/spa/utils/local-storage';

const storage = localForage.createInstance({});
storage.config({
    name: `mosaic-pos-spa-${window.locationId}`,
    storeName: `mosaic-pos-spa-${window.locationId}`,
    driver: localForage.INDEXEDDB,
    version: 1.0,
    description: 'Mosaic POS App',
});

const omitProps = USE_EXPERIMENTAL_STORAGE_STRATEGY
    ? ['orders', 'isOrdersLoaded', 'settlements', 'isSettlementsLoaded', 'modals', 'global']
    : [];

export const store = createStore({
    ...orders,

    modules: {
        modals: modalToggles,
        user,
        settings,
        global,
        sqlite
    },

    plugins: [new VuexPersistence({
        storage,
        reducer: s => {
            const payload = pick(s, [
                ...without(Object.keys(orders.state), ...omitProps),
                'user',
                'settings',
            ]);

            return isCloneable(payload) ? payload : cloneDeep(payload);
        },
        asyncStorage: true,
    }).plugin],
});

if (!('$store' in window)) {
    window.$store = store;
}

export const clearStorage = async () => {
    await storage.clear();
}

export default store;
