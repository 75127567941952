<template>
  <m-alert-bar
    v-if="showUpdateAvailableAlert"
    variant="warning"
    icon="fas fa-exclamation-triangle"
    :dismissible="true"
    @close="dismissAlert"
  >
    We've launched some improvements on the system. {{ getUpdateInstructionLabel() }}
  </m-alert-bar>
</template>

<script>
import MAlertBar from "@/spa/components/common/MAlertBar.vue";

export default {
  name: 'AppUpdateAlert',
  components: {
    MAlertBar
  },
  data() {
    return {
      showUpdateAvailableAlert: this.isUpdateAvailable(),
    };
  },
  created() {
    if (window.appUpdateCheckEvents) {
      window.appUpdateCheckEvents.on('forceShowSoftRequireUpdate', () => {
        this.showUpdateAvailableAlert = true;
      });
    } else {
      console.warn('UpdateAvailableAlert: window.appUpdateCheckEvents is not available');
    }
  },
  methods: {
    isUpdateAvailable() {
      const lastDismissed = localStorage.getItem('appUpdateBannerLastDismissed');
      const threeHoursAgo = Date.now() - 3 * 60 * 60 * 1000;

      if (lastDismissed && lastDismissed > threeHoursAgo) {
        return false;
      }

      const currentVersion = window.MosaicPosAppVersionNumbers?.versionCode;
      const minimumVersion = window.APP_BUILD_NUMBER_SOFT_MINIMUM;

      return currentVersion && currentVersion < minimumVersion;
    },
    getUpdateInstructionLabel() {
      const fromStore = isInstalledFromStore();

      if (fromStore) {
        const store = getInstallSourceStoreName();
        return `Please update your application on ${store}.`;
      } else {
        return 'Please update your application.';
      }
    },
    dismissAlert() {
      this.showUpdateAvailableAlert = false;
      localStorage.setItem('appUpdateBannerLastDismissed', Date.now());
    },
  },
};
</script>