<template>
    <svg version="1.0" width="70.000000pt" height="70.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M850 5111 c-78 -24 -142 -76 -178 -145 -27 -51 -27 -52 -30 -293 l-3
                              -243 75 0 75 0 3 223 c4 326 -11 312 343 312 l250 0 6 -120 c4 -96 9 -128 27
                              -161 30 -58 78 -103 138 -131 l52 -24 493 3 c551 3 531 1 605 75 63 63 78 105
                              84 238 l5 120 260 0 c286 0 292 -1 332 -62 17 -26 18 -115 21 -1555 l2 -1528
                              75 0 75 0 -2 1548 -3 1547 -27 50 c-31 59 -67 94 -128 126 l-45 24 -1240 2
                              c-682 1 -1251 -2 -1265 -6z m1790 -231 c0 -107 -15 -151 -62 -180 -32 -19 -49
                              -20 -488 -20 -439 0 -456 1 -488 20 -18 11 -40 36 -48 55 -14 35 -20 195 -7
                              208 3 4 251 7 550 7 l543 0 0 -90z"></path>
            <path d="M642 2498 l3 -1783 24 -45 c32 -61 67 -97 126 -128 l50 -27 588 -3
                              587 -3 0 -101 0 -102 82 -152 82 -152 68 34 68 34 -16 33 c-9 17 -43 80 -75
                              139 l-59 107 0 313 c0 174 4 328 10 347 11 38 43 76 80 96 14 7 67 15 120 16
                              l95 3 3 -347 2 -347 75 0 75 0 2 908 c3 859 4 909 21 937 30 48 65 69 127 73
                              65 4 111 -21 141 -78 18 -33 19 -72 19 -707 l0 -673 74 0 75 0 3 292 c3 272 4
                              295 23 326 29 48 79 76 137 76 52 0 86 -19 122 -68 20 -26 21 -42 24 -327 l3
                              -300 77 3 77 3 5 220 c4 191 7 225 23 248 56 86 189 88 251 4 19 -26 21 -46
                              26 -250 l5 -222 75 0 75 0 5 145 c4 130 7 148 27 176 23 32 86 64 126 64 40
                              -1 103 -37 124 -71 20 -33 21 -53 28 -601 l8 -568 74 0 74 0 -3 577 -3 578
                              -24 53 c-31 68 -114 145 -182 167 -66 22 -169 17 -226 -11 l-43 -21 -29 53
                              c-65 114 -204 172 -334 139 -34 -9 -66 -20 -71 -25 -18 -18 -31 -10 -50 31
                              -41 85 -142 148 -250 156 -56 5 -88 -2 -158 -32 -16 -6 -17 16 -20 292 -3 289
                              -4 299 -26 341 -31 58 -88 113 -147 142 -70 33 -180 34 -250 2 -63 -29 -119
                              -83 -153 -147 l-27 -50 -3 -501 -3 -501 -107 -6 c-59 -3 -124 -10 -144 -16
                              -80 -25 -174 -124 -198 -209 -5 -20 -10 -117 -10 -214 l0 -178 -557 0 c-616 0
                              -600 -2 -647 63 l-21 28 -3 1765 -2 1764 -75 0 -75 0 2 -1782z"></path>
            <path d="M2018 4043 l-3 -47 -78 -13 c-302 -51 -570 -286 -665 -583 -16 -50
                              -42 -165 -42 -187 0 -2 -22 -3 -50 -3 l-50 0 0 -80 0 -80 970 0 970 0 0 80 0
                              80 -54 0 -54 0 -6 53 c-11 84 -68 238 -120 322 -143 228 -338 354 -638 410
                              -26 5 -28 9 -28 50 l0 45 -74 0 -75 0 -3 -47z m248 -218 c269 -61 489 -300
                              538 -582 l6 -33 -712 0 -711 0 6 43 c21 155 131 337 264 439 178 138 388 183
                              609 133z"></path>
            <path d="M1730 2655 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"></path>
            <path d="M2030 2655 l0 -75 80 0 80 0 0 75 0 75 -80 0 -80 0 0 -75z"></path>
            <path d="M2340 2655 l0 -75 75 0 75 0 0 75 0 75 -75 0 -75 0 0 -75z"></path>
            <path d="M1180 2125 l0 -75 385 0 385 0 0 75 0 75 -385 0 -385 0 0 -75z"></path>
            <path d="M1180 1815 l0 -75 385 0 385 0 0 75 0 75 -385 0 -385 0 0 -75z"></path>
            <path d="M1180 1510 l0 -80 385 0 386 0 -3 78 -3 77 -382 3 -383 2 0 -80z"></path>
        </g>
    </svg>
</template>
