<template>
  <BaseModal
    v-model="isOpen"
    min-width="440"
    min-height="210"
    full-width-actions
    :click-to-close="false"
    @confirm="confirm"
    @cancel="$emit('cancelled')"
    confirm-label="Confirm"
    @before-open="reset"
  >
    <template v-slot:title v-if="title">{{ title }}</template>

    <div class="label mb-1" v-if="label">{{ label }}</div>

    <VueMultiselect
      v-model="selected"
      :options="options"
      :showLabels="false"
      label="display"
    />
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import VueMultiselect from "vue-multiselect";

export default {
  name: "DynamicOptionsModal",

  components: { BaseModal, VueMultiselect },

  props: {
    title: {
      type: String,
    },

    label: {
      type: String,
    },

    options: {
      type: Array[Object],
      required: true,
    },
  },

  data() {
    return {
      selected: null,
    };
  },

  methods: {
    reset() {
      this.selected = null;
    },

    confirm() {
      this.$emit("confirmed", this.selected);
    },
  },
};
</script>

<style scoped>
::v-deep(.modal__content) {
  overflow: visible !important;
}
</style>
