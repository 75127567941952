<template>
    <base-modal
        v-model="isOpen"
        min-width="0"
        max-width="800"
        min-height="100px"
        width="100%"
        full-width-actions
        :click-to-close="false"
        @confirm="checkPasscode"
        @cancel="emitCancel"
        >
        <template v-slot:title>Authorization</template>

        <hr />
        <div class="col-12 mb-1 bg-warning" v-if="approvers.length == 0">
            <span class="text-danger">{{ warning }}</span>
        </div>

        <select
            v-model="selectedApproverId"
            class="form-control"
            name="approver-selection"
            id="approver-selection"
            >
            <option value="">Choose approver</option>
            <option
                v-for="approver in approvers"
                :value="approver.id"
                :key="`approver-${approver.id}`"
                >
                {{ approver.first_name }} {{ approver.last_name }}
            </option>
        </select>

        <div class="passcode-keypad d-flex flex-wrap justify-content-between align-items-center">
                <div
                    class="passcode-value col-12 value-section"
                    v-text="'•'.repeat(passcode.length)"
                    />

                <template v-for="y in [0, 1, 2]">
                    <div
                        v-for="x in [1, 2, 3]"
                        class="col-4"
                        :key="`keypad-${x + (y * 3)}`"
                        >
                        <button
                            class="btn btn-block btn-light p-4"
                            @click="append(x + (y * 3))"
                            >
                            {{ x + (y * 3) }}
                        </button>
                    </div>
                </template>

                <div class="col-4">
                    <button
                        class="btn btn-block clear-btn btn-danger p-4"
                        @click="clearPasscode"
                        >
                        CLEAR
                    </button>
                </div>

                <div class="col-4">
                    <button
                        class="btn btn-block btn-light p-4"
                        @click="append(0)"
                        >
                        0
                    </button>
                </div>

                <div class="col-4">
                    <button
                        class="btn btn-block btn-danger p-4"
                        @click="unappend"
                        >
                        <i class="fas fa-backspace"></i>
                    </button>
                </div>
        </div>

    </base-modal>
</template>

<script>
import { noop, isFunction } from 'lodash';
import { mapState } from 'vuex';
import BaseModal from '@/spa/components/modals/BaseModal';
import {dbService} from "@/spa/services/db-service";
import {DEXIE_TABLES, ENABLE_USER_TYPE_PERMISSION_CHECKING, OFFLOAD} from "@/spa/constants";

export default {
    name: 'ApproverModal',

    components: {
        BaseModal,
    },

    props: {
        passcodeLength: {
            type: Number,
            default: 4,
        },
    },

    data() {
        return {
            passcode: '',
            approvers: [],
            selectedApproverId: '',
            warning: 'No approver users found!'
        };
    },

    computed: {
        ...mapState(['modals/approversCallback']),

        ...mapState("modals", ['permissionType']),

        isOpen: {
            get() {
                const {approvers} = this.$store.state.modals;
                if (approvers) {
                    if (this.$can(this.PERMISSIONS.EMPLOYEE_CLOCK_IN)) {
                        this.warning = `No approver's have clocked-in`
                    }
                    this.fetchApprovers(this.permissionType)
                }

                return approvers;
            },
            set(value) {
                this.$store.commit('modals/setApprovers', value);
            },
        },

        authCallback() {
            return isFunction(this.$store.state.modals.approversCallback)
                ? this.$store.state.modals.approversCallback
                : noop;
        },

        selectedApprover() {
            return this.approvers.find(a => a.id == this.selectedApproverId);
        },
    },

    methods: {
      async fetchApprovers(permissionType) {
          try {
              let filter = ['user_type_id', '=', 5];
              if (ENABLE_USER_TYPE_PERMISSION_CHECKING && permissionType) {
                  filter = ['user_type_id', '>=', 4]
              }
              
              let approvers;
              let supported = window.MosaicPosAppSupportedFeatures?.multiterminal >= 2 && window.MosaicPosAppSupportedFeatures.sqliteOffloadUser;
              let multiterminalActive = false;

              if (supported) {
                  const mub = new MultiterminalUtilityBridge();
                  const status = await mub.getStatus();

                  multiterminalActive = status.mode != 'none';
              }

              // To reduce risk, only use SQLite users records
              // when running app-based multiterminal.
              if (multiterminalActive) {
                  const ub = new UserBridge();
                  const users = await ub.getAll();

                  if (ENABLE_USER_TYPE_PERMISSION_CHECKING && permissionType) {
                      approvers = users.rows.filter(user => user.user_type_id >= 4);
                  } else {
                      approvers = users.rows.filter(user => user.user_type_id == 5);
                  }
              } else {
                  approvers = await dbService.fetchItems(DEXIE_TABLES.USERS, [filter]) || [];
              }

              const selectedFields = await Promise.all(approvers.map(async item => {
                  const hasPermission = await this.hasPermission(item, permissionType);
                  if (hasPermission) {
                      return {
                          id: item.id,
                          account_id: item.account_id,
                          location_id: item.location_id,
                          username: item.username,
                          email: item.email,
                          first_name: item.first_name,
                          last_name: item.last_name
                      };
                  }
                  return null;
              })).then(items => items.filter(item => item));

              this.approvers = selectedFields;
          } catch (e) {
              this.approvers = [];
              console.error(e);
          }
      },

      async hasPermission(item, permissionType) {
          let hasPermission = true;
          if (ENABLE_USER_TYPE_PERMISSION_CHECKING && permissionType) {
              const pb = new PermissionBridge();
              hasPermission = OFFLOAD.sqliteOffloadPermission
                  ? await pb.can(UserPermissionBridge, permissionType, item.user_type_id)
                  : await dbService.fetchPermission(permissionType, DEXIE_TABLES.USER_TYPE_PERMISSIONS, item.user_type_id);
          }
          if (this.$can(this.PERMISSIONS.EMPLOYEE_CLOCK_IN)) {
              const tx = dbService.db.transaction(DEXIE_TABLES.ATTENDANCES, 'readonly');
              const store = tx.objectStore(DEXIE_TABLES.ATTENDANCES);
              const attendance = await store.index('user_id').getAll(item.id) || [];
              const sortedAttendance = attendance.filter(item => !item.is_break).sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
              const isClockedIn = sortedAttendance[0]?.clock_in_time;
              const isClockedOut = sortedAttendance[sortedAttendance.length - 1]?.clock_out_time;

              return hasPermission && isClockedIn && !isClockedOut;
          }
          return hasPermission;
      },

      append(value) {
            if (this.passcode.length >= this.passcodeLength) return;
            this.passcode = `${this.passcode}${value}`;
        },

        unappend() {
            if (!this.passcode) return;
            const passcodeArray = this.passcode.split('');
            passcodeArray.pop();
            this.passcode = passcodeArray.join('');
        },

        clearPasscode() {
            this.passcode = '';
        },

        resetForms() {
            this.passcode = '';
            this.selectedApproverId = '';
        },

        emitCancel() {
            this.authCallback({
                success: false,
                cancelled: true,
            });
            this.resetForms();
        },

        async checkPasscode() {
            if (!this.selectedApproverId) {
                this.$swal.error('Please select an approver');
                return;
            }

            if (!this.passcode) {
                this.$swal.error('Please enter a passcode');
                return;
            }

            if (window.__showLoader) window.__showLoader();

            try {
                const isValid = await window.validatePasscode(this.selectedApprover.username, this.passcode);
                if (window.__hideLoader) window.__hideLoader();
                if (!isValid) {
                    this.$swal.error('Incorrect passcode!');
                    return;
                }
            } catch (e) {
                console.error(e);
            } finally {
                if (window.__hideLoader) window.__hideLoader();
            }

            this.authCallback({ success: true, approver: this.selectedApprover });
            this.resetForms();
        },
    },
}
</script>

<style scoped>
.passcode-keypad {
    width: 400px;
    height: 400px;
    margin: auto;
}

@media screen and (min-width: 992px) {
    .passcode-keypad .btn {
    border-radius: 64px !important;
    font-size: 1.2rem;
    font-weight: bold;
}
}

.passcode-keypad .clear-btn {
    font-size: 0.8rem;
}

.passcode-value {
    font-size: 3rem;
    font-family: 'Exo', sans-serif;
    text-align: center;
}

.value-section {
    background: white;
    height: 48px;
    place-content: center;
    display: grid;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.value-section .btn {
    min-width: 64px;
}
</style>