<template>
  <div class="d-lg-flex flex-nowrap">
    <div class="pending-page py-4 px-0 px-lg-4" :class="{
      'col-12': pendingOrders && pendingOrders.length === 0 && !activeOrderId,
      'col-lg-8 col-md-12':
        pendingOrders && pendingOrders.length > 0 && activeOrderId,
    }">
      <label>Pending Orders</label>

      <button v-if="isMultiTerminal" class="btn btn-primary btn-lg mr-2" :disabled="isFetching"
        @click="triggerManualFetchingOfOrders">
        <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
      </button>

      <div class="d-flex flex-wrap my-4">
        <no-items v-if="pendingOrders && pendingOrders.length === 0" />
        <div v-for="pending in pendingOrders" :key="`pending-${pending._id}`" :class="{ 'd-none': !pending._id }">
          <m-pending-order v-if="pending._id" @click="selectOrder(pending)" :active="pending._id == activeOrderId"
            :order="pending" />
        </div>
      </div>
    </div>

    <orders-panel v-if="activeOrderId" :order-id="activeOrderId" :orders="activeOrderItems" :pax="activeOrder.pax"
      :table-id="activeOrder.tableId" :kots="activeOrder.kots" :order-detail="activeOrder.orderDetail" preview
      @updateOrders="goToUpdateOrder(activeOrderId)" @billOrders="goToPayments(activeOrderId, $event)"
      @voidOrders="voidTable(activeOrderId)" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { getTableLayout } from "@/spa/services/table-service";
import NoItems from "@/spa/components/placeholders/NoItems";
import MPendingOrder from "@/spa/components/common/MPendingOrder";
import OrdersPanel from "@/spa/components/panels/OrdersPanel";
import { multiterminalEvents } from '@/mobile_bridge/multiterminal/events';
import {OFFLOAD} from "@/spa/constants";

export default {
  name: "PendingPage",

  components: {
    NoItems,
    MPendingOrder,
    OrdersPanel,
  },

  data() {
    return {
      tables: [],
      maxOrderDetailsLength: 40,
      isFetching: false,
    };
  },



  computed: {


    ...mapState(["activeOrderId"]),

    ...mapGetters(["activeOrder", "pendingOrders", "hasPendingSettlements"]),

    activeOrderItems() {
      return this.activeOrder.orders;
    },

    activeTable() {
      if (!this.activeOrder.tableId) return {};

      const table = this.tables.find(
        (table) => table.table_id == this.activeOrder.tableId
      );
      return table || {};
    },

    tableNameMap() {
      return this.tables.reduce((map, table) => {
        map[table.table_id] = table.table_name;
        return map;
      }, {});
    },

    isMultiTerminal() {
      const terminal = JSON.parse(sessionStorage.getItem("terminal"));
      return terminal?.is_shared_terminal;
    },
  },

  beforeDestroy() {
    window.eventBus.off("show-cart");
  },

  async mounted() {
    window.eventBus.on("show-cart", (isToggleCart) => {
      this.isToggleCart = isToggleCart;
    });

    await this.fetchTables();
    if (this.$route.query.order_id) {
      this.selectOrderById(this.$route.query.order_id, true);
    } else {
      this.setActiveOrderId("");
    }

    multiterminalEvents.on('MessageFeed', async (event) => {
      const { type, action } = event.body;

      if (type === 'Receipt' && action === 'UPSERT') {
        await this.sqliteFetchPendingOrders();
      }
    });

    if (OFFLOAD.sqliteOffloadReceipt && !window.ENABLE_APP_BASED_MULTITERMINAL) {
      await this.sqliteFetchPendingOrders();
    }
  },

  methods: {
    ...mapMutations([
      'setActiveServiceTypeId',
      'setActiveChannelId',
      'setActiveOrderId',
      'deleteOrder',
    ]),

    ...mapActions(["getLatestServerOrders", "sqliteFetchPendingOrders"]),

    async triggerManualFetchingOfOrders() {
      this.isFetching = true;
      try {
        if (OFFLOAD.sqliteOffloadReceipt) {
          await this.sqliteFetchPendingOrders();
        } else {
          await this.getLatestServerOrders();
        }
        this.$swal.success(
          "Success",
          "Multiterminal orders fetched successfully"
        );
      } catch (e) {
        this.$swal.error(
          "Failed to fetch orders",
          "Please check your connection and try again"
        );
      }

      setTimeout(() => {
        this.isFetching = false;
      }, 2000);
    },

    async fetchTables() {
      try {
        const layoutResponse = await getTableLayout();
        this.tables = layoutResponse.data.table_details.service_areas
          .map((a) => a.tables)
          .flat();
      } catch (error) {
        console.error(error);
      }
    },

    selectOrderById(orderId, force = false) {
      const order = this.pendingOrders.find((order) => order._id == orderId);
      if (order) this.selectOrder(order, force);
    },

    selectOrder(order, force = false) {

      if (order.isSplitRequested && !this.$can(this.PERMISSIONS.SETTLE_ORDER)) {
        this.$swal.warning(
          "Cannot view table",
          "This table is currently split, please continue on the cashing terminal."
        );
        return;
      }

      if (this.activeOrderId == order._id && force) return;
      this.setActiveOrderId(order._id == this.activeOrderId ? null : order._id);
      this.setActiveServiceTypeId(order.serviceTypeId);
      this.setActiveChannelId(order.channelTypeId);

      this.$nextTick(() => {
        if (this.hasPendingSettlements) {
          this.goToPayments(order._id, {
            tableName: this.activeTable.table_name,
          });
        }
        window.eventBus.emit("show-cart", false);
      });
    },

    goToPayments(orderId, data) {
      this.$router.push({
        name: "payments",
        params: { orderId, tableName: data.tableName },
        query: data.query,
      });
    },

    voidTable(orderId) {
      this.deleteOrder(orderId);
      this.setActiveOrderId(null);
    },

    goToUpdateOrder(orderId) {
      this.$router.push({
        name: "update-orders",
        params: { orderId, serviceType: this.activeOrder.serviceType },
      });
    },
  },
};
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

.pending-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}

.pending-page-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  padding: 0 16px;
}

@media (min-width: 768px) {
  .pending-page-list {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }
}
</style>