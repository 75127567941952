/* eslint-disable no-case-declarations */
import { get } from 'lodash';
import { IS_PUC_LOCATION, USE_SM_MARKETS_OR_FORMAT, IMIN_PRINTER_FONT_SIZES } from '@/spa/constants';

export async function print(model, ip_address, print_type, html, paper_width, itemString = null, qrcode = null, accountLogo = null) {
  let result;
  let printData = {};
  let formatted_html = '';
  let htmlData = '';
  let re;
  let pythonPrintData = {};

  //remove unecessary string replacement if method is not IMIN
  if(model.printer_method != 'IMIN' && print_type != 'XZRead_Custom_Sections') {
    html = html.replace(/__BLANK__/ig, ' ');
    html = html.replace(/__MOD__/ig, ' ');
    html = html.replace(/__SERVICE_TYPE__/ig, '');
    html = html.replace(/__SPLIT__/ig, '');
  }

  switch (model.printer_method) {
    case 'XP_LABEL_PYTHON':
    case 'BIRCH_LABEL_PYTHON':
      printData = {
        api_host: model.api_host,
        api_port: model.api_port,
        ip_address: ip_address,
        zpl_string: html,
        print_type: print_type,
      };
      result = print_label_via_python(printData);
      break;
    case 'EPSON':
      result = epson_print(ip_address, print_type, html, paper_width, itemString);
    break;
    case 'EPSON_PYTHON':

      if(print_type == 'XZRead_Custom_Sections') {
        // value of html is an array
        html.forEach(d =>  {
          htmlData = JSON.stringify({
              custom_zread: {
              html: html_to_text.convert(d.html, {
                selectors: [
                  {
                    selector: ".custom_xzread-tbl",
                    format: "dataTable",
                    options: { maxColumnWidth: 25 },
                  },
                ],
              }),
              alignment: 'left',
            }
          });

          pythonPrintData = {
            api_host: model.api_host,
            api_port: model.api_port,
            ip_address: ip_address,
            html: htmlData,
            print_type: print_type,
          };

          //set minimal timeout
          setTimeout(() => {
            result = print_via_python(pythonPrintData);
          }, 300);
        });
      }

      formatted_html = await escpos_print_formatter(print_type, html, paper_width, itemString, qrcode);
      htmlData = '';

      if(print_type === 'EOD') {
        // change to report after formatting
        print_type = 'Report';
      }

      //replace &nbsp;
      re = new RegExp(String.fromCharCode(160), "g");

      switch (print_type){
        case 'KOT':
          for (const key in formatted_html) {
            formatted_html[key] = (formatted_html[key]).replace(re, " ");
          }

          // append top margin
          let maxTopMarginVal = 4;
          let topMargin = model.top_margin <= maxTopMarginVal ? model.top_margin : maxTopMarginVal;
          let topMarginString = new Array(topMargin).fill("\n").join('');

          formatted_html.top_margin_string = topMarginString;
          htmlData = JSON.stringify(formatted_html);
          break;
        case 'PlainText':
          formatted_html['text'] = html;
          htmlData = is_print_via_app_available()
            ? html
            : JSON.stringify(formatted_html)
          console.log(html);
          break;
        case 'Receipt':
        case 'QRPayment':
        case 'Void':
        case 'Report':
        case 'CashFloat':
        case 'QRCode':
          for(const key in formatted_html) {
            formatted_html[key] = (formatted_html[key]).replace(re, " ");
          }
          htmlData = JSON.stringify(formatted_html);
          break;
        default:
          break;
        }

        pythonPrintData = {
          api_host: model.api_host,
          api_port: model.api_port,
          ip_address: ip_address,
          html: htmlData,
          print_type: print_type,
          has_qr: qrcode ? '1' : '0',
          image_logo: accountLogo
        };
        
      result = print_via_python(pythonPrintData);
    break;
    case 'STAR':
      result = star_print(ip_address, print_type, html, paper_width);
    break;
    case 'IMIN':
      //format html
      formatted_html = await escpos_print_formatter(print_type, html, 56, itemString);

      htmlData = '';

      if(print_type === 'EOD') {
        // change to report after formatting
        print_type = 'Report';
      }

      if (print_type === 'KOT') {
        // Replace &nbsp;
        let re = new RegExp(String.fromCharCode(160), "g");
        for (const key in formatted_html) {
          formatted_html[key] = formatted_html[key].replace(re, " ");
        }
      }

      htmlData = JSON.stringify(formatted_html);
      //process imin print
      result = imin_print(model.api_host, print_type, htmlData, paper_width);
    break;

    default:
      break;
  }

  return result;
}

export async function get_printer_ips(location_id, csrf_token) {
  let response = await fetch(`/printer/store-printer-ips-idb`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": csrf_token,
    },
    body: JSON.stringify({ location_id: location_id }),
  });
  let data = await response.json();
  return data;
}

export async function print_kot({ ip_address = null, data = {}, from_grab = false, is_imin_device = false, void_type = null, product_to_void = {}, printer_model = {name: 'EPSON', printer_method: 'EPSON'} } = {}) {
  if (!!ip_address) {
    // Saves copy of KOT data to indexedDB
    writeData("printed-kot-data", data);
  }
  const kot_printer_data = await get_offline_kot({ ...data, void_type, product_to_void });
  const { kot_html, paper_width } = kot_printer_data;
  $("#kot-modal").html(kot_html);
  let kot_header = $("#kot-modal").find("#kot-header").text().trim();
  let kot_items = $("#kot-modal").find("#kot-items").text().trim();
  console.log(kot_header + "\n" + kot_items + "\n\n" + ip_address);

  if (!!ip_address) {
    if (from_grab == true) {
      $("#print-kot").on("click", function () {
        print(printer_model, ip_address, 'KOT', kot_printer_data.kot_html, kot_printer_data.paper_width)
        console.log("Printer IP: " + ip_address);
      });
    } else {
      print(printer_model, ip_address, 'KOT', kot_printer_data.kot_html, kot_printer_data.paper_width)
    }
  } else {
    console.log("No Epson printer found...");
  }
}

export function print_via_python(data) {
  data.is_sm_markets = USE_SM_MARKETS_OR_FORMAT ? '1' : '0';
  if (is_print_via_app_available()) {
    if (data.print_type === 'PlainText') {
      print_string_via_app(
        data.ip_address.trim(),
        data.print_type,
        data.html
      );
    } else {
      print_via_app(data);
    }
  } else {
    $.post({
      url: `http://${data.api_host}:${data.api_port}/print`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
      success: function(response) {
        if(response == 'done') {
         console.log("Printer IP: " + data.ip_address);
        } else {
          //prints error message from python api
          console.log("Print Error: " + response);
        }
      },
    });
  }
}

export function cash_drawer_via_python(data) {
  if (is_print_via_app_available()) {
    print_via_app(data);
  } else {
    $.post({
    url: `http://${data.api_host}:${data.api_port}/open_cash_drawer`,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(data),
    success: function(response) {
      if(response == 'done') {
      console.log("Printer IP: " + data.ip_address);
      } else {
      console.log("Print error");
      }
    }
    });
  }
}

export function print_label_via_python(data) {
  if (window.MosaicPosAppSupportedFeatures?.rawPrinting) {
    const { ip_address, zpl_string } = data;
    print_label_via_app(ip_address, zpl_string);
  } else {
    $.post({
      url: `http://${data.api_host}:${data.api_port}/print_label`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
      success: function(response) {
        if(response == 'done') {
          console.log("Printer IP: " + data.ip_address);
        } else {
          console.log("Print error");
        }
      }
    });
  }
}

export function imin_print(host, print_type, html, paper_width) {
  //connect to printer

  var IminPrintInstance = iminPrint(host);
  //create websocket connection
  IminPrintInstance.connect(function (ws) {
    // The websocket status is linked
    if (ws.readyState == ws.OPEN) { // Note: All encapsulated print methods must be called in the context of a websocket link
      //1. initPrinter
      IminPrintInstance.initPrinter(IminPrintInstance.PrintConnectType.SPI);
      // 2. getPrinterStatus

      IminPrintInstance.getPrinterStatus(IminPrintInstance.PrintConnectType.SPI, function (status) {
        console.log('Printer status:' + JSON.stringify(status));
      });
      // 3. printText
      //alignment

      let htmlObj = {};
      const itemColCount = 4;
      const totalsColCount = 2;
      const iminMaxCharPerLine = 29;
      const twoColumnsSmallTextSizeArray = new Array(2).fill(IMIN_PRINTER_FONT_SIZES.SMALL);
      const fourColumnsSmallTextSizeArray = new Array(4).fill(IMIN_PRINTER_FONT_SIZES.SMALL);
      switch(print_type) {
        case "Receipt":
          htmlObj = JSON.parse(html);
          let itemsArr  = htmlObj.receipt_items.split(/\n/);
          let modString = '';
          let totalsArr = htmlObj.receipt_totals.split(/\n/);
          let vatsArr = htmlObj.vat_breakdown.split(/\n/);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.MEDIUM);
          IminPrintInstance.setTextStyle(1);
          IminPrintInstance.printText(htmlObj.receipt_location_details, 1);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.setTextStyle(0);
          IminPrintInstance.printText(htmlObj.receipt_header_wo_location_details, 1);
          IminPrintInstance.setAlignment(0);
          IminPrintInstance.printText(htmlObj.receipt_buyer_info, 1);
          if(htmlObj.receipt_buyer_info.trim().length > 0) {
            IminPrintInstance.printAndFeedPaper(25);
          }
          itemsArr.forEach((item, index) => {
            item = item.trim();
            if(item.length > 0) {
              if(item.includes('__MOD__')) {
                modString += item.replace(/__MOD__/ig, ' ');
              } else {
                if(modString.length > 0) {
                  //this means you have got all the mod strings from the product
                  IminPrintInstance.setAlignment(0);
                  IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
                  IminPrintInstance.printText(modString, 1);

                  //reset modstring
                  modString = '';
                }
                if(item.includes('__SERVICE_TYPE__')) {
                  item = item.replace(/__SERVICE_TYPE__/ig, '');
                  if(item.length > iminMaxCharPerLine) {
                    //get difference
                    const diff = item.length - iminMaxCharPerLine;
                    const removeCount = diff % 2 > 0 ? Math.round(diff / 2) + 1 : (diff / 2);

                    //remove from start and end
                    item = item.slice(removeCount);
                    item = item.slice(0, -removeCount);
                  }
                  IminPrintInstance.setAlignment(1);
                  IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
                  IminPrintInstance.printText(item, 1);
                  IminPrintInstance.printAndFeedPaper(15);
                } else {
                  let textArr = item.split(' ');
                  textArr = textArr.filter(data => data.trim());
                  textArr = textArr.map(data => data.replace(/__BLANK__/ig, ' '));

                  //if item name has space
                  if(textArr.length > itemColCount) {
                    while(textArr.length > itemColCount) {
                      //set slicer to textArr length - 3 (using 3 instead of 4 since arrays starts at index 0)
                      let slicer = textArr.length - (itemColCount-1);
                      let itemDataArr = textArr.slice(slicer);
                      let prependString = textArr.slice(0, slicer).join(' ');
                      itemDataArr.unshift(prependString);
                      textArr = itemDataArr;
                    }
                  }

                  if(textArr.length < itemColCount) {
                    let spaceCount = itemColCount - textArr.length;
                    while(spaceCount > 0) {
                      textArr.push(' ');
                      spaceCount--;
                    }
                  }
                  // EPOS-1260
                  if (index === 0) {
                    // Make line "ITEM QTY PRICE AMOUNT" bold style
                    IminPrintInstance.setTextStyle(1);
                  } else {
                    IminPrintInstance.setTextStyle(0);
                  }
                  IminPrintInstance.printColumnsText(textArr,[2,1,2,2],[0,1,1,1],fourColumnsSmallTextSizeArray,576);
                  IminPrintInstance.printAndFeedPaper(20);
                }
              }
            }
          });
          totalsArr.forEach(total => {
            if(total.trim().length > 0) {
              let totalSplitArr = total.split(' ');
              totalSplitArr = totalSplitArr.map(data =>data.replace(/__BLANK__/ig, ' '));
              totalSplitArr = totalSplitArr.filter(v => v.length > 0);
              if(totalSplitArr.length < totalsColCount) {
                let spaceCount = totalsColCount - totalSplitArr.length;
                while(spaceCount > 0) {
                  totalSplitArr.push(' ');
                  spaceCount--;
                }
              }
              IminPrintInstance.printColumnsText(totalSplitArr,[3,2],[0,0],twoColumnsSmallTextSizeArray,576);
            }
          });
          IminPrintInstance.printAndFeedPaper(25);
          IminPrintInstance.setAlignment(0);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.printText(htmlObj.discounts, 1);
          vatsArr.forEach(vatdata => {
            if(vatdata.trim().length > 0) {
              let vatSplitArr = vatdata.split(' ');
              vatSplitArr = vatSplitArr.map(data =>data.replace(/__BLANK__/ig, ' '));
              vatSplitArr = vatSplitArr.filter(v => v.length > 0);
              if(vatSplitArr.length < totalsColCount) {
                let spaceCount = totalsColCount - vatSplitArr.length;
                while(spaceCount > 0) {
                  vatSplitArr.push(' ');
                  spaceCount--;
                }
              }
              IminPrintInstance.printColumnsText(vatSplitArr,[3,2],[0,0],twoColumnsSmallTextSizeArray,576);
            }
          });
          IminPrintInstance.printAndFeedPaper(50);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.printText(htmlObj.receipt_footer, 1);
          IminPrintInstance.printAndFeedPaper(50);
          IminPrintInstance.openCashBox();
          break;
        case "Report":
          htmlObj = JSON.parse(html);
          let mainArr = htmlObj.z_main.split(/\n/);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.setTextStyle(1);
          IminPrintInstance.printText(htmlObj.z_header, 1);
          IminPrintInstance.setTextStyle(0);
          if (htmlObj.z_header.trim() == 'EOD Report') {
            IminPrintInstance.printText(htmlObj.z_balance, 1);
          } else {
            const balanceArr = htmlObj.z_balance.split(/\n/);
            balanceArr.forEach(balance => {
              balance = balance.trim();
              if(balance.length > 0) {
                const balanceSplitArr = balance.split('__SPLIT__');
                IminPrintInstance.printColumnsText(balanceSplitArr,[2,2],[0,0],twoColumnsSmallTextSizeArray,576);
              }
            });
          }
          IminPrintInstance.printAndFeedPaper(10);            
          IminPrintInstance.setAlignment(0);
          IminPrintInstance.printText(htmlObj.z_numbers, 1);
          IminPrintInstance.printAndFeedPaper(10);

          mainArr.forEach(main => {
            main = main.trim();
            if(main.length > 0) {
              let mainSplitArr = main.split('__SPLIT__');
              if(mainSplitArr.length != 2) {
                if(mainSplitArr.includes('-------------------------------')) {
                  IminPrintInstance.setAlignment(0);
                  IminPrintInstance.printText(mainSplitArr[0], 1);
                } else if(mainSplitArr.includes('__LINEBREAK__')) {
                  IminPrintInstance.printAndFeedPaper(10);
                } else {
                  IminPrintInstance.printColumnsText(mainSplitArr,[5],[0],[IMIN_PRINTER_FONT_SIZES.SMALL],576);
                }

              } else {
                IminPrintInstance.printColumnsText(mainSplitArr,[3,2],[0,0],twoColumnsSmallTextSizeArray,576);
              }
            }
          });
          IminPrintInstance.printAndFeedPaper(10);

          IminPrintInstance.printText(htmlObj.discounts, 1);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.printText(htmlObj.z_footer, 1);
          IminPrintInstance.printAndFeedPaper(50);
          break;
        case "Void":
          htmlObj = JSON.parse(html);
          itemsArr = htmlObj.void_items.split(/\n/);
          modString = '';
          totalsArr = htmlObj.void_totals.split(/\n/);
          vatsArr = htmlObj.void_vats.split(/\n/);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.setTextStyle(1);
          IminPrintInstance.printText(htmlObj.void_header_title_only, 1);
          IminPrintInstance.setTextStyle(0);
          IminPrintInstance.printText(htmlObj.void_header_wo_title, 1);
          itemsArr.forEach((item, index) => {
            item = item.trim();
            if(item.length > 0) {
              if(item.includes('__MOD__')) {
                modString += item.replace(/__MOD__/ig, ' ');
              } else {
                if(modString.length > 0) {
                  //this means you have got all the mod strings from the product
                  IminPrintInstance.setAlignment(0);
                  IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
                  IminPrintInstance.printText(modString, 1);

                  //reset modstring
                  modString = '';
                }

                let textArr = item.split(' ');
                textArr = textArr.filter(data => data.trim());
                textArr = textArr.map(data => data.replace(/__BLANK__/ig, ' '));

                //if item name has space
                if(textArr.length > itemColCount) {
                  while(textArr.length > itemColCount) {
                    //set slicer to textArr length - 3 (using 3 instead of 4 since arrays starts at index 0)
                    let slicer = textArr.length - (itemColCount-1);
                    let itemDataArr = textArr.slice(slicer);
                    let prependString = textArr.slice(0, slicer).join(' ');
                    itemDataArr.unshift(prependString);
                    textArr = itemDataArr;
                  }
                }

                if(textArr.length < itemColCount) {
                  let spaceCount = itemColCount - textArr.length;
                  while(spaceCount > 0) {
                    textArr.push(' ');
                    spaceCount--;
                  }
                }
                // EPOS-1260
                if (index === 0) {
                  // Make line "ITEM QTY PRICE AMOUNT" bold style
                  IminPrintInstance.setTextStyle(1);
                } else {
                  IminPrintInstance.setTextStyle(0);
                }
                IminPrintInstance.printColumnsText(textArr,[2,1,2,2],[0,1,1,1],fourColumnsSmallTextSizeArray,576);
                IminPrintInstance.printAndFeedPaper(20);
              }
            }
          });
          totalsArr.forEach(total => {
            if(total.trim().length > 0) {
              let totalSplitArr = total.split(' ');
              totalSplitArr = totalSplitArr.map(data =>data.replace(/__BLANK__/ig, ' '));
              totalSplitArr = totalSplitArr.filter(v => v.length > 0);
              if(totalSplitArr.length < totalsColCount) {
                let spaceCount = totalsColCount - totalSplitArr.length;
                while(spaceCount > 0) {
                  totalSplitArr.push(' ');
                  spaceCount--;
                }
              }
              IminPrintInstance.printColumnsText(totalSplitArr,[3,2],[0,0],twoColumnsSmallTextSizeArray,576);
            }
          });
          IminPrintInstance.printAndFeedPaper(50);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.setAlignment(0);
          IminPrintInstance.printText(htmlObj.void_discounts, 1);
          vatsArr.forEach(vatdata => {
            if(vatdata.trim().length > 0) {
              let vatSplitArr = vatdata.split(' ');
              vatSplitArr = vatSplitArr.map(data =>data.replace(/__BLANK__/ig, ' '));
              vatSplitArr = vatSplitArr.filter(v => v.length > 0);
              if(vatSplitArr.length < totalsColCount) {
                let spaceCount = totalsColCount - vatSplitArr.length;
                while(spaceCount > 0) {
                  vatSplitArr.push(' ');
                  spaceCount--;
                }
              }
              IminPrintInstance.printColumnsText(vatSplitArr,[3,2],[0,0],twoColumnsSmallTextSizeArray,576);
            }
          });
          IminPrintInstance.printAndFeedPaper(100);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.printText(htmlObj.void_footer, 1);
          IminPrintInstance.printAndFeedPaper(100);
          IminPrintInstance.partialCut();
          break;
        case "CashFloat":
          htmlObj = JSON.parse(html);
          IminPrintInstance.setAlignment(1);
          IminPrintInstance.printText(htmlObj.cash_float_header, 1);
          IminPrintInstance.setAlignment(0);
          IminPrintInstance.printText(htmlObj.cash_float_body, 1);
          IminPrintInstance.printAndFeedPaper(100);
          break;
        default:
          // FOR "KOT" case:
          // EPOS-1260
          htmlObj = JSON.parse(html);
          const serviceTypeString = '\n' + htmlObj.kot_service_type.trim();
          
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.SMALL);
          IminPrintInstance.printText(htmlObj.kot_text_wo_items.trim(), 1);
          IminPrintInstance.printText(serviceTypeString, 1);
          IminPrintInstance.setTextSize(IMIN_PRINTER_FONT_SIZES.LARGE);
          IminPrintInstance.setTextStyle(1);
          IminPrintInstance.printText(htmlObj.kot_items_wo_service_type.trim(), 1);
          IminPrintInstance.printAndFeedPaper(100);
          break;
      }
    }
  });
}

export function getIdbKey(transactionId, kotNum) {
  const filteredKeys = Object.keys(localStorage).filter((key) => key.startsWith("QSR") || key.startsWith("Delivery") || key.includes("Table"));
  const jsonValues = filteredKeys.map((key) => JSON.parse(localStorage.getItem(key)));
  const kotData = jsonValues.find((data) => data.transaction_id.includes(parseInt(transactionId)));
  if (!kotData) {
    return null;
  }

  const serviceMap = {
    'Dine-in': 'Table',
    'Take-out': 'Table',
    'QSR-Dine-in PS': 'QSR',
    'QSR Take-out': 'QSR',
    'QSR-Dine-in': 'QSR',
    'QSR Take-out PS': 'QSR',
  };

  const serviceType = get(serviceMap, kotData.serviceType, kotData.serviceType);
  const order_num = serviceType === 'Table' ? kotData.table : kotData.order_num;
  const key = serviceType === 'Table' ? `${serviceType}${order_num}` : `${serviceType}-${order_num}`;

  const fullIdbKey = `${key}-${kotNum}`;
  return fullIdbKey;
}

export async function getPrintVoidKot(transactionId, kotNum = null) {
  const idbKey = getIdbKey(transactionId, kotNum);
  const idbData = idbKey && (await readData("printed-kot-data", idbKey));

  return idbData || null;
}

export async function updateStoredIDBKots() {
  const idbKeys = await getAllKeys("printed-kot-data");
  if (idbKeys.length === 0) {
    return;
  }
  const localStorageKeys = Object.keys(localStorage).filter((key) => key.startsWith("QSR") || key.startsWith("Delivery") || key.includes("Table"));
  const updatedLocalStorageKeys = [];
  for (let key of localStorageKeys) {
    const localStorageData = JSON.parse(localStorage.getItem(key));
    if (localStorageData) {
      for (let kot of localStorageData.kot) {
        const idbKey = `${key}-${kot}`;
        updatedLocalStorageKeys.push(idbKey);
      }
    }
  }
  const notInIdbKeys = idbKeys.filter((key) => !updatedLocalStorageKeys.includes(key));
  for (let key of notInIdbKeys) {
    deleteItemFromData("printed-kot-data", key);
  }
}

function create_epos_print_object(address) {
  if (is_print_via_app_available()) {
    return new ePOSPrintEmulator(address);
  } else {
    return new epson.ePOSPrint(address);
  }
}

function create_epson_builder_object() {
  if (is_print_via_app_available()) {
    return new EpsonBuilderEmulator();
  } else {
    return new epson.ePOSBuilder();
  }
}

export function centerAlign(len, longest) {
  return Math.floor((longest - len) / 2);
}

export function epson_test_print(printer) {
  let model = printer.printer_model;
  const flagToYN = (flag) => flag == 1 ? 'Yes' : 'No';
  const testPrintMeta = [
    `\nPrinter IP: ${printer.ip_address.trim()}`,
    `Printer Location: ${printer.printer_location}`,
    `Paper Width: ${printer.paper_width}`,
    `Can Print KOT: ${flagToYN(printer.can_print_kot)}`,
    `Can Print Bill: ${flagToYN(printer.can_print_bill)}`,
    `Can Print Receipt: ${flagToYN(printer.can_print_receipt)}`,
    `Can Print Report: ${flagToYN(printer.can_print_z_report)}\n`,
];
  switch(model.printer_method) {
    case 'EPSON_PYTHON':
      let pythonPrintData = {
        api_host: model.api_host,
        api_port: model.api_port,
        ip_address: printer.ip_address,
        html: JSON.stringify({
          title: {
            html: `***TEST PRINT***` + '\n',
            alignment: 'left'
          },
          body: {
            html: testPrintMeta.join('\n'),
            alignment: 'left'
          }
        }),
        print_type: 'test_print',
      };
      print_via_python(pythonPrintData);
      break;
    case 'EPSON':
    default:
      if (is_print_via_app_available()) {
        print_string_via_app(
          printer.ip_address.trim(),
          'Test_print',
          `***TEST PRINT***` + "\n"
            + "\n" + `Printer IP: ${printer.ip_address.trim()}` + "\n"
            + `Printer Location: ${printer.printer_location}` + "\n"
            + `Paper Width: ${printer.paper_width}` + "\n"
            + `Can Print KOT: ${printer.can_print_kot == 1 ? "Yes" : "No"}` + "\n"
            + `Can Print Bill: ${printer.can_print_bill == 1 ? "Yes" : "No"}` + "\n"
            + `Can Print Receipt: ${printer.can_print_receipt == 1 ? "Yes" : "No"}` + "\n"
            + `Can Print Report: ${printer.can_print_z_report == 1 ? "Yes" : "No"}` + "\n"
        );
      } else {
        let builder = create_epson_builder_object();
        builder.addTextSize(2, 1);
        builder.addText(`***TEST PRINT***` + "\n");
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addTextSize(1, 1);
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n" + `Printer IP: ${printer.ip_address.trim()}` + "\n");
        builder.addText(`Printer Location: ${printer.printer_location}` + "\n");
        builder.addText(`Paper Width: ${printer.paper_width}` + "\n");
        builder.addText(`Can Print KOT: ${printer.can_print_kot == 1 ? "Yes" : "No"}` + "\n");
        builder.addText(`Can Print Bill: ${printer.can_print_bill == 1 ? "Yes" : "No"}` + "\n");
        builder.addText(`Can Print Receipt: ${printer.can_print_receipt == 1 ? "Yes" : "No"}` + "\n");
        builder.addText(`Can Print Report: ${printer.can_print_z_report == 1 ? "Yes" : "No"}` + "\n");
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addCut(builder.CUT_FEED);
        // Acquire the print document
        let request = builder.toString();
        let timeout = printer.paper_width == "76mm" ? 1000 : 10000;
        // Set the end point address
        let address = `https://${printer.ip_address.trim()}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=${timeout}`;


        // Create an ePOS-Print object
        let epos = create_epos_print_object(address);

        epos.onerror = function (err) {
          if (err.status == 0) {
            alert("Cannot connect to printer: " + printer.ip_address);
          }
        };

        epos.onstatuschange = function (status) {
          console.log(status);
        };

        // Set a response receipt callback function
        epos.onreceive = function (res) {
          // Obtain the print result and error code
          let msg = "Print" + (res.success ? "Success" : "Failure") + "\nCode:" + res.code + "\nStatus:\n";
          // Obtain the printer status
          let asb = res.status;

          if (asb & epos.ASB_NO_RESPONSE) {
            msg += " No printer response\n";
          }
          if (asb & epos.ASB_PRINT_SUCCESS) {
            msg += " Print complete\n";
          }
          if (asb & epos.ASB_DRAWER_KICK) {
            msg += ' Status of the drawer kick number 3 connector pin = "H"\n';
          }
          if (asb & epos.ASB_OFF_LINE) {
            msg += " Offline status\n";
          }
          if (asb & epos.ASB_COVER_OPEN) {
            msg += " Cover is open\n";
          }
          if (asb & epos.ASB_PAPER_FEED) {
            msg += " Paper feed switch is feeding paper\n";
          }
          if (asb & epos.ASB_WAIT_ON_LINE) {
            msg += " Waiting for online recovery\n";
          }
          if (asb & epos.ASB_PANEL_SWITCH) {
            msg += " Panel switch is ON\n";
          }
          if (asb & epos.ASB_MECHANICAL_ERR) {
            msg += " Mechanical error generated\n";
          }
          if (asb & epos.ASB_AUTOCUTTER_ERR) {
            msg += " Auto cutter error generated\n";
          }
          if (asb & epos.ASB_UNRECOVER_ERR) {
            msg += " Unrecoverable error generated\n";
          }
          if (asb & epos.ASB_AUTORECOVER_ERR) {
            msg += " Auto recovery error generated\n";
          }
          if (asb & epos.ASB_RECEIPT_NEAR_END) {
            msg += " No paper in the roll paper near end detector\n";
          }
          if (asb & epos.ASB_RECEIPT_END) {
            msg += " No paper in the roll paper end detector\n";

            //show swal error notification
            Swal.fire(
              'Printer Error: No paper detected!',
            );
          }
          if (asb & epos.ASB_BUZZER) {
            msg += " Sounding the buzzer (limited model)\n";
          }
          if (asb & epos.ASB_SPOOLER_IS_STOPPED) {
            msg += " Stop the spooler\n";
          }

          console.log(msg);
        };
        // Send the print document
        epos.send(request);
      }
      break;
  }
}

export function epson_open_drawer(ip_address, printer_model = {name: 'EPSON', printer_method: 'EPSON'}) {
  switch(printer_model.printer_method) {
    case 'EPSON':
      if (is_print_via_app_available()) {
        open_drawer_via_app(ip_address);
      } else {
        let builder = create_epson_builder_object();
        builder.addPulse();
        let request = builder.toString();
        let address = `https://${ip_address}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=1000`;
        let epos = create_epos_print_object(address);
        epos.send(request);
      }
      break;
    case 'EPSON_PYTHON':
      if (is_print_via_app_available()) {
        open_drawer_via_app(ip_address);
      } else {
        let pythonPrintData = {
          api_host: printer_model.api_host,
          api_port: printer_model.api_port,
          ip_address: ip_address,
        };
        cash_drawer_via_python(pythonPrintData);
      }
      break;
    case 'IMIN':
        var IminPrintInstance = iminPrint(printer_model.api_host);

        //create websocket connection
        IminPrintInstance.connect(function (ws) {
          // The websocket status is linked
          if (ws.readyState == ws.OPEN) { // Note: All encapsulated print methods must be called in the context of a websocket link
            //1. initPrinter
            IminPrintInstance.initPrinter(IminPrintInstance.PrintConnectType.SPI);
            // 2. getPrinterStatus

            IminPrintInstance.getPrinterStatus(IminPrintInstance.PrintConnectType.SPI, function (status) {
              console.log('Printer status:' + JSON.stringify(status));
            });

            IminPrintInstance.openCashBox();
          }
        });
        break;
    default:
      break;
  }
}

function epson_build(builder, config, data) {
  let debugString = '## EPSON PRINTER ##\n';
  config.forEach(c => {
    if (c.hidden) return;
    switch (c.type) {
      case 'content':
        builder.addText(data[c.value] + '\n');
        debugString += data[c.value] + '\n';
        break;
      case 'spacer':
        builder.addText('\n');
        debugString += '\n';
        break;
      case 'alignment':
        const alignKeyMap = {
          left: 'ALIGN_LEFT',
          center: 'ALIGN_CENTER',
          right: 'ALIGN_RIGHT',
        };
        builder.addTextAlign(builder[alignKeyMap[c.value]]);
        break;
    }
  });

  console.log(debugString);
  return builder;
}

function star_build(builder, config, data) {
  let debugString = '## STAR PRINTER ##\n';
  let request = '';
  config.forEach(c => {
    if (c.hidden) return;
    switch (c.type) {
      case 'content':
        request += builder.addText(data[c.value] + '\n');
        debugString += data[c.value] + '\n';
        break;
      case 'spacer':
        request += builder.addText('\n');
        debugString += '\n';
        break;
      case 'alignment':
        const alignKeyMap = {
          left: 'ALIGN_LEFT',
          center: 'ALIGN_CENTER',
          right: 'ALIGN_RIGHT',
        };
        request += builder.addTextAlign(builder[alignKeyMap[c.value]]);
        break;
    }
  });

  console.log(debugString);
  return request;
}

export async function epson_print(ip_address, print_type, html, paper_width, itemString = null) {
  // Create an ePOS-Print Builder object
  let builder = create_epson_builder_object();
  // Create a print document
  // Configure the print character settings
  // builder.addTextLang('en');
  // builder.addTextSmooth(true);
  // builder.addTextFont(builder.FONT_A);

  switch (print_type) {
    case "KOT":
      let kot_header_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".kot-voided-item",
            format: "skip"
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_order_identifier_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_details_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_items_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".kot-voided-item",
            format: "skip"
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_voided_items_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: ".kot-voided-item",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".kot-item",
            format: "skip",
          },
          {
            selector: ".kot-service-header",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_header = html_to_text.convert(html, kot_header_options);
      let kot_order_identifier = html_to_text.convert(html, kot_order_identifier_options);
      let kot_details = html_to_text.convert(html, kot_details_options);
      let kot_items = html_to_text.convert(html, kot_items_options).trim().replace(/\n{2,}/g, '\n');
      let kot_voided_items = html_to_text.convert(html, kot_voided_items_options).trim().replace(/\n{2,}/g, '\n');

      switch (paper_width) {
        case "80mm":
          builder.addTextSize(2, 1);
          builder.addText("\n\n" + kot_header + "\n");
          builder.addText(kot_order_identifier + "\n");
          builder.addTextSize(1, 1);
          builder.addText(kot_details + "\n");
          builder.addText(kot_items + "\n");
          builder.addTextStyle(false, false, true);
          builder.addText(kot_voided_items + "\n\n\n");
          break;
        case "76mm":
          builder.addTextSize(1, 1);
          builder.addText("\n" + kot_header + "\n");
          builder.addTextSize(2, 1);
          builder.addText(kot_order_identifier + "\n");
          builder.addTextSize(1, 1);
          builder.addText(kot_details + "\n");
          builder.addText(kot_items + "\n");
          builder.addTextStyle(false, false, true);
          builder.addText(kot_voided_items + "\n\n\n");
          break;
        default:
          builder.addTextSize(1, 1);
          builder.addText("\n" + kot_header + "\n");
          builder.addTextSize(2, 1);
          builder.addText(kot_order_identifier + "\n");
          builder.addTextSize(1, 1);
          builder.addText(kot_details + "\n");
          builder.addText(kot_items + "\n");
          builder.addTextStyle(false, false, true);
          builder.addText(kot_voided_items + "\n\n\n");
          break;
      }

      break;
    case "Receipt":
      let receipt_header_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };
      let receipt_buyer_info_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };
      let receipt_address_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "dataTable",
            options: { maxColumnWidth: 25 },
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_discount_options = {
        selectors: [
          {
            selector: ".receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
        ],
      };

      let receipt_items_options;
      let receipt_totals_options;
      let receipt_footer_options;
      let receipt_vat_options;
      let items;
      let totals;
      let vat;

      switch (paper_width) {
        case "80mm":
          receipt_items_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              // For U220B:
              // {
              //     selector: '.receipt-tbl',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 30, colSpacing: 2 }
              // }
              // For M30:
              {
                selector: ".receipt-tbl",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 6 },
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_totals_options = {
            selectors: [
              // For U220B:
              // {
              //     selector: '.receipt-tbl-totals',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 15, colSpacing: 7 }
              // },
              // For M30:
              {
                selector: ".receipt-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 18, colSpacing: 14 },
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_vat_options = {
            selectors: [
              // For U220B:
              // {
              //     selector: '.receipt-tbl-totals',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 15, colSpacing: 7 }
              // },
              // For M30:
              {
                selector: ".receipt-vat-breakdown",
                format: "dataTable",
                options: { maxColumnWidth: 18, colSpacing: 14 },
              },
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_footer_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              // For U220B:
              // {
              //     selector: '#receipt-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 32 }
              // },
              // For M30:
              {
                selector: "#receipt-footer",
                format: "dataTable",
                options: { maxColumnWidth: 35 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: "#receipt-table-items",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };
          items = align(html_to_text.convert(html, receipt_items_options), 4);
          totals = align(html_to_text.convert(html, receipt_totals_options), 4);
          vat = align(html_to_text.convert(html, receipt_vat_options), 4);
          break;
        case "76mm":
          receipt_items_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
              // For U220B:
              {
                selector: ".receipt-tbl",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 2 },
              },
              // For M30:
              // {
              //     selector: '.receipt-tbl',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 30, colSpacing: 6 }
              // }
            ],
          };

          receipt_totals_options = {
            selectors: [
              // For U220B:
              {
                selector: ".receipt-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 17, colSpacing: 3 },
              },
              // For M30:
              // {
              //     selector: '.receipt-tbl-totals',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 18, colSpacing: 14 }
              // },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_vat_options = {
            selectors: [
              {
                selector: ".receipt-vat-breakdown",
                format: "dataTable",
                options: { maxColumnWidth: 17, colSpacing: 3 },
              },
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_footer_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#receipt-footer",
                format: "dataTable",
                options: { maxColumnWidth: 32 },
              },
              // For M30:
              // {
              //     selector: '#receipt-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 35 }
              // },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: "#receipt-table-items",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };
          items = align(html_to_text.convert(html, receipt_items_options), 1);
          totals = align(html_to_text.convert(html, receipt_totals_options), 1);
          vat = align(html_to_text.convert(html, receipt_vat_options), 1);
          break;
        default:
          receipt_items_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
              // For U220B:
              {
                selector: ".receipt-tbl",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 2 },
              },
              // For M30:
              // {
              //     selector: '.receipt-tbl',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 30, colSpacing: 6 }
              // }
            ],
          };

          receipt_totals_options = {
            selectors: [
              // For U220B:
              {
                selector: ".receipt-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 17, colSpacing: 3 },
              },
              // For M30:
              // {
              //     selector: '.receipt-tbl-totals',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 18, colSpacing: 14 }
              // },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_vat_options = {
            selectors: [
              // For U220B:
              // {
              //     selector: '.receipt-tbl-totals',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 15, colSpacing: 7 }
              // },
              // For M30:
              {
                selector: ".receipt-vat-breakdown",
                format: "dataTable",
                options: { maxColumnWidth: 17, colSpacing: 3 },
              },
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_footer_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#receipt-footer",
                format: "dataTable",
                options: { maxColumnWidth: 32 },
              },
              // For M30:
              // {
              //     selector: '#receipt-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 35 }
              // },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: "#receipt-table-items",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };
          items = align(html_to_text.convert(html, receipt_items_options), 1);
          totals = align(html_to_text.convert(html, receipt_totals_options), 1);
          vat = align(html_to_text.convert(html, receipt_vat_options), 1);
          break;
      }

      let header = html_to_text.convert(html, receipt_header_options).trim();
      let footer = html_to_text.convert(html, receipt_footer_options);
      let buyer_info = align(html_to_text.convert(html, receipt_buyer_info_options), 1).trimEnd();
      let address = align(html_to_text.convert(html, receipt_address_options), 1).trim();
      let discounts = align(html_to_text.convert(html, receipt_discount_options), 1);

      let receiptFormat = [
        { type: 'alignment', value: 'center' },
        { type: 'content', value: 'address' },
        { type: 'content', value: 'header' },
        { type: 'spacer' },
        { type: 'alignment', value: 'left' },
        { type: 'content', value: 'buyer_info' },
        { type: 'content', value: 'items' },
        { type: 'content', value: 'totals' },
        { type: 'content', value: 'discounts', checker: discount => discount.length > 2 },
        { type: 'content', value: 'vat' },
        { type: 'spacer' },
        { type: 'alignment', value: 'center' },
        { type: 'content', value: 'footer' },
        { type: 'spacer' },
      ];

      if (USE_SM_MARKETS_OR_FORMAT) {
        receiptFormat = [
          { type: 'alignment', value: 'center' },
          { type: 'content', value: 'address' },
          { type: 'content', value: 'header' },
          { type: 'spacer' },
          { type: 'alignment', value: 'left' },
          { type: 'content', value: 'items' },
          { type: 'content', value: 'totals' },
          { type: 'content', value: 'discounts', hidden: discounts.length <= 2 },
          { type: 'content', value: 'vat' },
          { type: 'spacer' },
          { type: 'content', value: 'buyer_info', hidden: discounts.length > 2  },
          { type: 'alignment', value: 'center' },
          { type: 'content', value: 'footer' },
          { type: 'spacer' },
        ];
      }

      builder = epson_build(builder, receiptFormat, {
        address,
        header,
        buyer_info,
        items,
        totals,
        discounts,
        vat,
        footer,
      });
      // builder.addText(footer + '\n' + '\n');
      // For U220B:
      break;
    case "EOD":
    case "Report":
      let report_main_options;
      let report_footer_options;
      let report_balance_options;
      let report_numbers_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "dataTable",
            options: { colSpacing: 2 },
          },
        ],
      };

      let z_main;
      let z_footer;
      let z_balance;
      let z_numbers;

      switch (paper_width) {
        case "80mm":
          report_balance_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 9 },
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_main_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              // For U220B:
              // {
              //     selector: '#z-main-tables',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 20, colSpacing: 5 }
              // },
              // For M30:
              {
                selector: "#z-main-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 9 },
              },
              {
                selector: "#hourly-sales-table",
                format: "dataTable",
                options: { maxColumnWidth: 10, colSpacing: 4 },
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_footer_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              // For U220B:
              // {
              //     selector: '#z-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 16, colSpacing: 4 }
              // },
              // For M30:
              {
                selector: "#z-footer",
                format: "dataTable",
                options: { maxColumnWidth: 15, colSpacing: 7 },
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };
          z_main = align(html_to_text.convert(html, report_main_options).trim(), 6.5);
          z_footer = align(html_to_text.convert(html, report_footer_options).trim(), 6.5);
          z_balance = align(html_to_text.convert(html, report_balance_options).trim(), 6.5);
          z_numbers = align(html_to_text.convert(html, report_numbers_options).trim(), 6.5);
          break;
        case "76mm":
          report_balance_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 8.5 },
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_main_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#z-main-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
              {
                selector: "#hourly-sales-table",
                format: "dataTable",
                options: { maxColumnWidth: 10, colSpacing: 4 },
              },
              // For M30:
              // {
              //     selector: '#z-main-tables',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 20, colSpacing: 12 }
              // },
            ],
          };

          report_footer_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#z-footer",
                format: "dataTable",
                options: { maxColumnWidth: 16, colSpacing: 4 },
              },
              // For M30:
              // {
              //     selector: '#z-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 15, colSpacing: 7 }
              // },
            ],
          };

          z_main = align(html_to_text.convert(html, report_main_options).trim(), 1.7);
          z_footer = align(html_to_text.convert(html, report_footer_options).trim(), 1.7);
          z_balance = align(html_to_text.convert(html, report_balance_options).trim(), 1.7);
          z_numbers = align(html_to_text.convert(html, report_numbers_options).trim(), 1.7);
          break;
        default:
          report_balance_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 8.5 },
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_main_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#z-main-tables",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
              {
                selector: "#hourly-sales-table",
                format: "dataTable",
                options: { maxColumnWidth: 10, colSpacing: 4 },
              },
              // For M30:
              // {
              //     selector: '#z-main-tables',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 20, colSpacing: 12 }
              // },
            ],
          };

          report_footer_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
              // For U220B:
              {
                selector: "#z-footer",
                format: "dataTable",
                options: { maxColumnWidth: 16, colSpacing: 4 },
              },
              // For M30:
              // {
              //     selector: '#z-footer',
              //     format: 'dataTable',
              //     options: { maxColumnWidth: 15, colSpacing: 7 }
              // },
            ],
          };

          z_main = align(html_to_text.convert(html, report_main_options).trim(), 1.7);
          z_footer = align(html_to_text.convert(html, report_footer_options).trim(), 1.7);
          z_balance = align(html_to_text.convert(html, report_balance_options).trim(), 1.7);
          z_numbers = align(html_to_text.convert(html, report_numbers_options).trim(), 1.7);
          break;
      }
      let report_header_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "dataTable",
            options: { maxColumnWidth: 29 },
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let eod_title_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: ".eod-title",
            format: "block",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
          {
            selector: ".z-main-tables",
            format: "skip",
          },
          {
            selector: ".z-3col-tables",
            format: "skip",
          },
        ],
      };

      let report_address_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "dataTable",
            options: { maxColumnWidth: 25 },
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let z_header = html_to_text.convert(html, report_header_options).trim();
      let z_address = align(html_to_text.convert(html, report_address_options), 2).trim() + "\n\n";

      if(print_type == "EOD") {
        let skipOption = [
          {
            selector: ".eod-title",
            format: "skip",
          },
          {
            selector: ".z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: ".z-3col-tables",
            format: "skip",
          },
        ];

        report_numbers_options.selectors = report_numbers_options.selectors.map(opt => {
          if(opt.selector == "#z-numbers-tables") {
            opt.options = { maxColumnWidth: 20, colSpacing: 1 }
          }

          return opt;
        });

        report_main_options = {
          selectors: [
            {
              selector: ".modal-footer",
              format: "skip",
            },
            {
              selector: "#modal-close-btn",
              format: "skip",
            },
            {
              selector: "#report-head",
              format: "skip",
            },
            {
              selector: "#z-header",
              format: "skip",
            },
            {
              selector: "#z-footer",
              format: "skip",
            },
            {
              selector: ".report-address",
              format: "skip",
            },
            {
              selector: "#z-balance-tables",
              format: "skip",
            },
            {
              selector: "#z-numbers-tables",
              format: "skip",
            },
            {
              selector: ".z-main-tables",
              format: "dataTable",
              options: { maxColumnWidth: 20, colSpacing: 2 },
            },
            {
              selector: "#hourly-sales-table",
              format: "dataTable",
              options: { maxColumnWidth: 10, colSpacing: 4 },
            },
            {
              selector: ".z-3col-tables",
              format: "dataTable",
              options: { maxColumnWidth: 10, colSpacing: 2 },
            },
            {
              selector: ".eod-title",
              format: "skip",
            },
          ],
        };

        report_numbers_options.selectors.push(...skipOption);
        report_footer_options.selectors.push(...skipOption);
        report_balance_options.selectors.push(...skipOption);
        report_header_options.selectors.push(...skipOption);

        z_main = align(html_to_text.convert(html, report_main_options).trim(), 1.7);
        z_footer = align(html_to_text.convert(html, report_footer_options).trim(), 1.7);
        z_numbers = align(html_to_text.convert(html, report_numbers_options).trim(), 1.7);
        z_header = align(html_to_text.convert(html, report_header_options).trim(), 2);

        let eod_title = align(html_to_text.convert(html, eod_title_options).trim(), 2);

        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addText(eod_title + "\n");
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n" + z_header);
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n" + z_numbers + "\n\n" + z_main + "\n");
      } else {
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addText("\n" + z_address + z_header + "\n");
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n" + z_balance + "\n" + z_numbers + "\n\n" + z_main + "\n");
      }

      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addText("\n" + z_footer + "\n" + "\n" + "\n");
      break;
    case "Void":
      let void_header_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: "#void-items-header",
            format: "skip",
          },
          {
            selector: "#void-items-tbody",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };


      let void_discount_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: "#void-items-header",
            format: "skip",
          },
          {
            selector: "#void-items-tbody",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_items_header_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: "#void-items-header",
            format: "dataTable",
            options: { maxColumnWidth: 20, colSpacing: 2 },
          },
          {
            selector: "#void-items-tbody",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      }

      let void_items_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: "#void-items-header",
            format: "skip",
          },
          {
            selector: "#void-items-tbody",
            format: "dataTable",
            options: { maxColumnWidth: 20, colSpacing: 2 },
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_totals_options;
      let void_vats_options;
      let void_footer_options;
      let void_items;
      let void_totals;
      let void_vats;

      switch (paper_width) {
        case "80mm":
          void_vats_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
            ],
          };

          void_totals_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 15, colSpacing: 7 },
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };

          void_footer_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "dataTable",
                options: { maxColumnWidth: 32 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };
          void_items = align(html_to_text.convert(html, void_items_options), 2);
          void_vats = align(html_to_text.convert(html, void_vats_options), 2);
          void_totals = align(html_to_text.convert(html, void_totals_options), 2);
          break;
        case "76mm":
          void_vats_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: ".void-tbl",
                format: "skip",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
            ],
          };

          void_totals_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 15, colSpacing: 5 },
              },
              {
                selector: ".void-tbl",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };

          void_footer_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: ".void-tbl",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "dataTable",
                options: { maxColumnWidth: 32 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };
          void_items = align(html_to_text.convert(html, void_items_options), 2);
          void_vats = align(html_to_text.convert(html, void_vats_options), 2);
          void_totals = align(html_to_text.convert(html, void_totals_options), 2);
          break;
        default:
          void_vats_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
            ],
          };

          void_totals_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 15, colSpacing: 5 },
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };

          void_footer_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: "#void-items-header",
                format: "skip",
              },
              {
                selector: "#void-items-tbody",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "dataTable",
                options: { maxColumnWidth: 32 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };
          void_items = align(html_to_text.convert(html, void_items_options), 2);
          void_totals = align(html_to_text.convert(html, void_totals_options), 2);
          void_vats = align(html_to_text.convert(html, void_vats_options), 2);
          break;
      }
      let void_items_header = align(html_to_text.convert(html, void_items_header_options), 2);
      let void_header = html_to_text.convert(html, void_header_options).trim();
      let void_footer = html_to_text.convert(html, void_footer_options);
      let void_discounts = html_to_text.convert(html, void_discount_options);
      let combined_texts = void_header + "\n" + void_items_header + "\n" + void_items + "\n" + void_totals + "\n" + void_discounts + "\n" + void_vats + "\n" + void_footer;
      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addText("\n" + void_header + "\n");
      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addText(void_items_header + "\n");
      builder.addTextAlign(builder.ALIGN_LEFT);
      // Makes text bold
      builder.addTextStyle(false, false, true);
      builder.addText(void_items + "\n");
      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addTextStyle(false, false, false);
      builder.addText("\n" + void_totals + "\n");
      builder.addTextAlign(builder.ALIGN_LEFT);
      if (void_discounts.length > 0) {
        builder.addText("\n" + void_discounts + "\n");
      }
      builder.addTextAlign(builder.ALIGN_LEFT);
      builder.addText("\n" + void_vats + "\n\n");
      builder.addTextAlign(builder.ALIGN_CENTER);
      // builder.addText(footer + '\n' + '\n');
      // For U220B:
      builder.addText("\n\n" + void_footer + "\n" + "\n");
      break;
    case "CashFloat":
      let cash_float_header, cash_float_body;
      let cash_float_header_options = {
        selectors: [
          {
            selector: "#cash_float_header",
            format: "dataTable",
            options: { colSpacing: 2 },
          },
          {
            selector: "#cash_float_body",
            format: "skip",
          },
        ],
      }
      let cash_float_body_options = {
        selectors: [
          {
            selector: "#cash_float_header",
            format: "skip",

          },
          {
            selector: "#cash_float_body",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
        ],
      }
      cash_float_header = html_to_text.convert(html, cash_float_header_options);
      cash_float_body = html_to_text.convert(html, cash_float_body_options);

      switch (paper_width) {
        case "80mm":
          builder.addTextSize(2, 1);
          builder.addTextAlign(builder.ALIGN_CENTER);
          builder.addText("\n" + cash_float_header + "\n");
          builder.addTextAlign(builder.ALIGN_LEFT);
          builder.addText("\n" + cash_float_body + "\n");
          break;
        default:
          builder.addTextSize(1, 1);
          builder.addTextAlign(builder.ALIGN_CENTER);
          builder.addText("\n" + cash_float_header + "\n");
          builder.addTextAlign(builder.ALIGN_LEFT);
          builder.addText("\n" + cash_float_body + "\n");
          break;
      }

      break;
    case "XZRead_Custom_Sections":
      //loop through html array
      let custom_section = "";
      html.forEach((d, i) => {
        custom_section = html_to_text.convert(d, {
          selectors: [
            {
              selector: "#custom_xzread-tbl",
              format: "dataTable",
              options: { maxColumnWidth: 16, colSpacing: 1},
            },
          ],
        });

        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addText("\n" + custom_section + "\n");
        if(i < html.length -1) {
          // only add cut on the second to the last element
          builder.addCut(builder.CUT_FEED);
        }
      });

      break;
    case "PlainText":
      switch (paper_width) {
        case "80mm":
          builder.addTextSize(2, 1);
          break;
        default:
          builder.addTextSize(1, 1);
          break;
      }

      builder.addText("\n" + html + "\n\n");

      console.log(html);
      break;
  }

  builder.addCut(builder.CUT_FEED);
  // Acquire the print document
  let request = builder.toString();
  let timeout = paper_width == "76mm" ? 1000 : 10000;
  // Set the end point address
  let address = `https://${ip_address.trim()}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=${timeout}`;

  // Create an ePOS-Print object
  let epos = create_epos_print_object(address);
  let response = true;

  epos.onerror = function (err) {
    console.log("Error:", err);
    response = false;
  };
  epos.onstatuschange = function (status) {
    console.log(status);
  };

  // Set a response receipt callback function
  epos.onreceive = function (res) {
    // Obtain the print result and error code
    response = false;
    let msg = "Print" + (res.success ? "Success" : "Failure") + "\nCode:" + res.code + "\nStatus:\n";
    // Obtain the printer status
    let asb = res.status;

    if (asb & epos.ASB_NO_RESPONSE) {
      msg += " No printer response\n";
    }
    if (asb & epos.ASB_PRINT_SUCCESS) {
      response = true;
      msg += " Print complete\n";
    }
    if (asb & epos.ASB_DRAWER_KICK) {
      msg += ' Status of the drawer kick number 3 connector pin = "H"\n';
    }
    if (asb & epos.ASB_OFF_LINE) {
      msg += " Offline status\n";
    }
    if (asb & epos.ASB_COVER_OPEN) {
      msg += " Cover is open\n";
    }
    if (asb & epos.ASB_PAPER_FEED) {
      msg += " Paper feed switch is feeding paper\n";
    }
    if (asb & epos.ASB_WAIT_ON_LINE) {
      msg += " Waiting for online recovery\n";
    }
    if (asb & epos.ASB_PANEL_SWITCH) {
      msg += " Panel switch is ON\n";
    }
    if (asb & epos.ASB_MECHANICAL_ERR) {
      msg += " Mechanical error generated\n";
    }
    if (asb & epos.ASB_AUTOCUTTER_ERR) {
      msg += " Auto cutter error generated\n";
    }
    if (asb & epos.ASB_UNRECOVER_ERR) {
      msg += " Unrecoverable error generated\n";
    }
    if (asb & epos.ASB_AUTORECOVER_ERR) {
      msg += " Auto recovery error generated\n";
    }
    if (asb & epos.ASB_RECEIPT_NEAR_END) {
      msg += " No paper in the roll paper near end detector\n";
    }
    if (asb & epos.ASB_RECEIPT_END) {
      msg += " No paper in the roll paper end detector\n";

      //show swal error notification
      Swal.fire(
        'Printer Error: No paper detected!',
      );
    }
    if (asb & epos.ASB_BUZZER) {
      msg += " Sounding the buzzer (limited model)\n";
    }
    if (asb & epos.ASB_SPOOLER_IS_STOPPED) {
      msg += " Stop the spooler\n";
    }

    console.log(msg);
  };
  // Send the print document
  epos.send(request);

  return response;
}

export async function escpos_print_formatter(print_type, html, paper_width = 80, itemString = null, qrcode = null) {

  let formatted_html = {};

  switch (print_type) {
    case "KOT":
      let kot_header_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_order_identifier_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_details_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_items_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_service_type_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: ".kot-item",
            format: "skip",
          },
          {
            selector: ".kot-voided-item",
            format: "skip",
          },
          {
            selector: ".kot-service-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
        ],
      }

      let kot_items_wo_service_type_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-order-identifier",
            format: "skip",
          },
          {
            selector: "#kot-details",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: ".kot-service-header",
            format: "skip",
          },
          {
            selector: ".kot-item",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
        ],
      }

      let kot_header = html_to_text.convert(html, kot_header_options);
      let kot_order_identifier = html_to_text.convert(html, kot_order_identifier_options);
      let kot_details = html_to_text.convert(html, kot_details_options);
      let kot_items = html_to_text.convert(html, kot_items_options);
      let kot_service_type = html_to_text.convert(html, kot_service_type_options);
      let kot_items_wo_service_type = html_to_text.convert(html, kot_items_wo_service_type_options);

      formatted_html.kot_header = kot_header;
      formatted_html.kot_order_identifier = kot_order_identifier;
      formatted_html.kot_details = kot_details;
      formatted_html.kot_items = kot_items;
      formatted_html.kot_text_wo_items = "\n" + kot_header + "\n" + kot_order_identifier + "\n" + kot_details + "\n";
      formatted_html.kot_text = formatted_html.kot_text_wo_items + kot_items + "\n\n";

      // For IMIN: EPOS-1260
      formatted_html.kot_service_type = kot_service_type;
      formatted_html.kot_items_wo_service_type = kot_items_wo_service_type;
      break;
    case "Receipt":
    case "QRPayment":
      let itemsColWidth = (paper_width >= 50 && paper_width <=60) ? 9 : 20;
      let itemsColSpace = (paper_width >= 50 && paper_width <=60) ? 1 : 4;

      let receipt_header_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_location_details_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_header_wo_location_details = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_buyer_info_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_items_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "dataTable",
            options: { maxColumnWidth: itemsColWidth, colSpacing: itemsColSpace },
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_totals_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "dataTable",
            options: { maxColumnWidth: 15, colSpacing: 7 },
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_footer_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: "#receipt-table-items",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_vat_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: "#receipt-table-items",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_discount_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: "#receipt-table-items",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 25 },
          },
        ],
        longWordSplit: {
          forceWrapOnLimit: true,
        },
      };

      let receipt_note_options = {
        selectors: [
          {
            selector: "#receipt-note",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          }
        ],
      };

      let indent = 6;
      // EPOS-1260
      formatted_html.receipt_location_details = html_to_text.convert(html, receipt_location_details_options);
      formatted_html.receipt_header_wo_location_details = html_to_text.convert(html, receipt_header_wo_location_details);
      
      formatted_html.receipt_header = html_to_text.convert(html, receipt_header_options);
      formatted_html.receipt_buyer_info = align(html_to_text.convert(html, receipt_buyer_info_options), indent);
      formatted_html.receipt_items = align(html_to_text.convert(html, receipt_items_options), indent);
      formatted_html.receipt_totals = align(html_to_text.convert(html, receipt_totals_options), indent);
      formatted_html.receipt_footer = html_to_text.convert(html, receipt_footer_options);
      formatted_html.vat_breakdown = align(html_to_text.convert(html, receipt_vat_options), indent);
      formatted_html.discounts = align(html_to_text.convert(html, receipt_discount_options), indent);
      formatted_html.identifier = "receipt";

      if(qrcode)  {
        formatted_html.qrcode = qrcode;
        formatted_html.receipt_note = html_to_text.convert(html, receipt_note_options);
      }

      if(IS_PUC_LOCATION && qrcode) {
        formatted_html.qrcode = 'https://pickupcoffee.app.link/B5UKQnKVTBb';
        formatted_html.receipt_note = "DOWNLOAD OUR APP TO PICKUP BEST VALUE OFFERS";
      }

      break;
    case "EOD":
    case "Report":
      let report_main_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "dataTable",
            options: { maxColumnWidth: 31, colSpacing: 2 },
          },
          {
            selector: "#hourly-sales-table",
            format: "dataTable",
            options: { maxColumnWidth: 10, colSpacing: 4 },
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
        longWordSplit: {
          forceWrapOnLimit: true,
        },
      };

      let report_footer_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "dataTable",
            options: { maxColumnWidth: 16, colSpacing: 4 },
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let report_header_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "dataTable",
            options: { maxColumnWidth: 29 },
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let report_balance_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "dataTable",
            options: { maxColumnWidth: 20, colSpacing: 1 },
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let report_numbers_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "dataTable",
            options: { colSpacing: 2 },
          },
        ],
      };

      let eod_title_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: ".eod-title",
            format: "block",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
          {
            selector: ".z-main-tables",
            format: "skip",
          },
          {
            selector: ".z-3col-tables",
            format: "skip",
          },
        ],
      };

      if(print_type == "EOD") {
        let skipOption = [
          {
            selector: ".eod-title",
            format: "skip",
          },
          {
            selector: ".z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: ".z-3col-tables",
            format: "skip",
          },
        ];

        report_numbers_options.selectors = report_numbers_options.selectors.map(opt => {
          if(opt.selector == "#z-numbers-tables") {
            opt.options = { maxColumnWidth: 20, colSpacing: 1 }
          }

          return opt;
        });

        report_main_options = {
          selectors: [
            {
              selector: ".modal-footer",
              format: "skip",
            },
            {
              selector: "#modal-close-btn",
              format: "skip",
            },
            {
              selector: "#report-head",
              format: "skip",
            },
            {
              selector: "#z-header",
              format: "skip",
            },
            {
              selector: "#z-footer",
              format: "skip",
            },
            {
              selector: ".report-address",
              format: "skip",
            },
            {
              selector: "#z-balance-tables",
              format: "skip",
            },
            {
              selector: "#z-numbers-tables",
              format: "skip",
            },
            {
              selector: ".z-main-tables",
              format: "dataTable",
              options: { maxColumnWidth: 31, colSpacing: 2 },
            },
            {
              selector: "#hourly-sales-table",
              format: "dataTable",
              options: { maxColumnWidth: 10, colSpacing: 4 },
            },
            {
              selector: ".z-3col-tables",
              format: "dataTable",
              options: { maxColumnWidth: 10, colSpacing: 2 },
            },
            {
              selector: ".eod-title",
              format: "skip",
            },
          ],
          longWordSplit: {
            forceWrapOnLimit: true,
          },
        };

        report_numbers_options.selectors.push(...skipOption);
        report_footer_options.selectors.push(...skipOption);
        report_balance_options.selectors.push(...skipOption);
        report_header_options.selectors.push(...skipOption);

        let z_main, z_footer, z_numbers, z_header;

        z_main = align(html_to_text.convert(html, report_main_options).trim(), 1.7);
        z_footer = align(html_to_text.convert(html, report_footer_options).trim(), 1.7);
        z_numbers = align(html_to_text.convert(html, report_numbers_options).trim(), 1.7);
        z_header = align(html_to_text.convert(html, report_header_options).trim(), 2);

        let eod_title = align(html_to_text.convert(html, eod_title_options).trim(), 2);

        formatted_html.z_header = eod_title;
        // temporary alignment assignment
        // POS2-1982 Use z_header for z_balance [since it's not used by eod] to avoid huge changes in python API
        // this is for left alignment
        formatted_html.z_balance = z_header;
        formatted_html.z_numbers = z_numbers;
        formatted_html.z_main = z_main;
        formatted_html.z_footer = z_footer;
        formatted_html.identifier = "report";

      } else {
        formatted_html.z_header = html_to_text.convert(html, report_header_options).trim();
        formatted_html.z_balance = html_to_text.convert(html, report_balance_options).trim();
        formatted_html.z_numbers = html_to_text.convert(html, report_numbers_options).trim();
        formatted_html.z_main = html_to_text.convert(html, report_main_options).trim();
        formatted_html.z_footer = html_to_text.convert(html, report_footer_options).trim();
        formatted_html.identifier = "report";
      }
      break;

    case "Void":

      let void_header_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_header_title_only_options = {
        selectors: [
          {
            selector: "#void-slip-title",
            format: "block",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: ".void-slip-header-divider",
            format: "skip",
          },
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "block",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      }

      let void_header_wo_title_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#void-slip-title",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_discount_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_items_options;
      let void_totals_options;
      let void_vats_options;
      let void_footer_options;
      let void_items;
      let void_totals;
      let void_vats;

      void_items_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "dataTable",
            options: { maxColumnWidth: 20, colSpacing: 2 },
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      void_vats_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
            options: { maxColumnWidth: 20, colSpacing: 2 },
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "dataTable",
            options: { maxColumnWidth: 20, colSpacing: 2 },
          },
        ],
      };

      void_totals_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "dataTable",
            options: { maxColumnWidth: 15, colSpacing: 5 },
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      void_footer_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };
      void_items = align(html_to_text.convert(html, void_items_options), 2);
      void_totals = align(html_to_text.convert(html, void_totals_options), 2);
      void_vats = align(html_to_text.convert(html, void_vats_options), 2);

      formatted_html.void_header = html_to_text.convert(html, void_header_options);
      formatted_html.void_header_title_only = html_to_text.convert(html, void_header_title_only_options);
      formatted_html.void_header_wo_title = html_to_text.convert(html, void_header_wo_title_options);
      formatted_html.void_footer = html_to_text.convert(html, void_footer_options);
      formatted_html.void_discounts = html_to_text.convert(html, void_discount_options);
      formatted_html.void_items = align(html_to_text.convert(html, void_items_options), 2);
      formatted_html.void_totals = align(html_to_text.convert(html, void_totals_options), 2);
      formatted_html.void_vats = align(html_to_text.convert(html, void_vats_options), 2);
      formatted_html.identifier = "void";
      break;

    case "QRCode":
      formatted_html.qrcode = qrcode;
      formatted_html.receipt_note = itemString;

      break;
    
    case "CashFloat":
        let cash_float_header, cash_float_body;
        let cash_float_header_options = {
          selectors: [
            {
              selector: "#cash_float_header",
              format: "dataTable",
              options: { colSpacing: 2 },
            },
            {
              selector: "#cash_float_body",
              format: "skip",
            },
          ],
        }
        let cash_float_body_options = {
          selectors: [
            {
              selector: "#cash_float_header",
              format: "skip",
  
            },
            {
              selector: "#cash_float_body",
              format: "block",
              options: { maxColumnWidth: 28 },
            },
          ],
        }
        cash_float_header = html_to_text.convert(html, cash_float_header_options);
        cash_float_body = html_to_text.convert(html, cash_float_body_options);

        formatted_html.cash_float_header = cash_float_header;
        formatted_html.cash_float_body = cash_float_body;
        formatted_html.cash_float_text = "\n" + cash_float_header + "\n" + cash_float_body + "\n\n";

        break;
  }
  return formatted_html;
}

//Star Printer Methods
export function star_print(ip_address, print_type, html, paper_width = '80mm') {
  let builder = new StarWebPrintBuilder();
  let request = '';

  request += builder.createInitializationElement();

  switch (print_type) {
    case "KOT":
      let kot_header_options = {
        selectors: [
          {
            selector: "#kot-items",
            format: "skip",
          },
          {
            selector: "#kot-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_items_options = {
        selectors: [
          {
            selector: "#kot-header",
            format: "skip",
          },
          {
            selector: "#kot-items",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
        ],
      };

      let kot_header = html_to_text.convert(html, kot_header_options);
      let kot_items = html_to_text.convert(html, kot_items_options);

      request += builder.createAlignmentElement({position:'left'});
      switch (paper_width) {
        case "80mm":
        default:
          request += builder.createTextElement({data: `\n ${kot_header} \n\n`});
          request += builder.createTextElement({emphasis:true, width: 2, data: `${kot_items} \n\n\n`});
          break;
      }
    break;

    case "Receipt":
      let receipt_header_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "block",
            options: { maxColumnWidth: 20 },
          },
          {
            selector: ".transaction_details",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_transaction_details = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: 'skip',
          },
          {
            selector: ".transaction_details",
            format: "block",
            options: { maxColumnWidth: 20 },
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_buyer_info_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: ".transaction_details",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };
      let receipt_address_options = {
        selectors: [
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: ".transaction_details",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "dataTable",
            options: { maxColumnWidth: 25 },
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
          {
            selector: ".receipt-discounts",
            format: "skip",
          },
        ],
      };

      let receipt_discount_options = {
        selectors: [
          {
            selector: ".receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: ".receipt-tbl-totals",
            format: "skip",
          },
          {
            selector: ".receipt-tbl",
            format: "skip",
          },
          {
            selector: ".receipt-tbl-header",
            format: "skip",
          },
          {
            selector: ".transaction_details",
            format: "skip",
          },
          {
            selector: "#receipt-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#receipt-close-btn",
            format: "skip",
          },
          {
            selector: ".buyer-info",
            format: "skip",
          },
          {
            selector: ".receipt-address",
            format: "skip",
          },
          {
            selector: ".receipt-details",
            format: "skip",
          },
          {
            selector: ".receipt-vat-breakdown",
            format: "skip",
          },
        ],
      };

      let receipt_items_options;
      let receipt_totals_options;
      let receipt_footer_options;
      let receipt_vat_options;
      let items;
      let totals;
      let vat;

      switch (paper_width) {
        case "80mm":
        default:
          receipt_items_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: ".transaction_details",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".receipt-tbl",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 6 },
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_totals_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 18, colSpacing: 14 },
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: ".transaction_details",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_vat_options = {
            selectors: [
              {
                selector: ".receipt-vat-breakdown",
                format: "dataTable",
                options: { maxColumnWidth: 18, colSpacing: 14 },
              },
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: ".transaction_details",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };

          receipt_footer_options = {
            selectors: [
              {
                selector: ".receipt-tbl-totals",
                format: "skip",
              },
              {
                selector: ".receipt-tbl-header",
                format: "skip",
              },
              {
                selector: ".transaction_details",
                format: "skip",
              },
              {
                selector: "#receipt-footer",
                format: "dataTable",
                options: { maxColumnWidth: 60 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#receipt-close-btn",
                format: "skip",
              },
              {
                selector: "#receipt-table-items",
                format: "skip",
              },
              {
                selector: ".buyer-info",
                format: "skip",
              },
              {
                selector: ".receipt-address",
                format: "skip",
              },
              {
                selector: ".receipt-details",
                format: "skip",
              },
              {
                selector: ".receipt-vat-breakdown",
                format: "skip",
              },
              {
                selector: ".receipt-discounts",
                format: "skip",
              },
            ],
          };
          items = html_to_text.convert(html, receipt_items_options);
          totals = html_to_text.convert(html, receipt_totals_options);
          vat = html_to_text.convert(html, receipt_vat_options).trim();
        break;
      }

      let header = html_to_text.convert(html, receipt_header_options).trim();
      let transaction_details = html_to_text.convert(html, receipt_transaction_details).trim();
      let footer = html_to_text.convert(html, receipt_footer_options);
      let buyer_info = html_to_text.convert(html, receipt_buyer_info_options).trimEnd();
      let address = html_to_text.convert(html, receipt_address_options).trim();
      let discounts = html_to_text.convert(html, receipt_discount_options);

      let receiptFormat = [
        { type: 'alignment', value: 'center' },
        { type: 'content', value: 'address' },
        { type: 'content', value: 'header' },
        { type: 'spacer' },
        { type: 'alignment', value: 'left' },
        { type: 'content', value: 'transaction_details' },
        { type: 'spacer' },
        { type: 'content', value: 'buyer_info' },
        { type: 'content', value: 'items' },
        { type: 'content', value: 'totals' },
        { type: 'content', value: 'discounts', checker: discount => discount.length > 2 },
        { type: 'content', value: 'vat' },
        { type: 'spacer' },
        { type: 'alignment', value: 'center' },
        { type: 'content', value: 'footer' },
        { type: 'spacer' },
      ];

      if (USE_SM_MARKETS_OR_FORMAT) {
        receiptFormat = [
          { type: 'alignment', value: 'center' },
          { type: 'content', value: 'address' },
          { type: 'content', value: 'header' },
          { type: 'spacer' },
          { type: 'alignment', value: 'left' },
          { type: 'content', value: 'transaction_details' },
          { type: 'spacer' },
          { type: 'content', value: 'items' },
          { type: 'content', value: 'totals' },
          { type: 'content', value: 'discounts', hidden: discounts.length <= 2 },
          { type: 'content', value: 'vat' },
          { type: 'spacer' },
          { type: 'content', value: 'buyer_info', hidden: discounts.length > 2  },
          { type: 'alignment', value: 'center' },
          { type: 'content', value: 'footer' },
          { type: 'spacer' },
        ];
      }

      request += star_build(builder, receiptFormat, {
        address,
        header,
        transaction_details,
        buyer_info,
        items,
        totals,
        discounts,
        vat,
        footer,
      });
    break;

    case "Report":
      let report_main_options;
      let report_footer_options;
      let report_balance_options;
      let report_numbers_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "dataTable",
            options: { maxColumnWidth: 60, colSpacing: 26 },
          },
        ],
      };

      let z_main;
      let z_footer;
      let z_balance;
      let z_numbers;

      switch (paper_width) {
        case "80mm":
        default:
          report_balance_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: "#z-balance-tables",
                format: "dataTable",
                options: { maxColumnWidth: 60, colSpacing: 25 },
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_main_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 9 },
              },
              {
                selector: "#hourly-sales-table",
                format: "dataTable",
                options: { maxColumnWidth: 10, colSpacing: 4 },
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };

          report_footer_options = {
            selectors: [
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: "#modal-close-btn",
                format: "skip",
              },
              {
                selector: "#report-head",
                format: "skip",
              },
              {
                selector: "#z-header",
                format: "skip",
              },
              {
                selector: "#z-main-tables",
                format: "skip",
              },
              {
                selector: ".hourly-sales-header",
                format: "skip",
              },
              {
                selector: "#hourly-sales-table",
                format: "skip",
              },
              {
                selector: ".report-address",
                format: "skip",
              },
              {
                selector: "#z-footer",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 16},
              },
              {
                selector: "#z-balance-tables",
                format: "skip",
              },
              {
                selector: "#z-numbers-tables",
                format: "skip",
              },
            ],
          };
          z_main = html_to_text.convert(html, report_main_options).trim();
          z_footer = html_to_text.convert(html, report_footer_options).trim();
          z_balance = html_to_text.convert(html, report_balance_options).trim();
          z_numbers = html_to_text.convert(html, report_numbers_options).trim();
          break;
      }
      let report_header_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "skip",
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let report_address_options = {
        selectors: [
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: "#modal-close-btn",
            format: "skip",
          },
          {
            selector: "#report-head",
            format: "skip",
          },
          {
            selector: "#z-header",
            format: "skip",
          },
          {
            selector: "#z-main-tables",
            format: "skip",
          },
          {
            selector: ".hourly-sales-header",
            format: "skip",
          },
          {
            selector: "#hourly-sales-table",
            format: "skip",
          },
          {
            selector: "#z-footer",
            format: "skip",
          },
          {
            selector: ".report-address",
            format: "dataTable",
            options: { maxColumnWidth: 30 },
          },
          {
            selector: "#z-balance-tables",
            format: "skip",
          },
          {
            selector: "#z-numbers-tables",
            format: "skip",
          },
        ],
      };

      let z_header = html_to_text.convert(html, report_header_options).trim();
      let z_address = align(html_to_text.convert(html, report_address_options), 2).trim() + "\n\n";

      request += builder.createAlignmentElement({position: 'center'});
      request += builder.createTextElement({data: `\n ${z_address}  ${z_header} \n`});
      request += builder.createAlignmentElement({position: 'left'});
      request += builder.createTextElement({data: `\n${z_balance}\n${z_numbers}\n${z_main}\n`});
      request += builder.createAlignmentElement({position: 'left'});
      request += builder.createTextElement({data: `\n${z_footer} \n` });

    break;

    case "Void":
      let void_header_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "block",
            options: { maxColumnWidth: 28 },
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "skip",
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_discount_options = {
        selectors: [
          {
            selector: ".void-tbl-totals",
            format: "skip",
          },
          {
            selector: ".void-tbl",
            format: "skip",
          },
          {
            selector: "#or-void-header",
            format: "skip",
          },
          {
            selector: "#void-footer",
            format: "skip",
          },
          {
            selector: ".modal-footer",
            format: "skip",
          },
          {
            selector: ".modal-header",
            format: "skip",
          },
          {
            selector: "#void-close-btn",
            format: "skip",
          },
          {
            selector: "#void-receipt-discounts",
            format: "dataTable",
            options: { maxColumnWidth: 32 },
          },
          {
            selector: ".void-vats",
            format: "skip",
          },
        ],
      };

      let void_items_options;
      let void_totals_options;
      let void_vats_options;
      let void_footer_options;
      let void_items;
      let void_totals;
      let void_vats;

      switch (paper_width) {
        case "80mm":
        default:
          void_items_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: ".void-tbl",
                format: "dataTable",
                options: { maxColumnWidth: 30, colSpacing: 6 },
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };

          void_vats_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: ".void-tbl",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "dataTable",
                options: { maxColumnWidth: 20, colSpacing: 2 },
              },
            ],
          };

          void_totals_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "dataTable",
                options: { maxColumnWidth: 15, colSpacing: 7 },
              },
              {
                selector: ".void-tbl",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "skip",
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };

          void_footer_options = {
            selectors: [
              {
                selector: ".void-tbl-totals",
                format: "skip",
              },
              {
                selector: ".void-tbl",
                format: "skip",
              },
              {
                selector: "#or-void-header",
                format: "skip",
              },
              {
                selector: "#void-footer",
                format: "dataTable",
                options: { maxColumnWidth: 60 },
              },
              {
                selector: ".modal-footer",
                format: "skip",
              },
              {
                selector: ".modal-header",
                format: "skip",
              },
              {
                selector: "#void-close-btn",
                format: "skip",
              },
              {
                selector: "#void-receipt-discounts",
                format: "skip",
              },
              {
                selector: ".void-vats",
                format: "skip",
              },
            ],
          };
          void_items = html_to_text.convert(html, void_items_options);
          void_vats = html_to_text.convert(html, void_vats_options);
          void_totals = html_to_text.convert(html, void_totals_options);
        break;
      }

      let void_header = html_to_text.convert(html, void_header_options);
      let void_footer = html_to_text.convert(html, void_footer_options);
      let void_discounts = html_to_text.convert(html, void_discount_options);

      request += builder.createAlignmentElement({position: 'left'});
      request += builder.createTextElement({ data: void_header });
      request += builder.createTextElement({ data: void_items + "\n"});
      request += builder.createTextElement({ data: void_totals });
      if (void_discounts.length > 0) {
        request += builder.createTextElement({ data: "\n" + void_discounts + "\n"});
      }
      request += builder.createAlignmentElement({position: 'left'});
      request += builder.createTextElement({ data: void_vats + "\n"});
      request += builder.createAlignmentElement({ position: 'center'});

      request += builder.createTextElement({data: void_footer + "\n"});
    break;

    default:
      break;
  }

  request += builder.createCutPaperElement({feed:true});

  sendMessage(request, ip_address);
}


export function sendMessage(request, ip_address) {
  let url = `http://${ip_address}/StarWebPRNT/SendMessage`;
  let papertype = '';
  let result = true;
  var trader = new StarWebPrintTrader({url:url, papertype:papertype});

  trader.onReceive = function (response) {
      var msg = '- onReceive -\n\n';

      msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';

      msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';

      if (trader.isCoverOpen            ({traderStatus:response.traderStatus})) {msg += '\tCoverOpen,\n';}
      if (trader.isOffLine              ({traderStatus:response.traderStatus})) {msg += '\tOffLine,\n';}
      if (trader.isCompulsionSwitchClose({traderStatus:response.traderStatus})) {msg += '\tCompulsionSwitchClose,\n';}
      if (trader.isEtbCommandExecute    ({traderStatus:response.traderStatus})) {msg += '\tEtbCommandExecute,\n';}
      if (trader.isHighTemperatureStop  ({traderStatus:response.traderStatus})) {msg += '\tHighTemperatureStop,\n';}
      if (trader.isNonRecoverableError  ({traderStatus:response.traderStatus})) {msg += '\tNonRecoverableError,\n';}
      if (trader.isAutoCutterError      ({traderStatus:response.traderStatus})) {msg += '\tAutoCutterError,\n';}
      if (trader.isBlackMarkError       ({traderStatus:response.traderStatus})) {msg += '\tBlackMarkError,\n';}
      if (trader.isPaperEnd             ({traderStatus:response.traderStatus})) {msg += '\tPaperEnd,\n';}
      if (trader.isPaperNearEnd         ({traderStatus:response.traderStatus})) {msg += '\tPaperNearEnd,\n';}

      msg += '\tEtbCounter = ' + trader.extractionEtbCounter({traderStatus:response.traderStatus}).toString() + ' ]\n';


  }

  trader.onError = function (response) {
      var msg = '- onError -\n\n';

      msg += '\tStatus:' + response.status + '\n';

      msg += '\tResponseText:' + response.responseText + '\n\n';

      msg += 'Do you want to retry?\n';

      var answer = confirm(msg);

      if (answer) {
          sendMessage(request);
      }
      else {

      }
      result = false;
  }

  trader.sendMessage({request:request});

  return result;
}
