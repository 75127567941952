import {action, DataOffloadBridge} from "@/mobile_bridge/offload/offload";

export class PrinterDetailBridge extends DataOffloadBridge {
    constructor() {
        super('PrinterDetail');
    }

    async bulkImport(data) {
        const {location_printers, printer_details, printer_models} = data ?? {};

        if (location_printers) {
            const plb = new PrinterLocationBridge();
            await plb.bulkImport(location_printers);
        }

        if (printer_models) {
            const pmb = new PrinterModelBridge();
            await pmb.bulkImport(printer_models);
        }

        if (printer_details) {
            await this.sendMessage(action.IMPORT, { rows: printer_details });
        }
    }

    async getAll() {
        const plb = new PrinterLocationBridge();
        const pmb = new PrinterModelBridge();
        const { rows: location_printers } = await plb.getAll();
        const { rows: printer_models } = await pmb.getAll();
        const { rows: printer_details } = await this.sendMessage(action.GET_ALL);

        return {
            data: {
                location_printers,
                printer_details,
                printer_models
            }
        }
    }
}

export class PrinterLocationBridge extends DataOffloadBridge {
    constructor() {
        super('PrinterLocation');
    }

}

export class PrinterModelBridge extends DataOffloadBridge {
    constructor() {
        super('PrinterModel');
    }

}
