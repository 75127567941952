/* globals tempMaxUnsyncTransactions */

import { mapState, mapMutations } from "vuex";
import { SYNC_STATUSES } from "@/spa/constants";
import { getSyncedOrders } from "@/spa/services/sync-service";
import { debounce } from 'lodash';

export default {
  data() {
    return {
      debouncedUpdateSyncStatus: () => null,
    };
  },

  computed: {
    ...mapState(["settlements", "orders", "unsyncVoidedBillCount"]),

    ...mapState("global", ["syncStatus"]),

    unsyncVoidedTransactionCount() {
      return this.orders.filter(
        (order) => order.isVoided && !order.isUpdateSynced
      ).length;
    },

    unsyncedOrdersCount() {
      return this.orders.filter(o => o && o.isBilled && !(o.isSync || o.isUpdateSynced)).length;
    },

    unsycTransactionsCount() {
      return (
        this.unsyncedOrdersCount +
        this.unsyncVoidedTransactionCount +
        this.unsyncVoidedBillCount
      );
    },
  },

  mounted() {
    this.debouncedUpdateSyncStatus = debounce(this.setSyncStatus.bind(this), 3000);
  },

  methods: {
    ...mapMutations(["setUnsyncVoidedBillCount"]),

    ...mapMutations("global", ["setSyncStatus"]),

    async updateUnsyncVoidedBillCount() {
      const unsyncVoidedBills = await getSyncedOrders();
      const unsyncVoidedBillCount = unsyncVoidedBills.filter(
        (unsyncVoidedBill) => unsyncVoidedBill &&
          unsyncVoidedBill.isVoided && !unsyncVoidedBill.isUpdateSynced
      ).length;

      this.setUnsyncVoidedBillCount(unsyncVoidedBillCount);
    },
  },

  watch: {
    unsycTransactionsCount: {
      handler(newValue, oldValue) {
        switch (true) {
          case newValue === 0:
            this.debouncedUpdateSyncStatus(SYNC_STATUSES.SYNCED);
            break;

          case newValue >= tempMaxUnsyncTransactions && newValue > oldValue:
            this.debouncedUpdateSyncStatus(SYNC_STATUSES.FULL);
            break;

          case newValue < tempMaxUnsyncTransactions && newValue > oldValue:
            this.debouncedUpdateSyncStatus(SYNC_STATUSES.UNSYNC);
            break;

          default:
            break;
        }
      },
      immediate: true,
    }
  },

  async created() {
    this.updateUnsyncVoidedBillCount()
  },
};
