import { mapMutations } from 'vuex'
export default {
    methods: {
        ...mapMutations('modals', [
            'openApproversModal',
            'closeApproversModal',
            'setApproversCallback',
        ]),

        async $openApproverModal(permissionType=null) {
            return new Promise(resolve => {
                this.setApproversCallback(result => {
                    this.closeApproversModal();
                    resolve(result);
                });

                this.openApproversModal(permissionType);
            });
        },
    }
}