<template>
    <base-modal
        v-bind="$attrs"
        @confirm="applyComp"
        min-width="0"
        max-width="700px"
        min-height="100px"
        width="100%"
        :confirmLabel="hasComp ? 'Remove' : 'Add'"
        >
        <template v-slot:title>Comp Item</template>

        <hr />

        <div class="comp-item">
            <div v-if="item" class="row d-flex justify-content-between align-items-start">
                <div class="item-details col-lg-8 col-8">
                    <h2 class="item-name" v-text="item.product.product_name" />
                    <h4 class="item-modifiers" v-text="modifiersString" />
                </div>

                <div class="item-price col-lg-4 col-4">
                    ₱{{ getTotalItemRawPrice(item.quantity).toFixed(2) }}
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="row px-0 py-2">
                        <div class="col-lg-12">
                            <label class="mx-0">Select a reason for comping this item</label>
                        </div>
                        <div class="col-lg-6 col-md-12" v-for="(cancellationReason, key) in cancellationReasons">
                            <label class="forced-modifier-box my-1 d-flex d-lg-inline" :for="'cancellation-reason-' +key">
                                <input type="radio" name="cancellation-reason" v-model="compReasonId" :value="cancellationReason.id" :id="'cancellation-reason-' +key" @click="checkCancellationReasonId($event)" :disabled="hasComp"/>
                                <div class="forced-moditem-content disctype-box">
                                    <p>{{ cancellationReason.cancellation_reason_name }}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row py-2">
                        <div class="col-lg-12 comp-title">
                            <label class="mx-0">Select a percentage comp</label>
                        </div>
                        <div class="col-lg-4 col-12" v-for="(cancellationRate, key) in cancellationRates">
                            <label class="comp-rate my-1 d-flex d-lg-inline" :for="'cancellation-rate-' +key">
                                <input type="radio" name="cancellation-rate"  class="radio-cancel" v-model="compRate" :value="cancellationRate.rate" :id="'cancellation-rate-' +key" @click="checkCancellationRate($event)" :disabled="hasComp"/>
                                <div class="comp-rate-box">
                                    <p>{{ Math.round(cancellationRate.rate * 100) }} %</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';

import { get, some, isEmpty } from 'lodash';
import { LineItemMixin } from '@/spa/components/mixins/ComputationMixin';

export default {
    components: {
        BaseModal,
    },

    mixins: [
        LineItemMixin,
    ],

    props: {
        cancellationReasons: {
            type: [Object, null],
            required: true,
        },

        cancellationRates: {
            type: [Object, null],
            required: true,
        },

        hasComp: {
            type: [Boolean, null],
            required: false,
        },

        compDiscount: {
            type: [Object, null],
            required: false,
        },
    },

    data() {
        return {
            compReasonId: '',
            compRate: '',
            compDetail: [],
        }
    },

    watch: {

        item: {
            handler() {
                this.compReasonId = get(this.item, 'comp.compReasonId', '');
                this.compRate = get(this.item,'comp.compRate','');
            },
        },
    },

    computed: {
        itemTotalPrice() {
            return (this.item) ? this.item.total.toFixed(2) : 0;
        },

        modifiersString() {
            if (!this.item) return '';

            return [
                ...(this.item.product.forcedMods || []),
                ...(this.item.product.unforcedMods || []),
            ].map(mod => `${mod.quantity || 1} ${mod.modifier_name}`).join(', ');
        },
    },

    methods: {

        async applyComp() {

            if (some([this.compReasonId, this.compRate], item => isEmpty(item.toString()))) {
                this.$swal.warning('Please fill in all the details');
                return;
            }

            const compItem = (this.cancellationReasons).find(item => item.id == this.compReasonId)

            let response = null;

            response = await this.$openApproverModal();
            if (!response.success) {
                if (response.cancelled) {
                    this.$swal.warning('Comp authorization cancelled');
                }

                return;
            };

            if(this.hasComp) {

                this.$emit('compRemoved', {
                    compReasonId: this.item.compReasonId,
                    approver: response ? response.approver : null,
                });

            } else {

                //do minor computations for the compamount
                let net = this.item ? (this.itemRawPrice / (this.totalPercentage || 1)) : 0;
                let discountAmount = 0;

                if (this.compDiscount.is_vat_based != 1) {
                    discountAmount = this.itemRawPrice * this.compRate * this.item.quantity;
                } else {
                    discountAmount = net * this.compRate * this.discountPax;
                }

                this.$emit('compSelected', {
                    compReasonId: compItem.id,
                    compReason: compItem.cancellation_reason_name,
                    compRatePercentage: this.compRate * 100,
                    compRate: this.compRate,
                    compAmount: discountAmount,
                    compName: this.compDiscount.discount_name,
                    approver: response ? response.approver : null,
                    has_service_charge: this.compDiscount.has_service_charge,
                    has_customer_details: this.compDiscount.has_customer_details,
                });

            }



        },

        checkCancellationRate(event) {

            //check if discountid is equal to clicked discount id, if yes remove discount
            if(this.compRate == event.target.value) {
                this.compRate = '';
            }
        },

        checkCancellationReasonId(event) {

            //check if discountid is equal to clicked discount id, if yes remove discount
            if(this.compReasonId == event.target.value) {
                this.compReasonId = '';
            }
        },

    },
}
</script>

<style scoped>
.forced-moditem-content.disctype-box {
    width: 100%;
}
.item-name {
    font-size: 2rem;
    color: #1b75bb;
    font-weight: bold;
}

.item-price {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Exo', sans-serif;
    text-align: right;
}

.comp-item {
    width: 600px;
}

@media screen and (max-width: 992px) {
    .comp-item {
        width: 100%;
    }
}

.comp-rate {
    cursor: pointer;
    margin: 8px;
    width: 100%;
}

.comp-rate .radio-cancel {
    display: none;
}
.comp-rate .comp-rate-box {
    background-color: #dbeaf9;
    color: #000;
    height: auto;
    padding: 12px;
    text-align: center;
}
.radio-cancel:checked ~ .comp-rate-box {
    height: auto;
    background-color: #1b75bb;
    color: #fff;
}

@media screen and (max-width: 992px) {
    .modifier-box div p, .forced-modifier-box div p {
        margin: 0 !important;
        position: relative !important;
        bottom: 0 !important;
    }
}
</style>