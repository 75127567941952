import {isEmpty} from "lodash";

export default {
    computed: {
        activeOrderPax() {
            if (!this.activeOrder) return 0;
            if (isEmpty(this.activeOrder.tableMergedWith)) return this.activeOrder.pax;

            const mergedOrders = this.pendingOrders.filter(o => this.activeOrder.tableMergedWith.includes(o.tableId));
            return mergedOrders.reduce((acc, o) => acc + o.pax, this.activeOrder.pax);
        }
    }
}
