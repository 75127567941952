<template>
    <Popper
        placement="top"
        hover="true"
        :disabled="!mappedSectionLabels.length > 0"
        class="print-sections-btn"
        >
        <button class="btn btn-prim my-1" id="print-sections-btn" @click="$emit('printSections')" :disabled="!mappedSectionLabels.length > 0">Print Sections</button>

        <template #content>
            {{mappedSectionLabels}}
        </template>
    </Popper>
</template>

<script>
import Popper from "vue3-popper";

export default {
    name: "BPrintZReadSections",
    components: {
      Popper,
    },

    props: {
        sections: {
            type: String,
            required: true,
        },
    },

    computed: {
        mappedSectionLabels() {
            const labels = {
                'over_short': "Over/Short",
                'pmix': "Product Mix"
            };

        const sections = this.sections ? JSON.parse(this.sections) : [];
        return sections.map(s => this.labels[s]).join(",");        }
    },
  };
</script>
<style scoped>
    .print-sections-btn {
        display: block !important;
    }
</style>