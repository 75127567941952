import cashierService from '@/spa/services/cashier-service';
import discountService from '@/spa/services/discount-service';
import printerService from '@/spa/services/printer-service';
import productService from '@/spa/services/product-service';
import syncService from '@/spa/services/sync-service';
import tableService from '@/spa/services/table-service';
import userService from '@/spa/services/user-service';
import user from '@/spa/services/user';
import {OFFLOAD} from "@/spa/constants";

const toggle = document.querySelector('.offline-toggle');

const prefetchServices = [
    cashierService,
    discountService,
    printerService,
    productService,
    syncService,
    tableService,
    userService,
    user,
];

let prefetchBlacklist = [
    'getDiscountItem',
    'getXreadDetails'
];

const getPrefetchMethods = (service) => {
    if (OFFLOAD.sqliteOffloadProduct) {
        prefetchBlacklist = prefetchBlacklist.concat([
            'getOwnBrands',
            'getChannels',
            'getAllProducts',
            'getOpenFoodItem',
            'getPromos',
            'getTaxes',
            'getProductPricings'
        ])
    }

    if (OFFLOAD.sqliteOffloadModifier) {
        prefetchBlacklist.push('getAllModifiers')
    }

    if (OFFLOAD.sqliteOffloadReceiptDetail) {
        prefetchBlacklist.push('getReceiptDetails');
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
        prefetchBlacklist = prefetchBlacklist.concat([
            'getLastKot',
            'getFreshLastKot',
            'getLatestSeries',
            'getLatestOrderId',
            'getLatestOrders',
            'getDateRangeOrders',
        ])
    }

    if (OFFLOAD.sqliteOffloadPrinter) {
        prefetchBlacklist.push('getPrinterIps');
    }

    if (OFFLOAD.sqliteOffloadTableDetail) {
        prefetchBlacklist.push('getTableLayout');
    }

    if (OFFLOAD.sqliteOffloadPosDate) {
        prefetchBlacklist.push('getPosDate');
    }

    if (OFFLOAD.sqliteOffloadPOSFE1358) {
        prefetchBlacklist = prefetchBlacklist.concat([
            'getPaymentMethods',
            'getBillLevelDiscounts',
            'getAllDiscountItems',
            'getBillsDenomination',
            'getVoidReasons',
            'getApprovers',
            'getUserInfo',
            'getMe',
        ]);
    }

    return Object.keys(service)
        .filter(key => key.startsWith('get') && !prefetchBlacklist.includes(key))
        .map(key => service[key]);
}

let prefetchCount = 0;
export async function prefetchAll() {
    const methods = prefetchServices.map(service => getPrefetchMethods(service)).flat();

    prefetchCount++;
    if (prefetchCount <= 1) {
        return;
    }

    let progress = 0;
    let total = methods.length;
    let done = false;
    if (toggle) {
        setTimeout(() => {
            if (done) return;
            toggle.classList.remove('ready');
            toggle.classList.remove('initial');
        }, 200)
    }

    const tryPreFetch = async method => {
        try {
            await method();
        } catch (e) {
            console.error(e);
            return tryPreFetch(method);
        }

        progress++;
        if (!toggle) return;

        toggle.setAttribute('style', `--prefetch-progress: '${(progress / total * 100).toFixed(2)}'`);

        if (progress === total) {
            done = true;
            toggle.classList.add('ready');
            toggle.classList.remove('initial');
        }
    };

    return Promise.all(methods.map(tryPreFetch));
}

prefetchAll();
