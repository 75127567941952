<template>
  <div class="settings-page">
    <div class="d-flex flex-nowrap">
      <div class="settings-page p-4">
        <label>Settings Page</label>
      </div>
    </div>
    <div class="row mx-2">
      <div class="col-xl-4 order-lg-12">
        <SettingsSidebar />
      </div>
      <div class="col">
        <div class="tab-panel" id="no-selected" role="tabpanel">
          <div class="row">
            <div>
              <i class="fa fa-info-circle mb-3"></i>
              <h5 class="d-none d-lg-block">Please select an option from the right section</h5>
              <h5 class="d-lg-none text-center">Please select an option from the top section</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
export default {
  name: 'Settings',

  components: {
    SettingsSidebar
  },

  data() {
    return {
    };
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

@media screen and (max-width: 720px) {
  .settings-page {
    overflow-y: auto;
    max-height: calc(100vh - 86px);
  }
}

</style>