<template>
    <base-modal
        v-bind="$attrs"
        min-width="0"
        max-width="800"
        min-height="100px"
        width="100%"
        :confirm-label="hasExistingDiscount ? 'Remove Discount' : 'Apply Discount'"
        @before-open="checkForExistingDiscount"
        @confirm="toggleDiscount"
        >
        <template #title>Bill Discounts</template>
        <hr />
        <div class="d-lg-flex bill-discount" :class="{ locked: hasExistingDiscount }">
            <div class="row">
                <div :class="{ [selectedDiscount ? 'col-lg-7 order-lg-1 order-2' : 'col-lg-9 order-lg-1 order-2']: true }">
                    <label>
                        Discount type <span>Pick 1</span>
                    </label>
                    <div class="form-check mod-radio d-lg-none discount-radio">
                        <div v-for="discount in discounts" :key="discount.discount_detail_id" class="d-flex align-items-baseline">
                            <input type="radio" :id="'discount-' + discount.discount_detail_id" v-model="selectedDiscount" :value="discount" @change="selectDiscount(discount)">
                            <label :for="'discount-' + discount.discount_detail_id" class="discount-radio-label">
                            <div class="discount-name font-weight-bold">{{ discount.discount_name }} {{ getDiscountAmount(discount) }}</div>
                            <div class="max-transaction-amount">{{ getMaxDiscountLabel(discount.max_discount_amount) }}</div>
                            <div class="meal-combination-amount">{{ getMEMCLabel(discount.meal_combination_amount) }}</div>
                            </label>
                        </div>
                    </div>

                    <div class="mods-row d-none d-lg-flex flex-wrap">
                        <m-card v-for="discount in discounts" :key="discount.discount_detail_id"
                            :header="getDiscountAmount(discount)"
                            :active="selectedDiscount && selectedDiscount.discount_detail_id == discount.discount_detail_id"
                            clickable ratio="1:1" class="col-4 mx-0" @click="selectDiscount(discount)">
                            <div class="font-weight-bold">{{ discount.discount_name }}</div>
                            <div class="max-transaction-amount">{{ getMaxDiscountLabel(discount.max_discount_amount) }}</div>
                            <div class="meal-combination-amount">{{ getMEMCLabel(discount.meal_combination_amount) }}</div>
                        </m-card>
                    </div>
                </div>

                <div v-if="selectedDiscount" class="col-lg-5 order-lg-2 order-1">
                   
                    <div class="col-md-12 d-lg-block d-none">
                        <div class="row">
                            <h4>Quantity:</h4>
                        </div>
                    </div>
                    <div class="col-md-12" id="bill-disc-qty">
                        <div class="row">
                            <input type="number" name="bill-qty-input" readonly class="pax-form" min="1" max="900" v-model="discountQuantity">
                            <div class="pax-qty-btn">
                                <div class="min-qty" @click="decreaseDiscountAmount">-</div>
                                <div class="plus-qty" @click="increaseDiscountAmount">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" id="disc-summary-section">
                        <form>
                            <div class="form-group d-flex">
                                <label for="disc-percent" id="discount-label">Percentage:</label>
                                <p id="disc-percent">{{ discountRate }}%</p>
                            </div>
                        </form>
                        <form>
                            <div class="form-group d-flex">
                                <label for="disc-value">Value:</label>
                                <p id="disc-value">
                                    ₱ {{ discountValue }}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasCustomerDetails" class="information-forms" :class="{ locked: hasExistingDiscount }">
            <div v-for="(_, index) in customerDetails" class="d-lg-flex flex-wrap" :key="index">

                <div class="col-12 customer-details">
                    <label>
                        Customer {{ index + 1 }}
                    </label>
                </div>

                <div class="form-group col-lg-3 col-12">
                    <label>
                        First Name
                    </label>

                    <input type="text" class="form-control" v-model="customerDetails[index].firstName" />
                </div>

                <div class="form-group col-lg-3 col-12">
                    <label>
                        Last Name
                    </label>

                    <input type="text" class="form-control" v-model="customerDetails[index].lastName" />
                </div>

                <div class="form-group col-lg-6 col-12">
                    <label>
                        ID/Reference No.
                    </label>

                    <input type="text" class="form-control" v-model="customerDetails[index].idReferenceNumber" />
                </div>
            </div>
        </div>

        <div v-if="isOpenDiscount" class="col-12 information-forms" :class="{ locked: hasExistingDiscount }">
            <div class="form-group">
                <label>
                    Discount Name
                </label>
                <input type="text" class="form-control" v-model="openDiscountName" />
            </div>

            <div class="form-group">
                <label>
                    Discount Type
                </label>
                <select v-model="openDiscountType" class="form-control minimal">
                    <option :value="''">Select discount type</option>
                    <option value="fixed">By Amount</option>
                    <option value="percentage">By Percentage</option>
                </select>
            </div>

            <div class="form-group">
                <label>
                    Discount Amount ({{ openDiscountType == 'fixed' ? '₱' : '%' }})
                </label>
                <input type="number" step="any" min="0" oninput="validity.valid||(value='');" class="form-control"
                    v-model="openDiscountAmount" />
            </div>
        </div>
    </base-modal>
</template>

<script>
import MCard from '../common/MCard.vue';
import BaseModal from '../modals/BaseModal.vue';
import { getBillLevelDiscounts } from '@/spa/services/discount-service';
import { calculateDiscountAmount } from '@/spa/utils/computations';
import { ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE } from "@/spa/constants";
import { checkCloudConnection } from "@/spa/plugins/axios";
import { some, isEmpty, isNil } from 'lodash';
import cloneDeep from 'rfdc/default';
import { hasDiscountEligibilityKeywords } from "@/vue/helper/discount";

export default {
    name: 'BillDiscountModal',

    components: {
        MCard,
        BaseModal,
    },

    props: {
        pax: {
            type: Number,
            default: 1,
        },

        order: {
            type: Object,
            default: () => ({}),
        },

        isOpen: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedDiscount: null,
            customerDetails: [{
                firstName: '',
                lastName: '',
                idReferenceNumber: '',
            }],
            discountQuantity: 1,
            discounts: [],
            approvers: [],
            openDiscountName: '',
            openDiscountType: '',
            openDiscountAmount: '',
            hasExistingDiscount: false,
            discountPreviewValue: 0,
        };
    },

    watch: {
        discountQuantity() {
            this.customerDetails = Array.from(new Array(this.discountQuantity)).map(() => ({
                firstName: '',
                lastName: '',
                idReferenceNumber: '',
            }));
        },

        openDiscountAmount(newValue) {
            const maxValue = (this.order.totals.net / (this.pax || 1)) * this.discountQuantity
            if (!isEmpty(this.order) && newValue > maxValue) {
                this.$swal.warning(`Total discount amount cannot exceed total net sales of ${this.$filters.formatPrice(maxValue)}`);
                this.openDiscountAmount = 0;
            }
        },
        isOpen() {
            if (this.isOpen) {
                this.fetchApplicableDiscounts(true)
            }
        }
    },

    computed: {
        hasCustomerDetails() {
            return this.selectedDiscount && this.selectedDiscount.has_customer_details == 1;
        },

        isOpenDiscount() {
            return this.selectedDiscount && this.selectedDiscount.discount_name == 'Open Discount';
        },

        discountData() {
            if (this.isOpenDiscount) {
                return {
                    discount_name: this.openDiscountName,
                    discount_amount: this.openDiscountType === 'fixed' ? this.openDiscountAmount : 0,
                    discount_rate: this.openDiscountType === 'percentage' ? this.openDiscountAmount / 100 : 0,
                }
            } else {
                return this.selectedDiscount;
            }
        },

        openDiscountHasCompleteFields() {
            if (this.openDiscountName && this.openDiscountType && this.openDiscountAmount) {
                return true;
            }

            return false;
        },

        discountPreviewValueComp() {
            if (!this.selectedDiscount) return this.order.totals;
            return this.calculateDiscount(this.selectedDiscount);
        },

        discountRate() {
            const { discountData } = this;
            if (discountData?.original_discount_amount || discountData?.discount_amount) {
                return 0;
            }
            return (discountData?.original_discount_rate || discountData.discount_rate) * 100;
        },

        discountValue() {
            const { activeVat } = this.order.orders[0];
            const maxDiscount = this.discountData?.max_discount_amount;
            const mealCombinationAmount = this.discountData?.meal_combination_amount;

            let discountValue = 0;

            discountValue = (this.discountData.original_discount_amount || this.discountData.discount_amount)
            if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE && maxDiscount) {
                discountValue = (maxDiscount / activeVat.tax_rate) * (this.discountRate / 100)
            }

            if (
                mealCombinationAmount &&
                hasDiscountEligibilityKeywords(this.discountData?.discount_name)
            ) {
                const vatExemptSalesAfterDiscount = (mealCombinationAmount * this.discountQuantity) / 1.12;
                discountValue = vatExemptSalesAfterDiscount * parseFloat(this.discountData.discount_rate)
                if (discountValue > mealCombinationAmount) {
                    discountValue = mealCombinationAmount;
                }
            }

            return this.$filters.formatPrice(discountValue ?? this.discountPreviewValue);
        }
    },

    mounted() {
        this.fetchApplicableDiscounts();
    },

    methods: {
        async fetchApplicableDiscounts(isModalOpen = false) {
            try {
                // Only fetch new discounts if the modal is open and there is a connection 
                // to prevent unnecessary API calls on mount
                const forceRefresh = await checkCloudConnection() && isModalOpen;
                const response = await getBillLevelDiscounts(forceRefresh);
                this.discounts = response.data.discounts;
                this.approvers = response.data.approvers;
            } catch (e) {
                console.error(e);
                this.discounts = [];
                this.approvers = [];
            }
        },

        checkForExistingDiscount() {
            if (!this.order.billDiscount) {
                this.hasExistingDiscount = false;
                this.discountPreviewValue = 0;
                return;
            }

            const { billDiscount } = this.order;
            this.selectedDiscount = billDiscount.discount;
            this.customerDetails = cloneDeep(billDiscount.customerDetails);
            this.hasExistingDiscount = true;
            this.discountPreviewValue = this.order.billDiscount.amount;
        },

        calculateDiscount(discount) {
            return calculateDiscountAmount(
                {
                    discount,
                    discountQuantity: this.discountQuantity,
                },
                this.order.totals,
                this.order.pax,
                false
            );
        },

        selectDiscount(discount) {
            const discountAmount = this.calculateDiscount(discount);

            let limitReached = false
            if (!discount.meal_combination_amount) {
                limitReached = this.hasReachedMaxDiscountAmount(discountAmount, discount.max_discount_amount);
            }

            this.selectedDiscount = discount;
            this.discountPreviewValue = discountAmount
            if (
                discount.meal_combination_amount && 
                hasDiscountEligibilityKeywords(discount.discount_name) &&
                discountAmount > discount.meal_combination_amount
            ) {
                this.discountPreviewValue = discount.meal_combination_amount
            } else if (limitReached) {
                this.discountPreviewValue = discount.max_discount_amount
            }
        },

        increaseDiscountAmount() {
            if (this.discountQuantity < this.pax) {
                this.discountQuantity++;

                this.selectDiscount(this.selectedDiscount)
            }
        },

        decreaseDiscountAmount() {
            if (this.discountQuantity > 1) {
                this.discountQuantity--;

                this.selectDiscount(this.selectedDiscount)
            }
        },

        resetInfo() {
            this.selectedDiscount = null;
            this.customerDetails = [{
                firstName: '',
                lastName: '',
                idReferenceNumber: '',
            }];
            this.discountQuantity = 1;
            this.openDiscountName = '';
            this.openDiscountType = '';
            this.openDiscountAmount = '';
        },

        toggleDiscount() {
            if (this.hasExistingDiscount) {
                this.$emit('removeDiscount');
                this.resetInfo();
            } else {
                this.applyDiscount();
            }
        },

        async applyDiscount() {
            if (this.selectedDiscount.has_customer_details == 1) {
                if (some(this.customerDetails, v => some(v, isEmpty))) {
                    this.$swal.warning('Please fill in all customer details');
                    return;
                }
            }

            let response = null;
            if (this.selectedDiscount.passcode_required == 1) {
                response = await this.$openApproverModal();
                if (!response.success) {
                    if (response.cancelled) {
                        this.$swal.warning('Discount authorization cancelled');
                    }

                    return;
                }
            }

            const discount = cloneDeep(this.selectedDiscount);

            if (this.isOpenDiscount) {
                discount.discount_name = this.openDiscountName;
                if (this.openDiscountType == 'fixed') {
                    discount.discount_amount = this.openDiscountAmount;
                } else {
                    discount.discount_rate = (this.$filters.formatPrice(this.openDiscountAmount) / 100);
                }

                if (!this.openDiscountHasCompleteFields) {
                    this.$swal.warning('All discount details are required');

                    return;
                }
            }

            this.$emit('applyDiscount', {
                discount,
                customerDetails: this.customerDetails,
                discountQuantity: this.discountQuantity,
                discountApprover: response ? response.approver : null,
            });

            this.resetInfo();
        },

        getMaxDiscountLabel(maxDiscountAmount) {
            if (maxDiscountAmount) {
                return `Max discount is ${maxDiscountAmount}`
            }

            return ''
        },

        getMEMCLabel(mealCombinationAmount) {
            if (mealCombinationAmount) {
                return `MEMC Amount: ${mealCombinationAmount}`
            }

            return ''
        },

        hasReachedMaxDiscountAmount(discountAmount, maxDiscountAmount) {
            if (!maxDiscountAmount) {
                return false;
            }

            if (discountAmount > maxDiscountAmount) {
                this.$swal.warning(`You reach the max discount possible and the POS will get the ceiling amount you set with this discounts. Which is ${maxDiscountAmount}`);
                return true;
            }

            return false;
        },

        getDiscountAmount(discount) {
            if (discount.discount_name === 'Open Discount') {
                return '';
            }

            return isNil(discount.discount_rate) ? '₱' + discount.discount_amount.toFixed(2) : discount.discount_rate * 100 + '%';
        },
    },
};
</script>

<style scoped>

.mods-row {
    gap: 8px;
    margin: 8px;
}

.quantity-control {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    display: grid;
    place-content: center;
}

.locked {
    pointer-events: none;
    user-select: none;
}

.max-transaction-amount {
    font-size: .7rem;
    margin-top: 5px;
}

.customer-info {
    text-indent: 15px;
}
</style>
