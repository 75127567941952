<template>
    <m-card
        class="m-open-item"
        clickable
        @click="selectOpenItem"
    >
        <template #default>
            
            <div
                class="m-product-open-item"
                >
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                        viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#1b75bb" stroke="none">
                            <path d="M125 5101 c-49 -23 -89 -64 -109 -113 -14 -33 -16 -289 -16 -2430 0
                    -2308 1 -2394 19 -2433 23 -49 64 -89 113 -109 33 -14 222 -16 1726 -16 l1689
                    0 48 24 48 25 86 -19 c249 -55 493 -26 727 84 121 58 201 113 301 209 185 178
                    293 380 338 630 25 141 17 344 -20 482 -28 107 -102 275 -137 312 -41 44 -128
                    16 -128 -41 0 -13 22 -71 50 -128 71 -148 92 -234 97 -398 4 -110 1 -152 -15
                    -226 -89 -403 -382 -698 -773 -780 -110 -22 -297 -22 -404 1 -299 64 -550 261
                    -686 540 -79 163 -94 230 -94 430 0 202 17 280 95 440 130 265 375 456 686
                    531 97 24 334 24 428 1 144 -36 281 -104 399 -199 84 -67 122 -75 156 -32 37
                    47 24 79 -59 146 -190 153 -405 236 -655 254 -89 6 -228 -4 -302 -23 l-23 -5
                    0 1338 c0 904 -4 1351 -11 1377 -13 49 -63 105 -114 128 -37 17 -85 19 -746
                    19 -700 0 -707 0 -733 -21 -36 -28 -36 -80 0 -108 26 -21 34 -21 720 -21 681
                    0 694 0 714 -20 20 -20 20 -33 20 -1377 l0 -1358 -74 -33 c-303 -137 -536
                    -417 -623 -750 -34 -132 -43 -356 -19 -487 32 -172 97 -328 193 -464 66 -92
                    194 -216 288 -279 l79 -52 -1607 0 c-1594 0 -1607 0 -1627 20 -20 20 -20 33
                    -20 2390 0 2357 0 2370 20 2390 20 20 33 20 859 20 462 0 847 3 856 6 26 10
                    45 63 34 93 -20 53 18 50 -899 50 -811 1 -857 0 -895 -18z"/>
                            <path d="M1513 4603 c-27 -5 -63 -48 -63 -74 0 -10 11 -31 24 -46 24 -28 25
                    -28 165 -31 l141 -4 0 -109 0 -109 -42 0 c-94 0 -321 -53 -415 -97 -42 -19
                    -60 -62 -41 -100 22 -46 57 -51 146 -20 252 88 553 93 807 12 345 -111 634
                    -369 774 -691 76 -177 101 -308 101 -540 l0 -164 -1255 0 -1255 0 0 169 c0
                    224 31 379 110 555 63 140 181 300 292 398 169 149 177 161 137 212 -44 56
                    -113 24 -263 -122 -130 -126 -223 -258 -301 -427 -90 -193 -122 -349 -132
                    -631 l-6 -193 -34 -31 c-51 -49 -76 -104 -76 -175 0 -104 45 -177 138 -221
                    l50 -24 1340 0 1340 0 50 24 c93 44 138 117 138 221 0 71 -25 126 -78 176
                    l-35 33 0 96 c-1 140 -17 334 -35 425 -52 253 -175 487 -358 681 -160 169
                    -358 295 -578 368 -106 35 -261 66 -331 66 l-38 0 0 109 0 109 141 4 c140 3
                    141 3 165 31 30 35 30 59 0 94 l-24 28 -339 1 c-186 1 -348 0 -360 -3z m1694
                    -2155 c42 -47 34 -117 -18 -143 -42 -22 -2626 -22 -2668 0 -52 26 -60 96 -18
                    143 l20 22 1332 0 1332 0 20 -22z"/>
                            <path d="M3873 1886 c-63 -30 -107 -86 -122 -157 -6 -27 -11 -120 -11 -204 l0
                    -155 -179 0 c-176 0 -179 0 -232 -28 -163 -85 -162 -309 1 -394 51 -28 55 -28
                    231 -28 l179 0 0 -155 c0 -227 18 -284 111 -347 110 -74 263 -27 324 98 16 33
                    20 69 23 222 l4 182 177 0 c149 0 182 3 216 19 174 79 182 321 13 405 -50 25
                    -58 26 -230 26 l-178 0 0 149 c0 81 -4 172 -10 200 -14 73 -58 133 -122 165
                    -66 33 -126 34 -195 2z m140 -146 l25 -20 4 -213 c3 -233 6 -244 64 -272 25
                    -12 74 -15 227 -15 181 0 196 -1 221 -21 36 -28 36 -80 0 -108 -25 -20 -40
                    -21 -225 -21 -294 0 -283 11 -287 -287 -4 -230 -5 -233 -63 -248 -20 -5 -33 0
                    -55 18 l-29 25 -3 209 c-2 135 -7 216 -14 231 -24 46 -52 52 -266 52 -186 0
                    -201 1 -226 21 -36 28 -36 80 0 108 25 20 40 21 220 21 201 0 229 4 263 41 20
                    22 21 34 21 220 0 113 4 209 10 225 21 55 68 69 113 34z"/>
                            <path d="M416 1749 c-34 -27 -36 -79 -4 -109 22 -20 29 -20 1128 -20 l1107 0
                    21 23 c31 33 29 80 -4 106 -27 21 -29 21 -1124 21 -1095 0 -1097 0 -1124 -21z"/>
                            <path d="M412 1280 c-32 -30 -30 -82 4 -109 27 -21 30 -21 1063 -21 1112 1
                    1059 -2 1080 51 11 30 -8 83 -34 93 -9 3 -483 6 -1054 6 -1030 0 -1037 0
                    -1059 -20z"/>
                            <path d="M410 810 c-27 -27 -26 -81 1 -106 20 -18 50 -19 798 -22 776 -2 778
                    -2 804 19 34 26 37 79 7 109 -20 20 -33 20 -805 20 -772 0 -785 0 -805 -20z"/>
                            <path d="M2220 810 c-27 -27 -26 -81 1 -106 20 -18 39 -19 261 -19 l240 0 19
                    24 c25 30 24 62 -2 95 l-20 26 -240 0 c-226 0 -240 -1 -259 -20z"/>
                        </g>
                    </svg>
                
            </div>
            <div class="open-item-details">
                Open Item
            </div>
        </template>
    </m-card>
</template>

<script>
import MCard from '@/spa/components/common/MCard';
export default {
    name: 'MOpenItem',

    components: { MCard },

    methods: {
        selectOpenItem() {
                this.$emit('clickedOpenItemModal');
        },
    },
};
</script>

<style scoped>
div.m-open-item {
    width: auto;
    position: relative;
    overflow: hidden;
}

@media (min-width: 768px) {
    div.m-open-item {
        min-width: 172px;
    }
}

.m-product-open-item {
    text-align: center;
}

.open-item-details {
    text-align: center;
    font-weight: bold;
}


</style>

<style>
div.m-open-item .m-card__body {
    align-self: stretch;
}
</style>