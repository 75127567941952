<template>
  <img src="/images/alipay-dstore.png" :width="width" :height="height" />
</template>
<script>
export default {
  name: "AlipayDstore",

  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 85,
    }
  }
}
</script>