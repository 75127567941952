<template>
    <div class="no-orders-placeholder">
        <i
            :class="{
                'placeholder-icon': true,
                fa: true,
                [`fa-${icon}`]: true,
                ['fa-spin']: icon === 'spinner',
                ...iconClass,
            }"
            :style="{ fontSize: iconSize }"
        />
        <h2>{{ text }}</h2>
    </div>
</template>

<script>
export default {
    name: 'MPlaceholder',
    props: {
        text: {
            type: String,
            default: 'NO ITEMS'
        },
        icon: {
            type: String,
            default: 'box-open'
        },
        iconSize: {
            type: String,
            default: '3rem'
        },
        iconClass: {
            type: Object,
            default: () => ({})
        },
    },
}
</script>

<style scoped>
.no-orders-placeholder {
    position: relative;
    height: 65vh;
    width: 100%;
}

.no-orders-placeholder>* {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #656565;
}

.no-orders-placeholder>.placeholder-icon {
    top: calc(50% - 32px);
    left: 50%;
}

.no-orders-placeholder>h2 {
    padding-top: 40px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}
</style>