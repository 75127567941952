<template>
    <base-modal
        max-width="600px"
        min-width="0"
        min-height="100px"
        width="100%"
        v-bind="$attrs"
        @confirm="saveItemRequest"
        >
        <template v-slot:title>Item Request</template>

        <hr />

        <div v-if="item" class="d-flex justify-content-between align-items-center">
            <div class="item-details col-8">
                <h2 class="item-name" v-text="item.product.product_name" />
                <h4 class="item-modifiers" v-text="modifiersString" />
            </div>

            <div class="item-price col-4">
                ₱{{ itemTotalPrice }}
            </div>
        </div>

        <div class="form-group mt-4">
            <label for="item-request">Add request</label>
            <textarea v-model="request" class="form-control" id="item-request" rows="3" />
        </div>

    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';

export default {
    components: {
        BaseModal,
    },

    props: {
        item: {
            type: [Object, null],
            required: true,
        },

        pricePreviewWithSc: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            request: '',
        };
    },

    computed: {
        itemTotalPrice() {
            if (this.pricePreviewWithSc) return this.$filters.formatPrice(this.item.total ?? this.item.totals.total * this.item.quantity);
            return this.$filters.formatPrice(this.item.beforeSc);
        },

        modifiersString() {
            if (!this.item) return '';

            return [
                ...(this.item.product.forcedMods || []),
                ...(this.item.product.unforcedMods || []),
            ].map(mod => `${mod.quantity || 1} ${mod.modifier_name}`).join(', ');
        },
    },

    watch: {
        item(item) {
            if (item) {
                this.request = item.request || '';
            }
        }
    },

    methods: {
        saveItemRequest() {
            this.$emit('saveItemRequest', this.request);
        },
    },
}
</script>

<style scoped>
.item-name {
    font-size: 2rem;
    color: #1b75bb;
    font-weight: bold;
}

.item-price {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Exo', sans-serif;
    text-align: right;
}
</style>