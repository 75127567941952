<template>
    <base-modal
        v-bind="$attrs"
        @confirm="itemMoveTable"
        >
        <template v-slot:title>Move Item To Table</template>

        <hr />

        <div v-if="item" class="d-flex justify-content-between align-items-center">
            <div class="item-details col-8">
                <h2 class="item-name" v-text="item.product.product_name" />
                <h4 class="item-modifiers" v-text="modifiersString" />
            </div>

            <div class="item-price col-4">
                ₱{{ getTotalItemRawPrice(item.quantity).toFixed(2) }}
            </div>
        </div>

        <m-arranged-tables
            v-if="tableData.length"
            :tables="activeArea.tables"
            :occupied-tables="occupiedTables"
            :isMoveItem="true"
            :disabled-table-ids="[activeTableId]"
            :active-table-id="activeTableId"
            :target-tables="targetTables"
            :item-data="itemData"
            :move-item-table-qty="moveItemTableQty"
            @tableSelected="selectTable($event)"
            @subtractQuantity="subtractQuantity($event)"
            @addQuantity="addQuantity($event)"
            />
    </base-modal>
</template>

<script>
import { uniq, map, isEmpty, isEqual, pick } from 'lodash';
import cloneDeep from 'rfdc/default';
import { getTableLayout } from '@/spa/services/table-service';
import { print } from '@/spa/services/printer-service';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import BaseModal from '@/spa/components/modals/BaseModal';
import MArrangedTables from '@/spa/components/common/MArrangedTables';
import { checkCloudConnection } from '@/spa/plugins/axios';
import { getLatestSeries } from '@/spa/services/sync-service';
import { LineItemMixin } from '@/spa/components/mixins/ComputationMixin';
import seriesService from "@/spa/services/series-service";
import {OFFLOAD} from "@/spa/constants";

export default {
    components: {
        BaseModal,
        MArrangedTables,
    },

    mixins: [
        LineItemMixin,
    ],

    props: {
        item: {
            type: [Object, null],
            required: true,
        },
        serviceType: {
            type: String,
            default: 'Dine-in',
        },
        activeTableId: {
            type: [Number, String],
            required: true,
        },
        getKOTPrintObj: {
            type: Function,
            required: true,
        },
        locationId: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            tableData: [],
            activeAreaIndex: 0,
            occupiedTables: {},
            targetTables: [],
            moveItemTableQty: {},
            itemData: {},
        };
    },

    computed: {
        ...mapState([
            'orders',
        ]),

        ...mapGetters([
            'activeOrder',
            'pendingOrders',
            'hasPendingSettlements',
        ]),

        activeArea() {
            return this.tableData.length > 0
                ? this.tableData[this.activeAreaIndex]
                : { tables: [] };
        },

        modifiersString() {
            if (!this.item) return '';

            return [
                ...(this.item.product.forcedMods || []),
                ...(this.item.product.unforcedMods || []),
            ].map(mod => `${mod.quantity || 1} ${mod.modifier_name}`).join(', ');
        },
        isMultiTerminal() {
            const terminal = JSON.parse(sessionStorage.getItem('terminal'));
            return terminal?.is_shared_terminal;
        },
    },

    async mounted() {
        await this.fetchTables();
        this.updateTableStates();
    },

    watch: {
        activeTableId(value) {
            if (value) {
                this.parseTableOrders();
            }
        },
        item(value) {
            if(value) {
                this.itemData = cloneDeep(value);
            }
        },
    },

    methods: {
        ...mapMutations([
            'setOrders',
            'setActiveOrderId',
            'setActiveBrandId',
            'setActiveServiceTypeId',
            'setActiveChannelId',
            'updateOrder',
            'deleteOrder',
            'addOrder',
        ]),

        ...mapActions([
            'recomputeTotals',
        ]),

        async fetchTables() {
            try {
                const response = await getTableLayout();
                this.tableData = map(response.data.table_details.service_areas, a => ({
                    ...a,
                    tables: map(a.tables, t => ({
                        ...t,
                        table_position: JSON.parse(t.table_position),
                    })),
                }));
            } catch (e) {
                console.error(e);
            }
        },

        setActiveAreaIndex(areaId) {
            this.activeAreaIndex = areaId;
        },

        updateTargetTableOrders(originOrder, targetTableOrder, qty, newKot) {
            const targetOrders = cloneDeep(targetTableOrder.orders);
            const originOrderCopy = cloneDeep(originOrder);

            targetOrders.push({
                ...originOrderCopy,
                quantity: qty,
                kot: newKot
            });

            targetTableOrder.orders = targetOrders;

            return {targetTableOrder, originOrder};
        },

        recomputeKotBreakdowns(orders) {
            return orders.reduce((acc, curr) => {
                const { kotNum, net, serviceCharge, total, vat } = curr.totals;
                const existingOrder = acc.find(order => order.kotNum === kotNum);
                if (existingOrder) {
                    existingOrder.net += net;
                    existingOrder.serviceCharge += serviceCharge;
                    existingOrder.total += total;
                    existingOrder.vat += vat;
                } else {
                    acc.push({ kotNum, vat, serviceCharge, net, total });
                }
                return acc;
            }, []);
        },

        areProductSkusEqual(product1, product2) {
            const relevantFields = ['id', 'forcedMods', 'unforcedMods', 'product_name', 'price'];
            return isEqual(pick(product1, relevantFields), pick(product2, relevantFields));
        },

        async getLatestKot() {
            if (!(await checkCloudConnection())) return null;
            try {
                const response = await getLatestSeries();
                return response.data.kot ? response.data.kot + 1 : null;
            } catch (e) {
                console.log(e);
            }
        },

        async itemMoveTable() {
            let originTableOrder = cloneDeep(this.activeOrder);
            let lastOrderId = 0;
            let lastTableId = 0;

            //moving logic
            let originOrderIndex = originTableOrder.orders.findIndex(order => this.areProductSkusEqual(order.product, this.item.product));
            let originOrder = originTableOrder.orders[originOrderIndex];
            let originKOTBreakdownIndex = originTableOrder.kotBreakdowns.findIndex(k => k.kotNum == originOrder.kot);

            // get target kotBreakdown from origin order
            const targetKOTBreakdown = {
                kotNum: originOrder.kot,
                vat: originOrder.totals.vat,
                serviceCharge: originOrder.totals.serviceCharge,
                net: originOrder.totals.net,
                total: originOrder.totals.total,
            }

            //move item to targetTables
            for (let tableId of this.targetTables) {
                let targetTableOrder = cloneDeep(this.orders.find(order=> order.tableId == tableId && !order.isVoided));
                let qty = this.moveItemTableQty[tableId];

                let targetTableOrderTotals = 0;

                const targetTable = this.activeArea.tables.find(t => t.table_id == tableId);

                let newKot = Math.max((await seriesService.getLastKotNum() + 1), (originOrder.kot + 1));
                if (!OFFLOAD.sqliteOffloadMA33) {
                  const lastKot = this.isMultiTerminal || window.ENABLE_SERIES_TABLE
                    ? await this.getLatestKot() || newKot
                    : newKot;
                  newKot = Math.max(lastKot, newKot);
                }
                seriesService.setLastKotNum(newKot);

                const originTableDetails = {
                    tableId: originTableOrder.tableId,
                    tableName: originTableOrder.tableName,
                    kot: originOrder.kot
                }

                // save previous kots
                originOrder.prevKots = originOrder.prevKots || [];
                originOrder.prevKots.push(originOrder.kot);
                originOrder.kotHistory = originOrder.kotHistory || {};
                originOrder.kotHistory[newKot] = qty;

                if(!isEmpty(targetTableOrder)) {
                    const existingKOTBreakdownIndex = targetTableOrder.kotBreakdowns.findIndex(kotBreakdown => kotBreakdown.kotNum == targetKOTBreakdown.kotNum);
                    
                    if(existingKOTBreakdownIndex >= 0) {
                        // if kot number is already exist in the target table, add the kotBreakdown instead of creating a new entry
                        const accumulatedKOTBreakdown = {
                            ...targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex],
                            vat: targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex].vat + targetKOTBreakdown.vat,
                            serviceCharge: targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex].serviceCharge + targetKOTBreakdown.serviceCharge ,
                            net: targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex].net + targetKOTBreakdown.net,
                            total: targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex].total + targetKOTBreakdown.total,
                        }

                        targetTableOrder.kotBreakdowns[existingKOTBreakdownIndex] = accumulatedKOTBreakdown
                        originOrder.kot = newKot;
                    } else {
                        targetTableOrder.kotBreakdowns.push(targetKOTBreakdown);
                    }

                    targetTableOrder.kots.push(newKot);

                    let updatedOrdersData = this.updateTargetTableOrders(originOrder, targetTableOrder, qty, newKot);
                    targetTableOrder = updatedOrdersData.targetTableOrder;
                    targetTableOrder.originTableDetails = originTableDetails;
                    this.updateOrder({ orderId: targetTableOrder._id, order: updatedOrdersData.targetTableOrder });
                    await this.$nextTick();

                    //totals recomputation
                    targetTableOrderTotals = await this.recomputeTotals({
                        orderId: targetTableOrder._id,
                        updateOrder: false
                    });
                    updatedOrdersData.targetTableOrder.totals = targetTableOrderTotals.grandTotals;
                    updatedOrdersData.targetTableOrder.orders.map((item, index) => {
                        if (item.prevKots?.includes(newKot) || item._id == originOrder._id && !item.movedQty) item.kot = newKot;
                        item.totals = targetTableOrderTotals.item_totals[index] ?? item.totals;
                    });

                    updatedOrdersData.targetTableOrder.kotBreakdowns = this.recomputeKotBreakdowns(updatedOrdersData.targetTableOrder.orders)
                    this.updateOrder({ orderId: targetTableOrder._id, order: updatedOrdersData.targetTableOrder });

                    //assign updated originOrder
                    originOrder = updatedOrdersData.originOrder;
                    targetTableOrder = updatedOrdersData.targetTableOrder;
                } else {

                    let originOrderCopy = cloneDeep(originOrder);

                    //create new order
                    targetTableOrder = {
                        ...originTableOrder,
                        _id: new Date().getTime(),
                        tableId: tableId,
                        orders: [{...originOrderCopy, quantity: qty}],
                        kotBreakdowns: [targetKOTBreakdown],
                        kots: [newKot],
                        tableName: targetTable?.table_name ?? null,
                        originTableDetails
                    };

                    targetTableOrderTotals = await this.recomputeTotals({
                        order: targetTableOrder,
                        updateOrder: false
                    });

                    targetTableOrder.totals = targetTableOrderTotals.grandTotals;
                    targetTableOrder.orders.map((item, index) => {
                        if (item._id == originOrder._id) item.kot = newKot;
                        item.totals = targetTableOrderTotals.item_totals[index];
                    });

                    this.addOrder(targetTableOrder);
                }

                const { printData, printConfig } = await this.getKOTPrintProps(targetTableOrder, newKot, originOrder.kot);
                await print(printData, printConfig);

                //deduct quantity after move
                originOrder.quantity -= qty;

                //delete originOrder if quantity is zero
                if(originOrder.quantity == 0) {
                    //remove kots from originOrder
                    originTableOrder.kotBreakdowns.splice(originKOTBreakdownIndex,1);
                    originTableOrder.kots.splice(originTableOrder.kots.findIndex(k => k == originOrder.kot), 1);
                    originTableOrder.orders.splice(originOrderIndex, 1);
                }

                if(isEmpty(originTableOrder.orders)) {
                    this.deleteOrder(originTableOrder._id);
                    this.clearTable(originTableOrder.tableId);
                } else {
                    //recompute totals before updating order
                    let originTableOrderTotals = await this.recomputeTotals({
                        order: originTableOrder,
                        updateOrder: false
                    });
                    originTableOrder.totals = originTableOrderTotals.grandTotals;
                    originTableOrder.orders.map((item, index) => {
                        item.totals = originTableOrderTotals.item_totals[index];
                    });
                    originTableOrder.kots = uniq(originTableOrder.orders.map(o => o.kot));

                    originTableOrder.kotBreakdowns = this.recomputeKotBreakdowns(originTableOrder.orders);

                    this.updateOrder({ orderId: originTableOrder._id, order: originTableOrder });
                }

                lastOrderId = targetTableOrder._id;
                lastTableId = tableId;
            }

            this.targetTables = [];
            this.updateTableStates();
            this.$emit('itemMoveTable', { lastOrderId, lastTableId });
        },

        async getKOTPrintProps(targetTableOrder, lastKot, orderNumOverride = null) {
            const kotPrintObj = await this.getKOTPrintObj(targetTableOrder, lastKot, [], false, orderNumOverride);
            const kotHTMLContent = kotPrintObj.printTemplate;
            
            const printData = {
                print_type: 'KOT',
                html: kotHTMLContent,
                kotStrArr: kotPrintObj.kotStrArr
            };
            
            const printConfig = {
                location_id: locationId,
                kotItemObject: kotPrintObj.kotItemObject,
            };

            return { printData, printConfig };
        },

        selectTable(tableId) {
            //check if item qty is 0
            if(this.itemData.quantity == 0 && !this.targetTables.includes(tableId)) {
                return;
            }

            if(!this.targetTables.includes(tableId)) {
                this.targetTables.push(tableId);
                this.moveItemTableQty[tableId] = 1;
                this.itemData.quantity--;
            } else {
                this.subtractQuantity(tableId);
            }

            return;
        },

        subtractQuantity(tableId) {
            if(this.moveItemTableQty.hasOwnProperty(tableId) && this.moveItemTableQty[tableId] > 0) {
                this.moveItemTableQty[tableId]--;
                if(this.moveItemTableQty[tableId] == 0) {
                    //remove from targetTables
                    this.targetTables.splice(this.targetTables.indexOf(tableId), 1);
                }
                this.itemData.quantity++;
            }
        },

        addQuantity(tableId) {
            if(this.moveItemTableQty.hasOwnProperty(tableId) && this.moveItemTableQty[tableId] < this.item.quantity) {
                this.moveItemTableQty[tableId]++;
                this.itemData.quantity--;
            }
        },

        viewOrder(orderId) {
            this.setActiveOrderId(orderId);
        },

        goToTableDetails(tableId) {
            this.$router.push({
                name: 'table-details',
                params: { tableId, serviceType: this.serviceType },
            });
        },

        goToPayments(tableId, data) {
            const order = this.pendingOrders.find(o => o.tableId == tableId);
            if (!order) return;

            this.$router.push({
                name: 'payments',
                params: { orderId: order._id , tableName: data.tableName},
            });
        },

        parseTableOrders() {
            const order = this.pendingOrders.find(o => o.tableId == this.activeTableId);
            this.setActiveOrderId(order ? order._id : null);
            this.$nextTick(() => {
                if (this.activeOrder) {
                    this.setActiveBrandId(this.activeOrder.brandId);
                    this.setActiveServiceTypeId(this.activeOrder.serviceTypeId);
                    this.setActiveChannelId(this.activeOrder.channelId);
                }

                if (this.hasPendingSettlements) {
                    const activeTable = this.activeArea.tables.find(t => t.table_id == this.activeTableId);
                    this.goToPayments(this.activeTableId, activeTable);
                }
            });
        },

        clearTable(tableId) {
            this.occupiedTables[tableId] = false;
        },

        updateTableStates(unselectActive = false) {
            const tablesWithOrders = uniq(map(this.pendingOrders, 'tableId'));

            this.occupiedTables = tablesWithOrders.reduce((acc, tableId) => {
                acc[tableId] = true;
                return acc;
            }, {});

            if (unselectActive) {
                this.activeTableId = null;
            }
        },
    },
};
</script>

<style scoped>
.item-name {
    font-size: 2rem;
    color: #1b75bb;
    font-weight: bold;
}

.item-price {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Exo', sans-serif;
    text-align: right;
}
</style>