import {action, DataOffloadBridge} from "@/mobile_bridge/offload/offload";

export class PaymentMethodBridge extends DataOffloadBridge {
    constructor() {
        super('PaymentMethod');
    }

    async getAll() {
        const { rows } = await this.sendMessage(action.GET_ALL);
        return rows;
    }
}
