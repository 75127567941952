<template>
  <base-modal
      v-bind="$attrs"
      min-width="50"
      :hasConfirm=false
      :hasCancel=true
      cancelLabel="Close"
  >
    <template v-slot:title>{{ reportTitle }} Report</template>

    <hr />
    <div class="x-report">

      <table class="mt-2" id="z-balance-tables">
        <tbody>
          <tr>
            <td>Date & Time:</td>
            <td>{{ dateToday }}</td>
          </tr>
          <tr>
            <td>Store Code:</td>
            <td></td>
          </tr>
          <tr>
            <td>Brand/Branch:</td>
            <td>{{ recieptDetails.account_name + ' ' + recieptDetails.location }}</td>
          </tr>
          <tr>
            <td>DOB:</td>
            <td>{{ businessDate }}</td>
          </tr>
          <tr>
            <td>BIR MIN #:</td>
            <td>{{ recieptDetails.lp_min }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="reportType !== 'eod'">
        <tbody>
          <tr>
            <td>Cashier Name:</td>
            <td>{{ username }}</td>
          </tr>
          <tr>
            <td>Shift:</td>
            <td>{{ currentShift.shift }}</td>
          </tr>
          <tr>
            <td>POS #:</td>
            <td>{{ shiftTable.terminal_id }}</td>
          </tr>
        </tbody>
      </table>

      <table class="mt-4" v-bind:class="{'border-bottom': reportType ==='eod'}">
        <tbody>
          <tr>
            <td>Gross Sales {{ reportType !== "eod" ? "Beginning" : "Opening Reading" }}:</td>
            <td class="text-right">{{ this.$filters.formatPrice(parseFloat(beginningBalance)) }}</td>
          </tr>
          <tr>
            <td>Gross Sales {{ reportType !== "eod" ? "Ending" : "Closing Reading" }}:</td>
            <td class="text-right">{{ this.$filters.formatPrice(parseFloat(grossEndBalance)) }}</td>
          </tr>
          <tr>
            <td>Gross Sales Today:</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalGrossSales) }}</td>
          </tr>
          <tr>
            <td>Gross Sales Net of Discounts:</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalGrossNet) }}</td>
          </tr>
        </tbody>
      </table>

      <table v-if="reportType !== 'eod'" class="border-bottom">
        <tbody>
          <tr>
            <td>Beginning SI#:</td>
            <td class="text-right">{{ this.padWithZero(beginningSettlement.receipt_num) }}</td>
          </tr>
          <tr>
            <td>Ending SI#:</td>
            <td class="text-right">{{ this.padWithZero(endingSettlement.receipt_num) }}</td>
          </tr>
          <tr>
            <td>Beginning CheckID#:</td>
            <td class="text-right"></td>
          </tr>
          <tr>
            <td>Ending CheckID#:</td>
            <td class="text-right"></td>
          </tr>
          <tr>
            <td>Beginning Void Amount:</td>
            <td class="text-right">{{ this.$filters.formatPrice(beginningVoidAmount) }}</td>
          </tr>
          <tr>
            <td>Ending Void Amount:</td>
            <td class="text-right">{{ this.$filters.formatPrice(endingVoidAmount) }}</td>
          </tr>
        </tbody>
      </table>

      <table class="mt-3 border-bottom">
        <tbody>
          <tr>
            <td colspan="2">Gross Sales</td>
          </tr>
          <tr>
            <td class="indent-2">Total Punched Sales</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalPunchedSales) }}</td>
          </tr>
          <tr>
            <td class="indent-2">Voids</td>
            <td class="text-right text-danger">{{ this.$filters.formatPrice(voidedSalesAmount) }}</td>
          </tr>
          <tr>
            <td>Gross Sales</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalGrossSales) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="mt-3">
        <tbody>
          <tr>
            <td colspan="2">Gross Sales Net of Discounts</td>
          </tr>
          <tr>
            <td class="indent-2">Gross Sales</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalGrossSales) }}</td>
          </tr>
          <tr>
            <td class="indent-2">Discounts, Comps</td>
            <td class="text-right text-danger">{{ this.$filters.formatPrice(totalDiscounts) }}</td>
          </tr>
          <tr>
            <td class="indent-2">Promos</td>
            <td class="text-right text-danger border-bottom"></td>
          </tr>
          <tr>
            <td>Gross Sales Net of Discounts</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalGrossNet) }}</td>
          </tr>
        </tbody>

      </table>

      <!--A. Sales Net of Discount-->

      <table class="mt-3">
        <tbody>
          <tr>
            <td colspan="2" class="font-weight-bold">A. Sales Net of Discount (VAT-Inc) | Summary and settlement</td>
          </tr>
          <tr>
            <td style="text-decoration: underline;">A1. Sales Net of Discount (VAT-Inc)</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(totalGrossNet) }}</td>
          </tr>
          <tr>
            <td class="indent-1">Sales Net of Discount **</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table>
<!--        @foreach($service->types as $key => $value)-->
        <template v-for="st in serviceTypes" :key="st.name">
            <tr>
              <td class="indent-2" style="width: 50%">{{ st.name }}</td>
              <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(st.amount) }}</td>
              <td style="width: 20%"></td>
            </tr>
        </template>

        <tbody>
          <tr>
            <td class="indent-1 border-top">Total Sales Net of Discount</td>
            <td class="text-right border-top">{{ this.$filters.formatPrice(serviceTypesNet) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-2">Delivery Income</td>
            <td class="text-right">0</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-2">Service Charge</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalServiceCharge) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1 border-top">Total Sales plus Delivery Income and Service Charge</td>
            <td class="text-right border-top">{{ this.$filters.formatPrice(totalSalesIncomeAndCharge) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-2">VAT</td>
            <td class="text-right border-bottom">{{ this.$filters.formatPrice(totalVatAmount) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1">Total Sales Net of Discount VAT-Inc</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(totalGrossNet) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="mt-2 border-top">
        <tbody>
          <tr>
            <td style="text-decoration: underline;">A2. Settlement | Sales Net of Discount (Vat-Inc)</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(totalSettlement) }}</td>
          </tr>
          <tr>
            <td class="indent-1 font-weight-bold">Settlement</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="indent-2" style="width: 50%">A2.1 CASH</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(totalEODCashSales) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-2 border-bottom">A2.2 NON-CASH</td>
            <td class="text-right border-bottom">{{ this.$filters.formatPrice(totalEODNonCashSales) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1 font-weight-bold">Total Settlement</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(totalSettlement) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="mt-2">
        <tbody>
          <tr>
            <td class="font-weight-bold indent-1" style="width: 40%">NON-CASH</td>
            <td class="text-right font-weight-bold" style="width: 25%">Transaction Count</td>
            <td class="text-right font-weight-bold" style="width: 35%">Total Amount</td>
          </tr>
  <!--        @foreach($revenue->settlement as $key => $value)-->
          <tr v-for="(breakdown, index) in nonCashPaymentMethods">
            <td class="indent-2">{{ breakdown.name }}</td>
            <td class="text-right">{{ breakdown.count ?? 0  }}</td>
            <td class="text-right">{{ this.$filters.formatPrice(breakdown.amount) }}</td>
          </tr>

          <tr class="border-top">
            <td class="indent-2">Total</td>
            <td class="text-right">{{ nonCashTotalsObj.totalCount }}</td>
            <td class="text-right">{{ this.$filters.formatPrice(nonCashTotalsObj.totalAmount) }}</td>
          </tr>
        </tbody>
      </table>

      <!--B. Non-Revenue Receipts/Items-->

      <table class="mt-3">
        <tbody>
          <tr>
            <td class="font-weight-bold">B. Non-Revenue Receipts/Items | Summary and Settlement</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(nonRevTotal) }}</td>
          </tr>
          <tr>
            <td class="indent-1" style="text-decoration: underline;">B1. Non-Revenue Receipts/Items</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table>
<!--        @foreach($non_revenue_items as $key => $value)-->
        <tbody>
          <tr v-for="(amount, name) in nonRevenueItems">
            <td class="indent-2" style="width: 50%;">{{ name }}</td>
            <td style="width: 30%" class="text-right">{{ this.$filters.formatPrice(amount) }}</td>
            <td style="width: 20%"></td>
          </tr>

          <tr>
            <td class="indent-1 border-top">Total Non-Revenue Receipts/Items</td>
            <td class="border-top text-right font-weight-bold">{{ this.$filters.formatPrice(nonRevTotal) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="mt-1">
        <tbody>
          <tr>
            <td class="font-weight-bold indent-1" style="text-decoration: underline;">B2. Settlement | Non-Revenue Receipts/Items</td>
            <td class="font-weight-bold text-right">{{ this.$filters.formatPrice(0) }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold indent-2">Settlement</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="indent-2" style="width: 50%">B2.1 CASH</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(0) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-2 border-bottom">B2.2 NON-CASH</td>
            <td class="text-right border-bottom">{{ this.$filters.formatPrice(0) }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1 font-weight-bold">Total Settlement</td>
            <td class="text-right font-weight-bold">{{ this.$filters.formatPrice(0) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="mt-2">
        <tbody>
          <tr>
            <td class="font-weight-bold indent-1" style="width: 35%">NON-CASH</td>
            <td class="text-right font-weight-bold" style="width: 30%">Transaction Count</td>
            <td class="text-right font-weight-bold" style="width: 35%">Amount</td>
          </tr>
  <!--        @foreach($non_revenue->settlement as $key => $value)-->
          <tr v-for="(breakdown, index) in nonCashPaymentMethods">
              <td class="indent-2">{{ breakdown.name }}</td>
              <td class="text-right">{{ 0  }}</td>
              <td class="text-right">{{ this.$filters.formatPrice(0) }}</td>
          </tr>

          <tr class="font-weight-bold border-top">
            <td class="indent-2">Total</td>
            <td class="text-right">{{ 0 }}</td>
            <td class="text-right">{{ this.$filters.formatPrice(0) }}</td>
          </tr>
        </tbody>
      </table>

      <!--settlement summary-->

      <table class="mt-3">
        <tbody>
          <tr>
            <td colspan="2" class="font-weight-bold text-center">**SETTLEMENT SUMMARY**</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Cash for Deposit</td>
            <td class="font-weight-bold text-right">{{ this.$filters.formatPrice(totalCashForDeposit) }}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="indent-1" style="width: 50%">Sales VAT-Inc Cash Settlement (A2.1)</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(totalEODCashSales) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-1">Non-Revenue Receipts/Items Cash Settlement (B2.1)</td>
            <td class="text-right">{{ this.$filters.formatPrice(0) }}</td>
            <td></td>
          </tr>

          <tr v-if="reportType !== 'eod'">
            <td class="indent-1">Employee Cash Transactions</td>
            <td class="text-right">{{ this.$filters.formatPrice(cashFloat) }}</td>
            <td></td>
          </tr>
          <tr v-if="reportType === 'eod'">
            <td class="indent-1">Employee Over (Short)</td>
            <td class="text-right">{{ this.$filters.formatPrice(totalOverShort) }}</td>
            <td></td>
          </tr>

          <tr>
            <td class="indent-1 border-top">Total Cash for Deposit</td>
            <td class="text-right border-top">{{ this.$filters.formatPrice(totalCashForDeposit) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table v-if="reportType !== 'eod'">
        <tbody>
          <tr>
            <td colspan="3" class="indent-1 font-weight-bold">Employee Cash Transactions</td>
          </tr>
          <tr>
            <td class="indent-2" style="width: 50%">Cash Fund</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(cashFloat) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-2">Cash Bleed 1</td>
            <td class="text-right">0</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1 font-weight-bold">Total Employee Cash Transactions</td>
            <td class="text-right">{{ this.$filters.formatPrice(cashFloat) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table v-if="reportType === 'eod'">
        <tbody>
          <tr>
            <td colspan="3" class="indent-1 font-weight-bold">Employee Over (Short)</td>
          </tr>

          <tr v-for="(over_short, name) in cashierShifts">
            <td class="indent-3" style="width: 50%">{{ name }}</td>
            <td style="width: 30%" class="text-right
            ">
            {{ this.$filters.formatPrice(over_short) }}
            </td>
            <td style="width: 20%"></td>
          </tr>

          <tr>
            <td class="indent-1 font-weight-bold border-top">Total Employee Over (Short)</td>
            <td class="text-right border-top
            ">
            {{ this.$filters.formatPrice(totalOverShort) }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="mt-2">
        <tbody>
          <tr>
            <td class="font-weight-bold">Non-Cash</td>
            <td class="font-weight-bold text-right">{{ this.$filters.formatPrice(totalEODNonCashSales) }}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="indent-1" style="width: 50%">Sales VAT-Inc Cash Settlement (A2.2)</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(totalEODNonCashSales) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-1">Non-Revenue Receipts/Items Cash Settlement (B2.2)</td>
            <td class="text-right">{{ this.$filters.formatPrice(0) }}</td>
            <td></td>
          </tr>

          <tr>
            <td class="indent-1 border-top">Total Non-Cash for Deposit</td>
            <td class="text-right border-top">{{ this.$filters.formatPrice(totalEODNonCashSales) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <!--CHECKOUT SUMMARY-->
      <table class="mt-3" v-if="reportType !== 'eod'">
        <tbody>
          <tr>
            <td colspan="3" class="font-weight-bold text-center">CHECKOUT SUMMARY</td>
          </tr>
          <tr>
            <td colspan="3"><br></td>
          </tr>
          <tr>
            <td colspan="3" class="mt-2 font-weight-bold">CASH SETTLEMENT</td>
          </tr>
          <tr>
            <td class="indent-1" style="width: 50%">CALCULATED CASH OWED</td>
            <td class="text-right" style="width: 30%">{{ this.$filters.formatPrice(totalCashForDeposit) }}</td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td class="indent-1">CALCULATED CASH DECLARED</td>
            <td class="text-right">0</td>
            <td></td>
          </tr>
          <tr>
            <td class="indent-1">Cash Over (Short)</td>
            <td class="text-right">0</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="mt-5" v-if="reportType !== 'eod'">
        <tbody>
          <tr>
            <td class="indent-1" style="width: 50%">Employee</td>
            <td style="width: 30%"></td>
            <td style="width: 20%"></td>
          </tr>
          <tr>
            <td colspan="3">
              <br>
              <br>
            </td>
          </tr>
          <tr>
            <td class="indent-1" style="width: 50%">Cash Received By</td>
            <td style="width: 30%"></td>
            <td style="width: 20%"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import {mapState} from 'vuex';
import moment from "moment";

export default {
    name: 'EodPreviewModal',
    props: {
        reportType: {
            type: String,
            required: true
        },

        recieptDetails: {
            type: Object,
            required: true,
        },

        receiptDate: {
            type: String,
            required: true,
        },

        beginningBalance: {
            type: Number,
            required: true,
        },

        endingBalance: {
            type: Number,
            required: true,
        },

        totalGrossWithServiceCharge: {
            type: Number,
            required: true
        },

        totalBeforeServiceCharge: {
            type: Number,
            required: true,
        },

        totalNetSales: {
            type: Number,
            required: true,
        },

        beginningSettlement: {
            type: Object,
            required: true,
        },

        endingSettlement: {
            type: Object,
            required: true,
        },

        beginningVoidAmount: {
            type: Number,
            required: true
        },

        endingVoidAmount: {
            type: Number,
            required: true
        },

        voidedSalesAmount: {
            type: Number,
            required: true
        },

        discountItems: {
            type: Array,
            required: true,
        },

        serviceTypes: {
            type: Array,
            required: true,
        },

        totalServiceCharge: {
            type: Number,
            required: true,
        },

        totalVatAmount: {
            type: Number,
            required: true,
        },

        totalCashSales: {
            type: Number,
            required: true,
        },

        totalNonCashSales: {
            type: Number,
            required: true,
        },

        totalEODCashSales: {
            type: Number,
            required: true,
        },

        totalEODNonCashSales: {
            type: Number,
            required: true,
        },

        paymentMethods: {
            type: Array,
            required: true,
        },

        nonRevenueOrders: {
            type: Object,
            required: true,
        },

        totalOverShort: {
            type: Number,
            required: true,
        },

        cashFloat: {
            type: Number,
            default: 0,
        },

        cashWithdraw: {
            type: Number,
            default: 0,
        },

        totalGrossNet: {
          type: Number,
          required: true
        },

        cashierShiftsData: {
          type: Array,
          default: [],
        },
    },
    data() {
      return {
        businessDate: null,
      }
    },
    components: {
        BaseModal,
    },
    computed: {
        ...mapState([
            'activeBrandId'
        ]),

        ...mapState('user', ['terminalId','username']),

        ...mapState('settings', ['posDate','shiftTable', 'currentShift']),

        reportTitle() {
            return this.reportType === "eod" ? "EOD" : "Cashier Checkout";
        },

        showGrandTotal() {
            return this.reportType === "eod";
        },

        dateToday() {
          return moment(this.receiptDate).format("LLL");
        }, 

        totalGrossSales() {
          return this.totalGrossNet + this.totalDiscounts;
        },

        grossEndBalance() {
          return this.beginningBalance + this.totalGrossSales;
        },

        totalPunchedSales() {
          return this.totalGrossSales + this.voidedSalesAmount;
        },

        totalDiscounts() {
          const totalDiscounts = this.discountItems.reduce((accumulator, object) => {
          return accumulator + object.amount;
          }, 0);

          return totalDiscounts;
        },

        serviceTypesNet() {
          return this.serviceTypes.reduce((accumulator, st) => {
            return accumulator + st.amount;
          }, 0);
        },

        totalSalesIncomeAndCharge() {
          return this.serviceTypesNet + this.totalServiceCharge;
        },

        totalSettlement() {
          return this.totalEODCashSales + this.totalEODNonCashSales;
        },

        nonCashPaymentMethods() {
          return this.paymentMethods.filter(p => p.name != 'CASH');
        },

        nonCashTotalsObj() {
          let totalCount = this.nonCashPaymentMethods.reduce((accumulator, p) => {
            return accumulator + (p.count ?? 0);
          }, 0);

          let totalAmount = this.nonCashPaymentMethods.reduce((accumulator, p) => {
            return accumulator + (p.amount ?? 0);
          }, 0);

          return {
            totalCount,
            totalAmount
          }
        },

        nonRevenueItems() {
          let nonRevItems = {};

          for (const prop in this.nonRevenueOrders) {
            this.nonRevenueOrders[prop].forEach(o => {
              if(nonRevItems[o.product.product_name]) {
                nonRevItems[o.product.product_name] += o.totals.total;
              } else {
                nonRevItems[o.product.product_name] = o.totals.total;
              }
            });
          }

          return nonRevItems;
        },

        nonRevTotal() {
          let total = 0;

          for (const prop in this.nonRevenueItems) {
            total += this.nonRevenueItems[prop];
          }

          return total;
        },

        cashierShifts() {
          let data = {};
          
          this.cashierShiftsData.forEach(cs => {
            let name = cs.shiftTable.user.last_name + ' ' + cs.shiftTable.user.first_name;
            if(data[name]) {
              data[name] += cs.over_short;
            } else {
              data[name] = 0;
            }
          });

          return data;
        },

        totalCashForDeposit() {
          let addend = this.reportType !== 'eod' ? this.cashFloat - this.cashWithdraw : 0;

          return this.totalEODCashSales + addend;
        }
    },

    mounted() {
      this.businessDate = moment(this.posDate).format("LLL");
    },

    methods: {
        padWithZero(num) {
            return String(num || 0).padStart(20, '0');
        },
    }
}
</script>

<style scoped>
.x-report {
  width: 300;
}

.x-read-title > div > h5 {
    text-align: center;
    text-transform: uppercase;
}

.x-read-title {
    margin-bottom: 17px;
    font-weight: bold;
}

.x-read-printer-details {
    font-weight: bold;
    text-align: center;
}

.x-read-printer-details > div > code {
    color: #000;
}

table {
  font-size: 12px;
}

table tr td {
  vertical-align: text-top;
}

.indent-1 {
  text-indent: 10px;
}

.indent-2 {
  text-indent: 20px;
}

.indent-3 {
  text-indent: 30px;
}
</style>