<template>
    <base-modal
        v-bind="$attrs"
        :confirm-disabled="!isForcedModsRequiredQtySatisfied"
        :minWidth="'100vw'"
        @confirm="emitSelectedMods"
        @before-open="$nextTick(() => scrollToFirstGroup(false))"
        >
        <template v-slot:title>Modifiers</template>

        <hr class="d-none d-lg-block" v-if="forcedGroups.length" />

        <div v-for="group in forcedGroups" :key="group.id">
            <label class="mt-3 mt-lg-0" :id="`group-${group.id}`">
                {{ group.name }}
                <small>Pick {{ group.requiredQty || minimumRequiredQtyOverride }}</small>
            </label>

            <div class="form-check mod-radio d-lg-none" v-for="mod in group.modifiers" :key="`checkbox-${mod.id}`">
                <input type="radio"
                    class="form-check-input"
                    :name="`modradio${group.id}`"
                    :id="`cbinput-${mod.id}`"
                    :checked="isForcedModSelected(mod, group.id)"
                    @click="selectForced(mod, group.id)"
                    />
                <label class="form-check-label" :for="`cbinput-${mod.id}`">
                    {{ mod.modifier_name }}
                </label>
            </div>

            <div class="mods-row d-none d-lg-flex flex-wrap">
                <m-card
                    v-for="mod in group.modifiers"
                    :key="mod.id"
                    :label="`₱ ${mod.mod_price}.00`"
                    :active="isForcedModSelected(mod, group.id)"
                    ratio="1:1"
                    clickable
                    @click="selectForced(mod, group.id)"
                >
                    <template #default>
                        {{ mod.modifier_name }}

                        <div
                            v-if="isForcedModSelected(mod, group.id)"
                            class="d-flex mod-qty"
                            >
                            <button
                                v-if="group.requiredQty > 1"
                                class="quantity-control"
                                @click.stop="subtractForcedMod(mod, group.id)"
                                v-text="'-'"
                            />

                            <span class="mx-2">{{ forcedMods[group.id][mod.modifier_detail_id] }}</span>

                            <button
                                v-if="group.requiredQty > 1"
                                class="quantity-control"
                                @click.stop="selectForced(mod, group.id)"
                                v-text="'+'"
                            />
                        </div>
                    </template>
                </m-card>
            </div>
        </div>

        <hr class="d-none d-lg-block" v-if="unforcedGroups.length" />

        <div v-for="group in unforcedGroups" :key="group.id">
            <label class="mt-3 mt-lg-0">
                {{ group.name }}
                <small>{{ group.modifiers.length }} items</small>
            </label>

            <div class="form-check mod-radio d-lg-none" v-for="mod in group.modifiers" :key="mod.id">
                <input type="checkbox"
                    class="form-check-input"
                    :id="`ufmodcb-${mod.id}`"
                    :checked="unforcedMods[mod.modifier_detail_id] >= 1"
                    @click="toggleUnforcedMod(mod)"
                    />
                <label :for="`ufmodcb-${mod.id}`">{{ mod.modifier_name }}</label>
            </div>

            <div class="mods-row d-none d-lg-flex flex-wrap">
                <m-card
                    v-for="mod in group.modifiers"
                    :key="mod.id"
                    :label="`₱ ${mod.mod_price}.00`"
                    :active="mod.modifier_detail_id in unforcedMods"
                    ratio="1:1"
                    clickable
                    @click="addUnforcedMod(mod)"
                >
                    <template #default>
                        {{ mod.modifier_name }}
                        <div
                            v-if="mod.modifier_detail_id in unforcedMods"
                            class="d-flex mod-qty"
                            >
                            <button
                                class="quantity-control"
                                @click.stop="subtractUnforcedMod(mod)"
                                v-text="'-'"
                            />

                            <span>{{ unforcedMods[mod.modifier_detail_id] }}</span>

                            <button
                                class="quantity-control"
                                @click.stop="addUnforcedMod(mod)"
                                v-text="'+'"
                            />
                        </div>
                    </template>
                </m-card>
            </div>
        </div>
    </base-modal>
</template>

<script>
import { fromPairs, every, isEmpty, uniqBy, some, map, reduce, sum, size, values, mapValues } from 'lodash';
import MCard from '@/spa/components/common/MCard.vue';
import BaseModal from '@/spa/components/modals/BaseModal.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'ModifiersModal',

    components: {
        MCard,
        BaseModal,
    },

    props: {
        modifiers: {
            type: Array,
            required: true,
        },

        minimumRequiredQtyOverride: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            forcedMods: {},
            unforcedMods: {},
        };
    },

    computed: {
        ...mapState([
            'activeBrandId',
            'activeServiceTypeId',
            'activeChannelId',
        ]),
        ...mapGetters(['activeOrder']),

        forcedGroups() {
            const forced = this.modifiers.filter(
                modifier => modifier.modifier_type == 'Forced'
            );
            return this.parseGroups(forced);
        },

        unforcedGroups() {
            const unforced = this.modifiers.filter(
                modifier => modifier.modifier_type == 'Unforced'
            );
            return this.parseGroups(unforced);
        },

        isForcedModsRequiredQtySatisfied() {
            const isAllForcedModsHaveSelections = size(this.forcedMods) === size(this.forcedGroups);
            const isAllForcedModsQtyMatchRequired = every(
                this.forcedMods,
                (selectedMods, groupId) => this.getGroupRequiredQty(groupId) == sum(values(selectedMods)),
            );

            return isAllForcedModsHaveSelections
                && isAllForcedModsQtyMatchRequired;
        },
    },

    watch: {
        modifiers() {
            this.resetSelection();
        },

        forcedGroups(groups) {
            this.$nextTick(() => {
                this.forcedMods = fromPairs(groups.map(group => [group.id, {}]));
            });
        },
    },

    methods: {
        resetSelection() {
            this.forcedMods = {};
            this.unforcedMods = {};
        },

        getGroupRequiredQty(groupId) {
            const group = this.forcedGroups.find(g => g.id == groupId);
            const requiredQty = group ? group.requiredQty : 0;
            return Math.max(this.minimumRequiredQtyOverride, requiredQty);
        },

        selectForced(mod, groupId) {
            if (groupId in this.forcedMods === false) {
                this.forcedMods[groupId] = {};
            }

            const currentQty = sum(Object.values(this.forcedMods[groupId]));
            const requiredQty = this.getGroupRequiredQty(groupId);

            if (currentQty == 1 && requiredQty == 1) {
                if (this.forcedMods[groupId][mod.modifier_detail_id] === 1) {
                    return this.subtractForcedMod(mod, groupId)
                }

                this.forcedMods[groupId] = mapValues(this.forcedMods[groupId], () => 0);
                this.forcedMods[groupId][mod.modifier_detail_id] = 1;
                return;
            }

            if (currentQty >= requiredQty) return;

            if (mod.modifier_detail_id in this.forcedMods[groupId]) {
                this.forcedMods[groupId][mod.modifier_detail_id]++;
            } else {
                this.forcedMods[groupId][mod.modifier_detail_id] = 1;
            }

            const newQty = currentQty + 1;
            if (newQty === requiredQty) {
                this.scrollToNextGroup(groupId)
            }
        },

        scrollToGroup(groupId, smooth = true) {
            const groupLabel = document.getElementById(`group-${groupId}`);
            if (!groupLabel) return false;

            groupLabel.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
        },

        scrollToFirstGroup(smooth = true) {
            const groupIdArray = map(this.forcedGroups, 'id');
            if (isEmpty(groupIdArray)) return;

            this.scrollToGroup(groupIdArray[0], smooth);
        },

        scrollToNextGroup(groupId) {
            const groupIdArray = map(this.forcedGroups, 'id');
            const currentIndex = groupIdArray.findIndex(v => v === groupId);
            if (currentIndex === groupIdArray.length - 1) return;

            this.scrollToGroup(groupIdArray[currentIndex + 1]);
        },

        subtractForcedMod(mod, groupId) {
            if (groupId in this.forcedMods === false) {
                this.forcedMods[groupId] = {};
            }

            if (mod.modifier_detail_id in this.forcedMods[groupId]) {
                this.forcedMods[groupId][mod.modifier_detail_id]--;
            }
        },

        isForcedModSelected(mod, groupId) {
            return groupId in this.forcedMods
                && mod.modifier_detail_id in this.forcedMods[groupId]
                && this.forcedMods[groupId][mod.modifier_detail_id] > 0;
        },

        toggleUnforcedMod(mod) {
            if (mod.modifier_detail_id in this.unforcedMods) {
                this.subtractUnforcedMod(mod);
            } else {
                this.addUnforcedMod(mod);
            }
        },

        addUnforcedMod(mod) {
            if (mod.modifier_detail_id in this.unforcedMods) {
                this.unforcedMods[mod.modifier_detail_id]++;
            } else {
                this.unforcedMods[mod.modifier_detail_id] = 1;
            }
        },

        subtractUnforcedMod(mod) {
            if (mod.modifier_detail_id in this.unforcedMods) {
                if (this.unforcedMods[mod.modifier_detail_id] > 1) {
                    this.unforcedMods[mod.modifier_detail_id]--;
                } else {
                    delete this.unforcedMods[mod.modifier_detail_id];
                }
            }
        },

        parseGroups(modifiers = []) {
            return modifiers.reduce((acc, modifier) => {
                const group = acc.find(group => group.id == modifier.modifier_group_id);
                if (group) {
                    group.modifiers.push(modifier);
                } else {
                    acc.push({
                        id: modifier.modifier_group_id,
                        name: modifier.mod_group_name,
                        requiredQty: modifier.required_qty,
                        modifiers: [modifier],
                    });
                }
                return acc;
            }, []);
        },

        checkForcedModsForEmpty() {
            return !every(map(this.forcedMods, modGroup => some(modGroup)));
        },

        emitSelectedMods() {
            if (this.checkForcedModsForEmpty()) {
                this.$swal.warning('Please select a modifier for each required mod groups');
                return;
            }

            const activeBrandId = this.activeOrder.brandId || this.activeBrandId;
            const activeServiceTypeId = this.activeOrder.serviceTypeId || this.activeServiceTypeId;
            const activeChannelId = this.activeOrder.channelId || this.activeChannelId;

            const forcedModIds = Object.values(this.forcedMods).map(Object.keys).flat().map(Number);
            let forcedMods = this.modifiers.filter(
                modifier => forcedModIds.includes(modifier.modifier_detail_id)
                    && modifier.modifier_type == 'Forced'
                    && (!activeBrandId || modifier.brand_id == activeBrandId)
                    && (!activeServiceTypeId || modifier.service_type_id == activeServiceTypeId)
                    && (!activeChannelId || modifier.service_channel_id == activeChannelId)
            );

            if (isEmpty(forcedMods)) {
                forcedMods = uniqBy(this.modifiers.filter(
                    modifier => forcedModIds.includes(modifier.modifier_detail_id)
                        && modifier.modifier_type == 'Forced'
                ), 'modifier_detail_id');
            }

            const flatForcedMods = reduce(this.forcedMods, (acc, modGroup) => ({ ...acc, ...modGroup }), {});
            forcedMods = map(forcedMods, mod => ({ ...mod, quantity: flatForcedMods[mod.modifier_detail_id] }))
                .filter(mod => mod.quantity > 0);

            let unforcedMods = Object.keys(this.unforcedMods).map(
                modifierDetailId => {
                    const mod = this.modifiers.find(
                        modifier => modifier.modifier_detail_id == modifierDetailId
                        && modifier.modifier_type == 'Unforced'
                        && (!activeBrandId || modifier.brand_id == activeBrandId)
                        && (!activeServiceTypeId || modifier.service_type_id == activeServiceTypeId)
                        && (!activeChannelId || modifier.service_channel_id == activeChannelId)
                    ) || this.modifiers.find(
                        modifier => modifier.modifier_detail_id == modifierDetailId
                        && modifier.modifier_type == 'Unforced'
                        && (!activeBrandId || modifier.brand_id == activeBrandId)
                        && (!activeChannelId || modifier.service_channel_id == activeChannelId)
                    );

                    return {
                        ...mod,
                        quantity: this.unforcedMods[modifierDetailId],
                    };
                }
            );

            if (isEmpty(unforcedMods)) {
                unforcedMods = uniqBy(this.modifiers.filter(
                    modifier => Object.keys(this.unforcedMods).includes(modifier.modifier_detail_id)
                        && modifier.modifier_type == 'Unforced'
                ), 'modifier_detail_id');
            }

            this.$emit('modsSelected', { forcedMods, unforcedMods });
        },
    },
};
</script>

<style scoped>
.mods-row {
    gap: 16px;
    margin: 24px;
}

.quantity-control {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    display: grid;
    place-content: center;
}

.mod-qty {
    align-items: center;
    justify-content: space-around;
    padding: 8px 0;
}

#mosaic-pos-app .mod-radio {
    border-bottom: 1px solid #DFECFF;
    padding: 1rem;
    display: flex;
    align-items: center;
}

#mosaic-pos-app .mod-radio input[type=radio],
#mosaic-pos-app .mod-radio input[type=checkbox] {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin: 0 16px 0 0;
}

#mosaic-pos-app .mod-radio label {
    color: #343A40;
    font-weight: normal;
    text-transform: none;
    margin: 0 1rem;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
}

label small {
    color: #343A40;
    font-weight: 500;
}
</style>
