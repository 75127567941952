<template>
    <tr>
        <td class="cell100 column1">{{ paymentType }}</td>
        <td class="cell100 column2 pl-2 x-read-expected-amt-td text-right" id="CASH-td"> <div v-show="showSpotAudit"> {{ this.$filters.formatPrice(expectedAmount) }}</div></td>
        <td class="text-right cell100 column3 text-dark pl-2 CASH-input" align="right" id="x-read-cash-payment-input"> 
            <div v-if="!isCash">
                <input type="text" style="text-align:right;" v-model="paymentAmount" :disabled="isChangeShift"/>
            </div>
            <div v-else-if="isCash" >{{this.$filters.formatPrice(paymentAmount)}}</div> 
        </td>
        <td class="cell100 column4 over-short-td text-right" ><div v-show="showSpotAudit">{{ this.$filters.formatPrice(overShortAmount) }}</div></td>
    </tr>
</template>

<script>
export default {
    name: 'PaymentBreakdownItem',
    props: {
        expectedAmount: {
            type: Number,
            required: true,
        },
        paymentType: {
            type: String,
            required: true,
        },
        isCash: {
            type: Boolean,
            required: true,
        }, 
        totalCashDeposit: {
            type: Number,
            required: true,
        },
        showSpotAudit: {
            type: Boolean,
            required: true,
        }, 
        isChangeShift: {
            type: Boolean,
            default: false
        },
        totalCashFloat: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            paymentAmount: 0,
            overShortAmount: 0,
        }
    },
    mounted() {
        if (!this.isCash && this.isChangeShift) {
            this.paymentAmount = this.expectedAmount
        }

        if (!this.isChangeShift) {
            if (this.isCash) {
                this.overShortAmount -= (this.expectedAmount + this.totalCashFloat);
            } else {
                this.overShortAmount -= this.expectedAmount;
            }
        }
    },
    watch: {
        expectedAmount(value) {
            this.overShortAmount = this.paymentAmount - value;
        },

        paymentAmount(value) {
            this.overShortAmount = value - this.expectedAmount;
        },
        totalCashDeposit(value) {
            if(this.isCash) {
                this.paymentAmount = value;
                this.overShortAmount =  value - this.expectedAmount;
            }
        },
        overShortAmount(value) {
            const paymentBreakdown = {
                paymentType: this.paymentType,
                paymentAmount: parseFloat(this.paymentAmount),
                overShortAmount: value,
            }

            this.$emit('applyPaymentBreakdown', paymentBreakdown);
        }
    }
};
</script>