import receiptHtml from '@/spa/components/templates/print/receipt.html';
import voidSlipHtml from '@/spa/components/templates/print/void_slip.html';
import qrPaymentHtml from '@/spa/components/templates/print/qr_payment.html';
import { isEmpty, get, mapValues, pick, flattenDeep, size, some } from 'lodash';
import { convert } from 'html-to-text';
import table_layout from 'table-layout';
import { dbService } from "@/spa/services/db-service";
import cloneDeep from 'rfdc/default';
import {
    IS_NON_VAT,
    ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE,
    USE_SM_MARKETS_OR_FORMAT,
    USE_BFC_OR_FORMAT,
    IS_PUC_LOCATION,
    ENABLE_NEW_VAT_EXEMPT_SALES,
    OFFLOAD,
    MOSAIC_COMPANY_NAME_AND_ADDRESS_FOR_PRINTOUT,
    USE_NEW_AMOUNT_DUE_FORMULA,
    LOCATION_PRINT_LAYOUT_OPTION
} from "@/spa/constants";
import { VAT_EXCL, hasDiscountEligibilityKeywords } from "@/vue/helper/discount";
import { Logger } from "@/spa/helpers/Logger";
import {appendNumberToInteger, formatAmount} from "@/mobile_bridge/offload/receipt-model";

const maxPerLine = 32; //76mm - 80mm paper width

export const itemRawPrice = (activeOrder, item) => {
    if (!item) return 0;


    item = JSON.parse(JSON.stringify(item)); // this will force into object.. got an error if there is an open item

    if(item.isOpenItem) {
        return item.activePrice;
    }

    let product = item.product;
    if(product.isSecondItem) {
        if(product.hasModifier) {
            let newPrice = 0;
            if(product.promo_type === 'BOGO') {
                if (product.unforcedMods) {
                    product.unforcedMods.forEach(mod => {
                        newPrice += (Number(mod.mod_price) * Number(mod.quantity));
                    });
                }
            } else {
                newPrice += Number(item.activePrice);
                if (product.forcedMods) {
                    product.forcedMods.forEach(mod => {
                        newPrice += Number(mod.mod_price) * Number(mod.quantity);
                    });
                }
                
                newPrice -= product.promo_type === 'By Percentage' ? (newPrice * (product.promo_percentage/100)) : 0;
                
                if (product.unforcedMods) {
                    product.unforcedMods.forEach(mod => {
                        newPrice += (Number(mod.mod_price) * Number(mod.quantity));
                    });
                }

                if(product.promo_type === 'By Amount') {
                    newPrice -= product.promo_amount;
                    newPrice = newPrice < 0 ? 0: newPrice;
                }
            }
            return newPrice;
        } else {
            if(product.promo_type === 'BOGO') {
                return 0;
            } else {
                return item.activePrice - (product.promo_type === 'By Amount' ? product.promo_amount : (item.activePrice * (product.promo_percentage/100)));
            }
        }
    }

    const activeServiceTypeId = activeOrder.serviceTypeId;
    const activeChannelId = activeOrder.channelTypeId;

    if(!item.product.pricings) return 0;

    let pricings = item.product.pricings.filter(p => p.service_type_id == activeServiceTypeId);
    if (activeChannelId) {
        pricings = pricings.filter(p => p.service_channel_id == activeChannelId);
    }

    let basePrice = pricings.length ? Number(pricings[0].prod_price) : 0;
    if (item.product.forcedMods) {
        item.product.forcedMods.forEach(mod => {
            basePrice += (Number(mod.mod_price)  * Number(mod.quantity));
        });
    }

    if (item.product.unforcedMods) {
        item.product.unforcedMods.forEach(mod => {
            basePrice += (Number(mod.mod_price) * Number(mod.quantity));
        });
    }

    return basePrice;
};


const longWordFormatter = (name) => {
    let splitNameArr = name.toString().split(" ");

    let longWords = splitNameArr.filter(n => n.length > 9);

    if(longWords.length > 0) {
        return splitNameArr.flatMap(sn => {
            if(sn.length > 9) {
                //set slice start end
                let start = 0;
                let end = 7;

                let ret = [];
                //add hyphen
                let loopCount = Math.ceil(sn.length/8);
                for (let index = 0; index < loopCount; index++) {
                    let hyphen = end < sn.length ? "-" : "";
                    ret.push(sn.slice(start, end) + hyphen);
                    start += 7;
                    end += 8;
                }
                return ret;
            }

            return sn;
        }).join(' ');
    } else {
        return name;
    }
}

// NOTE: Refrain from utilizing the totals from the returned result when it is consolidated.
export const kotGroupOrdersByServiceType = async (orderDetail, consolidated=false) => {
    await dbService.openDB('mosaic-posDB', 10);
    const serviceTypes = await dbService.fetchItems('service_type') || [];

    if (consolidated) {
        return consolidatedOrdersByServiceType(orderDetail.orders, serviceTypes);
    }

    return orderDetail.orders.reduce((acc, order) => {
        const serviceType = serviceTypes?.find(i => i.id === order.serviceTypeId)?.service_name || 'Unknown Service Type'
        if (!acc[serviceType]) {
            acc[serviceType] = [];
        }

        acc[serviceType].push(order);
        return acc;

    }, {});
}

export const consolidatedOrdersByServiceType = (orders, serviceTypes) => {
    const consolidated = {};

    orders.forEach(o => {
        const serviceType = serviceTypes?.find(i => i.id === o.serviceTypeId)?.service_name || 'Unknown Service Type'

        const prodString = JSON.stringify({
            ...pick(o.product, ['id', 'forcedMods', 'unforcedMods', 'product_name', 'price', 'customizedProductSummary']),
            kot: Boolean(o.kot),
            isVoided: Boolean(o.isVoided),
            serviceTypeId: o.serviceTypeId
        });

        consolidated[serviceType] = consolidated[serviceType] || {};

        if (consolidated[serviceType][prodString]) {
            consolidated[serviceType][prodString].quantity += o.quantity;
            consolidated[serviceType][prodString]._ids.push(o._id);
            if (o.discount && consolidated[serviceType][prodString].discount) {
                consolidated[serviceType][prodString].discount.discountPax += o.discount.discountPax;
                consolidated[serviceType][prodString].discount.discountAmount += o.discount.discountAmount;
            }
        } else {
            consolidated[serviceType][prodString] = cloneDeep(o);
            consolidated[serviceType][prodString]._ids = [o._id];
        }
    });

    const combined = {};
    for (const serviceType in consolidated) {
        combined[serviceType] = Object.values(consolidated[serviceType]);
    }

    return combined;
}

const stringEndSplicer = (string, maxSizePerLine = 32, identifier = '') => {
    let stringCopy = string;
    //remove any identifiers
    if(identifier !== '') {
        stringCopy = stringCopy.replace(identifier, '');
    }
    //get difference
    const diff = stringCopy.length - maxSizePerLine;
    const removeCount = diff % 2 > 0 ? Math.round(diff / 2) + 1 : (diff / 2);

    //remove from start and end
    string = string.slice(removeCount);
    string = string.slice(0, -removeCount);

    return string;
}

export const generateReceiptItemString = async (activeOrder) => {

    let receipt_items_string = '';
    const newOrders = await kotGroupOrdersByServiceType(activeOrder, true);

    let svcTypeIdentifier = '__SERVICE_TYPE__';
    for (let serviceType in newOrders) {
        let dataArr = [
            {
                "item": "ITEM",
                "qty": "QTY",
                "price": "PRICE",
                "amount": "AMOUNT",
            },
        ];

        const orders = newOrders[serviceType].filter(o => !o.isCancelled && !o.isVoided)
        if (orders.length > 0) {
            //servicetype extra space trimmer
            serviceType = serviceType.trim().replace(/\s+/g, '&nbsp;').replace(/(\r\n|\n|\r)/gm, '');

            let serviceTypeString = `==============${serviceType.toUpperCase() + svcTypeIdentifier}==============`;

            if(serviceTypeString.replace(svcTypeIdentifier, '').length > maxPerLine) {
                serviceTypeString = stringEndSplicer(serviceTypeString, maxPerLine, svcTypeIdentifier);
            }

            receipt_items_string += `${serviceTypeString}\n`;

        }

        orders.map(function(item) {
            let productItem = item.product;

            //push empty obj for spacing
            dataArr.push({"item": "","qty": "","price": "","amount": ""});
            let item_name = (productItem.product_name.length > 9 && productItem.sku && productItem.sku.toString() !== productItem.id.toString()) ? productItem.sku : productItem.product_name;

            //format name in case sku is not available and there is a very long word
            item_name = longWordFormatter(item_name);
            let itemPrice = itemRawPrice(activeOrder, item);
            let itemAmount = itemPrice * item.quantity;
            let itemObj = {
                "item": item_name,
                "qty": item.quantity,
                "price": parseFloat(itemPrice).toFixed(2),
                "amount": parseFloat(itemAmount).toFixed(2),
            };

            dataArr.push(itemObj);

            if(productItem.hasModifier == true) {
                let modArr = [...productItem.forcedMods, ...productItem.unforcedMods];
                modArr.forEach(function (mod) {
                    let modname = (mod.modifier_name.length > 8 && mod.modifier_code?.length > 0) ? mod.modifier_code : mod.modifier_name;
                    //format mod name in case code is not available and there is a very long word
                    modname = longWordFormatter(modname);
                    let modObj = {
                        "item": "*" + modname,
                        "qty": mod.quantity,
                        "price": "",
                        "amount": "",
                    };
                    dataArr.push(modObj);
                });
            }
        });

        let jsonOpts = {
            "columns": [
                { "name": "item", "width": 10, "break": true,
                    "padding": {
                    "left": " ",
                    "right": ""
                    },
                },
                { "name": "qty", "width": 3,
                    "padding": {
                    "left": " ",
                    "right": ""
                    },
                },
                { "name": "price", "minWidth": 7, "maxWidth": 9,
                    "padding": {
                    "left": " ",
                    "right": ""
                    },
                },
                { "name": "amount", "minWidth": 7, "maxWidth": 9,
                    "padding": {
                    "left": " ",
                    "right": ""
                    },
                }
            ]
        };

        const table = new table_layout(dataArr, jsonOpts);
        receipt_items_string += `${table.toString()}\n\n`;
    }

    return receipt_items_string;
}

const getVatRegInfo = (receiptDetails) => {
    const prefix = IS_NON_VAT ? 'Non-' : '';
    return `<td>
                ${prefix}VAT REG TIN: ${get(receiptDetails, 'location_vat_reg', '')}<br>
                MIN: ${get(receiptDetails, 'lp_min', '')}<br>
                Serial No.: ${get(receiptDetails, 'lp_serial', '')}
            </td>`;
}

/**
 * THESE is the template for receiptArgs
 * receiptArgs {
 *    formattedTotals
 *    tableName
 *    receiptDetails
 *    customerDetails
 *    discountContainer
 *    payments
 *    dataContainer
 *    activeBrandId
 *    serviceType
 *    billings
 *    mode = settlement, bill
 *}
*/
export const getReceiptPrintString = async (activeOrder, receiptArgs, qrcode = null) => {
    const vatConfig = activeOrder.orders[0].activeVat;
    let formattedTotals = receiptArgs.formattedTotals;
    let isCaramiaOverride = false;
    const receiptBridge = new ReceiptBridge();
    const receiptDetailBridge = new ReceiptDetailBridge();

    //mutate formattedTotals to remove comma on thousands value
    formattedTotals =  mapValues(formattedTotals, v => v !== null ? v.replace(/,/g, '') : v);

    if (OFFLOAD.sqliteOffloadReceipt && USE_NEW_AMOUNT_DUE_FORMULA) {
        const receipt = await receiptBridge.getReceiptByLocalId(activeOrder._id);
        formattedTotals.total = parseFloat(receipt.gross_sales);
    }

    const shouldHideServiceCharge = formattedTotals.serviceCharge == 0;
    let receiptDetails = OFFLOAD.sqliteOffloadPOSFE1300
        ? await receiptDetailBridge.getRowByLocationId(activeOrder.locationId)
        : receiptArgs.receiptDetails;

    let printTemplate = receiptHtml;

    let today = new Date();
    let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    let time =
        ("0" + today.getHours()).substr(-2) + ":" + ("0" + today.getMinutes()).substr(-2) + ":" + ("0" + today.getSeconds()).substr(-2);
    let dateTime = date + " " + time;

    let identifier = receiptArgs.tableName ?? activeOrder.tableId;

    let transactionNum = activeOrder.kots.join(", ");

    let isHappilee = false;
    let vatRegInfo = getVatRegInfo(receiptDetails);

    let subTotal = parseFloat(0);
    let totalItems = 0; 
    const newOrders = await kotGroupOrdersByServiceType(activeOrder, true);
    let itemString = ''
    let svcTypeIdentifier = '__SERVICE_TYPE__';
    for (let serviceType in newOrders) {
        const orders = newOrders[serviceType].filter(o => !o.isCancelled && !o.isVoided);
        if (orders.length > 0) {
            //servicetype extra space trimmer
            serviceType = serviceType.trim().replace(/\s+/g, '&nbsp;').replace(/(\r\n|\n|\r)/gm, '');

            let serviceTypeString = `==============${serviceType + svcTypeIdentifier}==============`;

            if(serviceTypeString.replace(svcTypeIdentifier, '').length > maxPerLine) {
                serviceTypeString = stringEndSplicer(serviceTypeString, maxPerLine, svcTypeIdentifier);
            }

            itemString += `<tr><td valign="top" colspan="4">${serviceTypeString}</td></tr>`;
        }

        orders.map(function(item) {
            let item_name = item.product.product_name;
            let itemPrice = itemRawPrice(activeOrder, item);
            let itemAmount = itemPrice * item.quantity;
            let totalModPrice = 0;
            totalItems += item.quantity;

            let forced_mod_name = !isEmpty(item.product.forcedMods)
                ? item.product.forcedMods.map(function (fmod) {
                    totalModPrice += fmod.mod_price;
                    return `${fmod.quantity} ${fmod.modifier_name}`
                 }).join(", ")
                : '';
            let unforced_mod_name = !isEmpty(item.product.unforcedMods)
                ? item.product.unforcedMods.map(function (ufmod) {
                    totalModPrice += ufmod.mod_price;
                    return `${ufmod.quantity} ${ufmod.modifier_name}`
                 }).join(", ")
                : '';

            let bundledProducts = item.product?.is_bundle ? item.product?.bundled_products
                .map(product => `${product.quantity} ${product.product_detail.product_name} ${product?.is_free ? 'FREE':''}`) : [];

            let customizedProducts = item.product?.is_customize ? item.product?.customizedProductSummary.map(customizedProduct =>
                customizedProduct.choices
                    .filter(choice => choice.selectedCount)
                    .map(choice => `${choice.selectedCount} ${choice.name}`)) : [];

            const childProducts = [...bundledProducts, ...flattenDeep(customizedProducts)].join(', ');

            const mod_name_prefix = (forced_mod_name || unforced_mod_name || childProducts) ? " - " : '';
            let mod_name = mod_name_prefix + forced_mod_name + unforced_mod_name + childProducts;
            let combined_length = item_name.length + mod_name.length;
            let nameLimitMarker = 9;
            subTotal += (itemAmount);

            itemString += `
                    ${combined_length > nameLimitMarker ? `<tr>
                    <td colspan="4" valign="top" class="product-name">
                        ${item.product.product_name + '__MOD__' + mod_name + '__MOD__'}
                    </td>
                </tr>
                <tr>
                    <td>__BLANK__</td>`
                : `<tr>
                    <td valign="top" class="product-name">
                        ${item.product.product_name + mod_name}
                    </td>`}
                    <td valign="top" class="strlen">${item.quantity % 1 != 0 ? item.quantity.toFixed(2) : item.quantity}</td>
                    <td valign="top" class="strlen">
                        ${parseFloat(itemPrice).toFixed(2)}
                    </td>
                    <td valign="top" class="strlen">
                        ${parseFloat(itemAmount).toFixed(2)}<br>
                    </td>
                </tr>`;
        });
        itemString += '<tr><td colspan="4"><br /></td></tr>';
    }

    if(USE_SM_MARKETS_OR_FORMAT && receiptArgs.mode == 'settlement') {
        itemString += `
            <tr>
                <td valign="top" class="product-name"> 
                    TOTAL QTY
                </td>
                <td>
                    ${totalItems}
                </td>
            </tr>
        `;
    }

    let tenderedPayments = receiptArgs.mode == "settlement" ? receiptArgs.payments.map(function(pay) {
        let stringAppCode = '';
        let splitsAppCode = '';
        if (pay?.type == 'check') {
            splitsAppCode = (pay?.approvalCode ?? '').split(/(.{10})/).filter(O=>O).map((splitAppCode) => {
                return stringAppCode.concat(splitAppCode + "\n");
            }).join("").trim("");
        }
        return `<tr>
            <td>${pay.method.replace(" ", "__BLANK__")} ${!OFFLOAD.sqliteOffloadReceipt && pay?.paymentChannel ? ' ('+pay.paymentChannel+')' : ''}</td>
            <td>${(parseFloat(pay.amount)).toFixed(2)}</td>
        </tr>
        ${pay?.type == 'card' ? 
            `<tr>
                <td style="padding-left: 10px;">Number</td>
                <td>xxxx${pay?.cardNumber ?? ''}</td>
            </tr>
            <tr>
                <td style="padding-left: 10px;">App__BLANK__Code</td>
                <td>${pay?.approvalCode ?? ''}</td>
            </tr>` : ''
        }
        ${pay?.type == 'check' ? 
        `<tr>
            <td style="padding-left: 10px; padding-right: 10px;">Reference__BLANK__Number</td>
            <td>${splitsAppCode ?? ''}</td>
        </tr>` : ''
        }`;
    }) : '';

    const otherCharges = activeOrder.totals?.otherCharges ?? {};

    let itemsVat;
    if (USE_SM_MARKETS_OR_FORMAT) {
        itemsVat = {
            'Vat Exempt Sales': parseFloat(0),
            'Zero Rated Sales': parseFloat(0),
            ...otherCharges
        };
    } else {
        itemsVat = {
            'Vat Exempt Sales': parseFloat(0),
            'Zero Rated Sales': parseFloat(0),
            ...otherCharges
        };
    }

    let vatAdjustment = 0;
    let totalDiscount = 0;
    let hasDiplomat = false;

    const vatExemptDiscountTotal = receiptArgs.discountContainer
        .filter(d => ENABLE_NEW_VAT_EXEMPT_SALES && VAT_EXCL.includes(d?.discount_name))
        .reduce((discountTotal, item) => {
            return discountTotal + parseFloat(item.discountAmount);
        }, 0);

    let snrPwdAmount = 0;
    let discountString = (receiptArgs.discountContainer && receiptArgs.discountContainer.length != 0)
        ? `
        <table class="receipt-discounts">
            ${receiptArgs.discountContainer.map(discount => {
                totalDiscount = totalDiscount + discount.discountAmount;
                if(VAT_EXCL.includes(discount.discount_name) && discount?.discount_name != 'Diplomat Discount') {
                    snrPwdAmount += discount.discountAmount;
                    itemsVat['Vat Exempt Sales'] = ((activeOrder.totals.vatExemptSales ?? 0) + vatExemptDiscountTotal).toFixed(2);
                    
                    if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE && discount.max_discount_amount > 0) {
                        isCaramiaOverride = true;
                    }
                
                } else if(['Diplomat Discount'].includes(discount.discount_name)) {
                    itemsVat['Zero Rated Sales'] = activeOrder.totals.zeroRatedSales ?? 0;
                    hasDiplomat = true;
                }
            if (discount.has_customer_details && discount.idReferenceNumber) {
                let firstName = discount.firstName.toString() ?? '';
                let lastName = discount.lastName.toString() ?? '';
                let fullName = firstName.concat(" ", lastName);
                return `<tr>
                            <td>
                                ${discount.discount_name}:<br>
                            </td>
                            <td>
                                ${discount.discountAmount.toFixed(2)}<br>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Name: ${fullName}<br>
                                ID/Ref.#: ${discount.idReferenceNumber}<br>
                                Signature: ___________________<br>
                            </td>
                        </tr>`
            } else {
                 return `<tr>
                            <td>
                               ${discount.discount_name}:<br>
                            </td>
                            <td>
                                ${discount.discountAmount.toFixed(2)}<br>
                            </td>
                        </tr>`
            }
        }).join('')}</table>`
        : '';

    if (Number(vatConfig.tax_rate) == 1) {
        // always display vat exempt for non-vat config
        itemsVat['Vat Exempt Sales'] = activeOrder.totals.vatExemptSales;
    }

    let vatableRatio = 1;
    if (!isEmpty(receiptArgs.discountContainer) && some(receiptArgs.discountContainer, d => VAT_EXCL.includes(d?.discount_name))) {
        vatableRatio = (activeOrder.pax - size(receiptArgs.discountContainer)) / activeOrder.pax;
    }

    const adjustment = vatableRatio == 1 ? totalDiscount : 0;
    const isGrossBasedDiscount = receiptArgs.discountContainer.some(d => d.is_vat_based == 0);
    let vatableSales = '0.00';
    vatableSales = activeOrder.totals.net;

    if (activeOrder.totals.vatExemptSales > 0) {
        vatableSales -= activeOrder.totals.vatExemptSales;
    }
    if (activeOrder.totals.zeroRatedSales > 0) {
        vatableSales -= activeOrder.totals.zeroRatedSales;
    }
    vatableSales = vatableSales.toFixed(2);

    if (vatableRatio > 0) {
        if (isGrossBasedDiscount) {
            vatableSales = ((formattedTotals.rawPrice * vatableRatio - adjustment) / Number(vatConfig.tax_rate)).toFixed(2);
        } else {
            vatableSales = (formattedTotals.rawPrice / Number(vatConfig.tax_rate) * vatableRatio - adjustment).toFixed(2);
        }
    }

    if (isCaramiaOverride) {
        vatAdjustment = (formattedTotals.vatExemptSales * 0.12);
        vatableSales = ((formattedTotals.rawPrice / Number(vatConfig.tax_rate)) - parseFloat(itemsVat['Vat Exempt Sales'])).toFixed(2);
    }

    if (
        activeOrder.billDiscount &&
        activeOrder.billDiscount.discount.meal_combination_amount &&
        hasDiscountEligibilityKeywords(activeOrder.billDiscount.discount.discount_name)
    ) {
        vatableSales = formattedTotals.vatableSales
        itemsVat['Vat Exempt Sales'] = formattedTotals.vatExemptSales
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
        const receipt = await receiptBridge.getReceiptByLocalId(activeOrder._id);
        vatableSales = receipt?.vatable_sales ?? 0;
    }

    let itemsVatString = Object.keys(itemsVat).map(function (key, index) {
            return `<tr>
                        <td>${key.replace(" ", "__BLANK__")}</td>
                        <td>${itemsVat[key] == 0 ? '0.00' : parseFloat(itemsVat[key]).toFixed(2)}</td>
                    </tr>`
    }).join('');

    let customCss = `<link rel="stylesheet" type="text/css" href="${window.location.origin}/css/main.css">
                <link rel="stylesheet" type="text/css" href="${window.location.origin}/css/app.css">
                <link rel="stylesheet" type="text/css" href="${window.location.origin}/css/dragula.min.css">`;

    if (USE_SM_MARKETS_OR_FORMAT) {
        customCss += `
        <style type="text/css">
            .receipt-header, #receipt-footer {
                font-size: 0.8em;
            }
        </style>
        `;
    }

    let customerInfo = `
                    Buyer's Information: <br>
                    Customer Name: ${receiptArgs.customerDetails?.name ?? ''}<br>
                    Address: ${receiptArgs.customerDetails?.address ?? ''}<br>
                    Bus. Style: ${receiptArgs.customerDetails?.businessStyle ?? ''}<br>
                    TIN: ${receiptArgs.customerDetails?.tin ?? ''}<br>
                    ${LOCATION_PRINT_LAYOUT_OPTION == 'standard' ? `Manual SI No.: ${receiptArgs.customerDetails?.officialReceipt ?? ''}<br>` : ''}<br>`;

    let totalPayments = receiptArgs.payments.reduce(
                (acc,payment) => acc + parseFloat(payment.amount), 0);

    let identifierString = "";
    const BFC_OR_FORMAT = USE_BFC_OR_FORMAT && receiptArgs.mode == 'settlement';

    let timestamp = new Date().getTime();
    switch (receiptDetails.account_type_id) {
        case 4:
            timestamp = get(receiptArgs.dataContainer, 'qsr_' + activeOrder._id, timestamp);
            identifierString = !BFC_OR_FORMAT ? "QSR-" + receiptArgs.activeBrandId + "-" + timestamp : "";
            break;
        case 2:
            identifierString = (receiptArgs.dataContainer.hasOwnProperty('bm_del_' + activeOrder._id)) ? "Delivery-" + receiptArgs.activeBrandId + "-" + receiptArgs.dataContainer['bm_del_' + activeOrder._id] : receiptArgs.serviceType.replace("-", " ") + "-" + identifier;
            break;
        default:
            identifierString = receiptArgs.serviceType.replace("-", " ") + "-" + identifier;
    }

    if(activeOrder.isOnlineDelivery) {
        identifierString = 'Delivery: ' +  activeOrder.shortOrderId ?? activeOrder.channelName;
    }

    if (activeOrder?.isTabsquareOrder) {
        identifierString = `${activeOrder?.serviceType} TS - ${activeOrder?.tableName}`;
    }

    let qrNote = "";
    if(qrcode) {
        qrNote = `
        <tr>
            <td colspan="2" height="20"></td>
         </tr>
        <tr>
            <td style="text-align: center;">
                <p>
                    Scan using your phone camera
                    <br />
                    <span>Note: This will not work if scanned with an e-wallet application</span>
                </p>
            </td>
        </tr>
        `;
    }

    //get billing data
    //FOR FUTURE REFERENCE. DO NOT REMOVE AS OF THE MOMENT
    // let billing = receiptArgs.billings.find(bill => bill.order_id == activeOrder._id);

    let footerString = "";
    let receiptLabel = "";
    let changeDue = "";
    switch(receiptArgs.mode) {
        case "settlement":  
            receiptLabel = 'SALES INVOICE';
            changeDue = `<tr>
                            <td>Change__BLANK__Due</td>
                            <td>${(totalPayments - formattedTotals.total).toFixed(2)}</td>
                        </tr>
                        `;
            footerString = `<table class="receipt-tbl-footer" id="receipt-footer">
                <tr>
                    <td>
                        This Serves As Sales Invoice <br>
                        ${LOCATION_PRINT_LAYOUT_OPTION == 'standard' ? '<br>' : ''}
                        ${MOSAIC_COMPANY_NAME_AND_ADDRESS_FOR_PRINTOUT}
                        VAT REG TIN:009-642-270-00000 <br>

                        Accred. No.  ${receiptDetails.lp_accrednum ?? '0490096422702019081151'}<br>
                        Date Issued:  ${receiptDetails.lp_accred_date ?? '09/23/2019'} <br>
                        ${USE_SM_MARKETS_OR_FORMAT ? '' : 'Valid Until:  ' + (receiptDetails.lp_accred_valid_until ? moment(receiptDetails.lp_accred_valid_until, 'YYYY-MM-DD').format('MM/DD/YYYY') : '07/31/2025') + '<br>'}
                        PTU No.: ${isHappilee ? 'FP042022-033-0325886-00000' : receiptDetails.lp_ptunum ?? 'FP012021-049-0278210-00000'} <br>
                        Date Issued: ${receiptDetails.lp_ptu_date ? moment(receiptDetails.lp_ptu_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '04/19/2022'} <br>

                        ${LOCATION_PRINT_LAYOUT_OPTION == 'standard' ? '<br>------Check Closed------<br>' : ''}
                        <br>
                        
                        ${!window.is_print_via_app_available() && (IS_PUC_LOCATION || qrcode) ? "DOWNLOAD OUR APP TO PICKUP BEST VALUE OFFERS" : ""}
                    </td>
                </tr>
            </table>`;
            break;
        case "bill":  customerInfo = '';
                      receiptLabel = 'BILL';
                      footerString = `<table class="receipt-tbl-footer" id="receipt-footer">
                            <tr>
                                <td colspan="2" height="10"></td>
                            </tr>
                            <tr>
                                <td>
                                    THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX <br>
                                </td>
                            </tr>
                            <br>
                            <tr>
                                <td>
                                    ------Check Open------
                                </td>
                            </tr>
                            ${qrNote}
                        </table>`;
                     break;
         default: receiptLabel = '';
                  footerString = '';

    }

    //TODO: finalize logic once confirmed by PO
    // let priceNoVatNoSC = ((subTotal / 1.12) - formattedTotals.serviceCharge).toFixed(2);
    let priceNoVatNoSC = (parseFloat(formattedTotals.net) + parseFloat(totalDiscount)).toFixed(2);
    
    // Need to check if discount has customer details since we cannot hide customer info fields completely in OR for BIR compliance
    const discountHasCustomerDetails = receiptArgs.discountContainer.some(d => d.has_customer_details);
    if (discountString && discountHasCustomerDetails) {
        customerInfo = '';
    }

    let vatExemption = 0;
    if (
        activeOrder.billDiscount &&
        activeOrder.billDiscount.discount.meal_combination_amount &&
        hasDiscountEligibilityKeywords(activeOrder.billDiscount.discount.discount_name)
    ) {
        vatExemption = (Number(activeOrder.totals.vatExemptSales) * 0.12).toFixed(2);
    } else {
        vatExemption = Number(activeOrder.totals.rawPrice / Number(vatConfig.tax_rate) * (Number(vatConfig.tax_rate) - 1)).toFixed(2);
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
        const receipt = await receiptBridge.getReceiptByLocalId(activeOrder._id);
        vatExemption = receipt?.vat_adjustment ?? 0;
    }

    const vatExemptionHtml = USE_SM_MARKETS_OR_FORMAT && Number(itemsVat['Vat Exempt Sales'] > 0)
        ? `
        <tr>
            <td>VAT__BLANK__Exemption</td>
            <td>-${vatExemption}</td>
        </tr>
        `
        : '__BLANK__'


    let toReplace = {
        __CUSTOMCSS__: customCss,
        __ACCOUNTALIAS__: receiptDetails.account_alias?.toUpperCase() ?? '',
        __ACCOUNTNAME__: receiptDetails.account_name.toUpperCase(),
        __LOCATIONNAME__: receiptDetails.location.toUpperCase(),
        __LOCATIONADDRESS__: receiptDetails.location_address.toUpperCase(),
        __VATREGINFO__: vatRegInfo,
        __REPRINT__: get(receiptArgs, 'reprint_string', ''),
        __RECEIPTLABEL__: receiptLabel,
        __OR__: receiptArgs.mode == 'settlement' ? `SI#: ${receiptArgs.receipt_num.toString().padStart(20, '0')}<br>` : '',
        __BILL__: '',

        __BILLNUM__: LOCATION_PRINT_LAYOUT_OPTION == 'standard' 
            ? (USE_SM_MARKETS_OR_FORMAT || BFC_OR_FORMAT ? '' : `Bill#: ${activeOrder.bill_num.toString().padStart(20, '0')}<br>`)
            : '',

        __TRANSACTIONNUM__: LOCATION_PRINT_LAYOUT_OPTION == 'standard'
            ? (USE_SM_MARKETS_OR_FORMAT || BFC_OR_FORMAT ? '' : `Transaction#: ${transactionNum}<br>`)
            : '',

        __TERMINAL_NUMBER__: USE_SM_MARKETS_OR_FORMAT ? `Terminal#: ${JSON.parse(sessionStorage.getItem('terminal') || '{id: 1}').id}<br>` : '',
        
        __TABLEIDENTIFIER__: LOCATION_PRINT_LAYOUT_OPTION == 'standard'
            ? identifierString
            : '',

        __DATETIME__: dateTime,
        __CASHIERNAME__: receiptDetails.username,
        __PAXCOUNT__: activeOrder.pax,
        __MOREDETAILS__: (activeOrder.orderDetail) ? "Reference: " +  activeOrder.orderDetail : "",
        __CUSTOMERINFO__: USE_SM_MARKETS_OR_FORMAT ? '' : customerInfo,
        __SM_FORMAT_CUSTOMERINFO__: USE_SM_MARKETS_OR_FORMAT ? customerInfo : '',
        __TABLEITEMS__: itemString,
        __TOTALPRICE__: subTotal.toFixed(2),

        __PRICE_NOVAT_NOSC_HTML__: LOCATION_PRINT_LAYOUT_OPTION == 'standard'
            ? `<tr>
                    <td>Price__BLANK__w/o VAT&amp;SC</td>
                    <td>${priceNoVatNoSC}</td>
                </tr>`
            : '',

        __TTL_DISCOUNT_HTML__: totalDiscount != 0 ? `<tr>
                                    <td>Discount</td>
                                    <td>-${totalDiscount.toFixed(2)}</td>
                                </tr>` : '__BLANK__',
        __VAT_EXEMPTION_HTML__: vatExemptionHtml,
        __SERVICE_CHARGE_HTML__: !shouldHideServiceCharge ? `<tr>
                                    <td>Service__BLANK__Charge  </td>
                                    <td>${formattedTotals.serviceCharge}</td>
                                </tr>` : '__BLANK__',
        __VAT__: formattedTotals.vat,

        __VAT_ADJUSTMENT_HTML__: LOCATION_PRINT_LAYOUT_OPTION === 'standard' 
            ? `<tr>
                    <td>12%__BLANK__Vat</td>
                    <td>${vatAdjustment > 0 ? vatAdjustment.toFixed(2) : formattedTotals.vat}</td>
                </tr>` 
            : '',

        __AMOUNTDUE__: formattedTotals.total,
        __DISCOUNTBREAKDOWN__: discountString,
        __VATABLESALES__: vatableSales,
        __ITEMSVAT__: itemsVatString,
        __DYNAMICTAXES__: '',
        __TENDEREDPAYMENTS__: tenderedPayments,
        __CHANGEDUE__: changeDue,
        __FORFEITEDRETURNAMOUNT__: '',
        __FOOTER__: footerString,
        __COPYHEADER__: receiptArgs.copyHeader ?? ''
    };

    var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
    printTemplate = printTemplate.replace(RE, function(matched) {
        return toReplace[matched];
    });

    Logger.info(`preparing receipt template from: getReceiptPrintString`)

    Logger.debug(
        convert(printTemplate,
            {
                wordwrap: 130,
                preserveNewlines: true,
            }
    ));

    Logger.debug(formattedTotals);

    return printTemplate;
};

export const getVoidPrintString = async (activeOrder, receiptArgs) => {
    const vatConfig = activeOrder.orders[0].activeVat;
    let formattedTotals = receiptArgs.formattedTotals;
    let isCaramiaOverride = false;
    const receiptBridge = new ReceiptBridge();

    //mutate formattedTotals to remove comma on thousands value
    formattedTotals =  mapValues(formattedTotals, v => v !== null ? v.replace(/,/g, '') : v);

    if (OFFLOAD.sqliteOffloadReceipt && USE_NEW_AMOUNT_DUE_FORMULA) {
        const receipt = await receiptBridge.getReceiptByLocalId(appendNumberToInteger(activeOrder._id, 5));
        formattedTotals.total = parseFloat(receipt.gross_sales);
    }

    let receiptDetails = receiptArgs.receiptDetails;
    let printTemplate = voidSlipHtml;

    let today = new Date();
    let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    let time =
        ("0" + today.getHours()).substr(-2) + ":" + ("0" + today.getMinutes()).substr(-2) + ":" + ("0" + today.getSeconds()).substr(-2);
    let dateTime = date + " " + time;

    let identifier = receiptArgs.tableName ?? activeOrder.tableId;

    let transactionNum = activeOrder.kots.join(", ");

    let vatRegInfo = getVatRegInfo(receiptDetails);
    const shouldHideServiceCharge = formattedTotals.serviceCharge == 0;

    let subTotal = parseFloat(0);
    let totalItems = 0;
    const orderFilter = (o) => (o.isCancelled == false && receiptArgs.isReprint
        ? o.isVoided == true || (OFFLOAD.sqliteOffloadReceipt && activeOrder.isVoided)
        : o.isVoided != true);
    let itemString = activeOrder.orders.filter(o => (orderFilter(o))).map(function(item) {
        let item_name = item.product.product_name;
        let itemPrice = itemRawPrice(activeOrder, item);
        let itemAmount = itemPrice * item.quantity;
        let totalModPrice = 0;

        let forced_mod_name = !isEmpty(item.product.forcedMods)
            ? item.product.forcedMods.map(function (fmod) {
                totalModPrice += fmod.mod_price;
                return `-${fmod.quantity} ${fmod.modifier_name}`
             }).join(", ")
            : '';
        let unforced_mod_name = !isEmpty(item.product.unforcedMods)
            ? item.product.unforcedMods.map(function (ufmod) {
                totalModPrice += ufmod.mod_price;
                return `-${ufmod.quantity} ${ufmod.modifier_name}`
             }).join(", ")
            : '';

        const mod_name_prefix = (forced_mod_name || unforced_mod_name) ? " - " : '';
        let mod_name = mod_name_prefix + forced_mod_name + unforced_mod_name;
        let combined_length = item_name.length + mod_name.length;

        totalItems += item.quantity;
        subTotal += (itemAmount);
            return `<tr>
            <td height="30" colspan="4"></td>
            </tr>
                        ${combined_length > 9 ? `<tr>
                        <td colspan="4" valign="top" class="product-name">
                            ${item.product.product_name + '__MOD__' + mod_name + '__MOD__'}
                        </td>
                    </tr>
                    <tr>
                        <td>__BLANK__</td>`
                    : `<tr>
                        <td valign="top" class="product-name">
                            ${item.product.product_name + mod_name}
                        </td>`}
                        <td valign="top" class="strlen">-${item.quantity % 1 != 0 ? item.quantity.toFixed(2) : item.quantity}</td>
                        <td valign="top" class="strlen">
                            -${parseFloat(itemPrice).toFixed(2)}
                        </td>
                        <td valign="top" class="strlen">
                            -${parseFloat(itemAmount).toFixed(2)}<br>
                        </td>
                    </tr>`;
        }).join("");

    if(USE_SM_MARKETS_OR_FORMAT && receiptArgs.mode == 'void_paid') {
        itemString += `
            <tr>
                <td valign="top" class="product-name"> 
                    TOTAL QTY
                </td>
                <td>
                    ${totalItems}
                </td>
            </tr>
            <br>
        `;
    }
    
    let tenderedPayments = receiptArgs.mode == 'void_paid' ?
        `<table class="void-vats">
            <tr>
                <td colspan="2" style="text-align: center;">Settlement__BLANK__Details</td>
            </tr>
            <br>
            ${receiptArgs.payments.map(function(pay) {
                return `<tr>
                    <td>${pay.method.replace(" ", "__BLANK__")} ${!OFFLOAD.sqliteOffloadReceipt && pay?.paymentChannel ? ' ('+pay.paymentChannel+')' : ''}</td>
                    <td>-${(parseFloat(pay.exact_amount)).toFixed(2)}</td>
                </tr>`})}
        </table>` : '';

    const otherCharges = activeOrder.totals?.otherCharges ?? {};

    let itemsVat;
    if (USE_SM_MARKETS_OR_FORMAT) {
        itemsVat = {
            'Vat Exempt Sales': parseFloat(0),
            'Zero Rated Sales': parseFloat(0),
            ...otherCharges
        };
    } else {
        itemsVat = {
            'Vat Exempt Sales': parseFloat(0),
            'Zero Rated Sales': parseFloat(0),
            ...otherCharges
        };
    }

    let vatAdjustment = 0;
    let totalDiscount = 0;
    let hasDiplomat = false;
    const vatExemptDiscountTotal = receiptArgs.discountContainer
        .filter(d => ENABLE_NEW_VAT_EXEMPT_SALES && VAT_EXCL.includes(d?.discount_name))
        .reduce((discountTotal, item) => {
            return discountTotal + parseFloat(item.discountAmount);
        }, 0);

    let discountString = (receiptArgs.discountContainer && receiptArgs.discountContainer.length != 0)
        ? `
        <table id="void-receipt-discounts">
        <tr>
            <td colspan="2" style="text-align:center;">DISCOUNT BREAKDOWN</td><br>
        </tr>
        <tr>
            <td colspan="2" height="10"></td>
        </tr>
            ${receiptArgs.discountContainer.map(discount => {
                totalDiscount = totalDiscount + discount.discountAmount;
                if(VAT_EXCL.includes(discount.discount_name)) {
                    itemsVat['Vat Exempt Sales'] = ((activeOrder.totals.vatExemptSales ?? 0) + vatExemptDiscountTotal).toFixed(2);
                    
                    if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE && discount.max_discount_amount > 0) {
                        itemsVat['Vat Exempt Sales'] = activeOrder.totals.vatExemptSales;
                        isCaramiaOverride = true;
                    }

                } else if(['Diplomat Discount'].includes(discount.discount_name)) {
                    itemsVat['Zero Rated Sales'] = discount.netSales ? itemsVat['Zero Rated Sales'] + discount.netSales : (((parseFloat(formattedTotals.net) + discount.discountAmount) / activeOrder.pax) * discount.discountPax) - discount.discountAmount;
                    hasDiplomat = true;
                } else if (
                    activeOrder.billDiscount && 
                    activeOrder.billDiscount.discount.meal_combination_amount &&
                    hasDiscountEligibilityKeywords(activeOrder.billDiscount.discount.discount_name)
                ) {
                    itemsVat['Vat Exempt Sales'] = activeOrder.totals.vatExemptSales ?? 0
                }
            if (discount.has_customer_details && discount.idReferenceNumber) {
                let firstName = discount.firstName.toString() ?? '';
                let lastName = discount.lastName.toString() ?? '';
                let fullName = firstName.concat(" ", lastName);
                return `<tr>
                            <td>
                                ${discount.discount_name}:<br>
                            </td>
                            <td>
                                -${discount.discountAmount.toFixed(2)}<br>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Name: ${fullName}<br>
                                ID/Ref.#: ${discount.idReferenceNumber}<br>
                                Signature: ___________________<br>
                            </td>
                        </tr>`
            } else {
                return `<tr>
                            <td>
                            ${discount.discount_name}:<br>
                            </td>
                            <td>
                                -${discount.discountAmount.toFixed(2)}<br>
                            </td>
                        </tr>`
            }
        }).join(', ')}</table>`
        : '';

    if (Number(vatConfig.tax_rate) == 1) {
        // always display vat exempt for non-vat config
        itemsVat['Vat Exempt Sales'] = activeOrder.totals.vatExemptSales;
    }

    let vatableRatio = 1;
    if (!isEmpty(receiptArgs.discountContainer) && some(receiptArgs.discountContainer, d => VAT_EXCL.includes(d?.discount_name))) {
        vatableRatio = (activeOrder.pax - size(receiptArgs.discountContainer)) / activeOrder.pax;
    }

    const adjustment = vatableRatio == 1 ? totalDiscount : 0;
    const isGrossBasedDiscount = receiptArgs.discountContainer.some(d => d.is_vat_based == 0);
    let vatableSales = '0.00';
    if (vatableRatio > 0) {
        if (isGrossBasedDiscount) {
            vatableSales = ((formattedTotals.rawPrice * vatableRatio - adjustment) / Number(vatConfig.tax_rate)).toFixed(2);
        } else {
            vatableSales = (formattedTotals.rawPrice / Number(vatConfig.tax_rate) * vatableRatio - adjustment).toFixed(2);
        }
    }

    if (isCaramiaOverride) {
        vatAdjustment = (formattedTotals.vatExemptSales * 0.12);
        vatableSales = ((formattedTotals.rawPrice / Number(vatConfig.tax_rate)) - parseFloat(itemsVat['Vat Exempt Sales'])).toFixed(2);
    }

    if (
        activeOrder.billDiscount &&
        activeOrder.billDiscount.discount.meal_combination_amount &&
        hasDiscountEligibilityKeywords(activeOrder.billDiscount.discount.discount_name)
    ) {
        vatableSales = formattedTotals.vatableSales
        itemsVat['Vat Exempt Sales'] = formattedTotals.vatExemptSales
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
        const receipt = await receiptBridge.getReceiptByLocalId(appendNumberToInteger(activeOrder._id, 5));
        vatableSales = formatAmount(receipt?.vatable_sales ?? 0);
    }

    const promiseItemsVatString = await Promise.all(Object.keys(itemsVat).map(async (key) => {
        if (key === 'Zero Rated Sales' && OFFLOAD.sqliteOffloadReceipt) {
            const receipt = await receiptBridge.getReceiptByLocalId(appendNumberToInteger(activeOrder._id, 5));
            itemsVat[key] = receipt?.zero_rated_sales ?? 0;
        }

        const formattedValue = itemsVat[key] === 0 ? '0.00' : parseFloat(itemsVat[key]).toFixed(2);
        return `<tr>
                <td>${key.replace(" ", "__BLANK__")}</td>
                <td>-${formattedValue}</td>
            </tr>`;
    }));

    let itemsVatString = promiseItemsVatString.join('');

    let customCss = `<link rel="stylesheet" type="text/css" href="${window.location.origin}/css/main.css">
                <link rel="stylesheet" type="text/css" href="${window.location.origin}/css/app.css">
                <link rel="stylesheet" type="text/css" href="${window.location.origin}/css/dragula.min.css">`;

    let customerInfo = `
                    Buyer's Information: <br>
                    Customer Name: ${receiptArgs.customerDetails?.name ?? ''}<br>
                    Address: ${receiptArgs.customerDetails?.address ?? ''}<br>
                    Bus. Style: ${receiptArgs.customerDetails?.businessStyle ?? ''}<br>
                    TIN: ${receiptArgs.customerDetails?.tin ?? ''}<br>
                    Manual SI No.: ${receiptArgs.customerDetails?.officialReceipt ?? ''}<br><br>`;

    let totalPayments = receiptArgs.payments.reduce(
                (acc,payment) => acc + parseFloat(payment.amount), 0);

    let identifierString = "";

    let timestamp = new Date().getTime();
    switch (receiptDetails.account_type_id) {
        case 4:
            timestamp = get(receiptArgs.dataContainer, 'qsr_' + activeOrder._id, timestamp);
            identifierString = "QSR-" + receiptArgs.activeBrandId + "-" + timestamp;
            break;
        case 2:
            identifierString = (receiptArgs.dataContainer.hasOwnProperty('bm_del_' + activeOrder._id)) ? "Delivery-" + receiptArgs.activeBrandId + "-" + receiptArgs.dataContainer['bm_del_' + activeOrder._id] : receiptArgs.serviceType.replace("-", " ") + "-" + identifier;
            break;
        default:
            identifierString = receiptArgs.serviceType.replace("-", " ") + "-" + identifier;
    }

    if(activeOrder.isOnlineDelivery) {
        identifierString = 'Delivery: ' +  activeOrder.shortOrderId ?? activeOrder.channelName;
    }

    if (activeOrder?.isTabsquareOrder) {
        identifierString = `${activeOrder?.serviceType} TS - ${activeOrder?.tableName}`;
    }

    //get billing data
    //FOR FUTURE REFERENCE. DO NOT REMOVE AS OF THE MOMENT
    // let billing = receiptArgs.billings.find(bill => bill.order_id == activeOrder._id);

    let footerString = "";
    let checkStatusString = "";
    let changeDue = "";
    let receiptLabel = "";
    switch(receiptArgs.mode) {
        case "void_paid": checkStatusString = "Check Closed";
                        break;
        case "void_bill":  checkStatusString = "Check Open";
                     break;
         default: receiptLabel = '';
                  footerString = '';

    }

    //TODO: finalize logic once confirmed by PO
    // let priceNoVatNoSC = ((subTotal / 1.12) - formattedTotals.serviceCharge).toFixed(2);
    let priceNoVatNoSC = (parseFloat(formattedTotals.net) + parseFloat(totalDiscount)).toFixed(2);

    let vatExemption = 0;
    if (
        activeOrder.billDiscount &&
        activeOrder.billDiscount.discount.meal_combination_amount &&
        hasDiscountEligibilityKeywords(activeOrder.billDiscount.discount.discount_name)
    ) {
        vatExemption = activeOrder.vatExemptSales * 0.12;
    } else {
        vatExemption = Number(activeOrder.totals.rawPrice / Number(vatConfig.tax_rate) * (Number(vatConfig.tax_rate) - 1)).toFixed(2);
    }

    if (OFFLOAD.sqliteOffloadReceipt) {
        const receipt = await receiptBridge.getReceiptByLocalId(appendNumberToInteger(activeOrder._id, 5));
        vatExemption = formatAmount(receipt?.vat_adjustment ?? 0);
    }

    const vatExemptionHtml = USE_SM_MARKETS_OR_FORMAT && Number(itemsVat['Vat Exempt Sales'] > 0)
        ? `
        <tr>
            <td>VAT__BLANK__Exemption</td>
            <td>-${vatExemption}</td>
        </tr>
        `
        : '__BLANK__'

    let toReplace = {
        __CUSTOMCSS__: customCss,
        __ACCOUNTNAME__: receiptDetails.account_name.toUpperCase(),
        __LOCATIONNAME__: receiptDetails.location.toUpperCase(),
        __LOCATIONADDRESS__: receiptDetails.location_address.toUpperCase(),
        __VATREGINFO__: vatRegInfo,
        __REPRINT__: get(receiptArgs, 'reprint_string', ''),
        __VOIDREFNUM__: receiptArgs.void_refnum.toString().padStart(20, '0'),
        __OR__: receiptArgs.mode == 'void_paid' ? `OR Ref#: ${receiptArgs.receipt_num.toString().padStart(20, '0')}<br>` : '',
        __BILL__: '',
        __BILLNUM__: activeOrder.bill_num.toString().padStart(20, '0'),
        __TRANSACTIONNUM__: transactionNum,
        __TABLEIDENTIFIER__: identifierString,
        __DATETIME__: dateTime,
        __CASHIERNAME__: receiptDetails.username,
        __PAXCOUNT__: activeOrder.pax,
        __MOREDETAILS__: (activeOrder.orderDetail) ? "Reference: " +  activeOrder.orderDetail : "",
        __CUSTOMERINFO__: customerInfo,
        __TABLEITEMS__: itemString,
        __TOTALPRICE__: subTotal.toFixed(2),
        __PRICENOVATNOSC__: priceNoVatNoSC,
        __TTL_DISCOUNT_HTML__: totalDiscount != 0 ? `<tr>
                                    <td>Discount</td>
                                    <td>-${totalDiscount.toFixed(2)}</td>
                                </tr>` : '__BLANK__',
        __VAT_EXEMPTION_HTML__: vatExemptionHtml,
        __SERVICE_CHARGE_HTML__: !shouldHideServiceCharge ? `<tr>
                                <td>Service__BLANK__Charge</td>
                                <td>-${formattedTotals.serviceCharge}</td>
                            </tr>` : '__BLANK__',
        __VAT__: formattedTotals.vat,
        __VAT_ADJUSTMENT__: vatAdjustment > 0 ? vatAdjustment.toFixed(2) : formattedTotals.vat,
        __AMOUNTDUE__: formattedTotals.total,
        __DISCOUNTBREAKDOWN__: discountString,
        __VATABLESALES__: vatableSales,
        __ITEMSVAT__: itemsVatString,
        __TENDEREDPAYMENTS__: tenderedPayments,
        __FORFEITEDRETURNAMOUNT__: '',
        __CHECK_OPEN_CLOSED__: checkStatusString,
    };

    var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
    printTemplate = printTemplate.replace(RE, function(matched) {
        return toReplace[matched];
    });

    Logger.info(`preparing receipt template from: getVoidPrintString`)

    Logger.debug(
        convert(printTemplate,
            {
                wordwrap: 130,
                preserveNewlines: true,
            }
    ));

    Logger.debug(formattedTotals);

    return printTemplate;
};

export const getQrPaymentPrintString = async () => {
    let printTemplate = qrPaymentHtml;

    let note = `<table class="receipt-tbl-footer" id="receipt-note">     
                            <tr>
                                    <td colspan="2" height="20"></td>
                                </tr>
                                <tr>
                                    <td style="text-align: center;">
                                        <p>
                                            Scan using your phone camera
                                            <br />
                                            <span>Note: This will not work if scanned with an ewallet application</span>
                                        </p>
                                    </td>
                                </tr>
                        </table>`;

    let toReplace = {
      __NOTE__: note,
    };

    var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
    printTemplate = printTemplate.replace(RE, function (matched) {
      return toReplace[matched];
    });
    
    Logger.info(`preparing receipt template from: getQrPaymentPrintString print.js file`)

    Logger.debug(
      convert(printTemplate, {
        wordwrap: 130,
        preserveNewlines: true,
      })
    );

    return printTemplate;
  };


export default {
    getReceiptPrintString,
    getVoidPrintString,
    generateReceiptItemString,
}
