<template>
    <div v-if="!hidden" class="m-line-item d-flex flex-wrap justify-content-between align-items-stretch" :class="{
        readonly,
        edit,
        voided: item.isVoided,
        open: isCollapsibleOpen
    }" @click="isCollapsibleOpen = !isCollapsibleOpen && (!locked || isDstoreOrder)">
        <div class="quantity" :class="isAdvancedTotals ? 'col-1' : 'col-2'">
            <div><b>{{ this.$filters.formatPrice(item.quantity) }}</b></div>

            <template v-if="item.product.is_bundle && enableParentChildClassification">
                <div class="mod" v-for="product in item.product.bundled_products">
                    {{ product.quantity }}
                </div>
            </template>

            <template v-if="item.product.is_customize && enableParentChildClassification">
                <template v-for="customizedProduct in item.product.customizedProductSummary">
                    <template v-for="choice in customizedProduct.choices">
                        <div class="mod" v-if="choice.selectedCount">
                            {{ choice.selectedCount }}
                        </div>
                    </template>
                </template>
            </template>

            <template v-if="item.product.forcedMods">
                <div class="mod" v-for="mod in item.product.forcedMods">
                    {{ mod.quantity }}
                </div>
            </template>

            <template v-if="item.product.unforcedMods">
                <div class="mod" v-for="mod in item.product.unforcedMods">
                    {{ mod.quantity }}
                </div>
            </template>

            <template v-if="item.discount">
                <div class="mod">
                    {{ this.$filters.formatPrice(item.discount.discountValue) }}%
                </div>
            </template>

            <template v-if="item.comp">
                <div class="mod">
                    {{ this.$filters.formatPrice(item.comp.compRatePercentage) }}%
                </div>
            </template>
        </div>

        <div class="name" :class="isAdvancedTotals ? 'col-6' : 'col-7'">
            <div class="product-name">
                <span v-if="movable && isDragHandleVisible" class="drag-handle move mr-2">
                    <i class="fa fa-grip-lines" />
                </span>
                <b>{{ item.product.product_name }}</b>
                <span class="ml-2" v-if="item.isVoided">(Voided)</span>
            </div>

            <template v-if="item.product.is_bundle && enableParentChildClassification">
                <div class="mod" v-for="product in item.product.bundled_products">
                    {{ product.product_detail.product_name }}
                </div>
            </template>

            <template v-if="item.product.is_customize && enableParentChildClassification">
                <template v-for="customizedProduct in item.product.customizedProductSummary">
                    <template v-for="choice in customizedProduct.choices">
                        <div class="mod" v-if="choice.selectedCount">
                            {{ choice.name }}
                        </div>
                    </template>
                </template>
            </template>

            <template v-if="item.product.forcedMods">
                <div class="mod" v-for="mod in item.product.forcedMods">
                    {{ mod.mod_group_name }}: {{ mod.modifier_name }}
                </div>
            </template>

            <template v-if="item.product.unforcedMods">
                <div class="mod" v-for="mod in item.product.unforcedMods">
                    {{ mod.modifier_name }}
                </div>
            </template>

            <template v-if="item.discount">
                <div class="mod">
                    {{ item.discount.discount_name }}
                </div>
            </template>

            <template v-if="item.comp">
                <div class="mod">
                    {{ item.comp.compReason }}
                </div>
            </template>
        </div>

        <div v-if="isAdvancedTotals" class="col-1 vat">{{ formattedAmounts.vat }}</div>
        <div v-if="isAdvancedTotals" class="col-1 net">{{ formattedAmounts.net }}</div>
        <div v-if="isAdvancedTotals" class="col-1 service-charge">{{ formattedAmounts.serviceCharge }}</div>

        <div class="price" :class="isAdvancedTotals ? 'col-2' : 'col-3'">
            <div><b>{{ formattedAmounts.rawPrice }}</b></div>

            <template v-if="item.product.is_bundle && enableParentChildClassification">
                <div class="mod" v-for="product in item.product.bundled_products">
                    <span v-if="product.is_free == 1">FREE</span>
                    <span v-else>-</span>
                </div>
            </template>

            <template v-if="item.product.is_customize && enableParentChildClassification">
                <template v-for="customizedProduct in item.product.customizedProductSummary">
                    <template v-for="choice in customizedProduct.choices">
                        <div class="mod" v-if="choice.selectedCount">-</div>
                    </template>
                </template>
            </template>

            <template v-if="item.product.forcedMods">
                <div :class="item.product.isSecondItem && item.product.promo_type != 'BOGO' ? 'line-through mod' : 'mod'"
                    v-for="mod in item.product.forcedMods">
                    {{ item.product.isSecondItem && item.product.promo_type == 'BOGO' ? 0 : mod.mod_price * mod.quantity }}
                </div>
            </template>

            <template v-if="item.product.unforcedMods">
                <div :class="item.product.isSecondItem ? 'line-through mod' : 'mod'"
                    v-for="mod in item.product.unforcedMods">
                    {{ mod.mod_price * mod.quantity }}
                </div>
            </template>

            <template v-if="item.discount">
                <div class="mod">
                    - {{ this.$filters.formatPrice(item.discount.discountAmount) }}
                </div>
            </template>

            <template v-if="item.comp">
                <div class="mod">
                    {{ this.$filters.formatPrice(item.comp.compAmount) }}
                </div>
            </template>
        </div>

        <div v-if="mergedToTableName" class="col-12">
            <div class="merged-to">
                <b>Merged to:</b> {{ mergedToTableName }}
            </div>
        </div>

        <div class="collapsible-area col-12 d-flex  align-items-center" :class="{ collapsed: !isCollapsibleOpen },
            readonly ? 'justify-content-end' : 'justify-content-between'">
            <template v-if="!readonly">
                <div class="quantity d-flex justify-content-between align-items-center" v-if="!item.product.isSecondItem">
                    <i class="fas fa-minus quantity-control cursor-pointer" @click.stop="$emit('subtractQuantity')"></i>

                    <span class="order-quantity mx-3">{{ item.quantity }}</span>

                    <i class="fas fa-plus quantity-control cursor-pointer" @click.stop="$emit('addQuantity')"></i>

                </div>

                <div class="d-flex align-items-center">
                    <m-icon-button :post-click-delay="0" label="Remove" icon="trash" color="danger"
                        @click="$emit('removeLineItem')" class="mr-2" v-if="!item.product.isSecondItem" />

                    <m-icon-button :post-click-delay="0" label="Request" icon="clipboard" color="primary"
                        @click="$emit('addRequest', this.parsedAmounts)" class="mr-2" />

                    <m-icon-button v-if="isServiceBtnVisible" :post-click-delay="0" :label="inverseServiceType"
                        icon="clipboard" color="primary" @click="$emit('changeService', inverseServiceType)" />
                </div>
            </template>
            <div v-if="readonly || isDstoreOrder" class="d-flex align-items-center">
                <m-icon-button :post-click-delay="0" label="Actions" icon="ellipsis-h" color="success"
                    @click="$emit('showItemAction', this.parsedAmounts)" class="mr-2" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { LineItemMixin } from '@/spa/components/mixins/ComputationMixin';
import MIconButton from '@/spa/components/common/MIconButton';
import { ENABLE_DINE_IN_TAKE_OUT_BUTTON, ENABLE_LINE_ITEM_REORDERING, ENABLE_PARENT_CHILD_CLASSIFICATION } from "@/spa/constants";

export default {
    name: 'MLineItem',

    mixins: [
        LineItemMixin,
    ],

    components: { MIconButton },

    props: {
        serviceType: {
            type: String,
            default: 'Dine-in',
        },

        readonly: {
            type: Boolean,
            default: false,
        },

        isAdvancedTotals: {
            type: Boolean,
            default: false,
        },

        edit: {
            type: Boolean,
            default: false,
        },

        mergedToTableName: {
            type: String,
            default: '',
        },

        hidden: {
            type: Boolean,
            default: false,
        },

        locked: {
            type: Boolean,
            default: false,
        },

        movable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isCollapsibleOpen: false,
        };
    },

    computed: {
        ...mapState([
            'activeServiceTypeId',
            'activeChannelId',
        ]),

        ...mapState('settings', ['serviceTypes']),

        inverseServiceType() {
            return this.serviceType.includes('Dine-in') ? 'Take-out' : 'Dine-in'
        },

        isDragHandleVisible() {
            return ENABLE_LINE_ITEM_REORDERING;
        },

        isServiceBtnVisible() {
            const hasTakeOutAndDineIn = ['Dine-in', 'Take-out'].every(service => this.serviceTypes.map(item => item.service_name).includes(service));
            return ENABLE_DINE_IN_TAKE_OUT_BUTTON && hasTakeOutAndDineIn && this.serviceType.match(/Dine-in|Take-out/)
        },

        enableParentChildClassification() {
            return ENABLE_PARENT_CHILD_CLASSIFICATION;
        },

        isDstoreOrder() {
            return this.activeOrder.channelName === 'Alipay DStore';
        }
    },
};
</script>

<style scoped>
.m-line-item {
    padding-top: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
        0 2px 2px rgba(0, 0, 0, 0.01),
        0 4px 4px rgba(0, 0, 0, 0.01),
        0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.01);
    margin: 4px 0;
    cursor: pointer;
}

.m-line-item.readonly {
    cursor: initial;
}

.m-line-item.voided .product-name {
    text-decoration: line-through;
    font-family: 'Exo', sans-serif;
    color: var(--danger);
}

.m-line-item.readonly.edit {
    opacity: 0.5;
}

.m-line-item:not(.readonly):hover {
    transform: scale(1.01);
}

.m-line-item .mod {
    margin-left: 4px;
    font-style: italic;
    font-size: 0.6rem;
    font-weight: 600;
}

.m-line-item>.vat,
.m-line-item>.service-charge,
.m-line-item>.net {
    font-size: 0.7rem;
    font-weight: bold;
    padding-top: 4px;
    color: rgba(0, 0, 0, 0.3);
}

.m-linte-item>.price {
    font-size: 1.1rem;
    font-weight: 800;
}

.m-line-item:nth-child(2n) {
    background-color: rgba(255, 165, 0, 0.06);
}

.collapsible-area .quantity {
    padding: 10px;
    background: #3782AF;
    border-radius: 10px;
}

.m-line-item .quantity-control {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    display: grid;
    place-content: center;
    background-color: #37C6F4;
    color: white;
}

.collapsible-area {
    background-color: rgba(70, 208, 255, 0.15);
    padding: 15px !important;
    border-radius: 0 0 8px 8px;
    transition-property: height, opacity;
    transition-duration: 0.3s;
    height: 90px;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    border-top: 1px solid rgba(255, 255, 255, .3);
    margin-top: 15px;
}

.collapsible-area.collapsed {
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0 !important;
}

.m-line-item.open,
.m-line-item.open:hover {
    background: #53A6D8 !important;
    color: white !important;
}

.order-quantity {
    font-size: 20px;
    font-weight: 700;
}

.move {
    cursor: move;
}

.line-through {
    text-decoration: line-through;
}
</style>
