<template>
  <svg width="80" height="80" viewBox="0 0 71 35" fill="none">
    <path
      d="M0.885794 1.36806C0.307048 2.09149 0.524078 4.84054 1.60923 11.6408L3.05609 20.8284L1.53688 27.484C0.379391 32.4757 0.234704 34.1396 0.885794 34.5736C2.18797 35.3694 2.76672 34.7183 3.63484 31.6076L4.43062 28.7138H11.1585H17.8865L18.3929 31.5352C18.8993 34.646 19.478 35.4418 20.8526 34.5736C22.01 33.8502 20.5632 20.6837 19.261 19.8879C18.8269 19.5986 15.6438 19.3092 12.2437 19.3092H6.09451L4.50296 10.4833C2.91141 1.36806 2.26032 -0.223495 0.885794 1.36806Z"
      fill="#1B75BB" />
    <path
      d="M68.3821 1.22343C68.0204 1.80218 65.1267 17.2837 65.1267 18.7305C65.1267 19.0922 62.2329 19.3093 58.6881 19.3093C54.4198 19.3093 52.0325 19.5986 51.5261 20.1774C51.1644 20.6838 50.441 24.0116 50.0069 27.5564C49.4282 32.6204 49.4282 34.212 50.1516 34.646C51.3091 35.3695 52.8283 33.2715 52.8283 30.7395V28.7139H59.7009H66.5012L67.297 31.6076C68.1651 34.7907 68.7438 35.3695 70.1183 34.5737C70.7694 34.1396 70.6247 32.6204 69.3949 27.4117L67.8757 20.8285L69.3949 11.8579C70.8418 3.32139 70.8418 0.5 69.4673 0.5C69.1055 0.5 68.5991 0.789375 68.3821 1.22343Z"
      fill="#1B75BB" />
    <path
      d="M16.0779 7.87901C15.4992 8.45776 15.4268 8.96416 16.0056 9.6876C16.5843 10.3387 19.1887 10.6281 25.4102 10.6281H34.0191V20.0327V29.365L31.7041 30.5948C27.9422 32.4757 27.3635 33.1268 28.3039 34.212C28.955 35.0078 29.6785 34.8631 32.2828 33.5609L35.4659 32.0417L38.649 33.5609C41.2534 34.8631 41.9768 35.0078 42.6279 34.212C43.5684 33.1268 42.9896 32.4757 39.3001 30.5948L36.9128 29.365V20.0327V10.6281H45.4493C54.2752 10.6281 56.5902 9.97697 54.9263 7.95136C53.8411 6.64918 17.3801 6.57683 16.0779 7.87901Z"
      fill="#1B75BB" />
  </svg>

</template>