<template>
  <base-modal
    v-bind="$attrs"
    :click-to-close="false"
    confirm-label="Save Printers"
    min-width="80vw"
    min-height="80vh"
    @confirm="save"
    >
    <template #title>Discovered Printers</template>

    <ul class="list-group">
      <li class="list-group-item p-5 text-center" v-if="printers.length == 0">
        <strong>No printers found</strong>
      </li>
      <DiscoveredPrinterListItem v-for="printer in printers"
        :printer="printer"
        :productCategories="productCategories"
        @testPrint="testPrint"
        @remove="onRemove" />
    </ul>

    <div class="card mt-2">
      <div class="card-body">
        <div class="form-group">
          <label>Manually Add by IP Address</label>
          <div class="d-flex">
            <input type="text" class="form-control flex-fill" placeholder="Enter IP Address" v-model="ipAddress" />
            <button class="btn btn-secondary ml-2" @click="onManualAdd()">
              Add Printer
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>
<script>
import {uniqBy, map, orderBy} from 'lodash';
import BaseModal from '@/spa/components/modals/BaseModal';
import {test_print} from '@/spa/services/printer-service';
import {PRINTER_MODELS} from '@/local_printer_config_constants';
import DiscoveredPrinterListItem from '@/spa/components/common/DiscoveredPrinterListItem';

export default {
  components: {
    BaseModal,
    DiscoveredPrinterListItem
  },
  created() {
    db.product_detail.toArray().then(products => {
      const categories = [
        ...orderBy(
          uniqBy(
            map(products, p => ({
              id: p.product_group.product_category_id,
              name: p.category,
            })),
            'id'
          ),
          'name'
        ),
      ];

      this.productCategories = categories;
    });
  },
  data() {
    return {
      PRINTER_MODELS,
      productCategories: [],
      ipAddress: ''
    };
  },
  emits: ['save', 'manualAdd', 'remove'],
  methods: {
    testPrint(printer) {
      test_print(printer);
    },
    save() {
      this.printers.forEach(p => {
        p.printer_model = PRINTER_MODELS.find(pm => pm.id == p.printer_model_id);
      });
      this.$emit('save', this.printers);
    },
    onManualAdd() {
      this.$emit('manualAdd', this.ipAddress);
      this.ipAddress = '';
    },
    onRemove(printer) {
      this.$emit('remove', printer);
    },
  },
  props: {
    printers: Array
  }
}
</script>
