<template>
    <tr>
        <td class="cell100 column1 text-dark py-2 x-read-cash-denom"> {{denomination}}</td>
        <td class="cell100 column2 text-dark" align="center">
        <input :disabled="disabled" name="cash-input" class="x-read-cash-input" type="number" min="1" max="900" @keypress="inputValidation" style="width: 50px;" v-model="noOfBills">
        </td>
        <td class="cell100 column3 x-read-cash-ttl" align="center">{{this.$filters.formatPrice(totalAmount)}}</td>
    </tr>
</template>

<script>
export default {
    name: 'DenominationItem',

    props: {
        denomination: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            total: 1,
            noOfBills: 0,
            totalAmount: 0,
        }
    },

    watch: {

        noOfBills(value) {

            if(value > 900) {
                this.noOfBills = 0;
            }

            this.totalAmount = this.denomination * value;

            const cashBreakDown = {
                denomination: this.denomination,
                totalAmount: this.totalAmount
            }

            this.$emit('applyCashBreakdown', cashBreakDown);
        },
        disabled(value) {
            if (value) {
                this.noOfBills = 0;
            }
        }
    },

    methods: {
        inputValidation(e) {
            let keyCode = e.keyCode || e.which;
            if (keyCode < 48 || keyCode > 57 || keyCode == 46) { // 46 is dot
                e.preventDefault();
            }
        }
    },
};
</script>