const filters = {
  formatPrice(value) {
    if (isNaN(value)) {
      return "-";
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      currencyDisplay: "code"
    })
      .format(value)
      .replace('PHP', '')
      .trim();
  },

  inputNumbersOnly(evt) {
    evt.target.value = evt.target.value.replace(/[^0-9]$/g,'');
    return false;
  },

  inputNonSpecialCharacters(evt) {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9]$/g,'');
    return false;
  },
}
export default filters;