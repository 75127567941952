<template>
    <base-modal
        v-bind="$attrs"
        :confirm-label="actionLabel"
        @cancel="reset"
        @beforeClose="reset"
        @beforeOpen="updateTableStates"
        @confirm="confirmTableSelection"
        @contentScroll="redrawLines"
        >
        <template v-slot:title>Manage Tables</template>

        <hr />

        <div class="btn-group mb-4" role="group">
            <button
                v-for="(op, index) in tableOperations"
                :key="`table-op-${index}`"
                type="button"
                class="btn btn-lg"
                :class="{
                    'btn-primary': activeTableOperation === op,
                    'btn-light': activeTableOperation !== op,
                }"
                :disabled="hasMergedTables"
                @click="activeTableOperation = op"
            >
                {{ op }}
            </button>
        </div>

        <hr v-if="hasMultipleServiceAreas" />

        <div v-if="hasMultipleServiceAreas" class="btn-group mb-4" role="group">
            <button
                v-for="(area, index) in tableData"
                :key="`area-${area.id}`"
                type="button"
                class="btn btn-lg"
                :class="{
                    'btn-primary': index === activeAreaIndex,
                    'btn-light': index !== activeAreaIndex,
                }"
                @click="setActiveAreaIndex(index)"
            >
                {{ area.area_name }}
            </button>
        </div>

        <m-arranged-tables
            v-if="tableData.length"
            container-width="100%"
            class="move-target-selection"
            :tables="activeArea.tables"
            :occupied-tables="occupiedTables"
            :active-table-id="activeTableId"
            :active-table-ids="activeTableIds"
            :disabled-table-ids="[sourceTableId, ...billedTableIds, ...mergedTableIds]"
            :disableUnoccupiedTables="activeTableOperation === 'merge'"
            :multi-select="activeTableOperation === 'merge'"
            background-color="transparent"
            @tableSelected="selectTable($event)"
            />
    </base-modal>
</template>

<script>
import { uniq, map, debounce, compact, forEach, isEmpty, find, isEqual, pick } from 'lodash';
import cloneDeep from 'rfdc/default';
import { getTableLayout } from '@/spa/services/table-service';
import { mapState, mapGetters, mapMutations } from 'vuex';

import LeaderLine from 'leader-line-new';

import {OFFLOAD, PRODUCT_SKU_FIELDS} from '@/spa/constants';

import BaseModal from '@/spa/components/modals/BaseModal';
import MArrangedTables from '@/spa/components/common/MArrangedTables';

export default {
    name: 'TableActionsModal',

    components: {
        BaseModal,
        MArrangedTables,
    },

    props: {
        sourceTableId: {
            type: [Number, String],
            required: true,
        },
    },

    data() {
        return {
            tableData: [],
            activeAreaIndex: 0,
            occupiedTables: {},
            billedTableIds: [],
            activeTableId: null,
            activeTableIds: [],
            tableOperations: ['move', 'merge'],
            activeTableOperation: 'move',
            lines: {},
            debouncedRedrawLines: () => null,
        };
    },

    computed: {
        ...mapState([
            'orders',
            'activeOrderId',
        ]),

        ...mapGetters([
            'activeOrder',
            'pendingOrders',
            'hasPendingSettlements',
        ]),

        activeArea() {
            return this.tableData.length > 0
                ? this.tableData[this.activeAreaIndex]
                : { tables: [] };
        },

        hasMergedTables() {
            return !isEmpty(this.activeOrder.tableMergedWith);
        },

        mergedTableIds() {
            const merged = [];
            this.pendingOrders.forEach(order => {
                if (!order.tableId) return;
                if (order._id === this.activeOrderId) return;
                if (order.tableMergedWith) merged.push(...order.tableMergedWith);
                if (this.activeTableOperation === 'move') return;
                if (order.tableMergedTo) merged.push(order.tableMergedTo)
            });

            return uniq(merged);
        },

        hasMultipleServiceAreas() {
            return this.tableData.length > 1;
        },

        actionLabel() {
            return this.activeTableOperation === 'move'
                ? 'Move'
                : 'Merge';
        },
    },

    async mounted() {
        this.debouncedRedrawLines = debounce(() => this.redrawLines(), 100);
        this.updateTableStates();
    },

    watch: {
        sourceTableId() {
            this.debouncedRedrawLines();
        },
        activeTableId() {
            this.debouncedRedrawLines();
        },
        activeTableIds: {
            handler() {
                this.debouncedRedrawLines();
            },
            deep: true,
        },
        activeTableOperation() {
            this.debouncedRedrawLines();
        },
    },

    methods: {
        ...mapMutations([
            'setOrders',
            'setActiveOrderId',
            'setActiveBrandId',
            'setActiveServiceTypeId',
            'setActiveChannelId',
        ]),

        clearLines() {
            forEach(this.lines, line => line.remove());
            this.lines = {};
        },

        partialClearLines(sources, target) {
            forEach(this.lines, (line, id) => {
                const [sourceId, targetId] = id.split('-');
                if (sources.includes(sourceId) && target === targetId) return;
                line.remove();
                delete this.lines[id];
            });
        },

        redrawLines() {
            const sources = this.activeTableOperation === 'merge'
                ? this.activeTableIds
                : compact([this.sourceTableId]);

            const target = this.activeTableOperation === 'merge'
                ? this.sourceTableId
                : this.activeTableId;

            this.partialClearLines(sources, target);
            this.$nextTick(() => sources.forEach(sourceId => {
                if (`${sourceId}-${target}` in this.lines) return;
                const line = this.addLine(`.move-target-selection .m-table-${sourceId}`, `.move-target-selection .m-table-${target}`);
                if (line) {
                    this.lines[`${sourceId}-${target}`] = line;
                }
            }));
        },

        addLine(sourceQuery, targetQuery) {
            if (sourceQuery === targetQuery) return;
            const source = document.querySelector(sourceQuery);
            const target = document.querySelector(targetQuery);
            if (!source || !target) {
                return;
            }

            return new LeaderLine(source, target, {
                dash: { animation: true },
                color: 'var(--cyan)',
                size: 8,
                path: this.activeTableOperation === 'merge' ? 'straight' : 'magnet',
            });
        },

        async fetchTables(serviceAreaId = undefined) {
            try {
                const response = await getTableLayout(serviceAreaId);
                this.tableData = map(response.data.table_details.service_areas, a => ({
                    ...a,
                    tables: map(a.tables, t => ({
                        ...t,
                        table_position: JSON.parse(t.table_position),
                    })),
                }));
            } catch (e) {
                console.error(e);
            }
        },

        setActiveAreaIndex(areaId) {
            this.activeAreaIndex = areaId;
        },

        areProductSkusEqual(product1, product2) {
            return isEqual(pick(product1, PRODUCT_SKU_FIELDS), pick(product2, PRODUCT_SKU_FIELDS));
        },

        selectTable(tableId) {
            const tableOrder = find(this.pendingOrders, { tableId });
            if (tableOrder?.billDiscount) {
                this.$swal.warning('Please select a table with no active bill discount');
                return;
            }

            const commonProducts = tableOrder?.orders.filter(o => this.activeOrder.orders.some(ao => this.areProductSkusEqual(o, ao)));
            if (commonProducts?.some(p => p.discount)) {
                this.$swal.warning('Target table has active discounts on common products');
                return;
            }

            if (this.activeTableOperation === 'merge') {
                if (this.activeTableIds.includes(tableId)) {
                    this.activeTableIds = this.activeTableIds.filter(id => id !== tableId);
                } else {
                    this.activeTableIds.push(tableId);
                }
            } else {
                this.activeTableId = this.activeTableId === tableId ? null : tableId;
            }
        },

        async updateTableStates() {
            const tdb = new ServiceTableDetailBridge();

            const serviceAreaId = this.$route.params.serviceAreaId;
            await this.fetchTables(serviceAreaId);

            const tablesWithOrders = OFFLOAD.sqliteOffloadTableDetail
                ? await tdb.getRows({ select: ['table_id'], whereNotNull: ['receipt_local_id'] })
                : uniq(map(this.pendingOrders, 'tableId'));

            this.occupiedTables = OFFLOAD.sqliteOffloadTableDetail
                ? Object.assign({}, ...tablesWithOrders.map(item => ({ [item.table_id]: true })))
                : tablesWithOrders.reduce((acc, tableId) => {
                    acc[tableId] = true;
                    return acc;
                }, {});

            this.billedTableIds = [];
            this.pendingOrders.forEach(po => {
                if (!po.isBilled) return;
                this.billedTableIds = [...this.billedTableIds, po.tableId];
            });

            if (this.hasMergedTables) {
                this.activeTableOperation = 'merge';
                this.activeTableIds = cloneDeep(this.activeOrder.tableMergedWith);
                this.$nextTick(() => this.debouncedRedrawLines());
            } else {
                this.reset();
            }
        },

        reset() {
            this.activeTableId = '';
            this.activeTableIds = [];
            this.mode = 'move';
            this.clearLines();
        },

        confirmTableSelection() {
            this.$emit('tableSelected', {
                tableId: this.activeTableId,
                table: this.activeArea?.tables.find(t => t.table_id == this.activeTableId),
                mode: this.activeTableOperation,
                tableIds: this.activeTableIds,
                tables: this.activeArea?.tables.filter(t => this.activeTableIds.includes(t.table_id)),
            });

            this.$nextTick(() => this.reset());
        },
    },
};
</script>

<style scoped>
.btn-group .btn {
    text-transform: capitalize;
}
</style>