import { openDB } from 'idb'
import { DEXIE_TABLES } from "@/spa/constants";

class DbService {
    constructor() {
        this.db = null
    }

    async openDB(name, version) {
        this.db = await openDB(name, version)
    }

    async closeDB() {
        if (this.db) {
            this.db.close()
            this.db = null
        }
    }

    /**
     * @param storeName
     * @param conditions
     *       [
     *          ['field1', '=', 'value1'],
     *          ['field2', '=', 'value2'],
     *          // ...
     *       ]
     * @returns {Promise<*>}
     */
    async fetchItems(storeName, conditions = []) {
        try {
            const tx = this.db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);

            let result = await store.getAll();

            conditions.forEach(([fieldName, operator, fieldValue]) => {
                result = result.filter(item => {
                    const value = item[fieldName];
                    switch (operator) {
                        case '=':
                            return value === fieldValue;
                        case '!=':
                            return value !== fieldValue;
                        case '<':
                            return value < fieldValue;
                        case '>':
                            return value > fieldValue;
                        case '<=':
                            return value <= fieldValue;
                        case '>=':
                            return value >= fieldValue;
                    }
                });
            });

            return result;
        } catch (error) {
            console.log('fetchItems error: ', error)
        }
    }

    async fetchItem(storeName, condition) {
        const tx = this.db.transaction(storeName, 'readonly')
        const store = tx.objectStore(storeName)
        const items = await store.getAll();
        return items.find(condition)
    }

    async fetchPermission(permission, table = DEXIE_TABLES.USER_TYPE_PERMISSIONS, userTypeId = null) {
        try {
            const permissionData = await this.fetchItem(DEXIE_TABLES.PERMISSIONS, (item) => item && item.permission_name === permission);
            if (!permissionData) {
                return false;
            }

            const permissions = (table !== DEXIE_TABLES.LOCATIONS ?
                await this.fetchItems(table,[['user_type_id', '=', userTypeId]]) :
                await this.fetchItems(table)) || [];

            return permissions.some(data => {
                const ids = typeof data.permission_ids === 'string' ? JSON.parse(data.permission_ids) : (data.permission_ids || []);
                return ids.some(value => value == permissionData.id);
            });
        } catch (error) {
            console.log('fetchPermission error: ', error)
        }
    }

    async fetchColumnValue(storeName, key, column) {
        if (!key) return false
        const tx = this.db.transaction(storeName, 'readonly')
        const store = tx.objectStore(storeName)
        const item = await store.get(key)
        return item && item[column]
    }

    async saveIdbItem(storeName, item) {
        try {
            item = JSON.parse(serializeJs(item));
            const tx = this.db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const existingItem = await store.get(item.id);
            if (existingItem) {
                await store.put(item);
            } else {
                await store.add(item);
            }
            await tx.complete;
            return item.id;
        } catch (error) {
            console.error(error);
        }
    }

}

export const dbService = new DbService()
