<template>
  <svg
    width="32"
    height="23"
    viewBox="0 0 32 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.4366 15.1178C31.247 13.3828 30.39 11.8444 29.1326 10.7697C28.1141 9.90573 26.8356 9.32971 25.4238 9.20326C25.3746 8.71155 25.2903 8.23387 25.1709 7.76325C25.0374 7.25046 24.8618 6.75873 24.6511 6.28111C23.1479 2.88126 19.748 0.5 15.8005 0.5C11.8529 0.5 8.45272 2.88126 6.9499 6.28111C3.0865 6.92732 0.136475 10.292 0.136475 14.3308C0.136475 18.8334 3.80323 22.5 8.30565 22.5H24.8058C28.4796 22.5 31.4722 19.5076 31.4722 15.8336C31.4722 15.5948 31.4577 15.3566 31.4366 15.1178Z"
      fill="#2EC86B"
    />
    <path
      d="M14.8312 16.663L10.9327 13.2281C10.6587 12.9823 10.6307 12.5608 10.8695 12.2869C11.1153 12.0129 11.5368 11.9848 11.8107 12.2237L14.775 14.8297L19.7343 9.87047C19.9942 9.61056 20.4156 9.61056 20.6755 9.87047C20.9354 10.1304 20.9354 10.5518 20.6755 10.8117L14.8312 16.663Z"
      fill="white"
    />
  </svg>
</template>