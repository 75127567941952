<template>
  <BaseModal
    v-model="isOpen"
    min-width="440"
    min-height="120"
    confirm-label="Confirm"
    :hasConfirm="false"
    :hasCancel="false"
  >
    <template v-slot:title>Print Sticker</template>

    <div class="button-container mt-2 d-flex">
      <div
        v-if="actions.includes(orderPanelActionButtons.LABEL_PRINT)"
        v-for="kot in activeOrderKOTs"
        :key="kot"
        class="icon-button"
      >
        <m-icon-button
          :label="`KOT#-${kot}`"
          icon="sticky-note"
          color="primary"
          @click="emitActionWithParams('printLabelStickers', kot)"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import MIconButton from "@/spa/components/common/MIconButton";
import { ORDER_PANEL_ACTION_BUTTONS } from "@/spa/constants";

export default {
  name: "OrderPrintStickerModal",

  components: { BaseModal, MIconButton },

  props: {
    actions: {
      type: Array,
      default: [],
    },
    activeOrderKOTs: {
      type: Array,
      default: [],
    }
  },

  data() {
    return {
      orderPanelActionButtons: ORDER_PANEL_ACTION_BUTTONS,
    };
  },

  methods: {
    emitActionWithParams(action, kotNum) {
      this.$emit(action, kotNum);
    },
  },
};
</script>

<style scoped>
.button-container {
  gap: 10px;
}
.icon-button {
  width: 100px;
  height: 100px;
}
</style>
