<template>
    <base-modal
        v-bind="$attrs"
        min-width="320px"
        width="100vw"
        max-width="600px"
        min-height="100px"
        :confirm-label="'Void'"
        @confirm="applyFullVoid"
        @before-open="init"
        >
        <template #title>Void Bill</template>

        <hr />

        <div class="mt-2">
            <label>
                Select void reason
            </label>
            <div class="reasons-row d-none d-lg-flex flex-wrap">
                <m-card
                    v-for="reason in voidReasons"
                    :key="reason.id"
                    :label="reason.cancellation_reason_name"
                    :active="reason.id == selectedVoidReason?.id"
                    clickable
                    ratio="1:1"
                    class="col-4 mx-0"
                    @click="selectReason(reason)"
                />
            </div>
            <div class="d-lg-none">
                <div class="form-check choice-radio" v-for="reason in voidReasons">
                    <input type="radio"
                        class="form-check-input"
                        name="fullvoidradio"
                        :id="`fvinput-${reason.id}`"
                        :checked="reason.id == selectedVoidReason?.id"
                        @click="selectReason(reason)"
                        />
                    <label :for="`fvinput-${reason.id}`" class="form-check-label flex-fill">
                        {{ reason.cancellation_reason_name }}
                    </label>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import MCard from '@/spa/components/common/MCard';
import BaseModal from '@/spa/components/modals/BaseModal';
import { getVoidReasons } from '@/spa/services/cashier-service';

import cloneDeep from 'rfdc/default';

export default {
    name: 'FullVoidModal',

    components: {
        MCard,
        BaseModal,
    },

    props: {
        item: {
            type: Object,
            default: () =>({}),
        },
        voidTrigger: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            selectedVoidReason: null,
            voidReasons: [],
        };
    },

    methods: {
        async init() {
            this.selectedVoidReason = null;
            await this.fetchVoidReasons();
        },

        async fetchVoidReasons() {
            try {
                const response = await getVoidReasons();
                this.voidReasons = response.data;
            } catch (e) {
                console.error(e);
                this.voidReasons = [];
            }
        },

        selectReason(reason) {
            this.selectedVoidReason = reason;
        },

        applyFullVoid() {
            if (!this.selectedVoidReason) {
                this.$swal.warning('Please select a reason');
                return;
            }

            this.$emit('applyFullVoid', {item: this.item, reason: this.selectedVoidReason.cancellation_reason_name, triggeredFrom: this.voidTrigger});
        }
    },
};
</script>

<style scoped>
.reasons-row {
    gap: 16px;
    margin: 24px;
}

#mosaic-pos-app .choice-radio {
    border-bottom: 1px solid #DFECFF;
    padding: 1rem;
    display: flex;
    align-items: center;
}

#mosaic-pos-app .choice-radio input[type=radio] {
    width: 20px;
    height: 20px;
    padding: 4px;
    margin: 0 16px 0 0;
}

#mosaic-pos-app .choice-radio label {
    color: #343A40;
    font-weight: normal;
    text-transform: none;
    margin: 0 1rem;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
}
</style>
