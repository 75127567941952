import axios from "@/spa/plugins/axios";
import {ACCOUNT_TYPES, ACTIVE_ACCOUNT_TYPE, OFFLOAD} from "@/spa/constants";

export const getTableLayout = (serviceTypeId = "") => {
  if (ACTIVE_ACCOUNT_TYPE !== ACCOUNT_TYPES.BM) {
    return Promise.resolve({data: {table_details: {service_areas: []}}});
  }

  if (OFFLOAD.sqliteOffloadTableDetail) {
    const sab = new ServiceAreaBridge();
    return sab.getAll();
  }

  return axios.get(route("show.table.layout.new", [serviceTypeId]));
};

export default {
  getTableLayout,
};
