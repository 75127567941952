<template>
    <base-modal
        v-bind="$attrs"
        min-width="800px"
        min-height="100px"
        :confirm-label="confirmLabel"
        @before-open="init"
        @confirm="chargeToRoom"
        >
        <template #title>Select Room</template>

        <hr />

        <button class="btn btn-primary" @click="getAllReservations(true)">
          Refresh Reservation
        </button>
        
        <div class="">
            <div class="mods-row d-flex flex-wrap">
                <m-card v-for="reservation in reservations" :key="reservation.Id"
                    clickable ratio="1:1" class="col-2 mx-0" @click="selectReservation(reservation)"
                    :active="selectedReservation && selectedReservation.Id == reservation.Id"
                    >
                    <div class="font-weight-bold"> Rm. {{ reservation.Number }} </div>
                </m-card>
            </div>
        </div>
    </base-modal>
</template>

<script>
import MCard from '../common/MCard.vue';
import BaseModal from '../modals/BaseModal.vue';
import { getAllReservations } from '@/spa/services/mews-service';

export default {
    name: 'MewsGetReservationsModal',

    components: {
        MCard,
        BaseModal,
    },

    data() {
        return {
            selectedReservation: null,
            reservations: []
        };
    },

    computed: {
        confirmLabel() {
            return 'Charge to Room';
        }
    },
    
    methods: {

        async init() {
            this.getAllReservations();
        },
        
        async getAllReservations(forceFetch = false) {
            try {
                const payload = {
                    forceFetch: forceFetch
                };
                
                let response = await getAllReservations(payload);
                this.reservations = response.data.Reservations;
            } catch (e) {
                this.reservations = [];
                this.$swal.error("Something went wrong", "Please try again");
                throw e;
            }
        },

        selectReservation(reservation) {
            this.selectedReservation = reservation;
        },

        chargeToRoom() {
            this.$emit('chargeToRoom', {
                reservationId: this.selectedReservation.Id
            });
        }
    },
};
</script>

<style scoped>
.mods-row {
    gap: 8px;
    margin: 8px;
}

.quantity-control {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    display: grid;
    place-content: center;
}

.max-transaction-amount {
    font-size: .7rem;
    margin-top: 5px;
}
</style>
