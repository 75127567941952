export default {
    namespaced: true,

    state: {
        initialData: {}
    },

    mutations: {
        setInitialData(state, { key, value }) {
            state.initialData[key] = value;
        },
    },

    actions: {
        async populateInitialData({ commit }) {
            const productCategory = new ProductCategoryBridge();
            const { rows: categories } = await productCategory.getAll();
            commit('setInitialData', {
                key: 'categories',
                value: categories,
            });

            const productGroup = new ProductGroupBridge();
            const { rows: groups } = await productGroup.getAll();
            commit('setInitialData', {
                key: 'groups',
                value: groups,
            });

            const brand = new BrandBridge();
            commit('setInitialData', {
                key: 'brands',
                value: await brand.getAll(),
            });

            const tax = new TaxBridge();
            const { rows: taxes } = await tax.getAll();
            commit('setInitialData', {
                key: 'taxes',
                value: taxes,
            });

            const serviceChannel = new ServiceChannelBridge();
            const { rows: channels } = await serviceChannel.getAll();
            commit('setInitialData', {
                key: 'channels',
                value: channels,
            });
        }

    }
};
