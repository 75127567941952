<template>
    <div class="m-line-item-separator d-flex align-items-center justify-content-around">
        <hr class="line" />
        <div v-if="movable" class="drag-handle move ml-4">
            <i class="fa fa-grip-lines" />
        </div>
        <div
            class="separator-label mx-4"
            :class="{
                'ml-2': movable,
                'mr-2': removable,
            }"
            >
            <slot></slot>
        </div>
        <div v-if="removable" class="remove mr-4">
            <i class="fa fa-times" @click="$emit('click')" />
        </div>
        <hr class="line" />
    </div>
</template>

<script>
    export default {
        name: 'MLineItemSeparator',
        emits: ['click'],
        props: {
            removable: {
                type: Boolean,
                default: false
            },
            movable: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>
.m-line-item-separator hr {
    width: 100%;
    padding: 0 2px;
}

.separator-label {
    font-size: 0.5rem;
    font-weight: 600;
    color: #666;
    text-transform: uppercase full-width;
}

.move {
    cursor: move;
}

.remove {
    cursor: pointer;
}

.remove:hover {
    color: #f00;
}
</style>