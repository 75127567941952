<template>
  <BaseModal
    v-model="isOpen"
    min-width="0"
    max-width="800"
    min-height="100px"
    width="100%"
    confirm-label="Confirm"
    :hasConfirm="false"
    :hasCancel="false"
  >
    <template v-slot:title>Choose Action</template>

    <div class="button-container mt-2 d-flex">
      <div
        v-if="actions.includes(orderPanelActionButtons.LABEL_PRINT)"
        class="icon-button"
      >
        <m-icon-button
          label="Print Sticker"
          icon="sticky-note"
          color="primary"
          @click="emitAction('checkIfActiveOrderHasSingleKOT')"
        />
      </div>
      <div
        v-if="actions.includes(orderPanelActionButtons.ROOM_PAYMENT)"
        class="icon-button"
      >
        <m-icon-button
          label="Room Payment"
          icon="hotel"
          color="#3895D3"
          @click="emitAction('getReservations')"
        />
      </div>
      <div
        v-if="actions.includes(orderPanelActionButtons.SPLIT)"
        class="icon-button"
      >
        <m-icon-button
          label="Split Bill"
          icon="columns"
          color="#3895D3"
          @click="emitAction('setBilledAndSplit')"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import MIconButton from "@/spa/components/common/MIconButton";
import { ORDER_PANEL_ACTION_BUTTONS } from "@/spa/constants";

export default {
  name: "OrderMoreActionModal",

  components: { BaseModal, MIconButton },

  props: {
    actions: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      orderPanelActionButtons: ORDER_PANEL_ACTION_BUTTONS,
    };
  },

  methods: {
    emitAction(action) {
      this.$emit(action);
    },
  },
};
</script>

<style scoped>
.button-container {
  gap: 10px;
}
.icon-button {
  width: 100px;
  height: 100px;
}
</style>
