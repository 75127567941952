import {action, DataOffloadBridge} from "@/mobile_bridge/offload/offload";

export class PermissionBridge extends DataOffloadBridge {
    constructor() {
        super('Permission');
    }

    async getPermissionId(permissionName) {
        const response = await this.sendMessage('GET_PERMISSION_ID', { permissionName });
        return response.data;
    }

    async can(instanceConstructor, permissionName, userTypeId = null) {
        const instance = new instanceConstructor();
        const permissionId = await this.getPermissionId(permissionName);

        if (!permissionId) {
            return false;
        }

        let permissions = await instance.getAll();

        if (userTypeId) {
            permissions = permissions.filter(permission => permission.user_type_id == userTypeId)
        }

        return permissions.some(data => {
            return data.permission_ids.some(value => value == permissionId);
        });
    }
}

export class UserPermissionBridge extends DataOffloadBridge {
    constructor() {
        super('UserPermission');
    }

    async getAll() {
        const { rows } = await this.sendMessage(action.GET_ALL);

        return rows.map(row => {
            row.permission_ids = JSON.parse(row.permission_ids);
            return row;
        });
    }
}
