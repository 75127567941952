import axios from "@/spa/plugins/axios";
import {OFFLOAD} from "@/spa/constants";
import {sqliteOffloadGetStorageValue} from "@/mobile_bridge/offload/receipt-model";

export const getUserInfo = async () => {
  if (OFFLOAD.sqliteOffloadPOSFE1358) {
    const activeUser = await sqliteOffloadGetStorageValue();
    delete activeUser.currentShift;

    return {
      data: activeUser
    }
  }

  return axios.get(`/api/user-info`);
};

export default {
  getUserInfo,
};
