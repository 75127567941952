import axios from "@/spa/plugins/axios";
import qs from "qs";

const PARAMS = {
  Grab: {
    routes: {
      orders: "grab-orders.index",
      rejectReasons: "grabfood.order.cancelable",
      orderAccepted: "grabfood.order.prepare",
      orderRejected: "grabfood.order.prepare",
      orderCancelled: "grabfood.order.cancel",
      orderCollected: "grabfood.order.delivery",
      orderDelivered: "grabfood.order.delivery",
      orderReadyForDelivery: "grabfood.order.mark",
      pauseStore: "grabfood.merchant.pause",
      resumeStore: "grabfood.merchant.pause",
      orderItemVoided: "grabfood.order.edit",
    },
    statuses: {
      accepted: "Accepted",
      rejected: "Rejected",
      collected: "Collected",
      delivered: "Delivered",
      cancelled: "Cancelled",
    },
  },
  FoodPanda: {
    routes: {
      orders: "foodpanda-orders.index",
      rejectReasons: "foodpanda.order.reject.reasons",
      orderAccepted: "foodpanda.update.order.status",
      orderRejected: "foodpanda.update.order.status",
      orderCancelled: "foodpanda.update.order.status",
      orderCollected: "foodpanda.update.order.status",
      orderDelivered: "foodpanda.update.order.status",
      orderReadyForDelivery: "foodpanda.order.prepared",
      pauseStore: "foodpanda.store.pause",
      resumeStore: "foodpanda.store.pause",
    },
    statuses: {
      accepted: "order_accepted",
      rejected: "order_rejected",
      collected: "order_picked_up",
      cancelled: "order_rejected",
    },
  },
  AlipayDStore: {
    routes: {
      orders: "dstore.index",
      rejectReasons: "dstore.cancelable",
      orderAccepted: "dstore.accept",
      orderRejected: "dstore.cancel",
      orderCancelled: "dstore.cancel",
      orderCollected: "dstore.complete",
      orderDelivered: "grabfood.order.delivery",
      orderReadyForDelivery: "dstore.ready",
      pauseStore: "grabfood.merchant.pause",
      resumeStore: "grabfood.merchant.pause",
      orderItemVoided: "dstore.order.edit",
    },
    statuses: {
      accepted: "Accepted",
      cancelled: "Cancelled",
    },
  }
};

export const getOrders = (params, type) => {
  return axios.get(
    route(PARAMS[type].routes.orders, {
      ...params,
      paramsSerializer: (currentParams) => {
        return qs.stringify(currentParams);
      },
    }),
    {
      cache: { maxAge: 0 },
    }
  );
};

export const getOrder = (id) => {
  return axios.get(route("orders.order", { id }), {
    cache: { maxAge: 0 },
  });
};

export const getRejectReasons = (id = null, type) => {
  return axios.get(route(PARAMS[type].routes.rejectReasons, { id }), {
    cache: { maxAge: 0 },
  });
};

export const orderAccepted = (id, type) => {
  console.log(route(PARAMS[type].routes.orderAccepted, { id }));
  return axios.post(route(PARAMS[type].routes.orderAccepted, { id }), {
    status: PARAMS[type].statuses.accepted,
  });
};

export const orderReadyForDelivery = (id, type) => {
  return axios.post(route(PARAMS[type].routes.orderReadyForDelivery, { id }));
};

export const orderCollected = (id, type) => {
  return axios.post(route(PARAMS[type].routes.orderCollected, { id }), {
    status: PARAMS[type].statuses.collected,
  });
};

export const orderDelivered = (id, type) => {
  return axios.post(route(PARAMS[type].routes.orderDelivered, { id }), {
    status: PARAMS[type].statuses.delivered,
  });
};

export const orderRejected = (id, params, type) => {
  return axios.post(route(PARAMS[type].routes.orderRejected, { id }), {
    ...params,
    status: PARAMS[type].statuses.rejected,
  });
};

export const orderCancelled = (id, params, type) => {
  return axios.post(route(PARAMS[type].routes.orderCancelled, { id }), {
    ...params,
    status: PARAMS[type].statuses.cancelled,
  });
};

export const orderExtendReadyTime = (params) => {
  return axios.put(
    route("grabfood.order.readytime"),
    {
      ...params,
    },
    {
      "axios-retry": {
        retries: 0,
      },
    }
  );
};

export const pauseStore = (params, type) => {
  return axios.put(route(PARAMS[type].routes.pauseStore), {
    ...params,
  });
};

export const resumeStore = (params, type) => {
  return axios.put(route(PARAMS[type].routes.resumeStore), {
    ...params,
  });
};

export const orderItemVoided = (id, params, type) => {
  return axios.post(route(PARAMS[type].routes.orderItemVoided, { id }), {
    ...params,
  });
};

export const getMerchantResumeDetails = () => {
  return axios.get(route("get.merchant.delivery.details"), {
    cache: { maxAge: 0 },
  });
};
