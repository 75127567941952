<template>
    <div
        class="m-card d-flex flex-column justify-content-center"
        :class="{
            [`align-items-${alignItems}`]: true,
            [`justify-content-${justifyContent}`]: true,
            clickable,
            active,
            neumorph,
        }"
        :style="customStyleObject"
        v-bind="$attrs"
    >
        <div
            v-if="header"
            class="m-card__header p-2 pb-1"
            :style="{ color: headerColor }"
            v-text="header"
            />

        <div class="m-card__body">
            <slot />
        </div>

        <div
            v-if="label"
            class="m-card__label"
            :style="{ textTransform: labelTransform }"
            v-text="label"
            />

        <div v-if="hasClose" class="m-card__active-pip m-card__close" @click="$emit('close')">x</div>
    </div>
</template>

<script>
export default {
    name: 'MCard',

    props: {
        label: {
            type: String,
            default: '',
        },

        header: {
            type: String,
            default: '',
        },

        active: {
            type: Boolean,
            default: false,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        ratio: {
            type: String,
            default: '',
        },

        labelTransform: {
            type: String,
            default: 'capitalize',
        },

        neumorph: {
            type: Boolean,
            default: false,
        },

        hasClose: {
            type: Boolean,
            default: false,
        },

        justifyContent: {
            type: String,
            default: 'center',
        },

        alignItems: {
            type: String,
            default: 'center',
        },

        headerColor: {
            type: String,
            default: 'black',
        },

        backgroundColor: {
            type: String,
            default: 'white',
        },

        applyCustomStyle: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ratioValue() {
            if (!this.ratio) return 'initial';

            const [width, height] = this.ratio.split(':');
            return `${Number(width) / Number(height)}`;
        },

        customStyleObject() {
            const customStyles = {};

            customStyles['--background-color'] = this.backgroundColor;

            if (this.applyCustomStyle) {
                customStyles.width = '150px';
                customStyles.height = '150px';

                return customStyles;
            }

            customStyles.aspectRatio = this.ratioValue;

            return customStyles;
        }
    },
};
</script>

<style scoped>
.m-card {
    min-height: 120px;
    position: relative;
    border-radius: 16px;
    user-select: none;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0px 1.1px 5px -36px rgba(0, 0, 0, 0.153),
        0px 3.8px 16.8px -36px rgba(0, 0, 0, 0.179),
        0px 17px 75px -36px rgba(0, 0, 0, 0.255)
    ;
}

.m-card:not(.active) {
    background-color: var(--background-color);
}

.m-card.neumorph {
    box-shadow:  9px 9px 19px #e3e3e3,
                -9px -9px 19px #fafafa;
}

.m-card.clickable:hover {
    background: linear-gradient(145deg, #fafafa, #f0f0f0);
}

.m-card.clickable:active {
    transform: scale(0.98);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.m-card.clickable.active:hover {
    background: #3B95DB;
}

.m-card.clickable {
    cursor: pointer;
}

.m-card .m-card__active-pip {
    background-color: var(--light);
    box-shadow: inset 1px 1px 0px #c9c9c9,
                inset -1px -1px 0px #fafafa;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
}

.m-card .m-card__close {
    height: 16px;
    width: 16px;
    background: none;
    box-shadow: none;
    background-color: none;
    display: initial;
    cursor: pointer;
}

.m-card.active {
    background: #1B75BB;
    box-shadow:
        0 0 0 1px #d4e2ec,
        0px 1.1px 5px -36px #d4e2ec,
        0px 3.8px 16.8px -36px #0094ff99,
        0px 17px 75px -36px #fafafa
    ;
}

.m-card.active * {
    color: white !important;
}

.m-card.neumorph.active {
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        9px 9px 48px #d4e2ec,
        4px 4px 12px #0094ff99,
        -9px -9px 19px #fafafa
    ;
}

.m-card.active .m-card__active-pip {
    display: flex;
}

.m-card__header {
    font-size: 1rem;
    font-weight: bold;
}

.m-card__label {
    font-size: 0.75rem;
}

.m-card__body {
    max-height: 80%;
    overflow: auto;
    max-width: 100%;
}
</style>
