<template>
  <div class="e-payment-selector">
    <MCard clickable ratio="1:1" @click="goBack" class="back-icon" v-if="showBackIcon">
      <template #default>
        <i class="fas fa-arrow-alt-circle-left"></i>
      </template>
    </MCard>

    <MCard
      v-for="payment in ePayments"
      :class="{ active: payment === selelectedPayment }"
      clickable
      ratio="1:1"
      :active="payment.name === selelectedPayment"
      @click="selectPayment(payment, payment === selelectedPayment)"
    >
      <template #default>
        <img :src="`/images/payments/${payment.type}.png`" />
      </template>
    </MCard>
  </div>
</template>

<script>
import MCard from "@/spa/components/common/MCard";
import { getEPayments } from "@/spa/services/cashier-service";

export default {
  name: "MEPaymentSelector",

  components: { MCard },

  props: {
    showBackIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ePayments: [],
      selelectedPayment: null,
    };
  },

  methods: {
    async getEPayments() {
      try {
        const response = await getEPayments();
        this.ePayments = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    selectPayment(payment, selected) {
      if (selected) {
        this.selelectedPayment = null;
      } else {
        this.selelectedPayment = payment;
      }

      this.$emit("ePaymentSelected", this.selelectedPayment);
    },

    goBack() {
      this.$emit("showPaymentSelection");
    },
  },

  mounted() {
    this.getEPayments();
  },
};
</script>

<style scoped>
img {
  width: 60px;
  height: auto;
}

div.e-payment-selector {
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  div.e-payment-selector {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 992px) {
  div.e-payment-selector {
    grid-template-columns: repeat(6, 1fr);
  }
}

::v-deep(.m-card) {
  min-height: 80px;
}

::v-deep(.m-card.clickable) {
  background: white !important;
}

::v-deep(.clickable.active),
::v-deep(.clickable.active:hover) {
  background-color: #e1f3ff !important;
}

.back-icon i {
  font-size: 35px;
  color: gray;
}
</style>
