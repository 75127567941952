<template>
  <div
    class="m-notification-icon d-flex justify-content-center align-items-center"
    :class="{ disabled }"
  >
    <i class="fas fa-bell"></i>

    <div
      v-if="count"
      class="
        notification-count
        d-flex
        justify-content-center
        align-items-center
      "
    >
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MNotificationicon",

  props: {
    count: {
      type: Number,
      default: null,
    },
  },

  computed: {
    disabled() {
      return !this.count;
    },
  },
};
</script>

<style scoped>
.m-notification-icon {
  position: relative;
  width: 40px;
  height: 40px;
  color: white;
  background-color: #1b75bb;
  border-radius: 50%;
  cursor: pointer;
}

.m-notification-icon.disabled {
  background-color: #aaa;
  cursor: default;
}

.notification-count {
  color: white;
  background-color: #f47070;
  border: 1px solid white;
  border-radius: 50%;
  font-size: 10px;
  padding: 0 3px;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
}
</style>
