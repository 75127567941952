import moment from "moment";
import {cloneDeep, isEmpty} from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {OFFLOAD_RECEIPT_ACTION} from "@/mobile_bridge/offload/offload-receipt";
import {
    ACCOUNT_TYPES,
    ACTIVE_ACCOUNT_TYPE,
    ACTIVE_USER,
    OFFLOAD,
    USE_NEW_AMOUNT_DUE_FORMULA,
    USE_SM_MARKETS_OR_FORMAT
} from "@/spa/constants";
import { hasDiscountEligibilityKeywords } from "@/vue/helper/discount";
const ZERO_VATABLE = ["Diplomat Discount"];

export function generateDateTime() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
}

export async function posDateWithCurrentTime() {
    const posDateBridge = new PosDateBridge();
    const activePosDate = await posDateBridge.getPosDate(window.locationId);

    // Format the POS date without the time component
    const posDate = moment(activePosDate).format("YYYY-MM-DD");

    // Get the current time in HH:mm:ss format
    const currentTime = moment().format("HH:mm:ss");

    // Combine the formatted POS date and current time
    return `${posDate} ${currentTime}`;
}

export const TRANSACTION_STATUS = {
    1: 'Pending',
    2: 'Paid',
    3: 'Billed',
    4: 'Voided',
    5: 'Original',
    6: 'Merged', // for SQLite only
};

export const TRANSACTION_STATUS_ID = {
    PENDING: 1,
    PAID: 2,
    BILLED: 3,
    VOIDED: 4,
    ORIGINAL: 5,
    MERGED: 6, // for SQLite only
};

export const RECEIPT_CONTENT_TYPE = {
    TRANSACTIONS: 'transactions',
    PRODUCTS: 'products',
    PRODUCT_CATEGORIES: 'product_categories',
    MODIFIERS: 'modifiers',
    BILL_DISCOUNTS: 'bill_discounts',
    TAXES: 'taxes',
    PAYMENT_TYPES: 'payment_types',
    INLINE_DISCOUNTS: 'inline_discounts'
}

export const CANCELLATION_TYPE = {
    VOID: 1,
    REFUND: 2,
    COMP: 3,
    CANCEL: 4
}

export function sumArray(array, prop = null) {
    return array.reduce((sum, item) => {
        if (typeof item === 'number') {
            return sum + item;
        } else if (typeof item === 'object' && prop) {
            return sum + parseFloat(item?.[prop] ?? 0);
        } else {
            return sum;
        }
    }, 0);
}

export function formatAmount(value) {
    value = typeof value === "string" ? parseFloat(value || 0) : value;
    return value?.toFixed(2) || "0.00";
}

export function formatTwoDecimals(value) {
    const parseValue = typeof value === "string" ? parseFloat(value) : value || 0;

    const formattedValue = parseValue.toFixed(2)

    return parseFloat(formattedValue);
}

export const getTransactionStatusId = (orderObj, receipt = true) => {
    let statusId = TRANSACTION_STATUS_ID.PENDING;

    if (orderObj?.isBilled) {
        statusId = TRANSACTION_STATUS_ID.BILLED;
    }
    if (orderObj?.isSettled) {
        statusId = TRANSACTION_STATUS_ID.PAID;
    }
    if (orderObj?.isVoided) {
        statusId = TRANSACTION_STATUS_ID.VOIDED;
    }

    if (ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM && receipt && orderObj?.receiptMergedTo) {
        statusId = TRANSACTION_STATUS_ID.MERGED;
    }

    return statusId;
}

function hasAnyProperty(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return true;
        }
    }
    return false;
}

// Convert integer to a string, concatenate the number, and then convert back to an integer
export function appendNumberToInteger(integer, number) {
    return parseInt(integer.toString() + number);
}

function groupOrdersByProperty(orders, { propKey, secondPropKey = null, concatenatedKey = null, valueKey = null }) {
    return orders.reduce((result, item) => {
        let key = item[propKey];

        if (secondPropKey) {
            key = key[secondPropKey];

            if (concatenatedKey) {
                const value = concatenatedKey == 'product_category_id'
                    ? item[propKey]?.product_group?.product_category_id ?? item[propKey][concatenatedKey]
                    : item[propKey][concatenatedKey];

                key += `=${value}`
            }
        }

        if (!result[key]) {
            result[key] = [];
        }

        const valueToPush = valueKey ? item[valueKey] : item;

        result[key].push(valueToPush);

        return result;
    }, {});
}

function calculateTotalValues(totals, baseTotal = null) {
    const vat = sumArray(totals, 'vat');
    const net = sumArray(totals, 'net');
    const serviceCharge = sumArray(totals, 'serviceCharge');
    let otherCharges = 0;

    const totalValues = {
        tax: formatAmount(sumArray(totals, 'vat')),
        vat_amount: formatAmount(vat),
        net_sales: formatAmount(net),
        gross_sales: formatAmount(sumArray(totals, 'total')),
        sub_total: formatAmount(sumArray(totals, 'net')),
        total: formatAmount(sumArray(totals, 'total')),
        service_charge_amount: formatAmount(serviceCharge),
        vat_exempt_sales: formatAmount(sumArray(totals, 'vatExemptSales')),
        zero_rated_sales: formatAmount(sumArray(totals, 'zeroRatedSales')),
        rawPrice: formatAmount(sumArray(totals, 'rawPrice')),
    };

    totalValues.vatable_sales = calculateVatableSales(totalValues);
    totalValues.vat_adjustment = 0;

    if (baseTotal) {
        const rawPricePercentage = totalValues.rawPrice / baseTotal.rawPrice;
        const otherCharges = rawPricePercentage * sumArray(baseTotal?.other_charges || []);
        totalValues.other_charges = formatAmount(otherCharges);
    }

    const netSalesWCharges = vat + net + serviceCharge + otherCharges;
    totalValues.net_sales_w_charges = formatAmount(netSalesWCharges);

    return totalValues;
}

function calculateGrossSales(obj) {
    const total = parseFloat(obj.vatable_sales) +
        parseFloat(obj.vat_exempt_sales) +
        parseFloat(obj.zero_rated_sales) +
        parseFloat(obj.vat_amount) +
        parseFloat(obj.service_charge_amount) +
        parseFloat(obj.other_charges);

    return formatAmount(total);
}

function calculateNetSalesWCharges(obj) {
    const total = parseFloat(obj.net_sales) +
        parseFloat(obj.vat_amount) +
        parseFloat(obj.service_charge_amount) +
        parseFloat(obj.other_charges);

    return formatAmount(total);
}

async function getActiveVat(service_type) {
    const tb = new TaxBridge();
    return await tb.getRow({
        tax_name: "VAT",
        service_type
    });
}

async function calculateVatAdjustment(totals, totalPax, discountedPax, serviceType) {
    const activeVat = await getActiveVat(serviceType);
    const taxRate = parseFloat(activeVat?.tax_rate ?? 1.12);

    const total = totals.rawPrice * discountedPax * (taxRate - 1) / (totalPax * taxRate);

    return formatAmount(isNaN(total) ? 0 : total);
}

export function resetTotalsToZero() {
    return {
        tax: "0.0000",
        vat_amount: "0.0000",
        net_sales: "0.0000",
        net_sales_w_charges: "0.0000",
        gross_sales: "0.0000",
        sub_total: "0.0000",
        total: "0.0000",
        service_charge_amount: "0.0000",
        vat_exempt_sales: "0.0000",
        zero_rated_sales: "0.0000",
        vatable_sales: "0.0000",
        vat_adjustment: "0.0000",
    };
}

function calculateVatableSales(total, shouldFormatAmount = true) {
    const netSales = total?.net || parseFloat(total?.net_sales) || 0;
    const vatExemptSales = total?.vatExemptSales || parseFloat(total?.vat_exempt_sales) || 0;
    const zeroRatedSales = total?.zeroRatedSales || parseFloat(total?.zero_rated_sales) || 0;

    const value = total?.vatable_sales || (formatTwoDecimals(netSales) - formatTwoDecimals(vatExemptSales) - formatTwoDecimals(zeroRatedSales));

    return shouldFormatAmount ? formatAmount(value) : value;
}

function processTax(tax, key, amount, taxes) {
    if (tax?.tax_detail && taxes[key]) {
        taxes[key] += amount;
    } else {
        taxes[key] = amount;
    }

    return taxes;
}

function cancellationTypeId(isBillVoided, order) {
    if (isBillVoided) {
        return CANCELLATION_TYPE.VOID;
    }

    return order?.isVoided && !order?.isCancelled ? CANCELLATION_TYPE.VOID : CANCELLATION_TYPE.CANCEL;
}

// Recompute line item totals if bill discount is applied.
function recomputeLineItemTotals(baseTotal, itemTotal, shouldFormatAmount  = true, vatAdjustment = null) {
    const rawPricePercentage = itemTotal.rawPrice / baseTotal.rawPrice;

    const formatValue = (value) => shouldFormatAmount ? formatAmount(value) : value;

    const vatPercentage = rawPricePercentage * baseTotal.vat;
    const netPercentage = rawPricePercentage * baseTotal.net;
    const serviceChargePercentage = rawPricePercentage * baseTotal.serviceCharge;
    const otherChargesPercentage = rawPricePercentage * sumArray(baseTotal?.other_charges || []);
    const netSalesWCharges = vatPercentage + netPercentage + serviceChargePercentage + otherChargesPercentage;

    const vat_amount = formatValue(vatPercentage);
    const net_sales = formatValue(netPercentage);
    const gross_sales = formatValue(rawPricePercentage * baseTotal.total);

    const totals = {
        ...itemTotal,
        tax: vat_amount,
        vat_amount,
        net_sales,
        net_sales_w_charges: formatValue(netSalesWCharges),
        sub_total: net_sales,
        gross_sales,
        total: gross_sales,
        vatable_sales: formatValue(rawPricePercentage * calculateVatableSales(baseTotal, false)),
        service_charge_amount: formatValue(serviceChargePercentage),
        vat_exempt_sales: formatValue(rawPricePercentage * (baseTotal?.vatExemptSales ?? 0)),
        zero_rated_sales: formatValue(rawPricePercentage * (baseTotal?.zeroRatedSales ?? 0)),
        other_charges: formatValue(otherChargesPercentage),
        discount_detail_id: baseTotal.discount_detail_id,
        discount_value: formatValue(rawPricePercentage * (baseTotal?.discount_value ?? 0)),
    };

    if (vatAdjustment) {
        totals.vat_adjustment = formatValue(rawPricePercentage * vatAdjustment);
    }

    return totals;
}

export function hasLineItemVoid(orderObj) {
    return orderObj.orders.filter(order => !order.isCancelled && order.isVoided).length > 0;
}

export function hasDiscountedLineItems(orderObj) {
    return orderObj.orders.some(order => !isEmpty(order?.discount));
}

export function isBillVoided(orderObj) {
    return orderObj?.isVoided ?? (orderObj?.bill_num && orderObj.orders.every(obj => obj?.isVoided === true));
}

export async function sqliteOffloadGetStorageValue(key = ACTIVE_USER) {
    const storage = new ScopedNativeStorage(window.locationId);
    const result = await storage.get(key);

    if (isEmpty(result)) {
        return {};
    }

    return JSON.parse(result);
}

function removeEmojis(value) {
    if (typeof value === 'string') {
        return value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
    }

    return value;
}

function extractDiscountCustomerDetails(orderObj) {
    const discountCustomerDetails = [];
    const orderDiscount = orderObj?.billDiscount;
    const itemDiscounts = orderObj.orders.map(orderItem => !isEmpty(orderItem?.discount) && orderItem.discount).filter(Boolean);
    
    if (!isEmpty(itemDiscounts)) {
        for (const item of itemDiscounts) {
            discountCustomerDetails.push({
                first_name: item.firstName || '',
                last_name: item.lastName || '',
                reference_number: item.idReferenceNumber || '',
                discount_type: item.discount_name,
                discount_value: item.discountAmount,
            });
        }
    }

    if (!isEmpty(orderDiscount)) {
        const { customerDetails, discount, amount, discountQuantity } = orderDiscount;
        for (const item of customerDetails) {
            const discount_value = amount / discountQuantity;
            discountCustomerDetails.push({
                first_name: item.firstName || '',
                last_name: item.lastName || '',
                reference_number: item.idReferenceNumber || '',
                discount_type: discount.discount_name,
                discount_value,
            });
        }
    }

    return discountCustomerDetails;
}

export async function createReceipt(action, orderObj, isStatusOriginal = false) {
    const posDateBridge = new PosDateBridge();
    const isBillOrLineItemVoided = isBillVoided(orderObj) || hasLineItemVoid(orderObj);
    const isBillOrLineItemVoidedAndStatusNotOriginal = isBillOrLineItemVoided && !isStatusOriginal;
    const activeUser = await sqliteOffloadGetStorageValue();
    const activeShift = activeUser?.currentShift ?? {};
    const activePosDate = await posDateBridge.getPosDate(orderObj?.locationId ?? window.locationId);

    if (!orderObj || (!isBillOrLineItemVoided && isStatusOriginal)) {
        return null;
    }

    let receipt = {
        location_id: window.locationId,
        terminal_id: activeShift?.terminal_id ?? JSON.parse(sessionStorage.getItem('terminal')).id,
        service_type_id: orderObj.serviceTypeId,
        pax_count: orderObj?.pax || 1,
    };

    receipt.local_id = orderObj._id;
    if (isStatusOriginal) {
        receipt.local_id = appendNumberToInteger(receipt.local_id, TRANSACTION_STATUS_ID.ORIGINAL);
    }
    receipt.order_id = receipt.local_id;

    if (orderObj.channelTypeId) {
        receipt.service_channel_id = orderObj.channelTypeId;
    }

    if (orderObj?.bill_num) {
        receipt.bill_num = orderObj.bill_num;
    }

    if (!isBillVoided(orderObj) && hasLineItemVoid(orderObj) && isStatusOriginal) {
        receipt.is_line_item_void = 1;
    }

    const totalValues = isBillVoided(orderObj) && !isStatusOriginal
        ? resetTotalsToZero()
        : calculateTotalValues([orderObj.totals]);

    if (!isEmpty(orderObj?.billDiscount)) {
        const {discountQuantity, discount} = orderObj.billDiscount;
        totalValues.vat_adjustment = await calculateVatAdjustment(orderObj.totals, orderObj.pax, discountQuantity, orderObj.serviceType);

        if (ZERO_VATABLE.includes(discount.discount_name)) {
            totalValues.vatable_sales = "0.00"
        }

        if (discount.meal_combination_amount && hasDiscountEligibilityKeywords(discount.discount_name)) {
            totalValues.vat_adjustment = formatAmount(orderObj.totals.vatExemptSales * 0.12);

            const totalMEMCAmount = discount.meal_combination_amount * discountQuantity
            totalValues.vatable_sales = formatAmount((orderObj.totals.rawPrice - totalMEMCAmount) / 1.12);
        }
    }

    receipt = {
        ...receipt,
        ...totalValues,
        other_charges: formatAmount(sumArray(orderObj.totals?.other_charges || [])),
    }

    if (orderObj?.receipt_num) {
        receipt.receipt_num = orderObj.receipt_num
    }

    if (orderObj?.void_bill_num) {
        receipt.void_bill_num = orderObj.void_bill_num
    }

    if (orderObj?.void_receipt_num) {
        receipt.void_receipt_num = orderObj.void_receipt_num
    }

    // Customer details
    if (orderObj?.customerDetails && hasAnyProperty(orderObj.customerDetails)) {
        const customer = orderObj.customerDetails;
        for (const key in customer) {
            customer[key] = removeEmojis(customer[key]);
        }

        receipt.customer_info = JSON.stringify({
            name: customer.name,
            address: customer.address,
            businessStyle: customer.businessStyle,
            tin: customer.tin,
            officialReceipt: String(customer?.officialReceipt ?? 0).padStart(20, '0')
        });
    }

    receipt.transaction_status_id = isStatusOriginal
        ? TRANSACTION_STATUS_ID.ORIGINAL
        : getTransactionStatusId(orderObj);

    if (receipt.transaction_status_id === TRANSACTION_STATUS_ID.MERGED) {
        receipt.receipt_merged_to = orderObj?.receiptMergedTo || null;
    }

    const lineItemDiscounts = orderObj.orders?.map(order => order.discount).filter(Boolean);
    const isTransactionOriginalOrPaidStatus = [TRANSACTION_STATUS_ID.ORIGINAL, TRANSACTION_STATUS_ID.PAID].includes(receipt.transaction_status_id);
    receipt.discount_amount = isTransactionOriginalOrPaidStatus
        ? formatAmount(orderObj.billDiscount?.amount || sumArray(lineItemDiscounts, 'discountAmount'))
        : "0.00";

    // payments
    if (action === OFFLOAD_RECEIPT_ACTION.SETTLE_ORDER) {
        const payments = orderObj?.payments || [];
        receipt.tendered_amount = isTransactionOriginalOrPaidStatus
            ? formatAmount(sumArray(payments, 'amount'))
            : "0.00";
        receipt.return_amount = isTransactionOriginalOrPaidStatus
            ? formatAmount(sumArray(payments, 'change'))
            : "0.00";
    }

    if (isBillOrLineItemVoidedAndStatusNotOriginal) {
        let totals = orderObj.orders.filter(order => {
            if (orderObj.isVoided) {
                return true;
            }
            return order.isVoided && !order.isCancelled;
        }).map(order => order?.preDiscountTotals ?? order.totals);
        const voidAmount = sumArray(totals, 'total') - sumArray(totals, 'serviceCharge');
        receipt.void_amount = formatAmount(voidAmount);
        const modifiedTotals = isBillVoided(orderObj) ? [orderObj.totals] : totals;
        receipt.generated_void_amount = formatAmount(sumArray(modifiedTotals, 'total'));
    }

    // Override previous calculations of gross sales and net sales with charges if the new amount due formula is enabled.
    if (USE_NEW_AMOUNT_DUE_FORMULA) {
        receipt.gross_sales = calculateGrossSales(receipt);
        receipt.net_sales_w_charges = calculateNetSalesWCharges(receipt);
    }

    if (window.MosaicPosAppSupportedFeatures?.useOriginalGrossSales) {
        receipt.original_gross_sales = isBillVoided(orderObj) && !isStatusOriginal ? "0.00" : orderObj.totals.rawPrice;
    }

    receipt.cashier_user_id = activeUser.id ?? orderObj.originShiftTable.user_id;

    const moreDetail = orderObj?.moreDetail || {};
    for (const key in moreDetail) {
        moreDetail[key] = removeEmojis(moreDetail[key]);
    }

    receipt.more_details = JSON.stringify(moreDetail);
    receipt.is_non_vat = orderObj.isNonVat;
    receipt.business_date = activePosDate ?? orderObj.billedShiftTable?.pos_date;
    receipt.origin_shift_num = orderObj.originShiftTable.shift;

    if (orderObj?.isBilled) {
        receipt.billed_shift_num = orderObj.billedShiftTable.shift;
    }

    if (orderObj?.isSettled) {
        receipt.settled_shift_num = activeShift?.shift ?? null;
    }

    if (OFFLOAD.sqliteOffloadPOSFE1403 && !isStatusOriginal) {
        receipt.discount_customer_details = JSON.stringify(extractDiscountCustomerDetails(orderObj));
    }

    receipt.created_at = orderObj.createdAt;
    receipt.billed_at = orderObj.billedAt;
    receipt.settled_at = orderObj.settledAt;
    receipt.updated_at = generateDateTime();

    return receipt;
}

export async function createReceiptContent(orderObj, isStatusOriginal = false, vatAdjustment = null) {
    const data = [];
    const promises = [];

    const isBillVoidedAndStatusNotOriginal = isBillVoided(orderObj) && !isStatusOriginal;

    const hasBillDiscount = orderObj?.billDiscount || false;
    const baseTotals = orderObj.totals;
    const orders = cloneDeep(orderObj.orders);

    // Extracting activeSc and activeVat from the first order in orderObj
    const activeSc = orders[0].activeSc || {};
    const activeVat = orders[0].activeVat || {};
    // unique keys based on tax_name and tax_detail
    const activeScKey = activeSc?.tax_name ? `${activeSc.tax_name}-${activeSc.tax_detail}` : null;
    const activeVatKey = activeVat?.tax_name ? `${activeVat.tax_name}-${activeVat.tax_detail}` : null;

    let taxes = {};
    if (activeScKey) {
        taxes = processTax(activeSc, activeScKey, 0, taxes);
    }
    if (activeVatKey) {
        taxes = processTax(activeVat, activeVatKey, 0, taxes);
    }

    const timestamp = {
        created_at: generateDateTime(),
        updated_at: generateDateTime()
    }

    const commonData = {
        receipt_local_id: orderObj._id,
        service_type_id: orderObj.serviceTypeId,
        location_id: window.locationId,
        table_detail_id: orderObj?.tableId || null,
        transaction_status_id: isStatusOriginal ? TRANSACTION_STATUS_ID.ORIGINAL : getTransactionStatusId(orderObj),
        terminal_id: orderObj.originTerminalId,
        pax_count: orderObj?.pax || 1,
        ...timestamp
    }

    if (isStatusOriginal) {
        commonData.receipt_local_id = appendNumberToInteger(commonData.receipt_local_id, TRANSACTION_STATUS_ID.ORIGINAL);
    }

    let params = { propKey: 'kot' }
    const kotGroupedOrders = groupOrdersByProperty(orders, params);
    for (const kot in kotGroupedOrders) {
        const items = kotGroupedOrders[kot];
        const totals = items.filter(item => !item?.isVoided).map(item => item.totals);

        commonData.kot_num = kot;

        // transactions
        let transaction = calculateTotalValues(totals, baseTotals);

        if (hasBillDiscount) {
            const {discount,amount} = orderObj.billDiscount;
            baseTotals.discount_detail_id = discount.discount_detail_id;
            baseTotals.discount_value = amount;
            transaction = recomputeLineItemTotals(baseTotals, transaction, true, vatAdjustment);

            if (ZERO_VATABLE.includes(discount.discount_name)) {
                transaction.vatable_sales = "0.00"
            }
        }

        // Override previous calculations of gross sales and net sales with charges if the new amount due formula is enabled.
        if (USE_NEW_AMOUNT_DUE_FORMULA) {
            transaction.net_sales_w_charges = calculateNetSalesWCharges(transaction);

            const grossSales = calculateGrossSales(transaction);
            transaction.total = grossSales;
            transaction.gross_sales = grossSales;
        }

        if (isBillVoidedAndStatusNotOriginal) {
            transaction = resetTotalsToZero();
        }

        transaction.local_id = uuidv4();
        data.push({
            type: RECEIPT_CONTENT_TYPE.TRANSACTIONS,
            ...commonData,
            ...transaction,
        });

        // products
        for (const item of items.filter(row => !row?.isCancelled)) {
            let product = calculateTotalValues([item.totals], baseTotals);
            const isVoided = item?.isVoided;

            if (hasBillDiscount) {
                product = recomputeLineItemTotals(baseTotals, product, true, vatAdjustment);

                if (ZERO_VATABLE.includes(orderObj.billDiscount.discount.discount_name)) {
                    product.vatable_sales = "0.00"
                }
            }

            product.product_qty = item.quantity;
            product.product_price = formatAmount(item.activePrice);
            let cancellation = {};
            if (isBillVoidedAndStatusNotOriginal || (isVoided && !isStatusOriginal)) {
                product = resetTotalsToZero();
                cancellation = {
                    cancellation_type_id: cancellationTypeId(isBillVoidedAndStatusNotOriginal, item)
                };
                product.voided_qty = item.quantity;
                product.voided_price = formatAmount(item.activePrice);
                product.product_qty = 0;
                product.product_price = 0;
            }

            const productPromise = (async () => {
                if (!isEmpty(item?.discount)) {
                    const discountPax = item.discount.discountPax;
                    product.vat_adjustment = await calculateVatAdjustment(item.totals, orderObj.pax, discountPax, orderObj.serviceType);

                    const index = data.findIndex(datum => datum.local_id === transaction.local_id);
                    if (index >= 0) {
                        const existingVatAdjustment = parseFloat(data[index].vat_adjustment);
                        data[index].vat_adjustment = formatAmount(existingVatAdjustment + parseFloat(product.vat_adjustment));
                    }
                }
            })();

            promises.push(productPromise);

            product.local_id = uuidv4();
            product.is_product_open = item.isOpenItem;
            commonData.product_category_id = item.product?.product_group?.product_category_id ?? item.product?.product_category_id ?? null;
            product.product_group_id = item.product.product_group_id;
            product.product_group_name = item.product.product_group_name;

            // Override previous calculations of gross sales and net sales with charges if the new amount due formula is enabled.
            if (USE_NEW_AMOUNT_DUE_FORMULA) {
                product.net_sales_w_charges = calculateNetSalesWCharges(product);

                const grossSales = calculateGrossSales(product);
                product.total = grossSales;
                product.gross_sales = grossSales;
            }

            data.push({
                type: RECEIPT_CONTENT_TYPE.PRODUCTS,
                transaction_local_id: transaction.local_id,
                ...commonData,
                ...cancellation,
                product_id: item.product.id,
                product_name: item.product.product_name,
                customer_requests: JSON.stringify(item?.request || {}),
                ...product
            });

            if (!isStatusOriginal) {
                // modifiers
                const forcedMods = item.product?.forcedMods || [];
                const unforcedMods = item.product?.unforcedMods || [];
                const modifiers = [...forcedMods, ...unforcedMods];
                modifiers.forEach(modifier => {
                    const modifierItem = {};
                    if (isBillVoidedAndStatusNotOriginal || isVoided) {
                        modifierItem.voided_price = formatAmount(modifier.mod_price);
                        modifierItem.voided_qty = modifier.quantity * product.voided_qty;
                    }

                    data.push({
                        local_id: uuidv4(),
                        type: RECEIPT_CONTENT_TYPE.MODIFIERS,
                        transaction_local_id: transaction.local_id,
                        receipt_content_local_id: product.local_id,
                        ...commonData,
                        ...cancellation,
                        product_id: item.product.id,
                        modifier_id: modifier.modifier_detail_id,
                        modifier_group_name: modifier.mod_group_name,
                        modifier_pricing_id: modifier.id,
                        modifier_qty: modifier.quantity * product.product_qty,
                        modifier_type: modifier.modifier_type,
                        modifier_name: modifier.modifier_name,
                        modifier_price: formatAmount(modifier.mod_price),
                        ...modifierItem
                    })
                });

                // inline_discounts
                if (item?.discount) {
                    const {id: discount_detail_id, discount_name, discountAmount, discountPax } = item.discount;
                    data.push({
                        local_id: uuidv4(),
                        type: RECEIPT_CONTENT_TYPE.INLINE_DISCOUNTS,
                        transaction_local_id: transaction.local_id,
                        receipt_content_local_id: product.local_id,
                        ...commonData,
                        product_id: item.product.id,
                        discount_detail_id,
                        discount_name,
                        discount_value: formatAmount(discountAmount),
                        discount_qty: discountPax
                    })
                }

                let totals = {};
                if (hasBillDiscount) {
                    totals = recomputeLineItemTotals(baseTotals, {rawPrice: item.totals.rawPrice}, false)
                }

                if (activeScKey) {
                    const serviceCharge = hasBillDiscount ? totals.service_charge_amount : item.totals.serviceCharge;
                    taxes = processTax(item.activeSc, activeScKey, serviceCharge, taxes);
                }
                if (activeVatKey) {
                    const vat = hasBillDiscount ? totals?.tax : item.totals.vat;
                    taxes = processTax(item.activeVat, activeVatKey, vat, taxes);
                }
            }
        }
    }

    if (!isStatusOriginal) {
        // product_categories
        params = {
            propKey: 'product',
            secondPropKey: 'category',
            concatenatedKey: 'product_category_id',
            valueKey: 'totals'
        };
        const productCategories = groupOrdersByProperty(orders.filter(order => !order?.isVoided), params);
        for (const category in productCategories) {
            const explodedCategory = category.split("=");
            let category_value = formatTwoDecimals(sumArray(productCategories[category], 'net'));

            if (USE_SM_MARKETS_OR_FORMAT) {
                category_value += formatTwoDecimals(sumArray(productCategories[category], 'vat'));
                category_value += formatTwoDecimals(sumArray(productCategories[category], 'serviceCharge'));
            }

            if (hasBillDiscount) {
                const totals = recomputeLineItemTotals(baseTotals, {rawPrice: sumArray(productCategories[category], 'rawPrice')}, false);
                category_value = formatTwoDecimals(totals.net_sales);

                if (USE_SM_MARKETS_OR_FORMAT) {
                    category_value += formatTwoDecimals(totals.vat_amount);
                    category_value += formatTwoDecimals(totals.service_charge_amount);
                }
            }

            data.push({
                local_id: uuidv4(),
                type: RECEIPT_CONTENT_TYPE.PRODUCT_CATEGORIES,
                receipt_local_id: commonData.receipt_local_id,
                product_category_id: parseInt(explodedCategory[1]) || null,
                product_category_name: explodedCategory[0],
                category_value: formatAmount(category_value),
                ...timestamp
            })
        }

        // taxes
        for (const tax in taxes) {
            const explodedTax = tax.split("-");
            if (taxes[tax] > 0) {
                data.push({
                    local_id: uuidv4(),
                    type: RECEIPT_CONTENT_TYPE.TAXES,
                    receipt_local_id: commonData.receipt_local_id,
                    tax_detail_id: parseInt(explodedTax[1]),
                    tax_name: explodedTax[0],
                    tax_value: formatAmount(taxes[tax]),
                    ...timestamp
                })
            }
        }

        // bill_discounts
        const billDiscount = orderObj?.billDiscount || {};
        if (!isEmpty(billDiscount)) {
            const { discount_detail_id, discount_name } = billDiscount.discount;
            data.push({
                local_id: uuidv4(),
                receipt_local_id: commonData.receipt_local_id,
                location_id: commonData.location_id,
                terminal_id: commonData.terminal_id,
                type: RECEIPT_CONTENT_TYPE.BILL_DISCOUNTS,
                discount_detail_id,
                discount_name,
                discount_value: formatAmount(billDiscount.amount),
                discount_qty: billDiscount.discountQuantity,
                ...timestamp
            })
        }

        const payments = orderObj?.payments || [];
        payments.forEach(payment => {
            // CustomerCardDetail requires customerName, cardNumber, and approvalCode
            const { customerName, cardNumber, approvalCode } = payment

            data.push({
                local_id: uuidv4(),
                receipt_local_id: commonData.receipt_local_id,
                type: RECEIPT_CONTENT_TYPE.PAYMENT_TYPES,
                payment_type_id: payment?.id || null,
                payment_type_name: payment.method,
                payment_amount: formatAmount(payment.exact_amount),
                exact_tendered_amount: formatAmount(payment.amount),
                payment_invoice_id: payment?.payment_invoice_id || null,
                customer_card_detail: { customerName, cardNumber, approvalCode },
                ...timestamp
            });
        });
    }

    await Promise.all(promises);

    return data;
}

