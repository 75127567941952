export const PRINTER_MODELS = [
  {
      "id": 1,
      "name": "default",
      "created_at": "2022-11-16T00:55:05.000000Z",
      "updated_at": "2022-11-16T00:55:05.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "EPSON"
  },
  {
      "id": 2,
      "name": "EPSON TM-T82X",
      "created_at": "2022-11-16T00:55:05.000000Z",
      "updated_at": "2022-11-16T00:55:05.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "EPSON_PYTHON"
  },
  {
      "id": 3,
      "name": "EPSON TM-U220B",
      "created_at": "2022-11-16T00:55:05.000000Z",
      "updated_at": "2022-11-16T00:55:05.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "EPSON"
  },
  {
      "id": 4,
      "name": "STAR",
      "created_at": "2022-11-16T00:55:05.000000Z",
      "updated_at": "2022-11-16T00:55:05.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "STAR"
  },
  {
      "id": 5,
      "name": "IMIN",
      "created_at": "2022-12-12T02:50:42.000000Z",
      "updated_at": "2022-12-12T02:50:44.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "IMIN"
  },
  {
      "id": 6,
      "name": "XPrinter Label",
      "created_at": "2023-01-09T09:11:10.000000Z",
      "updated_at": "2023-01-09T09:11:10.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "XP_LABEL_PYTHON"
  },
  {
      "id": 7,
      "name": "Birch Label",
      "created_at": "2023-01-09T09:11:10.000000Z",
      "updated_at": "2023-01-09T09:11:10.000000Z",
      "api_host": "127.0.0.1",
      "api_port": "5000",
      "printer_method": "BIRCH_LABEL_PYTHON"
  }
];

export const PRINTER_EPSON_T82X = PRINTER_MODELS[1];
export const PRINTER_EPSON_U220B = PRINTER_MODELS[2];
