import axios from "@/spa/plugins/axios";

export const searchMewsCustomer = (name) => {
    const headers = { "Content-Type": "application/json" };

    return axios.post(route('mews.customer.search'),
        {name: name},
        { headers }
    );
}

export const addReservationProduct = (payload) => {
    const headers = { "Content-Type": "application/json" };

    return axios.post(route('mews.reservation.addProduct'),
		payload,
		{ headers }
    )
}

export const getAllReservations = (payload) => {
    const headers = { "Content-Type": "application/json" };

    return axios.post(route('mews.reservation.getAll'), payload, { headers });
}

export default {
    searchMewsCustomer,
    addReservationProduct,
    getAllReservations
}