<template>
  <BaseModal
    v-model="isOpen"
    min-width="440"
    min-height="150"
    full-width-actions
    :click-to-close="false"
    @confirm="$emit('confirmed')"
    @cancel="$emit('cancelled')"
    :confirm-label="confirmLabel"
  >
    <div class="message" v-if="message">{{ message }}</div>
  </BaseModal>
</template>

<script>
``;
import BaseModal from "@/spa/components/modals/BaseModal";

export default {
  name: "ConfirmMessageModal",

  components: { BaseModal },

  props: {
    message: {
      type: String,
    },

    confirmLabel: {
      type: String,
      default: "Confirm",
    },
  },
};
</script>

<style scoped>
.message {
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
</style>
