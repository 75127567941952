import { nextTick } from 'vue';

export function setupErrorHandler(app) {
    if (process.env.MIX_ERROR_HANDLER == 'true') {
        app.config.errorHandler = function (err, vm, info) {
            nextTick(() => {
                const payload = {
                    message: err.message,
                    name: err.name,
                    stack: err.stack,
                    info,
                    url: window.location.href,
                };
                window.axios.post('/api/log', payload)
                .then(() => {
                    // response no content
                })
                .catch(error => {
                    console.error('Error dispatching error log:', error);
                });
                console.error(err, vm, info);
            });
        };
    }
}
