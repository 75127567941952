<template>
    <base-modal
        min-width="0"
        max-width="800"
        min-height="100px"
        width="100%"
        v-bind="$attrs"
        @confirm="applyDiscount"
        >
        <template v-slot:title>Item Discounts</template>

        <hr />

        <div v-if="item">
            <div class="d-flex justify-content-between align-items-center">
                <div class="item-details">
                    <h2 class="item-name" v-text="item.product.product_name" />
                </div>
                <div class="item-price">
                    ₱{{ this.$filters.formatPrice(getTotalItemRawPrice(item.quantity)) }}
                </div>
            </div>
            <h4 class="item-modifiers" v-text="modifiersString" />
        </div>

        <div class="row discount-item-box mx-0">
            <div class="col-lg-6 order-lg-2 col-12 order-2">
                <div class="row discount-items-list">
                    <div class="col-md-12">
                        <label>Select a discount type</label>
                    </div>
                        <div class="col-lg-6 col-md-12 my-1" v-for="(discount, key) in discounts">
                            <label class="forced-modifier-box my-1 d-flex align-items-start d-lg-block" :for="'line-discount-' +key">
                                    <input type="radio" class="mr-1" name="line-discount" v-model="discountId" :value="discount.id" :id="'line-discount-' +key" @click="checkDiscountId($event)"/>
                                    <div class="forced-moditem-content disctype-box d-flex flex-column justify-content-between">
                                        <div class="d-flex align-items-center d-lg-block">
                                            <p class="m-0" id="discount-rate" v-if="discount['discount_name'] == 'Open Discount'"></p>
                                            <p class="m-0 pr-1" id="discount-rate" v-else>{{ getDiscountAmount(discount) }}</p>
                                            <p class="m-0">{{ discount['discount_name'] }}</p>
                                        </div>
                                        <div class="max-discount-amount mt-2" v-if="discount['max_discount_amount']">Max Discount is {{ discount['max_discount_amount'] }}</div>
                                    </div>
                            </label>
                        </div>
                </div>
            </div>
            <div class="col-lg-6 order-lg-2 col-12 order-1">
                <div class="row my-3">
                    <div class="col-md-12">
                        <div class="row">
                            <h4>Quantity:</h4>
                        </div>
                    </div>
                    <div class="col-md-12" id="inline-disc-qty">
                        <div class="row">
                            <input type="number" name="inline-qty-input" readonly class="pax-form" min="1" max="900" v-model="discountPax">
                            <div class="pax-qty-btn">
                                <div class="min-qty" @click="subtractDiscPax">-</div>
                                <div class="plus-qty" @click="addDiscPax">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 px-0" id="disc-summary-section">
                        <form>
                            <div class="form-group d-flex">
                                <label for="disc-percent" id="discount-label">Percentage:</label>
                                <p id="disc-percent">{{ this.$filters.formatPrice(selectedDiscount.discountValue) }}%</p>
                            </div>
                        </form>
                        <form>
                            <div class="form-group d-flex">
                                <label for="disc-value">Value:</label>
                                <p id="disc-value">₱ {{ this.$filters.formatPrice(selectedDiscount.discountAmount) }}</p>
                            </div>
                        </form>
                        <form>
                            <div class="form-group d-flex">
                                <label for="net-sales">Net Sales:</label>
                                <p id="net-sales">₱ {{ this.$filters.formatPrice(selectedDiscount.netSales) }}</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div id="open-discount-container" class="modal-body">
            <div class="open-discount-div row" id="line-open-discount-div"
                v-if="selectedDiscount.discount_name == 'Open Discount'">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="line-open-discount-name">Discount Name</label>
                        <input type="text" name="line-open-discount-name" id="open-discount-name" class="form-control"
                            v-model="openDiscountName">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="open-discount-option">Apply By</label>
                        <select class="form-control" name="open-discount-option" id="open-discount-option"
                            v-model="openDiscOpt">
                            <option value=""></option>
                            <option value="amount">By Amount</option>
                            <option value="percentage">By Percentage</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group" id="amount-div" v-if="openDiscOpt == 'amount'">
                        <i class="fa fa-money-bill"></i>
                        <label for="line-open-discount-amount">Amount</label>
                        <input type="number" name="line-open-discount-amount" id="line-open-discount-amount"
                            class="form-control" min="0" v-model="discountAmount" @blur="validateOpenDiscountAmount">
                    </div>
                    <div class="form-group" id="percentage-div" v-if="openDiscOpt == 'percentage'">
                        <i class="fa fa-percent"></i>
                        <label for="line-open-discount-rate">Rate</label>
                        <input type="number" name="line-open-discount-rate" id="line-open-discount-rate"
                            class="form-control" max="100" min="0" v-model="discountRate">
                    </div>
                </div>
            </div>
        </div>
        <div id="line-customer-input-container" class="modal-body" v-if="selectedDiscount.has_customer_details == 1">
            <div id="input-error" class="text-center" style="display: none">Fields cannot be blank</div>
            <div class="customer-inputs row" id="customer-1">
                <div class="col-md-12">
                    <p class="custsenior-head">Customer 1:</p>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <i class="fa fa-user-alt"></i>
                        <label class="customer-info-label" for="customer-fname">First Name</label>
                        <input type="text" name="customer-fname" class="form-control customer-fname customer-info" v-model="customerFirstName">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <i class="fa fa-user-alt"></i>
                        <label class="customer-info-label" for="customer-lname">Last Name</label>
                        <input type="text" name="customer-lname" class="form-control customer-lname customer-info" v-model="customerLastName">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <i class="fa fa-id-card"></i>
                        <label class="customer-info-label" for="customer-card-num">ID/Reference No.</label>
                        <input type="number" name="customer-card-num" class="form-control customer-card-num customer-info" v-model="customerIdReferenceNumber">
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal.vue';

import { get, some, isEmpty, isNil } from 'lodash';
import { LineItemMixin } from '@/spa/components/mixins/ComputationMixin';
import { ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE } from "@/spa/constants";

export default {
    components: {
        BaseModal,
    },

    mixins: [
        LineItemMixin,
    ],

    props: {
        discounts: {
            type: [Object, null],
            required: true,
        },
        approvers: {
            type: [Object, null],
            required: true,
        },
    },

    data() {
        return {
            discountPax: 1,
            discountId: '',
            openDiscOpt: '',
            discountRate: 0,
            discountAmount: 0,
            openDiscountName: '',
            customerFirstName: '',
            customerLastName: '',
            customerIdReferenceNumber: '',
            discountLimitReached: false,
        }
    },

    watch: {
        openDiscOpt(newValue) {
            switch (newValue) {
                case 'percentage':
                    this.discountAmount = 0;
                    break;
                case 'amount':
                    this.discountRate = 0;
                    break;
            }
        },

        item: {
            handler() {
                this.discountId = get(this.item, 'discount.id', '');
                this.discountPax = get(this.item, 'discount.discountPax', this.item.quantity);
            },
        },

        discountLimitReached(newValue) {
            if (newValue && this.$attrs.modelValue) {
                this.$swal.warning(`You reach the max discount possible and the POS will get the ceiling amount you set with this discounts. Which is ${this.selectedDiscount.max_discount_amount}`);
            }
        }
    },

    computed: {
        itemTotalPrice() {
            return (this.item) ? this.$filters.formatPrice(this.item.total) : 0;
        },

        modifiersString() {
            if (!this.item) return '';

            return [
                ...(this.item.product.forcedMods || []),
                ...(this.item.product.unforcedMods || []),
            ].map(mod => `${mod.quantity || 1} ${mod.modifier_name}`).join(', ');
        },

        selectedDiscount() {
            if (!this.item) return {};
            let itemQuantity = this.item ? this.item.quantity : 0;
            let totalItemRawPrice = this.item ? this.getTotalItemRawPrice(itemQuantity) : this.getTotalItemRawPrice(1);
            let vatToLess = 0;
            let selectedDiscount = null;

            selectedDiscount = this.discounts.find(d => d.id == this.discountId) || {};

            let vatExc = ['Senior Citizen Discount', 'Diplomat Discount', 'PWD'];

            //check vat exempt
            if (vatExc.includes(selectedDiscount.discount_name)) {
                vatToLess = this.vat * this.discountPax;
            }

            let discountRate = selectedDiscount.discount_rate || 0;

            discountRate = this.discountRate > 0
                ? this.discountRate / 100
                : discountRate;

            let net = this.item ? (this.itemRawPrice / (this.totalPercentage || 1)) : 0;
            let lessVatAmt = (net * this.discountPax);
            let discountAmount = 0;

            if (isNil(selectedDiscount.discount_rate) && selectedDiscount.discount_name !== 'Open Discount') {
                discountAmount = selectedDiscount.discount_amount
            } else {
                if (selectedDiscount.is_vat_based != 1) {
                    discountAmount = this.itemRawPrice * discountRate * this.discountPax;
                } else {
                    discountAmount = net * discountRate * this.discountPax;
                }
            }

            if (selectedDiscount.max_discount_amount) {

                const accumulatedDiscount = this.getActiveOrdersAccumulatedDiscount(selectedDiscount, this.item._id)
                this.discountLimitReached = this.hasReachedMaxDiscountAmount(accumulatedDiscount + discountAmount, selectedDiscount.max_discount_amount);

                if (this.discountLimitReached) {
                    discountAmount = this.getRemainingOrCurrentDiscount(selectedDiscount, accumulatedDiscount);
                }
            }

            let isDiscountByAmount = false;

            if (this.discountAmount > 0) {
                discountAmount = this.discountAmount;
                discountRate = discountAmount / totalItemRawPrice;
                isDiscountByAmount = true;
            }

            const netSales = selectedDiscount.is_vat_based == 1
                ? lessVatAmt - discountAmount
                : lessVatAmt - (discountAmount / this.item.activeVat.tax_rate);

            let returnObj = {
                ...selectedDiscount,
                discountValue: discountRate * 100,
                netSales,
                discountPax: this.discountPax,
                discountAmount,
                discountRate,
                isDiscountByAmount,
            };

            if (selectedDiscount.has_customer_details == 1) {
                returnObj.firstName = get(this.item, 'discount.firstName', this.customerFirstName);
                returnObj.lastName = get(this.item, 'discount.lastName', this.customerLastName);
                returnObj.idReferenceNumber = get(this.item, 'discount.idReferenceNumber', this.customerIdReferenceNumber);
            }

            return returnObj;
        },

        isOpenDiscount() {
            return this.selectedDiscount && this.selectedDiscount.discount_name == 'Open Discount';
        },

        openDiscountHasCompleteFields() {
            if (this.openDiscountName && this.openDiscOpt === 'amount' && this.discountAmount) {
                return true;
            }

            if (this.openDiscountName && this.openDiscOpt === 'percentage' && this.discountRate) {
                return true;
            }

            return false;
        },
    },

    methods: {
        resetCustomerInfoFields() {
            this.customerFirstName = '';
            this.customerLastName = '';
            this.customerIdReferenceNumber = '';
        },
        async applyDiscount() {
            if (this.selectedDiscount.has_customer_details == 1) {
                if (some([this.customerFirstName, this.customerLastName, this.customerIdReferenceNumber], item => isEmpty(item.toString()))) {
                    this.$swal.warning('Please fill in all customer details');
                    return;
                }
            }


            let response = null;
            if (this.selectedDiscount.passcode_required == 1) {
                response = await this.$openApproverModal();
                if (!response.success) {
                    if (response.cancelled) {
                        this.$swal.warning('Discount authorization cancelled');
                    }

                    return;
                };
            }


            if (this.isOpenDiscount) {
                if (!this.openDiscountHasCompleteFields) {
                    this.$swal.warning('All discount details are required');

                    return;
                }
            }

            this.discountLimitReached = false;

            this.$emit('discountSelected', {
                ...this.selectedDiscount,
                firstName: this.customerFirstName,
                lastName: this.customerLastName,
                idReferenceNumber: this.customerIdReferenceNumber,
                approver: response ? response.approver : null,
            });

            this.resetCustomerInfoFields();
        },

        validateOpenDiscountAmount() {
            if (this.selectedDiscount.netSales <= 0) {
                this.$swal.warning('Amount cannot exceed total net sales');
                this.discountAmount = 0;
            }
        },

        getDiscountAmount(discount) {
            return isNil(discount.discount_rate) ? '₱' + discount.discount_amount.toFixed(2) : discount.discount_rate * 100 + '%';
        },

        subtractDiscPax() {
            if (this.discountPax > 1) {
                this.discountPax--;
            }
        },

        addDiscPax() {
            if (this.discountPax < this.item.quantity) {
                this.discountPax++;
            }
        },

        checkDiscountId(event) {
            //check if discountid is equal to clicked discount id, if yes remove discount
            if (this.discountId == event.target.value) {
                this.discountId = '';
            }

            this.showMaxAmountWarning = true;
        },

        hasReachedMaxDiscountAmount(discountAmount, maxDiscountAmount) {
            if (discountAmount > maxDiscountAmount) {
                return true;
            }

            return false;
        },

        // sum all active order discount
        getActiveOrdersAccumulatedDiscount(discount, selectedId) {
            if (isEmpty(discount)) return;

            return this.activeOrder.orders.reduce((total, order) => {
                if (order?.discount?.id === discount.id && selectedId !== order._id) {
                    return total += order.discount.discountAmount
                }

                return total
            }, 0)
        },

        // return current order discount if set or return the remaining from max discount
        getRemainingOrCurrentDiscount(discount, accumulatedDiscount) {
            const activeOrder = this.activeOrder.orders.find((order) => order._id === this.item._id && order?.discount?.id === discount.id)

            if (activeOrder) {
                return activeOrder.discount.discountAmount
            }

            const discountAmt = discount.max_discount_amount;
            if (discount?.ceiling_discount_per_line_item) {
                return discountAmt;
            }

            if (ENABLE_CARAMIA_VAT_EXEMPT_OVERRIDE && discountAmt) {
                const taxRate = this.item.activeVat.tax_rate;
                const discountRate = discount.discount_rate;

                return (discountAmt / taxRate) * discountRate;
            }

            return discountAmt - accumulatedDiscount;
        }
    },
}
</script>

<style scoped>
.item-name {
    font-size: 2rem;
    color: #1b75bb;
    font-weight: bold;
}

.item-price {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Exo', sans-serif;
    text-align: right;
}

.forced-moditem-content p {
    position: relative;
}

.max-discount-amount {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
}

.forced-moditem-content {
    height: 140px;
}

.customer-info {
    text-indent: 15px;
}

.customer-info-label {
    margin: 4px 0px !important
}
</style>
