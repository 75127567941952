import { mapGetters } from 'vuex';
import { PERMISSIONS } from '@/spa/constants';
import {dbService} from "@/spa/services/db-service";

export default {
    computed: {
        ...mapGetters('user', ['permissions']),

        PERMISSIONS() {
            return PERMISSIONS;
        },

        isMultiTerminal() {
            const terminal = JSON.parse(sessionStorage.getItem('terminal'));
            return terminal?.is_shared_terminal;
        },
    },

    async mounted() {
        await dbService.openDB('mosaic-posDB', 10)
    },

    methods: {
        $can(permission) {
            if (!(permission in PERMISSIONS)) {
                throw new Error(`Permission '${permission}' is not defined.`);
            }

            return this.permissions[permission];
        },
    },

    async beforeDestroy() {
        await dbService.closeDB()
    }
}
