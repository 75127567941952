<template>
    <base-modal
        v-bind="$attrs"
        min-width="100px"
        min-height="100px"
        full-width-actions
        :has-cancel="false"
        :click-to-close="false"
        @confirm="savePax"
        :confirmLabel="'Submit'"
        :confirmDisabled="!isPaxValueValid"
        >
        <template v-slot:title>Enter number of guests</template>

        <div class="value-section">
            <button
                class="btn btn-primary plus-minus-button"
                @click.stop="subtract"
                :disabled="pax <= minPax"
            >
                <i class="fa fa-fw fa-minus" />
            </button>
            <div class="pax-value" v-text="pax" />
            <button
                class="btn btn-primary plus-minus-button"
                @click.stop="add"
                :disabled="pax >= maxPax"
            >
                <i class="fa fa-fw fa-plus" />
            </button>
        </div>

        <div class="pax-keypad">
            <template v-for="y in [0, 1, 2]" :key="`keypad-outer-${x}`">
                <template
                    v-for="x in [1, 2, 3]"
                    :key="`keypad-${x + (y * 3)}`"
                    >
                    <button
                        class="btn btn-outline-primary"
                        @click="append(x + (y * 3))"
                        >
                        {{ x + (y * 3) }}
                    </button>
                </template>
            </template>

            <button
                class="btn btn-outline-primary util-btn"
                @click="clearPax"
                >
                {{ clearLabel }}
            </button>
            <button
                class="btn btn-outline-primary"
                @click="append(0)"
                >
                0
            </button>
            <button
                class="btn btn-outline-primary util-btn"
                @click="unappend"
                >
                <i class="fas fa-backspace"></i>
            </button>
        </div>

    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';

export default {
    name: 'PaxModal',

    components: {
        BaseModal,
    },

    props: {
        currentPax: {
            type: Number,
            default: 0,
        },
        minPax: {
            type: Number,
            default: 1,
        },
        maxPax: {
            type: Number,
            default: 10000,
        },
    },

    data() {
        return {
            pax: '0',
        };
    },

    computed: {
        isCurrentPaxGreaterThanMinPax() {
            return this.currentPax >= this.minPax;
        },
        clearLabel() {
            return this.isCurrentPaxGreaterThanMinPax ? 'Reset' : 'Clear';
        },
        isPaxValueValid() {
            const pax = Number(this.pax);

            return pax >= this.minPax && pax <= this.maxPax;
        },
    },

    watch: {
        currentPax: {
            handler(value) {
                this.pax = value;
            },
            immediate: true,
        },
    },

    methods: {
        add() {
            if (Number(this.pax) >= this.maxPax) return;
            this.pax = String(Number(this.pax) + 1);
        },

        subtract() {
            if (this.pax <= this.minPax) return;
            this.pax = String(Number(this.pax) - 1);
        },

        append(value) {
            if (Number(this.pax) >= this.maxPax) return;
            this.pax = this.pax == '0' ? value : `${this.pax}${value}`;
            if (Number(this.pax) >= this.maxPax) this.pax = this.maxPax.toString();
        },

        unappend() {
            const paxArray = this.pax.split('');
            paxArray.pop();
            this.pax = paxArray.join('') || '0';
        },

        clearPax() {
            this.pax = String(this.currentPax);
        },

        savePax() {
            if (Number(this.pax) < this.minPax) {
                this.$swal.warning('Pax cannot be less than ' + this.minPax);
                return;
            }

            this.$emit('paxSelected', Number(this.pax));
        },
    },
}
</script>

<style scoped>
.pax-keypad {
    width: 316px;
    height: 294px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 16px;
    column-gap: 16px;
}

.pax-keypad .btn {
    border-radius: 10px !important;
    box-shadow: none !important;
    font-size: 1.2rem;
    font-weight: bold;
    border-color: #DFECFF;
}

.pax-keypad .util-btn {
    font-size: 16px;
}

.pax-keypad .util-btn:not(:hover) {
    color: #5B6671;
}

.pax-value {
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    min-width: 2rem;
    text-align: center;
}

.value-section {
    background: white;
    height: 60px;
    border-radius: 5px;
    border: 2px solid #DFECFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.value-section .pax-value {
    color: #0052CC;
    font-size: 20px;
    margin: 0 24px;
}

.value-section .btn.btn-primary.plus-minus-button {
    width: 46px;
    height: 46px;
    border-radius: 50% !important; /* override Bootstrap border radius */
}
</style>