<template>
  <div class="m-blocker d-flex align-items-center justify-content-center" v-if="isForceSyncing" >
    <div class="d-flex align-items-center">
      <span class="mr-2">Please wait.. This might take a while</span>
      <div class="spinner-border text-light spinner-border-sm" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MForceSyncBlocker",

  computed: {
    ...mapState("global", ["isForceSyncing"]),
  },
};
</script>

<style scoped>
.m-blocker {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.m-blocker div {
  font-size: 18px;
  color: white;
}
</style>
