<template>
  <base-modal
    v-bind="$attrs"
    min-width="600px"
    min-height="400px"
    :has-confirm="true"
    cancel-color="primary"
    cancel-label="Close"
    confirm-color="danger"
    confirm-label="Update"
    @confirm="save()"
    @before-open="fetchPrices"
  >
    <template v-slot:title>
      Product Name: {{ modifier?.product_name || '' }} <br />
      <small>Modifier: {{modifier?.mod_group_name || ''}} - {{modifier?.modifier_name || ''}}</small>
    </template>

    <hr />
    <v-data-table
      :headers="headers"
      :items="items"
      hide-footer
      show-index
    >
      <template #item-service_type_name="item">
        <strong class="ml-1">
          {{ getServiceType(item.service_type_id) }}
        </strong>
      </template>

      <template #item-channel_name="item">
        {{ item.channel_name }}
      </template>

      <template #item-prod_price="item">
        <select v-model="item.is_available" @change="updateAvailability(item)" class="form-control">
          <option :value="1" v-text="'Available'" />
          <option :value="0" v-text="'Unavailable'" />
        </select>
      </template>
    </v-data-table>
  </base-modal>
</template>

<script>
import BaseModal from '../BaseModal.vue';
import VDataTable from 'vue3-easy-data-table';
import {mapState} from "vuex";
import {cloneDeep} from "lodash";

export default {
  components: {
    BaseModal,
    VDataTable,
  },

  props: {
    modifier: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Service Types', value: 'service_type_name', sortable: false },
        { text: 'Service Channel', value: 'channel_name', sortable: false },
        { text: 'Availability', value: 'prod_price', sortable: false },
      ],
      items: [],
    };
  },

  computed: {
    ...mapState("sqlite", ["initialData"]),
  },

  methods: {
    async fetchPrices() {
      const {modifier_detail_id} = this.modifier;
      const mpb = new ProductModifierPriceBridge();
      this.items = await mpb.getRows({
        where: {
          modifier_detail_id
        }
      });
    },
    getServiceType(id) {
      const brands = this.initialData?.brands[0].service_types || [];
      return brands
        .find(item => item.id === id)
        ?.service_name || '';
    },
    updateAvailability(item) {
      const index = item.index - 1;
      this.items[index].is_available = item.is_available;
      this.items[index].is_updated = true;
    },
    async save() {
      const result = await this.$openApproverModal();
      if (!result.success) return;

      const updatedItems = cloneDeep(this.items)
          .filter(item => item?.is_updated);

      this.$emit("saveAvailability", updatedItems);
    },
  }
};
</script>
