<script>
import { printLabel, getPrinterData } from "@/spa/services/printer-service";
import moment from "moment";

const LABEL_PRINTERS = ['XP_LABEL_PYTHON', 'BIRCH_LABEL_PYTHON'];

export default {
  methods: {
    async processPrintLabel(order, kot) {
      let printModelIds = [];
      let printerData = await getPrinterData();
      let printerDetails = printerData.printer_details;
      let printModels = printerData.printer_models;

      printModels.forEach(function (value) {
        if (LABEL_PRINTERS.includes(value.printer_method)) printModelIds.push(value.id);
      });

      if (printModelIds.length > 0) {
        const labelPrinters = await printerDetails.filter((obj) => {
          return printModelIds.includes(obj.printer_model_id) && obj?.can_print_label_sticker;
        });

        labelPrinters.forEach(printer => {
          const currentLabelPrinter = printModels.find(obj => obj.id === printer.printer_model_id);
          const printerModel = currentLabelPrinter?.name ?? null;
          const printData = {
            print_type: "Label",
            zpl: this.getLabelPrintDetails(order, kot, printer.label_sticker_template, printerModel),
          };

          printLabel(printData, [printer], printModels);
        });
      }
    },

    getLabelPrintDetails(orderDetail, kotNum, labelTemplate, printerModel = null) {
      const orders = orderDetail.orders;
      const moreDetail = orderDetail.orderDetail || "";
      const isDelivery = orderDetail.serviceType == 'Delivery';
      const customerName = orderDetail?.orderCustomerName || "";
      const refNumber = isDelivery ? moreDetail : kotNum;
      const serviceName = isDelivery ? orderDetail.channelName : orderDetail.serviceType;
      let totalItems = 0;
      let itemNo = 0;

      const printZplParts = [];

      orders.forEach((order) => {
        if (!order.product.label_printing) {
          return;
        }

        if (order && order.kot == kotNum) {
          const product = order.product;
          const forcedMods = product.forcedMods || [];
          const unforcedMods = product.unforcedMods || [];
          const combineMods = [...forcedMods, ...unforcedMods];

          const qty = order.quantity;

          for (let i = 0; i < qty; i++) {
            itemNo++;
            totalItems++;

            printZplParts.push(this.getPrinterLabelTemplate({
              kotNum,
              moreDetail,
              itemNo,
              productName: product.product_name,
              serviceName,
              customerName,
              refNumber,
              combineMods,
              printerModel
            }, labelTemplate));
          }
        }
      });

      return printZplParts.join("").replace(/___TOTAL_ITEMS___/g, totalItems.toString());
    },

    groupModsIntoArrayByGivenSize({ modsArray, groupSize = 2 } = {}) {
      // Sample output for groupSize = 2:
      // groupedArray = [ [modifier1, modifier2], [modifier3, modifier4], [modifier5] ] 

      // Sample output for groupSize = 3:
      // groupedArray = [ [modifier1, modifier2, modifier3], [modifier4, modifier5, modifier6], [modifier7] ]
      const groupedArray = [];
      for (let i = 0; i < modsArray.length; i += groupSize) {
        groupedArray.push(modsArray.slice(i, i + groupSize));
      }

      return groupedArray;
    },

    getLabelTemplatePUC(data, modifiersData, verticalSpacingConfig = {}) {
      const firstMod = modifiersData[0]?.modifier_name || "";
      const refNumber = data.refNumber?.toString()?.substr(0, 15);
      const serviceName = data.serviceName.replace(/QSR /g, '');
      const customerName = data.customerName?.substr(0, 18)?.toUpperCase();
      const productName = this.breakString(`${data.productName} - ${firstMod}`, 26, 2, verticalSpacingConfig.productName);
      const itemNo = data.itemNo;
      
      const modifierString = this.getModifiers(modifiersData.slice(1), verticalSpacingConfig.modifiers, 18);

      return `
        ^FT6,${verticalSpacingConfig.refNumber}^A0,20,20^FH\^FD${refNumber}^FS
        ^FT180,${verticalSpacingConfig.serviceName}^A0,20,20^FH\^FD${serviceName}^FS
        ^FT6,${verticalSpacingConfig.customerName}^A0N,32,28^FH\^FD${customerName}^FS
        ${productName.join('\n')}
        ${modifierString}
        ^FT6,${verticalSpacingConfig.itemNo}^A0N,20,20^FH\^FD${itemNo} of ___TOTAL_ITEMS___^FS
        ^FT105,${verticalSpacingConfig.date}^A0,18,17^FH\^FD${moment().format("MMMM D, YYYY hh:mmA")}^FS`;
    },

    getLabelTemplateChatime(data, modifiersData, verticalSpacingConfig = {}) {
      const firstMod = modifiersData[0]?.modifier_name || "";
      const { itemNo, serviceName, kotNum } = data;
      const customerName = data.customerName || "None";
      const productName = this.breakString(`${data.productName} - (${firstMod?.charAt(0)?.toUpperCase()})`, 26, 2, verticalSpacingConfig.secondLine);
      const groupedModifiers = this.groupModsIntoArrayByGivenSize({ modsArray: modifiersData.slice(1), groupSize: 2 })
      const modifierString = this.getModifiers(groupedModifiers.slice(0), verticalSpacingConfig.modifiers, 18, 22, 22, 4, true);

      const firstLine = `${kotNum}/${serviceName.replace(/QSR /g, '')}/${customerName}`.substr(0, 24);
      const secondLine = productName.join('\n');

      return `
        ^FT6,${verticalSpacingConfig.firstLine}^A0,22,22^FH^FD${firstLine}^FS
        ^FT6,110^A0,22,22^FH^FD${secondLine}^FS
        ${modifierString}
        ^FT6,${verticalSpacingConfig.lastLine}^A0,22,22^FH^FD${moment().format("YYYY/MM/DD")} ............... ${itemNo}/___TOTAL_ITEMS___^FS`;
    },

    getLabelTemplateStandard(data, modifiersData, verticalSpacingConfig = {}) {
      let modifierString = "";
      const firstMod = modifiersData[0]?.modifier_name || "";
      const { kotNum, moreDetail, itemNo, productName } = data;

      if (modifiersData.length > 0) {
        const chunkSize = 3;
        let chunkMod = modifiersData.slice(1).reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }

          const modifierDisplay = item.quantity > 1 ? `(${item.quantity})` : '';
          resultArray[chunkIndex].push(`${modifierDisplay}${item.modifier_name}`);

          return resultArray;
        }, []);

        // Loop through each modifier and add new line to the modifier string
        // 'placeNumber' is the vertical position of the modifier string from the top
        chunkMod.forEach((chunk) => {
          modifierString += `^FT6,${verticalSpacingConfig.placeNumber}^A0N,30,16^FH\^FD${chunk.toString()}^FS`;
          verticalSpacingConfig.placeNumber += 30;
        });
      }

      // For the first modifier, we need to wrap it in parenthesis
      // The remaining modifiers are displayed in a separate line
      return `
        ^FT6,${verticalSpacingConfig.kotAndMoreDetail}^A0N,26,18^FH\^FD${kotNum} // ${moreDetail}^FS
        ^FT250,${verticalSpacingConfig.itemNo}^A0N,26,18^FH\^FD${itemNo}/___TOTAL_ITEMS___^FS
        ^FT6,${verticalSpacingConfig.product}^A0N,32,21^FH^FD${productName} ${firstMod ? `(${firstMod})` : ''}^FS
        ${modifierString}
        ^FT221,${verticalSpacingConfig.date}^A0N,15,14^FH\^FD${moment().format("MMM-DD-YY")}^FS`;
    },

    getVerticalSpacingConfig({ printerModel, labelTemplate }) {
      let verticalSpacingConfig = {};

      const templateConfigs = {
        PUC: {
          refNumber: 60,
          serviceName: 60,
          customerName: 98,
          productName: 120,
          modifiers: 160,
          itemNo: 228,
          date: 226,
        },
        Chatime: {
          firstLine: 80,
          secondLine: 120,
          modifiers: 160,
          lastLine: 228,
        },
        Standard: {
          kotAndMoreDetail: 43,
          itemNo: 43,
          product: 105,
          date: 200,
          placeNumber: 140,
        },
      };

      // POSFE-1031 - Need to modify the vertical spacing for XPrinter XP-420B Label Printer
      // To adjust vertical spacing per template and printer model, just add new properties
      const verticalAdjustmentConfig = {
        PUC: {
          "XPrinter XP-420B Label": -40,
        },
        Chatime: {
          "XPrinter XP-420B Label": -40,
        },
        Standard: {
          "XPrinter XP-420B Label": -50,
        },
      }

      if (labelTemplate in templateConfigs) {
        // Loop through the keys for the specific label template
        for (const key in templateConfigs[labelTemplate]) {
          // Apply adjustments based on the label template and printer model
          verticalSpacingConfig[key] = templateConfigs[labelTemplate][key] + (verticalAdjustmentConfig[labelTemplate][printerModel] ?? 0);
        }
      }

      return verticalSpacingConfig;
    },

    getPrinterLabelTemplate(data, labelTemplate) {
      const verticalSpacingConfig = this.getVerticalSpacingConfig({ printerModel: data.printerModel, labelTemplate });
      const modifiersData = data.combineMods || [];
      let selectedTemplate;

      switch (labelTemplate) {
        case 'PUC':
          selectedTemplate = this.getLabelTemplatePUC(data, modifiersData, verticalSpacingConfig);
          break;

        case 'Chatime':
          selectedTemplate = this.getLabelTemplateChatime(data, modifiersData, verticalSpacingConfig);
          break;

        case 'Standard':
          selectedTemplate = this.getLabelTemplateStandard(data, modifiersData, verticalSpacingConfig);
          break;
      }

      return `^XA
            ^MMT
            ^PW320
            ^LL0240
            ^LS0
            ^CI28
            ${selectedTemplate}
            ^PQ1,0,1,Y^XZ`;
    },

    getModifiers(combineMods, placeNumber = 160, padding = 18, x = 18, y = 21, numGroups = 3, itemCnt = false) {
      let string = "";
      const splitArrayIntoGroups = (arr, numGroups) => {
        const result = Array.from({ length: numGroups }, () => []);
        arr.forEach((item, index) => {
          if (item.length) {
            // EPOS-989 - For Chatime label: group modifiers into pairs and divide it by '/',
            // e.g. "50% sugar/less ice"
            const formattedModifiers = item.map(modifier => {
              const modifierCount = (itemCnt && modifier.quantity > 1) ? `(${modifier.quantity})` : '';
              return `${modifierCount}${modifier.modifier_name}`;
            });

            result[index % numGroups].push(formattedModifiers.join('/'));
          } else {
            const groupIndex = index % numGroups;
            const modifierCount = (itemCnt && item.quantity > 1) ? `(${item.quantity})` : '';
            result[groupIndex].push(`${modifierCount}${item.modifier_name}`);
          }
        });
        return result;
      }

      const groups = splitArrayIntoGroups(combineMods, numGroups);

      groups.filter(group => group.length > 0).forEach(group => {
        string += `^FT6,${placeNumber}^A0N,${x},${y}^FH\^FD${group.join(', ')}^FS`;
        placeNumber += padding;
      });

      return string;
    },

    breakString(inputString, maxLineLength, maxRow = 2, placeNumber = 120, padding = 20) {
      const lines = [];
      let remaining = inputString;

      for (let i = 0; i < maxRow && remaining.length > 0; i++) {
        if (i === maxRow - 1 && remaining.length > maxLineLength) {
          const line = `^FT6,${placeNumber}^A0,20,20^FH\^FD${remaining.slice(0, maxLineLength)}...^FS`;
          lines.push(line);
          break;
        } else {
          const line = `^FT6,${placeNumber}^A0,20,20^FH\^FD${remaining.slice(0, maxLineLength)}^FS`;
          lines.push(line);
          remaining = remaining.slice(maxLineLength);
        }
        placeNumber += padding;
      }

      return lines;
    }
  },
};
</script>
