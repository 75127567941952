import {OFFLOAD, SERIES_TYPES} from "@/spa/constants";
import axios from '@/spa/plugins/axios';
import { MultiterminalUtilityBridge, SeriesUtilityBridge } from "../../mobile_bridge/multiterminal/bridges";

const payload_series_map = {
    [SERIES_TYPES.KOT_NUM]: 'kot_num',
    [SERIES_TYPES.BILL_NUM]: 'bill_num',
    [SERIES_TYPES.RECEIPT_NUM]: 'receipt_num',
    [SERIES_TYPES.VOID_BILL_NUM]: 'void_bill_num',
    [SERIES_TYPES.VOID_RECEIPT_NUM]: 'void_receipt_num',
}

const updateSeries = async (locationId, seriesType, value) => {
    if (window.enableOfflineMode) return;

    const headers = {
        'Content-Type': 'application/json',
    };

    const data = { locationId };
    const key = payload_series_map[seriesType];
    data[key] = value;

    try {
        return axios.post(route("orders.update.series.offline"), data, { headers });
    } catch (e) {
        console.error(e);
    }
};

class SeriesService {
    async #setItem(key, value, fromApi = false) {
        value = Number(value);

        const mub = new MultiterminalUtilityBridge();
        const appBasedMultiterminalSupported = !!window.MosaicPosAppSupportedFeatures?.multiterminal && window.ENABLE_APP_BASED_MULTITERMINAL;
        const oldValue = Number(await this.#getItem(key));
        const maxValue = Math.max(value, oldValue);
        const status = appBasedMultiterminalSupported && await mub.getStatus();

        if (appBasedMultiterminalSupported && status?.mode != 'none') {
            const sb = new SeriesUtilityBridge();
            await sb.setSeriesNumber(key, parseInt(maxValue));

            console.log(`setItem: set ${key} => ${maxValue}`);
            return;
        } else if (OFFLOAD.sqliteOffloadMA33) {
            const storage = new ScopedNativeStorage(window.locationId)
            storage.put(key, JSON.stringify(maxValue));

            return;
        }

        localStorage.setItem(`${window.locationId}-${key}`, maxValue);

        if (fromApi) return;
        try {
            await updateSeries(window.locationId, key, value);
        } catch (e) {
            console.error(e);
        }
    }

    async #getItem(key) {
        const mub = new MultiterminalUtilityBridge();
        const appBasedMultiterminalSupported = !!window.MosaicPosAppSupportedFeatures?.multiterminal && window.ENABLE_APP_BASED_MULTITERMINAL;
        const status = appBasedMultiterminalSupported && await mub.getStatus();

        if (appBasedMultiterminalSupported && status?.mode != 'none') {
            const sb = new SeriesUtilityBridge();
            const response = await sb.getSeriesNumber(key);

            console.log(`getItem: received ${key} => ${response.seriesNumber}`);
            return Number(response.seriesNumber);
        } else if (OFFLOAD.sqliteOffloadMA33) {
            const storage = new ScopedNativeStorage(window.locationId)
            return Number(await storage.get(key));
        }

        return JSON.parse(localStorage.getItem(`${window.locationId}-${key}`));
    }

    async #getAndIncrementItem(key) {
        const appBasedMultiterminalSupported = !!window.MosaicPosAppSupportedFeatures?.multiterminal && window.ENABLE_APP_BASED_MULTITERMINAL;
        const mub = new MultiterminalUtilityBridge();
        const status = appBasedMultiterminalSupported && await mub.getStatus();

        if (appBasedMultiterminalSupported && status?.mode != 'none') {
            const sb = new SeriesUtilityBridge();
            const response = await sb.getAndIncrement(key);

            console.log(`getAndIncrementItem: received ${key} => ${response.seriesNumber}`);
            return response.seriesNumber;
        } else {
            const seriesItem = await this.#getItem(key);
            this.#setItem(key, seriesItem + 1);

            return seriesItem;
        }
    }

    setLastKotNum(value = 0) {
        return this.#setItem(SERIES_TYPES.KOT_NUM, value);
    }

    setLastBillNum(value = 0) {
        return this.#setItem(SERIES_TYPES.BILL_NUM, value);
    }

    setLastReceiptNum(value = 0) {
        return this.#setItem(SERIES_TYPES.RECEIPT_NUM, value);
    }

    setLastVoidBillNum(value = 0) {
        return this.#setItem(SERIES_TYPES.VOID_BILL_NUM, value);
    }

    setLastVoidReceiptNum(value = 0) {
        return this.#setItem(SERIES_TYPES.VOID_RECEIPT_NUM, value);
    }

    async getAndIncrementKotNum() {
        return this.#getAndIncrementItem(SERIES_TYPES.KOT_NUM);
    }

    async getAndIncrementBillNum() {
        return this.#getAndIncrementItem(SERIES_TYPES.BILL_NUM);
    }

    async getAndIncrementReceiptNum() {
        return this.#getAndIncrementItem(SERIES_TYPES.RECEIPT_NUM);
    }

    async getAndIncrementVoidBillNum() {
        return this.#getAndIncrementItem(SERIES_TYPES.VOID_BILL_NUM);
    }

    getLastKotNum() {
        return this.#getItem(SERIES_TYPES.KOT_NUM);
    }

    getLastBillNum() {
        return this.#getItem(SERIES_TYPES.BILL_NUM);
    }

    getLastReceiptNum() {
        return this.#getItem(SERIES_TYPES.RECEIPT_NUM);
    }

    getLastVoidBillNum() {
        return this.#getItem(SERIES_TYPES.VOID_BILL_NUM);
    }

    getLastVoidReceiptNum() {
        return this.#getItem(SERIES_TYPES.VOID_RECEIPT_NUM);
    }
}

const seriesService = new SeriesService();
export default seriesService;
