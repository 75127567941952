<template>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      :style="`width: ${percent}%`"
      :aria-valuenow="percent"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {{ percent }}%
    </div>
  </div>
</template>

<script>
export default {
  name: "MProgressBar",

  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
};
</script>


<style scoped>
.progress {
  width: 100%;
}
</style>