<template>
  <base-modal
    v-bind="$attrs"
    @confirm="saveSelectedValues"
    @before-open="reset"
  >
    <template v-slot:title>Customize {{ productName }}</template>

    <div
      v-for="customizedProduct in mappedCustomizedProducts"
      :key="customizedProduct.id"
      class="mt-2"
    >
      <h3 class="product-header">
        Select {{ customizedProduct.title }}
        <span
          :class="{
            complete:
              customizedProduct.totalSelectedCount ===
              customizedProduct.quantity,
          }"
        >
          ({{ customizedProduct.totalSelectedCount }}/{{
            customizedProduct.quantity
          }})
        </span>
      </h3>

      <div class="mods-row d-flex flex-wrap">
        <m-card
          v-for="choice in customizedProduct.choices"
          :key="choice.id"
          ratio="2:1"
          clickable
          @click="updateSelectedCount('add', choice, customizedProduct)"
          :header="choice.name"
        >
          <template v-if="choice.selectedCount">
            <div class="pick-count">{{ choice.selectedCount }}</div>

            <div
              class="minus-count"
              @click.stop="
                updateSelectedCount('minus', choice, customizedProduct)
              "
            >
              <i class="fas fa-minus-circle"></i>
            </div>
          </template>
        </m-card>
      </div>
    </div>
  </base-modal>
</template>

<script>
import MCard from "@/spa/components/common/MCard.vue";
import BaseModal from "@/spa/components/modals/BaseModal.vue";

export default {
  name: "CustomizedProductsModal",

  components: {
    MCard,
    BaseModal,
  },

  props: {
    customizedProducts: {
      type: Array,
      required: true,
      default: [],
    },

    productName: {
      type: String,
      required: true,
    },

    serviceType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      mappedCustomizedProducts: [],
    };
  },

  methods: {
    saveSelectedValues() {
      this.$emit("saveSelectedValues", this.mappedCustomizedProducts);
    },

    mapCustomizedProducts() {
      this.mappedCustomizedProducts = this.customizedProducts
        .filter((customizedProduct) =>
          customizedProduct.service_types.some(
            (serviceType) => serviceType.service_name === this.serviceType
          )
        )
        .map((customizedProduct) => {
          return {
            id: customizedProduct.id,
            title: customizedProduct.title,
            totalSelectedCount: 0,
            quantity: customizedProduct.quantity,
            choices: customizedProduct.choices.map((choice) => {
              return {
                id: choice.id,
                name: choice.product_name,
                selectedCount: 0,
              };
            }),
          };
        });
    },

    updateSelectedCount(operation, choice, customizedProduct) {
      if (operation === "add") {
        if (customizedProduct.totalSelectedCount < customizedProduct.quantity) {
          choice.selectedCount++;
          customizedProduct.totalSelectedCount++;
        }
      } else {
        choice.selectedCount--;
        customizedProduct.totalSelectedCount--;
      }
    },

    reset() {
      this.mappedCustomizedProducts = [];
      this.mapCustomizedProducts();
    },
  },
};
</script>

<style scoped>
.mods-row {
  gap: 16px;
  margin: 0 10px;
}

.product-header {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 10px 10px;
}

.product-header span {
  color: #dc3545;
  font-size: 20px;
  margin-left: 5px;
}

.product-header span.complete {
  color: #28a745;
}

:deep(.modal__title) {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 15px 0;
}

:deep(.m-card__header) {
  padding-bottom: 0 !important;
  color: #555;
}

.pick-count {
  position: absolute;
  background-color: #043b66;
  top: 0;
  right: 0;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 0 5px 0 5px;
}

.minus-count {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}
.minus-count i {
  color: #dc3545;
  font-size: 20px;
}
</style>
