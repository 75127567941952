<template>
  <div
    class="m-delivery-total d-flex align-items-center justify-content-between"
    :class="delivery.toLowerCase()"
  >
    <div>
      <div class="label">{{ delivery == 'AlipayDStore' ? 'D-store' : 'Delivery' }} Sales Total</div>
      <div class="amount">{{ amount }}</div>
    </div>

    <div class="logo d-flex align-items-center justify-content-center">
      <component :is="delivery" :width="50" :height="50"/>
    </div>
  </div>
</template>

<script>
import Grab from "@/spa/components/icons/deliveries/grab";
import FoodPanda from "@/spa/components/icons/deliveries/foodpanda";
import AlipayDStore from "@/spa/components/icons/deliveries/AlipayDstore";

export default {
  name: "MDeliverySalesTotal",

  components: { Grab, FoodPanda, AlipayDStore },

  props: {
    amount: {
      type: Number,
      required: true,
      default: "--",
    },

    delivery: {
      type: String,
      required: true,
      validator(value) {
        return ["Grab", "FoodPanda", "AlipayDStore"].includes(value);
      },
    },
  },
};
</script>

<style scoped>
.m-delivery-total {
  padding: 20px;
  background: #aaa;
  box-shadow: 0px 5px 5px rgba(219, 219, 219, 0.25);
  border-radius: 10px;
  color: white;
}

.alipaydstore {
  background: #1677FF !important;
}

.m-delivery-total .label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 35px;
}

.m-delivery-total .amount {
  font-size: 28px;
  font-weight: bold;
}

.m-delivery-total .logo {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.7);
}

/* override by delivery type */
.m-delivery-total.grab {
  background: #00b45e;
  box-shadow: 0px 5px 5px rgba(0, 180, 94, 0.25);
}

.m-delivery-total.foodpanda {
  background: #d70f64;
  box-shadow: 0px 5px 5px rgba(215, 15, 100, 0.25);
}

.m-delivery-total.foodpanda svg {
  width: 40px;
  height: auto;
}
</style>
