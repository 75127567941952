<template>
  <div class="row mx-2 settings-page px-lg-4 py-4">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <label>Ayala Sales File Setting</label>

      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="card asf-card">
          <div class="card-body">
            <h3 class="card-title px-3">Ayala Network</h3>
            
            <div class="form-group px-3">
              <label>Drive path</label>
              <input type="text" class="form-control" placeholder="Ex. \\HOSTNAME\folder\path\here">
            </div>

            <div class="d-flex">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Username</label>
                  <input type="text" class="form-control" placeholder="Enter username">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" class="form-control" placeholder="Enter password">
                </div>
              </div>
            </div>
          </div> <!-- /.card-body -->
        </div> <!-- /.card -->

        <div class="card asf-card">
          <div class="card-body">
            <h3 class="card-title px-3">Laptop</h3>
            
            <div class="form-group px-3">
              <label>Ayala Tenant API OUTGOING network folder path</label>
              <input type="text" class="form-control" placeholder="Ex. \\HOSTNAME\folder\path\here">
            </div>

            <div class="d-flex">
              <div class="col-md-6">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Enter username">
              </div>
              <div class="col-md-6">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Enter password">
              </div>
            </div>
          </div> <!-- /.card-body -->
        </div> <!-- /.card -->

        <div class="d-flex justify-content-end asf-button-row">
          <button class="btn btn-secondary" @click="showTestSuccessModal()">Test</button>
          <button class="btn btn-primary" @click="showSaveSuccessModal()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';

export default {
  components: {
    SettingsSidebar
  },
  data() {
    return {
    }
  },
  methods: {
    showTestSuccessModal() {
      Swal.fire({
        title: 'Test Successful!',
        text: 'Mosaic POS was able to connect to both shared folders',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    },
    showSaveSuccessModal() {
      Swal.fire({
        title: 'Success!',
        text: 'Ayala Sales File settings have been saved.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }
  }
}
</script>
<style scoped>
  .asf-card {
    box-shadow: 0px 4px 4px 0px #0000000D;
    margin-bottom: 16px;
  }

  .asf-card .card-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }

  .asf-card .card-body label {
    margin: 0 0 8px 0 !important;
  }

  .asf-button-row {
    margin-top: 16px;
    gap: 10px;
  }

  .asf-button-row .btn {
    min-width: 120px;
  }
</style>
