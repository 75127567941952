<template>
  <BaseModal
    v-model="showPaymentQRModal"
    :minHeight="modalHeight"
    :maxHeight="modalHeight"
    :maxWidth="modalWidth"
    :minWidth="modalWidth"
    full-width-actions
    hideModalAction="true"
    @cancel="close"
    @click-outside="close"
    @before-open="checkQR"
    cancelLabel="Close"
    :hasConfirm="false"
    :hasCancel="false"
    class="payment-qr-code-modal" 
  >
    <template v-if="paymentProvider === 'xendit'">
      <div class="contents d-flex flex-column align-items-center">
        <div class="header d-flex align-items-center pt-2">
          Pay via
          <img
            class="ml-2"
            v-if="paymentQRDetails?.payment"
            :src="`/images/payments/${paymentQRDetails?.payment.toLowerCase()}.png`"
            alt="Payment Icon"
          />
        </div>

        <div class="amount mt-2">
          ₱{{ $filters.formatPrice(paymentQRDetails?.amount) }}
        </div>

        <div class="d-flex flex-column align-items-center">
          <template v-if="!paymentQRDetails?.invoiceUrl">
            <div
              class="preloader d-flex align-items-center justify-content-center mt-3"
            >
              <div class="spinner-border spinner-border-sm"></div>
              <div class="generating ml-2">Generating QR Code</div>
            </div>
          </template>

          <template v-else>
            <div class="paid" v-if="paid">
              <i class="fas fa-check-circle"></i>
            </div>

            <p class="note">
              Scan using your phone camera
              <br />
              <span
                >Note: This will not work if scanned with an e-wallet
                application</span
              >
            </p>

            <qr-code class="mt-2" :text="paymentQRDetails?.invoiceUrl" />

            <div class="scan-banner mt-4" :class="{ success: paid }">
              {{ bannerText }}
            </div>

            <div class="ref mt-5 d-flex">
              <div v-if="paymentQRDetails?.bill">
                Bill#: {{ paymentQRDetails?.bill }}
              </div>

              <div v-if="paymentQRDetails?.table">
                | Table#: {{ paymentQRDetails?.table }}
              </div>

              <div v-if="paymentQRDetails?.externalId">
                | Ref#: {{ paymentQRDetails?.externalId }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="paymentProvider === 'paymongo'">
      <!-- PayMongo Modal Content -->
      <div class="contents d-flex flex-column align-items-center">
        <template v-if="!paymentQRDetails?.qrCodeImageUrl">
          <div
            class="preloader d-flex align-items-center justify-content-center mt-4"
          >
            <div class="spinner-border text-primary"></div>
          </div>
        </template>

        <template v-else>
          <div class="header pt-2">
            <img src="/images/mosaicpay-qrph.svg" alt="PayMongo Logo" />
          </div>

          <div class="paymongo-amount mt-2">
            ₱{{ $filters.formatPrice(paymentQRDetails?.amount) }}
          </div>

          <p class="note mt-3">
            Scan or upload to pay via your <span class="font-weight-bold paymongo-text">bank</span> or
            <span class="font-weight-bold paymongo-text">e-wallet app</span> within 30 minutes.
          </p>

          <div class="paymongo qr-code-container">
            <img
              class="paymongo qr-code-image"
              :src="paymentQRDetails?.qrCodeImageUrl"
              alt="QR Code"
            />
          </div>

          <div class="paymongo-footer d-flex flex-column align-items-center">
            <div class="footer-details text-center mb-3">
              <span class="text-muted">Ref. No:</span> {{ paymentQRDetails?.paymentIntentId }} <br />
              <span class="text-muted">Desc:</span> {{ paymentQRDetails?.description }} <br />
              <span class="text-muted">Date:</span> {{ paymentQRDetails?.qrGeneratedAt }}
            </div>
            <button class="btn btn-primary print-button d-none">
              <i class="fas fa-print"></i> PRINT
            </button>
          </div>

        </template>
      </div>
    </template>

    <template v-else>
      <!-- Default/Fallback Content -->
      <div class="contents d-flex flex-column align-items-center">
        <p class="note mt-3">Unsupported payment provider.</p>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import PaymentQRMixin from "@/spa/components/mixins/PaymentQRMixin";
import { PAYMENT_INVOICE_STATUSES } from "@/spa/constants";
import bus from "@/spa/utils/bus";

export default {
  name: "BillQrCodeModal",

  components: { BaseModal },
  mixins: [PaymentQRMixin],

  mounted() {

    // listen for the "paymentPaid" event
    bus.on("paymentPaid", () => {
      this.close();
      this.$customSwal({
        stackedIcons: [
          { src: '/images/mosaicpay-qrph.svg', alt: 'Mosaic Logo' },
          { src: '/images/icon_verified-check.svg', alt: 'Payment Received Icon' },
        ],
        title: "Payment Received",
        text: `₱ ${parseFloat(this.paymentQRDetails?.amount).toFixed(2)}`,
        customClass: {
          icon: 'no-border-icon',
          title: 'custom-title-class',
          content: 'custom-content-class',
        },
        buttonText: "OK",
        className: 'center-btn'
      });
    });

    // listen for the "paymentExpired" event
    bus.on("paymentExpired", () => {
      this.close();
      this.$customSwal({
        iconHtml: '<img src="/images/icon_qr-code.svg" alt="QR Code Icon"/>',
        title: "QR Code Expired",
        text: "This QR code has expired. Please generate a new code to proceed with your transaction.",
        customClass: {
          icon: 'no-border-icon',
          title: 'custom-title-class',
          content: 'custom-content-class',
        },
        buttonText: "OK",
        className: 'center-btn'
      });
    });

    // listen for the "paymentFailed" event
    bus.on("paymentFailed", () => {
      this.close();
      this.$customSwal({
        iconHtml: '<img src="/images/icon_close-circle.svg" alt="Payment Failed Icon"/>',
        title: "Payment Failed",
        text: "Transaction has been cancelled. We are unable to process the payment. Please choose another payment method or try again later.",
        customClass: {
          icon: 'no-border-icon',
          title: 'custom-title-class',
          content: 'custom-content-class',
        },
        buttonText: "OK",
        className: 'center-btn'
      });
    });
  },

  computed: {
    paymentProvider() {
      return this.paymentQRDetails?.paymentProvider?.toLowerCase() || "unknown";
    },

    paid() {
      return this.paymentQRDetails?.status === PAYMENT_INVOICE_STATUSES.PAID;
    },

    bannerText() {
      return this.paid ? "Paid" : "Scan to Pay";
    },

    modalWidth() {
       if (this.paymentProvider === "paymongo") {
        return !this.paymentQRDetails?.qrCodeImageUrl ? "120px" : "450px";
      }

      return "450px";
    },

    modalHeight() {
      if (this.paymentProvider === "paymongo") {
        return !this.paymentQRDetails?.qrCodeImageUrl ? "120px" : "";
      }
      return "";
    },
  },

  methods: {
    close() {
      this.setShowPaymentQRModal(false);

      setTimeout(() => {
        this.setPaymentQRDetails(null);
      }, 500);
    },

    checkQR() {
      console.log(`API call is made here before opening the QR Code modal..`);

      if (this.paymentProvider == "xendit") {
        if (this.paymentQRDetails && !this.paymentQRDetails.invoiceUrl) {
          const params = {
            paymentChannel: this.paymentQRDetails.xendItPaymentChannel,
            orderId: this.paymentQRDetails.orderId,
            billNum: this.paymentQRDetails.bill,
            amountPaid: this.paymentQRDetails.amount,
            paymentType: this.paymentQRDetails.payment.toLowerCase()
          };

          this.createPaymentInvoice(params);
        }
      }

      if (this.paymentProvider == "paymongo") {
        if (this.paymentQRDetails && !this.paymentQRDetails.qrCodeImageUrl) {
          const params = {
            orderId: this.paymentQRDetails.orderId,
            billNum: this.paymentQRDetails.bill,
            amountPaid: this.paymentQRDetails.amount
          };

          console.log('calling paymongo payments API..');
          this.createPaymongoInvoice(params);
        }
      }

    },
  },
};
</script>

<style scoped>
.header {
  font-size: 30px;
  font-weight: bold;
}

.header img {
  height: 50px;
}

.payment-qr-code-modal {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.amount {
  font-size: 30px;
  font-weight: bold;
  color: green;
  letter-spacing: 0.1rem;
}

.paymongo-amount {
  font-size: 40px;
  font-weight: bold;
  color: #3400FF;
  letter-spacing: 0.1rem;
}

.paymongo-text {
  color: #1A63D1;
}

.paymongo .qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 320px;
  background-color: #ffffff; /* Optional: White background */
  border: 1px solid #ddd; /* Optional: Border for QR code */
  border-radius: 10px; /* Optional: Rounded corners */
}

.paymongo .qr-code-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.paymongo-footer {
  background-color: #def1ff; /* Light blue background */
  padding: 15px; /* Add some padding for spacing */
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  width: 100%; /* Full width of the modal */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
}

.footer-details {
  font-size: 13px;
  font-weight: bold;
  color: #333;
}

.print-button {
  margin-top: 10px; /* Add space between footer details and button */
  background-color: #0052CC; /* Match branding color for the button */
  border: none; /* Remove button border */
  color: #fff; /* White text */
  font-weight: bold;
  padding: 10px 20px; /* Adjust padding for the button */
  border-radius: 5px; /* Rounded button */
  cursor: pointer;
  width: 90%; /* Ensure consistent button width */
  max-width: 320px; /* Prevent button from being too large */
}

.print-button:hover {
  background-color: #0052CC; /* Darker shade for hover */
}

.note {
  text-align: center;
  margin: 0;
}
.note span {
  font-size: 13px;
}

.contents {
  padding: 0 5px;
}

.status {
  font-weight: bold;
  color: orange;
}
.status.paid {
  color: green;
}

/* .preloader {
  width: 256px;
  height: 256px;
  opacity: 0.8;
} */

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.scan-banner {
  font-weight: bold;
  font-size: 32px;
  background-color: #135a96;
  color: white;
  border-radius: 10px;
  width: 260px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

.scan-banner.success {
  background-color: green;
}

.ref {
  font-size: 13px;
  gap: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.generating {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}

.paid {
  color: green;
  font-size: 35px;
  position: absolute;
  top: 10px;
  right: 10px;
}

::v-deep(.modal__title) {
  margin: 0 !important;
}
::v-deep(.modal__action) {
  padding: 0 !important;
}

</style>
