<template>
  <div class="d-flex flex-nowrap">
    <div class="settings-page px-lg-4 py-4">
      <label>Settings Page</label>
    </div>
  </div>
  <div class="row mx-2">
    <div class="col-xl-4 order-lg-12">
      <SettingsSidebar />
    </div>
    <div class="col">
      <div class="tab-panel" id="no-selected" role="tabpanel">
        <div class="row">
          <div>
            IMIN SETTINGS PAGE
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSidebar from '@/spa/components/common/SettingsSidebar';
export default {

  components: {
    SettingsSidebar
  },

  data() {
    return {
    };
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style scoped>
:deep(.items-breakdown) {
  height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 16px;
  margin-right: -16px;
}

:deep(.items-breakdown)>div:not(:last-of-type) {
  border-bottom: 1px dotted #aaa;
}

.settings-page {
  overflow-y: auto;
  max-height: calc(100vh - 86px);
}
</style>