<template>
    <div class="d-flex">
        <div class="tables-page pt-4 px-5" :class="{ 'col-8': activeTableId && activeOrderId }">
            <div>
                <label>Tables</label>

                <button
                    v-if="isMultiTerminal"
                    class="btn btn-primary btn-lg mr-2"
                    :disabled="isFetching"
                    @click="triggerManualFetchingOfOrders"
                    >
                    <i class="fa fa-sync-alt" :class="{ rotating: isFetching }" />
                </button>
            </div>
            <div class="btn-group mb-4 mt-2" role="group">
                <button
                    v-for="(area, index) in tableData"
                    :key="`area-${area.id}`"
                    type="button"
                    class="btn btn-lg"
                    :class="{
                        'btn-primary': index === activeAreaIndex,
                        'btn-light': index !== activeAreaIndex,
                    }"
                    @click="setActiveAreaIndex(index)"
                >
                    {{ area.area_name }}
                </button>
            </div>

            <m-arranged-tables
                v-if="tableData.length"
                :tables="activeArea.tables"
                :occupied-tables="occupiedTables"
                :active-table-id="activeTableId"
                @tableSelected="viewTable($event)"
                />
        </div>

        <orders-panel
            v-if="activeTableId && activeOrderId !== ''"
            :order-id="activeOrder._id"
            :orders="activeOrder.orders"
            :table-id="activeTableId"
            :kots="activeOrder.kots"
            :pax="activeOrder.pax"
            :order-detail="activeOrder.orderDetail"
            preview
            consolidate-orders
            @updateOrders="updateOrders(activeTableId)"
            @billOrders="goToPayments(activeTableId, $event)"
            @voidSuccess="clearTable(activeTableId)"
            @tableUpdated="updateTableStates(true)"
            @tableSelected="viewTable($event)"
            @voidOrders="voidTable(activeTableId)"
            @itemMoved="itemMoved($event)"
        />

    </div>
</template>

<script>
import bus from '@/spa/utils/bus';
import { uniq, map } from 'lodash';
import { getTableLayout } from '@/spa/services/table-service';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import MArrangedTables from '@/spa/components/common/MArrangedTables';
import OrdersPanel from '@/spa/components/panels/OrdersPanel';
import { multiterminalEvents } from '@/mobile_bridge/multiterminal/events';
import {OFFLOAD} from "@/spa/constants";

export default {
    name: 'TablesPage',

    components: {
        MArrangedTables,
        OrdersPanel,
    },

    props: {
        serviceType: {
            type: String,
            default: '',
        },
        serviceAreaId: {
            type: String,
            default: '1'
        }
    },

    data() {
        return {
            tableData: [],
            activeAreaIndex: 0,
            occupiedTables: {},
            activeTableId: null,
            isFetching: false,
        };
    },

    computed: {
        ...mapState([
            'orders',
            'activeOrderId',
            'lastMTEventTimeStamp',
        ]),

        ...mapGetters([
            'activeOrder',
            'pendingNotReopenedOrders',
            'hasPendingSettlements',
        ]),

        activeArea() {
            return this.tableData.length > 0
                ? this.tableData[this.activeAreaIndex]
                : { tables: [] };
        },

        isMultiTerminal() {
            const terminal = JSON.parse(sessionStorage.getItem('terminal'));
            return terminal?.is_shared_terminal;
        },
    },

    async beforeMount() {
        await this.updateTableStates();
    },

    async mounted() {
        await this.fetchTables();

        if (this.$route.query.order_id) {
            this.viewOrder(this.$route.query.order_id);
        }

        bus.on("resetTables", (tableId) => {
            this.clearTable(tableId);
        });

        bus.on("setViewOrder", (orderId) => {
            this.viewOrder(orderId);
        });

        bus.on("triggerUpdateTableStates", async (tableId = null) => {
            await this.updateTableStates(true);

            if (tableId) {
                this.activeTableId = tableId;
            }
        });

        this.messageFeedListener = async (event) => {
            const message = event.body;
            const { type, action } = message;
            const receiptOrOrder = type === 'Receipt' || type === 'Order';

            if (receiptOrOrder && action === 'UPSERT') {
                const stdb = new ServiceTableDetailBridge();
                const activeTable = this.activeTableId ? await stdb.getRowById(this.activeTableId) : null;

                await this.sqliteFetchPendingOrders();
                // Unset active order if the table is no longer occupied
                await this.updateTableStates(!activeTable?.receipt_local_id);
                await this.fetchTables();
            }
        };
        multiterminalEvents.on('MessageFeed', this.messageFeedListener);
    },

    beforeUnmount() {
        console.log('TablesPage: Removing MessageFeed handler');

        multiterminalEvents.off('MessageFeed', this.messageFeedListener);
    },

    watch: {
        activeTableId(value) {
            if (value) {
                this.parseTableOrders();
            }
        },

        serviceType(value) {
            if(value) {
                this.fetchTables()
            }
        },

        lastMTEventTimeStamp() {
            this.$nextTick(async () => {
                await this.updateTableStates(!this.activeOrderId);
                if (this.activeOrder?.tableId && this.activeOrder?.tableId !== this.activeTableId) this.viewTable(this.activeOrder.tableId);
            });
        },
    },

    methods: {
        ...mapMutations([
            'setOrders',
            'clearActiveOrderId',
            'setActiveOrderId',
            'setActiveBrandId',
            'setActiveServiceTypeId',
            'setActiveChannelId',
        ]),

        ...mapActions(['getLatestServerOrders', 'sqliteFetchPendingOrders']),

        async triggerManualFetchingOfOrders() {
            this.isFetching = true;
            try {
                if (OFFLOAD.sqliteOffloadReceipt) {
                    await this.sqliteFetchPendingOrders();
                } else {
                    await this.getLatestServerOrders();
                }

                await this.updateTableStates(true);
                await this.fetchTables();
                this.$swal.success('Success', 'Multiterminal orders fetched successfully');
            } catch (e) {
                this.$swal.error('Failed to fetch orders', 'Please check your connection and try again');
            }

            setTimeout(() => {
                this.isFetching = false;
            }, 2000);
        },

        async fetchTables() {
            try {
                const response = await getTableLayout(this.serviceAreaId);
                this.tableData = map(response.data.table_details.service_areas, a => ({
                    ...a,
                    tables: map(a.tables, t => ({
                        ...t,
                        table_position: JSON.parse(t.table_position),
                    })),
                }));
            } catch (e) {
                console.error(e);
            }
        },

        setActiveAreaIndex(areaId) {
            this.activeAreaIndex = areaId;
        },

        viewTable(tableId) {
            if (this.occupiedTables[tableId]) {
                const tableOrder = this.pendingNotReopenedOrders.find(o => o.tableId == tableId);
                if (tableOrder.isSplitRequested) {
                    if (this.$can(this.PERMISSIONS.SETTLE_ORDER)) {
                        this.goToPayments(tableId, tableOrder);
                    } else {
                        this.$swal.warning('Cannot view table', 'This table is currently split, please continue on the cashing terminal.');
                    }
                    return;
                }

                this.activeTableId = this.activeTableId === tableId ? null : tableId;
                const index = this.tableData.findIndex(a => a.tables.find(t => t.table_id === tableId))

                if (index < 0) {
                    // If this code runs while fetchTables is running, tableData
                    // might be empty(!). This can happen on a satellite terminal
                    // within a multiterminal setup.
                    // We can simply try again after a short delay.
                    console.error('Table not found in tableData! Retrying shortly...');

                    setTimeout(() => this.viewTable(tableId), 500);
                } else {
                    this.setActiveAreaIndex(index);
                }
            } else {
                this.goToTableDetails(tableId);
            }
        },

        viewOrder(orderId) {
            this.setActiveOrderId(orderId);
            if (this.activeOrder?.tableId) {
                this.$nextTick(() => this.viewTable(this.activeOrder.tableId))
            }
        },

        goToTableDetails(tableId) {
            this.$router.push({
                name: 'table-details',
                params: { tableId, serviceType: this.serviceType },
            });
        },

        updateOrders(tableId) {
            const order = this.pendingNotReopenedOrders.find(o => o.tableId == tableId);
            if (!order) return;

            this.$router.push({
                name: 'update-orders',
                params: { orderId: order._id, serviceType: (this.activeOrder.serviceType || this.serviceType) },
            });
        },

        goToPayments(tableId, data) {
            const order = this.pendingNotReopenedOrders.find(o => o.tableId == tableId);
            if (!order) return;

            this.$router.push({
                name: 'payments',
                params: { orderId: order._id , tableName: data.tableName },
                query: data.query,
            });
        },

        parseTableOrders() {
            const order = this.pendingNotReopenedOrders.find(o => o.tableId == this.activeTableId);
            this.setActiveOrderId(order ? order._id : null);
            this.$nextTick(() => {
                if (this.activeOrder) {
                    this.setActiveBrandId(this.activeOrder.brandId);
                    this.setActiveServiceTypeId(this.activeOrder.serviceTypeId);
                    this.setActiveChannelId(this.activeOrder.channelId);
                }

                if (this.hasPendingSettlements) {
                    const activeTable = this.activeArea.tables.find(t => t.table_id == this.activeTableId);
                    this.goToPayments(this.activeTableId, activeTable);
                }
            });
        },

        clearTable(tableId) {
            this.occupiedTables[tableId] = false;

            if (this.activeTableId == tableId) {
                this.activeTableId = null;
            }
        },

        async updateTableStates(unselectActive = false) {
            const tdb = new ServiceTableDetailBridge();

            const tablesWithOrders = OFFLOAD.sqliteOffloadTableDetail
               ? await tdb.getRows({ select: ['table_id'], whereNotNull: ['receipt_local_id'] })
               : uniq(map(this.pendingNotReopenedOrders, 'tableId'));

            this.occupiedTables = OFFLOAD.sqliteOffloadTableDetail
               ? Object.assign({}, ...tablesWithOrders.map(item => ({ [item.table_id]: true })))
               : tablesWithOrders.reduce((acc, tableId) => {
                  acc[tableId] = true;
                  return acc;
               }, {});

            if (unselectActive) {
                this.activeTableId = null;
            }

            if (!this.occupiedTables[this.activeOrder.tableId]) {
                this.clearActiveOrderId();
            }
        },

        async itemMoved(tableObject) {
            await this.updateTableStates(true);
            this.setActiveOrderId(tableObject.lastOrderId);
            this.viewTable(tableObject.lastTableId);
        },
    },
};
</script>
