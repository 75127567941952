<template>
  <div class="d-flex channels-container px-4 py-3">
    <template v-for="channel in mappedChannels" :key="`channel-${channel.id}`">
      <MCard
        v-if="isChannelSupported(channel.channel_name)"
        class="col-3 mr-3"
        ratio="4:3"
        clickable
        @click="gotoChannel(channel)"
      >
        <template #default>
          <div class="controls" v-if="channelResumeDetails.length">
            <div class="d-flex justify-content-between align-items-center">
              <template v-if="!hasMultipleBrands">
                <MSwitch
                  :defaultValue="!Boolean(channel.resume_at)"
                  @changed="handleChangeStoreAvailability(channel, $event)"
                  :ref="`channel-${channel.id}`"
                />

                <span v-if="channel.resume_at"
                  >resume in
                  {{ calculateRemainingTime(channel.resume_at) }}</span
                >
              </template>

              <template v-else>
                <div class="brand" @click.stop="showBrandList(channel)">
                  <i class="fas fa-store mr-2"></i> Pause / Resume stores
                </div>
              </template>
            </div>
          </div>

          <div
            class="contents d-flex flex-column justify-content-center align-items-center"
            :class="channel.channel_name.toLowerCase()"
          >
            <div class="logo d-flex align-items-center">
              <component :is="toNoSpace(channel.channel_name)" />
            </div>

            <div class="title mt-1" :class="channel.channel_name">{{ channel.channel_name }}</div>
          </div>
        </template>
      </MCard>
    </template>

    <DynamicOptionsModal
      v-model="showPauseStoreModal"
      title="Store Status"
      label="How long do you want to pause store?"
      :options="pauseOptions"
      @confirmed="pauseStore"
      @cancelled="cancelUpdate"
    />

    <ConfirmMessageModal
      v-model="showResumeStoreModal"
      message="Are you sure you want to resume store?"
      @confirmed="resumeStore"
      @cancelled="cancelUpdate"
    />

    <MultiplePauseResumeModal
      v-if="mappedChannelResumeDetails.length && selectedChannel"
      v-model="showBrandListModal"
      :channel="selectedChannel"
      :details="mappedChannelResumeDetails"
      @handleChangeBrandAvailability="handleChangeBrandAvailability"
      ref="multiplePauseResumeModal"
    />
  </div>
</template>

<script>
import { getChannels } from "@/spa/services/cashier-service";
import MCard from "@/spa/components/common/MCard";
import MSwitch from "@/spa/components/common/MSwitch";
import Grab from "@/spa/components/icons/deliveries/grab";
import FoodPanda from "@/spa/components/icons/deliveries/foodpanda";
import AlipayDStore from "@/spa/components/icons/deliveries/AlipayDstore.vue";
import moment from "moment";
import DynamicOptionsModal from "@/spa/components/modals/DynamicOptionsModal";
import ConfirmMessageModal from "@/spa/components/modals/ConfirmMessageModal";
import MultiplePauseResumeModal from "@/spa/components/modals/MultiplePauseResumeModal";
import {
  pauseStore,
  resumeStore,
  getMerchantResumeDetails,
} from "@/spa/services/delivery-service";
import {OFFLOAD} from "@/spa/constants";
import {mapState} from "vuex";

export default {
  name: "DeliveriesPage",

  components: {
    MCard,
    Grab,
    FoodPanda,
    AlipayDStore,
    MSwitch,
    DynamicOptionsModal,
    ConfirmMessageModal,
    MultiplePauseResumeModal,
  },

  data() {
    return {
      selectedChannel: null,
      channels: [],
      channelResumeDetails: [],

      showPauseStoreModal: false,
      showResumeStoreModal: false,
      showBrandListModal: false,
      pauseOptions: [
        { id: 1, value: "30m", display: "30 minutes" },
        { id: 2, value: "1h", display: "1 hour" },
        { id: 3, value: "24h", display: "24 hours" },
      ],

      pageInterval: null,
      revalidateTimer: 1000 * 60 * 5, // 5 minutes
      selectedDetail: null,
    };
  },

  computed: {
    ...mapState('sqlite', ['initialData']),

    mappedChannels() {
      return this.channels.map((channel) => ({
        ...channel,
        resume_at: this.getChannelResumeAt(channel.channel_name),
      }));
    },

    hasMultipleBrands() {
      return this.channelResumeDetails.length > 1;
    },

    mappedChannelResumeDetails() {
      if (this.selectedChannel?.channel_name === "Grab") {
        return this.channelResumeDetails.map((detail) => ({
          ...detail,
          resumeAtFormatted: this.calculateRemainingTime(detail.grab_resume_at),
        }));
      }

      return this.channelResumeDetails.map((detail) => ({
        ...detail,
        resumeAtFormatted: this.calculateRemainingTime(
          detail.foodpanda_resume_at
        ),
      }));
    },
  },

  methods: {
    toNoSpace(str) {
      return str.replaceAll(' ', '');
    },

    async fetchChannels() {
      if (OFFLOAD.sqliteOffloadProduct) {
        this.channels = this.initialData?.channels || [];

        return;
      }

      try {
        const response = await getChannels();
        this.channels = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async getMerchantResumeDetails() {
      try {
        const response = await getMerchantResumeDetails();
        this.channelResumeDetails = response.data.data;
        this.checkResumedStores();
      } catch (e) {
        console.error(e);
      }
    },

    gotoChannel(channel) {
      this.$router.push({
        name: "delivery",
        params: {
          channelName: this.toNoSpace(channel.channel_name),
        },
      });
    },

    isChannelSupported(channel) {
      return this.$tempActiveDeliveryIntegrations.includes(channel);
    },

    calculateRemainingTime(brandResumeAt) {
      const now = moment().format("LLLL");
      const resumeAt = moment(brandResumeAt).format("LLLL");
      const diff = moment(now).diff(moment(resumeAt), "minutes");
      const prepend = diff < 0 ? "" : "-";
      const positiveDiff = Math.abs(
        moment(now).diff(moment(resumeAt), "minutes")
      );
      const hours = Math.floor(positiveDiff / 60);
      const minutes = positiveDiff % 60;

      let response = prepend;

      if (hours) {
        response += hours + "h ";
      }

      if (minutes) {
        response += minutes + "m";
      }

      return response;
    },

    handleChangeStoreAvailability(channel, enabled) {
      this.selectedChannel = channel;

      if (enabled) {
        this.showPauseStoreModal = true;
        return;
      }

      this.showResumeStoreModal = true;
    },

    handleChangeBrandAvailability({ enable, detail }) {
      this.selectedDetail = detail;

      this.handleChangeStoreAvailability(this.selectedChannel, enable);
    },

    showBrandList(channel) {
      this.selectedChannel = channel;

      this.showBrandListModal = true;
    },

    async pauseStore({ value: duration }) {
      if (this.hasMultipleBrands) {
        this.pauseMultiBrands(duration);
        return;
      }

      this.pauseSingleBrand(duration);
    },

    async pauseSingleBrand(duration) {
      const result = await this.$openApproverModal();

      if (result.cancelled) return;

      this.showPauseStoreModal = false;

      switch (duration) {
        case "30m":
          this.selectedChannel.resume_at = moment().add(30, "minutes");
          break;

        case "1h":
          this.selectedChannel.resume_at = moment().add(1, "hours");
          break;

        case "24h":
          this.selectedChannel.resume_at = moment().add(24, "hours");
          break;

        default:
          break;
      }

      const payload = {
        isPause: true,
        duration,
        id: this.mappedChannelResumeDetails[0].id,
      };

      try {
        await pauseStore(payload, this.selectedChannel.channel_name);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.selectedChannel.resume_at = null;
        this.toggleChangeStoreAvailability();

        throw e;
      }
    },

    async pauseMultiBrands(duration) {
      const result = await this.$openApproverModal();

      if (result.cancelled) return;

      this.showPauseStoreModal = false;

      const resumeAtKey =
        this.selectedChannel.channel_name === "Grab"
          ? "grab_resume_at"
          : "foodpanda_resume_at";

      switch (duration) {
        case "30m":
          this.updateBrandResumeAt(
            this.selectedDetail?.id,
            resumeAtKey,
            moment().add(30, "minutes")
          );
          break;

        case "1h":
          this.updateBrandResumeAt(
            this.selectedDetail?.id,
            resumeAtKey,
            moment().add(1, "hours")
          );

          break;

        case "24h":
          this.updateBrandResumeAt(
            this.selectedDetail?.id,
            resumeAtKey,
            moment().add(24, "hours")
          );

          break;

        default:
          break;
      }

      const payload = {
        isPause: true,
        duration,
        id: this.selectedDetail?.id,
      };

      try {
        await pauseStore(payload, this.selectedChannel.channel_name);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");

        this.updateBrandResumeAt(this.selectedDetail?.id, resumeAtKey, null);

        this.toggleChangeBrandAvailability(this.selectedDetail?.id);

        throw e;
      }
    },

    updateBrandResumeAt(id, key, value) {
      this.channelResumeDetails = this.channelResumeDetails.map((detail) => {
        if (id === detail.id) {
          return {
            ...detail,
            [key]: value,
          };
        }

        return { ...detail };
      });
    },

    async resumeStore(channel = null) {
      if (this.hasMultipleBrands) {
        this.resumeMultiBrands();
        return;
      }

      this.resumeSingleBrand(channel);
    },

    async resumeSingleBrand(channel) {
      const store = channel ?? this.selectedChannel;

      const tempResumeAtValue = store.resume_at;
      this.showResumeStoreModal = false;
      this.setChannelResumeAt(store.channel_name, null);

      const payload = {
        isPause: false,
        id: this.mappedChannelResumeDetails[0].id,
      };
console.log(store, tempResumeAtValue);
      try {
        await resumeStore(payload, store.channel_name);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.setChannelResumeAt(store.channel_name, tempResumeAtValue);
        this.toggleChangeStoreAvailability();
        throw e;
      }
    },

    async resumeMultiBrands() {
      const resumeAtKey =
        this.selectedChannel.channel_name === "Grab"
          ? "grab_resume_at"
          : "foodpanda_resume_at";

      const tempResumeAtValue = this.selectedDetail[resumeAtKey];
      this.showResumeStoreModal = false;
      this.updateBrandResumeAt(this.selectedDetail?.id, resumeAtKey, null);

      const payload = {
        isPause: false,
        id: this.selectedDetail?.id,
      };

      try {
        await resumeStore(payload, this.selectedChannel.channel_name);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        this.updateBrandResumeAt(
          this.selectedDetail?.id,
          resumeAtKey,
          tempResumeAtValue
        );
        this.toggleChangeBrandAvailability(this.selectedDetail?.id);
        throw e;
      }
    },

    cancelUpdate(channel = null) {
      if (this.hasMultipleBrands) {
        this.toggleChangeBrandAvailability(this.selectedDetail?.id);
        return;
      }

      this.toggleChangeStoreAvailability(channel);
    },

    toggleChangeStoreAvailability(channel = null) {
      const store = channel ?? this.selectedChannel;

      this.$refs[`channel-${store.id}`][0].enabled =
        !this.$refs[`channel-${store.id}`][0].enabled;
    },

    toggleChangeBrandAvailability(id) {
      if (this.$refs?.multiplePauseResumeModal?.$refs[`detail-${id}`]) {
        this.$refs.multiplePauseResumeModal.$refs[`detail-${id}`][0].enabled =
          !this.$refs.multiplePauseResumeModal.$refs[`detail-${id}`][0].enabled;
      }
    },

    checkResumedStores() {
      this.mappedChannels.forEach((channel) => {
        if (
          channel.resume_at &&
          moment(channel.resume_at).format("LLLL") <= moment().format("LLLL")
        ) {
          this.resumeStore(channel);
          this.toggleChangeStoreAvailability(channel);
        }
      });
    },

    getChannelResumeAt(channel) {
      switch (channel) {
        case "Grab":
          return this.channelResumeDetails[0]?.grab_resume_at;

        case "FoodPanda":
          return this.channelResumeDetails[0]?.foodpanda_resume_at;

        default:
          return null;
      }
    },

    setChannelResumeAt(channel, value) {
      this.selectedChannel.resume_at = value;

      switch (channel) {
        case "Grab":
          this.channelResumeDetails[0].grab_resume_at = value;
          break;

        case "FoodPanda":
          this.channelResumeDetails[0].foodpanda_resume_at = value;
          break;
      }
    },
  },

  created() {
    this.fetchChannels();
    this.getMerchantResumeDetails();

    clearInterval(this.pageInterval);

    this.pageInterval = setInterval(() => {
      this.getMerchantResumeDetails();
    }, this.revalidateTimer);
  },

  unmounted() {
    clearInterval(this.pageInterval);
  },
};
</script>

<style scoped>
.contents .logo {
  height: 80px;
}
.foodpanda .logo svg {
  width: auto;
  height: 80px;
}

.grab .logo svg {
  width: auto;
  height: 50px;
}

.title {
  color: #1b75bb;
  font-size: 24px;
  font-weight: 700;
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.controls > div {
  padding: 10px;
}

.controls span {
  color: gray;
  font-weight: 700;
}

.brand {
  font-size: 12px;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  background-color: #043b66;
  text-transform: uppercase;
}

.foodpanda {
  text-transform: lowercase;
}
</style>
