<template>
    <div class="custom-control custom-switch offline-toggle ready initial" @click="toggleSwitch">
        <input
            class="custom-control-input"
            type="checkbox"
            :checked="isChecked"
            @change="$emit('update:isChecked', $event.target.checked)"
            :id="uniqueId"
            :disabled="true"
        >
        <label
            class="custom-control-label"
            :for="uniqueId"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            uniqueId: {
                type: String,
                required: true
            },

            isChecked: {
                type: Boolean,
                required: true,
                default: true
            },

            label: {
                type: String,
                default: ""
            },

            flip: {
                type: Boolean,
                default: false
            }

        },
        methods: {
            toggleSwitch() {
                const isChecked = this.flip ? this.isChecked : !this.isChecked;
                this.$emit('toggle', isChecked);
            }
        }
    }
</script>

<style>
.offline-toggle .custom-control-input[checked] ~ .custom-control-label::before,
.offline-toggle .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #51DC6B !important;
  border-color: #51DC6B !important;
}
</style>
