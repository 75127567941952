<template>
    <base-modal
        v-bind="$attrs"
        max-width="800px"
        min-width="0"
        min-height="100px"
        width="100%"
        :has-cancel="false"
        @confirm="saveCashFloat"
        :confirmDisabled="confirmDisabled"
        >
        <template v-slot:title><div class="d-flex">Cash Fund<div class="d-md-none d-block">:</div><div class="ml-1 d-md-none d-block" v-text="totalCashFloat" /></div></template>

        <hr />

        <div class="row">
            <div class="col-12 col-md-5">
                <div class="d-flex flex-wrap align-items-center">
                    <div class="col-6"><strong>Denomination</strong></div>
                    <div class="col-3"><strong>Bill</strong></div>
                    <div class="col-3"><strong>Total</strong></div>
                    <template v-for="item in cashFloatItems" :key="`denomination-${item.denomination}`">
                        <div class="col-2">
                            <input type="radio" :value="item.denomination" v-model="selectedDenomination" />
                        </div>
                        <div class="col-4">{{ item.denomination }}</div>
                        <div class="col-4">
                            <input
                                class="d-none d-lg-block d-md-block form-control"
                                readonly
                                :value="item.quantity"
                            />
                            <input
                                class="d-block d-lg-none d-md-none form-control"
                                v-model="item.quantity"
                                @input="recalculateDenomationTotalOnInput(item)"
                            />
                        </div>
                        <div class="col-2">{{ item.total.toFixed(2) }}</div>
                    </template>
                </div>
            </div>

            <div class="col-12 col-md-7 d-none d-md-block">
                <div class="cash-float-keypad d-flex flex-wrap justify-content-between align-items-center">
                    <div class="cash-float-value col-12 value-section d-none d-lg-block d-md-block" v-text="totalCashFloat" />
                        <template v-for="y in [0, 1, 2]">
                            <div v-for="x in [1, 2, 3]" class="col-4" :key="`${x}-${y}`">
                                <button
                                    class="btn btn-block btn-light p-4"
                                    @click="append(x + (y * 3))"
                                    >
                                    {{ x + (y * 3) }}
                                </button>
                            </div>
                        </template>
                    <div class="col-4">
                        <button
                            class="btn btn-block btn-danger p-4"
                            @click="clearCurrentDenomination"
                            >
                            CLEAR
                        </button>
                    </div>
                    <div class="col-4">
                        <button
                            class="btn btn-block btn-light p-4"
                            @click="append(0)"
                            >
                            0
                        </button>
                    </div>
                    <div class="col-4">
                        <button
                            class="btn btn-block btn-danger p-4"
                            @click="unappend"
                            >
                            <i class="fas fa-backspace"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/spa/components/modals/BaseModal';
import { split } from 'lodash'
import bus from '@/spa/utils/bus';

export default {
    name: 'CashFloatModal',

    components: {
        BaseModal,
    },
    mounted() {
        bus.on("isCashFloatSubmitBtnDisabled", (value) => {
            this.confirmDisabled = value
        })
    },
    data() {
        return {
            cashFloatHeaders: [
                { text: 'Denomination', value: 'denomination' },
                { text: 'Bill', value: 'quantity' },
                { text: 'Total', value: 'total' },
            ],
            cashFloatItems: [
                { denomination: '1000', quantity: 0, total: 0 },
                { denomination: '500', quantity: 0, total: 0 },
                { denomination: '200', quantity: 0, total: 0 },
                { denomination: '100', quantity: 0, total: 0 },
                { denomination: '50', quantity: 0, total: 0 },
                { denomination: '20', quantity: 0, total: 0 },
                { denomination: '10', quantity: 0, total: 0 },
                { denomination: '5', quantity: 0, total: 0 },
                { denomination: '1', quantity: 0, total: 0 },
                { denomination: '0.25', quantity: 0, total: 0 },
                { denomination: '0.10', quantity: 0, total: 0 },
            ],
            selectedDenomination: '1000',
            confirmDisabled: false,
        };
    },

    computed: {
        selectedDenominationItem() {
            return this.cashFloatItems.find(item => item.denomination == this.selectedDenomination);
        },

        selectedDenominationIndex() {
            return this.cashFloatItems.findIndex(item => item.denomination == this.selectedDenomination);
        },

        selectedDenominationTotal() {
            return Number(this.selectedDenominationItem.total);
        },

        totalCashFloat() {
            return this.cashFloatItems.reduce((total, item) => total + item.total, 0).toFixed(2);
        },
    },

    methods: {

        recalculateDenomationTotalOnInput(item) {
            const index = this.cashFloatItems.findIndex(i => i === item);
            this.recalculateDenomationTotal(index);
        },
    
        add() {
            this.cashFloatItems[this.selectedDenominationIndex].quantity++;
            this.recalculateDenomationTotal(this.selectedDenominationIndex);
        },

        subtract() {
            if (this.selectedDenominationItem.quantity == 0) return;
            this.cashFloatItems[this.selectedDenominationIndex].quantity--;
            this.recalculateDenomationTotal(this.selectedDenominationIndex);
        },

        append(value) {
            const { quantity } = this.selectedDenominationItem;
            this.cashFloatItems[this.selectedDenominationIndex].quantity = `${quantity > 0 ? quantity : ''}${value}`;
            this.recalculateDenomationTotal(this.selectedDenominationIndex);
        },

        unappend() {
            const { quantity } = this.selectedDenominationItem;
            const quantityArray = split(quantity, '');
            quantityArray.pop();
            this.cashFloatItems[this.selectedDenominationIndex].quantity = quantityArray.join('') || '0';
            this.recalculateDenomationTotal(this.selectedDenominationIndex);
        },

        clearCurrentDenomination() {
            this.cashFloatItems[this.selectedDenominationIndex].quantity = 0;
            this.recalculateDenomationTotal(this.selectedDenominationIndex);
        },

        recalculateDenomationTotal(index = null) {
            if (index === null) {
                this.cashFloatItems.forEach((item, index) => {
                    this.cashFloatItems[index].total = item.quantity * item.denomination;
                });
            } else {
                this.cashFloatItems[index].total = this.cashFloatItems[index].quantity * this.cashFloatItems[index].denomination;
            }

            this.cashFloatItems = [...this.cashFloatItems];
        },

        saveCashFloat() {
            this.confirmDisabled = true;
            this.$emit('save', this.totalCashFloat);
        },
    },
}
</script>

<style scoped>
.cash-float-keypad {
    max-width: 420px;
    height: 100%;
    margin: auto;
}

.cash-float-keypad .btn {
    border-radius: 64px !important;
    font-size: 1.3rem;
    font-weight: bold;
}

.cash-float-value {
    font-size: 2.5rem;
    font-family: 'Exo', sans-serif;
    text-align: center;
}

.value-section {
    background: white;
    height: 80px;
    place-content: center;
    display: grid;
    padding: 8px 0;
}

.value-section .btn {
    min-width: 64px;
}

.col-5,
.col-7 {
    padding: 0;
}
</style>