import { PRINTER_MODELS } from '@/local_printer_config_constants';

export class PrinterConfig {
  static autoId = 0;

  constructor(ip, preselectedCategories = []) {
    this.id = PrinterConfig.autoId++;
    this.ip_address = ip
    this.printer_location = `Printer ${ip}`;
    this.paper_width = '76mm';
    this.can_print_kot = 1;
    this.can_print_bill = 1;
    this.can_print_receipt = 1;
    this.can_print_z_report = 1;
    this.can_print_qr_code = 1;

    const defaultModel = PRINTER_MODELS[0];

    this.printer_model = defaultModel;
    this.printer_model_id = defaultModel.id;
    this.kot_print_count = 1;
    this.__categories = preselectedCategories.map(cat => cat.id)
  }

  addCategory(categoryOrID) {
    if (categoryOrID.id) {
      categoryOrID = categoryOrID.id
    }
    this.__categories.push(categoryOrID);
  }

  removeCategory(categoryOrID) {
    if (categoryOrID.id) {
      categoryOrID = categoryOrID.id
    }
    this.__categories = this.__categories.filter(i => i != categoryOrID);
  }

  hasCategory(categoryOrID) {
    if (categoryOrID.id) {
      categoryOrID = categoryOrID.id
    }
    return this.__categories.includes(categoryOrID);
  }

  setCategoryActive(categoryOrId, active) {
    if (categoryOrId.id) {
      categoryOrId = categoryOrId.id;
    }

    if (active) {
      this.addCategory(categoryOrId);
    } else {
      this.removeCategory(categoryOrId);
    }
  }
} 

export class LocalPrinterConfigService {
  constructor(allCategories) {
    if (!allCategories) {
      throw new Error('allCategories is required, but was not provided');
    }
    this.allCategories = allCategories;
  }

  createCategoryEntries(printer) {
    if (printer.__categories.length >= this.allCategories.length) {
      return [
        {
          printer_detail_id: printer.id,
          product_category_id: null
        }
      ];
    } else {
      return printer.__categories.map(cat => ({
        printer_detail_id: printer.id,
        product_category_id: cat
      }));
    }
  }

  bulkCreateCategoryEntries(printers) {
    const allEntries = [];

    for (const printer of printers) {
      allEntries.push(...this.createCategoryEntries(printer));
    }

    return allEntries;
  }

  syncUpPrinterModelData(printerConfig) {
    const model = PRINTER_MODELS.find(m => m.id == printerConfig.printer_model_id);

    printerConfig.printer_model = model;
  }
}
