import {DOWNLINK_SPEED} from "@/spa/constants";

export function isNetworkStable() {
    if (navigator && navigator.onLine && navigator.connection) {
        const connectionType = navigator.connection.effectiveType;
        const downlinkSpeed = navigator.connection.downlink;

        // Check if it's greater than slow 3G (considering 3G as 0.384 Mbps)
        return downlinkSpeed > DOWNLINK_SPEED && !connectionType.includes('2g');
    } else {
        return false;
    }
}
